import { appDefaultValues } from "../../../../../../config/Config";

export interface DatosAdicionalesConyugeInterface {
    identNacionalidad: number,
    identNivelEducacion: number,
    correo: string,
    identProfesion: number,
}

export const DatosAdicionalesConyugeInterfaceDefault: DatosAdicionalesConyugeInterface = {

    identNacionalidad: appDefaultValues.NumberDefault,
    identNivelEducacion: appDefaultValues.NumberDefault,
    correo: appDefaultValues.StringEmpty,
    identProfesion: appDefaultValues.NumberDefault,
};