import { useEffect } from "react";
import icoAlertWarning from './../../../../../assets/media/icons/ico_alert_warning.svg';
import React from "react";
import { appConfigKey } from "../../../../../config/Config";

const CardResultReevaluar = ({ responseMotorAfterStepTwo, responseMotorBeforceStepTwo, listaSegmento, newMEN, newCuota, solicitud }: any) => {

    const [viewModel, setViewModel] = React.useState<any>({
        titulo: '',
        subTitulo: '',
        mensajes: [],
        motivos: [],
    });

    const DetalleRechazoModelica = (data: any) => {
        let mensajesModelica: any = [];
        let detalleRechazo = data?.response?.Politica_Creditos?.motivo_Rechazo?.Detalle;
        if (detalleRechazo && detalleRechazo?.length > 0) {
            detalleRechazo?.map((det: any, index: number) => (
                mensajesModelica.push({ motivo: det?.descripcion + (det?.interviniente ? ' del ' + det?.interviniente : '') })
            ));
        }
        return mensajesModelica;
    }


    useEffect(() => {
        if (responseMotorAfterStepTwo) {

            let responseMotorAfter = responseMotorAfterStepTwo?.response;
            let responseMotorBeforce = responseMotorBeforceStepTwo?.response;
            let decisionAfter = responseMotorAfter?.Politica_Creditos?.decision_Credito?.decision;
            let puntajeBeforce = responseMotorBeforce?.Politica_Creditos?.decision_Credito?.puntaje;
            let segmentoBeforce = responseMotorBeforce?.Politica_Creditos?.decision_Credito?.segmento;
            let puntajeAfter = responseMotorAfter?.Politica_Creditos?.decision_Credito?.puntaje;
            let segmentoAfter = responseMotorAfter?.Politica_Creditos?.decision_Credito?.segmento;
            let tituloTemp: string = 'RESULTADOS DE REEVALUACIÓN';
            let subTituloTemp: string = '';
            let resultadoDescripcion: string = '';
            let mensajesTemp: any = [];
            let motivosTemp: any = [];

            if (decisionAfter === appConfigKey.CodigoEvaluacionAprobado ||
                decisionAfter == appConfigKey.CodigoEvaluacionRechazado ||
                decisionAfter == appConfigKey.CodigoEvaluacionZonaGris
            ) {

                resultadoDescripcion = decisionAfter === appConfigKey.CodigoEvaluacionAprobado ? appConfigKey.CodigoEvaluacionAprobadoDescripcion.toUpperCase() : (
                    decisionAfter === appConfigKey.CodigoEvaluacionZonaGris ? appConfigKey.CodigoEvaluacionZonaGrisDescripcion.toUpperCase() : appConfigKey.CodigoEvaluacionRechazadoDescripcion.toUpperCase())


                if (responseMotorAfter?.productos_comerciales) {
                    let producto: any = responseMotorAfter?.productos_comerciales.find((x: any) => Number(x.codigo) == solicitud.idProducto);
                    if (!producto) {

                        subTituloTemp = appConfigKey.KeyMensajeSolicitudEstado.replace('{data}', resultadoDescripcion) +
                            ' Con las condiciones de tu revaluación, no genera el mismo producto indicado en la solicitud ingresada por el ejecutivo';
                    }
                    else {
                        subTituloTemp = appConfigKey.KeyMensajeSolicitudEstado.replace('{data}', resultadoDescripcion);
                    }
                } else {
                    subTituloTemp = appConfigKey.KeyMensajeSolicitudEstado.replace('{data}', resultadoDescripcion) +
                        ' Con las condiciones de tu revaluación, no se encontraron productos comerciales';
                }
            }

            if (decisionAfter == appConfigKey.CodigoEvaluacionRechazado) {
                motivosTemp = DetalleRechazoModelica(responseMotorAfterStepTwo);
            }


            if (puntajeBeforce !== puntajeAfter) {
                if (puntajeAfter) {
                    mensajesTemp.push({ mensaje: 'Puntaje ' + puntajeAfter });
                } else {
                    mensajesTemp.push({ mensaje: appConfigKey.KeyMensajeNoTiene.replace('{data}', 'Puntaje') });
                }
            }

            if (segmentoBeforce !== segmentoAfter) {
                //if (segmentoAfter) {
                let segmentoDescripAfter: string = '';
                if (listaSegmento.length > 0) {
                    let segmento: any = listaSegmento?.find((x: any) => x.id === segmentoAfter);
                    if (segmento) {
                        segmentoDescripAfter = segmento.description
                        mensajesTemp.push({ mensaje: 'Segmento ' + segmentoDescripAfter });
                    } else {
                        mensajesTemp.push({ mensaje: appConfigKey.KeyMensajeNoTiene.replace('{data}', 'Segmento') });
                    }
                }
                // } else {
                //     mensajesTemp.push({ mensaje: appConfigKey.KeyMensajeNoTiene.replace('{data}', 'Segmento') });
                // }
            }

            if (newCuota > newMEN) {
                mensajesTemp.push({ mensaje: appConfigKey.KeyMensajeMENMenor });
            }


            if (subTituloTemp || motivosTemp.length > 0) {
                setViewModel({
                    ...viewModel,
                    titulo: tituloTemp,
                    subTitulo: subTituloTemp + (motivosTemp.length > 0 ? ' Revise el siguiente detalle:' : ''),
                    mensajes: mensajesTemp,
                    motivos: motivosTemp
                });
            }

        }
    }, [responseMotorAfterStepTwo, newCuota]);


    return (
        <div className="border rounded-9 p-3 p-md-4 mb-4 mb-md-4 card-result body-alert-warning">

            <div className="row g-2 mb-2 item-alert-warning">
                <div className="col-lg-1 col-md-12">
                    <img src={icoAlertWarning} alt="Alert Icon" style={{ width: '40px' }} />
                </div>
                <div className="col-lg-11 col-md-12">
                    <div className="row g-2 mb-2 item-alert-warning">
                        <div className="col-lg-11 col-md-12 d-flex">
                            <span className="text-negrita">{viewModel.titulo}</span>
                        </div>
                    </div>
                    <div className="row g-2 mb-2 item-alert-warning">
                        <div className="col-lg-11 col-md-12 d-flex">
                            <span className="text-negrita">{viewModel.subTitulo}</span>
                        </div>
                    </div>

                    <div className="row g-2 mb-2 item-alert-warning">
                        <div className="col-lg-11 col-md-12 d-flex">
                            {viewModel.mensajes?.map((item: any, index: number) => (
                                <div key={index} style={{ marginBottom: index === 0 ? '10px' : '0' }}>{(index > 0 ? ', ' : '') + item?.mensaje}</div>
                            ))}
                        </div>
                    </div>
                    {viewModel.motivos.length > 0 && (<div className="row g-2 mb-2 item-alert-warning">
                        <div className="col-lg-11 col-md-12 d-flex">
                            <span className="text-negrita">Motivo:</span>
                        </div>
                    </div>)}
                    {viewModel.motivos?.map((item: any, index: number) => (
                        <div className="row g-2 mb-2 item-alert-warning">
                            <div className="col-lg-11 col-md-12 d-flex">

                                <div key={index}>{'- ' + item?.motivo}</div>

                            </div>
                        </div>
                    ))}

                </div>
            </div>
        </div>
    );
};

export default CardResultReevaluar;