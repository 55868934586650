import React from "react";
import { Sheet, Table } from "@mui/joy";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { Dialog } from "@mui/material";
import ResultadosHistorial from "../13.50.-Resultados/resultadosHistorial";
import './../13.10.-base/creditEvaluation.scss';
import GeneralHelper from "../../../helpers/GeneralHelper";

const DialogHistorialResultadoAnalisis = ({ open, handleClose, selSolicitud }: any) => {
  return (
    <Dialog open={open} maxWidth={'lg'} >
      <ResultadosHistorial flgResultadosHistorial={open} selSolicitud={selSolicitud} />
      <div className='centrar-div'>
        <div className='request-dialog-btn-close cursor-pointer' onClick={handleClose}>Cerrar</div>
      </div>
    </Dialog>
  )
}

export const ScoringTitular = ({ posicionCliente, setPosicionCliente, show }: any) => {
  // const { titular } = props;

  const [showCli, setShowCli] = React.useState(false);

  const [newPosicion, setNewPosicion] = React.useState(
    {
      solicitud: ['SOLICITUDES'],
      fchSolicitud: ['Fecha Solicitud'],
      estSolicitud: ['Estado Solicitud'],
      fchEvaluacion: ['Fecha evaluación'],
      horaEvaluacion: ['Hora evaluación'],
      concesionario: ['Concesionario'],
      sucursal: ['Sucursal'],
      categoriaLaboral: ['Categoría laboral'],
      producto: ['Producto'],
      ejecutivo: ['Ejecutivo'],
      analista: ['Analista'],
      tipoDocumento: ['Tipo documento'],
      nroDocumento: ['N. Documento'],
      cliente: ['Cliente'],
      monedaFinanciamiento: ['Moneda financiamiento'],
      finanaciamiento: ['Financiamiento'],
      resultadoEvaluacion: ['Resultado evaluación'],
      tipoResultado: ['Tipo resultado'],
      comentario: ['Comentario'],
    }
  );

  React.useEffect(() => {
    if (posicionCliente !== undefined && posicionCliente.solicitudes !== undefined && posicionCliente.solicitudes.length > 0) {

      const newArray: any = { ...newPosicion };

      posicionCliente.solicitudes.map((item: any, index: number) => {
        newArray.solicitud[index + 1] = item.solicitud;
        newArray.fchSolicitud[index + 1] = item.fechaSolicitud;
        newArray.estSolicitud[index + 1] = item.estado;
        newArray.fchEvaluacion[index + 1] = item.fechaEvaluacion;
        newArray.horaEvaluacion[index + 1] = item.horaEvaluacion;
        newArray.concesionario[index + 1] = item.concesionario;
        newArray.sucursal[index + 1] = item.sucursal;
        newArray.categoriaLaboral[index + 1] = item.categoriaLaboral;
        newArray.producto[index + 1] = item.producto;
        newArray.ejecutivo[index + 1] = item.ejecutivo;
        newArray.analista[index + 1] = item.analista;
        newArray.tipoDocumento[index + 1] = item.tipoDocumento;
        newArray.nroDocumento[index + 1] = item.nroDocumento;
        newArray.cliente[index + 1] = item.clienteNombre;
        newArray.monedaFinanciamiento[index + 1] = item.moneda;
        newArray.finanaciamiento[index + 1] = item.finanaciamiento;
        newArray.resultadoEvaluacion[index + 1] = item.resultadoEvaluacion;
        newArray.tipoResultado[index + 1] = item.tipoResultado;
        newArray.comentario[index + 1] = item.comentario;
      })
      setShowCli(true);
    }
  }, [posicionCliente])

  const [historialResultadoAnalisis, setHistorialResultadoAnalisis] = React.useState<boolean>(false);
  const [selSolicitud, setSelSolicitud] = React.useState(0);
  const handleHistorialResultadoAnalisisClose = () => {
    setHistorialResultadoAnalisis(false);
  };
  const handleHistorialResultadoAnalisisOpen = async (event: any) => {
    setHistorialResultadoAnalisis(true);
    setSelSolicitud(Number(event.target.parentElement.dataset.solicitud));
  };

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>HISTORIAL DE SOLICITUDES</AccordionSummary>
          <AccordionDetails>
            {newPosicion &&
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-12 cardObjets align-top">
                  <Sheet sx={{ overflow: 'auto' }}>
                    <Table borderAxis="none" className="style-table align-top" size={'md'} sx={{ '& thead tr:first-child': { borderTopLeftRadius: '10px', borderBottomLeftRadius: "10px" } }} >
                      <thead>
                        <tr>
                          {
                            newPosicion.solicitud.map((col: any) => {
                              return (
                                <th style={{ width: "170px", backgroundColor: "#E1E1E1" }}>{col}</th>
                              )
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        <tr>{newPosicion.fchSolicitud.map((row: any) => { return (<td> {row}</td>) })}</tr>
                        <tr>{newPosicion.estSolicitud.map((row: any) => { return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row}</td>) })}</tr>
                        <tr>{newPosicion.concesionario.map((row: any) => { return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row}</td>) })}</tr>
                        <tr>{newPosicion.sucursal.map((row: any) => { return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row}</td>) })}</tr>
                        <tr>{newPosicion.categoriaLaboral.map((row: any) => { return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row}</td>) })}</tr>
                        <tr>{newPosicion.producto.map((row: any) => { return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row}</td>) })}</tr>
                        <tr>{newPosicion.ejecutivo.map((row: any) => { return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row}</td>) })}</tr>
                        <tr>{newPosicion.analista.map((row: any) => { return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row}</td>) })}</tr>
                        <tr>{newPosicion.tipoDocumento.map((row: any) => { return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row}</td>) })}</tr>
                        <tr>{newPosicion.nroDocumento.map((row: any) => { return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row}</td>) })}</tr>
                        <tr>{newPosicion.cliente.map((row: any) => { return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row}</td>) })}</tr>
                        <tr>{newPosicion.monedaFinanciamiento.map((row: any) => { return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row}</td>) })}</tr>
                        <tr>{newPosicion.finanaciamiento.map((row: any) => { return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.NumeroConCommas(row ? row : 0)}</td>) })}</tr>
                        <tr>{newPosicion.resultadoEvaluacion.map((row: any, index: number) => {
                          return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}
                            data-solicitud={newPosicion.solicitud.find((f: any, nindex: number) => { return nindex === index })} > {row} {index > 0 ? <span className={"text-verMas"} onClick={handleHistorialResultadoAnalisisOpen}>Ver más</span> : ''}</td>)
                        })} </tr>
                        <tr>{newPosicion.tipoResultado.map((row: any) => { return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row}</td>) })}</tr>
                        <tr>{newPosicion.comentario.map((row: any) => { return (<td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row}</td>) })}</tr>
                      </tbody>
                    </Table>
                  </Sheet>
                </div>
              </div>
            }
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>

      <DialogHistorialResultadoAnalisis open={historialResultadoAnalisis} handleClose={handleHistorialResultadoAnalisisClose} selSolicitud={selSolicitud} />
    </div>
  );
};

export default ScoringTitular;