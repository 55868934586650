import CardTitleStart from '../../1.-shared/1.2.-layout/1.2.5-cardTitleStart/cardTitleStart';// '../3.20.-cardTitleStart/cardTitleStart';
import MainLayout from '../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout';
import { Toaster } from "sonner";
import { useNavigate, useParams } from 'react-router-dom';
import Steps from './components/Steps';
import { StepsProvider } from './providers';
import { General } from './obtenerServicios';
import { appConfigKey } from '../../../../config/Config';
import { useEffect } from 'react';

const Solicitud = () => {

  const { solicitudId } = useParams();
  const navigate = useNavigate();

  const InitDefaultSolicitud = async () => {
    //await ValidacionSolicitud(Number(solicitudId), Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0), appConfigKey.KeyFlujoSolicitud);
  }

  const ValidacionSolicitud = async function (idenT_SOLICITUD: any, idenT_USUARIO: any, idenT_FLUJO: any) {
    let datos = await General.ValidacionSolicitud(idenT_SOLICITUD, idenT_USUARIO, idenT_FLUJO);
    let resultado: any = datos?.data?.content?.resultado;
    
    if (!resultado || resultado === 0) {
      navigate(`/error/404`);
      return;
    }
  };  

  useEffect(() => {
    if (solicitudId) {
      InitDefaultSolicitud();
    }
  }, [solicitudId])

  return (
    <>
      <Toaster position="top-center" toastOptions={{ style: { width: '500px', margin: '0 auto' } }} richColors closeButton />
      <MainLayout />
      <CardTitleStart Ventana="Solicitud" identSolicitud={solicitudId} />
      <StepsProvider>
        <Steps />
      </StepsProvider>
    </>
  );

};

export default Solicitud;