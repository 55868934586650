import { appDefaultValues } from '../../../config/Config';

export interface reporte {
 periodo: string,
 nro_reportantes: number,
 nro_reportantes_c_deuda: number,
 porc_deuda_normal: number,
 porc_deuda_cpp: number,
 porc_deuda_def: number,
 porc_deuda_dud: number,
 porc_deuda_per: number
}

export interface reporteResumen {
 periodo: string,
 nombre: string,
 deuda: number
}

export interface InformacionEntidad {
 reporte: reporte[],
 reporteResumen: reporteResumen[]
}

export const InformacioEntidadDefault: InformacionEntidad = {
 reporte: [],
 reporteResumen: [],
}