import { useEffect, useState } from "react";
import { Dialog } from "@mui/material";
import { Button } from "@mui/joy";

const ConfirmDialog = (props: any) => {

    useEffect(() => {
    }, [props.open]);

    const handleClose = (e: any) => {
        if (props.handleClose) props.handleClose();
    };

    const handleSave = async () => {
        if (props.handleSave) props.handleSave();
    };

    return (
        <Dialog open={props.open} maxWidth={'xs'} >
            <div className='obs-confirm-dialog-content'>
                <div className='obs-confirm-dialog-title'>GUARDAR</div>
                <div className='obs-confirm-dialog-list-content'>
                    <div className="row g-2 mb-2 align-items-center text-center">
                        <div className="col align-top">
                            ¿Está seguro de guardar su resultado?
                        </div>
                    </div>
                </div>
                <div className="d-flex justify-content-center px-1">
                    <Button
                        disabled={false}
                        color="danger"
                        sx={{ borderRadius: 24, width: 1 }}
                        onClick={handleSave}
                        className="d-flex align-items-center w-100"
                    >
                        <div className="w-100">Guardar</div>
                    </Button>
                    <div className="px-1"></div>
                    <Button
                        disabled={false}
                        color="neutral"
                        sx={{ borderRadius: 24, width: 1 }}
                        onClick={handleClose}
                        className="d-flex align-items-center w-100"
                    >
                        <div className="w-100">Cerrar</div>
                    </Button>
                </div>
            </div>
        </Dialog>
    );
};

export default ConfirmDialog;
