import { Toaster } from 'sonner';
import MainLayout from '../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout';
import LoadingProgress from '../../1.-shared/1.4.-loading/loading-progress/loading-progress';
import { useEffect, useState } from 'react';
import './informeComercial.scss';
import DatosGenerales from '../15.20.-datosGenerales/datosGeneralesAuditor';
import Accionistas from '../15.30.-accionistas/accionistasAuditor';
import DatosDirectorio from '../15.40.-datosDirectorio/datosDirectorioAuditor';
import DatosGerencia from '../15.50.-datosGerencia/datosGerenciaAuditor';
import Participacion from '../15.60.-participacion/participacionAuditor';
import Cargos from '../15.70.-cargos/cargosAuditor';
import Produccion from '../15.80.-produccion/produccionAuditor';
import Propiedades from '../15.90.-propiedades/propiedadesAuditor';
import Proveedores from '../15.100.-proveedores/proveedoresAuditor';
import Ventas from '../15.110.-ventas/ventasAuditor';
import Representantes from '../15.120.-representantes/representantesAuditor';
import DatosGrupo from '../15.130.-datosGrupo/datosGrupoAuditor';
import RequestManager from '../../../services/origination/wapirequest.service';
import { DatosInformeComercial, DatosInformeComercialDefault } from './interfaces';
import { appSecurityConfiguration } from '../../../../config/Config';
import { InformeComercialProvider } from './providers/informeComercialProvider';
import InformeComercialCardTitle from './components/InformeComercialCardTitle';


export default function InformeComercialAuditor({ isModal, solicitudId }: any) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [dataInformeComercial, setDataInformeComercial] = useState<DatosInformeComercial>(DatosInformeComercialDefault);

  let urlParams = new URLSearchParams(window.location.search);

  const IdInforme = solicitudId ?? urlParams.get('Informe');

  const CargaInicial = async () => {
    const response = await RequestManager.GetMnInformeComercialByIdSolicitud({ "identInformeComercial": IdInforme});
    if (response.status === 200) {
      setDataInformeComercial(response.data.content);
    }
  }


  const GetCardInformeComercialClassName = (isModal: boolean | null | undefined) => {
    let className = 'row g-3 mb-2 cardInformeComercial';
    if (isModal) className += 'Modal';
    return className;
  };

  useEffect(() => {
    CargaInicial();
  }, []);

  return (
    <div>
      {isLoading && <LoadingProgress />}
      <Toaster position="top-center" toastOptions={{ style: { width: '500px', margin: '0 auto' } }} richColors closeButton />

      {!isModal && 
      <>
        <MainLayout />
        <InformeComercialCardTitle identification={IdInforme}/>
      </>
      }
      <InformeComercialProvider solicitudId={IdInforme}>
        <div className={GetCardInformeComercialClassName(isModal)}>
          <DatosGenerales
          />
          <Accionistas
            dataInformeComercial={dataInformeComercial}
          />
          <DatosDirectorio
            dataInformeComercial={dataInformeComercial}
          />
          <DatosGerencia
            dataInformeComercial={dataInformeComercial}
          />
          <Participacion
            dataInformeComercial={dataInformeComercial}
          />
          <Cargos
            dataInformeComercial={dataInformeComercial}
          />
          <Produccion
            dataInformeComercial={dataInformeComercial}
          />
          <Propiedades
            dataInformeComercial={dataInformeComercial}
          />
          <Proveedores
            dataInformeComercial={dataInformeComercial}
          />
          <Ventas
            dataInformeComercial={dataInformeComercial}
          />
          <Representantes
            dataInformeComercial={dataInformeComercial}
          />
          <DatosGrupo
            dataInformeComercial={dataInformeComercial}
          />
        </div>
      </InformeComercialProvider>
    </div>
  );
}