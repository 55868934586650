import { Component } from 'react';
import "./card-steps.scss";
import 'bootstrap/dist/css/bootstrap.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.material-purple.css';
import Ellipse1 from '../../../../assets/media/icons/Ellipse1.svg';
import StepCheck from '../../../../assets/media/icons/step-check.svg';
import StepWhite from '../../../../assets/media/icons/step-white.svg';
import Line1 from '../../../../assets/media/icons/Line1.svg';
import Line2 from '../../../../assets/media/icons/Line2.svg';

interface ICardStepsProps {
    objCardStepsProps : {
        numStep: number, 
    }
}

class CardSteps extends Component<ICardStepsProps, any> {
  constructor(props: any) {
    super(props);
  }

  render() {

    return (
      <div>
        <div className='content-quatition'>
          <div className="row g-2 mb-2 align-items-center ">
            <div className="col-lg-12" style={{ textAlign: "center" }} >

              <div className="conteint-img">
                  <img src={this.props.objCardStepsProps.numStep === 1 ? Ellipse1 : StepCheck}/>
                  <div className="conteint-img-center">{this.props.objCardStepsProps.numStep === 1 ? '1' : '' }</div>
              </div>
              <img src={this.props.objCardStepsProps.numStep === 1 ? Line1 :  Line2}/>
              <div className="conteint-img">
                <img src={this.props.objCardStepsProps.numStep === 2  ? Ellipse1 : this.props.objCardStepsProps.numStep === 3 || this.props.objCardStepsProps.numStep === 4  ? StepCheck : StepWhite }/>
                <div className="conteint-img-center">{this.props.objCardStepsProps.numStep === 2  ? '2' : '' }</div>
              </div>
              <img src={this.props.objCardStepsProps.numStep === 2 || this.props.objCardStepsProps.numStep === 1 ? Line1 :  Line2}/>
              <div className="conteint-img">
                <img src={this.props.objCardStepsProps.numStep === 3  ? Ellipse1 : this.props.objCardStepsProps.numStep === 4 ? StepCheck : StepWhite}/>
                <div className="conteint-img-center">{this.props.objCardStepsProps.numStep === 3  ? '3' : ''}</div>
              </div>
              <img src={this.props.objCardStepsProps.numStep === 3 || this.props.objCardStepsProps.numStep === 2 || this.props.objCardStepsProps.numStep === 1 ? Line1 :  Line2}/>
              <div className="conteint-img">
                <img src={this.props.objCardStepsProps.numStep === 4  ? Ellipse1 : StepWhite}/>
                <div className="conteint-img-center">{this.props.objCardStepsProps.numStep === 4  ? '4' : ''}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CardSteps;