import { CSSProperties, forwardRef } from "react";

type CustomToggleProps = {
  children?: React.ReactNode;
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  className?: string | undefined;
};

const CustomDivToggle = forwardRef(
  (props: CustomToggleProps, ref: React.Ref<HTMLDivElement>) => (
    <div
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        if (props.onClick) {
          props.onClick(e);
        }
      }}
    >
      {props.children}
    </div>
  )
);

CustomDivToggle.displayName = "CustomDivToggle";

export default CustomDivToggle;
