import { appConfigKey, appDefaultValues } from "../../../../../config/Config";
import GeneralHelper from "../../../../helpers/GeneralHelper";

class StepOneHelper {

    agregarDivisa(monto: any, divisa: any) {

        if (appConfigKey.keyIdentificadorSoles === divisa) {
            return `${appConfigKey.keySimboloSoles} ${monto}`;
        }
        
        if (appConfigKey.keyIdentificadorDolares === divisa) {
            return `${appConfigKey.keySimboloDolares1} ${monto}`;
        }

        return monto;
    }

    obtenerIngresos(ingresosTitular: any, tipoCambio: number) {

        const { calculoAnualizado, ingresosNetos, anualizado, identTipoMoneda } = ingresosTitular;

        let ingresoTotal: number = this.obtenerIngresosBase(anualizado, calculoAnualizado, ingresosNetos, identTipoMoneda, tipoCambio);

        const { listaOtrosTrabajos } = ingresosTitular;

        for (let index = 0; index < listaOtrosTrabajos.length; index++) {
            
            const element = listaOtrosTrabajos[index];
            
            ingresoTotal = ingresoTotal + this.obtenerIngresosBase(element.anualizado, element.calculoAnualizado, element.ingresosNetos, element.identTipoMoneda, tipoCambio);

        }

        return ingresoTotal;
        
    }

    obtenerIngresosBase(anualizado: boolean, calculoAnualizado: string, ingresosNetos: string, identTipoMoneda: number, tipoCambio: number) {

        if (anualizado) {
            
            const tempCalculoAnualizado = GeneralHelper.QuitarComasAMiles(calculoAnualizado);

            if (identTipoMoneda === appConfigKey.keyIdentificadorSoles) {
                return Number(tempCalculoAnualizado);
            }

            if (identTipoMoneda === appConfigKey.keyIdentificadorDolares) {
                return Number(tempCalculoAnualizado) * tipoCambio;
            }

            return Number(tempCalculoAnualizado);

        } else {

            const tempIngresosNetos = GeneralHelper.QuitarComasAMiles(ingresosNetos);

            if (identTipoMoneda === appConfigKey.keyIdentificadorSoles) {
                return Number(tempIngresosNetos);
            }

            if (identTipoMoneda === appConfigKey.keyIdentificadorDolares) {
                return Number(tempIngresosNetos) * tipoCambio;
            }

            return Number(tempIngresosNetos);

        }

    }

}

export default StepOneHelper;