import { Button, Switch, Table, Typography } from "@mui/joy";
import { Dialog } from "@mui/material";
import React from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { TextInput } from "./TextInput";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";

const DocumentationDialogAdd = (props: any) => {

    const [loading, setLoading] = React.useState<boolean>(false);
    const [selectedList, setSelectedList] = React.useState<any[]>([]);
    const [foundList, setFoundList] = React.useState<any[]>([]);
    const [searchText, setSearchText] = React.useState<string | null>(null);

    const addToList = (item: any) => {
        selectedList.push(item);
        setSelectedList(selectedList);
    };

    const removeToList = (item: any) => {
        const newSelected = selectedList.filter((x: any) => x.codigo !== item.codigo);
        setSelectedList(newSelected);
    }

    const resetValues = () => {
        setFoundList(props.tableData);
        setSelectedList([]);
        setSearchText(null);
    }

    const loadValues = () => {
        setFoundList(props.tableData);
    }

    const onSearchClick = (searchText: any = "") => {
        let founds = props.tableData.filter((x: any) => x.descripcion?.trim().toLocaleUpperCase().includes(searchText?.trim().toLocaleUpperCase()));
        setFoundList(founds);
    }

    const onChangeSearchText = (event: any) => {
        let text = event.target.value;
        setSearchText(text)
        onSearchClick(text);
    }

    const onCloseClick = () => {
        resetValues();
        props.handleClose();
    }

    const onClick = async () => {
        if (selectedList.length === 0) {
            return;
        }

        if (loading) {
            return;
        }

        setLoading(true);

        try {
            const itemWithId39 = selectedList.find((x: any) => x.codigo === 39);

            if (itemWithId39) {
                props.handleClose();
                props.handleOpenSecondModal(selectedList);
                resetValues();
            }else{
                await props.handleChangeSwitch(selectedList);
                setLoading(false);
                resetValues();
            }
        } catch (error) {
        }

        setLoading(false);
        resetValues();
        setLoading(false);
    }

    const handleChange = (event: any, data: any) => {
        if (event.target.checked) {
            addToList(data);
        } else {
            removeToList(data);
        }
        setFoundList([...foundList]);
    };

    const renderSwitch = (data: any) => {

        const finder = selectedList.find((x: any) => x.codigo === data.codigo);

        if (finder && loading) {
            return (
                <div style={{ textAlign: 'center' }}>
                    <CircularProgress size={12} />
                </div>
            );
        }

        return (
            <Switch
                slotProps={{
                    track: {
                        children: (
                            <React.Fragment>
                                <Typography component="span" level="inherit" sx={{ ml: '10px' }}>
                                    Si
                                </Typography>
                                <Typography component="span" level="inherit" sx={{ mr: '8px' }}>
                                    No
                                </Typography>
                            </React.Fragment>
                        ),
                    },
                }}
                sx={{
                    '--Switch-thumbSize': '27px',
                    '--Switch-trackWidth': '64px',
                    '--Switch-trackHeight': '31px',
                }}
                checked={finder != undefined && finder != null}
                onClick={(event: any) => {
                    handleChange(event, data);
                }}
            />
        );

    }

    React.useEffect(() => {
        if (props.open) {
            loadValues();
        }
    }, [props.open]);

    return (
        <Dialog open={props.open} maxWidth={'sm'}>
            <div className="content-result-documentation-add-dialog">
                <div className="content-result-documentation-add-dialog-title">
                    <h2>DOCUMENTOS ADICIONALES</h2>
                </div>
                <div className="row pb-3">
                    <div className="col-12">
                        <TextInput
                            placeholder="Ingresar nombre"
                            // value={searchText ?? null}
                            onChange={onChangeSearchText}
                        ></TextInput>
                    </div>
                    {/*<div className="col-4">
                        <Button
                            size="md"
                            color="danger"
                            sx={{ borderRadius: 24 }}
                            onClick={() => onSearchClick(searchText)}>
                            Buscar
                        </Button>
                    </div>*/}
                </div>
                <div className="content-result-documentation-add-dialog-body">
                    <Table borderAxis="none" className="style-table" stripe={'odd'} >
                        <tbody>
                            {foundList.length == 0 && <div className="col text-center"><label>No se encontraron registros.</label></div>}
                            {foundList.map((data: any, index: number) => (
                                <tr key={index}>
                                    <td width={70}>
                                        <div>{data.descripcion}</div>
                                    </td>
                                    <td width={30}>
                                        {renderSwitch(data)}
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table>
                </div>
                <div className="row pt-3">
                    <div className="col-6">
                        <Button
                            color="neutral"
                            startDecorator={<ChevronLeftIcon />}
                            sx={{ borderRadius: 24, width: 1 }}
                            onClick={onCloseClick}>
                            Cancelar
                        </Button>
                    </div>
                    <div className="col-6">
                        <Button
                            color="danger"
                            endDecorator={<KeyboardArrowRight />}
                            sx={{ borderRadius: 24, width: 1 }}
                            onClick={onClick}>
                            Agregar
                        </Button>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default DocumentationDialogAdd;