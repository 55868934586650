import { appConfigKey } from "../../../../../config/Config";
import { validarText } from "../../../../helpers/ValidarHelper";

const ValidacionRequestExceptionHelper = {

    NewReqAddSolicitudExcepcion: (reqSolicitudExcepcion: any) => {
        const {
            sustento
        } = reqSolicitudExcepcion;
    
        if (!validarText(sustento, '"Sustento excepción"')) {
            return false;
        }
        return true;
    },

    ModelicaDecision: (response: any) => {

        let validacion: { decision: string | null | number, mensajeModelica: any[] | null, derivacion: number } = { decision: null, mensajeModelica: null, derivacion: 0 };

        let decision = response?.Politica_Creditos?.decision_Credito?.decision;
        let mesaCreditos = response?.Politica_Creditos?.decision_Credito?.mesa_Creditos;
        let motivoRechazo = response?.Politica_Creditos?.motivo_Rechazo;
        let mensajes = [];

        const msgDecision = '- Decisión del crédito:';
        const msgMotivo = '- Motivo:';

        validacion.decision = decision;
        //if (decision === appConfigKey.CodigoEvaluacionZonaGris) {
        if (mesaCreditos.derivacion === appConfigKey.CodigoDerivacion) {
            //mensajes.push(`${msgDecision} ${appConfigKey.CodigoEvaluacionZonaGrisDescripcion}`);

            validacion.derivacion = appConfigKey.CodigoDerivacion;

            if (mesaCreditos?.motivo_Derivacion) {
                const detalleZonaGris = mesaCreditos?.motivo_Derivacion.map((det: any, index: number) => `${det?.detalle}`);
                mensajes.push(`${msgMotivo} ${detalleZonaGris.join(", ")}`);
            }
        }

        if (decision === appConfigKey.CodigoEvaluacionRechazado) {
            mensajes.push('La solicitud ha sido rechazada, revise la información del cliente y/o condiciones del financiamiento para que pueda aplicar al crédito.');
            mensajes.push(`${msgDecision} ${appConfigKey.CodigoEvaluacionRechazadoDescripcion}`);

            if (motivoRechazo?.Detalle) {
                const detalleRechazo = motivoRechazo.Detalle.map((det: any, index: number) => `${det?.descripcion} ${(det?.interviniente ? ' del ' + det?.interviniente : '')}`);
                mensajes.push(`${msgMotivo} ${detalleRechazo.join(", ")}`);
            }
        }

        if (mensajes.length > 0) {
            
            if (decision === appConfigKey.CodigoEvaluacionRechazado) {
                mensajes.unshift(appConfigKey.keyMsjModelicaObservado.replace("[CAMPO]", appConfigKey.keyMsjModelicaObservadoDecision));
            }

            validacion.mensajeModelica = mensajes?.map((msg: any, index: number) => (
                <div key={index} style={{ marginBottom: index === 0 ? '10px' : '0' }}>
                    {msg}
                </div>
            ));
            
        }

        return validacion;
    },
}
export default ValidacionRequestExceptionHelper;