import ArrowLeft from '../../../../../assets/media/icons/ico_arrowleft.svg';

const SolicitudeBtnBack = (props: any) => {
    return (
        <div className='solicitude-button-back' onClick={(event: any) => {
            props.setPaso(event);
        }}>
            <img src={ArrowLeft} />Regresar
        </div>
    );
};

export default SolicitudeBtnBack;