import { MnFormatoAuditorByIdRequest, MnFormatoAuditorRequest } from "../../../models/RequestManager/solicitud-formato-auditor-request.model";
import { MnInformeTerceraRequest } from "../../../models/RequestManager/solicitud-informel-tercera-request.model";
import MasterManager from "../../../services/origination/wapimaster.service";
import RequestManager from "../../../services/origination/wapirequest.service";

export const General = {

  GetMnSolicitud: async (solicitudId: any) => {
    let solicitud: any = null;

    try {
      const response = await MasterManager.GetMnSolicitud({ "idenT_SOLICITUD": solicitudId });

      if (response.status === 200) {
        solicitud = response.data.content[0];
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LA SOLICITUD.');
    }

    return solicitud;
  },

  GetMnEvaluacionScoringTercera: async (body: MnInformeTerceraRequest) => {
    try {
      const response = await RequestManager.GetMnEvaluacionScoringTercera(body);
      if (response?.status === 200) {
        return response.data.content;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE EVALUACION SCORING TERCERA.');
      return null;
    }
  },

  GetMnFormatoAuditorById: async (body: MnFormatoAuditorByIdRequest) => {
    try {
      const response = await RequestManager.GetMnFormatoAuditorById(body);
      if (response?.status === 200) {
        return response.data.content;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE OBTENER FORMATO REGIMEN AUDITOR.');
      return null;
    }
  },

  UpdateMnFormatoAuditor: async (body: MnFormatoAuditorRequest) => {
    try {
      const response = await RequestManager.UpdateMnFormatoAuditor(body);
      if (response?.status === 200) {
        return response.data.content;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE ACTUALIZAR FORMATO REGIMEN AUDITOR.');
      return null;
    }
  },

};