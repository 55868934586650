import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { Button, Divider } from "@mui/joy";
import HistoricoTeaResquestDialog from '../../7.-managerProfile/7.40.-financiamiento/financiamientoHistoricoTea';
import MasterManager from "../../../services/origination/wapimaster.service";
import { HistoricoTea, ListaGastos, clsHistoricoTeaDefault, clsListaGastosDefault } from "../../../models/ManagerProfile/HistoricoTea";
import { useParams } from "react-router-dom";
import { useContext, useState } from "react";
import React from "react";
import { appSecurityConfiguration } from "../../../../config/Config";
import ModalGastos from "../../3.-solicitude/3.70.-SolicitudeStepFour/components/ModalGastos";
import RequestManager from "../../../services/origination/wapirequest.service";
import GeneralHelper from "../../../helpers/GeneralHelper";

export const FinanciamientoDatosCondiciones = (props: any) => {

  const { datosFinanciamiento, datosSolActivacion } = props;
  const { solicitudId } = useParams();
  const [historicoTea, setHistoricoTea] = useState<HistoricoTea[]>([])
  const [listaGastos, setListaGastos] = useState<ListaGastos[]>([])
  var SessionPerfil = appSecurityConfiguration.SessionPerfil;

  const cargarListaTea = async () => {
    var body = {
      "idenT_SOLICITUD": solicitudId
    };
    await MasterManager.GetMnSolicitudHistoricoCambiarTasa(body)
      .then((response: any) => {
        response.data.content.forEach((item: HistoricoTea) => {
          const newTea = new clsHistoricoTeaDefault()
          newTea.fecha = item.fecha
          newTea.teA_ACTUAL = item.teA_ACTUAL
          newTea.teA_NUEVA = item.teA_NUEVA
          newTea.username = item.username
          newTea.comentario = item.comentario
          setHistoricoTea([...historicoTea, newTea]);
        })
        setHistoricoTeaRequestDialogOpen(true);
      })
  }

  const cargarListaGastos = async () => {
    let resultadoHistorial: any = []
    const response = await RequestManager.GetMnOfertaConsumoPrestamo({ "idenT_SOLICITUD": solicitudId, "id_tipO_TRANSACCION": 1 });
    if (response.status === 200) {
      resultadoHistorial = response.data.content;
    };
    //myowasp(resultadoHistorial)
    setListaGastos(resultadoHistorial);
    setOpenModalGastos(true);
  }

  const [historicoTeaRequestDialogOpen, setHistoricoTeaRequestDialogOpen] = React.useState<boolean>(false);
  const handleHistoricoTeaRequestDialogClose = () => {
    setHistoricoTeaRequestDialogOpen(false);
  };
  const handleHistoricoTeaRequestDialogOpen = () => {
    cargarListaTea();
  };

  const [openModalGastos, setOpenModalGastos] = useState(false);
  const handleCloseModalGastos = () => {
    setOpenModalGastos(false);
  };

  const handleClickOpenModalGastos = () => {
    cargarListaGastos();
  };

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-4 textLabelHeadboard">
          <span className="fw-bold">DATOS Y CONDICIONES DE FINANCIAMIENTO BPO</span>
        </div>
        <div className="col-lg-4">
          <span className="fw-bold"> </span>
        </div>
        <div className="col-lg-4 textAlignRigth"></div>
      </div>

      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-3 cardObjets">
          <FormControl>
            <FormLabel><span className="text-label">Moneda</span></FormLabel>
            <FormLabel>{datosFinanciamiento.moneda}</FormLabel>
          </FormControl>
        </div>
        <div className="col-lg-3 cardObjets">
          <FormControl>
            <FormLabel><span className="text-label">Porcentaje inicial<span style={{ color: "red" }}> * </span></span>
            </FormLabel>
            <FormLabel>{datosFinanciamiento.porcentajeInicial}%</FormLabel>
          </FormControl>
        </div>
        <div className="col-lg-5 cardObjets">
          <FormControl>
            <FormLabel><span className="text-label">Comentario <span style={{ color: "red" }}> * </span></span>
            </FormLabel>
            <FormLabel>{datosFinanciamiento.comentario}</FormLabel>
          </FormControl>
        </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-3 cardObjets">
          <FormControl>
            <FormLabel>
              <span className="text-label">Cuotas <span style={{ color: "red" }}> * </span></span>
            </FormLabel>
            <FormLabel>{datosFinanciamiento.cuota}</FormLabel>
          </FormControl>
        </div>
        <div className="col-lg-3 cardObjets">
          <FormControl>
            <FormLabel>
              <span className="text-label">TEA <span style={{ color: "red" }}> * </span></span>
            </FormLabel>
            <FormLabel>{datosFinanciamiento.tea}%</FormLabel>
          </FormControl>
        </div>
        <div className="col-lg-3 cardObjets">
          <FormControl>
            <FormLabel><span className="text-label">Cuota doble (Agosto - Enero)</span></FormLabel>
            <FormLabel>{datosFinanciamiento.cuotaDoble}</FormLabel>
          </FormControl>
        </div>
        <div className="col-lg-3 cardObjets">
          <FormControl>
            <FormLabel><span className="text-label">Tipo de cambio</span></FormLabel>
            <FormLabel>{datosFinanciamiento.tipoCambio}</FormLabel>
          </FormControl>
        </div>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Monto vehículo soles</span></FormLabel>
              <FormLabel>S/ {GeneralHelper.AgregarComasAMiles(datosFinanciamiento.montoVehiculoSoles ?? '')}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Cuota inicial soles</span></FormLabel>
              <FormLabel>S/ {GeneralHelper.AgregarComasAMiles(datosFinanciamiento.montoInicialSoles ?? '')}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Monto financiar soles</span></FormLabel>
              <FormLabel>S/ {GeneralHelper.AgregarComasAMiles(datosFinanciamiento.montoFinanciarSoles ?? '')}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Monto total financiamiento soles</span></FormLabel>
              <FormLabel>S/ {GeneralHelper.AgregarComasAMiles(datosFinanciamiento.montoTotalFinanciamientoSoles ?? '')}</FormLabel>
            </FormControl>
          </div>
        </div>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Monto vehículo dolares</span></FormLabel>
              <FormLabel>$ {GeneralHelper.AgregarComasAMiles(datosFinanciamiento.montoVehiculoDolares ?? '')}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Cuota inicial dolares</span></FormLabel>
              <FormLabel>$ {GeneralHelper.AgregarComasAMiles(datosFinanciamiento.montoInicialDolares ?? '')}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Monto financiar dolares</span></FormLabel>
              <FormLabel>$ {GeneralHelper.AgregarComasAMiles(datosFinanciamiento.montoFinanciarDolares ?? '')}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Monto total financiamiento dolares</span></FormLabel>
              <FormLabel>$ {GeneralHelper.AgregarComasAMiles(datosFinanciamiento.montoTotalFinanciamientoDolares ?? '')}</FormLabel>
            </FormControl>
          </div>
        </div>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Producto</span></FormLabel>
              <FormLabel>{datosFinanciamiento.producto}</FormLabel>
            </FormControl>
          </div>
          {datosFinanciamiento.campana && <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Campaña</span></FormLabel>
              <FormLabel>{datosFinanciamiento.campana}</FormLabel>
            </FormControl>
          </div>
          }
          {datosFinanciamiento.campana && <div className="col-lg-3 cardObjets">
            <FormLabel><span className="text-label">CI/Precio</span></FormLabel>
            <FormLabel>{datosFinanciamiento.ciPrecio}</FormLabel>
          </div>
          }
          <div className="col-lg-3 cardObjets">
            <Button
              size="md"
              color="danger"
              variant="outlined"
              sx={{
                borderRadius: 24,
                borderColor: "red",
                fontWeight: 650,
                width: 1 / 2,
              }}
              onClick={handleHistoricoTeaRequestDialogOpen}
            >
              Histórico TEA
            </Button>
          </div>
        </div>
        <br></br>
        <hr className="hr hr-blurry mb-4" style={{ borderColor: "#0089B5", opacity: 1 }} />
        <br></br>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Seguro Vehicular</span></FormLabel>
              <FormLabel>{datosFinanciamiento.descSeguroVehicular}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Tasa seguro Vehicular</span></FormLabel>
              <FormLabel>{datosFinanciamiento.seguroVehicularEndosado}%</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Monto asegurado</span></FormLabel>
              <FormLabel>{datosFinanciamiento.montoVehiculo}</FormLabel>
            </FormControl>
          </div>
        </div>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Proveedor GPS</span></FormLabel>
              <FormLabel>{datosFinanciamiento.proveedorGPS}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">GPS</span></FormLabel>
              <FormLabel>{datosFinanciamiento.gps}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Gastos inclusión GPS</span></FormLabel>
              <FormLabel>{datosFinanciamiento.gastoInclusionGPS}</FormLabel>
            </FormControl>
          </div>
        </div>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Gastos registrales</span></FormLabel>
              <FormLabel>{datosFinanciamiento.gastosRegistrales}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Gastos notariales</span></FormLabel>
              <FormLabel>{datosFinanciamiento.gastosNotariales}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Gastos delivery firmas</span></FormLabel>
              <FormLabel>{datosFinanciamiento.gastosDeliveryFirmas}</FormLabel>
            </FormControl>
          </div>
        </div>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <Button
              size="md"
              color="danger"
              variant="outlined"
              sx={{
                borderRadius: 24,
                borderColor: "red",
                fontWeight: 650,
                width: 1 / 2,
              }}
              onClick={handleClickOpenModalGastos}
            >
              Ver Gastos
            </Button>
          </div>
        </div>
        <br></br>
        <hr className="hr hr-blurry mb-4" style={{ borderColor: "#0089B5", opacity: 1 }} />
        <br></br>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Concesionario</span></FormLabel>
              <FormLabel>{datosFinanciamiento.concesionario}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Sucursal</span></FormLabel>
              <FormLabel>{datosFinanciamiento.sucursal}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Marca</span></FormLabel>
              <FormLabel>{datosFinanciamiento.marca}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Modelo</span></FormLabel>
              <FormLabel>{datosFinanciamiento.modelo}</FormLabel>
            </FormControl>
          </div>
        </div>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel><span className="text-label">Vendedor</span></FormLabel>
              <FormLabel>{datosFinanciamiento.vendedor}</FormLabel>
            </FormControl>
          </div>
        </div>

        {datosSolActivacion && (<>
          <br></br>
          <hr className="hr hr-blurry mb-4" style={{ borderColor: "#0089B5", opacity: 1 }} />
          <br></br>
          <div className="row g-2 mb-2 align-items-center">
            <div className="col-lg-3 cardObjets">
              <FormControl>
                <FormLabel><span className="text-label">N° Cuenta</span></FormLabel>
                <FormLabel>{datosSolActivacion?.btCuenta}</FormLabel>
              </FormControl>
            </div>
            <div className="col-lg-3 cardObjets">
              <FormControl>
                <FormLabel><span className="text-label">N° Operación</span></FormLabel>
                <FormLabel>{datosSolActivacion?.btOperacionId}</FormLabel>
              </FormControl>
            </div>
          </div>
        </>)}
      </div>
      <HistoricoTeaResquestDialog
        open={historicoTeaRequestDialogOpen}
        handleClose={handleHistoricoTeaRequestDialogClose}
        historicoTea={historicoTea}
      />
      <ModalGastos
        handleCloseModalGastos={handleCloseModalGastos}
        openModalGastos={openModalGastos}
        listaGastos={listaGastos}
      />
    </div>
  );
};

export default FinanciamientoDatosCondiciones;
