import { useContext, useEffect, useState } from "react";
import { StepsContext } from "../../../3.10.-base/providers/StepsProvider";
import TitleCard from "../../../3.40.-SolicitudeStepOne/components/elements/titleCard";
import { NumberInput, SelectInput, SimpleInput } from "../../../3.40.-SolicitudeStepOne/components/elements/inputs";
import { DomicilioTitularInterface, DomicilioTitularInterfaceDefault } from "./interfaces";
import { StepFiveContext } from "../../../3.10.-base/providers";

const DomicilioTitularCard = () => {


    const {
        listTipoVivienda,
        listTiempoResidencia,
        clickNext,
        step,
        viewModelStepOneFinal,
        readonlyForm,
        viewModelStepFiveFinal
    } = useContext(StepsContext);

    const {
        setNewDomicilioTitularData
    } = useContext(StepFiveContext);

    const [viewModel, setViewModel] = useState<DomicilioTitularInterface>(DomicilioTitularInterfaceDefault);

    useEffect(() => {
        if (viewModelStepOneFinal !== null) {
            setViewModel({
                ...viewModel,
                nombreVia: viewModelStepOneFinal.newTitularData.NombreVia,
                numDomicilio: viewModelStepOneFinal.newTitularData.NumeroDomicilio,
                intDptoPiso: viewModelStepOneFinal.newTitularData.IntDptoPiso,
                manzana: viewModelStepOneFinal.newTitularData.Manzana,
                lote: viewModelStepOneFinal.newTitularData.Lote,
                nombreAgrupacion: viewModelStepOneFinal.newTitularData.NombreAgrupacion,
                referencia: viewModelStepOneFinal.newTitularData.Referencia,
                identTipoVivienda: viewModelStepOneFinal.newTitularData.TipoViviendaId,
                identTiempoResidencia: viewModelStepOneFinal.newTitularData.TiempoResidenciaId,
            });
        }
    }, [viewModelStepOneFinal]);

    useEffect(() => {
        if (viewModelStepFiveFinal !== null) {
            const { newDomicilioTitularData } = viewModelStepFiveFinal;
            setViewModel(newDomicilioTitularData);
        }
    }, [viewModelStepFiveFinal]);

    useEffect(() => {
        if (clickNext !== 0 && step === 5) {
            setNewDomicilioTitularData(viewModel);
        }
    }, [clickNext]);

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
            <TitleCard title={"DOMICILIO TITULAR"} obligatorio={false}/>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"NombreVia"}
                        title={"Nombre de vía"}
                        required={false}
                        placeholder=""
                        value={viewModel.nombreVia}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, nombreVia: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"numDomicilio"}
                        title={"Número"}
                        required={false}
                        placeholder=""
                        value={viewModel.numDomicilio}
                        disabled={readonlyForm}
                        longNumber={5}
                        onChange={(event: any) => setViewModel({ ...viewModel, numDomicilio: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"IntDptoPiso"}
                        required={false}
                        title={"Int/Dpto/Piso"}
                        placeholder=""
                        value={viewModel.intDptoPiso}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, intDptoPiso: event.target.value })}

                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"manzana"}
                        required={false}
                        title={"Manzana"}
                        placeholder=""
                        value={viewModel.manzana}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, manzana: event.target.value })}

                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"lote"}
                        required={false}
                        title={"Lote"}
                        placeholder=""
                        value={viewModel.lote}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, lote: event.target.value })}

                    />
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"nombreAgrupacion"}
                        title={"Nombre agrupación"}
                        required={false}
                        placeholder=""
                        value={viewModel.nombreAgrupacion}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, nombreAgrupacion: event.target.value })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"referencia"}
                        required={false}
                        title={"Referencia"}
                        placeholder=""
                        value={viewModel.referencia}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, referencia: event.target.value })}

                    />

                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identTipoVivienda"}
                        required={false}
                        title={"Tipo de vivienda"}
                        options={listTipoVivienda}
                        value={viewModel.identTipoVivienda}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identTipoVivienda: newValue })}
                    />
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identTiempoResidencia"}
                        required={false}
                        title={"Tiempo de residencia"}
                        options={listTiempoResidencia}
                        value={viewModel.identTiempoResidencia}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identTiempoResidencia: newValue })}
                    />
                </div>
            </div>
        </div>
    );
};

export default DomicilioTitularCard;