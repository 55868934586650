import React, { useEffect, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { Button, Table } from "@mui/joy";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { IconButton, Tooltip } from "@mui/material";
import GeneralHelper from "../../../helpers/GeneralHelper";
import { DateInput, NumberInput, SelectInput, SimpleInput, TextInput } from "../15.10.-base/elements/inputs";
import { ObtenerListas } from "../15.10.-base/obtenerServicios";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SimbolAdd from "@mui/icons-material/Add";
import Save from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";
import { appConfigTipoPersonaTercera, appDefaultValues, appSecurityConfiguration } from "../../../../config/Config";
import TimeHelper from "../../../helpers/TimeHelper";
import RequestManager from "../../../services/origination/wapirequest.service";
import { Dropdown } from "react-bootstrap";
import CustomDivToggle from "../15.10.-base/elements/customDivToggle/CustomDivToggle";
import { BsThreeDots, BsSearch } from "react-icons/bs";
import { toast } from "sonner";
import { InformeComercialPersona, InformeComercialPersonaDefault } from "../15.10.-base/interfaces";
import { AxiosError } from "axios";

export const Representantes = (props: any) => {
  const [listTipoDocumento, setListTipoDocumento] = useState<any[]>([]);
  const [listPaises, setListPaises] = useState<any[]>([]);
  const [listEstadoCivil, setListEstadoCivil] = useState([]);
  const [listProfesion, setListProfesion] = useState<any[]>([]);
  const [listGenero, setListGenero] = useState([]);
  const [dataRepresentantes, setDataRepresentantes] = useState<InformeComercialPersona>(InformeComercialPersonaDefault);
  const [dataListRepresentantes, setDataListRepresentantes] = useState<InformeComercialPersona[]>([]);
  const [chkRequerido, setChkRequerido] = React.useState<boolean>(false);
  const [flgShow, setFlgShow] = useState<boolean>(false);
  const [longNumber, setLongNumber] = useState<any>(8);
  
  const InitListas = async () => {
    await ObtenerListas.TipoDocumento().then(response => {
        setListTipoDocumento(response);
    });
    await ObtenerListas.Pais().then(response =>{
        setListPaises(response)
    });
    await ObtenerListas.EstadoCiviles().then(response =>{
        setListEstadoCivil(response)
    });
    await ObtenerListas.Profesion().then(response =>{
        setListProfesion(response)
    });
    await ObtenerListas.IdentidadesGenero().then(response =>{
      setListGenero(response);
  });
};
const onClickNuevo = () => {
    setFlgShow(true);
    setDataRepresentantes(InformeComercialPersonaDefault);
}
const onClickCancelar = () => {
    setFlgShow(false);
    setDataRepresentantes(InformeComercialPersonaDefault);
}
const GuardarRepresentantes = async () => {
    // const idGenero = dataRepresentantes.idGenero.trim();
    let resultado;
    let bodyInsert = {
        "identInformeComercial": dataRepresentantes.identInformeComercial == 0 ? props.dataInformeComercial.identInformeComercial : dataRepresentantes.identInformeComercial,
        "identPersona": dataRepresentantes.identPersona == null ? 0 : dataRepresentantes.identPersona,
        "idTipoDocumento": dataRepresentantes.idTipoDocumento,
        "descTipoDocumento": dataRepresentantes.descTipoDocumento,
        "nroDocumento": dataRepresentantes.nroDocumento,
        "rucPnn": dataRepresentantes.rucPnn,
        "razonSocial": dataRepresentantes.razonSocial,
        "nombres": dataRepresentantes.nombres,
        "apellidoPaterno": dataRepresentantes.apellidoPaterno,
        "apellidoMaterno": dataRepresentantes.apellidoMaterno,
        "idEstadoCivil": dataRepresentantes.idEstadoCivil,
        "descEstadoCivil": dataRepresentantes.descEstadoCivil,
        "fechaNacimiento": dataRepresentantes.fechaNacimiento,
        "idGenero": dataRepresentantes.idGenero,
        "descGenero": dataRepresentantes.descGenero,
        "idPaisNacimiento": dataRepresentantes.idPaisNacimiento,
        "descPaisNacimiento": dataRepresentantes.descPaisNacimiento,
        "idPaisResidencia": dataRepresentantes.idPaisResidencia,
        "descPaisResidencia": dataRepresentantes.descPaisResidencia,
        "idDistrito": dataRepresentantes.idDistrito,
        "descDistrito": dataRepresentantes.descDistrito,
        "direccion": dataRepresentantes.direccion,
        "telefono1": dataRepresentantes.telefono1,
        "telefono2": dataRepresentantes.telefono2,
        "correo": dataRepresentantes.correo,
        "idProfesion": dataRepresentantes.idProfesion,
        "descProfesion": dataRepresentantes.descProfesion,
        "idCargo": dataRepresentantes.idCargo,
        "descCargo": dataRepresentantes.descCargo,
        "idTipoPersonaInforme": appConfigTipoPersonaTercera.PersonaRepresentanteLegal,
        "descTipoPersonaInforme": appConfigTipoPersonaTercera.DescPersonaRepresentanteLegal,
        // "aporteSoles": dataRepresentantes.aporteSoles,
        // "aportePorcentaje": dataRepresentantes.aportePorcentaje,
        "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
    }
    let bodyUpdate = {
        "identInformeComercialPersona": dataRepresentantes.identInformeComercialPersona,
        "identInformeComercial": dataRepresentantes.identInformeComercial == 0 ? props.dataInformeComercial.identInformeComercial : dataRepresentantes.identInformeComercial,
        "identPersona": dataRepresentantes.identPersona == null ? 0 : dataRepresentantes.identPersona,
        "idTipoDocumento": dataRepresentantes.idTipoDocumento,
        "descTipoDocumento": dataRepresentantes.descTipoDocumento,
        "nroDocumento": dataRepresentantes.nroDocumento,
        "rucPnn": dataRepresentantes.rucPnn,
        "razonSocial": dataRepresentantes.razonSocial,
        "nombres": dataRepresentantes.nombres,
        "apellidoPaterno": dataRepresentantes.apellidoPaterno,
        "apellidoMaterno": dataRepresentantes.apellidoMaterno,
        "idEstadoCivil": dataRepresentantes.idEstadoCivil,
        "descEstadoCivil": dataRepresentantes.descEstadoCivil,
        "fechaNacimiento": dataRepresentantes.fechaNacimiento,
        "idGenero": dataRepresentantes.idGenero,
        "descGenero": dataRepresentantes.descGenero,
        "idPaisNacimiento": dataRepresentantes.idPaisNacimiento,
        "descPaisNacimiento": dataRepresentantes.descPaisNacimiento,
        "idPaisResidencia": dataRepresentantes.idPaisResidencia,
        "descPaisResidencia": dataRepresentantes.descPaisResidencia,
        "idDistrito": dataRepresentantes.idDistrito,
        "descDistrito": dataRepresentantes.descDistrito,
        "direccion": dataRepresentantes.direccion,
        "telefono1": dataRepresentantes.telefono1,
        "telefono2": dataRepresentantes.telefono2,
        "correo": dataRepresentantes.correo,
        "idProfesion": dataRepresentantes.idProfesion,
        "descProfesion": dataRepresentantes.descProfesion,
        "idCargo": dataRepresentantes.idCargo,
        "descCargo": dataRepresentantes.descCargo,
        "idTipoPersonaInforme": appConfigTipoPersonaTercera.PersonaRepresentanteLegal,
        "descTipoPersonaInforme": appConfigTipoPersonaTercera.DescPersonaRepresentanteLegal,
        // "aporteSoles": dataRepresentantes.aporteSoles,
        // "aportePorcentaje": dataRepresentantes.aportePorcentaje,
        "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
    }

    try {
      const response = dataRepresentantes.identInformeComercialPersona == 0 ? 
      await RequestManager.MnInformeComercialPersonaRegistroInsert(bodyInsert) : 
      await RequestManager.MnInformeComercialPersonaRegistroUpdate(bodyUpdate);

      if (response.status === 200) {
        resultado = response.data.isValid;
        if (resultado === true) {
          if(dataRepresentantes.identInformeComercialPersona == 0)
              {toast.success("Registro guardado correctamente", { duration: 5000 });}
          else{toast.success("Registro actualizado correctamente.", { duration: 5000 });}
        }
        else{
          toast.error("Ocurrió un error", { duration: 5000 });
        }
      };
      CargaInicial();

      setFlgShow(false);
      setDataRepresentantes(InformeComercialPersonaDefault);      
    } catch (error: AxiosError | any) {            
      if (error.response && error.response.data.status === 400 && error.response.data.errors) {
          const validationErrors = error.response.data.errors;
          if (validationErrors) {
              for (const [field, messages] of Object.entries(validationErrors)) {                            
                  let message = messages as string[]; // Type assertion
                  toast.error(`${field}: ${message}`, { duration: 5000 });
              }
          } else {
              toast.error("Ocurrió un error", { duration: 5000 });
          }
      }
  }           
}

const CargaInicial = async () => {
    const response = await RequestManager.GetInformeComercialPersonaRegistro({ "identInformeComercial": props.dataInformeComercial.identInformeComercial, 
                                                                        "idTipoPersonaInforme": appConfigTipoPersonaTercera.PersonaRepresentanteLegal });
    if (response.status === 200) {
      setDataListRepresentantes(response.data.content);
    }
}

const handleClickViewDataDirectorio = async (value: any) =>  {
    setFlgShow(true);
    const response = await RequestManager.GetMnInformeComercialPersonaRegistroById({ "identInformeComercialPersona": value });
    if (response.status === 200) {
        setDataRepresentantes(response.data.content);
    }
}

const handleClickDeleteDirectorio = async (value: any) =>  {
    setFlgShow(false);
    setDataRepresentantes(InformeComercialPersonaDefault);
    const response = await RequestManager.MnInformeComercialPersonaAccionistaRegistroDelete({ "identInformeComercialPersona": value });
    if (response.status === 200) {
        console.log(response);
        setDataListRepresentantes(response.data.content);
        CargaInicial();
    }
}

const changeValueTipoDocumento = (e: any) => {
    let id = e.target.value as number;
    let text = '';
    if (id > 0) {
      for (let i = 0; i < listTipoDocumento.length; i++) {
        if (listTipoDocumento[i].id === id) {
            text = listTipoDocumento[i].description;
            break;
        }
      }
    }
    setDataRepresentantes({ ...dataRepresentantes, idTipoDocumento: id, descTipoDocumento: text,nroDocumento: appDefaultValues.StringEmpty });
    if(id === 172 || id === 173){
      setLongNumber(12);
    }
    else if(id === 174){
      setLongNumber(20);
    }
    else{
      setLongNumber(8);
    }
}

const changeValuePaisNacimiento = (e: any) => {
    let id = e.target.value as number;
    let text = '';
    if (id > 0) {
      for (let i = 0; i < listPaises.length; i++) {
        if (listPaises[i].id === id) {
            text = listPaises[i].description;
            break;
        }
      }
    }
    setDataRepresentantes({ ...dataRepresentantes, idPaisNacimiento: id, descPaisNacimiento: text});
}

const changeValuePaisResidencia = (e: any) => {
    let id = e.target.value as number;
    let text = '';
    if (id > 0) {
      for (let i = 0; i < listPaises.length; i++) {
        if (listPaises[i].id === id) {
            text = listPaises[i].description;
            break;
        }
      }
    }
    setDataRepresentantes({ ...dataRepresentantes, idPaisResidencia: id, descPaisResidencia: text});
}

const changeValueCargo = (e: any) => {
  let id = e.target.value as number;
  let text = '';
  if (id > 0) {
    for (let i = 0; i < listProfesion.length; i++) {
      if (listProfesion[i].id === id) {
          text = listProfesion[i].description;
          break;
      }
    }
  }
  setDataRepresentantes({ ...dataRepresentantes, idCargo: id, descCargo: text});
}
  
  useEffect(() => {
    CargaInicial();
    InitListas();
}, [props]);
    
    return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>XI. REPRESENTANTES LEGALES</AccordionSummary>
            <AccordionDetails>
              <>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets align-top">
                        <Table borderAxis="none" className="style-table align-top">
                            <thead>
                                <tr>
                                <th style={{ fontSize: "12px", width: "10%", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>TIPO DOCUMENTO</th>
                                <th style={{ fontSize: "12px", width: "10%", backgroundColor: "#E1E1E1", }}>NRO DOCUMENTO</th>
                                <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>RUC</th>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>NOMBRE O RAZÓN SOCIAL</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>APELLIDO PATERNO</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>APELLIDO MATERNO</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>PAÍS DE NACIMIENTO</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>PAÍS DE RESIDENCIA</th>
                                <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>CARGO</th>
                                <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>ESTADO</th>
                                <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>ACCIONES</th>
                                {/* <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>APORTE %</th> */}
                                </tr>
                            </thead>
                        <tbody>
                        {
                            dataListRepresentantes.length > 0 && dataListRepresentantes.map((row: any, index: number) => {
                            return (
                            <tr key={row.identInformeComercialPersona}>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descTipoDocumento}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.nroDocumento}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.rucPnn}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {(row.idTipoDocumento === 174 ? row.razonSocial : row.nombres)}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.apellidoPaterno}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.apellidoMaterno}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descPaisNacimiento}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descPaisResidencia}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descCargo}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.estado}</td>
                            {/* <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.AportePorcentaje}</td> */}
                            <td>
                                <Dropdown key={row.identInformeComercialPersona}>
                                  <Dropdown.Toggle
                                    as={CustomDivToggle}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <BsThreeDots />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                          onClick={(e: any) =>
                                            handleClickViewDataDirectorio(
                                              row.identInformeComercialPersona
                                            )
                                          }
                                        >
                                          Editar
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                          onClick={(e: any) =>
                                            handleClickDeleteDirectorio(
                                              row.identInformeComercialPersona
                                            )
                                          }
                                          disabled={props.isDisabled}
                                          >
                                          Eliminar
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                            </td>
                            </tr>
                                )
                            })
                        }
                        </tbody>
                        </Table>
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets bottom-right">
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<SimbolAdd />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            // disabled={props.disabled}
                            disabled={props.isDisabled}
                            onClick={onClickNuevo}>Nuevo
                        </Button>
                    </div>
                </div>   
              { flgShow && <div>     
                  <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-2 cardObjets">
                      <FormLabel>Requerido</FormLabel>
                      <Switch
                        color={chkRequerido ? "success" : "neutral"}
                        checked={chkRequerido}
                        disabled={props.isDisabled}
                        onChange={(event: any) => {
                          setChkRequerido(event.target.checked);
                        }}
                        slotProps={{
                          track: {
                            children: (
                              <React.Fragment>
                                <Typography
                                  component="span"
                                  level="inherit"
                                  sx={{ ml: "10px" }}
                                >
                                  Si
                                </Typography>
                                <Typography
                                  component="span"
                                  level="inherit"
                                  sx={{ mr: "8px" }}
                                >
                                  No
                                </Typography>
                              </React.Fragment>
                            ),
                          },
                        }}
                        sx={{
                          "--Switch-thumbSize": "27px",
                          "--Switch-trackWidth": "64px",
                          "--Switch-trackHeight": "31px",
                        }}
                      />
                    </div>
                    <div className="col-lg-2 cardObjets">
                      <SelectInput
                          title={"Tipo de documento"}
                          options={listTipoDocumento}
                          value={dataRepresentantes.idTipoDocumento}
                          disabled={!chkRequerido || props.isDisabled}
                          onChange={(event: any) => {
                            changeValueTipoDocumento(event);
                            // setDataRepresentantes({ ...dataRepresentantes, TipoDocumento: event.target.value, NumeroDocumento: appDefaultValues.StringEmpty });
                        }}
                      />
                    </div>
                    {(dataRepresentantes.idTipoDocumento === 172 || dataRepresentantes.idTipoDocumento === 173) ?
                        <div className="col-lg-2 cardObjets">
                            <SimpleInput
                                title={"Nro. documento"}
                                placeholder=""
                                longNumber={longNumber}
                                required={true}
                                value={dataRepresentantes.nroDocumento.trim()}
                          disabled={!chkRequerido || props.isDisabled}
                          onChange={(event: any) => {
                                    setDataRepresentantes({ ...dataRepresentantes, nroDocumento: event.target.value});
                                }}
                        />
                        </div>
                        :
                        <div className="col-lg-2 cardObjets">
                        <NumberInput
                            title={"Nro. documento"}
                            placeholder=""
                            longNumber={longNumber}
                            required={true}
                            value={dataRepresentantes.nroDocumento.trim()}
                          disabled={!chkRequerido || props.isDisabled}
                          onChange={(event: any) => {
                                setDataRepresentantes({ ...dataRepresentantes, nroDocumento: event.target.value});
                            }}
                        />
                        </div>
                    }
                  <div className="col-lg-2 cardObjets">
                    <NumberInput
                        title={"RUC PNN"}
                        placeholder=""
                        value={dataRepresentantes.rucPnn}
                        longNumber={20}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          setDataRepresentantes({ ...dataRepresentantes, rucPnn: event.target.value });
                      }}
                    />
                  </div>
                    <div className="col-lg-4 cardObjets">
                      <SimpleInput
                          title={"Nombre / Razón Social"}
                          placeholder="Ingresar nombre"
                          value={dataRepresentantes.idTipoDocumento === 174 ? dataRepresentantes.razonSocial : dataRepresentantes.nombres}
                          disabled={!chkRequerido || props.isDisabled}
                          onChange={(event: any) => {
                            if(dataRepresentantes.idTipoDocumento === 174){
                              setDataRepresentantes({ ...dataRepresentantes, razonSocial: event.target.value, nombres: "-"});
                            }
                            else{
                              setDataRepresentantes({ ...dataRepresentantes, nombres: event.target.value, razonSocial: "-"});
                            }
                        }}
                      />
                    </div>
                  </div>
                  <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-4 cardObjets">
                    <TextInput
                        title={"Apellido Paterno"}
                        placeholder="Ingresar apellido"
                        value={dataRepresentantes.apellidoPaterno}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          setDataRepresentantes({ ...dataRepresentantes, apellidoPaterno: event.target.value });
                      }}
                    />
                  </div>
                  <div className="col-lg-4 cardObjets">
                    <TextInput
                        title={"Apellido Materno"}
                        placeholder="Ingresar apellido"
                        value={dataRepresentantes.apellidoMaterno}                        
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          setDataRepresentantes({ ...dataRepresentantes, apellidoMaterno: event.target.value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2 cardObjets">
                    <SelectInput
                        title={"Estado Civil"}
                        options={listEstadoCivil}
                        value={dataRepresentantes.idEstadoCivil}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          setDataRepresentantes({ ...dataRepresentantes, idEstadoCivil: event.target.value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2 cardObjets">
                    <DateInput
                        title={"Fecha de nacimiento"}
                        value={dataRepresentantes.fechaNacimiento}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(date: any | null) => setDataRepresentantes({ ...dataRepresentantes, fechaNacimiento: date === null ? '' : TimeHelper.ObtenerFormatoFechaAmericanaDayJs(date) })}
                    />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-4 cardObjets">
                      <SelectInput
                          title={"Género"}
                          options={listGenero}
                          value={dataRepresentantes.idGenero}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                            setDataRepresentantes({ ...dataRepresentantes, idGenero: event.target.value});
                        }}
                      />
                    </div>
                    <div className="col-lg-4 cardObjets">
                      <SelectInput
                          title={"País de nacimiento"}
                          options={listPaises}
                          value={dataRepresentantes.idPaisNacimiento}
                          disabled={!chkRequerido || props.isDisabled}
                          onChange={(event: any) => {
                            changeValuePaisNacimiento(event);
                        }}
                      />
                    </div>
                    <div className="col-lg-4 cardObjets">
                      <SelectInput
                          title={"País de residencia"}
                          options={listPaises}
                          value={dataRepresentantes.idPaisResidencia}
                          disabled={!chkRequerido || props.isDisabled}
                          onChange={(event: any) => {
                            changeValuePaisResidencia(event);
                        }}
                      />
                    </div>
                  </div>
                  <div className="row g-2 mb-2 align-items-center">
                      <div className="col-lg-4 cardObjets">
                          <SimpleInput
                              title={"Distrito"}
                              placeholder="Ejm. Av. La Marina"
                              value={dataRepresentantes.descDistrito}
                              disabled={!chkRequerido || props.isDisabled}
                              onChange={(event: any) => setDataRepresentantes({ ...dataRepresentantes, descDistrito: event.target.value })}
                          />
                      </div>
                      <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            title={"Dirección"}
                            placeholder="Ejm. Av. La Marina"
                            value={dataRepresentantes.direccion}
                            disabled={!chkRequerido || props.isDisabled}
                            onChange={(event: any) => {
                              setDataRepresentantes({ ...dataRepresentantes, direccion: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-lg-2 cardObjets">
                        <NumberInput
                            title={"Teléfono 1"}
                            placeholder=""
                            longNumber={9}
                            required={true}
                            value={dataRepresentantes.telefono1}
                            disabled={!chkRequerido || props.isDisabled}
                            onChange={(event: any) => {
                              setDataRepresentantes({ ...dataRepresentantes, telefono1: event.target.value});
                          }}
                        />
                      </div>
                      <div className="col-lg-2 cardObjets">
                        <NumberInput
                            title={"Teléfono 2"}
                            placeholder=""
                            longNumber={9}
                            required={true}
                            value={dataRepresentantes.telefono2}
                            disabled={!chkRequerido || props.isDisabled}
                            onChange={(event: any) => {
                              setDataRepresentantes({ ...dataRepresentantes, telefono2: event.target.value});
                          }}
                        />
                      </div>
                  </div>
                  <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-4 cardObjets">
                      <SimpleInput
                          title={"Correo"}
                          placeholder="Ingresar email"
                          value={dataRepresentantes.correo}
                          disabled={!chkRequerido || props.isDisabled}
                          onChange={(event: any) => {
                            setDataRepresentantes({ ...dataRepresentantes, correo: event.target.value});
                        }}
                      />
                    </div>
                    <div className="col-lg-4 cardObjets">
                      <SelectInput
                          title={"Profesión"}
                          options={listProfesion}
                          value={dataRepresentantes.idProfesion}
                          disabled={!chkRequerido || props.isDisabled}
                          onChange={(event: any) => {
                            setDataRepresentantes({ ...dataRepresentantes, idProfesion: event.target.value});
                        }}
                      />
                  </div>
                  <div className="col-lg-4 cardObjets">
                    <SelectInput
                        title={"Cargo"}
                        options={listProfesion}
                        value={dataRepresentantes.idCargo}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          changeValueCargo(event);
                    }}
                    />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center ">
                  <div className="col-lg-12 cardObjets bottom-right">
                          <Button
                              size="md"
                              color="neutral"
                              variant="outlined"
                              endDecorator={<Save />}
                              sx={{ borderRadius: 24, width: 1/8 }}
                              onClick={GuardarRepresentantes}
                              disabled={!chkRequerido || props.isDisabled}
                              >Guardar
                          </Button>
                          <Button
                              size="md"
                              color="neutral"
                              variant="outlined"
                              endDecorator={<Cancel />}
                              sx={{ borderRadius: 24, width: 1/8 }}
                              // disabled={!chkRequerido}
                              onClick={onClickCancelar}
                              >Cancelar
                          </Button>
                  </div>
              </div>
              </div>
              }
              </>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>


   
    </div>
  );
};

export default Representantes;