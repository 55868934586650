import { appDefaultValues } from '../../../config/Config';

export interface Solicitud {
    idSolicitud: string,
    nombreTitular: string,
    numeroDocumento: string,
    nombreEjecutivo: string,
    nombreAnalista: string,
    sustento: string,
    observacion: string,
    idDestinoCredito: number,
    idPersonaCompraPara: number,
    idEstadoCivilTitular: number,
    idOperacion: number,
    tipoCambio: number,
    esPendienteEvaluar: boolean,
    idEstadoSolicitud: number,
    idProducto: number,
    montoTotalFinanciamiento: number,
    usuarioExcepcion: string,
    fechaExcepcion: string,
    identSolicitudExcepcion: number,
    identRegimen: number | null,
    esTitularJuridico: boolean,
}

export const SolicitudDefault: Solicitud = {
    idSolicitud: appDefaultValues.StringEmpty,
    nombreTitular: appDefaultValues.StringEmpty,
    numeroDocumento: appDefaultValues.StringEmpty,
    nombreEjecutivo: appDefaultValues.StringEmpty,
    nombreAnalista: appDefaultValues.StringEmpty,
    observacion: appDefaultValues.StringEmpty,
    sustento: appDefaultValues.StringEmpty,
    idDestinoCredito: appDefaultValues.NumberDefault,
    idPersonaCompraPara: appDefaultValues.NumberDefault,
    idEstadoCivilTitular: appDefaultValues.NumberDefault,
    idOperacion: appDefaultValues.NumberDefault,
    tipoCambio: appDefaultValues.NumberDefault,
    esPendienteEvaluar: appDefaultValues.BooleanDefault,
    idEstadoSolicitud: appDefaultValues.NumberDefault,
    idProducto: appDefaultValues.NumberDefault,
    montoTotalFinanciamiento: appDefaultValues.NumberDefault,
    usuarioExcepcion: appDefaultValues.StringEmpty,
    fechaExcepcion: appDefaultValues.StringEmpty,
    identSolicitudExcepcion: appDefaultValues.NumberDefault,
    identRegimen: appDefaultValues.NullDefault,
    esTitularJuridico: appDefaultValues.BooleanDefault,
}