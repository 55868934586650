import React, { useEffect, useState } from "react";
import { Button, Table } from "@mui/joy";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { MoneyInput, NumberDecimalInput, NumberInput, SimpleInput, TextInput } from "../15.10.-base/elements/inputs";
import SimbolAdd from "@mui/icons-material/Add";
import Save from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";
import RequestManager from "../../../services/origination/wapirequest.service";
import { Dropdown } from "react-bootstrap";
import CustomDivToggle from "../15.10.-base/elements/customDivToggle/CustomDivToggle";
import { BsThreeDots, BsSearch } from "react-icons/bs";
import { toast } from "sonner";
import { DatosPropiedades, DatosPropiedadesDefault } from "../15.10.-base/interfaces";
import { appSecurityConfiguration } from "../../../../config/Config";
import { AxiosError } from "axios";
import GeneralHelper from "../../../helpers/GeneralHelper";

export const PropiedadesAuditor = (props: any) => {
  const [dataPropiedades, setDataPropiedades] = useState<DatosPropiedades>(DatosPropiedadesDefault);
  const [dataListProduccion, setDataListPropiedades] = useState<DatosPropiedades[]>([]);  
  const [flgShow, setFlgShow] = useState<boolean>(false);
  
  const onClickNuevo = () => {
    setFlgShow(true);
    setDataPropiedades(DatosPropiedadesDefault);
  }

  const handleClickViewDataPropiedades = async (value: any) =>  {
    setFlgShow(true);
    const response = await RequestManager.GetMnInformeComercialMaquinariasById({ "identInformeComercialMaquinarias": value });
    if (response.status === 200) {
        setDataPropiedades(response.data.content);
    }
}

const handleClickDeletePropiedades = async (value: any) =>  {
    setFlgShow(false);
    setDataPropiedades(DatosPropiedadesDefault);
    const response = await RequestManager.MnInformeComercialMaquinariasDelete({ "identInformeComercialMaquinarias": value });
    if (response.status === 200) {
        console.log(response);
        setDataPropiedades(response.data.content);
        CargaInicial();
    }
}

const CargaInicial = async () => {
    const response = await RequestManager.GetInformeComercialMaquinarias({ "identInformeComercial": props.dataInformeComercial.identInformeComercial });
    if (response.status === 200) {
        setDataListPropiedades(response.data.content);
    }
}

const onClickCancelar = () => {
    setFlgShow(false);
    setDataPropiedades(DatosPropiedadesDefault);
}

const GuardarPropiedades = async() => {
    let resultado;
    let bodyInsert = {
        "identInformeComercial": dataPropiedades.identInformeComercial == 0 ? props.dataInformeComercial.identInformeComercial : dataPropiedades.identInformeComercial,
        "descripcionUbicacion": dataPropiedades.descripcionUbicacion,
        "propioAlquiladoLeasing": dataPropiedades.propioAlquiladoLeasing,
        "valorComercial": GeneralHelper.QuitarComasAMiles(dataPropiedades.valorComercial),
        "gravamenMontoDolares": GeneralHelper.QuitarComasAMiles(dataPropiedades.gravamenMontoDolares),
        "gravamenEntidad": dataPropiedades.gravamenEntidad,
        "aniosAntiguedad": dataPropiedades.aniosAntiguedad,
        "aniosVidaUtil": dataPropiedades.aniosVidaUtil,
        "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
    }
    let bodyUpdate = {
        "identInformeComercialMaquinarias": dataPropiedades.identInformeComercialMaquinarias,
        "identInformeComercial": dataPropiedades.identInformeComercial,
        "descripcionUbicacion": dataPropiedades.descripcionUbicacion,
        "propioAlquiladoLeasing": dataPropiedades.propioAlquiladoLeasing,
        "valorComercial": GeneralHelper.QuitarComasAMiles(dataPropiedades.valorComercial),
        "gravamenMontoDolares": GeneralHelper.QuitarComasAMiles(dataPropiedades.gravamenMontoDolares),
        "gravamenEntidad": dataPropiedades.gravamenEntidad,
        "aniosAntiguedad": dataPropiedades.aniosAntiguedad,
        "aniosVidaUtil": dataPropiedades.aniosVidaUtil,
        "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
    }

    try {
      const response = dataPropiedades.identInformeComercialMaquinarias == 0 ? 
      await RequestManager.MnInformeComercialMaquinariasInsert(bodyInsert) : 
      await RequestManager.MnInformeComercialMaquinariasUpdate(bodyUpdate);
      
      if (response.status === 200) {
        resultado = response.data.isValid;
        if (resultado === true) {
          if(dataPropiedades.identInformeComercialMaquinarias == 0)
              {toast.success("Registro guardado correctamente", { duration: 5000 });}
          else{toast.success("Registro actualizado correctamente.", { duration: 5000 });}
        }
        else{
          toast.error("Ocurrió un error", { duration: 5000 });
        }
      };
      CargaInicial();

      setFlgShow(false);
      setDataPropiedades(DatosPropiedadesDefault);      
    } catch (error: AxiosError | any) {            
      if (error.response && error.response.data.status === 400 && error.response.data.errors) {
          const validationErrors = error.response.data.errors;
          if (validationErrors) {
              for (const [field, messages] of Object.entries(validationErrors)) {                            
                  let message = messages as string[]; // Type assertion
                  toast.error(`${field}: ${message}`, { duration: 5000 });
              }
          } else {
              toast.error("Ocurrió un error", { duration: 5000 });
          }
      }
  }           
}

useEffect(() => {
  CargaInicial();
}, [props]);
  

    return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>VIII. PROPIEDADES, MAQUINARIAS E INSTALACIONES</AccordionSummary>
            <AccordionDetails>
              <>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets align-top">
                        <Table borderAxis="none" className="style-table align-top">
                            <thead>
                                <tr>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>DESCRIPCIÓN Y UBICACIÓN</th>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>PROPIO / ALQUILADO / LEASING</th>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>VALOR COMERCIAL US$</th>
                                <th style={{ fontSize: "12px", width: "13%", backgroundColor: "#E1E1E1", }}>GRÁVAMEN MONTO US$</th>
                                <th style={{ fontSize: "12px", width: "13%", backgroundColor: "#E1E1E1", }}>GRÁVAMEN ENTIDAD</th>
                                <th style={{ fontSize: "12px", width: "13%", backgroundColor: "#E1E1E1", }}>AÑOS DE ANTIGUEDAD</th>
                                <th style={{ fontSize: "12px", width: "13%", backgroundColor: "#E1E1E1", }}>AÑOS DE VIDA ÚTIL</th>
                                <th style={{ fontSize: "12px", width: "13%", backgroundColor: "#E1E1E1", }}>ESTADO</th>
                                <th style={{ fontSize: "12px", width: "13%", backgroundColor: "#E1E1E1", }}>ACCIONES</th>
                                </tr>
                            </thead>
                        <tbody>
                        {
                            dataListProduccion.length > 0 && dataListProduccion.map((row: any, index: number) => {
                            return (
                            <tr key={row.identInformeComercialProduccion}>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descripcionUbicacion}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.propioAlquiladoLeasing}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.AgregarComasAMiles(row.valorComercial)}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.AgregarComasAMiles(row.gravamenMontoDolares)}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.gravamenEntidad}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.aniosAntiguedad}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.aniosVidaUtil}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.estado}</td>
                            <td>
                              <Dropdown key={row.identInformeComercialMaquinarias}>
                                <Dropdown.Toggle
                                  as={CustomDivToggle}
                                  style={{ cursor: "pointer" }}
                                >
                                  <BsThreeDots />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickViewDataPropiedades(
                                            row.identInformeComercialMaquinarias
                                          )
                                        }
                                      >
                                        Editar
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickDeletePropiedades(
                                            row.identInformeComercialMaquinarias
                                          )
                                        }
                                      >
                                        Eliminar
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            </tr>
                            )
                            })
                        }
                        </tbody>
                        </Table>
                    </div>
                    <div className="row g-2 mb-2 align-items-center">
                        <div className="col-lg-12 cardObjets bottom-right">
                            <Button
                                size="md"
                                color="neutral"
                                variant="outlined"
                                endDecorator={<SimbolAdd />}
                                sx={{ borderRadius: 24, width: 1/8 }}
                                // disabled={props.disabled}
                                onClick={onClickNuevo}>Nuevo
                            </Button>
                        </div>
                    </div>
                </div>
              
              {flgShow && <div>        
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-4 cardObjets">
                      <SimpleInput
                          title={"Descripción y ubicación (Area total)"}
                          placeholder="Ingresar"
                          value={dataPropiedades.descripcionUbicacion}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataPropiedades({ ...dataPropiedades, descripcionUbicacion: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-4 cardObjets">
                      <SimpleInput
                          title={"Propio / Alquilado / Leasing"}
                          placeholder="Ingresar"
                          value={dataPropiedades.propioAlquiladoLeasing}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataPropiedades({ ...dataPropiedades, propioAlquiladoLeasing: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-4 cardObjets">
                      <NumberDecimalInput
                          title={"Valor Comercial $"}
                          placeholder="Ingresar"
                          value={GeneralHelper.AgregarComasAMiles(dataPropiedades.valorComercial)}
                          disabled={props.isDisabled}
                          onChange={(event: any) => setDataPropiedades({ ...dataPropiedades, valorComercial: event.target.value })}
                      />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-2 cardObjets">
                      <NumberDecimalInput
                          title={"Gravámen monto $"}
                          placeholder="Ingresar"
                          value={GeneralHelper.AgregarComasAMiles(dataPropiedades.gravamenMontoDolares)}
                          disabled={props.isDisabled}
                          onChange={(event: any) => setDataPropiedades({ ...dataPropiedades, gravamenMontoDolares: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-2 cardObjets">
                      <SimpleInput
                          title={"Gravamen entidad"}
                          placeholder="Ingresar"
                          value={dataPropiedades.gravamenEntidad}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataPropiedades({ ...dataPropiedades, gravamenEntidad: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-2 cardObjets">
                      <NumberInput
                          title={"Años antiguedad"}
                          placeholder="Ingresar"
                          value={dataPropiedades.aniosAntiguedad}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataPropiedades({ ...dataPropiedades, aniosAntiguedad: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-2 cardObjets">
                      <NumberInput
                          title={"Años vida útil"}
                          placeholder="Ingresar"
                          value={dataPropiedades.aniosVidaUtil}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataPropiedades({ ...dataPropiedades, aniosVidaUtil: event.target.value })}
                      />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center ">
                    <div className="col-lg-12 cardObjets bottom-right">
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<Save />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            onClick={GuardarPropiedades}
                            >Guardar
                        </Button>
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<Cancel />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            onClick={onClickCancelar}
                            >Cancelar
                        </Button>
                    </div>
                </div>
              </div>
              }
              </>
            </AccordionDetails>
        </Accordion>
      </AccordionGroup>


   
    </div>
  );
};

export default PropiedadesAuditor;