import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormLabel from "@mui/joy/FormLabel";

const CardMembers = (props: any) => {
  const { members } = props;

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-10 textLabelHeadboard">
          <span className="fw-bold">SELECCIONAR INTEGRANTE</span>
        </div>
        <div className="col-lg-4">
          <span className="fw-bold"> </span>
        </div>
        <div className="row g-2 mb-2 align-items-center">
          {
            members.users.map((row: any) => (
              <div className="row g-2 mb-2 align-items-center">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormLabel>{row.nombre}</FormLabel>
                    <FormLabel>{row.nroDocumento}</FormLabel>
                  </LocalizationProvider>
              </div>
            ))
          }
        </div>
      </div>
    </div>
  );
};

export default CardMembers;