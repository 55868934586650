import { appDefaultValues } from '../../../config/Config';

export interface DatosCondicionFinanciamiento {
 idmoneda: number,
 moneda: string,
 porcentajeInicial: number,
 comentario: string,
 cuota: number,
 tea: number,
 teaString: string,
 montoVehiculo: string,
 cuotaInicial: number,
 montoVehiculoBono: string,
 cuotaInicialBono: number,
 cuotaDoble: string
 producto: string,
 campania: string,
 ciPrecio: number,

 montoAsegurado: string,
 seguroVehicularEndosado: string,
 gps: string,
 tipoGps: string,
 gastosInclusionGps: string,
 gastosRegistrales: string,
 gastosNotariales: string,
 gastosDeliveryFirmas: string,
 concesionario: string,
 sucursal: string,
 marca: string,
 modelo: string,
 vendedor: string,
 indicadorCuotaDoble: boolean,
 cuotaBallon: number,
 ciPrecioDescripcion: string,
 montoCuotaBallon: number,
}

export const DatosCondicionFinanciamientoDefault: DatosCondicionFinanciamiento = {
 idmoneda: appDefaultValues.NumberDefault,
 moneda: appDefaultValues.StringEmpty,
 porcentajeInicial: appDefaultValues.NumberDefault,
 comentario: appDefaultValues.StringEmpty,
 cuota: appDefaultValues.NumberDefault,
 tea: appDefaultValues.NumberDefault,
 teaString: appDefaultValues.StringEmpty,
 montoVehiculo: appDefaultValues.StringEmpty,
 cuotaInicial: appDefaultValues.NumberDefault,
 montoVehiculoBono: appDefaultValues.StringEmpty,
 cuotaInicialBono: appDefaultValues.NumberDefault,
 cuotaDoble: appDefaultValues.StringEmpty,
 producto: appDefaultValues.StringEmpty,
 campania: appDefaultValues.StringEmpty,
 ciPrecio: appDefaultValues.NumberDefault,

 montoAsegurado: appDefaultValues.StringEmpty,
 seguroVehicularEndosado: appDefaultValues.StringEmpty,
 gps: appDefaultValues.StringEmpty,
 tipoGps: appDefaultValues.StringEmpty,
 gastosInclusionGps: appDefaultValues.StringEmpty,
 gastosRegistrales: appDefaultValues.StringEmpty,
 gastosNotariales: appDefaultValues.StringEmpty,
 gastosDeliveryFirmas: appDefaultValues.StringEmpty,
 concesionario: appDefaultValues.StringEmpty,
 sucursal: appDefaultValues.StringEmpty,
 marca: appDefaultValues.StringEmpty,
 modelo: appDefaultValues.StringEmpty,
 vendedor: appDefaultValues.StringEmpty,
 indicadorCuotaDoble: appDefaultValues.BooleanDefault,
 cuotaBallon: appDefaultValues.NumberDefault,
 ciPrecioDescripcion: appDefaultValues.StringEmpty,
 montoCuotaBallon: appDefaultValues.NumberDefault,
}