import { MnPersonaEmpresaAdicionalAuditDto } from "../../../../models/MasterManager/persona-empresa-datos-adicional-response.model";
import { DatosAdicionalesTitularEmpresaInterface, DatosAdicionalesTitularEmpresaInterfaceDefault } from "../components/DatosAdicionalesTitularEmpresaCard/interfaces";

export const ConvertidorHelper = {

    ObtenerDatosAdicionalesTitularEmpresa: (tempPersonaEmpresa: MnPersonaEmpresaAdicionalAuditDto | null | undefined) => {
        const tempData: DatosAdicionalesTitularEmpresaInterface = DatosAdicionalesTitularEmpresaInterfaceDefault;
        if (!tempPersonaEmpresa) return tempData;
        tempData.codSBS = tempPersonaEmpresa.codL_CODIGO_SBS;
        tempData.capitalSocialActual = tempPersonaEmpresa.monT_CAPITAL_SOCIAL_ACTUAL;
        tempData.patrimonio = tempPersonaEmpresa.monT_PATRIMONIO;
        tempData.patrimonioPersonal = tempPersonaEmpresa.monT_PATRIMONIO_PERSONA;
        tempData.sectorEconomico = tempPersonaEmpresa.descC_SECTOR_ECONOMICO;
        tempData.tipoCadenaProductiva = tempPersonaEmpresa.descC_TIPO_CADENA_PRODUCTIVA;
        tempData.actividadComplementaria = tempPersonaEmpresa.descC_ACTIVIDAD_COMPLEMENTARIA;

        return tempData;
    },

};