import { Component } from "react";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

interface IGesintelListDetailProps {
  objGesintelListDetailProps: {
    index: number;
    description: string;
    showNew: boolean;
  };
}

class GesintelListDetail extends Component<IGesintelListDetailProps, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const data = this.props.objGesintelListDetailProps;
    return (
      <>
        {data.index > 0 && <label className="align-middle">/</label>}
        <div className={data.index == 0 ? "pe-2" : "px-2"}>
          {data.showNew && <ReportProblemOutlinedIcon className="text-error me-2" fontSize="small" />}
          <label className="align-middle">{data.description}</label>
        </div>
      </>
    );

  }
}
export default GesintelListDetail;
