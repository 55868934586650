import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { ErrorComponent } from "../../1.-shared/1.1.-auth/1.1.1.-login/ErrorComponent";
import { Loading } from "../../1.-shared/1.1.-auth/1.1.1.-login/Loading";
import { InteractionType } from "@azure/msal-browser";
import { Toaster, toast } from "sonner";
import LoadingProgress from "../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainLayout from "../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout";
import CardTitleStart from "../../1.-shared/1.2.-layout/1.2.5-cardTitleStart/cardTitleStart";
import { DataSourceInterface } from "./interfaces/dataSourceInterface";
import { SelectInput } from "./elements/select";
import { General, ObtenerListas } from "./obtenerServicios";
import { appConfig, appConfigKey, appConfigPerfiles } from "../../../../config/Config";
import { NumberInput, SimpleInput } from "./elements/input";
import { ButtonGeneric, ButtonSearch } from "./elements/button";
import UserCarousel from "./components/UserCarousel";
import { Box, FormControl, FormLabel, IconButton, Sheet, Table, Typography } from "@mui/joy";
import EnhancedTableHead from "./components/EnhancedTableHead";
import { Order } from "./interfaces/enhancedTableInterfaces";
import tableUtilHelper from "./helpers/tableUtilHelper";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import './../14.10.-base/requestManagement.scss'
import ValidacionGestionSolicitudesHelper from "./helpers/validacionGestionSolicitudesHelper";

export default function TableRequestManagement() {

    let navigate = useNavigate();
    const [showLoading, setShowLoading] = useState(false);
    const [idPerfilSession, setIdPerfilSession] = useState<number>(0);
    const [dataSource, setDataSource] = useState<DataSourceInterface[]>([]);
    const authRequest = {
        scopes: ["openid", "profile"],
    };
    const [listaTipoDocumento, setListaTipoDocumento] = useState([]);
    const [listaEstado, setListaEstado] = useState([]);
    const [listaUsuarios, setListaUsuarios] = useState([]);
    const [listaEtapa, setListaEtapa] = useState([]);
    const [listaReasignar, setListaReasignar] = useState([]);
    const [viewModelBusqueda, setViewModelBusqueda] = useState({
        identSolicitud: 0,
        idTipoDocumento: 0,
        codlNumeroDocumento: '',
        desclNombreCompleto: '',
        idEstadoSolicitud: 0,
        idEtapa: 0,
        identUsuario: 0
    });
    const [viewModelReasignar, setViewModelReasignar] = useState({
        idEtapa: 0,
        identUsuarioReasignar: 0
    });
    const [selected, setSelected] = useState<readonly string[]>([]);
    const [order, setOrder] = useState<Order>("desc");
    const [orderBy, setOrderBy] = useState<keyof DataSourceInterface>("idSolicitud");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = dataSource.map((n: any) => n.id.toString());
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };

    const isSelected = (solicitud: number) => selected.indexOf(solicitud.toString()) !== -1;

    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof DataSourceInterface
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
        const selectedIndex = selected.indexOf(name.toString());
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name.toString());
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataSource.length) : 0;
    const getLabelDisplayedRowsTo = () => {
        if (dataSource.length === -1) {
            return (page + 1) * rowsPerPage;
        }
        return rowsPerPage === -1
            ? dataSource.length
            : Math.min(dataSource.length, (page + 1) * rowsPerPage);
    };

    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };
    const GetMnIdPerfil = async () => {
        const account = appConfig.msalInstance.getActiveAccount();
        if (![null, undefined].includes(account)) {
            const response = await ObtenerListas.ObtenerIdPerfil(account.username);
            if (response?.length > 0) {
                let idPerfil = response[0]?.idenT_PERFIL;
                setIdPerfilSession(idPerfil);
                if (idPerfil !== appConfigPerfiles.CodigoJefeCredito &&
                    idPerfil !== appConfigPerfiles.CodigoSupervisorCredito) {
                    navigate(`/error/404`);
                }
            } else {
                navigate(`/error/404`);
            }
        } else {
            navigate(`/error/404`);
        }
    }

    const ObtenerTipoDocumento = async () => {
        const response = await ObtenerListas.ObtenerTipoDocumento();
        if (response.length > 0) {
            setListaTipoDocumento(response);
        }
    }
    const ObtenerEstados = async () => {
        const response = await ObtenerListas.ObtenerEstados();
        if (response.length > 0) {
            setListaEstado(response.filter((x: any) => x.id === appConfigKey.keyIdEstadoSolicitudPorEvaluar ||
                x.id === appConfigKey.keyIdEstadoSolicitudEnEvaluacion ||
                x.id === appConfigKey.keyIdEstadoSolicitudPorEvaluarExcepcion ||
                x.id === appConfigKey.keyIdEstadoSolicitudEnEvaluacionExcepcion ||
                x.id === appConfigKey.keyIdEstadoSolicitudPorEvaluarAutonomia ||
                x.id === appConfigKey.keyIdEstadoSolicitudEnEvaluacionAutonomia ||
                x.id === appConfigKey.keyIdEstadoSolicitudPorValidar ||
                x.id === appConfigKey.keyIdEstadoSolicitudEnValidacion))
        }
    }
    const ConsultaUsuarioGestionSolicitudes = async () => {
        const response = await ObtenerListas.ConsultaUsuarioGestionSolicitudes();
        if (response.length > 0) {
            setListaUsuarios(response)
        }
    }

    const ObtenerEtapa = async () => {
        const response = await ObtenerListas.ObtenerEtapa();
        if (response.length > 0) {
            setListaEtapa(response)
        }
    }
    const ConsultaUsuariosReasignarGestionSolicitud = async (etapa: number) => {
        const response = await ObtenerListas.GetConsultaUsuariosReasignarGestionSolicitud(etapa);
        if (response.length > 0) {
            setListaReasignar(response)
        }
    }

    const UsuarioReasignarGestionSolicitud = async (data: any) => {
        const response = await General.GetUsuarioReasignarGestionSolicitud(data);
        if (response) {
            await limpiarReasignar();
            await limpiarBusqueda();
            await ConsultaUsuarioGestionSolicitudes();
            await CargarBandejaGestionSolicitudes();
            toast.success(appConfigKey.keyMsjReasignoExitoso, { duration: appConfigKey.keyDurationToast });
        } else {
            toast.error(appConfigKey.keyMsjErrorEnServicioGenerico.replace('{data}', 'GetUsuarioReasignarGestionSolicitud'), { duration: appConfigKey.keyDurationToast });
        }
    }

    const ConsultaBandejaGestionSolicitudes = async (identSolicitud: number,
        idTipoDocumento: number,
        codlNumeroDocumento: string,
        desclNombreCompleto: string,
        idEstadoSolicitud: number,
        idEtapa: number,
        identUsuario: number) => {
        const response = await ObtenerListas.GetConsultaBandejaGestionSolicitudes(identSolicitud,
            idTipoDocumento,
            codlNumeroDocumento,
            desclNombreCompleto,
            idEstadoSolicitud,
            idEtapa,
            identUsuario);

        if (response.length > 0) {
            setDataSource(response);
        }
    }


    const handlerBtnSearch = async () => {
        setShowLoading(true);
        await CargarBandejaGestionSolicitudes();
        setShowLoading(false);
    }

    const limpiarBusqueda = async () => {
        setViewModelBusqueda({
            ...viewModelBusqueda,
            identSolicitud: 0,
            idTipoDocumento: 0,
            codlNumeroDocumento: '',
            desclNombreCompleto: '',
            idEstadoSolicitud: 0,
            idEtapa: 0,
            identUsuario: 0
        })
    }

    const limpiarReasignar = async () => {
        setViewModelReasignar({
            ...viewModelReasignar,
            idEtapa: 0,
            identUsuarioReasignar: 0
        })
    }

    const handlerBtnReasignar = async () => {

        const ValidacionGestionSolicitudes =
            ValidacionGestionSolicitudesHelper.NewDataReasignar(
                viewModelReasignar,
                dataSource
            );

        if (!ValidacionGestionSolicitudes) return;


        let solicitudesReasignar: any = [];
        const newDataSource: any = dataSource.filter((x: any) => x?.seleccionado === 1)
        for (let item of newDataSource) {
            solicitudesReasignar.push({
                "identSolicitud": item.idSolicitud
            })
        }

        let dataRequest = {
            "solicitudesReasignar": solicitudesReasignar,
            "identUsuarioReasignar": viewModelReasignar.identUsuarioReasignar,
            "identUsuarioModif": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0)
        }

        setShowLoading(true);
        await UsuarioReasignarGestionSolicitud(dataRequest);
        setShowLoading(false);
    }

    const CargarBandejaGestionSolicitudesPorUsuario = async (identUsuario: number) => {
        setShowLoading(true);
        setDataSource([]);
        setPage(0);
        await ConsultaBandejaGestionSolicitudes(
            viewModelBusqueda.identSolicitud,
            viewModelBusqueda.idTipoDocumento,
            viewModelBusqueda.codlNumeroDocumento,
            viewModelBusqueda.desclNombreCompleto,
            viewModelBusqueda.idEstadoSolicitud,
            viewModelBusqueda.idEtapa,
            identUsuario);
        setShowLoading(false);
    }

    const CargarBandejaGestionSolicitudes = async () => {
        setDataSource([]);
        setPage(0);
        await ConsultaBandejaGestionSolicitudes(
            viewModelBusqueda.identSolicitud,
            viewModelBusqueda.idTipoDocumento,
            viewModelBusqueda.codlNumeroDocumento,
            viewModelBusqueda.desclNombreCompleto,
            viewModelBusqueda.idEstadoSolicitud,
            viewModelBusqueda.idEtapa,
            viewModelBusqueda.identUsuario);
    }

    const InitDefaultBandejaGestionSolicitud = async () => {
        try {
            setShowLoading(true);
            await limpiarReasignar();
            await limpiarBusqueda();
            await GetMnIdPerfil();
            await ObtenerTipoDocumento();
            await ObtenerEstados();
            await ConsultaUsuarioGestionSolicitudes();
            await ObtenerEtapa();
            await CargarBandejaGestionSolicitudes();
            setShowLoading(false);

        } catch {
            setShowLoading(false);
        }
    }

    useEffect(() => {
        InitDefaultBandejaGestionSolicitud();
    }, []);

    useEffect(() => {
        CargarBandejaGestionSolicitudes();
    }, [viewModelBusqueda]);

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.None}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}
        >
            <Toaster
                position="top-center"
                toastOptions={{ style: { width: '500px', margin: '0 auto' } }}
                richColors closeButton
            />
            {showLoading && <LoadingProgress />}
            <MainLayout />
            <CardTitleStart Ventana={"Gestión de Solicitudes"}></CardTitleStart>

            <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 card cardContentRequestManagement">
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-6 cardObjets">
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <SelectInput
                            value={viewModelReasignar.idEtapa}
                            name={"idEtapa"}
                            title={"Etapa"}
                            options={listaEtapa}
                            required={false}
                            onChange={(event: any, newValue: any) => {
                                setViewModelReasignar({
                                    ...viewModelReasignar, idEtapa: newValue
                                    , identUsuarioReasignar: 0
                                });
                                setListaReasignar([]);
                                if (newValue) {
                                    ConsultaUsuariosReasignarGestionSolicitud(newValue)
                                }
                            }}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <SelectInput
                            value={viewModelReasignar.identUsuarioReasignar}
                            name={"identUsuarioReasignar"}
                            title={"Reasignar a"}
                            options={listaReasignar}
                            required={false}
                            onChange={(event: any, newValue: any) => {
                                setViewModelReasignar({ ...viewModelReasignar, identUsuarioReasignar: newValue });
                            }}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <ButtonGeneric
                            label={'Reasignar'}
                            onClick={handlerBtnReasignar}
                        />
                    </div>
                </div>
            </div>
            <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 card cardContentRequestManagement">


                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets">
                        <UserCarousel
                            data={listaUsuarios}
                            viewModelBusqueda={viewModelBusqueda}
                            setViewModelBusqueda={
                                setViewModelBusqueda
                            }
                            CargarBandejaGestionSolicitudesPorUsuario={CargarBandejaGestionSolicitudesPorUsuario}
                        ></UserCarousel>
                    </div>
                </div>

                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-2 cardObjets">
                        <SelectInput
                            value={viewModelBusqueda.idTipoDocumento}
                            name={"idTipoDocumento"}
                            title={"Tipo de documento"}
                            options={listaTipoDocumento}
                            required={false}
                            selectTextDefault={'Solicitud'}
                            onChange={(event: any, newValue: any) => {
                                setViewModelBusqueda({ ...viewModelBusqueda, idTipoDocumento: newValue });
                            }}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <NumberInput
                            name={"codlNumeroDocumento"}
                            title={"N°. Documento"}
                            placeholder=""
                            value={viewModelBusqueda.idTipoDocumento > 0 ? viewModelBusqueda.codlNumeroDocumento : (viewModelBusqueda.identSolicitud > 0 ? viewModelBusqueda.identSolicitud : '')}
                            longNumber={11}
                            onChange={(event: any) => {
                                if (viewModelBusqueda.idTipoDocumento > 0) {
                                    setViewModelBusqueda({
                                        ...viewModelBusqueda,
                                        identSolicitud: 0,
                                        codlNumeroDocumento: event.target.value
                                    })
                                } else {
                                    setViewModelBusqueda({
                                        ...viewModelBusqueda,
                                        identSolicitud: Number(event.target.value),
                                        codlNumeroDocumento: ''
                                    })
                                }

                            }}
                            required={false}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <SimpleInput
                            name={"desclNombreCompleto"}
                            title={"Nombre / Razón Social"}
                            placeholder=""
                            value={viewModelBusqueda.desclNombreCompleto}
                            onChange={(event: any) => setViewModelBusqueda({ ...viewModelBusqueda, desclNombreCompleto: event.target.value })}
                            required={false}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <SelectInput
                            value={viewModelBusqueda.idEtapa}
                            name={"idEtapa"}
                            title={"Etapa"}
                            options={listaEtapa}
                            required={false}
                            onChange={(event: any, newValue: any) => {
                                setViewModelBusqueda({ ...viewModelBusqueda, idEtapa: newValue });
                            }}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <SelectInput
                            value={viewModelBusqueda.idEstadoSolicitud}
                            name={"identEstado"}
                            title={"Estado"}
                            required={false}
                            options={listaEstado}
                            onChange={(event: any, newValue: any) => {
                                setViewModelBusqueda({ ...viewModelBusqueda, idEstadoSolicitud: newValue });
                            }}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <ButtonSearch
                            onClick={handlerBtnSearch}
                        />
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets">
                        <div className="col-lg-4">
                            <span className="fw-bold">Total {dataSource.length}</span>
                        </div>
                        <div className="col-lg-4">
                            <span className="fw-bold"> </span>
                        </div>
                        <div className="col-lg-4 textAlignRigth"></div>

                        <div className="row g-2 mb-2">
                            <div className="col-lg-12 tableContainer">
                                <Sheet
                                    variant="outlined"
                                    sx={{ width: "100%", boxShadow: "sm", borderRadius: "sm" }}
                                >
                                    <Table
                                        className="style-table"
                                        aria-labelledby="tableTitle"
                                        hoverRow
                                        sx={{
                                            "--TableCell-headBackground": "transparent",
                                            "--TableCell-selectedBackground": (theme) =>
                                                theme.vars.palette.primary.softBg,
                                            "& thead th:nth-child(1)": { width: "5%", textAlign: "center" },
                                            "& thead th:nth-child(2)": { width: "9%", textAlign: "center" },
                                            "& thead th:nth-child(3)": {
                                                width: "15%", textAlign: "center"
                                            },
                                            "& thead th:nth-child(4)": { width: "16%", textAlign: "center" },
                                            "& thead th:nth-child(5)": {
                                                width: "16%", textAlign: "center"
                                            },
                                            "& thead th:nth-child(6)": {
                                                width: "10%", textAlign: "center"
                                            },
                                            "& thead th:nth-child(7)": {
                                                width: "10%", textAlign: "center"
                                            },
                                            "& thead th:nth-child(8)": {
                                                width: "10%", textAlign: "center"
                                            },
                                            "& thead th:nth-child(9)": {
                                                width: "10%", textAlign: "center"
                                            },
                                            "& thead th:nth-child(10)": {
                                                width: "10%", textAlign: "center"
                                            },
                                            "& thead th:nth-child(11)": {
                                                width: "10%", textAlign: "center"
                                            },
                                            "& tr > *:nth-child(n+3)": {},
                                        }}
                                    >
                                        <EnhancedTableHead
                                            numSelected={selected.length}
                                            order={order}
                                            orderBy={orderBy}
                                            onSelectAllClick={handleSelectAllClick}
                                            onRequestSort={handleRequestSort}
                                            rowCount={dataSource.length}
                                        />
                                        <tbody>
                                            {tableUtilHelper.stableSort(dataSource, tableUtilHelper.getComparator(order, orderBy))
                                                .slice(
                                                    page * rowsPerPage,
                                                    page * rowsPerPage + rowsPerPage
                                                )
                                                .map((row: any, index: any) => {
                                                    const isItemSelected = isSelected(row.idSolicitud);
                                                    const labelId = `enhanced-table-checkbox-${index}`;

                                                    return (
                                                        <tr
                                                            onClick={(event) =>
                                                                handleClick(event, row.idSolicitud)
                                                            }
                                                            role="checkbox"
                                                            aria-checked={isItemSelected}
                                                            tabIndex={-1}
                                                            key={row.idSolicitud}
                                                            style={
                                                                isItemSelected
                                                                    ? ({
                                                                        "--TableCell-dataBackground":
                                                                            "var(--TableCell-selectedBackground)",
                                                                        "--TableCell-headBackground":
                                                                            "var(--TableCell-selectedBackground)",
                                                                    } as React.CSSProperties)
                                                                    : {}
                                                            }
                                                        >
                                                            <td style={{ textAlign: 'center', display: 'none' }} id={labelId} scope="row">
                                                                {<a className="colorHref">
                                                                    {row.idSolicitud}
                                                                </a>}
                                                            </td>
                                                            <td style={{ textAlign: 'center' }}>{row.idSolicitud}</td>
                                                            <td style={{ textAlign: 'center' }}>{row.fechaRegistro}</td>
                                                            <td style={{ textAlign: 'center' }}>{row.cliente}</td>
                                                            <td style={{ textAlign: 'center' }}>{row.marcaModelo}</td>
                                                            <td style={{ textAlign: 'center' }}>{row.concesionario}</td>
                                                            <td style={{ textAlign: 'center' }}>{row.producto}</td>
                                                            <td style={{ textAlign: 'center' }}>{row.estadoVehiculo}</td>
                                                            <td style={{ textAlign: 'center' }}>{row.monto}</td>
                                                            <td style={{ textAlign: 'center' }}>{row.etapa}</td>
                                                            <td style={{ textAlign: 'center' }}>{row.estado}</td>
                                                            <td style={{ textAlign: 'center' }}> {<input type="checkbox"
                                                                checked={row?.seleccionado === 1}
                                                                disabled={!row.checkBox || row.checkBox === false}
                                                                onChange={(event: any) => {
                                                                    for (let item of dataSource) {
                                                                        if (row.idSolicitud == item.idSolicitud) {
                                                                            item.seleccionado = event.target.checked ? 1 : 0;
                                                                        }
                                                                    }

                                                                }}
                                                            />}</td>
                                                        </tr>
                                                    );
                                                })}
                                            {emptyRows > 0 && (
                                                <tr
                                                    style={
                                                        {
                                                            height: `calc(${emptyRows} * 40px)`,
                                                            "--TableRow-hoverBackground": "transparent",
                                                        } as React.CSSProperties
                                                    }
                                                >
                                                    <td colSpan={11} />
                                                </tr>
                                            )}
                                        </tbody>

                                        <tfoot>
                                            <tr>
                                                <td colSpan={11}>
                                                    <Box
                                                        sx={{
                                                            display: "flex",
                                                            alignItems: "center",
                                                            gap: 2,
                                                            justifyContent: "flex-end",
                                                        }}
                                                    >
                                                        <FormControl orientation="horizontal" size="md">
                                                            <FormLabel>Líneas por páginas:</FormLabel>
                                                            <Select

                                                                value={rowsPerPage}
                                                            >
                                                                <Option value={5}>5</Option>
                                                                <Option value={10}>10</Option>
                                                                <Option value={25}>25</Option>
                                                                <Option value={50}>50</Option>
                                                                <Option value={100}>100</Option>
                                                            </Select>
                                                        </FormControl>
                                                        <Typography
                                                            textAlign="center"
                                                            sx={{ minWidth: 80 }}
                                                        >
                                                            {tableUtilHelper.labelDisplayedRows({
                                                                from:
                                                                    dataSource.length === 0
                                                                        ? 0
                                                                        : page * rowsPerPage + 1,
                                                                to: getLabelDisplayedRowsTo(),
                                                                count:
                                                                    dataSource.length === -1
                                                                        ? -1
                                                                        : dataSource.length,
                                                            })}
                                                        </Typography>
                                                        <Box sx={{ display: "flex", gap: 1 }}>
                                                            <IconButton
                                                                size="sm"
                                                                color="neutral"
                                                                variant="outlined"
                                                                disabled={page === 0}
                                                                onClick={() => handleChangePage(page - 1)}
                                                                sx={{ bgcolor: "background.surface" }}
                                                            >
                                                                <KeyboardArrowLeftIcon />
                                                            </IconButton>
                                                            <IconButton
                                                                size="sm"
                                                                color="neutral"
                                                                variant="outlined"
                                                                disabled={
                                                                    dataSource.length !== -1
                                                                        ? page >=
                                                                        Math.ceil(
                                                                            dataSource.length / rowsPerPage
                                                                        ) -
                                                                        1
                                                                        : false
                                                                }
                                                                onClick={() => handleChangePage(page + 1)}
                                                                sx={{ bgcolor: "background.surface" }}
                                                            >
                                                                <KeyboardArrowRightIcon />
                                                            </IconButton>
                                                        </Box>
                                                    </Box>
                                                </td>
                                            </tr>
                                        </tfoot>
                                    </Table>

                                </Sheet>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </MsalAuthenticationTemplate>
    );
}