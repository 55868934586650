import { appDefaultValues } from "../../../../config/Config";
import { BTCrearPersonaFisicaDatoAdicional } from "./BTCrearPersonaFisicaDatoAdicional";

export class BTCrearPersonaFisicaOtrosDatos {
    private sdtJSPSsBTDatoAdicional: BTCrearPersonaFisicaDatoAdicional[];

    constructor() {
        this.sdtJSPSsBTDatoAdicional = appDefaultValues.ArrayDefault;
    }

    public getSdtJSPSsBTDatoAdicional(): BTCrearPersonaFisicaDatoAdicional[] {
        return this.sdtJSPSsBTDatoAdicional;
    }

    public setSdtJSPSsBTDatoAdicional(sdtJSPSsBTDatoAdicional: BTCrearPersonaFisicaDatoAdicional[]): void {
        this.sdtJSPSsBTDatoAdicional = sdtJSPSsBTDatoAdicional;
    }
}