import { validarSelect, validarText } from "../../../../helpers/ValidarHelper";

const ValidacionRequestLiberarExcepcionHelper = {
    
    NewReqSolicitudExcepcionLiberacionInsert: (
        requestReleaseException: any) => {
        const {
            idEstadoResultadoExcepcion,
            comentario
        } = requestReleaseException;

        if (!validarSelect(idEstadoResultadoExcepcion, '"Acción"')) {
            return false;
        }
        if (!validarText(comentario, '"Comentario"')) {
            return false;
        }
        return true;
    }
};

export default ValidacionRequestLiberarExcepcionHelper;