import { appDefaultValues } from "../../../../../../config/Config";

export interface DatosTitularEmpresaInterface {
    nombreVia: string,
    numDomicilio: string,
    intDptoPiso: string,
    manzana: string,
    lote: string,
    nombreAgrupacion: string,
    referencia: string,
    identTipoVivienda: number,
    identTiempoResidencia: number,
    nomYApeContacto1: string,
    celular1: string,
    nomYApeContacto2: string,
    celular2: string
}

export const DatosTitularEmpresaInterfaceDefault: DatosTitularEmpresaInterface = {

    nombreVia: appDefaultValues.StringEmpty,
    numDomicilio: appDefaultValues.StringEmpty,
    intDptoPiso: appDefaultValues.StringEmpty,
    manzana: appDefaultValues.StringEmpty,
    lote: appDefaultValues.StringEmpty,
    nombreAgrupacion: appDefaultValues.StringEmpty,
    referencia: appDefaultValues.StringEmpty,
    identTipoVivienda: appDefaultValues.NumberDefault,
    identTiempoResidencia: appDefaultValues.NumberDefault,
    nomYApeContacto1: appDefaultValues.StringEmpty,
    celular1: appDefaultValues.StringEmpty,
    nomYApeContacto2: appDefaultValues.StringEmpty,
    celular2: appDefaultValues.StringEmpty,
};