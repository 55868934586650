import { useContext } from "react";
import DatosLaboralesTitularCard from "../components/DatosLaboralesTitularCard";
import DatosPersonalesDelTitularCard from "../components/DatosPersonalesDelTitularCard";
import { TitularContext } from "../contexts";
import { appConfigKey } from "../../../../../config/Config";
import DatosPersonalesDelConyugeCard from "../components/DatosPersonalesDelConyugeCard";
import DatosLaboralesDelConyuge from "../components/DatosLaboralesDelConyuge";

export const TitularSection = () => {

    const { titularEstadoCivilId, separacionBienes } = useContext(TitularContext);

    return (
        <>
            <DatosPersonalesDelTitularCard />
            <DatosLaboralesTitularCard />
            {(titularEstadoCivilId == appConfigKey.keyIdEstadoCivilCasado || titularEstadoCivilId == appConfigKey.keyIdEstadoCivilConviviente) && <DatosPersonalesDelConyugeCard />}
            {(titularEstadoCivilId == appConfigKey.keyIdEstadoCivilCasado || titularEstadoCivilId == appConfigKey.keyIdEstadoCivilConviviente) && !separacionBienes && <DatosLaboralesDelConyuge />}
        </>
    );
};