import { createContext } from "react";

export const InformeComercialContext = createContext<any>({});

export const InformeComercialProvider = ({ solicitudId, children }: any) => {
    const urlParams = new URLSearchParams(window.location.search);
    let IdSolicitud = solicitudId ?? urlParams.get('Solicitud');

    return (
        <InformeComercialContext.Provider value={{
            IdSolicitud,
        }}>
            {children}
        </InformeComercialContext.Provider>
    );
};