import { useEffect, useState } from "react";
import { appConfigKey } from "../../../../../../../config/Config";
import { SelectAutocompleteInput, SelectInput, SimpleInput } from "../../../../3.40.-SolicitudeStepOne/components/elements/inputs";
import TitleCard from "../../../../3.40.-SolicitudeStepOne/components/elements/titleCard";

const ConyugeDatosCard = (props: any) => {

    const {
        titularData,
        conyugeData,
        listPais,
        listNivelEducacion,
        listProfesion,
        setConyugeDatosFinal,
        clickSubmit
    } = props;

    const [viewModel, setViewModel] = useState({
        identNacionalidad: 0,
        identNivelEducacion: 0,
        identProfesion: 0,
        correo: "",
    });

    useEffect(() => {
        if (clickSubmit !== 0) {

            const findNacionalidad: any = listPais.find((pais: any) => pais.id === viewModel.identNacionalidad);
            const descripcionNacionalidad = findNacionalidad?.description;

            const findNivelEducacion: any = listNivelEducacion.find((nivel: any) => nivel.id === viewModel.identNivelEducacion);
            const descripcionNivelEducacion = findNivelEducacion?.description;

            const findProfesion: any = listProfesion.find((profesion: any) => profesion.id === viewModel.identProfesion);
            const descripcionProfesion = findProfesion?.description;

            setConyugeDatosFinal({
                ...viewModel,
                descripcionNacionalidad,
                descripcionNivelEducacion,
                descripcionProfesion,
            });

        }
    }, [clickSubmit]);

    useEffect(() => {
        if (conyugeData !== null && conyugeData !== undefined) {
            setViewModel({
                ...viewModel,
                identNacionalidad: conyugeData.iD_NACIONALIDAD,
                identNivelEducacion: conyugeData.iD_NIVEL_ESTUDIOS,
                identProfesion: conyugeData.iD_PROFESION,
                correo: conyugeData.descL_EMAIL,
            });
        }
    }, []);

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
            <TitleCard title={`DATOS DEL ${titularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ? "CÓNYUGE" : "CONVIVIENTE"}`} obligatorio={true} />
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectAutocompleteInput
                        value={viewModel.identNacionalidad}
                        name={"identNacionalidad"}
                        title={"Nacionalidad"}
                        options={listPais}
                        required={true}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel,
                                identNacionalidad: newValue,
                            });
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        value={viewModel.identNivelEducacion}
                        name={"identNivelEducacion"}
                        title={"Nivel de educación"}
                        options={listNivelEducacion}
                        required={true}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({ 
                                ...viewModel, 
                                identNivelEducacion: newValue 
                            });
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        value={viewModel.correo}
                        name={"correo"}
                        title={"Correo"}
                        required={true}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                correo: event.target.value
                            });
                        }}
                    />
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identProfesion"}
                        title={"Profesión"}
                        options={listProfesion}
                        value={viewModel.identProfesion}
                        required={true}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({ 
                                ...viewModel, 
                                identProfesion: newValue 
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );

};

export default ConyugeDatosCard;