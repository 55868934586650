import React, { useEffect, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { Button } from "@mui/joy";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';

import RequestManager from "../../../services/origination/wapirequest.service";
import HistoricoIngresosResquestDialog from './scoringHistoricoIngreso';
import { HistorialIngresos } from "../../../models/ManagerProfile/HistorialIngresos";
import { OtrosIngresos } from "../../../models/ManagerProfile/Titular";
import CardOtrosIngresos from './components/cardOtrosIngresos';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import GeneralHelper from "../../../helpers/GeneralHelper";
import { appConfigKey } from "../../../../config/Config";
import RestorePageIcon from '@mui/icons-material/RestorePage';
import IconButton from "@mui/material/IconButton";
import { Tooltip } from "@mui/material";

export const ScoringConyuge = ({ conyuge, conyugeBeforce, setConyuge, handleReevaluar, esModoLectura }: any) => {
  const [historicoIngresos, setHistoricoIngresos] = useState<HistorialIngresos[]>([]);
  const [esModoLecturaScoringConyuge, setEsModoLecturaScoringConyuge] = useState(false);

  const getListHistoricoIngresos = async () => {
    var body = {
      idenT_PERSONA: conyuge.ingresos.idPersona,
    };
    await RequestManager.GetMnIngresosPersonaHistorico(body)
      .then((response: any) => {
        setHistoricoIngresos(response.data.content);
        setHistoricoIngresoRequestDialogOpen(true);
      });
  }

  const [historicoIngresoRequestDialogOpen, setHistoricoIngresoRequestDialogOpen] = React.useState<boolean>(false);
  const handleHistoricoIngresoRequestDialogClose = () => {
    setHistoricoIngresoRequestDialogOpen(false);
  };

  const handleHistoricoIngresoRequestDialogOpen = () => {
    getListHistoricoIngresos();
  };

  const [otrosIngresos, setOtrosIngresos] = useState<OtrosIngresos[]>([]);
  const [otrosIngresosBeforce, setOtrosIngresosBeforce] = useState<any>([]);

  const handleAnualizadoIngreso = async (event: any) => {
    const ingresoNeto = GeneralHelper.QuitarComasAMiles(event.target.value)
    if (/^\d*\.?\d{0,2}$/.test(ingresoNeto)) {
      let ingresos = conyuge.ingresos;
      const newTotalIngreso = (ingresos.ingresoAnualizado && (ingresos.montoMinimoAnualizado <= Number(ingresoNeto))) ? Number(((Number(ingresoNeto) * 14) / 12).toFixed(2)) : 0;
      ingresos = {
        ...ingresos,
        ingresoNeto: ingresoNeto,
        totalAnualizado: newTotalIngreso
      };
      setConyuge({ ...conyuge, ingresos: ingresos });
    }
  }

  const handleAnualizadoQuinta = async (event: any) => {
    let ingresos = conyuge.ingresos;
    const newTotalIngreso = (event.target.checked && (ingresos.montoMinimoAnualizado <= Number(ingresos.ingresoNeto))) ? Number(((conyuge?.ingresos?.ingresoNeto * 14) / 12).toFixed(2)) : 0;

    ingresos = {
      ...ingresos,
      ingresoAnualizado: event.target.checked,
      totalAnualizado: newTotalIngreso
    };
    setConyuge({ ...conyuge, ingresos: ingresos });
  }

  const restoreIngresosConyuge = () => {
    let ingresosBeforce: any = null;
    let otrosIngresosBeforceTemp: any = [];
    otrosIngresosBeforce?.map((item: any, index: number) => {
      ingresosBeforce = {};
      ingresosBeforce.idIngresoPersona = item.idIngresoPersonaTemp;
      ingresosBeforce.idmoneda = item.idmonedaTemp;
      ingresosBeforce.moneda = item.monedaTemp;
      ingresosBeforce.ingresoNeto = item.ingresoNetoTemp;
      ingresosBeforce.comentario = item.comentarioTemp;
      ingresosBeforce.idcategoriaLaboral = item.idcategoriaLaboralTemp;
      ingresosBeforce.categoriaLaboral = item.categoriaLaboralTemp;
      ingresosBeforce.ruc = item.rucTemp;
      ingresosBeforce.razonSocial = item.razonSocialTemp;
      ingresosBeforce.fechaIngresoLaboral = item.fechaIngresoLaboralTemp;
      ingresosBeforce.ingresoAnualizado = item.ingresoAnualizadoTemp;
      ingresosBeforce.totalAnualizado = item.totalAnualizadoTemp;
      ingresosBeforce.montoMinimoAnualizado = item.montoMinimoAnualizadoTemp;
      ingresosBeforce.montoMinimoAnualizadoDolares = item.montoMinimoAnualizadoDolaresTemp;
      otrosIngresosBeforceTemp.push(ingresosBeforce);
    });

    setConyuge({
      ...conyuge,
      ingresos: conyugeBeforce.ingresos,
      otrosIngresos: otrosIngresosBeforceTemp
    });
  };

  useEffect(() => {
    setOtrosIngresos(conyuge.otrosIngresos);
    setEsModoLecturaScoringConyuge(!esModoLectura ? false : esModoLectura)
  }, [conyuge])

  useEffect(() => {
    let ingresosBeforceTemp: any = null;
    let otrosIngresosBeforceTemp: any = [];
    conyugeBeforce?.otrosIngresos?.map((item: any, index: number) => {
      ingresosBeforceTemp = {};
      ingresosBeforceTemp.idIngresoPersonaTemp = item.idIngresoPersona;
      ingresosBeforceTemp.idmonedaTemp = item.idmoneda;
      ingresosBeforceTemp.monedaTemp = item.moneda;
      ingresosBeforceTemp.ingresoNetoTemp = item.ingresoNeto;
      ingresosBeforceTemp.comentarioTemp = item.comentario;
      ingresosBeforceTemp.idcategoriaLaboralTemp = item.idcategoriaLaboral;
      ingresosBeforceTemp.categoriaLaboralTemp = item.categoriaLaboral;
      ingresosBeforceTemp.rucTemp = item.ruc;
      ingresosBeforceTemp.razonSocialTemp = item.razonSocial;
      ingresosBeforceTemp.fechaIngresoLaboralTemp = item.fechaIngresoLaboral;
      ingresosBeforceTemp.ingresoAnualizadoTemp = item.ingresoAnualizado;
      ingresosBeforceTemp.totalAnualizadoTemp = item.totalAnualizado;
      ingresosBeforceTemp.montoMinimoAnualizadoTemp = item.montoMinimoAnualizado;
      ingresosBeforceTemp.montoMinimoAnualizadoDolaresTemp = item.montoMinimoAnualizadoDolares;
      otrosIngresosBeforceTemp.push(ingresosBeforceTemp);
    });

    setOtrosIngresosBeforce(otrosIngresosBeforceTemp);
  
}, [conyugeBeforce])

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">

      <AccordionGroup>
        <Accordion>
          <AccordionSummary>INGRESOS CONYUGE</AccordionSummary>
          <AccordionDetails>
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                    <Tooltip title="Restaurar valores">
                      <IconButton
                        aria-label="restorepage"
                        size="large"
                        color="error"
                        disabled={esModoLecturaScoringConyuge}
                        style={{ position: "absolute", right: "0", top: "0" }}
                        onClick={restoreIngresosConyuge}>
                        <RestorePageIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-3 cardObjets">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormLabel>Moneda</FormLabel>
                    <Input
                      name="moneda"
                      placeholder="Soles"
                      value={conyuge.ingresos.moneda}
                      disabled={true}
                      onChange={(event: any) => {
                        let ingresos = conyuge.ingresos;
                        ingresos = { ...ingresos, numeroDocumento: event.target.value };
                        setConyuge({ ...conyuge, ingresos: ingresos });
                      }} />
                  </LocalizationProvider>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>Ingreso neto S/{/*<span style={{ color: "red" }}> * </span>*/}</FormLabel>
                    <Input
                      name="ingresoNeto"
                      placeholder="Ej: 00000000"
                      value={GeneralHelper.NumeroConCommas(conyuge.ingresos.ingresoNeto)}
                      disabled={esModoLecturaScoringConyuge}
                      // onChange={(event: any) => {
                      //   let ingresos = conyuge.ingresos;
                      //   ingresos = { ...ingresos, ingresoNeto: event.target.value };
                      //   setConyuge({ ...conyuge, ingresos: ingresos });
                      // }} 
                      onChange={handleAnualizadoIngreso}
                    />
                  </FormControl>
                </div>
                <div className="col-lg-5 cardObjets">
                  <FormControl>
                    <FormLabel>Comentario</FormLabel>
                    <Input
                      name="comentario"
                      placeholder=""
                      value={conyuge.ingresos.comentario}
                      disabled={esModoLecturaScoringConyuge}
                      onChange={(event: any) => {
                        let ingresos = conyuge.ingresos;
                        ingresos = { ...ingresos, comentario: event.target.value };
                        setConyuge({ ...conyuge, ingresos: ingresos });
                      }} />
                  </FormControl>
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                {conyuge.ingresos.idcategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCinco && <div className="col-lg-3 cardObjets">
                  <FormLabel>Anualizado solo 5ta</FormLabel>
                  <Switch
                    // color="success"
                    color={conyuge.ingresos.ingresoAnualizado ? "success" : "neutral"}
                    checked={conyuge.ingresos.ingresoAnualizado}
                    disabled={esModoLecturaScoringConyuge}
                    onChange={handleAnualizadoQuinta}
                    // onChange={(event) => {
                    //   let ingresos = conyuge.ingresos;
                    //   ingresos = { ...ingresos, ingresoAnualizado: event.target.checked };
                    //   setConyuge({...conyuge, ingresos: ingresos});
                    // }}
                    slotProps={{
                      track: {
                        children: (
                          <React.Fragment>
                            <Typography
                              component="span"
                              level="inherit"
                              sx={{ ml: "10px" }}
                            >
                              Si
                            </Typography>
                            <Typography
                              component="span"
                              level="inherit"
                              sx={{ mr: "8px" }}
                            >
                              No
                            </Typography>
                          </React.Fragment>
                        ),
                      },
                    }}
                    sx={{
                      "--Switch-thumbSize": "27px",
                      "--Switch-trackWidth": "64px",
                      "--Switch-trackHeight": "31px",
                    }}
                  />
                </div>}
                {/* <div className="col-lg-3 cardObjets">
                  <Button
                    size="md"
                    color="danger"
                    variant="outlined"
                    disabled={esModoLecturaScoringConyuge}
                    sx={{
                      borderRadius: 24,
                      borderColor: "red",
                      fontWeight: 650,
                      width: 1 / 2,
                    }}
                    onClick={handleReevaluar}
                  >
                    Reevaluar
                  </Button>
                  </div>*/}
                <div className="col-lg-4 cardObjets">
                  <Button
                    size="md"
                    color="danger"
                    variant="outlined"
                    sx={{
                      borderRadius: 24,
                      borderColor: "red",
                      fontWeight: 650,
                      width: 1 / 2,
                    }}
                    onClick={handleHistoricoIngresoRequestDialogOpen}
                  >
                    Ver historial ingresos
                  </Button>
                </div>

              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-3 cardObjets">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormLabel>Categoria laboral</FormLabel>
                    <FormLabel>{conyuge.ingresos.categoriaLaboral}</FormLabel>
                  </LocalizationProvider>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>RUC</FormLabel>
                    <FormLabel>{conyuge.ingresos.ruc}</FormLabel>
                  </FormControl>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>Razón Social</FormLabel>
                    <FormLabel>{conyuge.ingresos.razonSocial}</FormLabel>
                  </FormControl>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormLabel>Fecha ingreso laboral</FormLabel>
                      <DatePicker
                        format="DD/MM/YYYY"
                        disabled={esModoLecturaScoringConyuge}
                        value={dayjs(GeneralHelper.ParseDateString('DD/MM/YYYY', conyuge.ingresos.fechaIngresoLaboral))}
                        onChange={(date: any | null) => {
                          let ingresos = conyuge.ingresos;
                          ingresos = { ...ingresos, fechaIngresoLaboral: !date ? '' : date.format("DD/MM/YYYY") };
                          setConyuge({ ...conyuge, ingresos: ingresos });
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </div>
              </div>

              {conyuge.ingresos.idcategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCinco &&
                <>
                  <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-3 cardObjets">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormLabel>Ingreso anualizado</FormLabel>
                        <FormLabel>{GeneralHelper.NumeroConCommas(conyuge.ingresos.totalAnualizado)}</FormLabel>
                      </LocalizationProvider>
                    </div>
                    {/* <div className="col-lg-3 cardObjets">
                      <FormControl>
                        <FormLabel>Separación de bienes</FormLabel>
                        <FormLabel>{conyuge.ingresos.separacionDeBienes}</FormLabel>
                      </FormControl>
                    </div> */}
                  </div>
                </>
              }

              {conyuge.ingresos.idIngresoPersonaTrabajoAnterior !== 0 &&
                <>
                  <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-3 cardObjets">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormLabel>Categoría anterior</FormLabel>
                        <FormLabel>{conyuge.ingresos.categoriaAnterior}</FormLabel>
                      </LocalizationProvider>
                    </div>
                    <div className="col-lg-3 cardObjets">
                      <FormControl>
                        <FormLabel>RUC</FormLabel>
                        <FormLabel>{conyuge.ingresos.rucAnterior}</FormLabel>
                      </FormControl>
                    </div>
                    <div className="col-lg-3 cardObjets">
                      <FormControl>
                        <FormLabel>Razón Social</FormLabel>
                        <FormLabel>{conyuge.ingresos.razonSocialAnterior}</FormLabel>
                      </FormControl>
                    </div>
                    <div className="col-lg-3 cardObjets">
                      <FormControl>
                        <FormLabel>Fecha ingreso laboral</FormLabel>
                        <FormLabel>{conyuge.ingresos.fechaIngresoLaboralAnterior}</FormLabel>
                      </FormControl>
                    </div>
                  </div>
                  <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-3 cardObjets">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormLabel>Fecha hasta</FormLabel>
                        <FormLabel>{conyuge.ingresos.fechaHasta}</FormLabel>
                      </LocalizationProvider>
                    </div>
                  </div>
                </>
              }

              {/* <div className="row g-2 mb-2 align-items-center borderOtherIncome">
              <div className="col-lg-4 textLabelHeadboard">
                <span className="fw-bold">OTROS INGRESOS</span>
              </div>
              <div className="col-lg-4">
                <span className="fw-bold"> </span>
              </div>
              <div className="col-lg-4 textAlignRigth">

              </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
              <div className="col-lg-3 cardObjets">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormLabel>Moneda</FormLabel>
                  <Input
                    name="moneda"
                    placeholder="Soles"
                    value={conyuge.otrosIngresos.moneda}
                    onChange={(event: any) => {
                      let otrosIngresos = conyuge.ingresos;
                      otrosIngresos = { ...otrosIngresos, numeroDocumento: event.target.value };
                      setconyuge({ ...conyuge, otrosIngresos: otrosIngresos });
                    }} />
                </LocalizationProvider>
              </div>
              <div className="col-lg-3 cardObjets">
                <FormControl>
                  <FormLabel>Ingreso neto S/<span style={{ color: "red" }}> * </span></FormLabel>
                  <Input
                    name="ingresoNeto"
                    placeholder="Ej: 00000000"
                    value={conyuge.otrosIngresos.ingresoNeto}
                    onChange={(event: any) => {
                      let otrosIngresos = conyuge.otrosIngresos;
                      otrosIngresos = { ...otrosIngresos, ingresoNeto: event.target.value };
                      setconyuge({ ...conyuge, otrosIngresos: otrosIngresos });
                    }} />
                </FormControl>
              </div>
              <div className="col-lg-5 cardObjets">
                <FormControl>
                  <FormLabel>Comentario <span style={{ color: "red" }}> * </span></FormLabel>
                  <Input
                    name="comentario"
                    placeholder=""
                    value={conyuge.otrosIngresos.comentario}
                    onChange={(event: any) => {
                      let otrosIngresos = conyuge.otrosIngresos;
                      otrosIngresos = { ...otrosIngresos, comentario: event.target.value };
                      setconyuge({ ...conyuge, otrosIngresos: otrosIngresos });
                    }} />
                </FormControl>
              </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
              <div className="col-lg-3 cardObjets">
                <FormLabel>Anualizado solo 5ta</FormLabel>
                <Switch
                  color="success"
                  // checked={checkedAnuladoSolo5ta}
                  // onChange={(event) =>
                  //   setcheckedAnuladoSolo5ta(event.target.checked)
                  // }
                  slotProps={{
                    track: {
                      children: (
                        <React.Fragment>
                          <Typography
                            component="span"
                            level="inherit"
                            sx={{ ml: "10px" }}
                          >
                            Si
                          </Typography>
                          <Typography
                            component="span"
                            level="inherit"
                            sx={{ mr: "8px" }}
                          >
                            No
                          </Typography>
                        </React.Fragment>
                      ),
                    },
                  }}
                  sx={{
                    "--Switch-thumbSize": "27px",
                    "--Switch-trackWidth": "64px",
                    "--Switch-trackHeight": "31px",
                  }}
                />
              </div>
              <div className="col-lg-3 cardObjets">
                <Button
                  size="md"
                  color="danger"
                  variant="outlined"
                  sx={{
                    borderRadius: 24,
                    borderColor: "red",
                    fontWeight: 650,
                    width: 1 / 2,
                  }}
                //onClick={handlerBtnFiadoringresos}
                >
                  Reevaluar
                </Button>
              </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
              <div className="col-lg-3 cardObjets">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormLabel>Categoria labora</FormLabel>
                  <FormLabel>{conyuge.ingresos.categoriaLaboral}</FormLabel>
                </LocalizationProvider>
              </div>
              <div className="col-lg-3 cardObjets">
                <FormControl>
                  <FormLabel>RUC</FormLabel>
                  <FormLabel>{conyuge.ingresos.ruc}</FormLabel>
                </FormControl>
              </div>
              <div className="col-lg-3 cardObjets">
                <FormControl>
                  <FormLabel>Razón Social</FormLabel>
                  <FormLabel>{conyuge.ingresos.razonSocial}</FormLabel>
                </FormControl>
              </div>
              <div className="col-lg-3 cardObjets">
                <FormControl>
                  <FormLabel>Fecha ingreso laboral</FormLabel>
                  <FormLabel>{conyuge.ingresos.fechaIngresoLaboral}</FormLabel>
                </FormControl>
              </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
              <div className="col-lg-3 cardObjets">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormLabel>Ingreso anualizado</FormLabel>
                  <FormLabel>{conyuge.ingresos.ingresoAnualizado}</FormLabel>
                </LocalizationProvider>
              </div>
            </div> */}

              <CardOtrosIngresos otrosIngresos={otrosIngresos} setOtrosIngresos={setOtrosIngresos} esModoLectura={esModoLectura} />
            </>

          </AccordionDetails>
        </Accordion>
      </AccordionGroup>

      <HistoricoIngresosResquestDialog
        open={historicoIngresoRequestDialogOpen}
        handleClose={handleHistoricoIngresoRequestDialogClose}
        historicoIngresos={historicoIngresos}
      />
    </div>
  );
};

export default ScoringConyuge;