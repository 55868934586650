import { MnFormatoAuditorResponse } from "../../../../models/RequestManager/solicitud-formato-auditor-response.model";
import { MnFormatoRegimenDto } from "../../../../models/RequestManager/solicitud-formato-regimen-response.model";

export const ConvertidorHelper = {

    ObtenerRegimenDto: (response: MnFormatoAuditorResponse): MnFormatoRegimenDto => {
        return {
            identFormatoRegistro: response?.identFormatoAuditor,
            identFormato: response?.identFormato,
            identSolicitud: response?.identSolicitud,
            jsonTable: response?.jsonTable
        } as MnFormatoRegimenDto;
    },
}