import {
    Dialog,
    DialogTitle,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
} from "@mui/material";
import { Table } from "@mui/joy";
import { useContext } from "react";
import { StepFourContext } from "../../../3.10.-base/providers";
import CloseIcon from '@mui/icons-material/Close';
import { appConfigKey } from "../../../../../../config/Config";

function createData(
    name: string,
    calories: number,
    fat: number,
    carbs: number,
    protein: number,
) {
    return { name, calories, fat, carbs, protein };
}

const rows = [
    createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
    createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
    createData('Eclair', 262, 16.0, 24, 6.0),
    createData('Cupcake', 305, 3.7, 67, 4.3),
    createData('Gingerbread', 356, 16.0, 49, 3.9),
];

const ModalGastos = (props: any) => {

    const {
        listaComisionesCronoSel,
        listaComisionesCuotaCronoSel
    } = useContext(StepFourContext);

    const {
        handleCloseModalGastos,
        openModalGastos,
        listaGastos,
        tipoMonedaFinanciamiento
    } = props;

    return (
        <Dialog
            onClose={handleCloseModalGastos}
            open={openModalGastos}
            fullWidth={true}
            maxWidth={'md'}
        >
            <div style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px"
            }}>
                <div style={{
                    textAlign: "center",
                    paddingTop: "1.5rem"
                }}>
                    <div className="textLabelHeadboard">
                        <span className="fw-bold">GASTOS TOTALES</span>
                    </div>
                </div>

                <IconButton
                    aria-label="close"
                    onClick={handleCloseModalGastos}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <div style={{ padding: '0 20px 30px 20px' }}>
                    <Table borderAxis="none" className="style-table" stripe={'odd'}
                        sx={{
                            "--TableCell-headBackground": "transparent",
                            "--TableCell-selectedBackground": (theme) =>
                                theme.vars.palette.primary.softBg,
                            "& thead th:nth-child(1)": { width: "50%" },
                            "& thead th:nth-child(2)": { width: "50%" },
                            "& tr > *:nth-child(n+3)": {},
                        }}>
                        <thead>
                            <tr>
                                <th style={{ backgroundColor: "#E1E1E1", textAlign: "center" }}>TIPO DE GASTOS</th>
                                <th style={{ backgroundColor: "#E1E1E1", textAlign: "center" }}>IMPORTE {tipoMonedaFinanciamiento === appConfigKey.keyIdentificadorDolares ? appConfigKey.keySimboloDolares1 : appConfigKey.keySimboloSoles}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                listaGastos.map((row: any, index: number) => {
                                    return (
                                        <tr key={index}>
                                            <td style={{ textAlign: "center" }}>{row.descripcion}</td>
                                            <td style={{ textAlign: "center" }}>{tipoMonedaFinanciamiento === appConfigKey.keyIdentificadorDolares ? appConfigKey.keySimboloDolares1 : appConfigKey.keySimboloSoles} {Number(row.importe).toFixed(2)}</td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </Table>
                </div>
            </div>

        </Dialog>
    );

};

export default ModalGastos;