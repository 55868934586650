import React, { useEffect, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { Button, Table } from "@mui/joy";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { IconButton, Tooltip } from "@mui/material";
import GeneralHelper from "../../../helpers/GeneralHelper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SimbolAdd from "@mui/icons-material/Add";
import Save from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";
import { appDefaultValues, appSecurityConfiguration } from "../../../../config/Config";
import TimeHelper from "../../../helpers/TimeHelper";
import { DatosParticipacion, DatosParticipacionDefault } from "../15.10.-base/interfaces";
import { DateInput, MoneyInput, NumberDecimalInput, NumberInput, SelectInput, SimpleInput, TextInput } from "../15.10.-base/elements/inputs";
import RequestManager from "../../../services/origination/wapirequest.service";
import { Dropdown } from "react-bootstrap";
import CustomDivToggle from "../15.10.-base/elements/customDivToggle/CustomDivToggle";
import { BsThreeDots, BsSearch } from "react-icons/bs";
import { toast } from "sonner";
import { AxiosError } from "axios";

export const ParticipacionAuditor = (DatosParticipacionP: any) => {
//   const [chkRequerido, setChkRequerido] = React.useState<boolean>(false);
  const [dataParticipacion, setDataParticipacion] = useState<DatosParticipacion>(DatosParticipacionDefault);
  const [dataListParticipacion, setDataListParticipacion] = useState<DatosParticipacion[]>([]);  
  const [flgShow, setFlgShow] = useState<boolean>(false);

  const onClickNuevo = () => {
    setFlgShow(true);
    setDataParticipacion(DatosParticipacionDefault);
  }

  const handleClickViewDataParticipacion = async (value: any) =>  {
    setFlgShow(true);
    const response = await RequestManager.GetMnInformeComercialParticipacionPatrimonialById({ "IdentInformeComercialParticipacionPatrimonial": value });
    if (response.status === 200) {
        setDataParticipacion(response.data.content);
    }
}

const handleClickDeleteParticipacion = async (value: any) =>  {
    setFlgShow(false);
    setDataParticipacion(DatosParticipacionDefault);
    const response = await RequestManager.MnInformeComercialParticipacionPatrimonialDelete({ "IdentInformeComercialParticipacionPatrimonial": value });
    if (response.status === 200) {
        console.log(response);
        setDataParticipacion(response.data.content);
        CargaInicial();
    }
}

const CargaInicial = async () => {
    const response = await RequestManager.GetInformeComercialParticipacionPatrimonial({ "identInformeComercial": DatosParticipacionP.dataInformeComercial.identInformeComercial });
    if (response.status === 200) {
        setDataListParticipacion(response.data.content);
    }
}

const onClickCancelar = () => {
    setFlgShow(false);
    setDataParticipacion(DatosParticipacionDefault);
}

const GuardarParticipacion = async() => {
    let resultado;
    let bodyInsert = {
        "identInformeComercial": dataParticipacion.identInformeComercial == 0 ? DatosParticipacionP.dataInformeComercial.identInformeComercial : dataParticipacion.identInformeComercial,
        "razonSocial": dataParticipacion.razonSocial,
        "razonSocialOtrasEntidades": dataParticipacion.razonSocialOtrasEntidades,
        "rucOtrasEntidades": dataParticipacion.rucOtrasEntidades,
        "aporteSoles": GeneralHelper.QuitarComasAMiles(dataParticipacion.aporteSoles),
        "aportePorcentaje": dataParticipacion.aportePorcentaje,
        "codigoSbs": dataParticipacion.codigoSbs,
        "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
    }
    let bodyUpdate = {
        "identInformeComercialParticipacionPatrimonial": dataParticipacion.identInformeComercialParticipacionPatrimonial,
        "identInformeComercial": dataParticipacion.identInformeComercial,
        "razonSocial": dataParticipacion.razonSocial,
        "razonSocialOtrasEntidades": dataParticipacion.razonSocialOtrasEntidades,
        "rucOtrasEntidades": dataParticipacion.rucOtrasEntidades,
        "aporteSoles": GeneralHelper.QuitarComasAMiles(dataParticipacion.aporteSoles),
        "aportePorcentaje": dataParticipacion.aportePorcentaje,
        "codigoSbs": dataParticipacion.codigoSbs,
        "identUsuarioModificador": String(appSecurityConfiguration.SessionUserId)
    }

    try {
      const response = dataParticipacion.identInformeComercialParticipacionPatrimonial == 0 ? 
      await RequestManager.MnInformeComercialParticipacionPatrimonialInsert(bodyInsert) : 
      await RequestManager.MnInformeComercialParticipacionPatrimonialUpdate(bodyUpdate);
      
      if (response.status === 200) {
        resultado = response.data.isValid;
        if (resultado === true) {
          if(dataParticipacion.identInformeComercialParticipacionPatrimonial == 0)
              {toast.success("Registro guardado correctamente", { duration: 5000 });}
          else{toast.success("Registro actualizado correctamente.", { duration: 5000 });}
        }
        else{
          toast.error("Ocurrió un error", { duration: 5000 });
        }
      };
      CargaInicial();

      setFlgShow(false);
      setDataParticipacion(DatosParticipacionDefault);      
    } catch (error: AxiosError | any) {            
      if (error.response && error.response.data.status === 400 && error.response.data.errors) {
          const validationErrors = error.response.data.errors;
          if (validationErrors) {
              for (const [field, messages] of Object.entries(validationErrors)) {                            
                  let message = messages as string[]; // Type assertion
                  toast.error(`${field}: ${message}`, { duration: 5000 });
              }
          } else {
              toast.error("Ocurrió un error", { duration: 5000 });
          }
      }
  }           
}

useEffect(() => {
    CargaInicial();
    // InitListas();
}, [DatosParticipacionP]);

    return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>V. PARTIC. PATRIMONIAL II, III, IV OTRAS ENTIDADES</AccordionSummary>
          <AccordionDetails>
            <>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-12 cardObjets align-top">
                    <Table borderAxis="none" className="style-table align-top">
                        <thead>
                            <tr>
                            <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>NOMBRE O RAZÓN SOCIAL</th>
                            <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>PART. OTRAS ENT. RAZÓN SOCIAL</th>
                            <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>PART. OTRAS ENT. RUC</th>
                            <th style={{ fontSize: "12px", width: "13%", backgroundColor: "#E1E1E1", }}>APORTE S/.</th>
                            <th style={{ fontSize: "12px", width: "12%", backgroundColor: "#E1E1E1", }}>APORTE %</th>
                            <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>CÓDIGO SBS</th>
                            <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>ESTADO</th>
                            <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>ACCIONES</th>
                            </tr>
                        </thead>
                    <tbody>
                    {
                        dataListParticipacion.length > 0 && dataListParticipacion.map((row: any, index: number) => {
                        return (
                        <tr key={row.identInformeComercialParticipacionPatrimonial}>
                        <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.razonSocial}</td>
                        <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.razonSocialOtrasEntidades}</td>
                        <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.rucOtrasEntidades}</td>
                        <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.AgregarComasAMiles(row.aporteSoles)}</td>
                        <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.aportePorcentaje}</td>
                        <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.codigoSbs}</td>
                        <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.estado}</td>
                        <td>
                              <Dropdown key={row.identInformeComercialPersona}>
                                <Dropdown.Toggle
                                  as={CustomDivToggle}
                                  style={{ cursor: "pointer" }}
                                >
                                  <BsThreeDots />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickViewDataParticipacion(
                                            row.identInformeComercialParticipacionPatrimonial
                                          )
                                        }
                                      >
                                        Editar
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickDeleteParticipacion(
                                            row.identInformeComercialParticipacionPatrimonial
                                          )
                                        }
                                      >
                                        Eliminar
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                        </td>
                        </tr>
                        )
                        })
                    }
                    </tbody>
                    </Table>
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-12 cardObjets bottom-right">
                      <Button
                          size="md"
                          color="neutral"
                          variant="outlined"
                          endDecorator={<SimbolAdd />}
                          sx={{ borderRadius: 24, width: 1/8 }}
                          // disabled={props.disabled}
                          onClick={onClickNuevo}>Nuevo
                      </Button>
                  </div>
              </div> 

              { flgShow && <div>       
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        title={"Nombre / Razón Social"}
                        placeholder="Ingresar"
                        value={dataParticipacion.razonSocial}
                        // disabled={readonlyForm}
                        onChange={(event: any) => setDataParticipacion({ ...dataParticipacion, razonSocial: event.target.value })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        title={"Part. otras ent. razón social"}
                        placeholder="Ingresar"
                        value={dataParticipacion.razonSocialOtrasEntidades}
                        // disabled={readonlyForm}
                        onChange={(event: any) => setDataParticipacion({ ...dataParticipacion, razonSocialOtrasEntidades: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        title={"Part. otras ent. RUC"}
                        placeholder="Ingresar"
                        value={dataParticipacion.rucOtrasEntidades}
                        // disabled={readonlyForm}
                        onChange={(event: any) => setDataParticipacion({ ...dataParticipacion, rucOtrasEntidades: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <MoneyInput
                        title={"Aporte S/"}
                        placeholder="Ingresar"
                        value={GeneralHelper.AgregarComasAMiles(dataParticipacion.aporteSoles)}
                        // disabled={readonlyForm}
                        onChange={(event: any) => setDataParticipacion({ ...dataParticipacion, aporteSoles: event.target.value })}
                    />
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-2 cardObjets">
                      <NumberInput
                          title={"Aporte %"}
                          placeholder="Ingresar"
                          longNumber={3}
                          value={dataParticipacion.aportePorcentaje}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataParticipacion({ ...dataParticipacion, aportePorcentaje: event.target.value })}
                      />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        title={"Codigo SBS"}
                        placeholder="Ingresar"
                        value={dataParticipacion.codigoSbs}
                        // disabled={readonlyForm}
                        onChange={(event: any) => setDataParticipacion({ ...dataParticipacion, codigoSbs: event.target.value })}
                    />
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center ">
                  <div className="col-lg-12 cardObjets bottom-right">
                          <Button
                              size="md"
                              color="neutral"
                              variant="outlined"
                              endDecorator={<Save />}
                              sx={{ borderRadius: 24, width: 1/8 }}
                              onClick={GuardarParticipacion}
                            //   disabled={!chkRequerido}
                              >Guardar
                          </Button>
                          <Button
                              size="md"
                              color="neutral"
                              variant="outlined"
                              endDecorator={<Cancel />}
                              sx={{ borderRadius: 24, width: 1/8 }}
                              // disabled={!chkRequerido}
                              onClick={onClickCancelar}
                              >Cancelar
                          </Button>
                  </div>
              </div>
              </div>
            }     
            </>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
    </div>
  );
};

export default ParticipacionAuditor;