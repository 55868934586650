import React, { useEffect, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import Textarea from "@mui/joy/Textarea";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import Option from "@mui/joy/Option";
import dayjs, { Dayjs } from "dayjs";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import Select, { selectClasses } from "@mui/joy/Select";
import { Button, Table } from "@mui/joy";
import * as Accordion from '@radix-ui/react-accordion';

export const ScoringConyugue = (props: any, setconyugue: any) => {
 const { conyugue } = props;

 return (
  <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
   <Accordion.Root className="AccordionRoot" type="single" defaultValue="item-1" collapsible>
    <Accordion.Item className="AccordionItem" value="item-1">
     <Accordion.Header>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-4 textLabelHeadboard">
        <span className="fw-bold">INGRESOS CONYUGUE</span>
       </div>
       <div className="col-lg-4">
        <span className="fw-bold"> </span>
       </div>
       <div className="col-lg-4 textAlignRigth">
        <Accordion.Trigger>
         ^
        </Accordion.Trigger>
       </div>
      </div>
     </Accordion.Header>
     <Accordion.Content>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Moneda</FormLabel>
         <Input
          name="moneda"
          placeholder="Soles"
          value={conyugue.ingresos.moneda}
          onChange={(event: any) => {
           let ingresos = conyugue.ingresos;
           ingresos = { ...ingresos, numeroDocumento: event.target.value };
           setconyugue({ ...conyugue, ingresos: ingresos });
          }} />
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <FormControl>
         <FormLabel>Ingreso neto S/<span style={{ color: "red" }}> * </span></FormLabel>
         <Input
          name="ingresoNeto"
          placeholder="Ej: 00000000"
          value={conyugue.ingresos.ingresoNeto}
          onChange={(event: any) => {
           let ingresos = conyugue.ingresos;
           ingresos = { ...ingresos, ingresoNeto: event.target.value };
           setconyugue({ ...conyugue, ingresos: ingresos });
          }} />
        </FormControl>
       </div>
       <div className="col-lg-5 cardObjets">
        <FormControl>
         <FormLabel>Comentario <span style={{ color: "red" }}> * </span></FormLabel>
         <Input
          name="comentario"
          placeholder=""
          value={conyugue.ingresos.comentario}
          onChange={(event: any) => {
           let ingresos = conyugue.ingresos;
           ingresos = { ...ingresos, comentario: event.target.value };
           setconyugue({ ...conyugue, ingresos: ingresos });
          }} />
        </FormControl>
       </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-3 cardObjets">
        <FormLabel>Anualizado solo 5ta</FormLabel>
        <Switch
         color="success"
         // checked={checkedAnuladoSolo5ta}
         // onChange={(event) =>
         //   setcheckedAnuladoSolo5ta(event.target.checked)
         // }
         slotProps={{
          track: {
           children: (
            <React.Fragment>
             <Typography
              component="span"
              level="inherit"
              sx={{ ml: "10px" }}
             >
              Si
             </Typography>
             <Typography
              component="span"
              level="inherit"
              sx={{ mr: "8px" }}
             >
              No
             </Typography>
            </React.Fragment>
           ),
          },
         }}
         sx={{
          "--Switch-thumbSize": "27px",
          "--Switch-trackWidth": "64px",
          "--Switch-trackHeight": "31px",
         }}
        />
       </div>
       <div className="col-lg-3 cardObjets">
        <Button
         size="md"
         color="danger"
         variant="outlined"
         sx={{
          borderRadius: 24,
          borderColor: "red",
          fontWeight: 650,
          width: 1 / 2,
         }}
        //onClick={handlerBtnFiadoringresos}
        >
         Reevaluar
        </Button>
       </div>
       <div className="col-lg-4 cardObjets">
        <Button
         size="md"
         color="danger"
         variant="outlined"
         sx={{
          borderRadius: 24,
          borderColor: "red",
          fontWeight: 650,
          width: 1 / 2,
         }}
        //onClick={handlerBtnFiadoringresos}
        >
         Ver historial ingresos
        </Button>
       </div>

      </div>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Categoria labora</FormLabel>
         <FormLabel>{conyugue.ingresos.categoriaLaboral}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <FormControl>
         <FormLabel>RUC</FormLabel>
         <FormLabel>{conyugue.ingresos.ruc}</FormLabel>
        </FormControl>
       </div>
       <div className="col-lg-3 cardObjets">
        <FormControl>
         <FormLabel>Razón Social</FormLabel>
         <FormLabel>{conyugue.ingresos.razonSocial}</FormLabel>
        </FormControl>
       </div>
       <div className="col-lg-3 cardObjets">
        <FormControl>
         <FormLabel>Fecha ingreso laboral</FormLabel>
         <FormLabel>{conyugue.ingresos.fechaIngresoLaboral}</FormLabel>
        </FormControl>
       </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Ingreso anualizado</FormLabel>
         <FormLabel>{conyugue.ingresos.ingresoAnualizado}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <FormControl>
         <FormLabel>Separación de bienes</FormLabel>
         <FormLabel>{conyugue.ingresos.separacionDeBienes}</FormLabel>
        </FormControl>
       </div>
      </div>

      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Categoria anterior</FormLabel>
         <FormLabel>{conyugue.ingresos.categoriaAnterior}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <FormControl>
         <FormLabel>RUC</FormLabel>
         <FormLabel>{conyugue.ingresos.rucAnterior}</FormLabel>
        </FormControl>
       </div>
       <div className="col-lg-3 cardObjets">
        <FormControl>
         <FormLabel>Razón Social</FormLabel>
         <FormLabel>{conyugue.ingresos.razonSocialAnterior}</FormLabel>
        </FormControl>
       </div>
       <div className="col-lg-3 cardObjets">
        <FormControl>
         <FormLabel>Fecha ingreso laboral</FormLabel>
         <FormLabel>{conyugue.ingresos.fechaIngresoLaboralAnterior}</FormLabel>
        </FormControl>
       </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Fecha hasta</FormLabel>
         <FormLabel>{conyugue.ingresos.fechaHasta}</FormLabel>
        </LocalizationProvider>
       </div>
      </div>

      <div className="row g-2 mb-2 align-items-center borderOtherIncome">
       <div className="col-lg-4 textLabelHeadboard">
        <span className="fw-bold">OTROS INGRESOS</span>
       </div>
       <div className="col-lg-4">
        <span className="fw-bold"> </span>
       </div>
       <div className="col-lg-4 textAlignRigth">

       </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Moneda</FormLabel>
         <Input
          name="moneda"
          placeholder="Soles"
          value={conyugue.otrosIngresos.moneda}
          onChange={(event: any) => {
           let otrosIngresos = conyugue.ingresos;
           otrosIngresos = { ...otrosIngresos, numeroDocumento: event.target.value };
           setconyugue({ ...conyugue, otrosIngresos: otrosIngresos });
          }} />
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <FormControl>
         <FormLabel>Ingreso neto S/<span style={{ color: "red" }}> * </span></FormLabel>
         <Input
          name="ingresoNeto"
          placeholder="Ej: 00000000"
          value={conyugue.otrosIngresos.ingresoNeto}
          onChange={(event: any) => {
           let otrosIngresos = conyugue.otrosIngresos;
           otrosIngresos = { ...otrosIngresos, ingresoNeto: event.target.value };
           setconyugue({ ...conyugue, otrosIngresos: otrosIngresos });
          }} />
        </FormControl>
       </div>
       <div className="col-lg-5 cardObjets">
        <FormControl>
         <FormLabel>Comentario <span style={{ color: "red" }}> * </span></FormLabel>
         <Input
          name="comentario"
          placeholder=""
          value={conyugue.otrosIngresos.comentario}
          onChange={(event: any) => {
           let otrosIngresos = conyugue.otrosIngresos;
           otrosIngresos = { ...otrosIngresos, comentario: event.target.value };
           setconyugue({ ...conyugue, otrosIngresos: otrosIngresos });
          }} />
        </FormControl>
       </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-3 cardObjets">
        <FormLabel>Anualizado solo 5ta</FormLabel>
        <Switch
         color="success"
         // checked={checkedAnuladoSolo5ta}
         // onChange={(event) =>
         //   setcheckedAnuladoSolo5ta(event.target.checked)
         // }
         slotProps={{
          track: {
           children: (
            <React.Fragment>
             <Typography
              component="span"
              level="inherit"
              sx={{ ml: "10px" }}
             >
              Si
             </Typography>
             <Typography
              component="span"
              level="inherit"
              sx={{ mr: "8px" }}
             >
              No
             </Typography>
            </React.Fragment>
           ),
          },
         }}
         sx={{
          "--Switch-thumbSize": "27px",
          "--Switch-trackWidth": "64px",
          "--Switch-trackHeight": "31px",
         }}
        />
       </div>
       <div className="col-lg-3 cardObjets">
        <Button
         size="md"
         color="danger"
         variant="outlined"
         sx={{
          borderRadius: 24,
          borderColor: "red",
          fontWeight: 650,
          width: 1 / 2,
         }}
        //onClick={handlerBtnFiadoringresos}
        >
         Reevaluar
        </Button>
       </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Categoria labora</FormLabel>
         <FormLabel>{conyugue.ingresos.categoriaLaboral}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <FormControl>
         <FormLabel>RUC</FormLabel>
         <FormLabel>{conyugue.ingresos.ruc}</FormLabel>
        </FormControl>
       </div>
       <div className="col-lg-3 cardObjets">
        <FormControl>
         <FormLabel>Razón Social</FormLabel>
         <FormLabel>{conyugue.ingresos.razonSocial}</FormLabel>
        </FormControl>
       </div>
       <div className="col-lg-3 cardObjets">
        <FormControl>
         <FormLabel>Fecha ingreso laboral</FormLabel>
         <FormLabel>{conyugue.ingresos.fechaIngresoLaboral}</FormLabel>
        </FormControl>
       </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Ingreso anualizado</FormLabel>
         <FormLabel>{conyugue.ingresos.ingresoAnualizado}</FormLabel>
        </LocalizationProvider>
       </div>
      </div>
     </Accordion.Content>
    </Accordion.Item>
   </Accordion.Root>
  </div>
 );
};

export default ScoringConyugue;