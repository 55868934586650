import { Box, FormControl, FormLabel, Tooltip } from "@mui/joy";
import { useState } from "react";
import icoQuestion from '../../../../../../assets/media/icons/ico_question.svg';
import { MenuItem, Select } from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";

const obligatorioStyle = {
    color: "red",
    marginLeft: '5px'
};

export const SelectInput = (props: any) => {
    var [showToolTip, setShowToolTip] = useState(false);
    const handlerButtonClick = (e: any) => {
        setShowToolTip(!showToolTip);

        //e.target.open=true;
    }
    return (
        <FormControl>
            {props.title !== undefined && (
                <FormLabel>{props.title} {(props.required === undefined || props.required === true) && <span style={obligatorioStyle}>*</span>}
                    {props.tooltip !== undefined && (<Tooltip sx={{ borderColor: '#0089B5' }} open={showToolTip} disableInteractive={true} title={
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                maxWidth: 250,
                                justifyContent: 'center',
                                p: 1
                            }}
                        >
                            {props.tooltip}
                        </Box>}
                        variant="outlined"
                        placement="top-start"
                        size="sm">
                        <button className="circle-btn-tooltip" onClick={handlerButtonClick} >
                            <img src={icoQuestion} />
                        </button>
                    </Tooltip>)}
                </FormLabel>
            )}

            <Select
                name={props.name}
                className={"select-input-card"}
                disabled={props.disabled}
                onChange={(event: any, newValue: any) => {
                    props.onChange(event, event.target.value);
                }}
                value={props.value}
                IconComponent={props => (<KeyboardArrowDown {...props} className={`material-icons ${props.className}`} />)}

            >
                <MenuItem value={0}>{props.selectTextDefault ? props.selectTextDefault : 'Seleccione'}</MenuItem>
                {props.options.map((data: any, index: any) => (
                    <MenuItem value={data.id} key={index}>{data.description}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};