import { MsalAuthenticationTemplate } from "@azure/msal-react";
import { ErrorComponent } from "../../1.-shared/1.1.-auth/1.1.1.-login/ErrorComponent";
import { Loading } from "../../1.-shared/1.1.-auth/1.1.1.-login/Loading";
import { InteractionType } from "@azure/msal-browser";
import CardTitleStart from "../../1.-shared/1.2.-layout/1.2.5-cardTitleStart/cardTitleStart";
import MainLayout from "../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout";
import { useEffect, useState } from "react";
import { DataSourceInterface } from "./interfaces/dataSourceInterface";
import { Box, FormLabel, IconButton, Link, Sheet, Switch, Table, Typography } from "@mui/joy";
import { appConfig, appConfigKey, appConfigPerfiles, appSecurityConfiguration } from "../../../../config/Config";
import React from "react";
import MasterManager from "../../../services/origination/wapimaster.service";
import FormControl from "@mui/joy/FormControl";
import Select from "@mui/joy/Select";
import Option from "@mui/joy/Option";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
import KeyboardArrowRightIcon from "@mui/icons-material/KeyboardArrowRight";
import { Dropdown } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import CustomDivToggle from "../12.20.-CustomDivToogle/CustomDivToggle";
import tableUtilHelper from "./helpers/tableUtilHelper";
import EnhancedTableHead from "./components/EnhancedTableHead";
import { Order } from "./interfaces/enhancedTableInterfaces";
import AssignCategoriesDialog from "./components/AssignCategoriesDialog";
import { General, ObtenerListas } from "./obtenerServicios";
import LoadingProgress from "../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import { Toaster, toast } from "sonner";
import { useNavigate } from "react-router-dom";

export default function TableTeamManagement() {

    let navigate = useNavigate();
    const [showLoading, setShowLoading] = useState(false);
    const [dataSource, setDataSource] = useState<DataSourceInterface[]>([]);
    const authRequest = {
        scopes: ["openid", "profile"],
    };
    const [selected, setSelected] = React.useState<readonly string[]>([]);
    const [order, setOrder] = React.useState<Order>("desc");
    const [orderBy, setOrderBy] = React.useState<keyof DataSourceInterface>("id");
    const [page, setPage] = React.useState(0);
    const [rowsPerPage, setRowsPerPage] = React.useState(10);
    const [idPerfilSession, setIdPerfilSession] = useState<number>(0);
    const [assignCategoriesDialogOpen, setAssignCategoriesDialogOpen] = useState<boolean>(false);
    const [dataAssignCategories, setDataAssignCategories] = useState<any>(null);
    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = dataSource.map((n) => n.id.toString());
            setSelected(newSelected);
            return;
        }
        setSelected([]);
    };
    const handleRequestSort = (
        event: React.MouseEvent<unknown>,
        property: keyof DataSourceInterface
    ) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };
    const handleChangeRowsPerPage = (event: any, newValue: number | null) => {
        setRowsPerPage(parseInt(newValue!.toString(), 10));
        setPage(0);
    };
    const getLabelDisplayedRowsTo = () => {
        if (dataSource.length === -1) {
            return (page + 1) * rowsPerPage;
        }
        return rowsPerPage === -1
            ? dataSource.length
            : Math.min(dataSource.length, (page + 1) * rowsPerPage);
    };
    const handleChangePage = (newPage: number) => {
        setPage(newPage);
    };
    const isSelected = (solicitud: number) => selected.indexOf(solicitud.toString()) !== -1;
    const handleClick = (event: React.MouseEvent<unknown>, name: number) => {
        const selectedIndex = selected.indexOf(name.toString());
        let newSelected: readonly string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name.toString());
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1)
            );
        }
        setSelected(newSelected);
    };
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - dataSource.length) : 0;

    const GetMnIdPerfil = async () => {
        const account = appConfig.msalInstance.getActiveAccount();
        if (![null, undefined].includes(account)) {
            const response = await ObtenerListas.ObtenerIdPerfil(account.username);
            if (response?.length > 0) {
                let idPerfil = response[0]?.idenT_PERFIL;
                setIdPerfilSession(idPerfil);
                if (idPerfil !== appConfigPerfiles.CodigoJefeCredito &&
                    idPerfil !== appConfigPerfiles.CodigoSupervisorCredito ) {
                    navigate(`/error/404`);
                }
            } else {
                navigate(`/error/404`);
            }
        } else {
            navigate(`/error/404`);
        }
    }

    const ListarUsuarioAsignacion = async () => {
        const response = await ObtenerListas.UsuarioAsignacion();
        let dataSourceTemp: DataSourceInterface[] = [];
        if (response.length > 0) {
            for (let item of response) {
                let dataItemTemp: any = {};
                dataItemTemp.id = item.identUsuario;
                dataItemTemp.usuario = item.username;
                dataItemTemp.rol = item.rol;
                dataItemTemp.perfil = item.perfil;
                dataItemTemp.etapaI = item.etapaI;
                dataItemTemp.etapaII = item.etapaII;
                dataItemTemp.activar = item.estado ? 1 : 0;
                dataSourceTemp.push(dataItemTemp);
            }
        }
        setDataSource(dataSourceTemp);
    }

    const UsuarioStatusUpdate = async (identUsuario: number, estado: boolean) => {
        const response = await General.UsuarioStatusUpdate(identUsuario, estado);
        if (!response) {
            for (let item of dataSource) {
                if (identUsuario == item.id) {
                    item.activar = estado ? 0 : 1;
                }
            }
            toast.error(appConfigKey.keyMsjErrorEnServicio, { duration: appConfigKey.keyDurationToast });
        }
    }

    const InitDefaultBandejaGestionEquipo = async () => {
        try {
            setShowLoading(true);
            await GetMnIdPerfil();
            await ListarUsuarioAsignacion();
            setShowLoading(false);

        } catch {
            setShowLoading(false);
        }

    }
    const handleAssignCategoriesDialogClose = () => {
        setAssignCategoriesDialogOpen(false);
    };
    const handleAssignCategoriesDialogOpen = (item: any) => {
        setDataAssignCategories({ "identUsuario": item.id, "usuario": item.usuario, "rol": item.rol });
        setAssignCategoriesDialogOpen(true);
    };


    useEffect(() => {
        InitDefaultBandejaGestionEquipo();
    }, []);

    return (
        <MsalAuthenticationTemplate
            interactionType={InteractionType.None}
            authenticationRequest={authRequest}
            errorComponent={ErrorComponent}
            loadingComponent={Loading}
        >
            <Toaster
                position="top-center"
                toastOptions={{ style: { width: '500px', margin: '0 auto' } }}
                richColors closeButton
            />
            {showLoading && <LoadingProgress />}
            <MainLayout />
            <CardTitleStart Ventana={"Gestión de Equipo"}></CardTitleStart>
            <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 card cardContentTrayOfRequest">
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12">
                        <div className="col-lg-4">
                            <span className="fw-bold">Total {dataSource.length}</span>
                        </div>
                        <div className="col-lg-4">
                            <span className="fw-bold"> </span>
                        </div>
                        <div className="col-lg-4 textAlignRigth"></div>
                    </div>
                    <div className="row g-2 mb-2">
                        <div className="col-lg-12 tableContainer">
                            <Sheet
                                variant="outlined"
                                sx={{ width: "100%", boxShadow: "sm", borderRadius: "sm" }}
                            >
                                <Table
                                    className="style-table"
                                    aria-labelledby="tableTitle"
                                    hoverRow
                                    sx={{
                                        "--TableCell-headBackground": "transparent",
                                        "--TableCell-selectedBackground": (theme) =>
                                            theme.vars.palette.primary.softBg,
                                        "& thead th:nth-child(1)": { width: "5%", textAlign: "center" },
                                        "& thead th:nth-child(2)": { width: "9%", textAlign: "center" },
                                        "& thead th:nth-child(3)": {
                                            width: "15%", textAlign: "center"
                                        },
                                        "& thead th:nth-child(4)": { width: "16%", textAlign: "center" },
                                        "& thead th:nth-child(5)": {
                                            width: "16%", textAlign: "center"
                                        },
                                        "& thead th:nth-child(6)": {
                                            width: "16%", textAlign: "center"
                                        },
                                        "& thead th:nth-child(7)": {
                                            width: "10%", textAlign: "center"
                                        },
                                        "& tr > *:nth-child(n+3)": {},
                                    }}
                                >
                                    <EnhancedTableHead
                                        numSelected={selected.length}
                                        order={order}
                                        orderBy={orderBy}
                                        onSelectAllClick={handleSelectAllClick}
                                        onRequestSort={handleRequestSort}
                                        rowCount={dataSource.length}
                                    />
                                    <tbody>
                                        {tableUtilHelper.stableSort(dataSource, tableUtilHelper.getComparator(order, orderBy))
                                            .slice(
                                                page * rowsPerPage,
                                                page * rowsPerPage + rowsPerPage
                                            )
                                            .map((row, index) => {
                                                const isItemSelected = isSelected(row.id);
                                                const labelId = `enhanced-table-checkbox-${index}`;

                                                return (
                                                    <tr
                                                        onClick={(event) =>
                                                            handleClick(event, row.id)
                                                        }
                                                        role="checkbox"
                                                        aria-checked={isItemSelected}
                                                        tabIndex={-1}
                                                        key={row.id}
                                                        style={
                                                            isItemSelected
                                                                ? ({
                                                                    "--TableCell-dataBackground":
                                                                        "var(--TableCell-selectedBackground)",
                                                                    "--TableCell-headBackground":
                                                                        "var(--TableCell-selectedBackground)",
                                                                } as React.CSSProperties)
                                                                : {}
                                                        }
                                                    >
                                                        <td style={{ textAlign: 'center', display: 'none' }} id={labelId} scope="row">
                                                            {<a className="colorHref">
                                                                {row.id}
                                                            </a>}
                                                        </td>
                                                        <td style={{ textAlign: 'center' }}>{row.usuario}</td>
                                                        <td style={{ textAlign: 'center' }}>{row.perfil}</td>
                                                        <td style={{ textAlign: 'center' }}>{row.rol}</td>
                                                        <td style={{ textAlign: 'center' }}>{row.etapaI}</td>
                                                        <td style={{ textAlign: 'center' }}>{row.etapaII}</td>
                                                        {/*<td style={{ textAlign: 'center' }}>
                                                            <Switch
                                                                color={row.activar === 1 ? "success" : "neutral"}
                                                                checked={row.activar === 1}
                                                                onChange={(event: any) => {
                                                                    for (let item of dataSource) {
                                                                        if (row.id == item.id) {
                                                                            item.activar = event.target.checked ? 1 : 0;
                                                                            UsuarioStatusUpdate(row.id, event.target.checked);
                                                                        }
                                                                    }
                                                                }}
                                                                slotProps={{
                                                                    track: {
                                                                        children: (
                                                                            <React.Fragment>
                                                                                <Typography
                                                                                    component="span"
                                                                                    level="inherit"
                                                                                    sx={{ ml: "10px" }}
                                                                                >
                                                                                    Si
                                                                                </Typography>
                                                                                <Typography
                                                                                    component="span"
                                                                                    level="inherit"
                                                                                    sx={{ mr: "8px" }}
                                                                                >
                                                                                    No
                                                                                </Typography>
                                                                            </React.Fragment>
                                                                        ),
                                                                    },
                                                                }}
                                                                sx={{
                                                                    "--Switch-thumbSize": "27px",
                                                                    "--Switch-trackWidth": "64px",
                                                                    "--Switch-trackHeight": "31px",
                                                                }}
                                                            />

                                                        </td>*/}
                                                        <td style={{ textAlign: 'center' }}>
                                                            {row.activar === 1 && (<div>
                                                                <Dropdown key={row.id}>
                                                                    <Dropdown.Toggle
                                                                        as={CustomDivToggle}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        <BsThreeDots />
                                                                    </Dropdown.Toggle>
                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item
                                                                            onClick={(e: any) =>
                                                                                handleAssignCategoriesDialogOpen(row)
                                                                            }
                                                                        >
                                                                            Asignar categorias
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown>
                                                            </div>)}
                                                        </td>
                                                    </tr>
                                                );
                                            })}
                                        {emptyRows > 0 && (
                                            <tr
                                                style={
                                                    {
                                                        height: `calc(${emptyRows} * 40px)`,
                                                        "--TableRow-hoverBackground": "transparent",
                                                    } as React.CSSProperties
                                                }
                                            >
                                                <td colSpan={6} />
                                            </tr>
                                        )}
                                    </tbody>

                                    <tfoot>
                                        <tr>
                                            <td colSpan={6}>
                                                <Box
                                                    sx={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        gap: 2,
                                                        justifyContent: "flex-end",
                                                    }}
                                                >
                                                    <FormControl orientation="horizontal" size="md">
                                                        <FormLabel>Líneas por páginas:</FormLabel>
                                                        <Select
                                                            onChange={handleChangeRowsPerPage}
                                                            value={rowsPerPage}
                                                        >
                                                            <Option value={5}>5</Option>
                                                            <Option value={10}>10</Option>
                                                            <Option value={25}>25</Option>
                                                            <Option value={50}>50</Option>
                                                            <Option value={100}>100</Option>
                                                        </Select>
                                                    </FormControl>
                                                    <Typography
                                                        textAlign="center"
                                                        sx={{ minWidth: 80 }}
                                                    >
                                                        {tableUtilHelper.labelDisplayedRows({
                                                            from:
                                                                dataSource.length === 0
                                                                    ? 0
                                                                    : page * rowsPerPage + 1,
                                                            to: getLabelDisplayedRowsTo(),
                                                            count:
                                                                dataSource.length === -1
                                                                    ? -1
                                                                    : dataSource.length,
                                                        })}
                                                    </Typography>
                                                    <Box sx={{ display: "flex", gap: 1 }}>
                                                        <IconButton
                                                            size="sm"
                                                            color="neutral"
                                                            variant="outlined"
                                                            disabled={page === 0}
                                                            onClick={() => handleChangePage(page - 1)}
                                                            sx={{ bgcolor: "background.surface" }}
                                                        >
                                                            <KeyboardArrowLeftIcon />
                                                        </IconButton>
                                                        <IconButton
                                                            size="sm"
                                                            color="neutral"
                                                            variant="outlined"
                                                            disabled={
                                                                dataSource.length !== -1
                                                                    ? page >=
                                                                    Math.ceil(
                                                                        dataSource.length / rowsPerPage
                                                                    ) -
                                                                    1
                                                                    : false
                                                            }
                                                            onClick={() => handleChangePage(page + 1)}
                                                            sx={{ bgcolor: "background.surface" }}
                                                        >
                                                            <KeyboardArrowRightIcon />
                                                        </IconButton>
                                                    </Box>
                                                </Box>
                                            </td>
                                        </tr>
                                    </tfoot>
                                </Table>

                            </Sheet>
                        </div>
                    </div>
                </div>
                <AssignCategoriesDialog
                    data={dataAssignCategories}
                    open={assignCategoriesDialogOpen}
                    handleClose={handleAssignCategoriesDialogClose}
                    listarUsuarioAsignacion={ListarUsuarioAsignacion}
                />
            </div>

        </MsalAuthenticationTemplate>
    );
}