export class TMnSolicitudObservadaRequest {
  idenT_SOLICITUD_OBSERVADA: number = 0;
  idenT_SOLICITUD: number = 0;
}

export class TMnSolicitudObservadaInsertUpdateRequest {
  idenT_SOLICITUD_OBSERVADA: number = 0;
  idenT_SOLICITUD: number = 0;
  idenT_AREA_SITUACION: number = 0;
  idenT_SITUACION: number = 0;
  descC_SUSTENTO: string = "";
  descC_COMENTARIO: string | null = null;
  inD_ESTADO: boolean = true;
  idenT_USUARIO: number = 0;
}