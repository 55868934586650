import { appConfigKey } from "../../../../../config/Config";
import { GesintelItem } from "../../../../models/observed-member.model";

class GesintelHelper {

    static TransformarContentToList(content?: any): Array<GesintelItem> | null {
        let gesintelList: Array<GesintelItem> = [];
        if (!content) return gesintelList;

        const observations: string[] = []

        const pepResults = content?.results?.pepResults;
        const pepHResults = content?.results?.pepHResults;
        const pepCResults = content?.results?.pepCResults;
        const fpResults = content?.results?.fpResults;
        const pjudResults = content?.results?.pjudResults;
        const personResults = content?.results?.personResults;
        const negativeResults = content?.results?.negativeResults;
        const vipResults = content?.results?.vipResults;

        const djResults = content?.results?.djResults;
        const wlResults = djResults?.wlResults;
        const ameResults = djResults?.ameResults;
        const socResults = djResults?.socResults;

        if (pepResults && pepResults.length > 0) {
            observations.push(appConfigKey.keyDescripPEPVigente);
        }

        if (pepHResults && pepHResults.length > 0) {
            observations.push(appConfigKey.keyDescripPEPHistorico);
        }

        if (pepCResults && pepCResults.length > 0) {
            observations.push(appConfigKey.keyDescripPEPCandidato);
        }

        if (fpResults && fpResults.length > 0) {
            observations.push(appConfigKey.keyDescripFuncionarioPublico);
        }

        if (pjudResults && pjudResults.length > 0) {
            observations.push(appConfigKey.keyDescripPoderJudicial);
        }

        if (personResults && personResults.length > 0) {
            observations.push(appConfigKey.keyDescripPersonasInteres);
        }

        if (negativeResults && negativeResults.length > 0) {
            observations.push(appConfigKey.keyDescripNegatividad);
        }

        if (vipResults && vipResults.length > 0) {
            observations.push(appConfigKey.keyDescripPersonasVIP);
        }

        if ((wlResults && wlResults.length > 0) || (ameResults && ameResults.length > 0) || (socResults && socResults.length > 0)) {
            observations.push(appConfigKey.keyDescripInternacionalesDJ);
        }

        gesintelList = observations.map(s => { return { descripcion: s } as GesintelItem });

        return gesintelList;
    }

}

export default GesintelHelper;