import { createContext, useContext, useEffect, useState } from "react";
import { DatosPersonalesDelTitular } from "../../3.40.-SolicitudeStepOne/components/DatosPersonalesDelTitularCard/interfaces";
import { DatosPersonalesDelConyuge } from "../../3.40.-SolicitudeStepOne/components/DatosPersonalesDelConyugeCard/interfaces";
import { General, ObtenerListas } from "../obtenerServicios";
import { appConfigGenero, appConfigKey, appConfigPerfiles, appSecurityConfiguration } from "../../../../../config/Config";
import FiadoresHelper from "../helpers/fiadoresHelper";
import ValidacionStepOneHelper from "../helpers/ValidacionStepOneHelper";
import { useParams } from "react-router-dom";
import { ConvertidorHelper } from "../../3.40.-SolicitudeStepOne/helpers";
import { ModelicaBodyDefault, ModelicaLoadStepOneData, ModelicaStepOneDataInteface } from "../helpers/GenerarJsonHelper";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import { StepsContext } from "./StepsProvider";
import { LaboresAdicionalesTitular } from "../../3.40.-SolicitudeStepOne/components/DatosLaboralesTitularCard/interfaces";
import { LaboresAdicionalesConyuge } from "../../3.40.-SolicitudeStepOne/components/DatosLaboralesDelConyuge/interfaces";
import { obtenerTipoMoneda } from "../../../../helpers/ModelicaMotorHelper";
import JsonHelper from "../../../../helpers/JsonHelper";
import { toast } from "sonner";
import WindowHelper from "../../../../helpers/WindowHelper";
import { TMnSolicitudPersonaPorValidarRequest } from "../../../../models/MasterManager/solicitud-persona-observada-request.model";
import { MnFormatoRegimenDto } from "../../../../models/RequestManager/solicitud-formato-regimen-response.model";
import { GenerarMnFormatoRegimenRequest, MnFormatoRegimenUpdateRequest } from "../../../../models/RequestManager/solicitud-formato-regimen-request.model";

export const StepOneContext = createContext<any>({});

export const StepOneProvider = ({ children }: any) => {

    const { solicitudId } = useParams();

    const {
        solicitudData,
        setSolicitudData,
        setClickNext,
        step,
        setBtnContinuarLoading,
        setResponseStepOne,
        setViewModelStepOneFinal,
        setStep,
        modelicaRequestBody,
        setModelicaRequestBody,
        setLoadingSteps,
        retrocedioAlPrimerPaso,
        setRequestTitularIngresos,
        setRequestConyugeIngresos,
        viewModelStepOneFinal,
        setRequestStepOne,
        setMessageCustomerIsBlackListGesintel,
        setDetailCustomerIsBlackListGesintel,
        listTipoDocumento,
        listEstadoCivil,
        listIdentidadesGenero,
        listMagnitud,
        listActividadEconomica,
        listCategoriaLaboral,
        listMoneda,
        listPais,
        listProfesion,
        listRegimen,
        listRangoVentaAnual,
        listGiroNegocio,
        readonlyForm,
        setReadonlyForm,
        TCEA,
        updateSolicitudValue,
        setUpdateSolicitudValue,
        isTitularJuridico,
        setTitularJuridico
    } = useContext(StepsContext);

    const [titularData, setTitularData] = useState<DatosPersonalesDelTitular | number>(0);
    const [titularDataAntes, setTitularDataAntes] = useState<DatosPersonalesDelTitular | number>(0);
    const [laboresAdicionalesTitularData, setLaboresAdicionalesTitularData] = useState<LaboresAdicionalesTitular | number>(0);
    const [ingresosTitularData, setIngresosTitularData] = useState<any>(0);
    const [conyugeData, setConyugeData] = useState<DatosPersonalesDelConyuge | number>(0);
    const [conyugeDataAntes, setConyugeDataAntes] = useState<DatosPersonalesDelConyuge | number>(0);
    const [laboresAdicionalesConyugeData, setLaboresAdicionalesConyugeData] = useState<LaboresAdicionalesConyuge | number>(0);
    const [ingresosConyugeData, setIngresosConyugeData] = useState(0);
    const [fiadorData, setFiadorData] = useState(0);
    const [personaCompraParaConyugeData, setPersonaCompraParaConyugeData] = useState(0);
    const [personaCompraParaData, setPersonaCompraParaData] = useState(0);
    const [estadosFinancierosData, setEstadosFinancierosData] = useState(0);

    const [newTitularData, setNewTitularData] = useState<any>(0);
    const [newIngresosTitularData, setNewIngresosTitularData] = useState<any>(0);
    const [newLaboresAdicionalesTitularData, setNewLaboresAdicionalesTitularData] = useState<any>(0);

    const [newConyugeData, setNewConyugeData] = useState<any>(0);
    const [newIngresosConyugeData, setNewIngresosConyugeData] = useState<any>(0);
    const [newLaboresAdicionalesConyugeData, setNewLaboresAdicionalesConyugeData] = useState<any>(0);

    const [newFiadorData, setNewFiadorData] = useState<any>(0);
    const [newPersonaCompraParaData, setNewPersonaCompraParaData] = useState<any>(0);
    const [newPersonaCompraParaConyugeData, setNewPersonaCompraParaConyugeData] = useState<any>(0);
    const [newObservacionData, setNewObservacionData] = useState<any>(0);
    const [formatoRegimen, setFormatoRegimen] = useState<MnFormatoRegimenDto | number>(0);
    const [formatoRegimenLoading, setFormatoRegimenLoading] = useState<boolean>(false);

    const resetSendClick = () => {
        setNewTitularData(0);
        //console.log("LOG: Limpiando ingresos titular");
        setNewIngresosTitularData(0);
        setNewConyugeData(0);
        setNewIngresosConyugeData(0);
        setNewFiadorData(0);
        setNewPersonaCompraParaData(0);
        setNewPersonaCompraParaConyugeData(0);
        setNewObservacionData(0);
        setClickNext(0);
        setBtnContinuarLoading(false);
        setLoadingSteps(false);
    };

    const GenerateActualizacionSolicitudV2 = async (body: any) => {
        return await General.GenerateActualizacionSolicitudV2(body);
    };

    const generarMnFormatoByRegimen = async (identRegimen: number) => {
        setFormatoRegimenLoading(true);
        let formatoRequest = new GenerarMnFormatoRegimenRequest();
        formatoRequest.identRegimen = identRegimen;
        formatoRequest.identSolicitud = solicitudData?.idenT_SOLICITUD;
        formatoRequest.identUsuario = appSecurityConfiguration.SessionUserId;
        formatoRequest.identPerfil = appSecurityConfiguration.SessionPerfil;
        const response = await General.GenerarMnFormatoByRegimen(formatoRequest);
        setFormatoRegimenLoading(false);
        if (!response) {
            toast.error(appConfigKey.keyMsjErrorObtenerFormatoRegimen, { duration: appConfigKey.keyDurationToast });
            setFormatoRegimen(0);
            return;
        }
        setFormatoRegimen(response);
    }

    const updateMnFormatoRegimen = async (request: MnFormatoRegimenUpdateRequest) => {
        return await General.UpdateMnFormatoRegimen(request);
    }

    const continuarSiguientePaso = async () => {

        if (newTitularData !== 0 && newIngresosTitularData !== 0 && newFiadorData !== 0 && newPersonaCompraParaData !== 0 && newObservacionData !== 0 && step === 1) {

            if (readonlyForm) {
                setViewModelStepOneFinal({
                    newTitularData,
                    newIngresosTitularData,
                    newLaboresAdicionalesTitularData,
                    newConyugeData,
                    newIngresosConyugeData,
                    newLaboresAdicionalesConyugeData,
                    newFiadorData,
                    newPersonaCompraParaData,
                    newPersonaCompraParaConyugeData,
                    newObservacionData,
                });
                setStep(step + 1);
                resetSendClick();
                return
            }

            const validacionNewTitularData = !ValidacionStepOneHelper.NewTitularData(newTitularData, newIngresosTitularData, isTitularJuridico);

            if (validacionNewTitularData) {
                resetSendClick();
                return;
            }

            const validacionNewIngresosTitularData = !ValidacionStepOneHelper.NewIngresosTitularData(newIngresosTitularData, newTitularData, isTitularJuridico);

            if (validacionNewIngresosTitularData) {
                resetSendClick();
                return;
            }

            if (newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado || newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente) {

                if (newConyugeData !== 0 && newIngresosConyugeData !== 0) {

                    const validacionNewConyugeData = !ValidacionStepOneHelper.NewConyugeData(newConyugeData);

                    if (validacionNewConyugeData) {
                        resetSendClick();
                        return;
                    }

                    const validacionNewIngresosConyugeData = !ValidacionStepOneHelper.NewIngresosConyugeData(newIngresosConyugeData);

                    if (validacionNewIngresosConyugeData) {
                        resetSendClick();
                        return;
                    }

                }

            }

            const listaFiadores = newFiadorData.ListaFiadores;

            for (let index = 0; index < listaFiadores.length; index++) {
                const element = listaFiadores[index];

                if (!ValidacionStepOneHelper.NewFiadorData(element)) {
                    resetSendClick();
                    return;
                }
            }

            const validacionNewPersonaCompraParaData = !ValidacionStepOneHelper.NewPersonaCompraParaData(newPersonaCompraParaData);

            if (validacionNewPersonaCompraParaData) {
                resetSendClick();
                return;
            }

            if (GeneralHelper.ParseNumber(newPersonaCompraParaData.EstadoCivilId) === appConfigKey.keyIdEstadoCivilCasado || newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente) {

                if (newPersonaCompraParaConyugeData !== 0) {

                    const validacionNewPersonaCompraParaConyugeData = !ValidacionStepOneHelper.NewPersonaCompraParaConyugeData(newPersonaCompraParaConyugeData);

                    if (validacionNewPersonaCompraParaConyugeData) {
                        resetSendClick();
                        return;
                    }

                }

            }

            const otroIngresosModelica: any[] = [];

            newIngresosTitularData.listaOtrosTrabajos.map((item: any) => {

                let tempIngresoAnualizadoTitularOtro = 0;

                if (item.anualizado) {
                    tempIngresoAnualizadoTitularOtro = Math.trunc(Number(GeneralHelper.QuitarComasAMiles(item.calculoAnualizado)));
                } else {
                    tempIngresoAnualizadoTitularOtro = Math.trunc(Number(GeneralHelper.QuitarComasAMiles(item.ingresosNetos)));
                }

                const tempOtroIngresoModelica = {
                    "ruc": item.ruc,
                    "categoria_Laboral": item.identCategoriaLaboral,
                    "fecha_Inicio_Actividad": item.fechaIngresoLaboral ?? '',
                    "ingreso_Anualizado": tempIngresoAnualizadoTitularOtro,
                    "ingreso_Mensual": Math.trunc(Number(GeneralHelper.QuitarComasAMiles(item.ingresosNetos))),
                    "moneda": Number(item.identTipoMoneda),
                    "regimen_tributario": null,
                };

                if (item.identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralTres) {
                    tempOtroIngresoModelica["regimen_tributario"] = item.regimenId;
                }

                otroIngresosModelica.push(tempOtroIngresoModelica);

            });

            const otroIngresosConyugeModelica: any[] = [];

            if (newIngresosConyugeData !== 0) {

                newIngresosConyugeData.listaOtrosTrabajos.map((item: any) => {

                    let tempIngresoAnualizadoConyugeOtro = 0;

                    if (item.anualizado) {
                        tempIngresoAnualizadoConyugeOtro = Math.trunc(Number(GeneralHelper.QuitarComasAMiles(item.calculoAnualizado)));
                    } else {
                        tempIngresoAnualizadoConyugeOtro = Math.trunc(Number(GeneralHelper.QuitarComasAMiles(item.ingresosNetos)));
                    }

                    const tempOtroIngresoModelica = {
                        "ruc": item.ruc,
                        "categoria_Laboral": item.identCategoriaLaboral,
                        "fecha_Inicio_Actividad": item.fechaIngresoLaboral ?? '',
                        "ingreso_Anualizado": tempIngresoAnualizadoConyugeOtro,
                        "ingreso_Mensual": Math.trunc(Number(GeneralHelper.QuitarComasAMiles(item.ingresosNetos))),
                        "moneda": Number(item.identTipoMoneda),
                        "regimen_tributario": null,
                    };

                    if (item.identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralTres) {
                        tempOtroIngresoModelica["regimen_tributario"] = item.regimenId;
                    }

                    otroIngresosConyugeModelica.push(tempOtroIngresoModelica);

                });

            }

            let tempIngresoAnualizadoTitular = 0;

            if (newIngresosTitularData.anualizado) {
                tempIngresoAnualizadoTitular = Math.trunc(Number(GeneralHelper.QuitarComasAMiles(newIngresosTitularData.calculoAnualizado)));
            } else {
                tempIngresoAnualizadoTitular = Math.trunc(Number(GeneralHelper.QuitarComasAMiles(newIngresosTitularData.ingresosNetos)));
            }

            let tempIngresoAnualizadoConyuge = 0;

            if (newIngresosConyugeData !== 0 && newIngresosConyugeData.anualizado) {
                tempIngresoAnualizadoConyuge = Math.trunc(Number(GeneralHelper.QuitarComasAMiles(newIngresosConyugeData.calculoAnualizado)));
            } else if (newIngresosConyugeData !== 0 && !newIngresosConyugeData.anualizado) {
                tempIngresoAnualizadoConyuge = Math.trunc(Number(GeneralHelper.QuitarComasAMiles(newIngresosConyugeData.ingresosNetos)));
            }

            let validacionEnviarDatosConyugeMotor = newConyugeData !== 0;

            if (newTitularData.SeparacionBienes) {
                validacionEnviarDatosConyugeMotor = false;
            }

            const modelicaModel: ModelicaStepOneDataInteface = {
                DatosCliente: {
                    TipoDocumento: newTitularData.TipoDocumentoId,
                    ActividadEconomica: newTitularData.ActividadEconomicaId,
                    EstadoCivil: newTitularData.EstadoCivilId,
                    FechaNacimiento: newTitularData.FechaNacimiento,
                    Genero: newTitularData.GeneroId === appConfigGenero.CODIGO_MASCULINO ? 1 : 2,
                    Magnitud: newTitularData.MagnitudId,
                    NumeroDocumento: newTitularData.NumeroDocumento.trim()
                },
                DatosConyuge: validacionEnviarDatosConyugeMotor ? {
                    TipoDocumento: newConyugeData.TipoDocumentoId,
                    FechaNacimiento: newConyugeData.FechaNacimiento,
                    Genero: newConyugeData.GeneroId === appConfigGenero.CODIGO_MASCULINO ? 1 : 2,
                    NumeroDocumento: newConyugeData.NumeroDocumento.trim()
                } : null,
                IngresoDeclarado: {
                    IngresoTitular: {
                        CategoriaLaboral: newIngresosTitularData.identCategoriaLaboral,
                        Ruc: newIngresosTitularData.ruc,
                        Moneda: Number(newIngresosTitularData.identTipoMoneda),
                        FechaInicioActividad: newIngresosTitularData.fechaIngresoLaboral ?? '',
                        IngresoAnualizado: tempIngresoAnualizadoTitular,
                        IngresoMensual: Math.trunc(Number(GeneralHelper.QuitarComasAMiles(newIngresosTitularData.ingresosNetos))),
                        OtrosIngresos: otroIngresosModelica
                    },
                    IngresoConyuge: validacionEnviarDatosConyugeMotor ? {
                        CategoriaLaboral: newIngresosConyugeData.identCategoriaLaboral,
                        Ruc: newIngresosConyugeData.ruc,
                        Moneda: Number(newIngresosConyugeData.identTipoMoneda),
                        FechaInicioActividad: newIngresosConyugeData.fechaIngresoLaboral ?? '',
                        IngresoAnualizado: tempIngresoAnualizadoConyuge,
                        IngresoMensual: Math.trunc(Number(GeneralHelper.QuitarComasAMiles(newIngresosConyugeData.ingresosNetos))),
                        OtrosIngresos: otroIngresosConyugeModelica
                    } : null
                }

            };

            let modelicaBody: any = null;

            if (modelicaRequestBody === null) {
                modelicaBody = ModelicaLoadStepOneData(ModelicaBodyDefault, modelicaModel, solicitudData);
            }
            else {
                modelicaBody = ModelicaLoadStepOneData(modelicaRequestBody, modelicaModel, solicitudData);
            }

            const listasTemp = {
                listTipoDocumento,
                listEstadoCivil,
                listIdentidadesGenero,
                listMagnitud,
                listActividadEconomica,
                listCategoriaLaboral,
                listMoneda,
                listPais,
                listProfesion,
                listRegimen,
                listRangoVentaAnual,
                listGiroNegocio
            }

            // if (typeof titularDataAntes !== "number") {
            //     if (titularDataAntes.TipoDocumentoId !== newTitularData.TipoDocumentoId ||
            //         titularDataAntes.NumeroDocumento.trim() !== newTitularData.NumeroDocumento.trim()) {
            //         solicitudData.idenT_PERSONA_TITULAR = 0;
            //         General.PersonaByTipoDocAndNroDoc(newTitularData.TipoDocumentoId, newTitularData.NumeroDocumento.trim()).then((response) => {
            //             const persona: any = response;
            //             if (persona !== 0) {
            //                 solicitudData.idenT_PERSONA_TITULAR = persona?.idenT_PERSONA
            //             }
            //         });

            //     }

            //     const validarPareja = newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ||
            //         newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente;

            //     if (validarPareja) {
            //         if (newTitularData.EstadoCivilId === titularDataAntes.EstadoCivilId) {
            //             if (typeof conyugeDataAntes !== "number") {
            //                 if (conyugeDataAntes.TipoDocumentoId !== newConyugeData.TipoDocumentoId ||
            //                     conyugeDataAntes.NumeroDocumento.trim() !== newConyugeData.NumeroDocumento.trim()) {
            //                     solicitudData.idenT_PERSONA_CONYUGUE = 0;
            //                     General.PersonaByTipoDocAndNroDoc(newConyugeData.TipoDocumentoId, newConyugeData.NumeroDocumento.trim()).then((response) => {
            //                         const persona: any = response;
            //                         if (persona !== 0) {
            //                             solicitudData.idenT_PERSONA_CONYUGUE = persona?.idenT_PERSONA
            //                         }
            //                     });
            //                 }
            //             }
            //         } else {
            //             General.PersonaByTipoDocAndNroDoc(newConyugeData.TipoDocumentoId, newConyugeData.NumeroDocumento.trim()).then((response) => {
            //                 const persona: any = response;
            //                 if (persona !== 0) {
            //                     solicitudData.idenT_PERSONA_CONYUGUE = persona?.idenT_PERSONA
            //                 }
            //             });
            //         }
            //     }


            //     if (GeneralHelper.ParseString(newPersonaCompraParaData.NumeroDocumento) !== '') {
            //         if (personaCompraParaData !== 0) {
            //             const personaCompraParaDataAntes: any = personaCompraParaData;
            //             if (personaCompraParaDataAntes?.TipoDocumentoId !== newPersonaCompraParaData.TipoDocumentoId ||
            //                 personaCompraParaDataAntes?.NumeroDocumento.trim() !== newPersonaCompraParaData.NumeroDocumento.trim()) {
            //                 solicitudData.idenT_PERSONA_COMPRA_PARA = 0;
            //                 General.PersonaByTipoDocAndNroDoc(newPersonaCompraParaData.TipoDocumentoId, newPersonaCompraParaData.NumeroDocumento.trim()).then((response) => {
            //                     const persona: any = response;
            //                     if (persona !== 0) {
            //                         solicitudData.idenT_PERSONA_COMPRA_PARA = persona?.idenT_PERSONA
            //                     }
            //                 });

            //             }
            //         } else {
            //             solicitudData.idenT_PERSONA_COMPRA_PARA = 0;
            //             General.PersonaByTipoDocAndNroDoc(newPersonaCompraParaData.TipoDocumentoId, newPersonaCompraParaData.NumeroDocumento.trim()).then((response) => {
            //                 const persona: any = response;
            //                 if (persona !== 0) {
            //                     solicitudData.idenT_PERSONA_COMPRA_PARA = persona?.idenT_PERSONA
            //                 }
            //             });

            //         }


            //         if (GeneralHelper.ParseNumber(newPersonaCompraParaData.EstadoCivilId) === appConfigKey.keyIdEstadoCivilCasado &&
            //             GeneralHelper.ParseString(newPersonaCompraParaConyugeData.NumeroDocumento) !== '') {
            //             if (personaCompraParaConyugeData !== 0) {
            //                 const personaCompraParaConyugeDataAntes: any = personaCompraParaConyugeData;
            //                 if (personaCompraParaConyugeDataAntes?.TipoDocumentoId !== newPersonaCompraParaConyugeData.TipoDocumentoId ||
            //                     personaCompraParaConyugeDataAntes?.NumeroDocumento.trim() !== newPersonaCompraParaConyugeData.NumeroDocumento.trim()) {
            //                     solicitudData.idenT_PERSONA_COMPRA_PARA_CONYUGUE = 0;
            //                     General.PersonaByTipoDocAndNroDoc(newPersonaCompraParaConyugeData.TipoDocumentoId, newPersonaCompraParaConyugeData.NumeroDocumento.trim()).then((response) => {
            //                         const persona: any = response;
            //                         if (persona !== 0) {
            //                             solicitudData.idenT_PERSONA_COMPRA_PARA_CONYUGUE = persona?.idenT_PERSONA
            //                         }
            //                     });

            //                 }

            //             } else {
            //                 solicitudData.idenT_PERSONA_COMPRA_PARA_CONYUGUE = 0;
            //                 General.PersonaByTipoDocAndNroDoc(newPersonaCompraParaData.TipoDocumentoId, newPersonaCompraParaData.NumeroDocumento.trim()).then((response) => {
            //                     const persona: any = response;
            //                     if (persona !== 0) {
            //                         solicitudData.idenT_PERSONA_COMPRA_PARA_CONYUGUE = persona?.idenT_PERSONA
            //                     }
            //                 });

            //             }
            //         } else {
            //             solicitudData.idenT_PERSONA_COMPRA_PARA_CONYUGUE = 0;
            //         }
            //     }
            // }

            setRequestStepOne(modelicaModel);

            //Modelica
            if (!isTitularJuridico) {
                const modelicaSuccess = await General.Modelica(modelicaBody).then(async (response) => {

                    const jsonResponseModelica = JSON.parse(response?.data?.content);

                    setModelicaRequestBody(modelicaBody);

                    const validacionNewModelicaData = ValidacionStepOneHelper.NewModelicaData(response);

                    if (validacionNewModelicaData) {
                        toast.error(validacionNewModelicaData, { duration: appConfigKey.keyDurationToast });
                        return false;
                    }

                    await General.GetMnEvaluacionSolicitudInsert(
                        solicitudData.idenT_SOLICITUD,
                        appConfigKey.EvaluacionModelicaStepOne,
                        JSON.stringify(modelicaBody),
                        response?.data?.content
                    );

                    setResponseStepOne(jsonResponseModelica);

                    let validacionModelicaCMA = ValidacionStepOneHelper.ModelicaCMA(response);

                    if (validacionModelicaCMA) {
                        toast.info(validacionModelicaCMA, { duration: appConfigKey.keyDurationToast });
                        // resetSendClick();
                        // return;
                    }

                    return true;
                }).catch(error => {
                    return false;
                });

                if (!modelicaSuccess) {
                    resetSendClick();
                    return;
                }
            }

            if (isTitularJuridico) {
                const RequestUpdateFormatoRegimen = JsonHelper.crearJsonUpdateMnFormatoRegimen(
                    newIngresosTitularData,
                    appSecurityConfiguration.SessionUserId,
                );

                const updateMatrizSuccess = await updateMnFormatoRegimen(RequestUpdateFormatoRegimen);

                if (!updateMatrizSuccess) {
                    toast.error(appConfigKey.keyMsjErrorActualizarFormatoRegimen, { duration: appConfigKey.keyDurationToast });
                    resetSendClick();
                    return;
                }
            }

            const RequestGenerateActualizacionSolicitudV2 = JsonHelper.crearJsonRequestGenerateActualizacionSolicitudV2(
                step,
                solicitudData,
                newTitularData,
                newConyugeData,
                newIngresosTitularData,
                newIngresosConyugeData,
                newPersonaCompraParaData,
                newPersonaCompraParaConyugeData,
                newFiadorData,
                newObservacionData,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                null,
                listasTemp,
                appSecurityConfiguration.SessionUserId,
                TCEA,
                isTitularJuridico,
                null,
                null,
            );

            const updateSuccess = await GenerateActualizacionSolicitudV2(RequestGenerateActualizacionSolicitudV2).then(async (response) => {
                if (response?.status == 200 && response?.data?.isValid && response?.data?.content) {
                    //Obtener los id actualizados
                    const solicitudUpdated = response.data.content;
                    setSolicitudData({
                        ...solicitudData, idenT_PERSONA_COMPRA_PARA: solicitudUpdated.idenT_PERSONA_COMPRA_PARA,
                        idenT_PERSONA_COMPRA_PARA_CONYUGUE: solicitudUpdated.idenT_PERSONA_COMPRA_PARA_CONYUGUE
                    });
                    await cargarMiembros(solicitudUpdated);
                    return true
                } else {
                    return false
                }
            });

            if (!updateSuccess) {
                toast.error(appConfigKey.keyMsjErrorGuardarSolicitud, { duration: appConfigKey.keyDurationToast });
                resetSendClick();
                return;
            }

            setViewModelStepOneFinal({
                newTitularData,
                newIngresosTitularData,
                newLaboresAdicionalesTitularData,
                newConyugeData,
                newIngresosConyugeData,
                newLaboresAdicionalesConyugeData,
                newFiadorData,
                newPersonaCompraParaData,
                newPersonaCompraParaConyugeData,
                newObservacionData,
            });

            setStep(step + 1);
            resetSendClick();
        }
    };

    useEffect(() => { continuarSiguientePaso() }, [newTitularData, newIngresosTitularData, newConyugeData, newIngresosConyugeData, newFiadorData, newPersonaCompraParaData, newPersonaCompraParaConyugeData, newObservacionData]);

    const cargarConyuge = async (titular: any = null) => {
        const titularDataSource = titular ? titular : titularData;

        if (typeof titularDataSource !== "number") {

            const validarPareja = titularDataSource.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ||
                titularDataSource.EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente;

            if (validarPareja && GeneralHelper.ParseNumber(solicitudData.idenT_PERSONA_CONYUGUE) > 0) {

                await General.Persona(solicitudData.idenT_PERSONA_CONYUGUE).then((response) => {
                    const model = ConvertidorHelper.ObtenerDatosConyuge(response);
                    setConyugeData(model);
                    setConyugeDataAntes(model);
                });

                await ConvertidorHelper.ObtenerIngresos(solicitudData.idenT_PERSONA_CONYUGUE).then((response: any) => {
                    const { dataFormat, bodyResponse } = response;

                    setIngresosConyugeData(dataFormat);
                    setRequestConyugeIngresos(bodyResponse);
                    if (!GeneralHelper.EsObjetoNullOrUndefined(bodyResponse.empresaIngresoPrincipal)) {
                        const model = ConvertidorHelper.ObtenerDatosLaboresAdicionalesConyuge(bodyResponse.empresaIngresoPrincipal);
                        setLaboresAdicionalesConyugeData(model)
                    }

                });
            }
        }
    }

    const cargarFiadores = async () => {
        const tempFiadoresData = await General.Fiadores(solicitudId);
        const tempFiadores = FiadoresHelper.TransformarModeloFiadores(tempFiadoresData);
        const tempPersonas = await ConvertidorHelper.ObtenerPersonasPorFiadores(tempFiadores);
        const tempFiadoresInfo = await ConvertidorHelper.ObtenerFiadoresPersonas(tempPersonas, tempFiadores);
        const tempFiadorData: any = { Loading: false, ListaFiadores: tempFiadoresInfo };
        return tempFiadorData;
    };

    const cargarMiembros = async (solicitud: any = null) => {
        const solicitudSource = solicitud ? solicitud : solicitudData;

        if (solicitudSource !== 0) {

            let titularDataSource: DatosPersonalesDelTitular | null = null;

            await General.Persona(solicitudSource.idenT_PERSONA_TITULAR).then((response: any) => {
                const model = ConvertidorHelper.ObtenerDatosTitular(response, solicitudSource);
                titularDataSource = model;
                setTitularData(model);
                setTitularDataAntes(model);
            });

            await ConvertidorHelper.ObtenerIngresos(solicitudSource.idenT_PERSONA_TITULAR, solicitudSource.idenT_SOLICITUD).then(async (response: any) => {
                const { dataFormat, bodyResponse } = response;
                //console.log("LOG: Cargando ingresos desde http: ", dataFormat);
                setIngresosTitularData(dataFormat);
                setRequestTitularIngresos(bodyResponse);

                if (!GeneralHelper.EsObjetoNullOrUndefined(bodyResponse.empresaIngresoPrincipal)) {
                    const model = ConvertidorHelper.ObtenerDatosLaboresAdicionalesTitular(bodyResponse.empresaIngresoPrincipal);
                    setLaboresAdicionalesTitularData(model);
                }

                if ((!GeneralHelper.EsObjetoNullOrUndefined(dataFormat) && dataFormat.identCategoriaLaboral == appConfigKey.keyCodigoCategoriaLaboralTres) ||
                    titularDataSource?.TipoPersonaId == appConfigKey.keyIdPersonaJuridica) {
                    setTitularJuridico(true);
                }
            });

            await cargarConyuge(titularDataSource);

            if (GeneralHelper.ParseNumber(solicitudSource.idenT_PERSONA_COMPRA_PARA) > 0) {
                await General.Persona(solicitudSource.idenT_PERSONA_COMPRA_PARA).then((response: any) => {
                    const model = ConvertidorHelper.ObtenerDatosPersonaCompraPara(response);
                    setPersonaCompraParaData(model);
                });

            }

            if (GeneralHelper.ParseNumber(solicitudSource.idenT_PERSONA_COMPRA_PARA_CONYUGUE) > 0) {
                if (solicitudSource.idenT_PERSONA_COMPRA_PARA_CONYUGUE > 0) {
                    await General.Persona(solicitudSource.idenT_PERSONA_COMPRA_PARA_CONYUGUE).then((response: any) => {
                        const model = ConvertidorHelper.ObtenerDatosPersonaCompraParaConyuge(response);
                        setPersonaCompraParaConyugeData(model);
                    });
                }
            }

            await General.EstadosFinancieros(solicitudSource.idenT_PERSONA_TITULAR).then((response: any) => {
                const model = ConvertidorHelper.ObtenerEstadosFinancieros(response);
                setEstadosFinancierosData(model);
            });

            await cargarFiadores().then((response: any) => {
                setFiadorData(response);
            });
        }
    }

    const eventoTitularData = () => {
        if (retrocedioAlPrimerPaso) {
            return;
        }

        cargarConyuge();
    };

    const cargarDatosNuevamente = () => {
        setTitularData(viewModelStepOneFinal.newTitularData);
        //console.log("LOG: Cargando cache ingresos titular contexto:", viewModelStepOneFinal.newIngresosTitularData);
        setIngresosTitularData(viewModelStepOneFinal.newIngresosTitularData);
        setLaboresAdicionalesTitularData(viewModelStepOneFinal.newLaboresAdicionalesTitularData);

        setConyugeData(viewModelStepOneFinal.newConyugeData);
        setIngresosConyugeData(viewModelStepOneFinal.newIngresosConyugeData);
        setLaboresAdicionalesConyugeData(viewModelStepOneFinal.newLaboresAdicionalesConyugeData);

        setFiadorData(viewModelStepOneFinal.newFiadorData);

        setPersonaCompraParaData(viewModelStepOneFinal.newPersonaCompraParaData);
        setPersonaCompraParaConyugeData(viewModelStepOneFinal.newPersonaCompraParaConyugeData);
    }

    const eventoCargarSolicitud = () => {

        if (retrocedioAlPrimerPaso && readonlyForm) {
            cargarDatosNuevamente();
            return;
        }

        if (retrocedioAlPrimerPaso) {
            cargarDatosNuevamente();
            return;

        }

        cargarMiembros();

    };

    useEffect(() => eventoCargarSolicitud(), [solicitudData]);
    useEffect(() => eventoTitularData(), [titularData]);

    return (
        <StepOneContext.Provider value={{
            titularData,
            ingresosTitularData,
            conyugeData,
            ingresosConyugeData,
            fiadorData,
            personaCompraParaData,
            personaCompraParaConyugeData,
            estadosFinancierosData,
            setNewTitularData,
            setNewIngresosTitularData,
            setNewConyugeData,
            setNewIngresosConyugeData,
            setNewFiadorData,
            setNewPersonaCompraParaData,
            setNewPersonaCompraParaConyugeData,
            setNewObservacionData,
            laboresAdicionalesTitularData,
            setNewLaboresAdicionalesTitularData,
            laboresAdicionalesConyugeData,
            setNewLaboresAdicionalesConyugeData,
            generarMnFormatoByRegimen,
            formatoRegimen,
            setFormatoRegimen,
            formatoRegimenLoading,
            setFormatoRegimenLoading
        }}>
            {children}
        </StepOneContext.Provider>
    );
};