import { appDefaultValues } from "../../../../config/Config";

export class BTCrearPersonaFisicaDomicilio {
    private agrupador1Id: number;
    private departamento: string;
    private agrupador3Id: number;
    private agrupador4: string;
    private agrupador3: string;
    private codigo: string;
    private agrupador2: string;
    private codigoId: number;
    private agrupador1: string;
    private direccion: string;
    private ubigeo: string;
    private distrito: string;
    private distritoId: number;
    private paisId: number;
    private pais: string;
    private agrupador2Id: number;
    private codigoPostal: string;
    private provinciaId: number;
    private provincia: string;
    private agrupador4Id: number;
    private referencia: string;
    private departamentoId: number;

    constructor() {
        this.agrupador1Id = appDefaultValues.NumberDefault;
        this.departamento = appDefaultValues.StringEmpty;
        this.agrupador3Id = appDefaultValues.NumberDefault;
        this.agrupador4 = appDefaultValues.StringEmpty;
        this.agrupador3 = appDefaultValues.StringEmpty;
        this.codigo = appDefaultValues.StringEmpty;
        this.agrupador2 = appDefaultValues.StringEmpty;
        this.codigoId = appDefaultValues.NumberDefault;
        this.agrupador1 = appDefaultValues.StringEmpty;
        this.direccion = appDefaultValues.StringEmpty;
        this.ubigeo = appDefaultValues.StringEmpty;
        this.distrito = appDefaultValues.StringEmpty;
        this.distritoId = appDefaultValues.NumberDefault;
        this.paisId = appDefaultValues.NumberDefault;
        this.pais = appDefaultValues.StringEmpty;
        this.agrupador2Id = appDefaultValues.NumberDefault;
        this.codigoPostal = appDefaultValues.StringEmpty;
        this.provinciaId = appDefaultValues.NumberDefault;
        this.provincia = appDefaultValues.StringEmpty;
        this.agrupador4Id = appDefaultValues.NumberDefault;
        this.referencia = appDefaultValues.StringEmpty;
        this.departamentoId = appDefaultValues.NumberDefault;
    }

    public getAgrupador1Id(): number {
        return this.agrupador1Id;
    }

    public setAgrupador1Id(agrupador1Id: number): void {
        this.agrupador1Id = agrupador1Id;
    }

    public getDepartamento(): string {
        return this.departamento;
    }

    public setDepartamento(departamento: string): void {
        this.departamento = departamento;
    }

    public getAgrupador3Id(): number {
        return this.agrupador3Id;
    }

    public setAgrupador3Id(agrupador3Id: number): void {
        this.agrupador3Id = agrupador3Id;
    }

    public getAgrupador4(): string {
        return this.agrupador4;
    }

    public setAgrupador4(agrupador4: string): void {
        this.agrupador4 = agrupador4;
    }

    public getAgrupador3(): string {
        return this.agrupador3;
    }

    public setAgrupador3(agrupador3: string): void {
        this.agrupador3 = agrupador3;
    }

    public getCodigo(): string {
        return this.codigo;
    }

    public setCodigo(codigo: string): void {
        this.codigo = codigo;
    }

    public getAgrupador2(): string {
        return this.agrupador2;
    }

    public setAgrupador2(agrupador2: string): void {
        this.agrupador2 = agrupador2;
    }

    public getCodigoId(): number {
        return this.codigoId;
    }

    public setCodigoId(codigoId: number): void {
        this.codigoId = codigoId;
    }

    public getAgrupador1(): string {
        return this.agrupador1;
    }

    public setAgrupador1(agrupador1: string): void {
        this.agrupador1 = agrupador1;
    }

    public getDireccion(): string {
        return this.direccion;
    }

    public setDireccion(direccion: string): void {
        this.direccion = direccion;
    }

    public getUbigeo(): string {
        return this.ubigeo;
    }

    public setUbigeo(ubigeo: string): void {
        this.ubigeo = ubigeo;
    }

    public getDistrito(): string {
        return this.distrito;
    }

    public setDistrito(distrito: string): void {
        this.distrito = distrito;
    }

    public getDistritoId(): number {
        return this.distritoId;
    }

    public setDistritoId(distritoId: number): void {
        this.distritoId = distritoId;
    }

    public getPaisId(): number {
        return this.paisId;
    }

    public setPaisId(paisId: number): void {
        this.paisId = paisId;
    }

    public getPais(): string {
        return this.pais;
    }

    public setPais(pais: string): void {
        this.pais = pais;
    }

    public getAgrupador2Id(): number {
        return this.agrupador2Id;
    }

    public setAgrupador2Id(agrupador2Id: number): void {
        this.agrupador2Id = agrupador2Id;
    }

    public getCodigoPostal(): string {
        return this.codigoPostal;
    }

    public setCodigoPostal(codigoPostal: string): void {
        this.codigoPostal = codigoPostal;
    }

    public getProvinciaId(): number {
        return this.provinciaId;
    }

    public setProvinciaId(provinciaId: number): void {
        this.provinciaId = provinciaId;
    }

    public getProvincia(): string {
        return this.provincia;
    }

    public setProvincia(provincia: string): void {
        this.provincia = provincia;
    }

    public getAgrupador4Id(): number {
        return this.agrupador4Id;
    }

    public setAgrupador4Id(agrupador4Id: number): void {
        this.agrupador4Id = agrupador4Id;
    }

    public getReferencia(): string {
        return this.referencia;
    }

    public setReferencia(referencia: string): void {
        this.referencia = referencia;
    }

    public getDepartamentoId(): number {
        return this.departamentoId;
    }

    public setDepartamentoId(departamentoId: number): void {
        this.departamentoId = departamentoId;
    }

}