import React, { useContext, useEffect, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { Button } from "@mui/joy";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { IconButton, Tooltip } from "@mui/material";
import GeneralHelper from "../../../helpers/GeneralHelper";
import { DateInput, SelectInput, SimpleInput, SwitchInput, NumberInput, TextInput, SelectAutocompleteInput } from "../15.10.-base/elements/inputs";
import { ObtenerListas } from "../15.10.-base/obtenerServicios";
import { DatosInformeComercial, DatosInformeComercialDefault } from "../15.10.-base/interfaces";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SimbolAdd from "@mui/icons-material/Add";
import Save from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";
import { appDefaultValues, appSecurityConfiguration } from "../../../../config/Config";
import TimeHelper from "../../../helpers/TimeHelper";
import RequestManager from "../../../services/origination/wapirequest.service";
import { useParams } from "react-router-dom";
import MasterManager from "../../../services/origination/wapimaster.service";
import { toast } from "sonner";
import { InformeComercialContext } from "../15.10.-base/providers/informeComercialProvider";
import { AxiosError } from "axios";

export const DatosGeneralesAuditor = (props: any) => {
    const {
        IdSolicitud,
    } = useContext(InformeComercialContext);

    const [listActividadEconomica, setListActividadEconomica] = useState([]);
    const [listTipoDocumento, setListTipoDocumento] = useState([]);
    const [dataInformeComercial, setDataInformeComercial] = useState<DatosInformeComercial>(DatosInformeComercialDefault);

    const InitListas = async () => {
        await ObtenerListas.ActividadEconomica().then(response => {
            setListActividadEconomica(response);
        });
        await ObtenerListas.TipoDocumento().then(response => {
            setListTipoDocumento(response);
        });
    }

    const GuardarDatosGenerales = async () => {
        var identOperacion = 0;
        // const tempSolicitud = await MasterManager.GetMnSolicitud({ idenT_SOLICITUD: IdSolicitud });
        // identOperacion = tempSolicitud.data.content[0].idenT_OPERACION == null ? 0 : tempSolicitud.data.content[0].idenT_OPERACION == undefined ? 0 : tempSolicitud.data.content[0].idenT_OPERACION;        
        
        let resultado;
        let body = {
            "identInformeComercial": dataInformeComercial.identInformeComercial == null ? 0 : dataInformeComercial.identInformeComercial,
            "fechaRegistro": dataInformeComercial.fechaRegistro,
            "ruc": dataInformeComercial.nroRuc,
            "razonSocial": dataInformeComercial.razonSocial,
            "direccionComercialFabrica": dataInformeComercial.direccionComercialFabrica,
            "direccionComercialOficina": dataInformeComercial.direccionComercialOficina,
            "direccionComercialLocal1": dataInformeComercial.direccionComercialLocal_1,
            "direccionComercialLocal2": dataInformeComercial.direccionComercialLocal_2,
            "telefono1": dataInformeComercial.telefono_1,
            "telefono2": dataInformeComercial.telefono_2,
            "fechaConstitucion": dataInformeComercial.fechaConstitucion,
            "codigoCiiu": dataInformeComercial.codigoCIIU,
            "capitalSocialActual": dataInformeComercial.capitalSocialActual,
            "codigoSbs": dataInformeComercial.codigoSBS,
            "idGrupoEconomico": dataInformeComercial.idGrupoEconomico,
            "descGrupoEconomico": dataInformeComercial.descGrupoEcnonomico,
            "idSectorEconomico": dataInformeComercial.idSectorEconomico,
            "descSectorEconomico": dataInformeComercial.descGrupoEcnonomico,
            "idActividadGiroPrincipal": dataInformeComercial.idActividadGiroPrincipal,
            "actividadGiroPrincipal": dataInformeComercial.actividadGiroPrincipal,
            "idAuditorExterno": dataInformeComercial.idAuditorExterno,
            "auditorExterno": dataInformeComercial.auditorExterno,
            "idOtrasActividadesComplementarias": dataInformeComercial.idOtrasActividadesComplementarias,
            "descOtrasActividadesComplementarias": dataInformeComercial.descOtrasActividadesComplementarias,
            "idPatrimonio": dataInformeComercial.idPatrimonio,
            "descPatrimonio": dataInformeComercial.descPatrimonio,
            "numeroOperacion": 0,
            "identUsuarioModificador": String(appSecurityConfiguration.SessionUserId)
        }
        
        try {
            const response = await RequestManager.MnInformeComercialUpdate(body);
            if (response.status === 200) {
                resultado = response.data.isValid;
                if (resultado === true) {
                  toast.success("Registro guardado", { duration: 5000 });
                }
                else{
                  toast.error("Ocurrió un error", { duration: 5000 })
                }
              };
              CargaInicial();
            } catch (error: AxiosError | any) {            
                if (error.response && error.response.data.status === 400 && error.response.data.errors) {
                    const validationErrors = error.response.data.errors;
                    if (validationErrors) {
                        for (const [field, messages] of Object.entries(validationErrors)) {                            
                            let message = messages as string[]; // Type assertion
                            toast.error(`${field}: ${message}`, { duration: 5000 });
                        }
                    } else {
                        toast.error("Ocurrió un error", { duration: 5000 });
                    }
                }
            }

    }
    
    const CargaInicial = async () => {
        let urlParams = new URLSearchParams(window.location.search);

        const IdInforme = urlParams.get('Informe');
        const response = await RequestManager.GetMnInformeComercialByIdSolicitud({ "identInformeComercial": IdInforme});
        if (response.status === 200) {
            setDataInformeComercial(response.data.content);
        }
    }

    useEffect(() => {
        InitListas();
        CargaInicial();
    }, []);

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion defaultExpanded>
          <AccordionSummary>I. INFORME COMERCIAL</AccordionSummary>
          <AccordionDetails>
            <>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        title={"Número RUC"}
                        placeholder=""
                        // longNumber={8}
                        // required={true}
                        disabled={true}
                        value={dataInformeComercial.nroRuc}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, nroRuc: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <DateInput
                        title={"Fecha registro Obligatorio"}
                        value={dataInformeComercial.fechaRegistro}
                        disabled={true}
                        onChange={(date: any | null) => setDataInformeComercial({ ...dataInformeComercial, fechaRegistro: date === null ? '' : TimeHelper.ObtenerFormatoFechaAmericanaDayJs(date) })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <TextInput
                        title={"Razón Social"}
                        placeholder="Ingresar razón social"
                        value={dataInformeComercial.razonSocial}
                        disabled={true}
                        // disabled={readonlyForm}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, razonSocial: event.target.value })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        title={"Dirección comercial fabrica"}
                        placeholder="Ejm. Av. La Marina"
                        value={dataInformeComercial.direccionComercialFabrica}
                        disabled={true}
                        // disabled={readonlyForm}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, direccionComercialFabrica: event.target.value })}
                    />
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        title={"Dirección comercial oficina"}
                        placeholder="Ejm. Av. La Marina"
                        value={dataInformeComercial.direccionComercialOficina}
                        // disabled={readonlyForm}
                        disabled={true}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, direccionComercialOficina: event.target.value })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        title={"Dirección comercial local 1"}
                        placeholder="Ejm. Av. La Marina"
                        value={dataInformeComercial.direccionComercialLocal_1}
                        disabled={true}
                        // disabled={readonlyForm}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, direccionComercialLocal_1: event.target.value })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        title={"Dirección comercial local 2"}
                        placeholder="Ejm. Av. La Marina"
                        value={dataInformeComercial.direccionComercialLocal_2}
                        disabled={true}
                        // disabled={readonlyForm}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, direccionComercialLocal_2: event.target.value })}
                    />
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        title={"Teléfono 1"}
                        placeholder=""
                        longNumber={9}
                        required={true}
                        value={dataInformeComercial.telefono_1}
                        disabled={true}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, telefono_1: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        title={"Teléfono 2"}
                        placeholder=""
                        longNumber={9}
                        required={true}
                        value={dataInformeComercial.telefono_2}
                        disabled={true}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, telefono_2: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <DateInput
                        title={"Fecha de constitución"}
                        value={dataInformeComercial.fechaConstitucion}
                        // disabled={solicitudId !== undefined || readonlyForm}
                        onChange={(date: any | null) => setDataInformeComercial({ ...dataInformeComercial, fechaConstitucion: date === null ? '' : TimeHelper.ObtenerFormatoFechaAmericanaDayJs(date) })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        title={"Código CIIU"}
                        placeholder=""
                        required={true}
                        value={dataInformeComercial.codigoCIIU}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, codigoCIIU: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        title={"Capital socio actual"}
                        placeholder=""
                        required={true}
                        value={dataInformeComercial.capitalSocialActual}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, capitalSocialActual: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        title={"Código SBS"}
                        placeholder=""
                        required={false}
                        disabled={true}
                        value={dataInformeComercial.codigoSBS}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, codigoSBS: event.target.value })}
                    />
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        title={"Grupo económico"}
                        value={dataInformeComercial.descGrupoEcnonomico}
                        // disabled={readonlyForm}
                        required={false}
                        disabled={true}
                        onChange={(event: any, newValue: any) => {
                          setDataInformeComercial({ ...dataInformeComercial, idGrupoEconomico: 0 });
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        title={"Sector económico"}
                        placeholder="Ejm. Av. La Marina"
                        value={dataInformeComercial.descSectorEconomico}
                        // disabled={readonlyForm}
                        required={false}
                        disabled={true}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, descSectorEconomico: event.target.value })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        title={"Actividad o giro principal"}
                        options={listActividadEconomica}
                        value={dataInformeComercial.idActividadGiroPrincipal}
                        // disabled={readonlyForm}
                        required={false}
                        disabled={true}
                        onChange={(event: any) => {
                          setDataInformeComercial({ ...dataInformeComercial, idActividadGiroPrincipal: event.target.value});
                        }}
                    />
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <TextInput
                        title={"Nombre auditores externos"}
                        placeholder="Ingresar"
                        disabled={props.isDisabled}
                        value={dataInformeComercial.auditorExterno}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, auditorExterno: event.target.value })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        title={"Otras actividades complementarias"}
                        placeholder="Ingresar"
                        disabled={true}
                        required={false}
                        value={dataInformeComercial.descOtrasActividadesComplementarias}
                        // disabled={readonlyForm}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, descOtrasActividadesComplementarias: event.target.value })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        title={"Patrimonio"}
                        placeholder="Ingresar"
                        required={false}
                        value={dataInformeComercial.descPatrimonio}
                        // disabled={readonlyForm}
                        disabled={true}
                        onChange={(event: any) => setDataInformeComercial({ ...dataInformeComercial, descPatrimonio: event.target.value })}
                    />
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center ">
                    <div className="col-lg-12 cardObjets bottom-right">
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<Save />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            onClick={GuardarDatosGenerales}
                            // onClick={props.onClick}>{props.title}
                            // disabled={!chkRequerido}
                            >Guardar
                        </Button>
                    </div>
            </div>
            </>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
    </div>
  );
};

export default DatosGeneralesAuditor;