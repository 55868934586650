import { appDefaultValues } from "../../../../config/Config";
import { BTCrearPersonaJuridicaDomicilio } from "./BTCrearPersonaJuridicaDomicilio";

export class BTCrearPersonaJuridicaDomicilios {
    private SdtJSPSsBTDomicilio: BTCrearPersonaJuridicaDomicilio[];

    constructor() {
        this.SdtJSPSsBTDomicilio = appDefaultValues.ArrayDefault;
    }

    public getSdtJSPSsBTDomicilio(): BTCrearPersonaJuridicaDomicilio[] {
        return this.SdtJSPSsBTDomicilio;
    }

    public setSdtJSPSsBTDomicilio(SdtJSPSsBTDomicilio: BTCrearPersonaJuridicaDomicilio[]): void {
        this.SdtJSPSsBTDomicilio = SdtJSPSsBTDomicilio;
    }
}