import { appDefaultValues } from "../../../../config/Config";
import { BTCrearPersonaFisicaDomicilios } from "./BTCrearPersonaFisicaDomicilios";
import { BTCrearPersonaFisicaInformacionDatosLaborales } from "./BTCrearPersonaFisicaInformacionDatosLaborales";
import { BTCrearPersonaFisicaOtrosDatos } from "./BTCrearPersonaFisicaOtrosDatos";

export class BTCrearPersonaFisicaRequest {
    private nroDocumento: string;
    private profesion: string;
    private nivelEducativo: string;
    private estadoCivil: string;
    private segundoNombre: string;
    private separacionBienes: string;
    private fechaVencimiento: string;
    private segundoApellido: string;
    private fechaNacimiento: string;
    private telefonoCelular: string;
    private tipoDocumentoId: number;
    private sexo: string;
    private otrosDatos: BTCrearPersonaFisicaOtrosDatos;
    private primerNombre: string;
    private paisDocumentoId: number;
    private telefonoFijo: string;
    private primerApellido: string;
    private conyugeUId: number;
    private paisDocumento: string;
    private nacionalidad: string;
    private domicilios: BTCrearPersonaFisicaDomicilios;
    private datosLaborales: BTCrearPersonaFisicaInformacionDatosLaborales;
    private correoElectronico: string;
    private tipoDocumento: string;
    private profesionId: number;
    private nivelEducativoId: number;
    private nacionalidadId: number;
    private estadoCivilId: string;

    constructor() {
        this.nroDocumento = appDefaultValues.StringEmpty;
        this.profesion = appDefaultValues.StringEmpty;
        this.nivelEducativo=appDefaultValues.StringEmpty;
        this.estadoCivil = appDefaultValues.StringEmpty;
        this.segundoNombre = appDefaultValues.StringEmpty;
        this.separacionBienes = appDefaultValues.StringEmpty;
        this.fechaVencimiento = appDefaultValues.StringEmpty;
        this.segundoApellido = appDefaultValues.StringEmpty;
        this.fechaNacimiento = appDefaultValues.StringEmpty;
        this.telefonoCelular = appDefaultValues.StringEmpty;
        this.tipoDocumentoId = appDefaultValues.NumberDefault;
        this.sexo = appDefaultValues.StringEmpty;
        this.otrosDatos = new BTCrearPersonaFisicaOtrosDatos();
        this.primerNombre = appDefaultValues.StringEmpty;
        this.paisDocumentoId = appDefaultValues.NumberDefault;
        this.telefonoFijo = appDefaultValues.StringEmpty;
        this.primerApellido = appDefaultValues.StringEmpty;
        this.conyugeUId = appDefaultValues.NumberDefault;
        this.paisDocumento = appDefaultValues.StringEmpty;
        this.nacionalidad = appDefaultValues.StringEmpty;
        this.domicilios = new BTCrearPersonaFisicaDomicilios();
        this.datosLaborales = new BTCrearPersonaFisicaInformacionDatosLaborales();
        this.correoElectronico = appDefaultValues.StringEmpty;
        this.tipoDocumento = appDefaultValues.StringEmpty;
        this.profesionId = appDefaultValues.NumberDefault;
        this.nivelEducativoId=appDefaultValues.NumberDefault;
        this.nacionalidadId = appDefaultValues.NumberDefault;
        this.estadoCivilId = appDefaultValues.StringEmpty;
    }

    public getNroDocumento(): string {
        return this.nroDocumento;
    }

    public setNroDocumento(nroDocumento: string): void {
        this.nroDocumento = nroDocumento;
    }

    public getProfesion(): string {
        return this.profesion;
    }

    public setProfesion(profesion: string): void {
        this.profesion = profesion;
    }

    public getNivelEducativo(): string {
        return this.nivelEducativo;
    }

    public setNivelEducativo(nivelEducativo: string): void {
        this.nivelEducativo = nivelEducativo;
    }

    public getEstadoCivil(): string {
        return this.estadoCivil;
    }

    public setEstadoCivil(estadoCivil: string): void {
        this.estadoCivil = estadoCivil;
    }

    public getSegundoNombre(): string {
        return this.segundoNombre;
    }

    public setSegundoNombre(segundoNombre: string): void {
        this.segundoNombre = segundoNombre;
    }

    public getSeparacionBienes(): string {
        return this.separacionBienes;
    }

    public setSeparacionBienes(separacionBienes: string): void {
        this.separacionBienes = separacionBienes;
    }

    public getFechaVencimiento(): string {
        return this.fechaVencimiento;
    }

    public setFechaVencimiento(fechaVencimiento: string): void {
        this.fechaVencimiento = fechaVencimiento;
    }

    public getSegundoApellido(): string {
        return this.segundoApellido;
    }

    public setSegundoApellido(segundoApellido: string): void {
        this.segundoApellido = segundoApellido;
    }

    public getFechaNacimiento(): string {
        return this.fechaNacimiento;
    }

    public setFechaNacimiento(fechaNacimiento: string): void {
        this.fechaNacimiento = fechaNacimiento;
    }

    public getTelefonoCelular(): string {
        return this.telefonoCelular;
    }

    public setTelefonoCelular(telefonoCelular: string): void {
        this.telefonoCelular = telefonoCelular;
    }

    public getTipoDocumentoId(): number {
        return this.tipoDocumentoId;
    }

    public setTipoDocumentoId(tipoDocumentoId: number): void {
        this.tipoDocumentoId = tipoDocumentoId;
    }

    public getSexo(): string {
        return this.sexo;
    }

    public setSexo(sexo: string): void {
        this.sexo = sexo;
    }

    public getOtrosDatos(): BTCrearPersonaFisicaOtrosDatos {
        return this.otrosDatos;
    }

    public setOtrosDatos(otrosDatos: BTCrearPersonaFisicaOtrosDatos): void {
        this.otrosDatos = otrosDatos;
    }

    public getPrimerNombre(): string {
        return this.primerNombre;
    }

    public setPrimerNombre(primerNombre: string): void {
        this.primerNombre = primerNombre;
    }

    public getPaisDocumentoId(): number {
        return this.paisDocumentoId;
    }

    public setPaisDocumentoId(paisDocumentoId: number): void {
        this.paisDocumentoId = paisDocumentoId;
    }

    public getTelefonoFijo(): string {
        return this.telefonoFijo;
    }

    public setTelefonoFijo(telefonoFijo: string): void {
        this.telefonoFijo = telefonoFijo;
    }

    public getPrimerApellido(): string {
        return this.primerApellido;
    }

    public setPrimerApellido(primerApellido: string): void {
        this.primerApellido = primerApellido;
    }

    public getConyugeUId(): number {
        return this.conyugeUId;
    }

    public setConyugeUId(conyugeUId: number): void {
        this.conyugeUId = conyugeUId;
    }

    public getPaisDocumento(): string {
        return this.paisDocumento;
    }

    public setPaisDocumento(paisDocumento: string): void {
        this.paisDocumento = paisDocumento;
    }

    public getNacionalidad(): string {
        return this.nacionalidad;
    }

    public setNacionalidad(nacionalidad: string): void {
        this.nacionalidad = nacionalidad;
    }

    public getDomicilios(): BTCrearPersonaFisicaDomicilios {
        return this.domicilios;
    }

    public setDomicilios(domicilios: BTCrearPersonaFisicaDomicilios): void {
        this.domicilios = domicilios;
    }

    public getDatosLaborales(): BTCrearPersonaFisicaInformacionDatosLaborales {
        return this.datosLaborales;
    }

    public setDatosLaborales(datosLaborales: BTCrearPersonaFisicaInformacionDatosLaborales): void {
        this.datosLaborales = datosLaborales;
    }

    public getCorreoElectronico(): string {
        return this.correoElectronico;
    }

    public setCorreoElectronico(correoElectronico: string): void {
        this.correoElectronico = correoElectronico;
    }

    public getTipoDocumento(): string {
        return this.tipoDocumento;
    }

    public setTipoDocumento(tipoDocumento: string): void {
        this.tipoDocumento = tipoDocumento;
    }

    public getProfesionId(): number {
        return this.profesionId;
    }

    public setProfesionId(profesionId: number): void {
        this.profesionId = profesionId;
    }

    public getNivelEducativoId(): number {
        return this.nivelEducativoId;
    }

    public setNivelEducativoId(nivelEducativoId: number): void {
        this.nivelEducativoId = nivelEducativoId;
    }

    public getNacionalidadId(): number {
        return this.nacionalidadId;
    }

    public setNacionalidadId(nacionalidadId: number): void {
        this.nacionalidadId = nacionalidadId;
    }

    public getEstadoCivilId(): string {
        return this.estadoCivilId;
    }

    public setEstadoCivilId(estadoCivilId: string): void {
        this.estadoCivilId = estadoCivilId;
    }
}