export class TMnSolicitudAsociadoRequest {
    idenT_SOLICITUD_ASOCIADO: number = 0;
    idenT_SOLICITUD: number = 0;
    uid: number = 0;
    idenT_TIPO_RELACION: number = 0;
}

export class TMnSolicitudAsociadoInsertUpdateRequest {
    idenT_SOLICITUD_ASOCIADO: number = 0;
    idenT_SOLICITUD: number = 0;
    uid: number = 0;
    iD_TIPO_DOCUMENTO: number = 0;
    codL_NUMERO_DOCUMENTO: string = '';
    idenT_TIPO_RELACION: number = 0;
    descL_NOMBRE_COMPLETO: string = '';
    descL_NOMBRES: string | null = null;
    descL_APELLIDO_PATERNO: string | null = null;
    descL_APELLIDO_MATERNO: string | null = null;
    inD_ESTADO: boolean = true;
    idenT_USUARIO: number = 0;
}

