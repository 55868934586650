import RegimenTabla from "./RegimenTabla";

const RegimenTablas = (props: any) => {
    const { data, cardClassName, onChange, readOnly } = props;

    return (
        <>
            {data?.map((item: any) => (
                <div key={item.identFormato} className="row g-2 mb-2">
                    {/* <h6>{item.nombreFormato}</h6> */}
                    {item.tablas.map((tabla: any, tableIndex: number) => (
                        <div key={`${item.identFormato}-${tableIndex}`} className={cardClassName}>
                            {/* <h6>{tabla.titulo}</h6> */}
                            <RegimenTabla
                                formatId={item.identFormato}
                                tableIndex={tableIndex}
                                columns={tabla.columnas}
                                rows={tabla.filas}
                                onChange={onChange}
                                readOnly={readOnly}
                            />
                        </div>
                    ))}
                </div>
            ))}
        </>
    );
};

export default RegimenTablas;