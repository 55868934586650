import { Table } from '@mui/joy';
import DocumentationFileUploader from './DocumentationFileUploader';
import IconEdit from '../../../../../assets/media/icons/ico_edit.svg';

const DocumentationCheckList2Table = (props: any) => {
    return (
        <Table borderAxis="none" className="style-table" stripe={'odd'}>
            <thead>
                <tr>
                    {props.tableHeaders.map((tableheader: any, index: number) => (
                        <th
                            key={index}
                            className={index > 2 ? 'cell-content-center' : ''}
                            style={{
                                width: props.widthColumns(index),
                                backgroundColor: "#E1E1E1",
                                borderTopLeftRadius: index === 0 ? "10px" : "auto",
                                borderBottomLeftRadius: index === 0 ? "10px" : "auto",
                                borderTopRightRadius: index === 4 ? "10px" : "auto",
                                borderBottomRightRadius: index === 4 ? "10px" : "auto",
                            }}
                        >
                            {tableheader}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {props.tableData.map((data: any, index: number) => (
                    <tr key={index}>
                        <td><div>{data.document}</div></td>
                        <td>
                            <div className={props.addStyleStatus(data.status)}>{props.renderStatus(data.status)}</div>
                        </td>
                        <td>
                            <div 
                                className={props.addStyleFile(data.file)} 
                                style={{ cursor: 'pointer' }}
                                onClick={(e: any) => { props.handleDownloadFile(e,data) }}
                            >{data.file}</div>
                        </td>
                        {/*<td className='cell-content-center'>
                            <img className='cursor-pointer' src={IconEdit} />
                </td>*/}
                        <td className='cell-content-center'>
                            <DocumentationFileUploader disabled={props.isDisabledFileUploader || data.disabledFileUploader} handleFile={props.handlerFile} data={data} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default DocumentationCheckList2Table;