import { Fragment, useContext, useEffect, useState } from 'react';
import icoAlertWarning from './../../../../../../assets/media/icons/ico_alert_warning.svg';
import { StepsContext } from '../../providers';
import { DatosAlertPlaftRiskCard, DatosAlertPlaftRiskCardDefault } from './interfaces';
import CircularProgress from '@mui/joy/CircularProgress';

const AlertPlaftRiskCard = () => {

    const {
        messageCustomerIsBlackListGesintel,
        detailCustomerIsBlackListGesintel
    } = useContext(StepsContext);
    const [viewModel, setViewModel] = useState<DatosAlertPlaftRiskCard>(DatosAlertPlaftRiskCardDefault);


    useEffect(() => {
        if (messageCustomerIsBlackListGesintel) {
            setViewModel({
                ...viewModel,
                Mensaje: messageCustomerIsBlackListGesintel,
                Detalle: detailCustomerIsBlackListGesintel
            });
        }
    }, [messageCustomerIsBlackListGesintel, detailCustomerIsBlackListGesintel]);

    return (
        <div className="border rounded-10 p-3 p-md-2 mb-4 mb-md-4 card body-alert-warning">
            <div className="row g-2 mb-2 item-alert-warning">
                <div className="col-lg-1 col-md-12 d-flex">
                    <img src={icoAlertWarning} alt="Alert Icon" />
                </div>
                <div className="col-lg-11 col-md-12 align-items-center">
                    <span>
                        <b>{viewModel.Mensaje}</b>
                        {viewModel.Detalle && viewModel.Detalle.map((item: string, index: number) => (
                            <Fragment key={index}>
                                <br />
                                {item}
                            </Fragment>
                        ))}
                        {!viewModel.Detalle &&
                            <div className="col text-center">
                                <br />
                                <CircularProgress size="sm" variant='plain' color="danger" />
                            </div>
                        }
                    </span>
                </div>
            </div>
        </div>
    );
};

export default AlertPlaftRiskCard;