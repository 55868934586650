import { appConfigKey } from "../../../../../config/Config";
import { TMnSolicitudObservadaInsertUpdateRequest, TMnSolicitudObservadaRequest } from "../../../../models/MasterManager/solicitud-observada-request.model";
import { TMnSolicitudObservadaAuditDto } from "../../../../models/MasterManager/solicitud-observada-response.model";
import MasterManager from "../../../../services/origination/wapimaster.service";

export const ObtenerServicio = {

  GetSolicitud: async (solicitudeId: number) => {
    let datos: any = {};
    const response = await MasterManager.GetMnSolicitud({
      IDENT_SOLICITUD: solicitudeId,
    });
    if (response.status === 200) {
      datos = response.data.content[0];
    }

    return datos;
  },

  GetPersona: async (personId: number) => {
    let datos: any = {};
    const response = await MasterManager.GetMnPersonaByID({
      idenT_PERSONA: personId,
    });
    if (response.status === 200) {
      datos = response.data.content[0];
    }

    return datos;
  },

  GetUsuario: async (userId: number) => {
    let datos: any = {};
    const response = await MasterManager.GetMnUsuarios({
      idenT_USUARIO: userId,
    });
    if (response.status === 200) {
      datos = response.data.content[0];
    }

    return datos;
  },

  GetSolicitudPersonaObservada: async (solicitudId: number) => {
    let datos: any = {};
    const response = await MasterManager.GetMnSolicitudPersonaObservada(solicitudId);
    if (response?.status === 200) {
      datos = response.data.content;
    }

    return datos;
  },

  GetSolicitudObservada: async (request: TMnSolicitudObservadaRequest) => {
    let datos: TMnSolicitudObservadaAuditDto[] = [];
    const response = await MasterManager.GetMnSolicitudObservada(request);
    if (response?.status === 200) {
      datos = response.data.content;
    }

    return datos;
  },

  GetSolicitudObservadaInsert: async (request: TMnSolicitudObservadaInsertUpdateRequest) => {
    let datos: TMnSolicitudObservadaAuditDto | null = null;
    const response = await MasterManager.GetMnSolicitudObservadaInsert(request);
    if (response?.status === 200) {
      datos = response.data.content[0];
    }

    return datos;
  },

  GetMnTablaGenericaDefinicion: async (tablaId: string | number) => {
    var body = {
      "v_LIST_IDENT_TABLA": tablaId
    };
    var response: any = await MasterManager.GetMnTablaGenericaDefinicion(body)
      .then((response: any) => {
        return response;
      })
      .catch(error => {
        return null;
      });
    return response;
  }

};