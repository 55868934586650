import { appDefaultValues } from "../../../../../../config/Config";

export interface DatosLaboralesAdicionalesConyugeInterface {
    identActividadEconomica: number,
    identPais: number,
    identDepartamentos : number,
    identProvincias : number,
    identDistritos : number,
    nombreVia: string,
    numeroVia: string,
    intDptoPiso: string,
    manzana: string,
    lote: string,
    nombreAgrupacion: string,
    referencia: string,
    telefono: string,
    anexo: string,
    correo: string,
    identCargoLaboral: number,
    descripcionCargo: string
}

export const DatosLaboralesAdicionalesConyugeInterfaceDefault: DatosLaboralesAdicionalesConyugeInterface = {
        
    identActividadEconomica: appDefaultValues.NumberDefault,
    identPais: appDefaultValues.NumberDefault,
    identDepartamentos : appDefaultValues.NumberDefault,
    identProvincias : appDefaultValues.NumberDefault,
    identDistritos : appDefaultValues.NumberDefault,
    nombreVia: appDefaultValues.StringEmpty,
    numeroVia: appDefaultValues.StringEmpty,
    intDptoPiso: appDefaultValues.StringEmpty,
    manzana: appDefaultValues.StringEmpty,
    lote: appDefaultValues.StringEmpty,
    nombreAgrupacion: appDefaultValues.StringEmpty,
    referencia: appDefaultValues.StringEmpty,
    telefono: appDefaultValues.StringEmpty,
    anexo: appDefaultValues.StringEmpty,
    correo: appDefaultValues.StringEmpty,
    identCargoLaboral: appDefaultValues.NumberDefault,
    descripcionCargo: appDefaultValues.StringEmpty
};