import { Sheet, Table } from "@mui/joy";
import { DateInput, MoneyInput, PercentageInput } from "./components";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import TimeHelper from "../../../../helpers/TimeHelper";

const RegimenTabla = (props: any) => {
    const {
        formatId,
        tableIndex,
        columns,
        rows,
        onChange,
        readOnly,
    } = props;

    const getHeaderCell = (celda: any, tableIndex: number, filaIndex: number, colIndex: number) => {
        if (celda.error) {
            return (
                <div style={{ userSelect: 'auto' }}>
                    {celda.error}
                </div>
            );
        }
        switch (celda.componente) {
            case "label":
            case "text":
                return (
                    <>
                        {celda.startDecoracion} {celda.label} {celda.endDecoracion}
                    </>
                );
            default:
                return null;
        }
    }

    const getDataCell = (celda: any, tableIndex: number, filaIndex: number, colIndex: number) => {
        if (celda.error) {
            return (
                <div style={{ userSelect: 'auto' }}>
                    {celda.error}
                </div>
            );
        }
        switch (celda.componente) {
            case "label":
            case "text":
                return (
                    <>
                        {celda.startDecoracion} {celda.label} {celda.endDecoracion}
                    </>
                );
            case "input-number":
                return (
                    <MoneyInput
                        startDecorator={{ d: celda.startDecoracion }['d']}
                        value={GeneralHelper.AgregarComasAMiles(celda.label)}
                        disabled={readOnly || (celda.tipo === "operation" && celda.soloLectura === "1")}
                        onChange={(event: any) => {
                            let result: string | null = null;
                            if (celda.tipo === "operation" && celda.soloLectura === "0") {
                                celda.tipo = "input";
                                celda.operation = null;
                            }
                            result = GeneralHelper.QuitarComasAMiles(event?.target?.value);
                            celda.label = result;
                            onChange?.(tableIndex, filaIndex, colIndex, result);
                        }}
                    />
                );
            case "input-percentage":
                return (
                    <PercentageInput
                        startDecorator={{ d: celda.startDecoracion }['d']}
                        value={`${Number(celda.label).toFixed(2)} ${celda.endDecoracion}`}
                        disabled={true}
                        onChange={(event: any) => { }}
                    />
                );
            case "input-date":
                return (
                    <DateInput
                        value={celda.label}
                        disabled={readOnly || celda.tipo === "operation"}
                        onChange={(date: any) => {
                            let result: string | null = null;
                            if (date) result = TimeHelper.ObtenerFormatoFechaAmericanaDayJs(date)
                            celda.label = result;
                            onChange?.(tableIndex, filaIndex, colIndex, result);
                        }}
                    />
                );
            default:
                return null;
        }
    }

    const getColumn = (celda: any, tableIndex: number, filaIndex: number, colIndex: number) => {
        // Ocultar celda si la columna no es visible
        if (!celda.visible) return null;

        return (
            <th
                key={`${formatId}-${tableIndex}-${colIndex}`}
                colSpan={celda.colSpan}
                rowSpan={celda.rowSpan}
                style={{
                    width: "170px",
                    backgroundColor: "#E1E1E1",
                    borderTopLeftRadius: (colIndex === 0) ? "10px" : "0",
                    borderBottomLeftRadius: (colIndex === 0) ? "0" : "0",
                    borderTopRightRadius: (colIndex === columns.length - 1) ? "10px" : "0",
                    borderBottomRightRadius: (colIndex === columns.length - 1) ? "0" : "0",
                    textAlign: celda.textAlign
                }}
            >
                {getHeaderCell(celda, tableIndex, 0, colIndex)}
            </th>
        )
    }

    const getRow = (celda: any, tableIndex: number, filaIndex: number, colIndex: number, fila: any) => {
        // Ocultar celda si la columna no es visible
        if (colIndex >= 0 && colIndex < columns.length && !columns[colIndex].visible) return null;

        return (
            <td
                key={`${formatId}-${tableIndex}-${filaIndex}-${colIndex}`}
                colSpan={celda.colSpan}
                rowSpan={celda.rowSpan}
                style={{
                    borderTopLeftRadius: (colIndex === 0) ? "0" : "0",
                    borderBottomLeftRadius: (colIndex === 0) ? "0" : "0",
                    borderTopRightRadius: (colIndex === fila.length - 1) ? "0" : "0",
                    borderBottomRightRadius: (colIndex === fila.length - 1) ? "0" : "0",
                    textAlign: celda.textAlign
                }}
            >
                {getDataCell(celda, tableIndex, filaIndex, colIndex)}
            </td>
        )
    }

    return (
        <Sheet sx={{ overflow: 'auto' }}>
            <Table borderAxis="none" className="style-table align-top">
                <thead>
                    <tr>
                        {columns?.map((col: any, colIndex: number) => getColumn(col, tableIndex, 0, colIndex))}
                    </tr>
                </thead>
                <tbody>
                    {rows?.map((row: any, filaIndex: number) => (
                        <tr key={`${formatId}-${tableIndex}-${filaIndex}`}>
                            {row?.fila.map((col: any, colIndex: number) => getRow(col, tableIndex, filaIndex, colIndex, row.fila))}
                        </tr>
                    ))}
                </tbody>
            </Table>
        </Sheet>
    );
};

export default RegimenTabla;