export const BantotalTipoDescuento = {
    PRECIO_VEHICULO: 1,
    CUOTA_INICIAL: 2,
    TASA: 3,
    OPCION_DOBLE: 4
};

export const BantotalCIPrecioSelect = (tipoDescuento: number) => {

    if (tipoDescuento == BantotalTipoDescuento.PRECIO_VEHICULO) {
        return ([
            { id: 1, description: 'Precio del vehículo.' },
        ]);
    }

    if (tipoDescuento == BantotalTipoDescuento.CUOTA_INICIAL) {
        return ([
            { id: 2, description: 'Cuota inicial.' },
        ]);
    }

    if (tipoDescuento == BantotalTipoDescuento.TASA) {
        return ([
            { id: 3, description: 'Tasa.' },
        ]);
    }

    if (tipoDescuento == BantotalTipoDescuento.OPCION_DOBLE) {
        return ([
            { id: 1, description: 'Precio del vehículo.' },
            { id: 2, description: 'Cuota inicial.' },
        ]);
    }

    return [];

};

export const BantotalAplicaBonoA = {
    PRECIO_VEHICULO: "P",
    MONTO_INICIAL: "E",
};