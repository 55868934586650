import { Typography } from "@mui/joy";
import { TabPanelPropsModel } from "../../../../models/generic-objects.model";
import { Box } from "@mui/material";

const DocumentationTabPanel = (props: TabPanelPropsModel) => {

    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
};

export default DocumentationTabPanel;