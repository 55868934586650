import { useEffect } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import './normalize.scss';
import './App.scss';
import Solicitud from './app/modules/3.-solicitude/3.10.-base/solicitud';
import Quatition from './app/modules/2.-quatition/2.10.-base/quatition';
import Login from './app/modules/1.-shared/1.1.-auth/1.1.1.-login/login';
import TrayOfRequests from './app/modules/4.-trayOfRequests/4.10.-base/trayOfRequests';
import Documentation from './app/modules/5.-documentation/5.10.-base/documentation';
import AnalysProfile from './app/modules/6.-analystProfile/6.10.-base/analysProfile';
import ManagerProfile from './app/modules/7.-managerProfile/7.10.-base/managerProfile';
import DboProfile from './app/modules/9.-dboProfile/9.10.-base/dboProfile';
import PlaftProfile from './app/modules/10.-plaftProfile/10.10.-base/plaftProfile';
import { appConfig, appSecurityConfiguration } from './config/Config';
import MasterManager from './app/services/origination/wapimaster.service';
import RiskProfile from './app/modules/11.-riskProfile/11.10.-base/riskProfile';
import Error404 from './app/modules/1.-shared/1.6.-errors/error404/error404';
import TeamManagement from './app/modules/12.-teamManagement/12.10.-base/teamManagement';
import CreditEvaluation from './app/modules/13.-creditEvaluation/13.10.-base/creditEvaluation';
import RequestManagement from './app/modules/14.-requestManagement/14.10.-base/requestManagement';
import InformeComercial from './app/modules/15.-InformeComercial/15.10.-base/informeComercial';
import InformeComercialAuditor from './app/modules/15.-InformeComercial/15.10.-base/informeComercialAuditor';
import MatrizEvaluacionAuditor from './app/modules/16.-matrizEvaluacion/5.10.-base/matrizEvaluacion';

function setToken(userToken: string) {
  sessionStorage.setItem('token', JSON.stringify(userToken));
}

function getToken() {
  const tokenString = sessionStorage.getItem('token');
  const userToken = tokenString ? JSON.parse(tokenString) : null;
  return userToken?.token
}

function App() {

  const GetMnUsuario = async () => {

    const account = appConfig.msalInstance.getActiveAccount();

    if (![null, undefined].includes(account)) {

      let request = {
        "emaiL_INTERNO": account.username
      }

      await MasterManager.GetMnUsuarios(request).then((data) => {
        appSecurityConfiguration.SessionUserId = data.data.content[0].idenT_USUARIO;
        appSecurityConfiguration.SessionPerfil = data.data.content[0].idenT_PERFIL;
        appSecurityConfiguration.SessionId = data.data.content[0].username;
      });

    }

  };

  useEffect(() => {
    GetMnUsuario();
  }, []);

  // var token = getToken();|
  var token = "";

  /* if(!token) {
    return <Login setToken={setToken} />
  } */
  return (
    <BrowserRouter>
      <Routes>
        <Route path="*" element={<Login />} />
        <Route path="/" element={<Login />} />
        <Route path="/Solicitude" element={<Solicitud />} />
        <Route path="/Solicitude/:solicitudId" element={<Solicitud />} />
        <Route path="/Solicitude/:solicitudId/:showResult" element={<Solicitud />} />
        <Route path="/Cotizacion" element={<Quatition />} />
        <Route path="/BandejaSolicitudes/:tipo" element={<TrayOfRequests />} />
        <Route path="/Documentacion" element={< Documentation />} />
        <Route path="/PerfilAnalista" element={< AnalysProfile />} />
        <Route path="/PerfilGestor/:solicitudId" element={< ManagerProfile />} />
        <Route path="/PerfilJefeOperaciones/:solicitudId" element={< DboProfile />} />
        <Route path="/PerfilPlaft/:solicitudId" element={< PlaftProfile />} />
        <Route path="/PerfilRiesgos/:solicitudId" element={< RiskProfile />} />
        <Route path="/error/404" element={<Error404 />} />
        <Route path="/BandejaGestionEquipo" element={<TeamManagement />} />
        <Route path="/EvaluacionCrediticia/:tipo/:solicitudId" element={< CreditEvaluation />} />
        <Route path="/BandejaGestionSolicitudes" element={< RequestManagement />} />
        <Route path="/InformeComercial" element={< InformeComercial />} />
        <Route path="/InformeComercialAuditor" element={< InformeComercialAuditor />} />
        <Route path="/MatrizEvaluacionAuditor" element={< MatrizEvaluacionAuditor />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;
