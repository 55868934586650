import { useContext, useEffect, useState } from "react";
import TitleCard from "../elements/titleCard";
import { DateInput, NumberInput, SelectAutocompleteInput, SelectInput, SimpleInput, TextInput } from "../elements/inputs";
import { CompraParaContext } from "../../contexts";
import { appConfigKey } from "../../../../../../config/Config";
import { StepOneContext, StepsContext } from "../../../3.10.-base/providers";

const InputHelper = {
    onChangeSelectInput: (event: any, newValue: any, viewModel: any, setViewModel: any) => {
        const data: any = { ...viewModel };
        data[event.target.name] = newValue;
        setViewModel(data);
    },
    onChangeTextInput: (event: any, viewModel: any, setViewModel: any) => {
        const data: any = { ...viewModel };
        data[event.target.name] = event.target.value;
        setViewModel(data);
    }
};

const DatosPersonalesCompraParaCard = () => {

    const {
        listTipoDocumento,
        listIdentidadesGenero,
        listEstadoCivil,
        listPais,
        clickNext,
        step,
        readonlyForm
    } = useContext(StepsContext);

    const { personaCompraParaData, setNewPersonaCompraParaData } = useContext(StepOneContext);
    const { setCompraParaEstadoCivilId, setTipoDocumentoId } = useContext(CompraParaContext);

    const [viewModel, setViewModel] = useState({
        TipoDocumentoId: 0,
        NumeroDocumento: "",
        Nombres: "",
        ApellidoPaterno: "",
        ApellidoMaterno: "",
        FechaNacimiento: "",
        GeneroId: 0,
        EstadoCivilId: 0,
        NacionalidadId: 0,
        PaisRecidenciaId: 0,
        RazonSocial: "",
    });

    useEffect(() => {
        if (personaCompraParaData !== 0) {
            setViewModel(personaCompraParaData);
        }
    }, [personaCompraParaData]);

    useEffect(() => {
        if (clickNext !== 0 && step === 1) {
            setNewPersonaCompraParaData(viewModel);
        }
    }, [clickNext]);

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
            <TitleCard title={"DATOS PERSONALES COMPRA PARA"} obligatorio={false} />
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-2 cardObjets">
                    <SelectInput
                        name={"TipoDocumentoId"}
                        title={"Tipo de doc. titular"}
                        options={listTipoDocumento}
                        value={viewModel.TipoDocumentoId}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => {
                            InputHelper.onChangeSelectInput(event, newValue, viewModel, setViewModel)
                            setTipoDocumentoId(newValue);
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    {viewModel.TipoDocumentoId === appConfigKey.keyCodigoDNI ? (
                        <NumberInput
                            name={"NumeroDocumento"}
                            required={false}
                            longNumber={8}
                            title={"Número documento"}
                            value={viewModel.NumeroDocumento.trim()}
                            disabled={readonlyForm}
                            onChange={(event: any) => InputHelper.onChangeTextInput(event, viewModel, setViewModel)}
                        />
                    ) : (
                        <NumberInput
                            name={"NumeroDocumento"}
                            required={false}
                            longNumber={11}
                            title={"Número documento"}
                            value={viewModel.NumeroDocumento}
                            disabled={readonlyForm}
                            onChange={(event: any) => InputHelper.onChangeTextInput(event, viewModel, setViewModel)}
                        />
                    )}
                </div>

                {viewModel.TipoDocumentoId !== appConfigKey.keyIdRUC && (
                    <>
                        <div className="col-lg-4 cardObjets">
                            <TextInput
                                name={"Nombres"}
                                title={"Nombres"}
                                value={viewModel.Nombres}
                                disabled={readonlyForm}
                                onChange={(event: any) => InputHelper.onChangeTextInput(event, viewModel, setViewModel)}
                            />
                        </div>
                        <div className="col-lg-4 cardObjets">
                            <TextInput
                                name={"ApellidoPaterno"}
                                title={"Apellido Paterno"}
                                value={viewModel.ApellidoPaterno}
                                disabled={readonlyForm}
                                onChange={(event: any) => InputHelper.onChangeTextInput(event, viewModel, setViewModel)}
                            />
                        </div>
                        <div className="col-lg-4 cardObjets">
                            <SimpleInput
                                name={"ApellidoMaterno"}
                                title={"Apellido Materno"}
                                value={viewModel.ApellidoMaterno}
                                disabled={readonlyForm}
                                onChange={(event: any) => InputHelper.onChangeTextInput(event, viewModel, setViewModel)}
                            />
                        </div>
                        <div className="col-lg-2 cardObjets">
                            <DateInput
                                name={"FechaNacimiento"}
                                title={(viewModel.TipoDocumentoId === appConfigKey.keyIdRUC) ? "Fecha inicio actividades" : "Fecha nacimiento"}
                                value={viewModel.FechaNacimiento}
                                disabled={readonlyForm}
                                onChange={(newValue: any | null) => setViewModel({ ...viewModel, FechaNacimiento: newValue === null ? '' : newValue?.format("YYYY-MM-DD") })}
                            />
                        </div>
                        <div className="col-lg-2 cardObjets">
                            <SelectInput
                                name={"GeneroId"}
                                title={"Género"}
                                options={listIdentidadesGenero}
                                value={viewModel.GeneroId}
                                disabled={readonlyForm}
                                required={viewModel.TipoDocumentoId !== appConfigKey.keyIdRUC}
                                onChange={(event: any, newValue: any) => InputHelper.onChangeSelectInput(event, newValue, viewModel, setViewModel)}
                            />
                        </div>

                        <div className="col-lg-4 cardObjets">
                            <SelectInput
                                name={"EstadoCivilId"}
                                title={"Estado Civil"}
                                options={listEstadoCivil}
                                value={viewModel.EstadoCivilId}
                                disabled={readonlyForm}
                                required={viewModel.TipoDocumentoId !== appConfigKey.keyIdRUC}
                                onChange={(event: any, newValue: any) => {
                                    InputHelper.onChangeSelectInput(event, newValue, viewModel, setViewModel)
                                    setCompraParaEstadoCivilId(newValue);
                                }}
                            />
                        </div>

                        <div className="col-lg-4 cardObjets">
                            <SelectAutocompleteInput
                                name={"NacionalidadId"}
                                title={"Nacionalidad"}
                                value={viewModel.NacionalidadId}
                                options={listPais}
                                disabled={readonlyForm}
                                required={viewModel.TipoDocumentoId !== appConfigKey.keyIdRUC}
                                onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, NacionalidadId: newValue })}
                            />
                        </div>

                        <div className="col-lg-4 cardObjets">
                            <SelectAutocompleteInput
                                name={"PaisRecidenciaId"}
                                title={"Pais de residencia"}
                                options={listPais}
                                value={viewModel.PaisRecidenciaId}
                                disabled={readonlyForm}
                                required={viewModel.TipoDocumentoId !== appConfigKey.keyIdRUC}                            
                                onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, PaisRecidenciaId: newValue })}
                            />

                        </div>
                    </>
                )}

                {viewModel.TipoDocumentoId === appConfigKey.keyIdRUC && (
                    <>
                        <div className="col-lg-4 cardObjets">
                            <SimpleInput
                                name={"RazonSocial"}
                                title={"Razón Social"}
                                value={viewModel.RazonSocial}
                                disabled={readonlyForm}
                                onChange={(event: any) => InputHelper.onChangeTextInput(event, viewModel, setViewModel)}
                            />
                        </div>
                        <div className="col-lg-2 cardObjets">
                            <DateInput
                                name={"FechaNacimiento"}
                                title={(viewModel.TipoDocumentoId === appConfigKey.keyIdRUC) ? "Fecha inicio actividades" : "Fecha nacimiento"}
                                value={viewModel.FechaNacimiento}
                                disabled={readonlyForm}
                                onChange={(newValue: any | null) => setViewModel({ ...viewModel, FechaNacimiento: newValue === null ? '' : newValue?.format("YYYY-MM-DD") })}
                            />
                        </div>
                    </>
                )}

            </div>
        </div>
    );

};

export default DatosPersonalesCompraParaCard;