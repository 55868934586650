import * as React from "react";
import { useParams } from "react-router-dom";
import { Toaster, toast } from "sonner";
import { TMnSolicitudObservadaAuditDto } from "../../../../models/MasterManager/solicitud-observada-response.model";
import ListaNegraResponseHelper from "../1.5.9.-helpers/ListaNegraResponseHelper";
import GesintelResponseHelper from "../1.5.9.-helpers/GesintelResponseHelper";
import { appConfigEstadoSolicitud, appConfigKey, appConfigPerfiles, appSecurityConfiguration } from "../../../../../config/Config";
import { TMnSolicitudObservadaInsertUpdateRequest, TMnSolicitudObservadaRequest } from "../../../../models/MasterManager/solicitud-observada-request.model";
import ReleaseRejectDialog, { ReleaseRejectDialogAction } from "../1.5.6.-dialog/ReleaseRejectDialog";
import LoadingProgress from "../../1.4.-loading/loading-progress/loading-progress";
import MainLayout from "../../1.2.-layout/1.2.3.-main-layout/main-layout";
import CardTitleStart from "../../1.2.-layout/1.2.5-cardTitleStart/cardTitleStart";
import CardSolicitudeDetail from "../1.5.7.-card-solicitude-detail/CardSolicitudeDetail";
import NegativeBaseResultList from "../1.5.5.-result/NegativeBaseResultList";
import BlackList from "../1.5.1.-blacklist/BlackList";
import GesintelList from "../1.5.2.-gesintel/GesintelList";
import ObservedMemberList from "../1.5.4.-member/ObservedMemberList";
import { GesintelItem } from "../1.5.10.-interfaces/GesintelInterface";
import { ObtenerServicio } from "../1.5.11.-service/ObtenerServicios";
import { ListaNegraItem } from "../1.5.10.-interfaces/ListaNegraInterface";
import "./ObservedMembersScreen.scss";
import ObservedActionButtons from "../1.5.8.-button/ObservedActionButtons";
import { FormControl, FormLabel, Table, Textarea } from "@mui/joy";
import { Select, MenuItem } from '@mui/material';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import TimeHelper from "../../../../helpers/TimeHelper";
import ValidacionHelper from "../1.5.9.-helpers/ValidacionHelper";
import ConfirmDialog from "../1.5.6.-dialog/ConfirmDialog";

function ObservedMembersScreen(props: any) {
    const { solicitudId } = useParams();
    const [isLoading, setLoading] = React.useState<boolean>(false);
    const [solicitudeData, setSolicitudeData] = React.useState<any>({});
    const [clientData, setClientData] = React.useState<any>({});
    const [executiveData, setExecutiveData] = React.useState<any>({});
    const [observedMemberIndexSelected, setObservedMemberIndexSelected] =
        React.useState<number>(0);
    const [observedMembers, setObservedMembers] = React.useState<any>([]);
    const [observedMember, setObservedMember] = React.useState<any>(null);
    const [blackListData, setBlackListData] = React.useState<ListaNegraItem[]>([]);
    const [blackListDeltaData, setBlackListDeltaData] = React.useState<ListaNegraItem[]>([]);
    const [gesintelListData, setGesintelListData] = React.useState<GesintelItem[]>([]);
    const [gesintelListDeltaData, setGesintelListDeltaData] = React.useState<GesintelItem[]>([]);
    const [observedSolicitudeResultData, setObservedSolicitudeResultData] = React.useState<TMnSolicitudObservadaAuditDto[]>([]);
    const [commentListData, setCommentListData] = React.useState<string[]>([]);
    const [resultListData, setResultListData] = React.useState<string[]>([]);
    const [resultData, setResultData] = React.useState<any>({ resultadoId: 0, sustento: "", comentario: "" });
    const [showButtons, setShowButtons] = React.useState<boolean>(false);
    const [confirmDialogOpen, setConfirmDialogOpen] =
        React.useState<boolean>(false);

    const solicitudeLoad = async function (solicitudeId: number) {
        let datos = await ObtenerServicio.GetSolicitud(solicitudeId);
        setSolicitudeData(datos);
        return datos;
    };

    const clientLoad = async function (personId: number) {
        let datos = await ObtenerServicio.GetPersona(personId);
        setClientData(datos);
        return datos;
    };

    const ExecutiveLoad = async function (userId: number) {
        let datos = await ObtenerServicio.GetUsuario(userId);
        setExecutiveData(datos);
        return datos;
    };

    const blackListLoad = function (setCallback: React.Dispatch<React.SetStateAction<ListaNegraItem[]>>, jsonString?: string) {
        let datos = ListaNegraResponseHelper.TransformarJsonToList(jsonString)
        setCallback(datos);
        return datos;
    };

    const gesintelListLoad = function (setCallback: React.Dispatch<React.SetStateAction<GesintelItem[]>>, jsonString?: string) {
        let datos = GesintelResponseHelper.TransformarJsonToList(jsonString)
        setCallback(datos);
        return datos;
    };

    const observedMembersLoad = async function (solicitudId: number) {
        let datos = await ObtenerServicio.GetSolicitudPersonaObservada(solicitudId);
        setObservedMembers(datos);
        return datos;
    };

    const observedResultSolicitudeLoad = async function (solicitudId: number) {
        let datos = await ObtenerServicio.GetSolicitudObservada({ idenT_SOLICITUD: solicitudId } as TMnSolicitudObservadaRequest);
        setObservedSolicitudeResultData(datos);
        return datos;
    };

    const clearResult = () => {
        setResultData({ resultadoId: 0, sustento: "", comentario: "" });
    }

    const dataLoad = async function (solicitudId: number) {
        try {
            setLoading(true);
            loadResultList();
            var solicitude = await solicitudeLoad(solicitudId);
            await ExecutiveLoad(solicitude?.idenT_USUARIO_CREADOR);
            await clientLoad(solicitude?.idenT_PERSONA_TITULAR);
            await observedResultSolicitudeLoad(solicitudId);
            var observedMemberList = await observedMembersLoad(solicitudId);
            blackListAndGesintelLoad(observedMemberList[observedMemberIndexSelected], observedMemberIndexSelected)
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const blackListAndGesintelLoad = (data: any, index: number) => {
        try {
            setLoading(true);
            setObservedMemberIndexSelected(index);
            setBlackListDeltaData([]);
            setGesintelListDeltaData([]);
            setBlackListData([]);
            setGesintelListData([]);
            setObservedMember(data);
            blackListLoad(setBlackListDeltaData, data.listA_NEGRA_DELTA);
            gesintelListLoad(setGesintelListDeltaData, data.gesinteL_DELTA);
            blackListLoad(setBlackListData, data.listA_NEGRA);
            gesintelListLoad(setGesintelListData, data.gesintel);
        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    const CommentsLoad = (datos: TMnSolicitudObservadaAuditDto[]) => {
        try {
            let comments = datos.filter((f: any) => (f.descC_COMENTARIO && f.descC_COMENTARIO?.trim() !== "")).map((m: any) => m.descC_COMENTARIO);
            setCommentListData(comments);
        } catch (error) {
        }
    };

    const ButtonsDisplayLoad = (solicitudeData: any) => {
        try {
            let show = false;

            const isPerfilPlaft = appSecurityConfiguration.SessionPerfil == appConfigPerfiles.CodigoPlaft;
            const isPerfilRiesgos = appSecurityConfiguration.SessionPerfil == appConfigPerfiles.CodigoRiesgos || appSecurityConfiguration.SessionPerfil == appConfigPerfiles.CodigoJefeCredito;

            if (solicitudeData.iD_ESTADO_SOLICITUD == appConfigEstadoSolicitud.NoLiberado) {
                show = true;
            } else {
                if (isPerfilPlaft) {
                    show = solicitudeData.idenT_SITUACION_PLAFT == appConfigKey.CodigoSituacionPlaftRiesgosObservada;
                } else if (isPerfilRiesgos) {
                    show = solicitudeData.idenT_SITUACION_RIESGOS == appConfigKey.CodigoSituacionPlaftRiesgosObservada;
                }
            }

            setShowButtons(show);
        } catch (error) {
        }
    };

    const loadResultList = async () => {
        let resultList: any = [];
        ObtenerServicio.GetMnTablaGenericaDefinicion(appConfigKey.keyIdPlaftRiesgosResultado)
            .then((response: any) => {
                if (!response) {
                    toast.error(appConfigKey.keyMsjObtencionResultadoError, {
                        duration: appConfigKey.keyDurationToast,
                    });
                    return;
                }
                const list = response.data.content.sort((a: any, b: any) => a.genericA_CODI_ORDEN - b.genericA_CODI_ORDEN);
                list.forEach((element: any) => {
                    if (![71].includes(element.genericA_IDENT_CAMPO)) {
                        resultList.push({ id: element.genericA_IDENT_CAMPO, label: element.genericA_DESCM_CAMPO });
                    }
                })
                setResultListData(resultList);
            });
    }

    React.useEffect(() => {
        dataLoad(Number(solicitudId));
    }, []);

    React.useEffect(() => {
        ButtonsDisplayLoad(solicitudeData);
    }, [solicitudeData]);

    React.useEffect(() => {
        CommentsLoad(observedSolicitudeResultData);
    }, [observedSolicitudeResultData, observedMembers]);

    const handleObservedMemberClick = (data: any, index: number) => {
        if (observedMemberIndexSelected === index) return;
        blackListAndGesintelLoad(data, index);
    };

    const handleConfirmDialogClose = () => {
        setConfirmDialogOpen(false);
    };

    const handleConfirmDialogSave = (_message: string) => {
        setConfirmDialogOpen(false);
        onSaveResult();
    };

    const onConfirmResult = () => {
        const valid = ValidacionHelper.NewSaveResult(resultData);
        if (!valid) return;

        setConfirmDialogOpen(true);
    }

    const onSaveResult = async () => {
        try {
            setLoading(true);
            let areaId = appSecurityConfiguration.SessionPerfil == appConfigPerfiles.CodigoPlaft ? appConfigKey.CodigoAreaPlaft :
                (appSecurityConfiguration.SessionPerfil == appConfigPerfiles.CodigoRiesgos || appSecurityConfiguration.SessionPerfil == appConfigPerfiles.CodigoJefeCredito) ? appConfigKey.CodigoAreaRiesgos : 0;

            var request = new TMnSolicitudObservadaInsertUpdateRequest();
            request.idenT_SOLICITUD = solicitudeData.idenT_SOLICITUD;
            request.idenT_USUARIO = appSecurityConfiguration.SessionUserId;
            request.idenT_AREA_SITUACION = areaId;
            request.idenT_SITUACION = resultData.resultadoId;
            request.descC_SUSTENTO = resultData.sustento;
            request.descC_COMENTARIO = resultData.comentario ?? null;

            let data = await ObtenerServicio.GetSolicitudObservadaInsert(request)
                .then(response => response)
                .catch(_error => null);

            if (!data) {
                toast.error("Falló el guardado, vuelva a intentarlo nuevamente.", {
                    duration: appConfigKey.keyDurationToast,
                });
                setLoading(false);
            } else {
                var actionMessage = request.idenT_SITUACION === appConfigKey.CodigoSituacionPlaftRiesgosRechazada ? "Rechazado" :
                    request.idenT_SITUACION === appConfigKey.CodigoSituacionPlaftRiesgosLiberada ? "Liberado" :
                        request.idenT_SITUACION === appConfigKey.CodigoSituacionPlaftRiesgosNoLiberada ? "No liberado" : "Guardado";
                toast.success(`${actionMessage} correctamente.`, {
                    duration: appConfigKey.keyDurationToast,
                });
                clearResult();
                dataLoad(Number(solicitudId));
            }
        } catch (error) {
            toast.error("Falló el guardado, vuelva a intentarlo nuevamente.", {
                duration: appConfigKey.keyDurationToast,
            });
            setLoading(false);
        }
    }

    return (
        <div>
            <Toaster position="top-center" richColors closeButton />
            {isLoading && <LoadingProgress />}
            <MainLayout />
            <CardTitleStart Ventana={props.title}></CardTitleStart>
            <CardSolicitudeDetail
                objCardSolicitudeDetailProps={{
                    solicitudeId: Number(solicitudId),
                    clientName: clientData?.descL_NOMBRE_COMPLETO ?? '',
                    executiveName: ((executiveData?.nombres ?? '') + ' ' + (executiveData?.apellidO_PATERNO ?? '') + ' ' + (executiveData?.apellidO_MATERNO ?? '')).trim(),
                    statusName: solicitudeData?.descC_ID_ESTADO_SOLICITUD ?? '',
                    observedTypeName: solicitudeData?.idenT_SITUACION_PLAFT && solicitudeData?.idenT_SITUACION_RIESGOS ? 'PLAFT / Riesgos' : solicitudeData?.idenT_SITUACION_PLAFT ? 'PLAFT' : solicitudeData?.idenT_SITUACION_RIESGOS ? 'Riesgos' : '',
                    stage: solicitudeData?.descC_ETAPA ?? '',
                }}
            ></CardSolicitudeDetail>
            <div className="row g-2 mb-2 content-solicitude-observed">
                <div className="col-lg-9 g-0">
                    <div className="col border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 card-solicitude-observed">
                        <div className="col mb-4">
                            <label className="text-subtitle">
                                DETALLE INTEGRANTE (
                                {observedMembers[
                                    observedMemberIndexSelected
                                ]?.descL_TIPO_RELACION?.toUpperCase() ?? "..."}
                                )
                            </label>
                        </div>
                        <div className="col">
                            <div className="row mb-4">
                                <label className="text-subtitle">LISTA NEGRA</label>
                            </div>
                            <BlackList
                                objBlackListProps={{
                                    blackListData: blackListData,
                                    blackListDeltaData: blackListDeltaData,
                                    member: observedMember,
                                }}
                            ></BlackList>
                        </div>

                        <hr
                            className="hr hr-blurry mb-4"
                            style={{ borderColor: "#0089B5", opacity: 1 }}
                        />
                        <div className="col mb-4">
                            <div className="row">
                                <label className="text-subtitle mb-3">GESINTEL</label>
                                <GesintelList
                                    objGesintelListProps={{
                                        gesintelListData: gesintelListData,
                                        gesintelListDeltaData: gesintelListDeltaData,
                                        member: observedMember,
                                    }}
                                ></GesintelList>
                            </div>
                        </div>
                    </div>
                    <div className="col border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 card-solicitude-observed">
                        <div className="col mb-4">
                            <label className="text-subtitle">RESULTADOS EVALUACIÓN</label>
                        </div>
                        <div className="col-lg-5 mb-3">
                            <FormControl>
                                <FormLabel>Resultado <span style={{ color: "red" }}> * </span></FormLabel>
                                <Select
                                    name="resultado"
                                    className={"select-input-card"}
                                    disabled={!showButtons}
                                    onChange={(event: any, _newValue: any) => {
                                        setResultData({ ...resultData, resultadoId: event.target.value })
                                    }}
                                    value={resultData.resultadoId}
                                    IconComponent={props => (<KeyboardArrowDown {...props} className={`material-icons ${props.className}`} />)}
                                >
                                    <MenuItem value={0}>Seleccione</MenuItem>
                                    {resultListData.map((data: any, index: any) => (
                                        <MenuItem value={data.id} key={index}>{data.label}</MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>

                        <div className="col mb-3">
                            <FormControl>
                                <FormLabel>Sustento <span style={{ color: "red" }}> * </span></FormLabel>
                                <Textarea
                                    name="sustento"
                                    placeholder="Ingresar texto"
                                    disabled={!showButtons}
                                    value={resultData.sustento}
                                    onChange={(event: any) => {
                                        setResultData({ ...resultData, sustento: event.target.value })
                                    }}
                                />
                            </FormControl>
                        </div>

                        <div className="col mb-3">
                            <FormControl>
                                <FormLabel>Comentario para ejecutivo</FormLabel>
                                <Textarea
                                    name="comentario"
                                    placeholder="Ingresar texto"
                                    disabled={!showButtons}
                                    value={resultData.comentario}
                                    onChange={(event: any) => {
                                        setResultData({ ...resultData, comentario: event.target.value })
                                    }}
                                />
                            </FormControl>
                        </div>
                    </div>

                    <div className="col mb-4">
                        {showButtons && <ObservedActionButtons handleClick={onConfirmResult}></ObservedActionButtons>}
                    </div>

                    <div className="col border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 card-solicitude-observed">
                        <div className="col mb-4">
                            <label className="text-subtitle">HISTORIAL BASE NEGATIVA</label>
                        </div>
                        <div className="row g-2 mb-2 align-items-center">
                            <div className="col-lg-12 table-obs-historical-result align-top">
                                <Table borderAxis="none" className="table-obs-historical-result-style align-top">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "170px", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>FECHA/HORA</th>
                                            <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>USUARIO</th>
                                            <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>ETAPA</th>
                                            <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>RESULTADO</th>
                                            <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>SUSTENTO</th>
                                            <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>COMENTARIO</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            observedSolicitudeResultData && observedSolicitudeResultData.map((row: any, index: number) => {
                                                return (
                                                    <tr key={index}>
                                                        <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {TimeHelper.ObtenerFormatoFechaHoraAMPM(row.fecH_MODIF ?? row.fecH_CREACION)}</td>
                                                        <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descC_USUARIO_CREADOR}</td>
                                                        <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descC_ETAPA}</td>
                                                        <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {`${row.descC_AREA_SITUACION} ${row.descC_SITUACION}`}</td>
                                                        <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descC_SUSTENTO}</td>
                                                        <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descC_COMENTARIO}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3 g-0 ps-lg-4">
                    {commentListData.length > 0 && showButtons &&
                        <div className="col border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 card-solicitude-observed">
                            <div className="row mb-4">
                                <label className="text-subtitle">COMENTARIO</label>
                                <label>(Documentos adicionales)</label>
                            </div>
                            {commentListData.map(
                                (comment: string, index: number) => {
                                    return (
                                        <div key={index} className="row mb-4">
                                            <label>{comment}</label>
                                        </div>
                                    );
                                }
                            )}

                        </div>
                    }
                    <div className="col border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 card-solicitude-observed">
                        <div className="row mb-4">
                            <label className="text-subtitle">INTEGRANTES OBSERVADOS</label>
                        </div>
                        <ObservedMemberList
                            objObservedMemberListProps={{
                                observedMemberListData: observedMembers,
                            }}
                            focusIndex={observedMemberIndexSelected}
                            handleClick={handleObservedMemberClick}
                        ></ObservedMemberList>
                    </div>
                </div>
            </div>
            <ConfirmDialog
                open={confirmDialogOpen}
                handleClose={handleConfirmDialogClose}
                handleSave={handleConfirmDialogSave}
            />
        </div>
    );
};

export default ObservedMembersScreen;
