import * as React from 'react';
import { Button } from "react-bootstrap";
import "./styles.scss";
import { useEffect } from "react";

export const CardTitleScore = ({
  scoring,
  resultadoEvaluacionResumen,
  motorMotivos
}: any) => {

  const [score, setScore] = React.useState('');
  const [colorScore, setColorScore] = React.useState('');

  useEffect(() => {
    const riesgo = scoring || resultadoEvaluacionResumen?.nivel_Riesgo;

    setScore('');
    setColorScore('');
    if (riesgo === 'RIESGO BAJO') {
      setScore('Score Bueno');
      setColorScore('btn-score-bueno');
    }
    if (riesgo === 'RIESGO ALTO') {
      setScore('Score Malo');
      setColorScore('btn-score-malo');
    }
    if (riesgo === 'RIESGO MEDIO') {
      setScore('Score Regular');
      setColorScore('btn-score-regular');
    }
  }, [scoring, resultadoEvaluacionResumen.nivel_Riesgo])


  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <div className="row">
        <div className="col-lg-1">
          <div className={'img-score-out'}>
            <span className={'img-score-in'}>X</span>
          </div>
        </div>
        <div className="col-lg-2">
          <div className="col-lg-12 col-md-6">
            <span className="text-negrita">Resultado evaluación</span>
          </div>
          <div className="col-lg-8 col-md-6">
            <span>
              {resultadoEvaluacionResumen.detalleDecision ? resultadoEvaluacionResumen.detalleDecision : ''}
            </span>
          </div>
        </div>
        {resultadoEvaluacionResumen.decision !== 'APR' &&
          <div className="col-lg-3">
            <div className="col-lg-12 col-md-6">
              <span className="text-negrita">Motivo</span>
            </div>
            {motorMotivos && (<div className="col-lg-8 col-md-6 text-motivo">
              <div className="row">
                <div className="col-lg-12">
                  <ol>
                    {
                      motorMotivos?.map((motivo: any) => {
                        return (<li style={{ fontSize: '15px', marginBottom: '10px'}}>{motivo}</li>)
                      })
                    }
                  </ol>
                </div>
              </div>
            </div>)}
            {/* {resultadoEvaluacionResumen.descripcionCMA && (<div className="col-lg-8 col-md-6 text-motivo">
              <div className="row">
                <div className="col-lg-3">:</div>
                <div className="col-lg-9">
                  {
                    resultadoEvaluacionResumen.descripcionCMA
                  }
                </div>
              </div>
            </div>)} */}
          </div>
        }
        <div className="col-lg-1">
          <div className={"linea-vertical"} />
        </div>
        <div className="col-lg-3">
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <span className="text-negrita">Puntaje</span>
            </div>
            <div className="col-lg-6 col-md-6">
              <span>
                {
                  resultadoEvaluacionResumen.puntaje
                }
              </span>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6 col-md-6">
              <span className="text-negrita">Segmento</span>
            </div>
            <div className="col-lg-6 col-md-6">
              <span>
                {
                  resultadoEvaluacionResumen.segmentoDescripcion
                }
              </span>
            </div>
          </div>
        </div>
        {colorScore !== '' && <div className="col-lg-2"><Button className={`${colorScore} btn-score`}><span>{score}</span></Button></div>}
      </div>
    </div>
  );
};

export default CardTitleScore;
