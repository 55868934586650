import IconAproved from '../../../../../assets/media/icons/ico-aproved.svg';
import IconNoBlack from '../../../../../assets/media/icons/ico_no_black.svg';

const DocumentationAlert = (props: any) => {   
    return (
        <div className='documentation-message-alet' style={{ display: props.show ? 'inherit' : 'none' }}>
            <img className='documentation-message-icon' src={IconAproved} />
            <span>Solicitud aprobada por Analista de crédito</span>
            <img 
                className='documentation-message-icon-close cursor-pointer' 
                src={IconNoBlack} 
                onClick={props.handleOnClick}
            />
        </div>
    );
};

export default DocumentationAlert;