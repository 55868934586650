import { FormControl, FormLabel, Input } from "@mui/joy";
import GeneralHelper from "../../../../helpers/GeneralHelper";

const obligatorioStyle = {
    color: "red",
    marginLeft: '5px'
};

export const MoneyInput = (props: any) => {
    return (
        <FormControl>
            {props.title !== undefined && (
                <FormLabel>{props.title} {(props.required === undefined || props.required === true) && <span style={obligatorioStyle}>*</span>} </FormLabel>
            )}
            <Input
                name={props.name}
                placeholder={props.placeholder}
                value={GeneralHelper.AgregarComasAMiles(props.value)}
                onBlur={(event: any) => {
                    props.onBlur?.(event);
                }}
                onChange={(event: any) => {
                    const newValue = event.target.value.replace(/,/g, '');

                    const validation = /^\d*\.?\d{0,2}$/.test(newValue);

                    if (!validation) {
                        event.preventDefault();
                        return;
                    }

                    event.target.value = GeneralHelper.AgregarComasAMiles(newValue);
                    props.onChange(event);
                }}
                disabled={props.disabled}
                startDecorator={props.startDecorator}
            />
        </FormControl>
    );
};