import axios from 'axios';
import { appConfig, appConfigPerfiles, appConfigPerfilesAzure } from '../../../config/Config';
import jwt_decode from 'jwt-decode';
import { GetConfigApiMaster } from '../../helpers/AuthHelper';
import { TMnSolicitudObservadaInsertUpdateRequest, TMnSolicitudObservadaRequest } from '../../models/MasterManager/solicitud-observada-request.model';
import { ResultResponse } from '../../models/Shared/result-response.model';
import { TMnSolicitudObservadaAuditDto } from '../../models/MasterManager/solicitud-observada-response.model';
import { TMnSolicitudCronogramaRequest } from '../../models/MasterManager/solicitud-cronograma-request.model';
import { TMnSolicitudCronogramaAudtDto } from '../../models/MasterManager/solicitud-cronograma-response.model';
import { TMnSolicitudTrackingRequest, TMnSolicitudTrackingResponse } from '../../models/MasterManager/solicitud-tracking-response.model';
import { TMnSolicitudPersonaPorValidarDto } from '../../models/MasterManager/solicitud-persona-observada-response.model';
import { TMnSolicitudPersonaPorValidarRequest } from '../../models/MasterManager/solicitud-persona-observada-request.model';
import { TMnCotizacionAuditDto } from '../../models/MasterManager/cotizacion-response.model';
import { TMnCotizacionIdInsertRequest } from '../../models/MasterManager/cotizacion-request.model';
import { TMnSolicitudAsociadoInsertUpdateRequest, TMnSolicitudAsociadoRequest } from '../../models/MasterManager/solicitud-asociado-request.model';
import { TMnSolicitudAsociadoAuditDto } from '../../models/MasterManager/solicitud-asociado-response.model';
import { MnPersonaEmpresaAdicionalAuditDto } from '../../models/MasterManager/persona-empresa-datos-adicional-response.model';
import { MnPersonaEmpresaAdicionalRequest } from '../../models/MasterManager/persona-empresa-datos-adicional-request.model';

const baseUrl = appConfig.apiUrlwapimaster + 'api/MasterManager/';

const MasterManager = {

  GetSesion: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    const decodedToken: any = jwt_decode(response.accessToken);
    return decodedToken;
  },

  GetMnTablaGenericaDefinicion: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnTablaGenericaDefinicion";
    return axios.post(url, data, config)
  },
  GetMnSolicitud: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnSolicitud";
    return axios.post(url, data, config)
  },
  GetMnSolicitudUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnSolicitudUpdate";
    return axios.post(url, data, config)
  },
  /* GetMnPersonaByID: function (data: any) {
      let url = baseUrl + "GetMnPersonaByID";
      return axios.post(url, data);
  },  */

  GetMnPersonaByID: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount.");
    }
    ////myowasp(appConfig.scopesMicroservices); api://70d68e7b-7ddc-4aff-b996-e3279859b1e2/.default openid profile offline_access
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      //data: data
    }
    let url = baseUrl + "GetMnPersonaByID";
    return axios.post(url, data, config)
  },


  GetMnPersonaByTipoDocAndNroDoc: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount.");
    }
    ////myowasp(appConfig.scopesMicroservices); api://70d68e7b-7ddc-4aff-b996-e3279859b1e2/.default openid profile offline_access
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      //data: data
    }
    let url = baseUrl + "GetMnPersona";
    return axios.post(url, data, config)
  },


  GetMnPersonaIngresosByIDCliente: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnIngresosPersona";
    return axios.post(url, data, config)
  },
  GetMnEmpresaEmpleadorPersona: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnEmpresaEmpleadorPersona";
    return axios.post(url, data, config)
  },
  GetMnMenuPerfil: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    const decodedToken: any = jwt_decode(response.accessToken);
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnMenuPerfil";

    var identPerfil =
      decodedToken.roles[0] == appConfigPerfilesAzure.KeyRolJefeComercialAzure ? appConfigPerfiles.CodigoJefeComercial :
        decodedToken.roles[0] == appConfigPerfilesAzure.KeyRolAnalistaGestorAzure ? appConfigPerfiles.CodigoGestor :
          decodedToken.roles[0] == appConfigPerfilesAzure.KeyRolBPOAzure ? appConfigPerfiles.CodigoBPO :
            decodedToken.roles[0] == appConfigPerfilesAzure.KeyRolEjecutivoAzure ? appConfigPerfiles.CodigoEjecutivo :
              decodedToken.roles[0] == appConfigPerfilesAzure.KeyRolAnalistaPlaftAzure ? appConfigPerfiles.CodigoPlaft :
                decodedToken.roles[0] == appConfigPerfilesAzure.KeyRolAnalistaRiesgosAzure ? appConfigPerfiles.CodigoRiesgos :
                  decodedToken.roles[0] == appConfigPerfilesAzure.KeyRolJefeBPOAzure ? appConfigPerfiles.CodigoJefeBPO :
                    decodedToken.roles[0] == appConfigPerfilesAzure.KeyRolJefeCreditosAzure ? appConfigPerfiles.CodigoJefeCredito :
                      decodedToken.roles[0] == appConfigPerfilesAzure.KeyRolSupervisorCreditoAzure ? appConfigPerfiles.CodigoSupervisorCredito :
                        decodedToken.roles[0] == appConfigPerfilesAzure.KeyRolAuditorAzure ? appConfigPerfiles.CodigoAuditor : 0;
    console.log(decodedToken.roles[0])
    data.identPerfil = identPerfil;
    return axios.post(url, data, config)
  },
  GetMnSolicitudGastos: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnSolicitudGastos";
    return axios.post(url, data, config);
  },
  GetMnDocumentacionDocumento: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnDocumentacionDocumento";
    return axios.post(url, data, config)
  },
  GetMnDocumentacionDocumentoInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnDocumentacionDocumentoInsert";
    return axios.post(url, data, config)
  },

  GetMnDocumentacionDocumentoUpdate: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnDocumentacionDocumentoUpdate";
    return axios.post(url, data, config)
  },

  GetMnDocumentacionDocumentoDelete: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnDocumentacionDocumentoDelete";
    return axios.post(url, data, config)
  },

  GetMnDocumentacionDocumentoDeleteById: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnDocumentacionDocumentoDeleteById";
    return axios.post(url, data, config)
  },

  GetMnUsuarioPerfil: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }

    //let url = baseUrl + "GetMnUsuarioPerfil";
    let url = baseUrl + "GetMnUsuarioPerfilV3";
    return axios.post(url, data, config)
  },
  GetMnSolicitudHistoricoCambiarTasa: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnSolicitudHistoricoCambiarTasa";
    return axios.post(url, data, config)
  },
  GetMnSolicitudHistoricoCambiarTasaInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnSolicitudHistoricoCambiarTasaInsert";
    return axios.post(url, data, config)
  },
  GetMnSolicitudHistoricoReasignarUsuario: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnSolicitudHistoricoReasignarUsuario";
    return axios.post(url, data, config)
  },
  GetMnSolicitudHistoricoReasignarUsuarioInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnSolicitudHistoricoReasignarUsuarioInsert";
    return axios.post(url, data, config)
  },
  GetMnSolicitudPersonaFiador: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnSolicitudPersonaFiador";
    return axios.post(url, data, config)
  },
  GetMnEstadoFinancieroPersonaJuridica: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnEstadoFinancieroPersonaJuridica";
    return axios.post(url, data, config)
  },
  GetMnUsuarios: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnUsuario";
    return axios.post(url, data, config)
  },
  GetMnEvaluacionCotizacion: async (identCotizacion: any) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnEvaluacionCotizacion";

    return axios.post(url, {
      "idenT_EVAL_COTIZACION": 0,
      "idenT_COTIZACION": identCotizacion
    }, config);
  },
  GetMnEvaluacionCotizacionInsert: async (identCotizacion: number, step: number, modelicaRequest: string, modelicaResponse: string, identUsuario: number) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnEvaluacionCotizacionInsert";

    return axios.post(url, {
      "idenT_EVAL_COTIZACION": 0,
      "idenT_COTIZACION": identCotizacion,
      "tipO_LLAMADA": step,
      "jsoN_REQUEST": modelicaRequest,
      "jsoN_RESPONSE": modelicaResponse,
      "idenT_USUARIO_CREADOR": identUsuario,
    }, config);
  },
  GetMnEvaluacionSolicitud: async (identSolicitud: any) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnEvaluacionSolicitud";

    return axios.post(url, {
      "idenT_EVALUACION_SOLICITUD": 0,
      "idenT_SOLICITUD": identSolicitud
    }, config);
  },
  GetMnEvaluacionSolicitudInsert: async (identSolicitud: number, step: number, modelicaRequest: string, modelicaResponse: string) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnEvaluacionSolicitudInsert";

    return axios.post(url, {
      "idenT_EVALUACION_SOLICITUD": 0,
      "idenT_SOLICITUD": identSolicitud,
      "tipO_LLAMADA": step,
      "jsoN_REQUEST": modelicaRequest,
      "jsoN_RESPONSE": modelicaResponse
    }, config);
  },
  GetMnResultadoEvaluacionInsert: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnResultadoEvaluacionInsert";
    return axios.post(url, data, config)
  },
  GetMnResultadoEvaluacion: async (idenT_SOLICITUD: number) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnResultadoEvaluacion";

    return axios.post(url, {
      "idenT_SOLICITUD": idenT_SOLICITUD
    }, config);
  },
  GetMnSolicitudPersonaObservada: async (identSolicitud: number, identPersona: number = 0, identSolicitudPersonaObservada: number = 0) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnSolicitudPersonaObservada";

    return axios.post(url, {
      "idenT_SOLICITUD_PERSONA_OBSERVADA": identSolicitudPersonaObservada,
      "idenT_PERSONA": identPersona,
      "idenT_SOLICITUD": identSolicitud
    }, config);
  },

  GetMnSolicitudObservada: async (request: TMnSolicitudObservadaRequest) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnSolicitudObservada";

    return axios.post<ResultResponse<TMnSolicitudObservadaAuditDto[]>>(url, request, config);
  },

  GetMnSolicitudObservadaInsert: async (request: TMnSolicitudObservadaInsertUpdateRequest) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnSolicitudObservadaInsert";

    return axios.post<ResultResponse<TMnSolicitudObservadaAuditDto[]>>(url, request, config);
  },

  GetMnSolicitudCronograma: async (request: TMnSolicitudCronogramaRequest) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnSolicitudCronograma";

    return axios.post<ResultResponse<TMnSolicitudCronogramaAudtDto[]>>(url, request, config);
  },

  GetMnSolicitudTracking: async (request: TMnSolicitudTrackingRequest) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnSolicitudTracking";

    return axios.post<ResultResponse<TMnSolicitudTrackingResponse[]>>(url, request, config);
  },

  GetMnSolicitudPersonaPorValidar: async (request: TMnSolicitudPersonaPorValidarRequest) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnSolicitudPersonaPorValidar";

    return axios.post<ResultResponse<TMnSolicitudPersonaPorValidarDto>>(url, request, config);
  },

  GetMnCotizacionIdInsert: async (request: TMnCotizacionIdInsertRequest) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnCotizacionIdInsert";

    return axios.post<ResultResponse<TMnCotizacionAuditDto[]>>(url, request, config);
  },

  GetMnEvaluacionSolicitudInsertLog: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "GetMnEvaluacionSolicitudInsertLog";
    return axios.post(url, data, config)
  },

  GetMnSolicitudAsociado: async (request: TMnSolicitudAsociadoRequest) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnSolicitudAsociado";

    return axios.post<ResultResponse<TMnSolicitudAsociadoAuditDto[]>>(url, request, config);
  },

  GetMnSolicitudAsociadoInsert: async (request: TMnSolicitudAsociadoInsertUpdateRequest) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnSolicitudAsociadoInsert";

    return axios.post<ResultResponse<TMnSolicitudAsociadoAuditDto[]>>(url, request, config);
  },
  GetMnUsuarioAsignacion: async () => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnUsuarioAsignacion";

    return axios.post(url, {}, config);
  },

  GetMnUsuarioStatusUpdate: async (identUsuario: number, estado: boolean) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnUsuarioStatusUpdate";
    return axios.post(url, {
      "identUsuario": identUsuario,
      "estado": estado
    }, config);
  },

  GetMnUsuarioAsignacionInsert: async (data: any) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnUsuarioAsignacionInsert";
    return axios.post(url, data, config);
  },

  GetMnUsuarioAsignacionById: async (identUsuario: number) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnUsuarioAsignacionById";

    return axios.post(url, { "identUsuario": identUsuario }, config);
  },

  GetMnUsuarioCargo: async (identUsuario: number) => {

    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetMnUsuarioCargo";

    return axios.post(url, { "IDENT_USUARIO": identUsuario }, config);

  },
  GetExtExperianConfigFC: async (tipoDeuda: any, deudaReal: any) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "GetExtExperianConfigFC";
    return axios.post(url, {
      "tipO_DEUDA": tipoDeuda,
      "deudA_REAL": deudaReal
    }, config);
  },
  GetPersonaEmpresaDatosAdicional: async (data: MnPersonaEmpresaAdicionalRequest) => {
    let config = await GetConfigApiMaster();

    if (config == null) {
      return null;
    }
    let url = baseUrl + `GetPersonaEmpresaDatosAdicional?identPersona=${data.identPersona}`;

    return axios.get<ResultResponse<MnPersonaEmpresaAdicionalAuditDto>>(url, config);
  }
};
export default MasterManager;
