import { Component } from "react";
import { IGaugeProps } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgauge";
import Box from '@mui/joy/Box';
import CircularProgress from '@mui/joy/CircularProgress';

class LoadingProgress extends Component<IGaugeProps, any> {
    render() {
        return (
            <Box
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgb(37 36 36 / 80%)',
                    zIndex: 9999
                }}
            >
                <CircularProgress size="lg" variant='plain' color="danger" />

            </Box>
        )
    }

}
export default LoadingProgress;