import * as Accordion from '@radix-ui/react-accordion';
import { Button, Table } from "@mui/joy";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";

export const ScoringDatosDeReevaluacion = (props: any) => {
  const { clasificacion } = props;
  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <Accordion.Root className="AccordionRoot" type="single" defaultValue="item-1" collapsible>
        <Accordion.Item className="AccordionItem" value="item-1">
          <Accordion.Header>
            <div className="row g-2 mb-2 align-items-center">
              <div className="col-lg-4 textLabelHeadboard">
                <span className="fw-bold">DATOS DE REEVALUACIÓN</span>
              </div>
              <div className="col-lg-4">
                <span className="fw-bold"> </span>
              </div>
              <div className="col-lg-4 textAlignRigth">
                <Accordion.Trigger>
                  ^
                </Accordion.Trigger>
              </div>
            </div>
          </Accordion.Header>
          <Accordion.Content>
            <div className="row g-2 mb-2 align-items-center">
              <div className="col-lg-3 cardObjets">
                <FormControl>
                  <FormLabel>Nueva línea disponible S/<span style={{ color: "red" }}> * </span></FormLabel>
                  <Input
                    name="nuevaLineaDisponible"
                    placeholder="Ej: 00000000"
                    value={clasificacion && clasificacion.linea_disponible}
                  />
                </FormControl>
              </div>
              <div className="col-lg-3 cardObjets">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <FormLabel>N. entidades reportan <span style={{ color: "red" }}> * </span></FormLabel>
                  <Input
                    name="nroEntidadesReportan"
                    placeholder=""
                    value=''
                  />
                </LocalizationProvider>
              </div>
              <div className="col-lg-6 cardObjets">
                <FormControl>
                  <FormLabel>Comentario <span style={{ color: "red" }}> * </span></FormLabel>
                  <Input
                    name="comentario"
                    placeholder=""
                    value={clasificacion.comentario}
                  />
                </FormControl>
              </div>
            </div>
            <div className="row">
              <div className='col-lg-3 cardObjets'>
                <Button
                  size="md"
                  color="danger"
                  variant="outlined"
                  sx={{
                    borderRadius: 24,
                    borderColor: "red",
                    fontWeight: 650,
                    width: 1 / 2,
                  }}
                //onClick={handlerBtnFiadorTitular}
                >

                </Button>
              </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
              <div className="col-lg-12 cardObjets align-top">
                <Table borderAxis="none" className="style-table align-top">
                  <thead>
                    <tr>
                      <th style={{ width: "170px", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>TIPO DEUDA</th>
                      <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>ENTIDAD</th>
                      <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>MONTO DEUDA</th>
                      <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>CUOTA FC</th>
                      <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>CUOTA REAL</th>
                      <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>COMENTARIO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clasificacion &&
                      clasificacion.reporte.map((row: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.tipo_deuda}</td>
                            <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.entidad}</td>
                            <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.monto_deuda}</td>
                            <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.cuota_fC}</td>
                            <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> <Input defaultValue={row.cuota_real} startDecorator={{ soles: 'S/.' }['soles']} disabled={row.solo_lectura} /> </td>
                            <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> <Input defaultValue={row.comentario} disabled={row.solo_lectura} /> </td>
                          </tr>
                        )
                      })
                    }
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                  </tbody>
                </Table>
             
              </div>
             
            </div>

            <div className="row g-2 mb-2 align-items-center borderOtherIncome">
              <div className="col-lg-4 textLabelHeadboard">
                <span className="fw-bold">REPORTE CREDITICIO DEUDA SBS - REPORTE ORIGINAL</span>
              </div>
              <div className="col-lg-4">
                <span className="fw-bold"> </span>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-3 cardObjets">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormLabel>Línea disponible</FormLabel>
                    {/* <FormLabel>{datosReevaluacion.credito.lineaDisponible}</FormLabel> */}
                  </LocalizationProvider>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>N. entidades reportan</FormLabel>
                    {/* <FormLabel>{datosReevaluacion.credito.nroEntidadesReportan}</FormLabel> */}
                  </FormControl>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
              <div className="col-lg-12 cardObjets align-top">
                <Table borderAxis="none" className="style-table align-top">
                  <thead>
                    <tr>
                      <th style={{ width: "170px", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>TIPO DEUDA</th>
                      <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>ENTIDAD</th>
                      <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>MONTO DEUDA</th>
                      <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>CUOTA FC</th>
                      <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>CUOTA REAL</th>
                      <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>COMENTARIO</th>
                    </tr>
                  </thead>
                  <tbody>
                    {clasificacion &&
                      clasificacion.reporte.map((row: any, index: number) => {
                        return (
                          <tr key={index}>
                            <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.tipo_deuda}</td>
                            <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.entidad}</td>
                            <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.monto_deuda}</td>
                            <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.cuota_fC}</td>
                            <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.cuota_fC}</td>
                            <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.comentario}</td>
                          </tr>
                        )
                      })
                    }
                    <tr><td></td></tr>
                    <tr><td></td></tr>
                  </tbody>
                </Table>
              </div>
            </div>
          </Accordion.Content>
        </Accordion.Item>
      </Accordion.Root>
    </div>
  );
};

export default ScoringDatosDeReevaluacion;