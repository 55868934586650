import React, { useEffect } from 'react';
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Table } from "@mui/joy";
import GeneralHelper from '../../../helpers/GeneralHelper';

export const ScoringClasificacionSBS = (props: any) => {

  const { clasificacion, tipoIntegranteClasif } = props;

  const [montoDeuda, setMontoDeuda] = React.useState(0);

  useEffect(() => {
    if (clasificacion?.reporte?.length > 0) {
      let deudaTotal: number = 0;
      clasificacion?.reporte?.map((row: any) => {
        let deuda = row?.monto_deuda;
        if (deuda !== undefined) {
          deudaTotal = deudaTotal + deuda;
        }
      });
      setMontoDeuda(deudaTotal);
    }
  }, [clasificacion])

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>CLASIFICACION SBS ({tipoIntegranteClasif})</AccordionSummary>
          <AccordionDetails>
            <>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-3 cardObjets">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormLabel>Periodo RCC</FormLabel>
                    <FormLabel>{clasificacion && clasificacion.periodo_rcc}</FormLabel>
                  </LocalizationProvider>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>Normal</FormLabel>
                    <FormLabel>{clasificacion && clasificacion.por_deuda_normal} %</FormLabel>
                  </FormControl>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>Problema potencial</FormLabel>
                    <FormLabel>{clasificacion && clasificacion.por_deuda_cpp} %</FormLabel>
                  </FormControl>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>Deficiente</FormLabel>
                    <FormLabel>{clasificacion && clasificacion.por_deuda_def} %</FormLabel>
                  </FormControl>
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-3 cardObjets">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormLabel>Dudoso</FormLabel>
                    <FormLabel>{clasificacion && clasificacion.por_deuda_dud} %</FormLabel>
                  </LocalizationProvider>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>Pérdida</FormLabel>
                    <FormLabel>{clasificacion && clasificacion.por_deuda_per} %</FormLabel>
                  </FormControl>
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center borderOtherIncome">
                <div className="col-lg-4 textLabelHeadboard">
                  <span className="fw-bold">REPORTE CREDITICIO DEUDA SBS - REPORTE ORIGINAL</span>
                </div>
                <div className="col-lg-4">
                  <span className="fw-bold"> </span>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-3 cardObjets">
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormLabel>Línea disponible</FormLabel>
                      <FormLabel>{clasificacion && GeneralHelper.NumeroConCommas(clasificacion.linea_disponible)}</FormLabel>
                    </LocalizationProvider>
                  </div>
                  <div className="col-lg-3 cardObjets">
                    <FormControl>
                      <FormLabel>Monto deuda S/</FormLabel>
                      <FormLabel>{GeneralHelper.NumeroConCommas(montoDeuda > 0 ? montoDeuda.toFixed(2) : 0)}</FormLabel>
                    </FormControl>
                  </div>
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-12 cardObjets align-top">
                  <Table borderAxis="none" className="style-table align-top">
                    <thead>
                      <tr>
                        <th style={{ width: "170px", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>TIPO DEUDA</th>
                        <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>ENTIDAD</th>
                        <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>DEUDA</th>
                        <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>CUOTA FC</th>
                        <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>DEUDA REAL</th>
                        <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>CUOTA REAL</th>
                        <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>COMENTARIO</th>
                      </tr>
                    </thead>
                    <tbody>
                      {clasificacion &&
                        clasificacion.reporte.map((row: any) => {
                          return (
                            <tr>
                              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.tipo_deuda}</td>
                              <td style={{ overflow: "hidden", textOverflow: "ellipsis", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.entidad}</td>
                              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.NumeroConCommas(row.monto_deuda ? row.monto_deuda.toFixed(2) : 0)}</td>
                              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.NumeroConCommas(row.cuota_fc ? row.cuota_fc.toFixed(2) : 0)}</td>
                              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.NumeroConCommas(row.monto_deuda_real ? row.monto_deuda_real.toFixed(2) : 0)}</td>
                              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.NumeroConCommas(row.cuota_fc_real ? row.cuota_fc_real.toFixed(2) : 0)}</td>
                              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.comentario}</td>
                            </tr>
                          )
                        })
                      }
                    </tbody>
                  </Table>
                </div>
              </div>
            </>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>

    </div>
  );

}
export default ScoringClasificacionSBS;