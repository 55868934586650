import { useContext, useEffect, useState } from "react";
import TitleCard from "../elements/titleCard";
import { SelectInput, SimpleInput, SwitchInput, DateInput, NumberInput, TextInput, MoneyInput, SelectAutocompleteInput } from "../elements/inputs";
import GeneralHelper from "../../../../../helpers/GeneralHelper";
import { appConfigKey, appDefaultValues } from "../../../../../../config/Config";
import { ButtonAdd } from "../elements/button";
import { Box, Button, CircularProgress, IconButton, LinearProgress, Table, Tooltip } from "@mui/joy";
import { StepOneContext, StepsContext } from "../../../3.10.-base/providers";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { ConvertidorTablaHelper } from "../../../../1.-shared/1.6.-table/1.6.1.-regimen/helpers/ConvertidorTablaHelper";
import RegimenTablas from "../../../../1.-shared/1.6.-table/1.6.1.-regimen/RegimenTablas";
import { MnFormatoRegimenDto } from "../../../../../models/RequestManager/solicitud-formato-regimen-response.model";
import { DatosPersonalesDelTitular, DatosPersonalesDelTitularDefault } from "../DatosPersonalesDelTitularCard/interfaces";

const DatosLaboralesTitularCard = () => {

  const {
    listCategoriaLaboral,
    listRegimen,
    listMoneda,
    listActividadEconomica,
    clickNext,
    messageCustomerIsBlackListGesintel,
    readonlyForm,
    isTitularJuridico,
    setTitularJuridico
  } = useContext(StepsContext);

  const {
    titularData,
    ingresosTitularData,
    estadosFinancierosData,
    setNewIngresosTitularData,
    setNewLaboresAdicionalesTitularData,
    laboresAdicionalesTitularData,
    generarMnFormatoByRegimen,
    formatoRegimen,
    setFormatoRegimen,
    formatoRegimenLoading,
    setFormatoRegimenLoading
  } = useContext(StepOneContext);

  const [viewModel, setViewModel] = useState<any>({
    identCategoriaLaboral: 0,
    ruc: "",
    razonSocial: "",
    identTipoMoneda: 0,
    ingresosNetos: "",
    fechaIngresoLaboral: "",
    anualizado: false,
    calculoAnualizado: "0.00",
    regimenId: 0,
    tieneTrabajoAnterior: false,
    identCategoriaLaboralAnterior: 0,
    rucAnterior: "",
    razonSocialAnterior: "",
    fechaIngresoLaboralAnterior: "",
    fechaHastaAnterior: "",
    regimenIdAnterior: 0,
    listaOtrosTrabajos: [],
    matrizRegimen: null,
    matrizError: false,
  });

  // const [titularDataViewModel, setTitularDataViewModel] = useState<DatosPersonalesDelTitular>(DatosPersonalesDelTitularDefault);

  const [estadosFinancierosViewModel, setEstadosFinancierosViewModel] = useState<any>({
    B_G_ULTIMA_DJ: {
      FechaAnual: null,
      FechaEEFF: null
    }
  });

  const handlerBtnOtrosIngresosTitular = () => {
    const data = { ...viewModel };
    data.listaOtrosTrabajos.push({
      idFila: ((new Date()).getTime()),
      idCategoria: 0,
      categoria: '',
      nroRuc: '',
      idTipoMoneda: 0,
      tipoMoneda: '',
      symboloMoneda: '',
      ingresoNeto: '',
      fecLaboral: null,
      fecLaboralText: '',
      esAnualizado5ta: false,
      ingresoAnualizado: ''
    });
    setViewModel(data);
  };

  const deleteOtrosIngresosTitular = (index: number) => {
    let data = { ...viewModel };
    let idFila = data.listaOtrosTrabajos[index].idFila;
    data.listaOtrosTrabajos = data.listaOtrosTrabajos.filter((x: any) => x.idFila !== idFila);
    setViewModel(data);
  }

  const onChangeSelectInput = (event: any, newValue: any) => {
    const data = { ...viewModel };
    data[event.target.name] = newValue;
    setViewModel(data);
  };

  const onChangeTextInput = (event: any) => {
    const data = { ...viewModel };
    data[event.target.name] = event.target.value;
    setViewModel(data);
  };

  const eventoContinuar = () => {
    if (clickNext !== 0) {
      //console.log("LOG: Guardando cache ingresos titular: ", viewModel);
      setNewIngresosTitularData(viewModel);
    }
  };

  const eventoCargarDatosTitular = () => {
    if (ingresosTitularData !== 0) {
      //console.log("LOG: Cargando ingresos titular contexto:", ingresosTitularData);
      setViewModel(ingresosTitularData);
    }
  };

  // const eventoCargarTitularData = () => {
  //   if (titularData !== 0) {
  //     setTitularDataViewModel(titularData);
  //   }
  // };

  const eventCargarEstadosFinancieros = () => {
    if (estadosFinancierosData !== 0) {

      const B_G_ULTIMA_DJ = estadosFinancierosData.find((item: any) => item.RegistroId === 1);

      if (B_G_ULTIMA_DJ !== undefined) {
        setEstadosFinancierosViewModel({ ...estadosFinancierosViewModel, B_G_ULTIMA_DJ });
      }

    }
  };

  const eventoCargarLaboresAdicionalesTitularData = () => {
    if (laboresAdicionalesTitularData !== 0) {
      setNewLaboresAdicionalesTitularData(laboresAdicionalesTitularData);
    }
  };

  const cargarRegimenMatriz = (formatoRegimen: MnFormatoRegimenDto | number) => {
    let matrizRegimen: any = {
      identFormatoRegistro: 0,
      identSolicitud: 0,
      identFormato: 0,
      matrizGeneral: [],
      matrizPerfil: [],
    }

    try {
      if (isTitularJuridico && formatoRegimen && typeof formatoRegimen === 'object') {
        matrizRegimen = ConvertidorTablaHelper.ObtenerValoresPorPerfil(formatoRegimen);
      }
    } catch (error) {
    }
    buildTable(matrizRegimen);
  }

  const buildTable = (matrizRegimen: any | null = null) => {
    let newMatrizRegimen = matrizRegimen ?? viewModel.matrizRegimen;
    ConvertidorTablaHelper.ObtenerValoresPorMatriz(newMatrizRegimen);

    //console.log("LOG: Dibujando tabla:", { ...viewModel, matrizRegimen: newMatrizRegimen });
    setViewModel((prevState: any) => ({ ...prevState, matrizRegimen: newMatrizRegimen }));
  }

  useEffect(() => eventoCargarDatosTitular(), [ingresosTitularData]);
  // useEffect(() => eventoCargarTitularData(), [titularData]);
  useEffect(() => eventCargarEstadosFinancieros(), [estadosFinancierosData]);
  useEffect(() => eventoCargarLaboresAdicionalesTitularData(), [laboresAdicionalesTitularData]);
  useEffect(() => eventoContinuar(), [clickNext]);
  useEffect(() => cargarRegimenMatriz(formatoRegimen), [formatoRegimen]);
  //console.log("LOG: Dibujando ingresos titular contexto:", viewModel);
  return (
    <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
      <TitleCard title={viewModel.identCategoriaLaboral == appConfigKey.keyCodigoCategoriaLaboralTres ? "ESTADOS FINANCIEROS" : "DATOS LABORALES TITULAR"} />
      <div className="row g-2 mb-2 align-items-center">
        <div className="row g-2 mb-2 align-items-center">
          {isTitularJuridico &&
            (
              <div className="col-lg-2 cardObjets">
                <SelectInput
                  name={"identCategoriaLaboral"}
                  title={"Categoría laboral"}
                  options={listCategoriaLaboral?.filter((x: any) => titularData.TipoDocumentoId == appConfigKey.keyIdDNI || x.id === appConfigKey.keyCodigoCategoriaLaboralTres)}
                  value={viewModel.identCategoriaLaboral ?? 0}
                  disabled={readonlyForm}
                  onChange={(event: any, newValue: any) => {

                    let tempFechaIngresoLaboral = viewModel.fechaIngresoLaboral;

                    if (appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado === newValue) {
                      tempFechaIngresoLaboral = '';
                    }

                    setViewModel((prevState: any) => ({
                      ...prevState,
                      identCategoriaLaboral: newValue,
                      // regimenId: 0,
                      anualizado: false,
                      calculoAnualizado: '',
                      tieneTrabajoAnterior: false,
                      rucAnterior: "",
                      razonSocialAnterior: "",
                      fechaIngresoLaboralAnterior: null,
                      fechaHastaAnterior: null,
                      regimenIdAnterior: 0,
                      identCategoriaLaboralAnterior: 0,
                      fechaIngresoLaboral: tempFechaIngresoLaboral,
                    }));

                    if (titularData.TipoDocumentoId != appConfigKey.keyIdRUC && newValue != appConfigKey.keyCodigoCategoriaLaboralTres) {
                      setTitularJuridico(false);
                    }

                  }}
                />
              </div>
            )
          }

          {isTitularJuridico && (
            <div className="col-lg-2 cardObjets">
              <SelectInput
                name={"regimenId"}
                title={"Régimen"}
                options={listRegimen}
                value={viewModel.regimenId ?? 0}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => {
                  const data = { ...viewModel };
                  data["regimenId"] = newValue;
                  setViewModel(data);
                  setFormatoRegimen(0);
                  if (newValue > 0) {
                    generarMnFormatoByRegimen(newValue);
                  }
                }}
              />
            </div>
          )}

          {isTitularJuridico && titularData.TipoDocumentoId == appConfigKey.keyIdDNI && (
            <div className="col-lg-2 cardObjets">
              <NumberInput
                name={"ruc"}
                title={"RUC"}
                value={viewModel.ruc}
                placeholder={""}
                longNumber={11}
                disabled={readonlyForm}
                onChange={onChangeTextInput}
              />
            </div>)}

          {isTitularJuridico && titularData.TipoDocumentoId != appConfigKey.keyIdRUC && (
            <div className="col-lg-4 cardObjets">
              <SimpleInput
                name={"razonSocial"}
                title={"Razón Social"}
                placeholder=""
                value={viewModel.razonSocial}
                disabled={readonlyForm}
                onChange={onChangeTextInput}
              />
            </div>
          )}

          {isTitularJuridico && titularData.TipoDocumentoId != appConfigKey.keyIdRUC &&
            (
              <div className="col-lg-2 cardObjets">
                <DateInput
                  name={"fechaIngresoLaboral"}
                  title={"Fecha inicio actividad"}
                  value={viewModel.fechaIngresoLaboral}
                  disabled={readonlyForm || appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado === viewModel.identCategoriaLaboral}
                  onChange={(date: any | null) => {
                    const data = { ...viewModel };
                    data["fechaIngresoLaboral"] = date === null ? '' : date.format("YYYY-MM-DD");
                    setViewModel(data);
                  }}
                />
              </div>
            )
          }

        </div>

        {isTitularJuridico && viewModel.matrizRegimen?.matrizPerfil?.length > 0 && (
          <>
            <div className="col">
              <div style={{ height: "6px" }} className="row mx-2">
                {formatoRegimenLoading && (
                  <LinearProgress className="solicitude-linear-progress" />
                )}
              </div>
            </div>
            <RegimenTablas
              cardClassName="col-lg-6 align-top"
              data={viewModel.matrizRegimen.matrizPerfil}
              readOnly={readonlyForm}
              onChange={(tableIndex: number, filaIndex: number, colIndex: number, event: any) => {
                buildTable(viewModel.matrizRegimen);
              }}
            />
          </>
        )}

        <div className="row g-2 mb-2 align-items-center">
          {!isTitularJuridico && (
            <div className="col-lg-2 cardObjets">
              <SelectInput
                name={"identCategoriaLaboral"}
                title={"Categoría laboral"}
                options={listCategoriaLaboral?.filter((x: any) => x.id !== appConfigKey.keyIdSinCategoria)}
                value={viewModel.identCategoriaLaboral}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => {

                  let tempFechaIngresoLaboral = viewModel.fechaIngresoLaboral;

                  if (appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado === newValue) {
                    tempFechaIngresoLaboral = '';
                  }

                  setViewModel((prevState: any) => ({
                    ...prevState,
                    identCategoriaLaboral: newValue,
                    // regimenId: 0,
                    anualizado: false,
                    calculoAnualizado: '',
                    tieneTrabajoAnterior: false,
                    rucAnterior: "",
                    razonSocialAnterior: "",
                    fechaIngresoLaboralAnterior: null,
                    fechaHastaAnterior: null,
                    regimenIdAnterior: 0,
                    identCategoriaLaboralAnterior: 0,
                    fechaIngresoLaboral: tempFechaIngresoLaboral,
                  }));

                  if (newValue == appConfigKey.keyCodigoCategoriaLaboralTres) {
                    setTitularJuridico(true);
                  }

                }}
              />
            </div>)}
          {!isTitularJuridico && viewModel.identCategoriaLaboral !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado && (
            <div className="col-lg-2 cardObjets">
              <NumberInput
                name={"ruc"}
                title={"RUC"}
                value={viewModel.ruc}
                placeholder={""}
                longNumber={11}
                disabled={readonlyForm}
                onChange={onChangeTextInput}
              />
            </div>)}
          {!isTitularJuridico && viewModel.identCategoriaLaboral !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado && (
            <div className="col-lg-4 cardObjets">
              <SimpleInput
                name={"razonSocial"}
                title={"Razón Social"}
                placeholder=""
                value={viewModel.razonSocial}
                disabled={readonlyForm}
                onChange={onChangeTextInput}
              />
            </div>
          )}
          {!isTitularJuridico &&
            (
              <div className="col-lg-2 cardObjets">
                <SelectInput
                  name={"identTipoMoneda"}
                  title={"Tipo de moneda"}
                  options={listMoneda ?? []}
                  value={viewModel.identTipoMoneda}
                  disabled={readonlyForm}
                  onChange={onChangeSelectInput}
                />
              </div>
            )
          }
          {!isTitularJuridico &&
            (
              <div className="col-lg-2 cardObjets">
                <MoneyInput
                  name={"ingresosNetos"}
                  title={"Ingresos netos"}
                  placeholder=""
                  value={viewModel.ingresosNetos}
                  disabled={readonlyForm}
                  onChange={(event: any) => {
                    setViewModel({
                      ...viewModel,
                      ingresosNetos: event.target.value,
                      calculoAnualizado: GeneralHelper.CalcularAnulizado(event.target.value)
                    });
                  }}
                />
              </div>
            )
          }

          {!isTitularJuridico &&
            (
              <div className="col-lg-2 cardObjets">
                <DateInput
                  name={"fechaIngresoLaboral"}
                  title={"Fecha ingreso laboral"}
                  value={viewModel.fechaIngresoLaboral}
                  disabled={readonlyForm || appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado === viewModel.identCategoriaLaboral}
                  onChange={(date: any | null) => {
                    const data = { ...viewModel };
                    data["fechaIngresoLaboral"] = date === null ? '' : date.format("YYYY-MM-DD");
                    setViewModel(data);
                  }}
                />
              </div>
            )
          }

          {!isTitularJuridico && viewModel.identCategoriaLaboral !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado && (
            <div className="col-lg-4 cardObjets">
              <SelectAutocompleteInput
                title={"Actividad económica"}
                options={listActividadEconomica}
                value={viewModel.ActividadEconomicaId}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, ActividadEconomicaId: newValue })}
              />
            </div>
          )}

          {viewModel.identCategoriaLaboral == appConfigKey.keyCodigoCategoriaLaboralCinco && (
            <>
              <div className="col-lg-2 cardObjets">
                <SwitchInput
                  name={"anualizado"}
                  title={"Anualizado solo 5ta"}
                  checked={viewModel?.anualizado ?? false}
                  // disabled={Number(viewModel?.ingresosNetos?viewModel.ingresosNetos.replace(/,/g, ''):0) < 2000 ? true : false}
                  disabled={readonlyForm}
                  onChange={(event: any) => {
                    const data = { ...viewModel };
                    data["anualizado"] = event.target.checked;
                    data["calculoAnualizado"] = GeneralHelper.CalcularAnulizado(viewModel.ingresosNetos);
                    setViewModel(data);
                  }}
                />
              </div>
              {viewModel.anualizado && (
                <div className="col-lg-2 cardObjets">
                  <SimpleInput
                    title={"Ingresos anualizado"}
                    disabled={true}
                    placeholder=""
                    value={viewModel.calculoAnualizado}
                  />
                </div>
              )}
            </>
          )}

          {!isTitularJuridico &&
            (
              <div className="col-lg-2 cardObjets">
                <SwitchInput
                  name={"tieneTrabajoAnterior"}
                  title={"Ingresar trabajo anterior"}
                  checked={viewModel?.tieneTrabajoAnterior ?? false}
                  disabled={readonlyForm}
                  onChange={(event: any) => {
                    const data = { ...viewModel };
                    data["tieneTrabajoAnterior"] = event.target.checked;
                    setViewModel(data);
                  }}
                />
              </div>
            )
          }

        </div>

        {viewModel.tieneTrabajoAnterior && (
          <div className="row g-2 mb-2 align-items-center">
            <div className="col-lg-2 cardObjets">
              <SelectInput
                name={"identCategoriaLaboralAnterior"}
                title={"Categoría anterior"}
                options={listCategoriaLaboral?.filter((x: any) => x.id !== appConfigKey.keyIdSinCategoria)}
                value={viewModel.identCategoriaLaboralAnterior}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => {

                  let tempFechaIngresoLaboral = viewModel.fechaIngresoLaboralAnterior;
                  let tempFechaHastaAnterior = viewModel.fechaHastaAnterior;

                  if (appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado === newValue) {
                    tempFechaIngresoLaboral = '';
                    tempFechaHastaAnterior = '';
                  }

                  setViewModel({
                    ...viewModel,
                    identCategoriaLaboralAnterior: newValue,
                    regimenIdAnterior: 0,
                    fechaIngresoLaboralAnterior: tempFechaIngresoLaboral,
                    fechaHastaAnterior: tempFechaHastaAnterior,
                  });

                }}
              />
            </div>
            <div className="col-lg-2 cardObjets">
              <NumberInput
                name={"rucAnterior"}
                title={"RUC"}
                placeholder=""
                value={viewModel.rucAnterior}
                longNumber={11}
                disabled={readonlyForm}
                onChange={(event: any) => {
                  const data = { ...viewModel };
                  data["rucAnterior"] = event.target.value;
                  setViewModel(data);
                }}
              />
            </div>
            <div className="col-lg-4 cardObjets">
              <SimpleInput
                name={"razonSocialAnterior"}
                title={"Razón Social"}
                placeholder=""
                value={viewModel.razonSocialAnterior}
                disabled={readonlyForm}
                onChange={(event: any) => {
                  const data = { ...viewModel };
                  data["razonSocialAnterior"] = event.target.value;
                  setViewModel(data);
                }}
              />
            </div>
            {viewModel.identCategoriaLaboralAnterior === appConfigKey.keyCodigoCategoriaLaboralTres && (
              <div className="col-lg-2 cardObjets">
                <SelectInput
                  name={"regimenIdAnterior"}
                  title={"Régimen"}
                  options={listRegimen}
                  value={viewModel.regimenIdAnterior ?? 0}
                  disabled={readonlyForm}
                  onChange={(event: any, newValue: any) => {
                    const data = { ...viewModel };
                    data["regimenIdAnterior"] = newValue;
                    setViewModel(data);
                  }}
                />
              </div>
            )}
            <div className="col-lg-2 cardObjets">
              <DateInput
                name={"fechaIngresoLaboralAnterior"}
                title={"Fecha ingreso laboral"}
                value={viewModel.fechaIngresoLaboralAnterior}
                disabled={readonlyForm || appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado === viewModel.identCategoriaLaboralAnterior}
                onChange={(date: any | null) => {
                  const data = { ...viewModel };
                  data["fechaIngresoLaboralAnterior"] = date === null ? '' : date.format("YYYY-MM-DD");
                  setViewModel(data);
                }}
              />
            </div>
            <div className="col-lg-2 cardObjets">
              <DateInput
                name={"fechaHastaAnterior"}
                title={"Hasta"}
                value={viewModel.fechaHastaAnterior}
                disabled={readonlyForm || appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado === viewModel.identCategoriaLaboralAnterior}
                onChange={(date: any | null) => {
                  const data = { ...viewModel };
                  data["fechaHastaAnterior"] = date === null ? '' : date.format("YYYY-MM-DD");
                  setViewModel(data);
                }}
              />
            </div>
            {/* <div className="col-lg-2 cardObjets">
              <MoneyInput
                placeholder={"Indique los ingresos netos"}
                name={"ingresosNetosAnterior"}
                title={"Ingreso netos"}
                value={viewModel.ingresosNetosAnterior}
                onChange={(event: any) => {
                  const data = { ...viewModel };
                  data["ingresosNetosAnterior"] = event.target.value;
                  data["calculoAnualizadoAnterior"] =
                    GeneralHelper.CalcularAnulizado(event.target.value);
                  setViewModel(data);
                }}
              />
              </div>*/}


            {/*viewModel.identCategoriaLaboralAnterior == appConfigKey.keyCodigoCategoriaLaboralCinco && (
              <>
                <div className="col-lg-2 cardObjets">
                  <SwitchInput
                    name={"anualizadoAnterior"}
                    title={"Anualizado solo 5ta"}
                    checked={viewModel.anualizadoAnterior}
                    onChange={(event: any) => {
                      const data = { ...viewModel };
                      data["anualizadoAnterior"] = event.target.checked;
                      data["calculoAnualizadoAnterior"] =
                        GeneralHelper.CalcularAnulizado(viewModel.ingresosNetosAnterior);
                      setViewModel(data);
                    }}
                  />
                </div>
                {viewModel.anualizadoAnterior && (
                  <div className="col-lg-2 cardObjets">
                    <MoneyInput
                      title={"Ingresos anualizado"}
                      disabled={true}
                      placeholder="Indique Ingresos netos"
                      value={viewModel.calculoAnualizadoAnterior}
                    />
                  </div>
                )}
              </>
            )*/}
          </div>
        )}

        {viewModel.listaOtrosTrabajos.map((data: any, index: any) => (
          <div
            className="row g-2 mb-2 align-items-center borderOtherIncome"
            key={index}
          >
            <div className="col-lg-2 cardObjets">
              <SelectInput
                name={"identCategoriaLaboral"}
                title={"Categoría laboral"}
                options={listCategoriaLaboral?.filter((x: any) => x.id !== appConfigKey.keyIdSinCategoria)}
                value={data.identCategoriaLaboral ?? 0}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => {

                  const tempDatosLaboralesTitular = { ...viewModel };

                  let tempFechaIngresoLaboral = tempDatosLaboralesTitular.listaOtrosTrabajos[index].fechaIngresoLaboral;

                  if (appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado === newValue) {
                    tempFechaIngresoLaboral = '';
                  }

                  tempDatosLaboralesTitular.listaOtrosTrabajos[index].identCategoriaLaboral = newValue;
                  tempDatosLaboralesTitular.listaOtrosTrabajos[index].regimenId = 0;
                  tempDatosLaboralesTitular.listaOtrosTrabajos[index].anualizado = false;
                  tempDatosLaboralesTitular.listaOtrosTrabajos[index].calculoAnualizado = '';
                  tempDatosLaboralesTitular.listaOtrosTrabajos[index].fechaIngresoLaboral = tempFechaIngresoLaboral;

                  setViewModel(tempDatosLaboralesTitular);

                }}
              />
            </div>
            <div className="col-lg-2 cardObjets">
              <NumberInput
                name={"ruc"}
                title={"RUC"}
                placeholder=""
                longNumber={11}
                value={data.ruc}
                disabled={readonlyForm}
                onChange={(event: any) => {
                  data[event.target.name] = event.target.value;
                  const tempDatosLaboralesTitular = {
                    ...viewModel,
                  };
                  tempDatosLaboralesTitular.listaOtrosTrabajos[index] = data;
                  setViewModel(tempDatosLaboralesTitular);
                }}
              />
            </div>
            <div className="col-lg-4 cardObjets">
              <SimpleInput
                name={"razonSocial"}
                title={"Razón Social"}
                placeholder=""
                value={data.razonSocial}
                disabled={readonlyForm}
                onChange={(event: any) => {
                  data[event.target.name] = event.target.value;
                  const tempDatosLaboralesTitular = {
                    ...viewModel,
                  };
                  tempDatosLaboralesTitular.listaOtrosTrabajos[index] = data;
                  setViewModel(tempDatosLaboralesTitular);
                }}
              />
            </div>
            <div className="col-lg-2 cardObjets">
              <SelectInput
                name={"identTipoMoneda"}
                title={"Tipo de moneda"}
                options={listMoneda}
                value={data.identTipoMoneda ?? 0}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => {
                  data["identTipoMoneda"] = newValue;
                  const tempDatosLaboralesTitular = {
                    ...viewModel,
                  };
                  tempDatosLaboralesTitular.listaOtrosTrabajos[index] = data;
                  setViewModel(tempDatosLaboralesTitular);
                }}
              />
            </div>
            <div className="col-lg-2 cardObjets">
              <MoneyInput
                name={"ingresosNetos"}
                title={data.identCategoriaLaboral == appConfigKey.keyCodigoCategoriaLaboralTres ? "Utilidad neta" : "Ingresos netos"}
                placeholder=""
                value={data.ingresosNetos}
                disabled={readonlyForm}
                onChange={(event: any) => {
                  data[event.target.name] = event.target.value;
                  data["calculoAnualizado"] = GeneralHelper.CalcularAnulizado(event.target.value);
                  const tempDatosLaboralesTitular = {
                    ...viewModel,
                  };
                  tempDatosLaboralesTitular.listaOtrosTrabajos[index] = data;
                  setViewModel(tempDatosLaboralesTitular);
                }}
              />
            </div>

            {data.identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCinco && (
              <>
                <div className="col-lg-2 cardObjets">
                  <SwitchInput
                    name={"anualizado"}
                    title={"Anualizado solo 5ta"}
                    checked={data?.anualizado ?? false}
                    disabled={readonlyForm} //{Number(data?.ingresosNetos ? data.ingresosNetos.replace(/,/g, '') : 0) < 2000 ? true : false}
                    onChange={(event: any) => {
                      data["anualizado"] = event.target.checked;
                      data["calculoAnualizado"] = GeneralHelper.CalcularAnulizado(data["ingresosNetos"]);
                      const tempDatosLaboralesTitular = {
                        ...viewModel,
                      };
                      tempDatosLaboralesTitular.listaOtrosTrabajos[index] = data;
                      setViewModel(tempDatosLaboralesTitular);
                    }}
                  />
                </div>
              </>
            )}

            <div className="col-lg-2 cardObjets">
              <DateInput
                name={"fechaIngresoLaboral"}
                title={"Fecha ingreso laboral"}
                value={data.fechaIngresoLaboral ?? ""}
                disabled={readonlyForm || appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado === data.identCategoriaLaboral}
                onChange={(date: any | null) => {
                  data["fechaIngresoLaboral"] = date === null ? '' : date.format("YYYY-MM-DD");
                  const tempDatosLaboralesTitular = {
                    ...viewModel,
                  };
                  tempDatosLaboralesTitular.listaOtrosTrabajos[index] = data;
                  setViewModel(tempDatosLaboralesTitular);
                }}
              />
            </div>

            {data.identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralTres && (
              <div className="col-lg-2 cardObjets">
                <SelectInput
                  name={"regimenId"}
                  title={"Régimen"}
                  options={listRegimen}
                  value={data.regimenId ?? 0}
                  disabled={readonlyForm}
                  onChange={(event: any, newValue: any) => {
                    data["regimenId"] = newValue;
                    const tempDatosLaboralesTitular = {
                      ...viewModel,
                    };
                    tempDatosLaboralesTitular.listaOtrosTrabajos[index] = data;
                    setViewModel(tempDatosLaboralesTitular);
                  }}
                />
              </div>
            )}

            <div className="col-lg-4 cardObjets">
              <SelectAutocompleteInput
                name={"actividadEconomicaId"}
                title={"Actividad económica"}
                options={listActividadEconomica}
                value={data.actividadEconomicaId ?? 0}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => {
                  data["actividadEconomicaId"] = newValue;
                  const tempDatosLaboralesTitular = {
                    ...viewModel,
                  };
                  tempDatosLaboralesTitular.listaOtrosTrabajos[index] = data;
                  setViewModel(tempDatosLaboralesTitular);
                }}
              />
            </div>

            {index >= 0 && <div className="col-lg-1 cardObjets center-button">

              <Tooltip title="Eliminar" placement="bottom">
                <Button
                  disabled={readonlyForm}
                  onClick={() => deleteOtrosIngresosTitular(index)}
                  size="sm"
                  variant="solid"
                  style={{
                    borderRadius: 100,
                    backgroundColor: '#444444',
                    width: '30px',
                    height: '30px'
                  }}
                >
                  <IconButton style={{
                    backgroundColor: '#444444',
                    color: 'white'
                  }}>
                    <DeleteForeverIcon />
                  </IconButton>


                </Button>
              </Tooltip>
            </div>}

          </div>


        ))}

        <div className="row g-2 mb-2 align-items-center ">
          <div className="col-lg-12 cardObjets bottom-right">
            <ButtonAdd
              disabled={readonlyForm}
              onClick={handlerBtnOtrosIngresosTitular}
              title={"Otros Ingresos"} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default DatosLaboralesTitularCard;
