import * as React from 'react';
import MainLayout from '../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout';
import { Tab, Tabs } from '@mui/material';
import CardTitleStart from '../6.20.-cardTitleStart/cardTitleStart';
import CardDebst from '../6.10.-base/components/CardDedts';
import CardMembers from '../6.10.-base/components/CardMembers';
import ScoringTitular from '../6.30.-scoring/scoringTitular';
import ScoringConyugue from '../6.30.-scoring/scoringConyugue';
import ScoringPosicionCliente from '../6.30.-scoring/scoringPosicionCliente';
import ScoringClasificacionSBSIntegrante from '../6.30.-scoring/scoringClasificacionSBSIntegrante';
import ScoringDatosDeReevaluacion from '../6.30.-scoring/scoringDatosDeReevaluacion';
import ScoringInformacionEntidad from '../6.30.-scoring/scoringInformacionEntidad';
import { Button } from '@mui/joy';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';


const headerTitles = ['SCORING', 'FINANCIAMIENTO', 'RESULTADOS'];
const START_POSITION = 0;

const AnalysProfile = () => {

  const [tabsPosition, setTabsPosition] = React.useState(START_POSITION);
  const handleTabsPosition = (e: any, value: any) => {
    setTabsPosition(value);
  };

  const [debts, setDebts] = React.useState({
    cfm: '72%',
    cfc: '40%',
    cuotasS: '2,030',
    cuotasD: '529.0',
    menS: '1,326.0',
    menD: '351.0'
  });

  const [members, setMembers] = React.useState({
    users: [{
      tipoPersona: 1,
      nombre: 'Julio Perez Solis (Titular)',
      nroDocumento: '4786756'
    },
    {
      tipoPersona: 2,
      nombre: 'Erika Ramos Lopez (Cónyuge)',
      nroDocumento: '56456633'
    },
    {
      tipoPersona: 3,
      nombre: 'Agencia Marítima SAC (Empresa)',
      nroDocumento: '204567834'
    },
    ]
  })

  const [titular, setTitular] = React.useState({
    ingresos: {
      moneda: 'Soles',
      ingresoNeto: 2670.00,
      comentario: '',
      categoriaLaboral: '5ta',
      ruc: '5563448374882',
      razonSocial: 'Tranporte MArítimo',
      fechaIngresoLaboral: '19/02/2023',
      ingresoAnualizado: 42000.00,
      separacionDeBienes: 'Si',

      categoriaAnterior: '5ta',
      rucAnterior: '5563448374885',
      razonSocialAnterior: 'Empresa S.A.C.',
      fechaIngresoLaboralAnterior: '19/02/2022',
      fechaHasta: '20/02/2023'
    },
    otrosIngresos: {
      moneda: 'Soles',
      ingresoNeto: 2670.00,
      comentario: '',
      categoriaLaboral: '5ta',
      ruc: '5563448374882',
      razonSocial: 'Tranporte MArítimo',
      fechaIngresoLaboral: '19/02/2023',
      ingresoAnualizado: 42000.00,
    },
  });

  const [conyugue, setConyugue] = React.useState({
    ingresos: {
      moneda: 'Soles',
      ingresoNeto: 2670.00,
      comentario: '',
      categoriaLaboral: '5ta',
      ruc: '5563448374882',
      razonSocial: 'Tranporte MArítimo',
      fechaIngresoLaboral: '19/02/2023',
      ingresoAnualizado: 42000.00,
      separacionDeBienes: 'Si',

      categoriaAnterior: '5ta',
      rucAnterior: '5563448374885',
      razonSocialAnterior: 'Empresa S.A.C.',
      fechaIngresoLaboralAnterior: '19/02/2022',
      fechaHasta: '20/02/2023'
    },
    otrosIngresos: {
      moneda: 'Soles',
      ingresoNeto: 2670.00,
      comentario: '',
      categoriaLaboral: '5ta',
      ruc: '5563448374882',
      razonSocial: 'Tranporte MArítimo',
      fechaIngresoLaboral: '19/02/2023',
      ingresoAnualizado: 42000.00,
    }
  });

  const [posicionCliente, setPosicionCliente] = React.useState({
    productos: [{
      nop: 1,
      producto: 'Crédito vehicular',
      moneda: 'Soles',
      saldoActual: '10,000.00',
      cuota: 300,
      cuotasPagadas: 125,
      proDiasAtrasada: 17
    },
    {
      nop: 2,
      producto: 'Crédito hipotecario',
      moneda: 'Soles',
      saldoActual: '30,000.00',
      cuota: 24,
      cuotasPagadas: 12,
      proDiasAtrasada: 10
    }
  ],
    solicitudes: [{
      solicitud: ''
    }
  ]
  });

  const [clasificacion, setClasificacion] = React.useState({
    integrante: {
      periodoRcc: '02/2023',
      normal: '100%',
      problema: '100%',
      deficiente: '0%',
      dudoso: '0%',
      perdida: '0%',
      lineaDisponible: '26685',
      montoDeuda: '36,899.00'
    },
    rerporte: [{
      tipoDeuda: 'Consumo',
      entidad: 'Interbank',
      montoDeuda: '36,899.00',
      cuotaFC: '809.00',
      cuotaReal: '0.00',
      comentario: ''
    },
    {
      tipoDeuda: 'Comercial',
      entidad: 'BCP',
      montoDeuda: '0.00',
      cuotaFC: '0.00',
      cuotaReal: '0.00',
      comentario: ''
    }
  ]
  });
  
  const [datosReevaluacion, setDatosReevaluacion] = React.useState({
    datos: {
      moneda: 'Soles',
      nuevaLineaDisponible: '26,670.00',
      comentario: '',
      nroEntidadesReportan: '1'
    },
    reporte: [
      {
      tipoDeuda: 'Consumo',
      entidad: 'Interbank',
      montoDeuda: '16,564.00',
      cuotaFC: '809.00',
      cuotaReal: '0.00',
      comentario: ''
      },
      {
        tipoDeuda: 'Comercial',
        entidad: 'BBVA',
        montoDeuda: '0.00',
        cuotaFC: '0.00',
        cuotaReal: '0.00',
        comentario: ''
      }
    ],
    credito: {
      lineaDisponible: '0',
      nroEntidadesReportan: '1'
    },
    reporteOriginal: [
      {
        tipoDeuda: 'Consumo',
        entidad: 'Interbank',
        montoDeuda: '16,564.00',
        cuotaFC: '809.00',
        cuotaReal: '0.00',
        comentario: ''
      },
      {
        tipoDeuda: 'Comercial',
        entidad: 'BBVA',
        montoDeuda: '0.00',
        cuotaFC: '0.00',
        cuotaReal: '0.00',
        comentario: ''
      }
    ]
  });

  const [informacionEntidad, setInformacionEntidad] = React.useState({
    informacion: {
      entidadesDeudaDirecta: '2',
      entidadesDeudaIndirecta: '0',
      entidadesConDeuda: '0',
      entidadesSinDeuda: '0',
      nEntidadReportan: '2',
      documentosMorosos: '1',
      valorS: '98.00',
      valorD: '0.00',
      IndicadorDeDeudor: 'Directo',
      peorCondicionDeuda: 'Morosidad',
      nCtaCteCerrados: '0',
      nTCAnulados: '0',
      nProtestos: 'Morosidad',
      fechaUltimoProtesto: '-',
      nProtestosNoAclarados: '0',
      valorSoles: '0',
      valorDolares: '0',
      fechaUltimaAclaracion: '-'
    },
    clasificacion: [
      {
        tipoDeuda: 'Consumo',
        entidad: 'Interbank',
        montoDeuda: '16,564.00',
        cuotaFC: '809.00',
        cuotaReal: '0.00',
        comentario: ''
      },
      {
        tipoDeuda: 'Comercial',
        entidad: 'BBVA',
        montoDeuda: '0.00',
        cuotaFC: '0.00',
        cuotaReal: '0.00',
        comentario: ''
      }
    ],
    entidad: [
      {
        tipoDeuda: 'Consumo',
        entidad: 'Interbank',
        montoDeuda: '16,564.00',
        cuotaFC: '809.00',
        cuotaReal: '0.00',
        comentario: ''
      },
      {
        tipoDeuda: 'Comercial',
        entidad: 'BBVA',
        montoDeuda: '0.00',
        cuotaFC: '0.00',
        cuotaReal: '0.00',
        comentario: ''
      }
    ]
  });

  const handleNextClick = () => {
    // this.setState({ isLoading : true });
    // this.setState({ step: this.state.step + 1 })
    // this.validarModelicaMotorEvaluacion();
    // const timer = setInterval(() => {
    //   this.setState({ isLoading : false});        
    // }, 1600);
    //   return () => {
    //     clearInterval(timer);
    // };
  }

  return (

    <div>
      <MainLayout />
      <CardTitleStart />
      <div className="row g-2 mb-2 cardAnalystProfile">
        <div className="col-lg-9">

          <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
            <Tabs value={tabsPosition} onChange={handleTabsPosition}>
              {headerTitles.map((header, index) => (<Tab key={index} label={header} className="header-tab-documentation" />))}
            </Tabs>
          </div>
          <ScoringTitular
            titular={titular}
            setScoring={setTitular}
          />
          <ScoringConyugue
            conyugue={conyugue}
            setScoring={setConyugue}
          />
          <ScoringPosicionCliente 
            posicionCliente={posicionCliente}
          />
          <ScoringClasificacionSBSIntegrante 
            clasificacion={clasificacion}
          />
          <ScoringDatosDeReevaluacion 
            datosReevaluacion={datosReevaluacion}
          />
          <ScoringInformacionEntidad
            informacionEntidad={informacionEntidad}
          />

          <div className="row g-2 mb-2 align-items-center ">
            <div className="col-lg-6 cardObjets">
            </div>
            <div className="col-lg-6 cardObjets bottom-right">
                <Button size="md" color="danger" endDecorator={<KeyboardArrowRight />} style={{ marginRight: "35px" }}
                  sx={{ borderRadius: 24, width: 3 / 10 }} onClick={e => handleNextClick()}>
                  Continuar
                </Button>    
              </div>    
          </div>
            

        </div>
        <div className="col-lg-3">
          <div style={{ position : "sticky", top: "0"  }}>
            <CardDebst
              debts={debts}
            />
            <CardMembers 
              members={members}
            />
          </div>
        </div>
      
      
        
      </div>

      
      
    </div>
  )
};

export default AnalysProfile;