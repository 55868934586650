import React, { useEffect, useState } from "react";
import { Button, Table } from "@mui/joy";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { MoneyInput, NumberDecimalInput, NumberInput, SimpleInput, TextInput } from "../15.10.-base/elements/inputs";
import SimbolAdd from "@mui/icons-material/Add";
import Save from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";
import RequestManager from "../../../services/origination/wapirequest.service";
import { Dropdown } from "react-bootstrap";
import CustomDivToggle from "../15.10.-base/elements/customDivToggle/CustomDivToggle";
import { BsThreeDots, BsSearch } from "react-icons/bs";
import { toast } from "sonner";
import { DatosClientes, DatosClientesDefault } from "../15.10.-base/interfaces";
import { appSecurityConfiguration } from "../../../../config/Config";
import { AxiosError } from "axios";

export const Ventas = (props: any) => {
    const [dataVentas, setDataVentas] = useState<DatosClientes>(DatosClientesDefault);
    const [dataListVentas, setDataListVentas] = useState<DatosClientes[]>([]);  
    const [flgShow, setFlgShow] = useState<boolean>(false);
    
    const onClickNuevo = () => {
      setFlgShow(true);
      setDataVentas(DatosClientesDefault);
    }
  
    const handleClickViewDataVentas = async (value: any) =>  {
      setFlgShow(true);
      const response = await RequestManager.GetMnInformeComercialClientesRegistroById({ "identInformeComercialClientes": value });
      if (response.status === 200) {
          setDataVentas(response.data.content);
      }
    }
  
    const handleClickDeleteVentas = async (value: any) =>  {
      setFlgShow(false);
      setDataVentas(DatosClientesDefault);
      const response = await RequestManager.MnInformeComercialClientesRegistroDelete({ "identInformeComercialClientes": value });
      if (response.status === 200) {
          console.log(response);
          setDataVentas(response.data.content);
          CargaInicial();
      }
    }
  
    const CargaInicial = async () => {
      const response = await RequestManager.GetMnInformeComercialClientesRegistro({ "identInformeComercial": props.dataInformeComercial.identInformeComercial });
      if (response.status === 200) {
          setDataListVentas(response.data.content);
      }
    }
  
    const onClickCancelar = () => {
      setFlgShow(false);
      setDataVentas(DatosClientesDefault);
    }
  
    const GuardarVentas = async() => {
        console.log(dataVentas);
      let resultado;
      let bodyInsert = {
          "identInformeComercial": dataVentas.identInformeComercial == 0 ? props.dataInformeComercial.identInformeComercial : dataVentas.identInformeComercial,
          "razonSocial": dataVentas.razonSocial,
          "ruc": dataVentas.ruc,
          "porcentajeSobreCompra": dataVentas.porcentajeSobreCompra,
          "porcentajeFacturacion": dataVentas.porcentajeFacturacion,
          "porcentajeMe": dataVentas.porcentajeMe,
          "porcentajeContado": dataVentas.porcentajeContado,
          "porcentajeCredito": dataVentas.porcentajeCredito,
          "creditoModalidad": dataVentas.creditoModalidad,
          "creditoPlazo": dataVentas.creditoPlazo,
          "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
      }
      let bodyUpdate = {
          "identInformeComercialClientes": dataVentas.identInformeComercialClientes,
          "identInformeComercial": dataVentas.identInformeComercial,
          "razonSocial": dataVentas.razonSocial,
          "ruc": dataVentas.ruc,
          "porcentajeSobreCompra": dataVentas.porcentajeSobreCompra,
          "porcentajeFacturacion": dataVentas.porcentajeFacturacion,
          "porcentajeMe": dataVentas.porcentajeMe,
          "porcentajeContado": dataVentas.porcentajeContado,
          "porcentajeCredito": dataVentas.porcentajeCredito,
          "creditoModalidad": dataVentas.creditoModalidad,
          "creditoPlazo": dataVentas.creditoPlazo,
          "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
      }

      try {
        const response = dataVentas.identInformeComercialClientes == 0 ? 
        await RequestManager.MnInformeComercialClientesRegistroInsert(bodyInsert) : 
        await RequestManager.MnInformeComercialClientesRegistroUpdate(bodyUpdate);
        
        if (response.status === 200) {
          resultado = response.data.isValid;
          if (resultado === true) {
            if(dataVentas.identInformeComercialClientes == 0)
                {toast.success("Registro guardado correctamente", { duration: 5000 });}
            else{toast.success("Registro actualizado correctamente.", { duration: 5000 });}
          }
          else{
            toast.error("Ocurrió un error", { duration: 5000 });
          }
        };
        CargaInicial();

        setFlgShow(false);
        setDataVentas(DatosClientesDefault);        
      } catch (error: AxiosError | any) {            
        if (error.response && error.response.data.status === 400 && error.response.data.errors) {
            const validationErrors = error.response.data.errors;
            if (validationErrors) {
                for (const [field, messages] of Object.entries(validationErrors)) {                            
                    let message = messages as string[]; // Type assertion
                    toast.error(`${field}: ${message}`, { duration: 5000 });
                }
            } else {
                toast.error("Ocurrió un error", { duration: 5000 });
            }
        }
    }           
}
  
    useEffect(() => {
    CargaInicial();
    }, [props]);      
    
    return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>X. VENTAS PRINCIPALES CLIENTES</AccordionSummary>
            <AccordionDetails>
              <>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets align-top">
                        <Table borderAxis="none" className="style-table align-top">
                            <thead>
                                <tr>
                                <th style={{ fontSize: "12px", width: "10%", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>NOMBRE O RAZÓN SOCIAL</th>
                                <th style={{ fontSize: "12px", width: "9%", backgroundColor: "#E1E1E1", }}>RUC</th>
                                <th style={{ fontSize: "12px", width: "9%", backgroundColor: "#E1E1E1", }}>% SOBRE VENTAS</th>
                                <th style={{ fontSize: "12px", width: "9%", backgroundColor: "#E1E1E1", }}>FACTURACIÓN % MN</th>
                                <th style={{ fontSize: "12px", width: "9%", backgroundColor: "#E1E1E1", }}>FACTURACIÓN % ME</th>
                                <th style={{ fontSize: "12px", width: "9%", backgroundColor: "#E1E1E1", }}>% CONTADO</th>
                                <th style={{ fontSize: "12px", width: "9%", backgroundColor: "#E1E1E1", }}>AL CRÉDITO %</th>
                                <th style={{ fontSize: "12px", width: "9%", backgroundColor: "#E1E1E1", }}>AL CRÉDITO MODALIDAD</th>
                                <th style={{ fontSize: "12px", width: "9%", backgroundColor: "#E1E1E1", }}>AL CRÉDITO PLAZO (DÍAS)</th>
                                <th style={{ fontSize: "12px", width: "9%", backgroundColor: "#E1E1E1", }}>ESTADO</th>
                                <th style={{ fontSize: "12px", width: "9%", backgroundColor: "#E1E1E1", }}>ACCIONES</th>
                                </tr>
                            </thead>
                        <tbody>
                        {
                            dataListVentas.length > 0 && dataListVentas.map((row: any, index: number) => {
                            return (
                            <tr key={row.identInformeComercialProduccion}>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.razonSocial}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.ruc}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.porcentajeSobreCompra}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.porcentajeFacturacion}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.porcentajeMe}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.porcentajeContado}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.porcentajeCredito}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.creditoModalidad}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.creditoPlazo}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.estado}</td>
                            <td>
                              <Dropdown key={row.identInformeComercialClientes}>
                                <Dropdown.Toggle
                                  as={CustomDivToggle}
                                  style={{ cursor: "pointer" }}
                                >
                                  <BsThreeDots />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickViewDataVentas(
                                            row.identInformeComercialClientes
                                          )
                                        }
                                      >
                                        Editar
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickDeleteVentas(
                                            row.identInformeComercialClientes
                                          )
                                        }
                                        disabled={props.isDisabled}
                                      >
                                        Eliminar
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            </tr>
                            )
                            })
                        }
                        </tbody>
                        </Table>
                    </div>
                    <div className="row g-2 mb-2 align-items-center">
                        <div className="col-lg-12 cardObjets bottom-right">
                            <Button
                                size="md"
                                color="neutral"
                                variant="outlined"
                                endDecorator={<SimbolAdd />}
                                sx={{ borderRadius: 24, width: 1/8 }}
                                // disabled={props.disabled}
                                disabled={props.isDisabled}
                                onClick={onClickNuevo}>Nuevo
                            </Button>
                        </div>
                    </div>
                </div>
                {flgShow && <div>        
                    <div className="row g-2 mb-2 align-items-center">
                        <div className="col-lg-4 cardObjets">
                            <SimpleInput
                                title={"Nombre o razón social principales clientes"}
                                placeholder="Ingresar"
                                value={dataVentas.razonSocial}
                                // disabled={readonlyForm}
                                disabled={props.isDisabled}
                                onChange={(event: any) => setDataVentas({ ...dataVentas, razonSocial: event.target.value })}
                            />
                        </div>
                        <div className="col-lg-4 cardObjets">
                            <NumberInput
                                title={"RUC"}
                                placeholder="Ingresar"
                                value={dataVentas.ruc}
                                longNumber={20}
                                // disabled={readonlyForm}
                                disabled={props.isDisabled}
                                onChange={(event: any) => setDataVentas({ ...dataVentas, ruc: event.target.value })}
                            />
                        </div>
                        <div className="col-lg-2 cardObjets">
                            <NumberInput
                                title={"% sobre ventas"}
                                placeholder="Ingresar"
                                longNumber={3}
                                value={dataVentas.porcentajeSobreCompra}
                                // disabled={readonlyForm}
                                disabled={props.isDisabled}
                                onChange={(event: any) => setDataVentas({ ...dataVentas, porcentajeSobreCompra: event.target.value })}
                            />
                        </div>
                        <div className="col-lg-2 cardObjets">
                            <NumberInput
                                title={"% facturación % MN"}
                                placeholder="Ingresar"
                                longNumber={3}
                                value={dataVentas.porcentajeFacturacion}
                                // disabled={readonlyForm}
                                disabled={props.isDisabled}
                                onChange={(event: any) => setDataVentas({ ...dataVentas, porcentajeFacturacion: event.target.value })}
                            />
                        </div>
                    </div>
                    <div className="row g-2 mb-2 align-items-center">
                        <div className="col-lg-2 cardObjets">
                            <NumberInput
                                title={"% facturación % ME"}
                                placeholder="Ingresar"
                                longNumber={3}
                                value={dataVentas.porcentajeMe}
                                // disabled={readonlyForm}
                                disabled={props.isDisabled}
                                onChange={(event: any) => setDataVentas({ ...dataVentas, porcentajeMe: event.target.value })}
                            />
                        </div>
                        <div className="col-lg-2 cardObjets">
                            <NumberInput
                                title={"% contado"}
                                placeholder="Ingresar"
                                longNumber={3}
                                value={dataVentas.porcentajeContado}
                                // disabled={readonlyForm}
                                disabled={props.isDisabled}
                                onChange={(event: any) => setDataVentas({ ...dataVentas, porcentajeContado: event.target.value })}
                            />
                        </div>
                        <div className="col-lg-4 cardObjets">
                            <NumberInput
                                title={"% al crédito"}
                                placeholder="Ingresar"
                                longNumber={3}
                                value={dataVentas.porcentajeCredito}
                                // disabled={readonlyForm}
                                disabled={props.isDisabled}
                                onChange={(event: any) => setDataVentas({ ...dataVentas, porcentajeCredito: event.target.value })}
                            />
                        </div>
                        <div className="col-lg-4 cardObjets">
                            <SimpleInput
                                title={"Al crédito modalidad"}
                                placeholder="Ingresar"
                                value={dataVentas.creditoModalidad}
                                // disabled={readonlyForm}
                                disabled={props.isDisabled}
                                onChange={(event: any) => setDataVentas({ ...dataVentas, creditoModalidad: event.target.value })}
                            />
                        </div>
                    </div>
                    <div className="row g-2 mb-2 align-items-center">
                      <div className="col-lg-2 cardObjets">
                          <NumberInput
                              title={"Al créd. plazo (días)"}
                              placeholder="Ingresar"
                              value={dataVentas.creditoPlazo}
                              // disabled={readonlyForm}
                              disabled={props.isDisabled}
                              onChange={(event: any) => setDataVentas({ ...dataVentas, creditoPlazo: event.target.value })}
                          />
                      </div>
                    </div>
                    <div className="row g-2 mb-2 align-items-center ">
                        <div className="col-lg-12 cardObjets bottom-right">
                            <Button
                                size="md"
                                color="neutral"
                                variant="outlined"
                                endDecorator={<Save />}
                                sx={{ borderRadius: 24, width: 1/8 }}
                                disabled={props.isDisabled}
                                onClick={GuardarVentas}
                                >Guardar
                            </Button>
                            <Button
                                size="md"
                                color="neutral"
                                variant="outlined"
                                endDecorator={<Cancel />}
                                sx={{ borderRadius: 24, width: 1/8 }}
                                onClick={onClickCancelar}
                                >Cancelar
                            </Button>
                        </div>
                    </div>
                </div>
                }
              </>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
    </div>
  );
};

export default Ventas;