import { appConfigKey } from '../../../../../../config/Config';
import './styles.scss';
import { Dialog } from "@mui/material";

const ValidarRequestDialog = (props: any) => {
    return (
        <Dialog open={props.open} maxWidth={'sm'}>
            <div className='validar-request-dialong-content'>
                <div className='validar-request-dialog-title'>EMPEZAR {props?.text ? props?.text.toUpperCase() : ''}</div>
                <div className='validar-request-dialog-list-content'>
                    {props?.type === appConfigKey.keyTipoBusquedaEvalCrediticiaExcepcion ?
                        appConfigKey.keyTextDetailEvalCrediticiaExcepcion.replace('[DATA]', (props?.text ? props?.text.toLowerCase() : '')) :
                        appConfigKey.keyTextDetailDefaultProceso.replace('[DATA]', (props?.text ? props?.text.toLowerCase() : ''))}
                </div>
                <div className='row'>
                    <div className='col-6'>
                        <div className='validar-request-dialog-btn-cancelar cursor-pointer' onClick={props.handleClose} >Cancelar</div>
                    </div>
                    <div className='col-6'>
                        <div className='validar-request-dialog-btn-empezar cursor-pointer' onClick={props.handleStart} >Empezar</div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default ValidarRequestDialog;