export class GenerarMnFormatoRegimenRequest {
    identRegimen: number = 0;
    identSolicitud: number = 0;
    identUsuario: number = 0;
    identPerfil: number = 0;
}

export class MnFormatoRegimenUpdateRequest {
    identFormatoRegistro: number = 0;
    jsonTable: string = '';
    identUsuario: number = 0;
}

export class MnFormatoRegimenByIdRequest {
    identFormatoRegistro: number = 0;
}
