import axios from 'axios';
import { ResponseType as ResponseTypeAxios } from 'axios';
import { appConfig } from '../../../config/Config';
import { GetCotizacionSimulacionRequest } from '../../models/ReportManager/cotizacion-simulacion-request.model';

const baseUrl = appConfig.apiUrlwapireport + 'api/ReportManager/'; // https://api.scpfront-dev.com/report/api/ReportManager/
const ReportManager = {

  ObtenerReporte: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapireport}/.default`],
      account: account
    });
    const params = {
      idSolicitud: data.idSolicitud,
      idUsuario: data.idUsuario,
      idReporte: data.idReporte,
      indBPO: data.indBPO,
    };
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      params: params,
      responseType: "blob" as ResponseTypeAxios
    }
    let url = baseUrl + "GetReport";
    return axios.get(url, config)
  },

  GetCotizacionSimulacion: async function (request: GetCotizacionSimulacionRequest) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapireport}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      responseType: "blob" as ResponseTypeAxios
    }
    let url = baseUrl + "GetCotizacionSimulacion";
    return axios.post(url, request, config)
  },

};

export default ReportManager;
