import './financiamientoRatio.scss';
import { Dialog, Grid } from "@mui/material";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";

const RatioRequestDialog = ({ open, handleClose, ratios }: any) => {
    const length = ratios?.length ?? 0;
    return (
        <Dialog open={open} maxWidth={'sm'} >
            <div className='request-ratio-dialog-content'>
                <div className='request-ratio-dialog-title'>RATIO</div>
                <div className='request-ratio-dialog-list-content'>
                    <div className="row g-2 mb-2 align-items-center">
                        {ratios.map((row: any, index: number) => {
                            return (
                                <div className="col-lg-6 d-flex justify-content-center align-top">
                                    <div className="col-12 col-lg-8 mb-3">
                                        <FormControl>
                                            <FormLabel className="fw-bold">{row.label}</FormLabel>
                                            <FormLabel>{row.formula}</FormLabel>
                                        </FormControl>
                                    </div>
                                </div>

                            )
                        })}
                    </div>
                </div>
                <div className='request-ratio-dialog-btn-close cursor-pointer' onClick={handleClose}>Cerrar</div>
            </div>
        </Dialog>
    );
};

export default RatioRequestDialog;