import axios from 'axios';
import { appConfig, appConfigKey } from '../../../config/Config';
import { GetConfigApiServiceProvider } from '../../helpers/AuthHelper';
import { BTCrearPersonaFisicaRequest, BTCrearClienteRequest, BTAltaClienteReducidaRequest } from '../../models/bantotal/index';
import { reqClasificacionDto } from '../../models/ManagerProfile/ClasificacionDto';
import { reqReevaluarDto } from '../../models/ManagerProfile/Reevaluar';
import { BTObtenerPersonaNaturalRequest } from '../../models/ServicesProviderManager/obtener-persona-natural-request.model';
import { ResultResponse } from '../../models/Shared/result-response.model';
import { BTObtenerPersonaNaturalDto } from '../../models/ServicesProviderManager/obtener-persona-natural-response.model';
import { BTObtenerResumenPrestamoDto } from '../../models/ServicesProviderManager/obtener-resumen-prestamo-response.model';
import { BTAltaClienteReducidaDto } from '../../models/bantotal/BTAltaClienteReducida/BTAltaClienteReducidaDto';
import { BTObtenerDatosPartnerVendedorRequest } from '../../models/bantotal/BTObtenerDatosPartnerVendedor/BTObtenerDatosPartnerVendedorRequest';
import { BTObtenerDatosPartnerVendedorDto } from '../../models/bantotal/BTObtenerDatosPartnerVendedor/BTObtenerDatosPartnerVendedorDto';
import { BTCrearPersonaJuridicaRequest } from '../../models/bantotal/BTCrearPersonaJuridica/BTCrearPersonaJuridicaRequest';

const baseUrl = appConfig.apiUrlwapiservicesprovider + 'api/ApiIntegration/'; // https://api.scpfront-dev.com/servicesprovider/api/ApiIntegration/
const ServicesProvider = {

  AutenticacionBantotal: async function () {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "AutenticacionBantotal";
    return axios.post(url, null, config)
  },


  ObtenerEstadosVehiculo: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BTObtenerEstadosVehiculo";
    return axios.get(url, config)
  },


  ObtenerTipoDocumento: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BTObtenerTiposDeDocumento";
    return axios.get(url, config)
  },

  ObtenerEstadoCiviles: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BTObtenerEstadoCiviles";
    return axios.get(url, config)
  },

  ObtenerIdentidadesGenero: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BTObtenerIdentidadesDeGenero";
    return axios.get(url, config)
  },

  ObtenerIdentidadesMagnitud: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTObtenerGuiaEspecialDeProceso";
    return axios.post(url, data, config)
  },

  ObtenerIdentidadesActividadEconomica: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BTObtenerActividades";
    return axios.get(url, config)
  },

  ObtenerCategoriaLaboral: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BTCategoriaLaboral";
    return axios.get(url, config)
  },

  ObtenerMonedas: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BTObtenerMonedas";
    return axios.get(url, config)
  },

  ObtenerConcesionarios: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BTObtenerConcesionarios";
    return axios.get(url, config)
  },

  ObtenerSucursal: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTObtenerSucursal";
    return axios.post(url, data, config)
  },

  ObtenerVendedores: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTObtenerVendedores";
    return axios.post(url, data, config)
  },


  ObtenerMarcas: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTObtenerMarcas";
    return axios.post(url, data, config)
  },

  ObtenerModelos: async function (marcaUId: number, data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTObtenerModelos?marcaUId=" + marcaUId;
    return axios.post(url, data, config)
  },


  ObtenerVersion: async function (modeloUId: number, data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTObtenerVersion?modeloUId=" + modeloUId;
    return axios.post(url, data, config)
  },

  ObtenerProfesiones: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BTObtenerProfesiones";
    return axios.get(url, config)
  },

  ObtenerPaises: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BTObtenerPaises";
    return axios.get(url, config)
  },

  ObtenerEstadoVehiculo: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BTObtenerEstadosVehiculo";
    return axios.get(url, config)
  },

  ObtenerProductos: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTObtenerProductos";
    return axios.post(url, data, config)
  },



  ObtenerCampanias: async function (productoUId: number, versionUId: number, data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BtObtenerCampanias?productoUId=" + productoUId + "&versionUId=" + versionUId;
    return axios.post(url, data, config)
  },


  ObtenerCantidadCuotas: async function (productoUId: number) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BtObtenerCantidadCuotas?productoUId=" + productoUId;
    return axios.post(url, null, config)
  },

  ObtenerCoberturaDesgravament: async function (productoUId: number) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BtObtenerCoberturaDesgravament?productoUId=" + productoUId;
    return axios.post(url, null, config)
  },


  ModellicaMotorEvaluacion: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "ModellicaMotorEvaluacionV2";
    return axios.post(url, data, config)
  },

  ValidarListasInhabilitados: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTValidarListasInhabilitados";
    return axios.post(url, data, config)
  },

  ValidarGesintelAML: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      params: data
    }
    let url = baseUrl + "BTGesintelObtenerAMLResult";
    return axios.get(url, config)
  },

  ObtenerUsos: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BtObtenerUsos";
    return axios.get(url, config)
  },
  ObtenerProveedoresGPS: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BTObtenerProveedoresGPS";
    return axios.get(url, config)
  },



  SimularOfertasVehicular: async function (clienteUId: number, data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTSimularOfertasVehicular?clienteUId=" + clienteUId;
    return axios.post(url, data, config)
  },

  ObtenerDetalleOferta: async function (simulacionUId: number, clienteUId: number, data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTObtenerDetalleOferta?simulacionUId=" + simulacionUId + "&clienteUId=" + clienteUId;
    return axios.post(url, data, config)
  },

  ObtenerComisiones: async function (productoUId: number) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: null
    }
    let url = baseUrl + "BTObtenerComisiones?productoUId=" + productoUId;
    return axios.get(url, config)
  },

  ObtenerComisionesCuota: async function (productoUId: number) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: null
    }
    let url = baseUrl + "BTObtenerComisionesCuota?productoUId=" + productoUId;
    return axios.get(url, config)
  },


  ObtenerTiposGPS: async function () {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: null
    }
    let url = baseUrl + "BTObtenerTiposGPS";
    return axios.get(url, config)
  },



  AsociarDocumentoDigital: async function (data: any, operacionUId: number) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTAsociarDocumentoDigital?operacionUId=" + operacionUId;
    return axios.post(url, data, config)
  },

  ActualizarDocumentoDigital: async function (data: any, operacionUId: number) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTActualizarDocumentoDigital?operacionUId=" + operacionUId;
    return axios.post(url, data, config)
  },

  ObtenerDocumentoDigital: async function (data: any, operacionUId: number) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTObtenerDocumentoDigital?operacionUId=" + operacionUId;
    return axios.post(url, data, config)
  },

  EliminarDocumentoDigital: async function (operacionUId: number, documentoId: number) {
    const config = await GetConfigApiServiceProvider();

    if (config == null) {
      return null;
    }

    let url = baseUrl + `BTEliminarDocumentoDigitalDto?operacionUId=${operacionUId}&documentoId=${documentoId}`;
    return axios.delete(url, config)
  },

  ObtenerDocumentosPorTipo: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTObtenerDocumentosPorTipo";
    return axios.post(url, data, config)
  },

  ObtenerNivelesEducativos: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BTObtenerNivelesEducativos";
    return axios.get(url, config)
  },

  ObtenerDestinoCredito: () => {
    const mockPromise = new Promise((resolve, reject) => {
      resolve({
        status: 200,
        data: {
          content: [
            { id: 1, description: "Description 1" },
            { id: 2, description: "Description 2" },
            { id: 3, description: "Description 3" },
            { id: 4, description: "Description 4" }
          ]
        }
      });
    });
    return mockPromise;
  },

  ObtenerTiposDeVivienda: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) {
      throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
    }
    //myowasp(appConfig.scopesMicroservices);
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
      data: data
    }
    let url = baseUrl + "BTObtenerTiposDeVivienda";
    return axios.get(url, config)
  },


  ObtenerEstadosProvinciasDepartamentos: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BtObtenerEstadosProvinciasDepartamentos";
    return axios.post(url, data, config)
  },

  ObtenerCiudadesLocalidades: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BtObtenerCiudadesLocalidades";
    return axios.post(url, data, config)
  },

  ObtenerBarriosColonias: async function (data: any) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BtObtenerBarriosColonias";
    return axios.post(url, data, config)
  },

  BTContratarVehicular: async function (data: any, operacionUId: number, clienteUId: number) {
    const account = appConfig.msalInstance.getActiveAccount();
    if (!account) { throw Error("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount."); }
    const response = await appConfig.msalInstance.acquireTokenSilent({
      scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
      account: account
    });
    let config = {
      headers: {
        'Authorization': 'Bearer ' + response.accessToken
      },
    }
    let url = baseUrl + "BTContratarVehicular?operacionUId=" + operacionUId + "&clienteUId=" + clienteUId;
    return axios.post(url, data, config)
  },

  BTObtenerIdentificadorUnico: async (tipoDocumentoId: number, numeroDocumento: string) => {
    const config = await GetConfigApiServiceProvider();

    if (config == null) {
      return null;
    }

    const url = `${baseUrl}BTObtenerIdentificadorUnico`;

    return axios.post(url, {
      "paisDocumentoId": appConfigKey.PaisDocumentoId,
      "tipoDocumentoId": tipoDocumentoId,
      "numeroDocumento": numeroDocumento.trimStart().trimEnd()
    }, config);
  },

  BTCrearPersonaFisica: async (persona: BTCrearPersonaFisicaRequest) => {

    const config = await GetConfigApiServiceProvider();

    if (config == null) {
      return null;
    }

    const url = `${baseUrl}BtCrearPersonaFisica`;

    const jsonString = JSON.stringify(persona);
    const sdtJSPSsBTPersonaF = JSON.parse(jsonString);
    const data = {
      "sdtJSPSsBTPersonaF": sdtJSPSsBTPersonaF
    };

    return axios.post(url, data, config);

  },

  BTCrearPersonaJuridica: async (data: BTCrearPersonaJuridicaRequest) => {

    const config = await GetConfigApiServiceProvider();

    if (config == null) {
      return null;
    }

    const url = `${baseUrl}BtCrearPersonaJuridica`;

    return axios.post(url, data, config);

  },

  BTCrearCliente: async (request: BTCrearClienteRequest) => {

    const config = await GetConfigApiServiceProvider();

    if (config == null) {
      return null;
    }

    const url = `${baseUrl}BTCrearCliente`;

    const jsonString = JSON.stringify(request);
    const json = JSON.parse(jsonString);

    return axios.post(url, json, config);

  },

  BTActualizaPersonaFisica: async (data: BTCrearPersonaFisicaRequest, personaUId: number) => {

    const config = await GetConfigApiServiceProvider();

    if (config == null) {
      return null;
    }

    const url = `${baseUrl}BtActualizaPersonaFisica?personaUId=${personaUId}`;

    return axios.post(url, data, config);

  },

  WorkflowClasificacion: async (request: reqClasificacionDto) => {
    const config = await GetConfigApiServiceProvider();

    if (config == null) {
      return null;
    }

    const url = `${baseUrl}WorkflowClasificacion`;

    const jsonString = JSON.stringify(request);
    const json = JSON.parse(jsonString);

    return axios.post(url, json, config);

  },

  WorkflowMEM: async (request: reqReevaluarDto) => {
    const config = await GetConfigApiServiceProvider();

    if (config == null) {
      return null;
    }

    const url = `${baseUrl}WorkflowMEM`;

    const jsonString = JSON.stringify(request);
    const json = JSON.parse(jsonString);

    return axios.post(url, json, config);

  },

  BTObtenerPersonaNatural: async (body: BTObtenerPersonaNaturalRequest) => {
    const config = await GetConfigApiServiceProvider();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "BTObtenerPersonaNatural";

    return axios.post<ResultResponse<BTObtenerPersonaNaturalDto>>(url, body, config);

  },

  BTObtenerResumenPrestamo: async (operacionUId: number) => {
    const config = await GetConfigApiServiceProvider();

    if (config == null) {
      return null;
    }

    let url = baseUrl + `BTObtenerResumenPrestamo?operacionUId=${operacionUId}`;

    return axios.get<ResultResponse<BTObtenerResumenPrestamoDto>>(url, config);

  },

  BTAltaReducidaClientes: async (body: BTAltaClienteReducidaRequest) => {
    const config = await GetConfigApiServiceProvider();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "BTAltaReducidaClientes";

    return axios.post<ResultResponse<BTAltaClienteReducidaDto>>(url, body, config);

  },

  ObtenerDatosPartnerVendedor: async (body: BTObtenerDatosPartnerVendedorRequest) => {
    const config = await GetConfigApiServiceProvider();

    if (config == null) {
      return null;
    }

    let url = baseUrl + "ObtenerDatosPartnerVendedor";

    return axios.post<ResultResponse<BTObtenerDatosPartnerVendedorDto>>(url, body, config);
  },

};

export default ServicesProvider;
