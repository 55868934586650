import IconSolicitudCustom from '../../assets/media/icons/IconSolicitudCustom.svg';
import IconCotizacionCustom2 from '../../assets/media/icons/IconCotizacionCustom2.svg';
import IconAdministracionCustom from '../../assets/media/icons/IconAdministracionCustom.svg';
class IconHelper {

    static ICON_SOLICITUD_CUSTOM = 'Imagenes/IconSolicitudCustom.svg';
    static ICON_SOLICITUD_CUSTOM_1 = 'Imagenes/IconCotizacionCustom2.svg';
    static ICON_SOLICITUD_CUSTOM_2 = 'Imagenes/IconAdministracionCustom.svg';
    

    static BuscarIcon(icon: string) {

        switch (icon) {

            case this.ICON_SOLICITUD_CUSTOM: {
                return IconSolicitudCustom
            }

            case this.ICON_SOLICITUD_CUSTOM_1: {
                return IconCotizacionCustom2
            }

            case this.ICON_SOLICITUD_CUSTOM_2: {
                return IconAdministracionCustom
            }
        }

    }

}

export default IconHelper