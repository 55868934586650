import { appDefaultValues } from "../../../../config/Config";

export class BTCrearPersonaFisicaDatosLaborales {
    private ocupacionId: number;
    private actividadLaboral: string;
    private ocupacion: string;
    private ingresoMensual: number;
    private rucEmpleador: string;
    private fechaIni: string;
    private ingresos: number;
    private ingresoNeto: number;
    private monedaIngreso: number;
    private nombre: string;
    private actividadLaboralId: number;
    private tipoIngreso: number;

    constructor() {
        this.ocupacionId = appDefaultValues.NumberDefault;
        this.actividadLaboral = appDefaultValues.StringEmpty;
        this.ocupacion = appDefaultValues.StringEmpty;
        this.ingresoMensual = appDefaultValues.NumberDefault;
        this.rucEmpleador = appDefaultValues.StringEmpty;
        this.fechaIni = appDefaultValues.StringEmpty;
        this.ingresos = appDefaultValues.NumberDefault;
        this.ingresoNeto = appDefaultValues.NumberDefault;
        this.monedaIngreso = appDefaultValues.NumberDefault;
        this.nombre = appDefaultValues.StringEmpty;
        this.actividadLaboralId = appDefaultValues.NumberDefault;
        this.tipoIngreso = appDefaultValues.NumberDefault;
    }

    public getOcupacionId(): number {
        return this.ocupacionId;
    }

    public setOcupacionId(ocupacionId: number): void {
        this.ocupacionId = ocupacionId;
    }

    public getActividadLaboral(): string {
        return this.actividadLaboral;
    }

    public setActividadLaboral(actividadLaboral: string): void {
        this.actividadLaboral = actividadLaboral;
    }

    public getOcupacion(): string {
        return this.ocupacion;
    }

    public setOcupacion(ocupacion: string): void {
        this.ocupacion = ocupacion;
    }

    public getIngresoMensual(): number {
        return this.ingresoMensual;
    }

    public setIngresoMensual(ingresoMensual: number): void {
        this.ingresoMensual = ingresoMensual;
    }

    public getRucEmpleador(): string {
        return this.rucEmpleador;
    }

    public setRucEmpleador(rucEmpleador: string): void {
        this.rucEmpleador = rucEmpleador;
    }

    public getFechaIni(): string {
        return this.fechaIni;
    }

    public setFechaIni(fechaIni: string): void {
        this.fechaIni = fechaIni;
    }

    public getIngresos(): number {
        return this.ingresos;
    }

    public setIngresos(ingresos: number): void {
        this.ingresos = ingresos;
    }

    public getIngresoNeto(): number {
        return this.ingresoNeto;
    }

    public setIngresoNeto(ingresoNeto: number): void {
        this.ingresoNeto = ingresoNeto;
    }

    public getMonedaIngreso(): number {
        return this.monedaIngreso;
    }

    public setMonedaIngreso(monedaIngreso: number): void {
        this.monedaIngreso = monedaIngreso;
    }

    public getNombre(): string {
        return this.nombre;
    }

    public setNombre(nombre: string): void {
        this.nombre = nombre;
    }

    public getActividadLaboralId(): number {
        return this.actividadLaboralId;
    }

    public setActividadLaboralId(actividadLaboralId: number): void {
        this.actividadLaboralId = actividadLaboralId;
    }

    public getTipoIngreso(): number {
        return this.tipoIngreso;
    }

    public setTipoIngreso(tipoIngreso: number): void {
        this.tipoIngreso = tipoIngreso;
    }
}