import { useEffect, useState } from 'react';
import './login.scss';
import 'bootstrap/dist/css/bootstrap.css';
import { Button, IconButton } from '@mui/joy';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import SantanderLogoRojo from '../../../../../assets/media/icons/SantanderLogoRojo.svg';
import { useNavigate } from "react-router-dom";
import ServicesProvider from '../../../../services/support/wapiservicesprovider';
import { useMsal } from '@azure/msal-react';
import { appConfig, appSecurityConfiguration, appConfigRelease, appConfigPerfiles, appConfigKey, appConfigPerfilesAzure } from '../../../../../config/Config';
import MasterManager from '../../../../services/origination/wapimaster.service';

const SignInButton = () => {
  const { instance } = useMsal();
  const signIn = () => {
    instance.loginRedirect({
      scopes: ['openid', 'profile', 'offline_access'],
    });
  };
  return (
    <div className="col-lg-12 ">
      <Button onClick={signIn}
        disabled={false}
        size="lg"
        variant="solid"
        style={{
          borderRadius: 100,
          backgroundColor: '#EC0000',
          width: '100%',
          height: '40px'
        }}
      >Acceder
        <IconButton style={{
          backgroundColor: '#EC0000',
          color: 'white',
          position: 'relative',
          left: '29.33%',
          right: '33.33%',
          top: '2.33%',
          bottom: '20%'
        }}>
          <ArrowForwardIosIcon />
        </IconButton>
      </Button>
    </div>
  );
};

function Login() {

  const { accounts } = useMsal();

  let navigate = useNavigate();


  const InitDefaultLogin = async () => {

    if (accounts.length > 0) {
      const account = accounts[0];


      const response: any = await MasterManager.GetSesion(account);
      if (!response) {
        return;
      }

      let identPerfil =
        response.roles[0] == appConfigPerfilesAzure.KeyRolJefeComercialAzure ? appConfigPerfiles.CodigoJefeComercial :
          response.roles[0] == appConfigPerfilesAzure.KeyRolAnalistaGestorAzure ? appConfigPerfiles.CodigoGestor :
            response.roles[0] == appConfigPerfilesAzure.KeyRolBPOAzure ? appConfigPerfiles.CodigoBPO :
              response.roles[0] == appConfigPerfilesAzure.KeyRolEjecutivoAzure ? appConfigPerfiles.CodigoEjecutivo :
                response.roles[0] == appConfigPerfilesAzure.KeyRolAnalistaPlaftAzure ? appConfigPerfiles.CodigoPlaft :
                  response.roles[0] == appConfigPerfilesAzure.KeyRolAnalistaRiesgosAzure ? appConfigPerfiles.CodigoRiesgos :
                    response.roles[0] == appConfigPerfilesAzure.KeyRolJefeBPOAzure ? appConfigPerfiles.CodigoJefeBPO :
                      response.roles[0] == appConfigPerfilesAzure.KeyRolJefeCreditosAzure ? appConfigPerfiles.CodigoJefeCredito :
                        response.roles[0] == appConfigPerfilesAzure.KeyRolSupervisorCreditoAzure ? appConfigPerfiles.CodigoSupervisorCredito :
                          response.roles[0] == appConfigPerfilesAzure.KeyRolAuditorAzure ? appConfigPerfiles.CodigoAuditor : 0;

      appSecurityConfiguration.SessionId = response.name;
      appSecurityConfiguration.SessionPerfil = identPerfil;

      let UserDat = {
        idenT_USUARIO: 0,
        username: response.name,
        emaiL_INTERNO: ""
      }

      const responseUsuario: any = await MasterManager.GetMnUsuarios(UserDat);

      if (responseUsuario?.status !== 200) {
        return;
      }
      const { content } = responseUsuario.data;
      if (!content || content?.length === 0) {
        return;
      }

      appSecurityConfiguration.SessionUserId = responseUsuario.data.content[0].idenT_USUARIO;
      localStorage.setItem('UserMn', responseUsuario.data.content[0].idenT_USUARIO);
      localStorage.setItem('UserProfileMn', responseUsuario.data.content[0].idenT_PERFIL);


      try {
        ServicesProvider.AutenticacionBantotal().then(() => {
          if (identPerfil == appConfigPerfiles.CodigoBPO) {
            navigate(`/BandejaSolicitudes/${appConfigKey.keyTipoBusquedaBpoPorGestionar}`);
          }
          else if (identPerfil == appConfigPerfiles.CodigoPlaft) {
            navigate(`/BandejaSolicitudes/${appConfigKey.keyTipoBusquedaPlaftPorGestionar}`);
          }
          else if (identPerfil == appConfigPerfiles.CodigoRiesgos) {
            navigate(`/BandejaSolicitudes/${appConfigKey.keyTipoBusquedaRiesgosPorGestionar}`);
          }
          else if (identPerfil == appConfigPerfiles.CodigoJefeBPO) {
            navigate(`/BandejaSolicitudes/${appConfigKey.keyTipoBusquedaEquipoPorGestionar}`);
          }
          else if (identPerfil == appConfigPerfiles.CodigoGestor ||
            identPerfil == appConfigPerfiles.CodigoJefeCredito ||
            identPerfil == appConfigPerfiles.CodigoSupervisorCredito) {

            appConfigKey.TituloBandejaPrincipal = appConfigKey.MenuOpcionEvalCrediticia + " - " +
              appConfigKey.MenuSubOpcionGestionar;
            navigate(`/BandejaSolicitudes/${appConfigKey.keyTipoBusquedaEvalCrediticiaPorGestionar}`);

          }
          else if (identPerfil == appConfigPerfiles.CodigoAuditor) {
            navigate(`/BandejaSolicitudes/${appConfigKey.keyTipoBusquedaAuditorPorGestionar}`);
          }
          else {
            navigate("/Cotizacion");
          }
        }).catch(error => {
          //myowasp("Error al llamar a AutenticacionBantotal:", error);
          alert("Error al llamar a Autenticacion Bantotal. Contacte con su administrador")
        });
      } catch (error) {
        //myowasp("Error antes de llamar a AutenticacionBantotal:", error);
        alert("Error al llamar a Autenticacion Bantotal. Contacte con su administrador")
      }

    }

  }



  useEffect(() => {
    InitDefaultLogin();

  }, [accounts]);

  return (
    <div className="loginBackground">
      <div className="contendorLogin">
        <div className="wrapper">
          <div className="formContenedorLogin">
            <div className="divTextTitulo">
              <label className="labelLoginTitulo">
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-3 ">
                    <img src={SantanderLogoRojo} alt="" style={{ width: '50%' }} />
                  </div>
                  <div className="col-lg-6 ">
                    <h1 className="textLabelLoginTitulo">
                      Santander
                    </h1>
                  </div>
                </div>
                <h3 className="textLabelLoginTitulo">
                  Consumer
                </h3>
                <h5 className="textLabelLoginSubTitulo">
                  Crédito Vehicular Santander Consumer
                </h5>
              </label>
              <div className="rounded-10 p-3 p-md-4 mb-4 mb-md-4 ">
                <div className="row g-2 mb-2 align-items-center textLabelLoginSubTitulo">
                  <SignInButton />
                </div>
                <div className="row g-2 mb-2 align-items-center">
                  {appConfigRelease.Environment ?
                    <span className='textVersion'>{appConfigRelease.Version} - {appConfigRelease.Build} [{appConfigRelease.Environment}]</span> :
                    <span className='textVersion'>[local]</span>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default Login;
