import { ReactComponent as CloseIcon } from './../../../../../../assets/media/icons/ico-close-modal.svg'
import { Button, FormControl, FormLabel, Input, Textarea } from '@mui/joy';
import './styles.scss';
import { Dialog, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import ArrowRight from './../../../../../../assets/media/icons/ico_arrowright.svg';
import { useEffect, useState } from 'react';
import { appConfigComentarioCambioTea, appConfigKey, appSecurityConfiguration } from './../../../../../../config/Config';  
import { Toaster, toast } from "sonner";
import { General } from "../../../3.10.-base/obtenerServicios";

const CambiarTeaOpen = (props: any) => {

    const [requestBody, setRequestBody] = useState<any>(null);   
    const [bodyCambiarTea, setBodyCambiarTea] = useState([]);
    
    useEffect(() => {
        if(props.idSolicitud !== null && props.open) {  
        }
        setRequestBody(null);
    }, [props.open]);


    const handleChange = (e: any) => {
        let { name, value, type } = e.target;
        if (type === 'number' && value === "") {
            value = 0;
        };
        if (type === 'select') {
            value = parseInt(value);
        };
        if (type === 'date' && value === "Invalid Date") {
            value = null
        }
        
        setRequestBody({
            ...requestBody, [name]: value
        });
    };

    const SaveModal = () => {  
        if(requestBody === undefined || requestBody.teA_NUEVA == null || requestBody.teA_NUEVA === "") {
            toast.error(appConfigKey.keyTasaNuevaEmpty , { duration: appConfigKey.keyDurationToast });
            return false;
        }

        if(!Number(requestBody.teA_NUEVA)) {
            toast.error(appConfigKey.keyTasaNuevaIsNumber , { duration: appConfigKey.keyDurationToast });
            return false;
        }
        
        if(requestBody.comentario == null || requestBody.comentario === "") {
            toast.error(appConfigKey.keyComentarioCambiarTasaEmpty , { duration: appConfigKey.keyDurationToast });
            return false;
        }
        if(props.tea != requestBody.teA_NUEVA){
            const newBodyCambiarTea = {
                ...bodyCambiarTea,
                idenT_SOLICITUD : props.idSolicitud,
                teA_ACTUAL : props.tea,
                teA_NUEVA : requestBody.teA_NUEVA,
                comentario : `${appConfigComentarioCambioTea.C400} ${requestBody.comentario}`,
                idenT_USUARIO_CREADOR: appSecurityConfiguration.SessionUserId,
            };        
            setBodyCambiarTea(newBodyCambiarTea);        
            General.ActualizarHistoricoTea(newBodyCambiarTea);   
            return true;     
        } else {            
            toast.error('La tasa ingresada debe ser diferente a la TEA actual.', { duration: appConfigKey.keyDurationToast });
            return false;
        }
    };      
    
    
    return (        
        <Dialog open={props.open} sx={{
            '& .MuiDialog-paper': {
                borderRadius: '15px',
            }
        }}>
            <Toaster position="top-center" richColors closeButton />
                <div className="change-rate-dialog-content">
                    <button className="btn btn-sm change-rate-close-btn" onClick={props.handleClose}>
                     <CloseIcon />                      
                    </button>                    
                    <div className='change-rate-dialog-title'>CAMBIAR TEA</div>                    
                     <div className="change-rate-info-container">
                        <div className="change-rate-info-row">
                            <span className="change-rate-label-info">TEA Actual:</span>
                            <span className="change-rate-label-info"><strong>{props.tea}</strong></span>
                        </div>
                    </div> 
                    <div className="textAlignRigth">
                        <FormControl>
                            <FormLabel>
                            <span className='change-rate-label'>TEA</span>&nbsp;
                            <span style={{ color: "red" }}>* </span>
                            </FormLabel>
                            <Input type='change-rate-number' placeholder="Ingresar número" name='teA_NUEVA' value={requestBody?.teA_NUEVA} onChange={handleChange} />
                        </FormControl>
                    </div>
                    <div className="textAlignRigth cardObjets">
                            <FormLabel>
                            <span className='change-rate-label'>Comentario</span>&nbsp;
                            <span style={{ color: "red" }}>* </span>
                            </FormLabel>
                            <div className="row g-2 mb-2 align-items-center">
                                <div className="col-lg-12">
                                    <FormControl>
                                    <Textarea
                                        placeholder="Ingresar texto"
                                        minRows={2}
                                        sx={{ mb: 1 }}
                                        name='comentario'
                                        onChange={handleChange}
                                        value={requestBody?.comentario}
                                    />
                                    </FormControl>
                                </div>
                            </div>
                    </div>
                    <div className='change-rate-dialog-btn-save'>
                        <Button
                            color="danger" sx={{
                            borderRadius: 24,
                            width: '100%'                            
                            }}
                            onClick={() => {
                                const rpta = SaveModal(); 
                                if(rpta){   
                                    props.updateTea(requestBody.teA_NUEVA);                                                            
                                }
                            }}
                            >Grabar
                            <span className='arrow-right'>
                                 <img src={ArrowRight} alt='arrow-right' /> 
                            </span>   
                        </Button>                 
                    </div>                   
                </div>
        </Dialog>
    );
}

export default CambiarTeaOpen;