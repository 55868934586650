
import { appDefaultValues } from "../../../../../../config/Config"

export interface DatosDetailsStepsOneCard {
    EstadoCivilId : number,
    Titular: string,
    Conyuge: string,
    CMA: string,
    MenAValor: string,
    MenBValor: string,
    MenAPorcentaje: string,
    MenBPorcentaje: string,
    IngresosNetosTitular: string,
    IngresosNetosConyuge: string,
    cargaFinanciera: string
};


export const DatosDetailsStepsOneCardDefault: DatosDetailsStepsOneCard = {

    EstadoCivilId : appDefaultValues.NumberDefault,
    Titular: appDefaultValues.StringEmpty,
    Conyuge: appDefaultValues.StringEmpty,
    CMA: appDefaultValues.StringEmpty,
    MenAValor: appDefaultValues.StringEmpty,
    MenBValor: appDefaultValues.StringEmpty,
    MenAPorcentaje: appDefaultValues.StringEmpty,
    MenBPorcentaje: appDefaultValues.StringEmpty,
    IngresosNetosTitular: appDefaultValues.StringEmpty,
    IngresosNetosConyuge: appDefaultValues.StringEmpty,
    cargaFinanciera: appDefaultValues.StringEmpty
};
