import { appConfigKey } from "../../../../../config/Config";
import { BlackListItem } from "../../../../models/observed-member.model";

class ListaNegraHelper {

    static TransformarContentToList(content?: any): Array<BlackListItem> | null {
        let blackList: Array<BlackListItem> = [];
        if (!content) return null;

        content?.listaInhabilitados?.sBtListaInhabilitados?.forEach(function (value: any) {
            let item = { tipo: value.organismo, descripcion: value.descripcion } as BlackListItem

            if (value?.organismo === appConfigKey.keyDescripPlaft) {
                item.tipo = value?.organismo;
                blackList.push(item);
            }
            if (value?.organismo === appConfigKey.keyDescripRiesgos) {
                item.tipo = appConfigKey.keyDescripRiesgosMin;
                blackList.push(item);
            }

        });

        return blackList;
    }

}

export default ListaNegraHelper;