import { FormControl, Textarea } from "@mui/joy";
import { useContext, useEffect, useState } from "react";
import { StepOneContext, StepsContext } from "../../../3.10.-base/providers";

const ObservacionesCard = () => {

    const { solicitudData, clickNext,
        step,
        messageCustomerIsBlackListGesintel,
        readonlyForm
    } = useContext(StepsContext);
    const { setNewObservacionData } = useContext(StepOneContext);

    const [viewModel, setViewModel] = useState({
        Observaciones: ""
    });

    useEffect(() => {
        if (solicitudData !== 0) {
            setViewModel({ ...viewModel, Observaciones: solicitudData.descL_OBSERVACION ?? "" });
        }
    }, [solicitudData]);

    useEffect(() => {
        if (clickNext !== 0) {
            setNewObservacionData(viewModel);
        }
    }, [clickNext]);

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 card">
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 textLabelHeadboard">
                    <span className="fw-bold">OBSERVACIONES</span>
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-12">
                    <FormControl>
                        <Textarea
                            disabled={readonlyForm}
                            name="Observaciones"
                            placeholder=""
                            minRows={4}
                            sx={{ mb: 1 }}
                            value={viewModel.Observaciones}
                            onChange={(event: any) => {
                                event.preventDefault();
                                setViewModel({ ...viewModel, Observaciones: event.target.value });
                            }}
                        />
                    </FormControl>
                </div>
            </div>
        </div>
    );
};

export default ObservacionesCard;