import { useEffect } from "react";
import { Accordion, AccordionDetails, AccordionGroup, AccordionSummary, Button } from "@mui/joy";
import HistoricoTeaResquestDialog from './financiamientoHistoricoTea';
import RegimenTablas from "../../1.-shared/1.6.-table/1.6.1.-regimen/RegimenTablas";

export const MatrizFlujoCaja = ({
  flujoCajaTabla,
  onFlujoCajaTablaChange,
  esModoLectura,
}: any) => {

  useEffect(() => {
  }, [])

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>FLUJO DE CAJA</AccordionSummary>
          <AccordionDetails>
            <>
              {flujoCajaTabla?.length > 0 && (
                <RegimenTablas
                  cardClassName="col-lg-12 cardObjets align-top"
                  data={flujoCajaTabla}
                  readOnly={esModoLectura}
                  onChange={(tableIndex: number, filaIndex: number, colIndex: number, event: any) => {
                    onFlujoCajaTablaChange?.();
                  }}
                />
              )}
            </>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
    </div>
  );
};

export default MatrizFlujoCaja;
