import { appDefaultValues } from "../../../../config/Config";
import { BTCrearPersonaJuridicaDomicilios } from "./BTCrearPersonaJuridicaDomicilios";
import { BTCrearPersonaJuridicaOtrosDatos } from "./BTCrearPersonaJuridicaOtrosDatos";

export class BTCrearPersonaJuridicaInformacion {
    private razonSocial: string;
    private poseeOfdeCumplimiento: string;
    private actividadId: number;
    private ventasAnualesId: number;
    private exportador: string;
    private actividad: string;
    private naturalezaJuridicaId: number;
    private tipoDocumentoId: number;
    private paisId: number;
    private importador: string;
    private nombreReducido: string;
    private regimenDebidaDiligencia: string;
    private ventasAnualesEEFF: number;
    private magnitud: string;
    private tamanopersonaJuridica: string;
    private nroDocumento: string;
    private pais: string;
    private tipoActividad: string;
    private magnitudId: number;
    private telefonoCelular: string;
    private otrosDatos: BTCrearPersonaJuridicaOtrosDatos;
    private paisConstitucionId: number;
    private tamanoPersonaJuridicaId: number;
    private nroRegistro: number;
    private naturalezaJuridica: string;
    private telefonoFijo: string;
    private situacionLaboral: string;
    private fechaConstitucion: string;
    private ventasAnuales: string;
    private regimenDebidaDiligenciaId: number;
    private fechaExpiracion: string;
    private presentaBalance: string;
    private capitalSocial: number;
    private correoElectronico: string;
    private paisConstitucion: string;
    private riesgoConcesionarioId: number;
    private riesgoConcesionario: string;
    private fechaBalance: string;
    private tipoActividadId: number;
    private domicilios: BTCrearPersonaJuridicaDomicilios;
    private tipoDocumento: string;

    constructor() {
        this.razonSocial = appDefaultValues.StringEmpty;
        this.poseeOfdeCumplimiento = appDefaultValues.StringEmpty;
        this.actividadId = appDefaultValues.NumberDefault;
        this.ventasAnualesId = appDefaultValues.NumberDefault;
        this.exportador = appDefaultValues.StringEmpty;
        this.actividad = appDefaultValues.StringEmpty;
        this.naturalezaJuridicaId = appDefaultValues.NumberDefault;
        this.tipoDocumentoId = appDefaultValues.NumberDefault;
        this.paisId = appDefaultValues.NumberDefault;
        this.importador = appDefaultValues.StringEmpty;
        this.nombreReducido = appDefaultValues.StringEmpty;
        this.regimenDebidaDiligencia = appDefaultValues.StringEmpty;
        this.ventasAnualesEEFF = appDefaultValues.NumberDefault;
        this.magnitud = appDefaultValues.StringEmpty;
        this.tamanopersonaJuridica = appDefaultValues.StringEmpty;
        this.nroDocumento = appDefaultValues.StringEmpty;
        this.pais = appDefaultValues.StringEmpty;
        this.tipoActividad = appDefaultValues.StringEmpty;
        this.magnitudId = appDefaultValues.NumberDefault;
        this.telefonoCelular = appDefaultValues.StringEmpty;
        this.otrosDatos = new BTCrearPersonaJuridicaOtrosDatos();
        this.paisConstitucionId = appDefaultValues.NumberDefault;
        this.tamanoPersonaJuridicaId = appDefaultValues.NumberDefault;
        this.nroRegistro = appDefaultValues.NumberDefault;
        this.naturalezaJuridica = appDefaultValues.StringEmpty;
        this.telefonoFijo = appDefaultValues.StringEmpty;
        this.situacionLaboral = appDefaultValues.StringEmpty;
        this.fechaConstitucion = appDefaultValues.StringEmpty;
        this.ventasAnuales = appDefaultValues.StringEmpty;
        this.regimenDebidaDiligenciaId = appDefaultValues.NumberDefault;
        this.fechaExpiracion = appDefaultValues.StringEmpty;
        this.presentaBalance = appDefaultValues.StringEmpty;
        this.capitalSocial = appDefaultValues.NumberDefault;
        this.correoElectronico = appDefaultValues.StringEmpty;
        this.paisConstitucion = appDefaultValues.StringEmpty;
        this.riesgoConcesionarioId = appDefaultValues.NumberDefault;
        this.riesgoConcesionario = appDefaultValues.StringEmpty;
        this.fechaBalance = appDefaultValues.StringEmpty;
        this.tipoActividadId = appDefaultValues.NumberDefault;
        this.domicilios = new BTCrearPersonaJuridicaDomicilios();
        this.tipoDocumento = appDefaultValues.StringEmpty;
    }

    public getRazonSocial(): string {
        return this.razonSocial;
    }

    public setRazonSocial(razonSocial: string): void {
        this.razonSocial = razonSocial;
    }

    public getPoseeOfdeCumplimiento(): string {
        return this.poseeOfdeCumplimiento;
    }

    public setPoseeOfdeCumplimiento(poseeOfdeCumplimiento: string): void {
        this.poseeOfdeCumplimiento = poseeOfdeCumplimiento;
    }

    public getActividadId(): number {
        return this.actividadId;
    }

    public setActividadId(actividadId: number): void {
        this.actividadId = actividadId;
    }

    public getVentasAnualesId(): number {
        return this.ventasAnualesId;
    }

    public setVentasAnualesId(ventasAnualesId: number): void {
        this.ventasAnualesId = ventasAnualesId;
    }

    public getExportador(): string {
        return this.exportador;
    }

    public setExportador(exportador: string): void {
        this.exportador = exportador;
    }

    public getActividad(): string {
        return this.actividad;
    }

    public setActividad(actividad: string): void {
        this.actividad = actividad;
    }

    public getNaturalezaJuridicaId(): number {
        return this.naturalezaJuridicaId;
    }

    public setNaturalezaJuridicaId(naturalezaJuridicaId: number): void {
        this.naturalezaJuridicaId = naturalezaJuridicaId;
    }

    public getTipoDocumentoId(): number {
        return this.tipoDocumentoId;
    }

    public setTipoDocumentoId(tipoDocumentoId: number): void {
        this.tipoDocumentoId = tipoDocumentoId;
    }

    public getPaisId(): number {
        return this.paisId;
    }

    public setPaisId(paisId: number): void {
        this.paisId = paisId;
    }

    public getImportador(): string {
        return this.importador;
    }

    public setImportador(importador: string): void {
        this.importador = importador;
    }

    public getNombreReducido(): string {
        return this.nombreReducido;
    }

    public setNombreReducido(nombreReducido: string): void {
        this.nombreReducido = nombreReducido;
    }

    public getRegimenDebidaDiligencia(): string {
        return this.regimenDebidaDiligencia;
    }

    public setRegimenDebidaDiligencia(regimenDebidaDiligencia: string): void {
        this.regimenDebidaDiligencia = regimenDebidaDiligencia;
    }

    public getVentasAnualesEEFF(): number {
        return this.ventasAnualesEEFF;
    }

    public setVentasAnualesEEFF(ventasAnualesEEFF: number): void {
        this.ventasAnualesEEFF = ventasAnualesEEFF;
    }

    public getMagnitud(): string {
        return this.magnitud;
    }

    public setMagnitud(magnitud: string): void {
        this.magnitud = magnitud;
    }

    public getTamanoPersonaJuridica(): string {
        return this.tamanopersonaJuridica;
    }

    public setTamanoPersonaJuridica(tamanopersonaJuridica: string): void {
        this.tamanopersonaJuridica = tamanopersonaJuridica;
    }

    public getNroDocumento(): string {
        return this.nroDocumento;
    }

    public setNroDocumento(nroDocumento: string): void {
        this.nroDocumento = nroDocumento;
    }

    public getPais(): string {
        return this.pais;
    }

    public setPais(pais: string): void {
        this.pais = pais;
    }

    public getTipoActividad(): string {
        return this.tipoActividad;
    }

    public setTipoActividad(tipoActividad: string): void {
        this.tipoActividad = tipoActividad;
    }

    public getMagnitudId(): number {
        return this.magnitudId;
    }

    public setMagnitudId(magnitudId: number): void {
        this.magnitudId = magnitudId;
    }

    public getTelefonoCelular(): string {
        return this.telefonoCelular;
    }

    public setTelefonoCelular(telefonoCelular: string): void {
        this.telefonoCelular = telefonoCelular;
    }

    public getOtrosDatos(): BTCrearPersonaJuridicaOtrosDatos {
        return this.otrosDatos;
    }

    public setOtrosDatos(otrosDatos: BTCrearPersonaJuridicaOtrosDatos): void {
        this.otrosDatos = otrosDatos;
    }

    public getPaisConstitucionId(): number {
        return this.paisConstitucionId;
    }

    public setPaisConstitucionId(paisConstitucionId: number): void {
        this.paisConstitucionId = paisConstitucionId;
    }

    public getTamanoPersonaJuridicaId(): number {
        return this.tamanoPersonaJuridicaId;
    }

    public setTamanoPersonaJuridicaId(tamanoPersonaJuridicaId: number): void {
        this.tamanoPersonaJuridicaId = tamanoPersonaJuridicaId;
    }

    public getNroRegistro(): number {
        return this.nroRegistro;
    }

    public setNroRegistro(nroRegistro: number): void {
        this.nroRegistro = nroRegistro;
    }

    public getNaturalezaJuridica(): string {
        return this.naturalezaJuridica;
    }

    public setNaturalezaJuridica(naturalezaJuridica: string): void {
        this.naturalezaJuridica = naturalezaJuridica;
    }

    public getTelefonoFijo(): string {
        return this.telefonoFijo;
    }

    public setTelefonoFijo(telefonoFijo: string): void {
        this.telefonoFijo = telefonoFijo;
    }

    public getSituacionLaboral(): string {
        return this.situacionLaboral;
    }

    public setSituacionLaboral(situacionLaboral: string): void {
        this.situacionLaboral = situacionLaboral;
    }

    public getFechaConstitucion(): string {
        return this.fechaConstitucion;
    }

    public setFechaConstitucion(fechaConstitucion: string): void {
        this.fechaConstitucion = fechaConstitucion;
    }

    public getVentasAnuales(): string {
        return this.ventasAnuales;
    }

    public setVentasAnuales(ventasAnuales: string): void {
        this.ventasAnuales = ventasAnuales;
    }

    public getRegimenDebidaDiligenciaId(): number {
        return this.regimenDebidaDiligenciaId;
    }

    public setRegimenDebidaDiligenciaId(regimenDebidaDiligenciaId: number): void {
        this.regimenDebidaDiligenciaId = regimenDebidaDiligenciaId;
    }

    public getFechaExpiracion(): string {
        return this.fechaExpiracion;
    }

    public setFechaExpiracion(fechaExpiracion: string): void {
        this.fechaExpiracion = fechaExpiracion;
    }

    public getPresentaBalance(): string {
        return this.presentaBalance;
    }

    public setPresentaBalance(presentaBalance: string): void {
        this.presentaBalance = presentaBalance;
    }

    public getCapitalSocial(): number {
        return this.capitalSocial;
    }

    public setCapitalSocial(capitalSocial: number): void {
        this.capitalSocial = capitalSocial;
    }

    public getCorreoElectronico(): string {
        return this.correoElectronico;
    }

    public setCorreoElectronico(correoElectronico: string): void {
        this.correoElectronico = correoElectronico;
    }

    public getPaisConstitucion(): string {
        return this.paisConstitucion;
    }

    public setPaisConstitucion(paisConstitucion: string): void {
        this.paisConstitucion = paisConstitucion;
    }

    public getRiesgoConcesionarioId(): number {
        return this.riesgoConcesionarioId;
    }

    public setRiesgoConcesionarioId(riesgoConcesionarioId: number): void {
        this.riesgoConcesionarioId = riesgoConcesionarioId;
    }

    public getRiesgoConcesionario(): string {
        return this.riesgoConcesionario;
    }

    public setRiesgoConcesionario(riesgoConcesionario: string): void {
        this.riesgoConcesionario = riesgoConcesionario;
    }

    public getFechaBalance(): string {
        return this.fechaBalance;
    }

    public setFechaBalance(fechaBalance: string): void {
        this.fechaBalance = fechaBalance;
    }

    public getTipoActividadId(): number {
        return this.tipoActividadId;
    }

    public setTipoActividadId(tipoActividadId: number): void {
        this.tipoActividadId = tipoActividadId;
    }

    public getDomicilios(): BTCrearPersonaJuridicaDomicilios {
        return this.domicilios;
    }

    public setDomicilios(domicilios: BTCrearPersonaJuridicaDomicilios): void {
        this.domicilios = domicilios;
    }

    public getTipoDocumento(): string {
        return this.tipoDocumento;
    }

    public setTipoDocumento(tipoDocumento: string): void {
        this.tipoDocumento = tipoDocumento;
    }
}