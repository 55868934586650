import { IGaugeProps } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgauge";
import { Component } from "react";
import './card-details-steps3.scss'
import GeneralHelper from "../../../helpers/GeneralHelper";
import { AgregarDivisa } from "../../../helpers/DivisaHelper";
import { appConfigKey } from "../../../../config/Config";

interface ICardDetailsStep3Props {
  objCardDetailsStep3Props: {
    product: string,
    vehicleInsurance: string,
    score: string,
    vehInsuranceCost: string,
    TEA: string,
    TCEA: string,
    totalFinancing: string,
    divisa: number,
    term: string,
    PEPValidation: string,
    doubleOdds: string,
    deductionInsurance: string,
    idTypeDocument: number,
    idCategoria: number,
  }
}

class CardDetailsStep3 extends Component<ICardDetailsStep3Props, any> {

  constructor(props: any) {
    super(props);
  }

  getFormatoTotalFinanciamiento = () => {
    const capitalSimulacion: Number = Number(this.props.objCardDetailsStep3Props.totalFinancing);
    const capitalSimulacionString: string = GeneralHelper.AgregarComasAMiles(capitalSimulacion.toFixed(2));
    const totalFinanciamiento: string = AgregarDivisa(capitalSimulacionString, Number(this.props.objCardDetailsStep3Props.divisa));
    return totalFinanciamiento;
  };

  render() {
    const isTitularJuridico = this.props.objCardDetailsStep3Props.idTypeDocument == appConfigKey.keyIdRUC ||
      this.props.objCardDetailsStep3Props.idCategoria == appConfigKey.keyCodigoCategoriaLaboralTres;
    return (

      <div className="border rounded-10 p-3 p-md-2 mb-4 mb-md-4 card body-details">
        <div className="row g-2 mb-2 align-items-center item-body-details">


          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Producto</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.props.objCardDetailsStep3Props.product}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Seguro Vehicular</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.props.objCardDetailsStep3Props.vehicleInsurance}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Score</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{!isTitularJuridico ? this.props.objCardDetailsStep3Props.score : '-'}</span>
                </div>
              </div>
            </div>
          </div>


          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Tasa seguro veh.</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{`${this.props.objCardDetailsStep3Props.vehInsuranceCost} %`}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>TEA</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{`${this.props.objCardDetailsStep3Props.TEA} %`}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>TCEA</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.props.objCardDetailsStep3Props.TCEA}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Total financiamiento</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.getFormatoTotalFinanciamiento()}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Plazo</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.props.objCardDetailsStep3Props.term}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Cuotas dobles</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.props.objCardDetailsStep3Props.doubleOdds}</span>
                </div>
              </div>
            </div>
            {/*<div className="row g-2 mb-2">
                    <div className="col-lg-6 col-md-6">
                      <span>Validación PEP</span>
                    </div>
                    <div className="col-lg-6 col-md-6">
                      <span className="text-negrita">{this.props.objCardDetailsStep3Props.PEPValidation}</span>
                    </div>
                    </div>*/}
          </div>

          <div className="col-lg-3 col-md-12">
            {!isTitularJuridico &&
              (
                <div className="row g-2 mb-2">
                  <div className="col-lg-6 col-md-6">
                    <span>Cobertura Desg.</span>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="text-long">
                      <span className="text-negrita">{this.props.objCardDetailsStep3Props.deductionInsurance}</span>
                    </div>
                  </div>
                </div>
              )
            }
          </div>
        </div>
      </div>
    )
  }
}
export default CardDetailsStep3;