import { evaluate } from "mathjs";
import { TableException } from "../../../../../models/Shared/table-exception";
import { MnFormatoRegimenDto } from "../../../../../models/RequestManager/solicitud-formato-regimen-response.model";
import GeneralHelper from "../../../../../helpers/GeneralHelper";
import TimeHelper from "../../../../../helpers/TimeHelper";

export const ConvertidorTablaHelper = {
    /**
     * Obtiene el valor de una celda en una matriz de datos dada su fila y columna.
     * 
     * @param rows La matriz de datos que representa las filas y columnas de la tabla.
     * @param rowIndex El índice de la fila de la celda deseada.
     * @param colIndex El índice de la columna de la celda deseada.
     * @returns El valor de la celda en la posición especificada por los índices de fila y columna.
     */
    ObtenerValorCelda: (rows: any[], rowIndex: number, colIndex: number) => {
        const cell = rows[rowIndex].fila[colIndex];
        // console.log("Filas:", rows, "col:", colIndex, "row:", rowIndex);
        // console.log("Celda:", cell);
        return (cell.label ? cell.label : 0);
    },

    /**
     * Convierte el nombre de una columna en su índice correspondiente en base cero.
     * 
     * Esta función utiliza el código ASCII para calcular el valor de los caracteres.
     * Se puede encontrar una tabla ASCII en el siguiente enlace: https://elcodigoascii.com.ar/
     * 
     * Ejemplos:
     * - "A" se convierte en 0.
     * - "Z" se convierte en 25.
     * - "AA" se convierte en 26.
     * - "AZ" se convierte en 51.
     * 
     * @param column El nombre de la columna que se va a convertir.
     * @returns El índice de la columna.
     */
    ObtenerIndiceColumna(column: string): number {
        let index = 0;
        for (let i = 0; i < column.length; i++) {
            index = index * 26 + (column.charCodeAt(i) - 'A'.charCodeAt(0)) + 1;
        }
        return index - 1;
    },

    /**
     * Reemplaza las variables de la fórmula con los valores de otras filas y columnas.
     * 
     * Por ejemplo, para la fórmula "B1 / A1 * 100":
     * - "B1" se reemplaza con el valor en la columna 2 (índice 1) y la fila 1 (índice 0).
     * - "A1" se reemplaza con el valor en la columna 1 (índice 0) y la fila 1 (índice 0).
     * - "100" se mantiene como un valor constante porque no cumple con la expresión regular.
     * 
     * El resultado de la fórmula sería "50 / 100 * 100", asumiendo que los valores de las celdas son 50 y 100 respectivamente.
     * 
     * @param formula La fórmula que contiene las referencias de celda.
     * @param rows La matriz de filas que contiene los valores de las celdas.
     * @returns La fórmula con las referencias de celda reemplazadas por sus valores correspondientes.
     */
    RemplazarReferenciasCeldas: (formula: string, rows: any[]) => {
        try {
            // Obtener índice de filas y columnas
            return formula.replace(/[A-Z]+\d+/g, (match: string) => {
                // Obtiene la primera palabra en mayúscula
                const columnPart = match.match(/[A-Z]+/)?.[0] ?? "";
                // Obtiene el primer número
                const rowPart = match.match(/[0-9]+/)?.[0] ?? "";

                // Convierte columnas con nombre a índice
                const colIndex = ConvertidorTablaHelper.ObtenerIndiceColumna(columnPart);
                // Obtiene el índice de fila
                const rowIndex = parseInt(rowPart, 10) - 1;

                // console.log(match, "col:", colIndex, "row:", rowIndex);

                return ConvertidorTablaHelper.ObtenerValorCelda(rows, rowIndex, colIndex);
            });
        } catch (error) {
            throw new TableException(`#¿REF?`);
        }
    },

    /**
     * Remplaza las variables de la formula con valores de variables en un diccionario
     * Ejemplo: "50 / 100 * variableX". 
     *  "50" no será remplazado porque no cumple la expresión regular
     *  "100" no será remplazado porque no cumple la expresión regular
     *  "variableX" será remplazado por el valor encontrado en parameters
     * @param formula 
     * @param parameters 
     * @returns 
     */
    RemplazarReferenciasParametros: (formula: string, parameters: any[]) => {
        try {
            // Reemplazar variables de parámetros en la fórmula
            return formula.replace(/\b[a-zA-Z]+\b/g, (match) => {
                const param = parameters.find(param => param.key === match);
                if (param) {
                    return param.value;
                }
                throw new TableException(`#¿VALOR?`);
            });
        } catch (error) {
            throw new TableException(`#¿VALOR?`);
        }
    },

    /**
     * Remplaza las variables de la formula con valores de variables en un diccionario
     * Ejemplo: "50 + EstadoPerdidasGanancias.B11". 
     *  "50" no será remplazado porque no cumple la expresión regular
     *  "EstadoPerdidasGanancias.B11" será remplazado por el valor del tableIndex 0 (u otro), colIndex 1 y rowIndex 10
     * @param formula 
     * @param parameters 
     * @returns 
     */
    RemplazarReferenciasTablas: (formula: string, tablas: any[]) => {
        try {
            // Reemplazar variables de parámetros en la fórmula
            return formula.replace(/([A-Za-z]+)\.([A-Z]+)(\d+)/g, (match, tableName, column, row) => {
                const tablaFound = tablas.find((t: any) => t.tablaName === tableName);
                if (!tablaFound) {
                    throw new TableException(`#¿TBREF?`);
                }

                // Convierte columnas con nombre a índice
                const colIndex = ConvertidorTablaHelper.ObtenerIndiceColumna(column);
                // Obtiene el índice de fila
                const rowIndex = parseInt(row, 10) - 1;

                // console.log(match, "col:", colIndex, "row:", rowIndex);

                return ConvertidorTablaHelper.ObtenerValorCelda(tablaFound.filas, rowIndex, colIndex);
            });
        } catch (error) {
            throw new TableException(`#¿TBREF?`);
        }
    },

    /**
     * Remplaza las variables de la formula con valores de fecha
     * Ejemplo: "2024-07-22 <= today()". 
     *  "2024-07-22" no será remplazado porque no cumple la expresión regular
     *  "today()" será remplazado por la fecha actual
     * @param formula 
     * @returns 
     */
    RemplazarReferenciasDate: (formula: string) => {
        try {
            // Reemplazar variables de parámetros en la fórmula
            return formula.replace(/today\(\)/g, (match) => {
                const date = TimeHelper.ObtenerFormatoFechaAmericana(GeneralHelper.ObtenerFechaActual());
                return date ?? '';
            });
        } catch (error) {
            throw new TableException(`#FECHA?`);
        }
    },

    /**
     * Evalúa una fórmula matemática
     * @param formula 
     * @param rows
     * @param parameters 
     * @returns 
     */
    EvaluarFormula: (formula: string) => {
        try {
            return evaluate(formula);
        } catch (error) {
            throw new TableException(`#¿NOMBRE?`);
        }
    },

    /**
     * Remplaza y ejecuta la operacion de cada celda
     * @param data 
     * @returns 
     */
    ObtenerValores: (data: any[], refresh: boolean = true) => {
        let updatedData = data?.map((item: any) => {
            item.tablas?.forEach((tabla: any) => {
                let parameters: any[] = [];
                parameters.push(...(tabla.parametros ?? []), ...(item.parametrosGlobales ?? []));
                // console.log("Parametros:", parameters);
                tabla.columnas?.forEach((columna: any, colIndex: number) => {
                    if (columna.tipo === 'operation') {
                        try {
                            columna.error = undefined;
                            let formula = columna.operacion;
                            formula = ConvertidorTablaHelper.RemplazarReferenciasParametros(formula, parameters);
                            columna.label = formula;
                        } catch (error) {
                            if (error instanceof TableException) {
                                columna.error = error.message;
                            } else {
                                columna.error = "#N/A";
                            }
                        }
                    }
                });
                tabla.filas?.forEach((obj: any, rowIndex: number) => {
                    obj.fila?.forEach((columna: any, colIndex: number) => {
                        if (columna.tipo === 'operation') {
                            try {
                                // console.log("Columna:", columna);
                                columna.error = undefined;
                                let formula = columna.operacion;

                                formula = ConvertidorTablaHelper.RemplazarReferenciasTablas(formula, item.tablas);
                                formula = ConvertidorTablaHelper.RemplazarReferenciasCeldas(formula, tabla.filas);
                                formula = ConvertidorTablaHelper.RemplazarReferenciasParametros(formula, parameters);

                                // console.log("Evaluar:", formula);
                                const result = ConvertidorTablaHelper.EvaluarFormula(formula);
                                columna.label = result ? result.toFixed(2) : null;
                                // console.log("Resultado:", columna);
                            } catch (error) {
                                if (error instanceof TableException) {
                                    columna.error = error.message;
                                } else {
                                    columna.error = "#N/A";
                                }
                            }
                        }
                    });
                });
            });
            return item;
        });
        if (refresh) {
            /**
             * Solución para filas que dependen de filas superiores, 
             * por ejemplo la fila 1 necesita la suma de la fila 2 y 3
             */
            updatedData = ConvertidorTablaHelper.ObtenerValores(updatedData, false);
        }
        return updatedData ?? [];
    },

    ObtenerValoresPorPerfil: (formatoRegimen: MnFormatoRegimenDto) => {
        const tableData = JSON.parse(formatoRegimen.jsonTable);
        const matrizGeneral: any = ConvertidorTablaHelper.ObtenerValores(tableData);

        const tablas = matrizGeneral.map((x: any) => { //Obtener solo tablas del perfil
            return x.tablas.filter((x: any) => x.visible)
        })[0];

        let matrizPerfil = JSON.parse(JSON.stringify(matrizGeneral));
        matrizPerfil.map((x: any) => {
            x.tablas = tablas;
        });

        return {
            identFormatoRegistro: formatoRegimen.identFormatoRegistro,
            identSolicitud: formatoRegimen.identSolicitud,
            identFormato: formatoRegimen.identFormato,
            matrizGeneral: matrizGeneral,
            matrizPerfil: matrizPerfil,
        }
    },

    ObtenerValoresPorMatriz: (matrizRegimen: any) => {
        const matrizGeneral = matrizRegimen?.matrizGeneral;
        const newMatrizGeneral: any = ConvertidorTablaHelper.ObtenerValores(matrizGeneral);

        const tablas = newMatrizGeneral.map((x: any) => { //Obtener solo tablas del ejecutivo
            return x.tablas.filter((x: any) => x.visible)
        })[0];

        let newMatrizPerfil = JSON.parse(JSON.stringify(newMatrizGeneral));
        newMatrizPerfil.map((x: any) => {
            x.tablas = tablas;
        });

        matrizRegimen.matrizGeneral = newMatrizGeneral;
        matrizRegimen.matrizPerfil = newMatrizPerfil;

        return matrizRegimen;
    },

    ObtenerMatrizTablaPorNombre: (matriz: any[], tablaName: string) => {
        try {
            const tablas = matriz.map((x: any) => {
                return x.tablas.filter((x: any) => x.tablaName === tablaName)
            })[0];

            let matrizTabla = JSON.parse(JSON.stringify(matriz));
            matrizTabla.map((x: any) => {
                x.tablas = tablas;
            });

            return matrizTabla;
        } catch (error) {

        }
        return null;
    },

    ModificarParametroTabla: (tablaMatriz: any[], paramName: any, paramValue: any) => {
        if (tablaMatriz?.[0]?.tablas?.[0]) {
            const value = paramValue ? paramValue.toString() : '0';
            const tablas = tablaMatriz[0].tablas[0];

            if (!tablas.parametros) {
                tablas.parametros = [];
            }

            const parametros = tablas.parametros;
            const parametro = parametros.find((p: any) => p.key === paramName);

            if (parametro) {
                parametro.value = value;
            } else {
                parametros.push({ key: paramName, value: value });
            }
        }
    },

    ObtenerRowTablaPorLabel: (tablaMatriz: any[], label: string): any[] | null => {
        try {
            for (let i = 0; i < tablaMatriz.length; i++) {
                let obj = tablaMatriz[i];
                for (let j = 0; j < obj.tablas.length; j++) {
                    let tabla = obj.tablas[j];
                    for (let k = 0; k < tabla.filas.length; k++) {
                        let row = tabla.filas[k];
                        for (let l = 0; l < row.fila.length; l++) {
                            let fila = row.fila[l];
                            if (fila.label == label) {
                                return row.fila;
                            }
                        }
                    }
                }
            }
        } catch (error) {
        }
        return null;
    },

    ObtenerValorLabelPorPosicion: (tablaMatriz: any[], label: string, index: number) => {
        let row = ConvertidorTablaHelper.ObtenerRowTablaPorLabel(tablaMatriz, label);
        if (!row) return null;

        if (row.length > index) {
            return row[index].label;
        }

        return null;
    },

    ObtenerValorLabelPorTablaPosicion: (matriz: any[], tablaName: string, label: string, index: number) => {
        let matrizTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(matriz, tablaName);
        if (!matrizTabla) return null;

        let row = ConvertidorTablaHelper.ObtenerRowTablaPorLabel(matrizTabla, label);
        if (!row) return null;

        if (row.length > index) {
            return row[index].label;
        }

        return null;
    }
}
