import './financiamientoHistoricoTea.scss';
import { Dialog } from "@mui/material";
import { Table } from "@mui/joy";

const HistoricoTeaRequestDialog = (props: any) => {
    return (
        <Dialog open={props.open} maxWidth={'lg'} >
            <div className='request-dialong-content'>
                <div className='request-dialog-title'>HISTORIAL TEA</div>
                <div className='request-dialog-list-content'>
                    <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-8 cardObjets align-top">
                        <Table borderAxis="none" className="style-table align-top">
                        <thead>
                        <tr>
                        <th style={{ fontSize: "12px", width: "170px", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>FECHA ACTUALIZACIÓN</th>
                        <th style={{ fontSize: "12px", width: "120px", backgroundColor: "#E1E1E1", }}>TEA ANTERIOR</th>
                        <th style={{ fontSize: "12px", width: "120px", backgroundColor: "#E1E1E1", }}>TEA NUEVO</th>
                        <th style={{ fontSize: "12px", width: "100px", backgroundColor: "#E1E1E1", }}>USUARIO</th>
                        <th style={{ fontSize: "12px", width: "350px", backgroundColor: "#E1E1E1", }}>COMENTARIO</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                        props.historicoTea.map((row: any, index: number) => {
                            return (
                            <tr key={index}>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.fecha}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.teA_ACTUAL}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.teA_NUEVA}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.username}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.comentario}</td>
                            </tr>
                            )
                        })
                        }
                        </tbody>
                        </Table>
                    </div>
                    </div>
                </div>
                <div className='request-dialog-btn-close cursor-pointer' onClick={props.handleClose}>Cerrar</div>
            </div>
        </Dialog>
    );
};

export default HistoricoTeaRequestDialog;