import IcoDownloadWhite from '../../../../../assets/media/icons/ico_download_white.svg';

const DocumentationDownload = (props: any) => {
    return (
        <div className='documentation-btn-send' onClick={(event:any)=>{props.handlerOnClick(event);}}>
            {props.text} <img src={IcoDownloadWhite} alt='icon-download' />
        </div>
    );
};

export default DocumentationDownload;