import { appDefaultValues } from '../../../config/Config';

export interface Users {
 idPersona: number,
 tipoPersona: number,
 nombre: string
 nroDocumento: string,
 tipoDocumento: string
 tipoIntegrante: string,
 idEstadoCivil: number
}

export interface Members {
 users : Users[]
}


export const MembersDefault: Members = {
 users : []
}