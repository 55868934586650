import { toast } from "sonner";
import { appConfigKey } from "../../config/Config";
import GeneralHelper from "./GeneralHelper";

export const validarText = (parameter: string, nameParameter: string) => {
    let response = GeneralHelper.ValidarTextRequired(parameter, nameParameter);

    if (!response[0]) {
        toast.error(response[1], { duration: appConfigKey.keyDurationToast });
        return response[0];
    }

    return true;
};

export const validarTextRuc = (parameter: string, nameParameter: string, categoriaLaboral: number) => {

    if(categoriaLaboral !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado){
        let response = GeneralHelper.ValidarTextRequired(parameter, nameParameter);
        if (!response[0]) {
            toast.error(response[1], { duration: appConfigKey.keyDurationToast });
            return response[0];
        }
    }

    return true;
};

export const validarMontoAnualizado = (parameter: number) => {
    let response = "El monto anualizado debe ser mayor a 2000"

    if(parameter < 2000){
        toast.error(response, { duration: appConfigKey.keyDurationToast });
        return false;
    }

    return true;
};

export const validarFechaPosterior = (parameter: string, nameParameter: string) => {
    let fechaString = GeneralHelper.ParseDateString('YYYY-MM-DD', parameter);
    let fechaLaboral = new Date(fechaString);
    let fechaActual = new Date();

    if (fechaLaboral > fechaActual) {
        toast.error(appConfigKey.keyMsjCampoOblFechaLabPosterior.replace('{dato}', nameParameter), { duration: appConfigKey.keyDurationToast });
        return false;
    }
    return true;
};


export const validarFecha = (parameter: string, nameParameter: string) => {
    let response = GeneralHelper.ValidarFechaRequired(parameter, nameParameter);

    if (!response[0]) {
        toast.error(response[1], { duration: appConfigKey.keyDurationToast });
        return response[0];
    }

    return true;
};

export const validarEdadMinima = (parameter: string, nameParameter: string) => {
    let response = GeneralHelper.ValidarEdadMinima(parameter, nameParameter, 18);

    if (!response[0]) {
        toast.error(response[1], { duration: appConfigKey.keyDurationToast });
        return response[0];
    }

    return true;
};

export const validarSelect = (parameter: any, nameParameter: string) => {
    let response = GeneralHelper.ValidarSelectRequired(parameter, nameParameter);

    if (!response[0]) {
        toast.error(response[1], { duration: appConfigKey.keyDurationToast });
        return response[0];
    }

    return true;
};

export const validarCorreo = (parameter: any, nameParameter: string) => {
    let response = GeneralHelper.ValidarCorreo(parameter, nameParameter);
    if (!response[0]) {
        toast.error(response[1], { duration: appConfigKey.keyDurationToast });
        return response[0];
    }

    return true;
};

export const validarMatriz = (parameter: string, nameParameter: string) => {
    let response = GeneralHelper.ValidarMatrizRequired(parameter, nameParameter);

    if (!response[0]) {
        toast.error(response[1], { duration: appConfigKey.keyDurationToast });
        return response[0];
    }

    return true;
};
