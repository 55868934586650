import { appConfigKey } from "../../../../../config/Config";

const ValidacionAsignCatDialogHelper = {
    NewDataListaAsignacion: () => {

        let lista: any[] = [
            {
                "identUsuarioAsignacion": 0,
                "idCategoriaLaboral": appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado,
                "descripcionCategoriaLaboral": appConfigKey.KeyCategoriaLaboralCincoJubilado,
                "selectCategory": false,
                "selectExcepcion": false
            },
            {
                "identUsuarioAsignacion": 0,
                "idCategoriaLaboral": appConfigKey.keyCodigoCategoriaLaboralCinco,
                "descripcionCategoriaLaboral": appConfigKey.keyCategoriaLaboralCinco,
                "selectCategory": false,
                "selectExcepcion": false
            },
            {
                "identUsuarioAsignacion": 0,
                "idCategoriaLaboral": appConfigKey.keyCodigoCategoriaLaboralCuarta,
                "descripcionCategoriaLaboral": appConfigKey.keyCategoriaLaboralCuarta,
                "selectCategory": false,
                "selectExcepcion": false
            },
            {
                "identUsuarioAsignacion": 0,
                "idCategoriaLaboral": appConfigKey.keyCodigoCategoriaLaboralTres,
                "descripcionCategoriaLaboral": appConfigKey.keyCategoriaLaboralTres,
                "selectCategory": false,
                "selectExcepcion": false
            },
            {
                "identUsuarioAsignacion": 0,
                "idCategoriaLaboral": appConfigKey.keyCodigoCategoriaLaboralUno,
                "descripcionCategoriaLaboral": appConfigKey.keyCategoriaLaboralUno,
                "selectCategory": false,
                "selectExcepcion": false
            }
        ];

        let data: any = {
            "ListaAsignacionPN": lista?.filter((x: any) => x.idCategoriaLaboral !== appConfigKey.keyId3raCategoríaPerRUC),
            "ListaAsignacionPJ": lista?.filter((x: any) => x.idCategoriaLaboral === appConfigKey.keyId3raCategoríaPerRUC)
        }

        return data;
    }
}
export default ValidacionAsignCatDialogHelper;