import TrakingList from '../TrakingList';
import './styles.scss';
import { Dialog } from "@mui/material";

const TrakingRequestDialog = (props: any) => {
    return (
        <Dialog open={props.open} maxWidth={'sm'}>
            <div className='traking-request-dialong-content'>
                <div className='traking-request-dialog-title'>TRACKING DE SOLICITUD</div>
                <div className='traking-request-dialog-list-content'>
                    <TrakingList IdSolicitud={props.IdSolicitud}/>
                </div>
                <div className='traking-request-dialog-btn-close cursor-pointer' onClick={props.handleClose}>Cerrar</div>
            </div>
        </Dialog>
    );
};

export default TrakingRequestDialog;