import { Button, IconButton, Link, Table } from "@mui/joy";
import ServicesProvider from "../../../services/support/wapiservicesprovider";
import { useParams } from "react-router-dom";
import IconDownload from '../../../../assets/media/icons/ico_download.svg'
import React from "react";
import MasterManager from "../../../services/origination/wapimaster.service";
import { appConfigKey, appSecurityConfiguration } from "../../../../config/Config";
import IconAproved from '../../../../assets/media/icons/ico-aproved.svg';
import icoDenided from '../../../../assets/media/icons/ico-denided.svg';
import DownloadHelper from "../../../helpers/DownloadHelper";

export const ResultadosEvaluacion = (props: any) => {
 const { resultadosEvaluacion } = props;
 const { solicitudId } = useParams();
 const [solicitud, setSolicitud] = React.useState<any>();
 var SessionPerfil = appSecurityConfiguration.SessionPerfil;

 function renderStatus(status: number) {
  let position = 0;
  const listStatus = [appConfigKey.keyDescripEstadoDocumentoPorAdjuntar,
  (<> {appConfigKey.keyDescripEstadoDocumentoAdjuntado} <img src={IconAproved} /></>),
  (<> {appConfigKey.keyDescripEstadoDocumentoConforme} <img src={IconAproved} /></>),
  (<> {appConfigKey.keyDescripEstadoDocumentoObservado} <img src={icoDenided} /></>)];

  switch (status) {
      case appConfigKey.keyIdEstadoDocumentoPorAdjuntar:
          position = 0;
          break;
      case appConfigKey.keyIdEstadoDocumentoAdjuntado:
          position = 1;
          break;
      case appConfigKey.keyIdEstadoDocumentoConforme:
          position = 2;
          break;
      case appConfigKey.keyIdEstadoDocumentoObservado:
          position = 3;
          break;
  }

  return listStatus[position];
}
function addStyleStatus(status: number) {
  let style = '';
  switch (status) {
      case appConfigKey.keyIdEstadoDocumentoAdjuntado:
          style = 'color-aproved';
          break;
      case appConfigKey.keyIdEstadoDocumentoConforme:
          style = 'color-aproved';
          break;
      case appConfigKey.keyIdEstadoDocumentoObservado:
          style = 'color-denided';
          break;
      default: style = '';
          break;
  }
  return style;
}
 

  const handleDownloadFile = async (event: any, data: any) => {
    event.preventDefault();
    const response: any = await descargarDocumentoAdjunto(data);

    if (!response) {
        //myowasp('Error al descargar el documento.');
        return;
    }

    const fileData = response?.data.content.sdtDocumentosDigitales.documento[0];
    const file = DownloadHelper.convertBase64ToFile(fileData.archivo, fileData.nombre);
    const a = document.createElement("a");
    const url = window.URL.createObjectURL(file);
    a.href = url;
    a.download = fileData.nombre;
    a.target = "_blank";
    a.click();
    window.URL.revokeObjectURL(url);
  };

  const descargarDocumentoAdjunto = async (documentoId: number) => {
    const responseObtenerDocumentoDigital = await ServicesProvider.ObtenerDocumentoDigital({
        "documentoId": documentoId
    }, Number(solicitud));

    if (responseObtenerDocumentoDigital.status !== 200) {
        //myowasp('Ocurrió un error al asociar el documento');
        return;
    }

    if (!responseObtenerDocumentoDigital.data.isValid) {
        //myowasp('Ocurrió un error en la respuesta del servicio externo.');
        return;
    }

    return responseObtenerDocumentoDigital;
};

const obtenerSolicitud = () => {

  MasterManager.GetMnSolicitud({ idenT_SOLICITUD: solicitudId }).then((response: any) => {
      const tempSolicitud = response.data.content[0];
      setSolicitud(tempSolicitud.idenT_OPERACION);
  });
};

React.useEffect(() => {
  obtenerSolicitud();
}, []);

 return (
  <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
    <div className="row g-2 mb-2 align-items-center">
      <div className="col-lg-4 textLabelHeadboard">
        <span className="fw-bold">DOCUMENTOS</span>
      </div>
      <div className="col-lg-4">
        <span className="fw-bold"> </span>
      </div>
      <div className="col-lg-4 textAlignRigth"></div>
    </div>

    <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-12 tableContainer-resultado">
        <Table borderAxis="none" className="style-table align-top" stripe={'odd'}>
         <thead>
          <tr>
           <th style={{ width: "350px", backgroundColor: "#E1E1E1", }}>DOCUMENTO</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>ESTADO</th>
           <th style={{ width: "350px", backgroundColor: "#E1E1E1", }}>ARCHIVO</th>
           <th style={{ width: "100px", backgroundColor: "#E1E1E1", }}>ACCIÓN</th>
          </tr>
         </thead>
         <tbody>
          {
           resultadosEvaluacion.map((row: any) => {
            return (
             <tr key={row.idenT_DOCUMENTO_BT}>
              <td>{row.descC_TIPO_DOCUMENTO}</td>
              <td><div className={addStyleStatus(row.idenT_ESTADO_ADJUNTO)}>{renderStatus(row.idenT_ESTADO_ADJUNTO)}</div></td>
              <td style={{ overflow: 'hidden', textOverflow: 'ellipsis'}}>{row.descC_NOMBRE_ARCHIVO}</td>
              {/* <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}><IconButton href="https://www.google.com.pe/" target="_blank"></DownloadIcon></IconButton></td> */}
              <td style={{ textAlign: "left"}} >
            <img style={{ paddingLeft: '5px', width: '23px', cursor: "pointer" }} src={IconDownload} onClick={(e) => handleDownloadFile(e, row.idenT_DOCUMENTO_BT)} />
            </td>
            </tr>
            )
           })
          }
         </tbody>
        </Table>
       </div>
      </div>
  </div>
);
};

export default ResultadosEvaluacion;