import { appDefaultValues } from "../../../../../config/Config";
import { validarSelect, validarText } from "../../../../helpers/ValidarHelper";

const ValidacionHelper = {

    //{ resultadoId: 0, sustento: "", comentario: "" }
    NewSaveResult: (data: any) => {

        if (!validarSelect(data?.resultadoId, 'Resultado')) {
            return false;
        }

        if (!validarText(data?.sustento, 'Sustento')) {
            return false;
        }

        return true;
    }

};

export default ValidacionHelper;