import IcoNoRed from '../../../../../assets/media/icons/ico_no_red.svg';
import ValidationPlacaCheck from '../../../../../assets/media/icons/validation_placa_check.svg';

const SolcitudeResultInfo = (props: any) => {
    return (
        <div style={{ display: 'flex', gap: ' 20px', alignItems: 'start' }}>
            {props.status === 'no-validate' && <img src={IcoNoRed} alt="ico-no-red" />}
            {props.status === 'validate' && <img src={ValidationPlacaCheck} alt="ico-validate" />}
            <div className='solicitude-response-detail'>
                <div><strong>{props.title}</strong></div>
                <div>{props.time}</div>
                <div>{props.detail}</div>
            </div>
        </div>
    );
};

export default SolcitudeResultInfo;