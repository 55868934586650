import { useContext, useEffect, useState } from "react";
import { StepsContext } from "../3.10.-base/providers";
import { appConfigKey } from "../../../../config/Config";
import DatosAdicionalesConyugeCard from "./components/DatosAdicionalesConyugeCard";
import DatosAdicionalesTitularCard from "./components/DatosAdicionalesTitularCard";
import DatosLaboralesAdicionalesConyugeCard from "./components/DatosLaboralesAdicionalesConyugeCard";
import DatosLaboralesAdicionalesTitularCard from "./components/DatosLaboralesAdicionalesTitularCard";
import DomicilioTitularCard from "./components/DomicilioTitularCard";
import DatosAdicionalesTitularEmpresaCard from "./components/DatosAdicionalesTitularEmpresaCard";
import DatosTitularEmpresaCard from "./components/DatosTitularEmpresaCard";
import InformeComercialModal from "../3.10.-base/components/InformeComercialModal";

const SolicitudeStepFive = () => {

  const {
    viewModelStepOneFinal,
    isTitularJuridico,
    solicitudId,
    openInformeComercial,
    setOpenInformeComercial,
  } = useContext(StepsContext);

  const [viewModel, setViewModel] = useState<any>({
    esVisibleConyugeCards: false,
    esVisibleConyugeDatosLabAdiCards: false,
    esVisibleRucCards: false,
  });

  const handleCloseInformeComercialModal = () => {
    setOpenInformeComercial(false);
  };

  useEffect(() => {
    if (!viewModelStepOneFinal) return;

    const {
      newTitularData: { EstadoCivilId, TipoDocumentoId },
      newIngresosConyugeData: { identCategoriaLaboral },
    } = viewModelStepOneFinal;

    const esCasadoOConviviente =
      EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ||
      EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente;

    const esVisibleConyugeDatosLab = esCasadoOConviviente &&
      identCategoriaLaboral !== appConfigKey.keyIdSinCategoria;

    setViewModel((prevViewModel: any) => ({
      ...prevViewModel,
      esVisibleConyugeCards: esCasadoOConviviente,
      esVisibleConyugeDatosLabAdiCards: esVisibleConyugeDatosLab,
      esVisibleRucCards: TipoDocumentoId === appConfigKey.keyIdRUC,
    }));
  }, [viewModelStepOneFinal]);

  return (
    <div>
      {viewModel.esVisibleRucCards && <DatosTitularEmpresaCard />}
      {viewModel.esVisibleRucCards && <DatosAdicionalesTitularEmpresaCard />}
      {!viewModel.esVisibleRucCards && <DatosAdicionalesTitularCard />}
      {!viewModel.esVisibleRucCards && <DomicilioTitularCard />}
      {!viewModel.esVisibleRucCards && <DatosLaboralesAdicionalesTitularCard />}
      {viewModel.esVisibleConyugeCards && <DatosAdicionalesConyugeCard />}
      {viewModel.esVisibleConyugeDatosLabAdiCards && <DatosLaboralesAdicionalesConyugeCard />}
      {isTitularJuridico &&
        <InformeComercialModal
          open={openInformeComercial}
          solicitudId={solicitudId}
          handleClose={handleCloseInformeComercialModal}
        />
      }
    </div>
  );
};

export default SolicitudeStepFive;