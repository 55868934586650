import { appConfigKey, appDefaultValues } from "../../../../../config/Config";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import { TMnSolicitudAsociadoInsertUpdateRequest } from "../../../../models/MasterManager/solicitud-asociado-request.model";

export const ObtenerModeloSolicitudAsociado = (
    data: any,
    asociado: any | null,
    nombres: string,
    uid: number,
    identTipoRelacion: number,
    identUsuario: number,
    identSolicitud: number,
) => {

    let nombreCompleto: string = appDefaultValues.StringEmpty;
    let nombre: string | null = null;
    let apellidoPaterno: string | null = null;
    let apellidoMaterno: string | null = null;

    if (identTipoRelacion === appConfigKey.KeyIdTipoRelacionConcesionario) {
        nombreCompleto = nombres;
    } else if (identTipoRelacion === appConfigKey.KeyIdTipoRelacionVendedor) {
        const result = GeneralHelper.SepararNombreCompleto(nombres);
        if (result) {
            nombreCompleto = [result.nombres?.trim(), result.apellidoPaterno?.trim(), result.apellidoMaterno?.trim()].filter(Boolean).join(' ');
            nombre = result.nombres?.trim();
            apellidoPaterno = result.apellidoPaterno?.trim();
            apellidoMaterno = result.apellidoMaterno?.trim();
        }
    }

    let model = new TMnSolicitudAsociadoInsertUpdateRequest();
    if (asociado) {
        model.idenT_SOLICITUD_ASOCIADO = asociado.idenT_SOLICITUD_ASOCIADO;
    }
    model.idenT_SOLICITUD = identSolicitud;
    model.uid = uid;
    model.iD_TIPO_DOCUMENTO = data.tipoDocumento;
    model.codL_NUMERO_DOCUMENTO = data.numeroDocumento;
    model.idenT_TIPO_RELACION = identTipoRelacion;
    model.descL_NOMBRE_COMPLETO = nombreCompleto;
    model.descL_NOMBRES = nombre;
    model.descL_APELLIDO_PATERNO = apellidoPaterno;
    model.descL_APELLIDO_MATERNO = apellidoMaterno;
    model.inD_ESTADO = true;
    model.idenT_USUARIO = identUsuario;

    return model;
};