import { Button, FormControl, FormLabel, Input, Textarea } from "@mui/joy";
import { Dialog } from "@mui/material";
import React, { useState } from "react";
import RequestManager from "../../../../services/origination/wapirequest.service";
import { toast } from "sonner";
import MasterManager from "../../../../services/origination/wapimaster.service";
import { IListTablaGenerica } from "../../../../../assets/interface/IListTablaGenerica";
import Option from "@mui/joy/Option";
import Select from "@mui/joy/Select";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { useMsal } from "@azure/msal-react";
import zIndex from "@mui/material/styles/zIndex";

const CambioEstadoSolicitudDialog = (props: any) => {
    const [optionsEstado, setOptionsEstado] = useState<IListTablaGenerica[]>([]);
    const { instance } = useMsal();
    const [name, setName] = useState<string>('');
    const [fecha, setFecha] = useState<string>('');
    const activeAccount = instance.getActiveAccount();
    const [ requestCambioEstado, setRequestCambioEstado] = React.useState({
        iD_ESTADO_SOLICITUD: 0,
        descL_OBSERVACION: "",
      })
    
    const changeValue = (e: any) => {
        let { name, value, type } = e.target;
        if (type == "number" && value == "") {
          value = 0;
        }
        if (type == "select") {
          value = parseInt(value);
        }
        if ((type = "date" && value == "Invalid Date")) {
          value = null;
        }
        
        setRequestCambioEstado({
          ...requestCambioEstado,
          [name]: value,
        });
      }
    
    const Guardar = async () =>{
        let resultado;
        var body = {
            idenT_SOLICITUD: props.IdSolicitud,
            iD_ESTADO_SOLICITUD: requestCambioEstado.iD_ESTADO_SOLICITUD,
            idenT_USUARIO_CREADOR: localStorage.getItem('UserMn'),
            descL_OBSERVACION: requestCambioEstado.descL_OBSERVACION
          };
          const response = await RequestManager.GetSolicitudCambiarEstadoById(body);
          if (response.status === 200) {
            //myowasp(response);
            resultado = response.data.isValid;
            if(resultado === true){
                toast.success("Estado de la solicitud cambiado", {duration: 4000});
                props.handleClose();
                props.GetMnbandejaSolicitud();
            }
            else{
                toast.error("Error al cambiar el estado de la solicitud", {duration: 4000});
            }
          };
      }

    const GetGetMnTablaGenericaDefinicion = async (dat: string) => {
        var body = {
          v_LIST_IDENT_TABLA: dat,
        };
        var response: any = await MasterManager.GetMnTablaGenericaDefinicion(
          body
        ).then((result: any) => {
            var data = result.data.content.filter((x:any) => 
                        (x.genericA_IDENT_CAMPO === 57 || x.genericA_IDENT_CAMPO === 58 || x.genericA_IDENT_CAMPO === 59))
            
            setOptionsEstado(data);
        });
    };


    const GetFechaActual = () => {
        var today = new Date(),
            date = today.getDate() + '/' + (today.getMonth() + 1) + '/' + today.getFullYear();
            setFecha(date);
    }
      React.useEffect(() => {
        //myowasp(props.IdSolicitud)
        if (activeAccount) {
            setName(activeAccount.name?.split(' ')[0]??'');
        }
        GetFechaActual();
        GetGetMnTablaGenericaDefinicion("1"); //Id estados de solicitud
      }, []);
    
    
        return (
            <Dialog open={props.open} maxWidth={'md'} style={{zIndex: 1000}}>
                <div className="content-documentation-add-dialog">
                    <div className="content-documentation-add-dialog-title">
                        <h2>Cambio de Estado</h2>
                    </div>
                    <div className="content-documentation-add-dialog-body mt-4">
                        <div className="row g-2 mb-2 ml-2 align-items-center">
                            <div className="col-lg-4">
                                <span className="color:">Usuario:</span>
                            </div>
                            <div className="col-lg-8">
                                <FormControl>
                                    <Input placeholder="" value={name} disabled/>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row g-2 mb-2 ml-2 align-items-center">
                            <div className="col-lg-4">
                                <span className="">Fecha:</span>
                            </div>
                            <div className="col-lg-8">
                                <FormControl>
                                    <Input placeholder="" value={fecha} disabled/>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row g-2 mb-2 ml-2 align-items-center">
                            <div className="col-lg-4">
                                <span className="">Estado:</span>
                            </div>
                            <div className="col-lg-8">
                                <FormControl>
                                <Select
                                    defaultValue="0"
                                    name="iD_ESTADO_SOLICITUD"
                                    onChange={(e, newValue) =>
                                        changeValue({
                                            target: {
                                            value: newValue,
                                            name: "iD_ESTADO_SOLICITUD",
                                            type: "select",
                                            },
                                        })
                                    }
                                    indicator={<KeyboardArrowDown />}>
                                    <Option value="0">--Seleccione--</Option>
                                        {optionsEstado.map((option) => (
                                    <Option value={option.genericA_IDENT_CAMPO}>
                                        {option.genericA_DESCM_CAMPO}
                                    </Option>
                                ))}
                                </Select>
                                </FormControl>
                            </div>
                        </div>
                        <div className="row g-2 mb-2 ml-2 align-items-center">
                            <div className="col-lg-4">
                                <span className="">Comentario:</span>
                            </div>
                            <div className="col-lg-8">
                                <FormControl>
                                    <Textarea name="descL_OBSERVACION" id="textComentario" minRows={3} className="custom-textarea" onChange={(e) => changeValue(e)}></Textarea>
                                </FormControl>
                            </div>
                        </div>
                    </div>
                    <div className="content-documentation-add-dialog-footer">
                        <Button size="md" color="danger" onClick={props.handleClose} sx={{ borderRadius: 24, width: 4 / 10 }}>
                             <div>Cancelar</div>
                        </Button>
                        <Button size="md" color="danger" onClick={() => Guardar()} sx={{ borderRadius: 24, width: 4 / 10 }}>
                            <div>Guardar</div>
                        </Button>
                    </div>
                </div>
            </Dialog>
        );
    }
    
    export default CambioEstadoSolicitudDialog;