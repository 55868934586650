import { BTCrearPersonaJuridicaGruposEconomicos } from "./BTCrearPersonaJuridicaGruposEconomicos";
import { BTCrearPersonaJuridicaInformacion } from "./BTCrearPersonaJuridicaInformacion";
import { BTCrearPersonaJuridicaIntegrantesJuridicos } from "./BTCrearPersonaJuridicaIntegrantesJuridicos";

export class BTCrearPersonaJuridicaRequest {
    private sBTPersonaJuridica1: BTCrearPersonaJuridicaInformacion;
    private sBTIntegrantesJuridicosAlta: BTCrearPersonaJuridicaIntegrantesJuridicos;
    private sBTGrupoEconomico: BTCrearPersonaJuridicaGruposEconomicos;

    constructor() {
        this.sBTPersonaJuridica1 = new BTCrearPersonaJuridicaInformacion();
        this.sBTIntegrantesJuridicosAlta = new BTCrearPersonaJuridicaIntegrantesJuridicos();
        this.sBTGrupoEconomico = new BTCrearPersonaJuridicaGruposEconomicos();
    }

    public getPersonaJuridica1(): BTCrearPersonaJuridicaInformacion {
        return this.sBTPersonaJuridica1;
    }

    public setPersonaJuridica1(sBTPersonaJuridica1: BTCrearPersonaJuridicaInformacion): void {
        this.sBTPersonaJuridica1 = sBTPersonaJuridica1;
    }

    public getIntegrantesJuridicosAlta(): BTCrearPersonaJuridicaIntegrantesJuridicos {
        return this.sBTIntegrantesJuridicosAlta;
    }

    public setIntegrantesJuridicosAlta(sBTIntegrantesJuridicosAlta: BTCrearPersonaJuridicaIntegrantesJuridicos): void {
        this.sBTIntegrantesJuridicosAlta = sBTIntegrantesJuridicosAlta;
    }

    public getGrupoEconomico(): BTCrearPersonaJuridicaGruposEconomicos {
        return this.sBTGrupoEconomico;
    }

    public setGrupoEconomico(sBTGrupoEconomico: BTCrearPersonaJuridicaGruposEconomicos): void {
        this.sBTGrupoEconomico = sBTGrupoEconomico;
    }
}