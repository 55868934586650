export const ObtenerResultadoEvaluacionVigente = (listaResultados: any) => {

    const etapA_RESULTADO = "EVALUACION CREDITICIA - MOTOR";

    const resultadosEvaluacionVigentes = [];

    for (let index = 0; index < listaResultados.length; index++) {
        
        const element = listaResultados[index];

        resultadosEvaluacionVigentes.push(element);

        if (element.etapA_RESULTADO === etapA_RESULTADO) {

            break;

        }
        
    }

    return resultadosEvaluacionVigentes;

};

export const ObtenerListaResultadoEvaluacion = (response: any) => {
    return response.data.content;
};