import { appDefaultValues } from "../../../../../../config/Config";

export interface DatosAdicionalesTitularInterface {
    identNacionalidad: number,
    identNivelEducacion: number,
    identProfesion: number,
    nroHijos: string,
    nroDependientes: string,
    separacionBienes: boolean,
    compraPara: boolean,
    nomYApeContacto1: string,
    celular1: string,
    nomYApeContacto2: string,
    celular2: string,
    correo: string,
}

export const DatosAdicionalesTitularInterfaceDefault: DatosAdicionalesTitularInterface = {

    identNacionalidad: appDefaultValues.NumberDefault,
    identNivelEducacion: appDefaultValues.NumberDefault,
    identProfesion: appDefaultValues.NumberDefault,
    nroHijos: appDefaultValues.StringEmpty,
    nroDependientes: appDefaultValues.StringEmpty,
    separacionBienes: appDefaultValues.BooleanDefault,
    compraPara: appDefaultValues.BooleanDefault,
    nomYApeContacto1: appDefaultValues.StringEmpty,
    celular1: appDefaultValues.StringEmpty,
    nomYApeContacto2: appDefaultValues.StringEmpty,
    celular2: appDefaultValues.StringEmpty,
    correo: appDefaultValues.StringEmpty,
};