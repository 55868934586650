class MontoSolicitudHelper {

    monto: number;
    tipoMoneda: number;
    tipoCambio: number;

    constructor(monto: number, tipoMoneda: number, tipoCambio: number) {
        this.monto = monto;
        this.tipoMoneda = tipoMoneda;
        this.tipoCambio = tipoCambio;
    }

    getDolares() {
        if (this.tipoMoneda === 1) {
            return this.monto / this.tipoCambio;
        }

        return this.monto;
    }

    getSoles() {
        if (this.tipoMoneda === 2) {
            return this.monto * this.tipoCambio;
        }

        return this.monto;
    }

    getPorcentajeInicial(montoTotal: number, montoInicial: number) {
        const monto = Number(montoInicial / montoTotal);
        const porcentaje = Number(monto) * 100;
        return  porcentaje;
    }

    getMontoFinanciar(tipoMoneda: number, montoTotal: number, montoInicial: number, tipoCambio: number) {

        
    }

}

export default MontoSolicitudHelper;