import TitleCard from "../../../../3.40.-SolicitudeStepOne/components/elements/titleCard";
import { NumberInput, SelectAutocompleteInput, SelectInput, SimpleInput } from "../../../../3.40.-SolicitudeStepOne/components/elements/inputs";
import { useEffect, useState } from "react";

const TitularDatosCard = (props: any) => {

    const {
        data,
        listPais,
        listProfesion,
        listNivelEducacion,
        setTitularDatosFinal,
        clickSubmit
    } = props;

    const [viewModel, setViewModel] = useState({
        identNacionalidad: 0,
        identNivelEducacion: 0,
        identProfesion: 0,
        nroHijos: 0,
        nroDependientes: 0,
        nomYApeContacto1: "",
        celular1: "",
        nomYApeContacto2: "",
        celular2: "",
        correo: "",
    });

    useEffect(() => {
        if (data !== null && data !== undefined) {
            setViewModel({
                ...viewModel,
                identNacionalidad: data.iD_NACIONALIDAD,
                identNivelEducacion: data.iD_NIVEL_ESTUDIOS,
                identProfesion: data.iD_PROFESION,
                nroHijos: data.monT_NUMERO_HIJOS,
                nroDependientes: data.monT_NUMERO_DEPENDIENTES,
                nomYApeContacto1: data.descL_NOMBRE_COMPLETO_CONTACTO1,
                celular1: data.descC_NUMERO_CONTACTO1,
                nomYApeContacto2: data.descL_NOMBRE_COMPLETO_CONTACTO2,
                celular2: data.descC_NUMERO_CONTACTO2,
                correo: data.descL_EMAIL,
            });
        }
    }, [data]);

    useEffect(() => {
        if (clickSubmit !== 0) {

            const findNacionalidad: any = listPais.find((pais: any) => pais.id === viewModel.identNacionalidad);
            const descripcionNacionalidad = findNacionalidad?.description;

            const findNivelEducacion: any = listNivelEducacion.find((nivel: any) => nivel.id === viewModel.identNivelEducacion);
            const descripcionNivelEducacion = findNivelEducacion?.description;

            const findProfesion: any = listProfesion.find((profesion: any) => profesion.id === viewModel.identProfesion);
            const descripcionProfesion = findProfesion?.description;

            setTitularDatosFinal({
                ...viewModel,
                descripcionNacionalidad,
                descripcionNivelEducacion,
                descripcionProfesion
            });
        }
    }, [clickSubmit]);

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
            <TitleCard title={"DATOS DEL TITULAR"} obligatorio={true} />
            <div className="row g-2 mb-2 align-items-center">
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-4 cardObjets">
                        <SelectAutocompleteInput
                            value={viewModel.identNacionalidad}
                            name={"identNacionalidad"}
                            title={"Nacionalidad"}
                            options={listPais}
                            required={true}
                            onChange={(event: any, newValue: any) => {
                                setViewModel({ ...viewModel, identNacionalidad: newValue });
                            }}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SelectInput
                            value={viewModel.identNivelEducacion}
                            name={"identNivelEducacion"}
                            title={"Nivel de educación"}
                            options={listNivelEducacion}
                            required={true}
                            onChange={(event: any, newValue: any) => {
                                setViewModel({ ...viewModel, identNivelEducacion: newValue });
                            }}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SelectInput
                            value={viewModel.identProfesion}
                            options={listProfesion}
                            name={"identProfesion"}
                            title={"Profesión"}
                            required={true}
                            onChange={(event: any, newValue: any) => {
                                setViewModel({ ...viewModel, identProfesion: newValue });
                            }}
                        />
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-2 cardObjets">
                        <NumberInput
                            value={viewModel.nroHijos}
                            title={"Nro. Hijos"}
                            name={"nroHijos"}
                            longNumber={3}
                            required={true}
                            onChange={(event: any) => {
                                setViewModel({ ...viewModel, nroHijos: event.target.value });
                            }}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <NumberInput
                            value={viewModel.nroDependientes}
                            title={"Nro. Dependientes"}
                            name={"nroDependientes"}
                            longNumber={3}
                            required={true}
                            onChange={(event: any) => {
                                setViewModel({ ...viewModel, nroDependientes: event.target.value });
                            }}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            value={viewModel.nomYApeContacto1}
                            title={"Nombre y Apellido contacto 1"}
                            name={"nomYApeContacto1"}
                            required={true}
                            onChange={(event: any) => {
                                setViewModel({ ...viewModel, nomYApeContacto1: event.target.value });
                            }}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <NumberInput
                            name={"celular1"}
                            title={"Celular 1"}
                            placeholder=""
                            value={viewModel.celular1}
                            longNumber={9}
                            required={true}
                            onChange={(event: any) => {
                                setViewModel({ ...viewModel, celular1: event.target.value });
                            }}
                        />
                    </div>
                </div>

                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            name={"nomYApeContacto2"}
                            title={"Nombre y Apellido contacto 2"}
                            placeholder=""
                            value={viewModel.nomYApeContacto2}
                            required={true}
                            onChange={(event: any) => {
                                setViewModel({ ...viewModel, nomYApeContacto2: event.target.value })
                            }}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <NumberInput
                            name={"celular2"}
                            title={"Celular 2"}
                            placeholder=""
                            value={viewModel.celular2}
                            longNumber={9}
                            required={true}
                            onChange={(event: any) => {
                                setViewModel({ ...viewModel, celular2: event.target.value });
                            }}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            name={"correo"}
                            title={"Correo Titular"}
                            placeholder=""
                            value={viewModel.correo}
                            required={true}
                            onChange={(event: any) => {
                                setViewModel({ ...viewModel, correo: event.target.value })
                            }}
                        />
                    </div>
                </div>
            </div>
        </div>
    );

};

export default TitularDatosCard;