import { appConfigKey, appDefaultValues } from "../../../../config/Config";
import GeneralHelper from "../../../helpers/GeneralHelper";

class CardDetailStepOneHelper {

    agregarDivisa(monto: any, divisa: any) {

        if (appConfigKey.keyIdentificadorSoles === divisa) {
            return `${appConfigKey.keySimboloSoles} ${monto}`;
        }
        
        if (appConfigKey.keyIdentificadorDolares === divisa) {
            return `${appConfigKey.keySimboloDolares1} ${monto}`;
        }

        return monto;
    }

    obtenerIngresos(listOtrosIngresosTitular: any, tipoCambio: number) {

        let ingresoTotal: number = appDefaultValues.NumberDefault;

        for (let index = 0; index < listOtrosIngresosTitular.length; index++) {
            
            const element = listOtrosIngresosTitular[index];

            const { esAnualizado5ta, ingresoAnualizado, ingresoNeto, idTipoMoneda } = element;

            ingresoTotal = ingresoTotal + this.obtenerIngresosBase(esAnualizado5ta,ingresoAnualizado,ingresoNeto,idTipoMoneda,tipoCambio);

        }

        return ingresoTotal;

    }

    obtenerIngresosBase(anualizado: boolean, calculoAnualizado: string, ingresosNetos: string, idTipoMoneda: number, tipoCambio: number) {

        if (anualizado) {

            const tempCalculoAnualizado = GeneralHelper.QuitarComasAMiles(calculoAnualizado);

            if (idTipoMoneda === appConfigKey.keyIdentificadorSoles) {
                return Number(tempCalculoAnualizado);
            }

            if (idTipoMoneda === appConfigKey.keyIdentificadorDolares) {
                return Number(tempCalculoAnualizado) * tipoCambio;
            }

            return Number(tempCalculoAnualizado);

        } else {

            const tempIngresosNetos = GeneralHelper.QuitarComasAMiles(ingresosNetos);

            if (idTipoMoneda === appConfigKey.keyIdentificadorSoles) {
                return Number(tempIngresosNetos);
            }

            if (idTipoMoneda === appConfigKey.keyIdentificadorDolares) {
                return Number(tempIngresosNetos) * tipoCambio;
            }

            return Number(tempIngresosNetos);

        }

    }

}

export default CardDetailStepOneHelper;