import { appConfigKey } from "../../../../../config/Config";
import { validarCorreo, validarFecha, validarSelect, validarText } from "../../../../helpers/ValidarHelper";

const ValidacionStepFiveHelper = {
    NewDatosDomicilioTitulardata: (newDomicilioTitularData: any) => {
        const {
            nombreVia,
            numDomicilio,
            intDptoPiso,
            manzana,
            lote,
            nombreAgrupacion,
            referencia,
            identTipoVivienda,
            identTiempoResidencia
        } = newDomicilioTitularData;

        if(!validarText(nombreVia, 'Nombre de vía del Titular')){
            return false;
        }
        if(!validarText(numDomicilio, 'Número de domicilio del Titular')){
            return false;
        }
        // if(!validarText(nombreAgrupacion, 'Nombre de agrupación del Titular')){
        //     return false;
        // }
        if(!validarSelect(identTipoVivienda, 'Tipo de vivienda del Titular')){
            return false;
        }
        if(!validarSelect(identTiempoResidencia, 'Tiempo de residencia del Titular')){
            return false;
        }
        
        return true;
    },

    NewDatosAdicionalesTitularData: (newDatosAdicionalesTitularData: any) => {
        const {
            identNacionalidad,
            identNivelEducacion,
            identProfesion,
            nroHijos,
            nroDependientes,
            nomYApeContacto1,
            celular1,
            nomYApeContacto2,
            celular2,
            correo
        } = newDatosAdicionalesTitularData;

        if (!validarSelect(identNacionalidad, 'nacionalidad del titular')) {
            return false;
        }
        if (!validarSelect(identNivelEducacion, 'nivel de educación del titular')) {
            return false;
        }
        if (!validarSelect(identProfesion, "profesión del titular")) {
            return false;
        }
        if (!validarText(nroHijos, "Nro. hijos del titular")) {
            return false;
        }
        if (!validarText(nroDependientes, "Nro. dependientes del titular")) {
            return false;
        }
        if (!validarText(nomYApeContacto1, 'nombre y apellido contacto 1 del titular')) {
            return false;
        }
        if (!validarText(celular1, 'Celular 1 del titular')) {
            return false;
        }
        if (!validarText(nomYApeContacto2, 'nombre y apellido contacto 2 del titular')) {
            return false;
        }
        if (!validarText(celular2, 'Celular 2 del titular')) {
            return false;
        }
        if ((!validarText(correo, 'Titular Correo')) || (!validarCorreo(correo, 'Titular Correo'))) {
            return false;
        }
        return true;
    },

    NewDatosLaboralesAdicionalesTitularData: (newDatosLaboralesAdicionalesTitularData: any) => {
        const {
            identActividadEconomica,
            identPais,
            identDepartamentos,
            identProvincias,
            identDistritos,
            nombreVia,
            numeroVia,
            intDptoPiso,
            manzana,
            lote,
            nombreAgrupacion,
            referencia,
            telefono,
            anexo,
            correo,
            identCargoLaboral,
            descripcionCargo
        } = newDatosLaboralesAdicionalesTitularData;

        if (!validarSelect(identActividadEconomica, 'Titular Datos Laborales Actividad económica')) {
            return false;
        }
        if (!validarSelect(identPais, 'Titular Datos Laborales País')) {
            return false;
        }
        if (!validarSelect(identDepartamentos, "Titular Datos Laborales Departamento")) {
            return false;
        }
        if (!validarSelect(identProvincias, "Titular Datos Laborales Provincia")) {
            return false;
        }
        if (!validarSelect(identDistritos, "Titular Datos Laborales Distrito")) {
            return false;
        }
        if (!validarText(nombreVia, 'Titular Datos Laborales Nombre de vía')) {
            return false;
        }
        if (!validarText(numeroVia, 'Titular Datos Laborales Número')) {
            return false;
        }
        // if (!validarText(intDptoPiso, 'Titular Datos Laborales Interior/Dpto/Piso')) {
        //     return false;
        // }
        // if (!validarText(manzana, 'Titular Datos Laborales Manzana')) {
        //     return false;
        // }
        // if (!validarText(lote, 'Titular Datos Laborales Lote')) {
        //     return false;
        // }
        // if (!validarText(nombreAgrupacion, 'Titular Datos Laborales Nombre agrupación')) {
        //     return false;
        // }
        // if (!validarText(referencia, 'Titular Datos Laborales Referencia')) {
        //     return false;
        // }
        if (!validarText(telefono, 'Titular Datos Laborales Teléfono')) {
            return false;
        }
        // if (!validarText(anexo, 'Titular Datos Laborales Anexo')) {
        //     return false;
        // }
        if (correo != "" && correo != undefined && correo != null) {
            if(!validarCorreo(correo, 'Titular Correo')){
                return false;
            }
        }
        if (!validarSelect(identCargoLaboral, 'Titular Datos Laborales Cargo laboral')) {
            return false;
        }
        // if (!validarText(descripcionCargo, 'Titular Datos Laborales Descripción cargo')) {
        //     return false;
        // }

        return true;
    },

    NewDatosAdicionalesConyugeData: (newDatosAdicionalesConyugeData: any) => {
        const {
            identNacionalidad,
            identNivelEducacion,
            correo,
            identProfesion,
        } = newDatosAdicionalesConyugeData;

        if (!validarSelect(identNacionalidad, 'nacionalidad del cónyuge')) {
            return false;
        }
        if (!validarSelect(identNivelEducacion, 'nivel de educación del cónyuge')) {
            return false;
        }
        if (!validarText(correo, "correo del cónyuge")) {
            return false;
        }
        if (!validarSelect(identProfesion, "profesión del cónyuge")) {
            return false;
        }
        return true;
    },

    NewDatosLaboralesAdicionalesConyugeData: (newDatosLaboralesConyugeData: any, newDatosLaboralesAdicionalesConyugeData: any) => {
                
        const {
            identActividadEconomica,
            identPais,
            identDepartamentos,
            identProvincias,
            identDistritos,
            nombreVia,
            numeroVia,
            intDptoPiso,
            manzana,
            lote,
            nombreAgrupacion,
            referencia,
            telefono,
            anexo,
            correo,
            identCargoLaboral,
            descripcionCargo
        } = newDatosLaboralesAdicionalesConyugeData;

        const { identCategoriaLaboral } = newDatosLaboralesConyugeData;

        if (identCategoriaLaboral == appConfigKey.keyIdSinCategoria) return true;

        if (!validarSelect(identActividadEconomica, 'Cónyuge Datos Laborales Actividad económica')) {
            return false;
        }
        if (!validarSelect(identPais, 'Cónyuge Datos Laborales País')) {
            return false;
        }
        if (!validarSelect(identDepartamentos, "Cónyuge Datos Laborales Departamento")) {
            return false;
        }
        if (!validarSelect(identProvincias, "Cónyuge Datos Laborales Provincia")) {
            return false;
        }
        if (!validarSelect(identDistritos, "Cónyuge Datos Laborales Distrito")) {
            return false;
        }
        if (!validarText(nombreVia, 'Cónyuge Datos Laborales Nombre de vía')) {
            return false;
        }
        if (!validarText(numeroVia, 'Cónyuge Datos Laborales Número')) {
            return false;
        }
        // if (!validarText(intDptoPiso, 'Cónyuge Datos Laborales Interior/Dpto/Piso')) {
        //     return false;
        // }
        // if (!validarText(manzana, 'Cónyuge Datos Laborales Manzana')) {
        //     return false;
        // }
        // if (!validarText(lote, 'Cónyuge Datos Laborales Lote')) {
        //     return false;
        // }
        //                                    
        // if (!validarText(referencia, 'Cónyuge Datos Laborales Referencia')) {
        //     return false;
        // }
        if (!validarText(telefono, 'Cónyuge Datos Laborales Teléfono')) {
            return false;
        }
        // if (!validarText(anexo, 'Cónyuge Datos Laborales Anexo')) {
        //     return false;
        // }
        if (!validarText(correo, 'Cónyuge Datos Laborales Correo')) {
            return false;
        }
        if (!validarSelect(identCargoLaboral, 'Cónyuge Datos Laborales Cargo laboral')) {
            return false;
        }
        // if (!validarText(descripcionCargo, 'Cónyuge Datos Laborales Descripción cargo')) {
        //     return false;
        // }

        return true;
    },

    NewDatosLaboralesAdicionalesConyugeData2: (newDatosLaboralesAdicionalesConyugeData: any) => {
                
        const {
            identActividadEconomica,
            identPais,
            identDepartamentos,
            identProvincias,
            identDistritos,
            nombreVia,
            numeroVia,
            intDptoPiso,
            manzana,
            lote,
            nombreAgrupacion,
            referencia,
            telefono,
            anexo,
            correo,
            identCargoLaboral,
            descripcionCargo
        } = newDatosLaboralesAdicionalesConyugeData;

        if (!validarSelect(identActividadEconomica, 'Cónyuge Datos Laborales Actividad económica')) {
            return false;
        }
        if (!validarSelect(identPais, 'Cónyuge Datos Laborales País')) {
            return false;
        }
        if (!validarSelect(identDepartamentos, "Cónyuge Datos Laborales Departamento")) {
            return false;
        }
        if (!validarSelect(identProvincias, "Cónyuge Datos Laborales Provincia")) {
            return false;
        }
        if (!validarSelect(identDistritos, "Cónyuge Datos Laborales Distrito")) {
            return false;
        }
        if (!validarText(nombreVia, 'Cónyuge Datos Laborales Nombre de vía')) {
            return false;
        }
        if (!validarText(numeroVia, 'Cónyuge Datos Laborales Número')) {
            return false;
        }

        // if (!validarText(intDptoPiso, 'Cónyuge Datos Laborales Interior/Dpto/Piso')) {
        //     return false;
        // }
        // if (!validarText(manzana, 'Cónyuge Datos Laborales Manzana')) {
        //     return false;
        // }
        // if (!validarText(lote, 'Cónyuge Datos Laborales Lote')) {
        //     return false;
        // }
        //                                    
        // if (!validarText(referencia, 'Cónyuge Datos Laborales Referencia')) {
        //     return false;
        // }

        if (!validarText(telefono, 'Cónyuge Datos Laborales Teléfono')) {
            return false;
        }
        
        // if (!validarText(anexo, 'Cónyuge Datos Laborales Anexo')) {
        //     return false;
        // }
        if (!validarText(correo, 'Cónyuge Datos Laborales Correo')) {
            return false;
        }
        if (!validarSelect(identCargoLaboral, 'Cónyuge Datos Laborales Cargo laboral')) {
            return false;
        }
        // if (!validarText(descripcionCargo, 'Cónyuge Datos Laborales Descripción cargo')) {
        //     return false;
        // }

        return true;
    },

    // 3era categoria
    NewDatosAdicionalesTitularData3era: (newDatosTitularData: any) => {
        const {
            celular1,
            celular2,
            identTiempoResidencia,
            identTipoVivienda,
            intDptoPiso,
            lote,
            manzana,
            nomYApeContacto1,
            nomYApeContacto2,
            nombreAgrupacion,
            nombreVia,
            numDomicilio,
            referencia
        } = newDatosTitularData;

        if (!validarText(nombreVia, 'Empresa Datos Nombre de Vía')) {
            return false;
        }
        if (!validarText(numDomicilio, 'Empresa Datos Número')) {
            return false;
        }
        if (!validarText(nombreAgrupacion, 'Empresa Datos Nombre de Agrupación')) {
            return false;
        }
        if (!validarSelect(identTipoVivienda, 'Empresa Datos Tipo de Vivienda')) {
            return false;
        }
        if (!validarSelect(identTiempoResidencia, 'Empresa Datos Tiempo de Residencia')) {
            return false;
        }
        if (!validarText(nomYApeContacto1, 'Empresa Datos Nombre de Contacto 1')) {
            return false;
        }
        if (!validarText(celular1, 'Empresa Datos Celular 1')) {
            return false;
        }
        if (!validarText(nomYApeContacto2, 'Empresa Datos Nombre de Contacto 2')) {
            return false;
        }
        if (!validarText(celular2, 'Empresa Datos Celular 2')) {
            return false;
        }

        return true;
    },

    NewDatosAdicionalesTitularEmpresaData3era: (newDatosTitularEmpresaData: any) => {
        const {
            codSBS,
            capitalSocialActual,
            patrimonio,
            patrimonioPersonal,
            sectorEconomico,
            tipoCadenaProductiva,
            actividadComplementaria
        } = newDatosTitularEmpresaData;

        if (!validarSelect(capitalSocialActual, 'Titular Datos Empresa Capital Socio Actual')) {
            return false;
        }
        if (!validarSelect(patrimonio, 'Titular Datos Empresa Patrimonio')) {
            return false;
        }
        if (!validarSelect(patrimonioPersonal, 'Titular Datos Empresa Patrimonio Personal')) {
            return false;
        }
        if (!validarText(tipoCadenaProductiva, 'Titular Datos Empresa Cadena Productiva')) {
            return false;
        }
        return true;
    },

}
export default ValidacionStepFiveHelper;