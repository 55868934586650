import { useEffect, useState } from "react";
import { useMsal } from "@azure/msal-react";
import './welcomeName.scss';
import Typography from "@mui/material/Typography";

const WelcomeName = () => {
    const { instance } = useMsal();
    const [name, setName] = useState<string>('');

    const activeAccount = instance.getActiveAccount();
    useEffect(() => {
        if (activeAccount) {
            ////myowasp(activeAccount);
            setName(activeAccount.name?.split(' ')[0]??'');
        } else {
            setName('');
        }
    }, [activeAccount]);

    if (name) {
        return <Typography>{name}</Typography>;
    } else {
        return null;
    }
};

export default WelcomeName;