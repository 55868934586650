import React, { Component, PropsWithChildren} from 'react';
import './main-layout.scss';
import 'bootstrap/dist/css/bootstrap.css';
import CustomNav from '../1.2.1.-custom-nav/custom-nav';
import InactivityDetector from './components/InactivityDetector';
import UpdateOtherService from './components/UpdateOtherService';

class MainLayout extends Component<PropsWithChildren,any> {
  
  constructor(props:PropsWithChildren){
    super(props);
    this.state = {};
    // this.handleUserName = this.handleUserName.bind(this);
  }

  render() {
    return (
      <>
        <CustomNav />
        <InactivityDetector />
        <UpdateOtherService />
      </>
    );
  }
}

export default MainLayout;