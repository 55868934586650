import { Button, styled } from "@mui/joy";
import { Dialog } from "@mui/material";
import React, { useRef } from "react";
import IconUploadBlack from '../../../../../assets/media/icons/ico_upload_black.svg';
import '../dboProfile.scss';
import RequestManager from "../../../../services/origination/wapirequest.service";
import { toast } from "sonner";

const VisuallyHiddenInput = styled('input')`
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  height: 1px;
  overflow: hidden;
  position: absolute;
  bottom: 0;
  left: 0;
  white-space: nowrap;
  width: 1px;
`;

const ActivacionMasiva = (props: any) => {
    const[labelFile, setLabelFile] = React.useState(null);
    const inputFile = useRef(null);
    const[file, setFile] = React.useState(null);

    const changeFileValue = (e: any) => {
        let { name, size} = e.target.files[0];
        setFile(e.target.files[0]);
        //myowasp(e.target.files[0]);
        //myowasp(name);
        setLabelFile(name);
    } 
    
    function fileToBase64(file: any) {
        return new Promise<string>((resolve, reject) => {
            const reader: any = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const base64String: string = reader.result.split(',')[1];
                resolve(base64String);
            };
            reader.onerror = (error: any) => reject(error);
        });
    }
    
    const UploadFile = async () => {
       if(file === null){
        toast.error("Suba un archivo.", {duration: 5000});
        return;
       }
       const archivo = await fileToBase64(file);
       const usuario = localStorage.getItem('UserMn')
        //myowasp(archivo);
       const body = {
        excel: archivo,
        idenT_USUARIO_CREADOR: usuario
       }
       const responseUploadFile = await RequestManager.GetMnSolicitudCargaMasiva(body);

        //myowasp(responseUploadFile);
        if(responseUploadFile.status !== 200){
            if(responseUploadFile.data.exceptions !== null){
                toast.error(responseUploadFile.data.exceptions.description, {duration: 5000})
            }
            else{
                toast.error("Ocurrió un error al subir el archivo", {duration: 5000})
            }
        }
        if(responseUploadFile.status === 200){
            toast.success("Solicitudes activadas correctamente", {duration: 5000});
            props.GetMnbandejaSolicitud();
            CancelFile();
            props.handleClose();
        }
    }

    const CancelFile = () => {
        setFile(null);
        setLabelFile(null);
    }
    
    React.useEffect(() => {
        setLabelFile(null);
      }, []);
    
    return (
        <Dialog open={props.open} maxWidth={'md'}>
            <div className="content-documentation-add-dialog">
                <div className="content-documentation-add-dialog-title">
                    <h2>Carga Masiva</h2>
                </div>
                <br></br>
                <br></br>
                <div className="content-documentation-add-dialog-body">
                    <input type="text" disabled></input>
                    <Button component="label" role={undefined} tabIndex={-1} variant="solid" color="danger" sx={{ borderRadius: 5, width: 2 / 10 }}>
                        Cargar 
                        <VisuallyHiddenInput ref={inputFile} type="file" onChange={((e) => changeFileValue(e))}/>
                    </Button>
                    <img style={{ paddingLeft: '5px', width: '23px' }} src={IconUploadBlack} />
                    <br></br>
                    <br></br>
                    <div className="row">
                        <label style={{marginLeft: "5px"}}>{labelFile}</label>
                    </div>
                </div>
                
                <div className="content-documentation-add-dialog-footer">
                    <Button size="md" color="danger" onClick={CancelFile} sx={{ borderRadius: 24, width: 3 / 10 }}>
                         <div>Cancelar</div>
                    </Button>
                    <Button size="md" color="danger" onClick={UploadFile} sx={{ borderRadius: 24, width: 3 / 10 }}>
                         <div>Activar</div>
                    </Button>
                    <Button size="md" color="danger" onClick={props.handleClose} sx={{ borderRadius: 24, width: 3 / 10 }}>
                        <div>Cerrar</div>
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default ActivacionMasiva;
