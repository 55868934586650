import { GesintelItem } from "../1.5.10.-interfaces/GesintelInterface";

class GesintelResponseHelper {

    static TransformarJsonToList(jsonString?: string): Array<GesintelItem> {
        let gesintelList: Array<GesintelItem> = [];
        if (!jsonString) return gesintelList;

        const jsonArrayObject = JSON.parse(jsonString);
        jsonArrayObject.forEach(function (g: any) {
            gesintelList.push({ description: g.descripcion } as GesintelItem)
        });

        return gesintelList;
    }

}

export default GesintelResponseHelper;