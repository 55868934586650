import * as React from "react";
import './CardMember.scss';

const CardMembers = ({ members , handleVerClasifPorIntegrante }: any) => {
  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <div className="row g-2 mb-4 align-items-center">
        <div className="col-12 mx-2 textLabelHeadboard">
          <span className="fw-bold">SELECCIONAR INTEGRANTE</span>
        </div>
      </div>
      {
        members.users.length > 0 && members.users.map((row: any) => (
          <div 
          className="row col-12 col-md-4 col-sm-4 col-xs-4 mb-3 mx-2 align-items-center item-member"
          onClick={() => handleVerClasifPorIntegrante(row.tipoIntegrante, row.nroDocumento, row.tipoDocumento)}
           >
           <div className="px-3 text-long">{row.tipoIntegrante?.toUpperCase()}</div> 
            <div className="px-3 text-long">{row.nombre}</div>
            <div className="px-3 text-long">{row.nroDocumento}</div>
          </div>
        ))
      }
    </div>
  );
};

export default CardMembers;