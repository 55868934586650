import React, { useEffect, useState } from "react";
import { Button, Table } from "@mui/joy";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { IconButton, Tooltip } from "@mui/material";
import GeneralHelper from "../../../helpers/GeneralHelper";
import { MoneyInput, NumberDecimalInput, NumberInput, SimpleInput, TextInput } from "../15.10.-base/elements/inputs";
import SimbolAdd from "@mui/icons-material/Add";
import Save from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";
import RequestManager from "../../../services/origination/wapirequest.service";
import { Dropdown } from "react-bootstrap";
import CustomDivToggle from "../15.10.-base/elements/customDivToggle/CustomDivToggle";
import { BsThreeDots, BsSearch } from "react-icons/bs";
import { toast } from "sonner";
import { DatosProveedores, DatosProveedoresDefault } from "../15.10.-base/interfaces";
import { appSecurityConfiguration } from "../../../../config/Config";
import { AxiosError } from "axios";

export const ProveedoresAuditor = (props: any) => {
    const [dataProveedores, setDataProveedores] = useState<DatosProveedores>(DatosProveedoresDefault);
    const [dataListProveedores, setDataListProveedores] = useState<DatosProveedores[]>([]);  
    const [flgShow, setFlgShow] = useState<boolean>(false);
    
    const onClickNuevo = () => {
      setFlgShow(true);
      setDataProveedores(DatosProveedoresDefault);
    }
  
    const handleClickViewDataProveedores = async (value: any) =>  {
      setFlgShow(true);
      const response = await RequestManager.GetMnInformeComercialProveedoresById({ "identInformeComercialProveedores": value });
      if (response.status === 200) {
          setDataProveedores(response.data.content);
      }
    }
  
    const handleClickDeleteProveedores = async (value: any) =>  {
      setFlgShow(false);
      setDataProveedores(DatosProveedoresDefault);
      const response = await RequestManager.MnInformeComercialProveedoresDelete({ "identInformeComercialProveedores": value });
      if (response.status === 200) {
          console.log(response);
          setDataProveedores(response.data.content);
          CargaInicial();
      }
    }
  
    const CargaInicial = async () => {
      const response = await RequestManager.GetInformeComercialProveedores({ "identInformeComercial": props.dataInformeComercial.identInformeComercial });
      if (response.status === 200) {
          setDataListProveedores(response.data.content);
      }
    }
  
    const onClickCancelar = () => {
      setFlgShow(false);
      setDataProveedores(DatosProveedoresDefault);
    }
  
    const GuardarProveedores = async() => {
      let resultado;
      let bodyInsert = {
          "identInformeComercial": dataProveedores.identInformeComercial == 0 ? props.dataInformeComercial.identInformeComercial : dataProveedores.identInformeComercial,
          "materiaPrimaInsumos": dataProveedores.materiaPrimaInsumos,
          "porcentajeSobreCompras": dataProveedores.porcentajeSobreCompras,
          "mayoresProveedores": dataProveedores.mayoresProveedores,
          "porcentajeSobreProveedores": dataProveedores.porcentajeSobreProveedores,
          "porcentajeMe": dataProveedores.porcentajeMe,
          "pagoModalidad": dataProveedores.pagoModalidad,
          "importacionesGlobales": dataProveedores.importacionesGlobales,
          "pagoPlazoDias": dataProveedores.pagoPlazoDias,
          "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
      }
      let bodyUpdate = {
          "identInformeComercialProveedores": dataProveedores.identInformeComercialProveedores,
          "identInformeComercial": dataProveedores.identInformeComercial,
          "materiaPrimaInsumos": dataProveedores.materiaPrimaInsumos,
          "porcentajeSobreCompras": dataProveedores.porcentajeSobreCompras,
          "mayoresProveedores": dataProveedores.mayoresProveedores,
          "porcentajeSobreProveedores": dataProveedores.porcentajeSobreProveedores,
          "porcentajeMe": dataProveedores.porcentajeMe,
          "pagoModalidad": dataProveedores.pagoModalidad,
          "importacionesGlobales": dataProveedores.importacionesGlobales,
          "pagoPlazoDias": dataProveedores.pagoPlazoDias,
          "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
      }
      
      try {
        const response = dataProveedores.identInformeComercialProveedores == 0 ? 
        await RequestManager.MnInformeComercialProveedoresInsert(bodyInsert) : 
        await RequestManager.MnInformeComercialProveedoresUpdate(bodyUpdate);
        
        if (response.status === 200) {
          resultado = response.data.isValid;
          if (resultado === true) {
            if(dataProveedores.identInformeComercialProveedores == 0)
                {toast.success("Registro guardado correctamente", { duration: 5000 });}
            else{toast.success("Registro actualizado correctamente.", { duration: 5000 });}
          }
          else{
            toast.error("Ocurrió un error", { duration: 5000 });
          }
        };
        CargaInicial();

        setFlgShow(false);
        setDataProveedores(DatosProveedoresDefault);        
      } catch (error: AxiosError | any) {            
        if (error.response && error.response.data.status === 400 && error.response.data.errors) {
            const validationErrors = error.response.data.errors;
            if (validationErrors) {
                for (const [field, messages] of Object.entries(validationErrors)) {                            
                    let message = messages as string[]; // Type assertion
                    toast.error(`${field}: ${message}`, { duration: 5000 });
                }
            } else {
                toast.error("Ocurrió un error", { duration: 5000 });
            }
        }
    }           
}
  
    useEffect(() => {
    CargaInicial();
    }, [props]);  
    
    return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>IX. PRINCIPALES PROVEEDORES</AccordionSummary>
          <AccordionDetails>
            <>

                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets align-top">
                        <Table borderAxis="none" className="style-table align-top">
                            <thead>
                                <tr>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>COMPRAS MATERIA PRIMA Y SUMINITSTROS</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>% SOBRE COMPRAS</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>MAYORES PROVEEDORES</th>
                                <th style={{ fontSize: "12px", width: "12%", backgroundColor: "#E1E1E1", }}>% SOBRE PROVEEDORES</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>% ME</th>
                                <th style={{ fontSize: "12px", width: "12%", backgroundColor: "#E1E1E1", }}>PAGO MODALIDAD</th>
                                <th style={{ fontSize: "12px", width: "12%", backgroundColor: "#E1E1E1", }}>PAGO PLAZO (DÍAS)</th>
                                <th style={{ fontSize: "12px", width: "10%", backgroundColor: "#E1E1E1", }}>ESTADO</th>
                                <th style={{ fontSize: "12px", width: "10%", backgroundColor: "#E1E1E1", }}>ACCIONES</th>
                                </tr>
                            </thead>
                        <tbody>
                        {
                            dataListProveedores.length > 0 && dataListProveedores.map((row: any, index: number) => {
                            return (
                            <tr key={row.identInformeComercialProduccion}>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.materiaPrimaInsumos}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.porcentajeSobreCompras}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.mayoresProveedores}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.porcentajeSobreProveedores}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.porcentajeMe}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.pagoModalidad}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.pagoPlazoDias}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.estado}</td>
                            <td>
                              <Dropdown key={row.identInformeComercialProveedores}>
                                <Dropdown.Toggle
                                  as={CustomDivToggle}
                                  style={{ cursor: "pointer" }}
                                >
                                  <BsThreeDots />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickViewDataProveedores(
                                            row.identInformeComercialProveedores
                                          )
                                        }
                                      >
                                        Editar
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickDeleteProveedores(
                                            row.identInformeComercialProveedores
                                          )
                                        }
                                      >
                                        Eliminar
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            </tr>
                            )
                            })
                        }
                        </tbody>
                        </Table>
                    </div>
                    <div className="row g-2 mb-2 align-items-center">
                        <div className="col-lg-12 cardObjets bottom-right">
                            <Button
                                size="md"
                                color="neutral"
                                variant="outlined"
                                endDecorator={<SimbolAdd />}
                                sx={{ borderRadius: 24, width: 1/8 }}
                                // disabled={props.disabled}
                                onClick={onClickNuevo}>Nuevo
                            </Button>
                        </div>
                    </div>
                </div>

              {flgShow && <div>        
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-4 cardObjets">
                      <SimpleInput
                          title={"Compra materia prima y suministro"}
                          placeholder="Ingresar"
                          value={dataProveedores.materiaPrimaInsumos}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataProveedores({ ...dataProveedores, materiaPrimaInsumos: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-4 cardObjets">
                      <NumberInput
                          title={"% sobre compras"}
                          placeholder="Ingresar"
                          longNumber={3}
                          value={dataProveedores.porcentajeSobreCompras}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataProveedores({ ...dataProveedores, porcentajeSobreCompras: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-4 cardObjets">
                      <SimpleInput
                          title={"Mayores proveedores"}
                          placeholder="Ingresar"
                          value={dataProveedores.mayoresProveedores}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataProveedores({ ...dataProveedores, mayoresProveedores: event.target.value })}
                      />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-2 cardObjets">
                      <NumberInput
                          title={"% sobre compras"}
                          placeholder="Ingresar"
                          longNumber={3}
                          value={dataProveedores.porcentajeSobreProveedores}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataProveedores({ ...dataProveedores, porcentajeSobreProveedores: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-2 cardObjets">
                      <NumberInput
                          title={"% ME"}
                          placeholder="Ingresar"
                          longNumber={3}
                          value={dataProveedores.porcentajeMe}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataProveedores({ ...dataProveedores, porcentajeMe: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-4 cardObjets">
                      <SimpleInput
                          title={"Pago modalidad"}
                          placeholder="Ingresar"
                          value={dataProveedores.pagoModalidad}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataProveedores({ ...dataProveedores, pagoModalidad: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-2 cardObjets">
                      <NumberInput
                          title={"Importaciones globales (% sobre compras)"}
                          placeholder="Ingresar"
                          longNumber={3}
                          value={dataProveedores.importacionesGlobales}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataProveedores({ ...dataProveedores, importacionesGlobales: event.target.value })}
                      />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-2 cardObjets">
                      <NumberInput
                          title={"Pago plazo (días)"}
                          placeholder="Ingresar"
                          value={dataProveedores.pagoPlazoDias}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataProveedores({ ...dataProveedores, pagoPlazoDias: event.target.value })}
                      />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center ">
                    <div className="col-lg-12 cardObjets bottom-right">
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<Save />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            onClick={GuardarProveedores}
                            >Guardar
                        </Button>
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<Cancel />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            onClick={onClickCancelar}
                            >Cancelar
                        </Button>
                    </div>
                </div>
            </div>
            }
              </>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
    </div>
  );
};

export default ProveedoresAuditor;