import { Table } from '@mui/joy';
import IconDownload from '../../../../../assets/media/icons/ico_download.svg';

const DocumentationContratosTable = (props: any) => {
    return (
        <Table borderAxis="none" className="style-table" stripe={'odd'}>
            <thead>
                <tr>
                    {props.tableHeaders.map((tableheader: any, index: number) => (
                        <th
                            key={index}
                            className={index > 0 ? 'cell-content-center' : ''}
                            style={{
                                width: props.widthColumns(index),
                                backgroundColor: "#E1E1E1",
                                borderTopLeftRadius: index === 0 ? "10px" : "auto",
                                borderBottomLeftRadius: index === 0 ? "10px" : "auto",
                                borderTopRightRadius: index === 4 ? "10px" : "auto",
                                borderBottomRightRadius: index === 4 ? "10px" : "auto"
                            }}
                        >
                            {tableheader}
                        </th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {props.tableData.map((data: any, index: number) => (
                    <tr key={index}>
                        <td>
                            <div>{data.genericA_DESCM_CAMPO}</div>
                        </td>
                        <td className='cell-content-center'>
                            <img className='cursor-pointer' onClick={event => props.handleClick(event, data)} src={IconDownload} />
                        </td>
                    </tr>
                ))}
            </tbody>
        </Table>
    );
};

export default DocumentationContratosTable;