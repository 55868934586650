import { FormControl, FormLabel, Input } from "@mui/joy";

const obligatorioStyle = {
    color: "red",
    marginLeft: '5px'
};

export const NumberInput = (props: any) => {
    return (
        <FormControl>
            {props.title !== undefined && (
                <FormLabel>{props.title} {(props.required === undefined || props.required === true) && <span style={obligatorioStyle}>*</span>} </FormLabel>
            )}
            <Input
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(event: any) => {

                    const newValue = event.target.value;
                    const validation = /^\d*$/.test(newValue);

                    if (props.longNumber !== undefined) {
                        if (props.longNumber < newValue.length) {
                            event.preventDefault();
                            return;
                        }
                    }

                    if (!validation) {
                        event.preventDefault();
                        return;
                    }

                    props.onChange(event);
                    
                }}
                disabled={props.disabled}
                startDecorator={props.startDecorator}
            />
        </FormControl>
    );
};

export const SimpleInput = (props: any) => {
    return (
        <FormControl>
            {props.title !== undefined && (
                <FormLabel>{props.title} {(props.required === undefined || props.required === true) && <span style={obligatorioStyle}>*</span>} </FormLabel>
            )}
            <Input
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
                startDecorator={props.startDecorator}
            />
        </FormControl>
    );
};
