import { useContext, useEffect, useState } from "react";
import { StepsContext } from "../../../3.10.-base/providers/StepsProvider";
import TitleCard from "../../../3.40.-SolicitudeStepOne/components/elements/titleCard";
import { SelectAutocompleteInput, SelectInput, SimpleInput } from "../../../3.40.-SolicitudeStepOne/components/elements/inputs";
import { StepFiveContext } from "../../../3.10.-base/providers";
import { DatosAdicionalesConyugeInterface, DatosAdicionalesConyugeInterfaceDefault } from "./interfaces";
import { appConfigKey } from "../../../../../../config/Config";

const DatosAdicionalesConyugeCard = () => {
    const {
        listPais,
        listNivelEducacion,
        listProfesion,
        clickNext,
        step,
        viewModelStepOneFinal,
        readonlyForm,
        viewModelStepFiveFinal
    } = useContext(StepsContext);

    const {
        setNewDatosAdicionalesConyugeData,
    } = useContext(StepFiveContext);

    const [viewModel, setViewModel] = useState<DatosAdicionalesConyugeInterface>(DatosAdicionalesConyugeInterfaceDefault);

    useEffect(() => {
        if (viewModelStepOneFinal !== null && viewModelStepFiveFinal === null) {
            setViewModel({
                ...viewModel,
                identNacionalidad: viewModelStepOneFinal.newConyugeData.NacionalidadId,
                identNivelEducacion: viewModelStepOneFinal.newConyugeData.NivelEstudioId,
                correo: viewModelStepOneFinal.newConyugeData.Correo,
                identProfesion: viewModelStepOneFinal.newConyugeData.ProfesionId,
            });
        }

    }, [viewModelStepOneFinal]);

    useEffect(() => {
        if (viewModelStepFiveFinal !== null) {
            const { newDatosAdicionalesConyugeData } = viewModelStepFiveFinal;
            setViewModel(newDatosAdicionalesConyugeData);
        }
    }, [viewModelStepFiveFinal]);

    useEffect(() => {
        if (clickNext !== 0 && step === 5) {
            setNewDatosAdicionalesConyugeData(viewModel);
        }
    }, [clickNext]);

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
            <TitleCard title={`DATOS DEL ${viewModelStepOneFinal.newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ? "CÓNYUGE" : "CONVIVIENTE"}`} obligatorio={false}/>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectAutocompleteInput
                        name={"identNacionalidad"}
                        title={"Nacionalidad"}
                        options={listPais}
                        value={viewModel.identNacionalidad}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identNacionalidad: newValue })}
                        required={false}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identNivelEducacion"}
                        title={"Nivel de educación"}
                        options={listNivelEducacion}
                        value={viewModel.identNivelEducacion}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identNivelEducacion: newValue })}
                        required={false}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"correo"}
                        title={"Correo"}
                        placeholder=""
                        value={viewModel.correo}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, correo: event.target.value })}
                        required={false}
                    />
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identProfesion"}
                        title={"Profesión"}
                        options={listProfesion}
                        value={viewModel.identProfesion}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identProfesion: newValue })}
                        required={false}
                    />
                </div>
            </div>
        </div>
    );
};

export default DatosAdicionalesConyugeCard;