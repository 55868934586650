import React, { useEffect, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { Button, Table } from "@mui/joy";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { IconButton, Tooltip } from "@mui/material";
import GeneralHelper from "../../../helpers/GeneralHelper";
import { DateInput, NumberInput, SelectInput, SimpleInput, TextInput } from "../15.10.-base/elements/inputs";
import { ObtenerListas } from "../15.10.-base/obtenerServicios";
import { InformeComercialPersona, InformeComercialPersonaDefault } from "../15.10.-base/interfaces";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SimbolAdd from "@mui/icons-material/Add";
import Save from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";
import { appConfigTipoPersonaTercera, appDefaultValues, appSecurityConfiguration } from "../../../../config/Config";
import TimeHelper from "../../../helpers/TimeHelper";
import RequestManager from "../../../services/origination/wapirequest.service";
import { Dropdown } from "react-bootstrap";
import CustomDivToggle from "../15.10.-base/elements/customDivToggle/CustomDivToggle";
import { BsThreeDots, BsSearch } from "react-icons/bs";
import { toast } from "sonner";
import { AxiosError } from "axios";

export const DatosDirectorio = (props: any) => {
  const [listTipoDocumento, setListTipoDocumento] = useState<any[]>([]);
  const [listPaises, setListPaises] = useState<any[]>([]);
  const [listEstadoCivil, setListEstadoCivil] = useState([]);
  const [listProfesion, setListProfesion] = useState<any[]>([]);
  const [listGenero, setListGenero] = useState([]);
  const [dataDirectorio, setDataDirectorio] = useState<InformeComercialPersona>(InformeComercialPersonaDefault);
  const [dataListDirectorio, setDataListDirectorio] = useState<InformeComercialPersona[]>([]);
  const [chkRequerido, setChkRequerido] = React.useState<boolean>(false);
  const [flgShow, setFlgShow] = useState<boolean>(false);
  const [longNumber, setLongNumber] = useState<any>(8);
  
  const InitListas = async () => {
      await ObtenerListas.TipoDocumento().then(response => {
          setListTipoDocumento(response);
      });
      await ObtenerListas.Pais().then(response =>{
          setListPaises(response)
      });
      await ObtenerListas.EstadoCiviles().then(response =>{
          setListEstadoCivil(response)
      });
      await ObtenerListas.Profesion().then(response =>{
          setListProfesion(response)
      });
      await ObtenerListas.IdentidadesGenero().then(response =>{
        setListGenero(response);
    });
  };
  const onClickNuevo = () => {
      setFlgShow(true);
      setDataDirectorio(InformeComercialPersonaDefault);
  }
  const onClickCancelar = () => {
      setFlgShow(false);
      setDataDirectorio(InformeComercialPersonaDefault);
  }
  const GuardarDirectorio = async () => {
      let resultado;
      let bodyInsert = {
          "identInformeComercial": dataDirectorio.identInformeComercial == 0 ? props.dataInformeComercial.identInformeComercial : dataDirectorio.identInformeComercial,
          "identPersona": dataDirectorio.identPersona == null ? 0 : dataDirectorio.identPersona,
          "idTipoDocumento": dataDirectorio.idTipoDocumento,
          "descTipoDocumento": dataDirectorio.descTipoDocumento,
          "nroDocumento": dataDirectorio.nroDocumento,
          "rucPnn": dataDirectorio.rucPnn,
          "razonSocial": dataDirectorio.razonSocial,
          "nombres": dataDirectorio.nombres,
          "apellidoPaterno": dataDirectorio.apellidoPaterno,
          "apellidoMaterno": dataDirectorio.apellidoMaterno,
          "idEstadoCivil": dataDirectorio.idEstadoCivil,
          "descEstadoCivil": dataDirectorio.descEstadoCivil,
          "fechaNacimiento": dataDirectorio.fechaNacimiento,
          "idGenero": dataDirectorio.idGenero,
          "descGenero": dataDirectorio.descGenero,
          "idPaisNacimiento": dataDirectorio.idPaisNacimiento,
          "descPaisNacimiento": dataDirectorio.descPaisNacimiento,
          "idPaisResidencia": dataDirectorio.idPaisResidencia,
          "descPaisResidencia": dataDirectorio.descPaisResidencia,
          "idDistrito": dataDirectorio.idDistrito,
          "descDistrito": dataDirectorio.descDistrito,
          "direccion": dataDirectorio.direccion,
          "telefono1": dataDirectorio.telefono1,
          "telefono2": dataDirectorio.telefono2,
          "correo": dataDirectorio.correo,
          "idProfesion": dataDirectorio.idProfesion,
          "descProfesion": dataDirectorio.descProfesion,
          "idCargo": dataDirectorio.idCargo,
          "descCargo": dataDirectorio.descCargo,
          "idTipoPersonaInforme": appConfigTipoPersonaTercera.PersonaDirectorio,
          "descTipoPersonaInforme": appConfigTipoPersonaTercera.DescPersonaDirectorio,
          // "aporteSoles": dataDirectorio.aporteSoles,
          // "aportePorcentaje": dataDirectorio.aportePorcentaje,
          "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
      }
      let bodyUpdate = {
          "identInformeComercialPersona": dataDirectorio.identInformeComercialPersona,
          "identInformeComercial": dataDirectorio.identInformeComercial == 0 ? props.dataInformeComercial.identInformeComercial : dataDirectorio.identInformeComercial,
          "identPersona": dataDirectorio.identPersona == null ? 0 : dataDirectorio.identPersona,
          "idTipoDocumento": dataDirectorio.idTipoDocumento,
          "descTipoDocumento": dataDirectorio.descTipoDocumento,
          "nroDocumento": dataDirectorio.nroDocumento,
          "rucPnn": dataDirectorio.rucPnn,
          "razonSocial": dataDirectorio.razonSocial,
          "nombres": dataDirectorio.nombres,
          "apellidoPaterno": dataDirectorio.apellidoPaterno,
          "apellidoMaterno": dataDirectorio.apellidoMaterno,
          "idEstadoCivil": dataDirectorio.idEstadoCivil,
          "descEstadoCivil": dataDirectorio.descEstadoCivil,
          "fechaNacimiento": dataDirectorio.fechaNacimiento,
          "idGenero": dataDirectorio.idGenero,
          "descGenero": dataDirectorio.descGenero,
          "idPaisNacimiento": dataDirectorio.idPaisNacimiento,
          "descPaisNacimiento": dataDirectorio.descPaisNacimiento,
          "idPaisResidencia": dataDirectorio.idPaisResidencia,
          "descPaisResidencia": dataDirectorio.descPaisResidencia,
          "idDistrito": dataDirectorio.idDistrito,
          "descDistrito": dataDirectorio.descDistrito,
          "direccion": dataDirectorio.direccion,
          "telefono1": dataDirectorio.telefono1,
          "telefono2": dataDirectorio.telefono2,
          "correo": dataDirectorio.correo,
          "idProfesion": dataDirectorio.idProfesion,
          "descProfesion": dataDirectorio.descProfesion,
          "idCargo": dataDirectorio.idCargo,
          "descCargo": dataDirectorio.descCargo,
          "idTipoPersonaInforme": appConfigTipoPersonaTercera.PersonaDirectorio,
          "descTipoPersonaInforme": appConfigTipoPersonaTercera.DescPersonaDirectorio,
          // "aporteSoles": dataDirectorio.aporteSoles,
          // "aportePorcentaje": dataDirectorio.aportePorcentaje,
          "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
      }

      try {
        const response = dataDirectorio.identInformeComercialPersona == 0 ? 
        await RequestManager.MnInformeComercialPersonaRegistroInsert(bodyInsert) : 
        await RequestManager.MnInformeComercialPersonaRegistroUpdate(bodyUpdate);
        if (response.status === 200) {
          resultado = response.data.isValid;
          if (resultado === true) {
            if(dataDirectorio.identInformeComercialPersona == 0)
                {toast.success("Registro guardado correctamente", { duration: 5000 });}
            else{toast.success("Registro actualizado correctamente.", { duration: 5000 });}
          }
          else{
            toast.error("Ocurrió un error", { duration: 5000 });
          }
        };
        CargaInicial();

        setFlgShow(false);
        setDataDirectorio(InformeComercialPersonaDefault);        
      } catch (error: AxiosError | any) {            
        if (error.response && error.response.data.status === 400 && error.response.data.errors) {
            const validationErrors = error.response.data.errors;
            if (validationErrors) {
                for (const [field, messages] of Object.entries(validationErrors)) {                            
                    let message = messages as string[]; // Type assertion
                    toast.error(`${field}: ${message}`, { duration: 5000 });
                }
            } else {
                toast.error("Ocurrió un error", { duration: 5000 });
            }
        }
    }           
}

  const CargaInicial = async () => {
      const response = await RequestManager.GetInformeComercialPersonaRegistro({ "identInformeComercial": props.dataInformeComercial.identInformeComercial, 
                                                                                 "idTipoPersonaInforme": appConfigTipoPersonaTercera.PersonaDirectorio });
      if (response.status === 200) {
        setDataListDirectorio(response.data.content);
      }
  }

  const handleClickViewDataDirectorio = async (value: any) =>  {
      setFlgShow(true);
      const response = await RequestManager.GetMnInformeComercialPersonaRegistroById({ "identInformeComercialPersona": value });
      if (response.status === 200) {
          setDataDirectorio(response.data.content);
      }
  }

  const handleClickDeleteDirectorio = async (value: any) =>  {
      setFlgShow(false);
      setDataDirectorio(InformeComercialPersonaDefault);
      const response = await RequestManager.MnInformeComercialPersonaRegistroDelete({ "identInformeComercialPersona": value });
      if (response.status === 200) {
          console.log(response);
          setDataListDirectorio(response.data.content);
          CargaInicial();
      }
  }

  const changeValueTipoDocumento = (e: any) => {
      let id = e.target.value as number;
      let text = '';
      if (id > 0) {
        for (let i = 0; i < listTipoDocumento.length; i++) {
          if (listTipoDocumento[i].id === id) {
              text = listTipoDocumento[i].description;
              break;
          }
        }
      }
      setDataDirectorio({ ...dataDirectorio, idTipoDocumento: id, descTipoDocumento: text,nroDocumento: appDefaultValues.StringEmpty });
      if(id === 172 || id === 173){
        setLongNumber(12);
      }
      else if(id === 174){
        setLongNumber(20);
      }
      else{
        setLongNumber(8)
      }
  }

  const changeValuePaisNacimiento = (e: any) => {
      let id = e.target.value as number;
      let text = '';
      if (id > 0) {
        for (let i = 0; i < listPaises.length; i++) {
          if (listPaises[i].id === id) {
              text = listPaises[i].description;
              break;
          }
        }
      }
      setDataDirectorio({ ...dataDirectorio, idPaisNacimiento: id, descPaisNacimiento: text});
  }

  const changeValuePaisResidencia = (e: any) => {
      let id = e.target.value as number;
      let text = '';
      if (id > 0) {
        for (let i = 0; i < listPaises.length; i++) {
          if (listPaises[i].id === id) {
              text = listPaises[i].description;
              break;
          }
        }
      }
      setDataDirectorio({ ...dataDirectorio, idPaisResidencia: id, descPaisResidencia: text});
  }

  const changeValueCargo = (e: any) => {
    let id = e.target.value as number;
    let text = '';
    if (id > 0) {
      for (let i = 0; i < listProfesion.length; i++) {
        if (listProfesion[i].id === id) {
            text = listProfesion[i].description;
            break;
        }
      }
    }
    setDataDirectorio({ ...dataDirectorio, idCargo: id, descCargo: text});
  }
    
    useEffect(() => {
      CargaInicial();
      InitListas();
  }, [props.dataInformeComercial]);
    
    return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>III. DATOS DEL DIRECTORIO</AccordionSummary>
            <AccordionDetails>
              <>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets align-top">
                        <Table borderAxis="none" className="style-table align-top">
                            <thead>
                                <tr>
                                <th style={{ fontSize: "12px", width: "10%", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>TIPO DOCUMENTO</th>
                                <th style={{ fontSize: "12px", width: "10%", backgroundColor: "#E1E1E1", }}>NRO DOCUMENTO</th>
                                <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>RUC</th>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>NOMBRE O RAZÓN SOCIAL</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>APELLIDO PATERNO</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>APELLIDO MATERNO</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>PAÍS DE NACIMIENTO</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>PAÍS DE RESIDENCIA</th>
                                <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>CARGO</th>
                                <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>ESTADO</th>
                                <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>ACCIONES</th>
                                {/* <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>APORTE %</th> */}
                                </tr>
                            </thead>
                        <tbody>
                        {
                            dataListDirectorio.length > 0 && dataListDirectorio.map((row: any, index: number) => {
                            return (
                            <tr key={row.identInformeComercialPersona}>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descTipoDocumento}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.nroDocumento}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.rucPnn}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {(row.idTipoDocumento === 174 ? row.razonSocial : row.nombres)}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.apellidoPaterno}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.apellidoMaterno}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descPaisNacimiento}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descPaisResidencia}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descCargo}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.estado}</td>
                            {/* <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.AportePorcentaje}</td> */}
                            <td>
                                <Dropdown key={row.identInformeComercialPersona}>
                                  <Dropdown.Toggle
                                    as={CustomDivToggle}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <BsThreeDots />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                          onClick={(e: any) =>
                                            handleClickViewDataDirectorio(
                                              row.identInformeComercialPersona
                                            )
                                          }
                                        >
                                          Editar
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                          onClick={(e: any) =>
                                            handleClickDeleteDirectorio(
                                              row.identInformeComercialPersona
                                            )
                                          }
                                          disabled={props.isDisabled}
                                        >
                                          Eliminar
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                            </td>
                            </tr>
                                )
                            })
                        }
                        </tbody>
                        </Table>
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets bottom-right">
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<SimbolAdd />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            disabled={props.isDisabled}
                            onClick={onClickNuevo}>Nuevo
                        </Button>
                    </div>
                </div>        

                { flgShow && <div>     
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-2 cardObjets">
                    <FormLabel>Requerido</FormLabel>
                    <Switch
                      color={chkRequerido ? "success" : "neutral"}
                      checked={chkRequerido}
                      onChange={(event: any) => {
                        setChkRequerido(event.target.checked);
                      }}
                      disabled={props.isDisabled}
                      slotProps={{
                        track: {
                          children: (
                            <React.Fragment>
                              <Typography
                                component="span"
                                level="inherit"
                                sx={{ ml: "10px" }}
                              >
                                Si
                              </Typography>
                              <Typography
                                component="span"
                                level="inherit"
                                sx={{ mr: "8px" }}
                              >
                                No
                              </Typography>
                            </React.Fragment>
                          ),
                        },
                      }}
                      sx={{
                        "--Switch-thumbSize": "27px",
                        "--Switch-trackWidth": "64px",
                        "--Switch-trackHeight": "31px",
                      }}
                    />
                  </div>
                  <div className="col-lg-2 cardObjets">
                    <SelectInput
                        title={"Tipo de documento"}
                        options={listTipoDocumento}
                        value={dataDirectorio.idTipoDocumento}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          changeValueTipoDocumento(event);
                        }}
                    />
                  </div>
                  {(dataDirectorio.idTipoDocumento === 172 || dataDirectorio.idTipoDocumento === 173) ?
                        <div className="col-lg-2 cardObjets">
                            <SimpleInput
                                title={"Nro. documento"}
                                placeholder=""
                                longNumber={longNumber}
                                disabled={!chkRequerido || props.isDisabled}
                                required={true}
                                value={dataDirectorio.nroDocumento.trim()}
                                onChange={(event: any) => {
                                    setDataDirectorio({ ...dataDirectorio, nroDocumento: event.target.value});
                                }}
                        />
                        </div>
                        :
                        <div className="col-lg-2 cardObjets">
                        <NumberInput
                            title={"Nro. documento"}
                            placeholder=""
                            longNumber={longNumber}
                            disabled={!chkRequerido || props.isDisabled}
                            required={true}
                            value={dataDirectorio.nroDocumento.trim()}
                            onChange={(event: any) => {
                                setDataDirectorio({ ...dataDirectorio, nroDocumento: event.target.value});
                            }}
                        />
                        </div>
                    }
                  <div className="col-lg-2 cardObjets">
                    <NumberInput
                        title={"RUC PNN"}
                        placeholder=""
                        value={dataDirectorio.rucPnn}
                        longNumber={20}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          setDataDirectorio({ ...dataDirectorio, rucPnn: event.target.value });
                      }}
                    />
                  </div>
                  <div className="col-lg-4 cardObjets">
                    <TextInput
                        title={"Nombre / Razón Social"}
                        placeholder="Ingresar nombre"
                        value={dataDirectorio.idTipoDocumento === 174 ? dataDirectorio.razonSocial : dataDirectorio.nombres}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                                if(dataDirectorio.idTipoDocumento === 174){
                                  setDataDirectorio({ ...dataDirectorio, razonSocial: event.target.value, nombres: "-"});
                                }
                                else{
                                  setDataDirectorio({ ...dataDirectorio, nombres: event.target.value, razonSocial: "-"});
                                }
                            }}
                    />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-4 cardObjets">
                    <TextInput
                        title={"Apellido Paterno"}
                        placeholder="Ingresar apellido"
                        value={dataDirectorio.apellidoPaterno}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          setDataDirectorio({ ...dataDirectorio, apellidoPaterno: event.target.value });
                      }}
                    />
                  </div>
                  <div className="col-lg-4 cardObjets">
                    <TextInput
                        title={"Apellido Materno"}
                        placeholder="Ingresar apellido"
                        value={dataDirectorio.apellidoMaterno}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          setDataDirectorio({ ...dataDirectorio, apellidoMaterno: event.target.value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2 cardObjets">
                    <SelectInput
                        title={"Estado Civil"}
                        options={listEstadoCivil}
                        value={dataDirectorio.idEstadoCivil}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          setDataDirectorio({ ...dataDirectorio, idEstadoCivil: event.target.value });
                      }}
                    />
                  </div>
                  <div className="col-lg-2 cardObjets">
                    <DateInput
                        title={"Fecha de nacimiento"}
                        value={dataDirectorio.fechaNacimiento}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(date: any | null) => setDataDirectorio({ ...dataDirectorio, fechaNacimiento: date === null ? '' : TimeHelper.ObtenerFormatoFechaAmericanaDayJs(date) })}
                    />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-4 cardObjets">
                    <SelectInput
                        title={"Género"}
                        options={listGenero}
                        value={dataDirectorio.idGenero}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          setDataDirectorio({ ...dataDirectorio, idGenero: event.target.value});
                      }}
                    />
                  </div>
                  <div className="col-lg-4 cardObjets">
                    <SelectInput
                        title={"País de nacimiento"}
                        options={listPaises}
                        value={dataDirectorio.idPaisNacimiento}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          changeValuePaisNacimiento(event);
                      }}
                    />
                  </div>
                  <div className="col-lg-4 cardObjets">
                    <SelectInput
                        title={"País de residencia"}
                        options={listPaises}
                        value={dataDirectorio.idPaisResidencia}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          changeValuePaisResidencia(event);
                      }}
                    />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        title={"Dirección"}
                        placeholder="Ejm. Av. La Marina"
                        value={dataDirectorio.direccion}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          setDataDirectorio({ ...dataDirectorio, direccion: event.target.value});
                      }}
                    />
                  </div>
                  <div className="col-lg-2 cardObjets">
                    <NumberInput
                        title={"Teléfono 1"}
                        placeholder=""
                        longNumber={9}
                        required={true}
                        value={dataDirectorio.telefono1}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          setDataDirectorio({ ...dataDirectorio, telefono1: event.target.value});
                      }}
                    />
                  </div>
                  <div className="col-lg-2 cardObjets">
                    <NumberInput
                        title={"Teléfono 2"}
                        placeholder=""
                        longNumber={9}
                        required={true}
                        value={dataDirectorio.telefono2}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          setDataDirectorio({ ...dataDirectorio, telefono2: event.target.value});
                      }}
                    />
                  </div>
                  <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        title={"Correo"}
                        placeholder="Ingresar email"
                        value={dataDirectorio.correo}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          setDataDirectorio({ ...dataDirectorio, correo: event.target.value});
                      }}
                    />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-4 cardObjets">
                      <SelectInput
                          title={"Profesión"}
                          options={listProfesion}
                          value={dataDirectorio.idProfesion}
                          disabled={!chkRequerido || props.isDisabled}
                          onChange={(event: any) => {
                            setDataDirectorio({ ...dataDirectorio, idProfesion: event.target.value});
                        }}
                      />
                  </div>
                  <div className="col-lg-4 cardObjets">
                    <SelectInput
                        title={"Cargo"}
                        options={listProfesion}
                        value={dataDirectorio.idCargo}
                        disabled={!chkRequerido || props.isDisabled}
                        onChange={(event: any) => {
                          changeValueCargo(event);
                    }}
                    />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center ">
                    <div className="col-lg-12 cardObjets bottom-right">
                            <Button
                                size="md"
                                color="neutral"
                                variant="outlined"
                                endDecorator={<Save />}
                                sx={{ borderRadius: 24, width: 1/8 }}
                                onClick={GuardarDirectorio}
                                disabled={!chkRequerido || props.isDisabled}
                                >Guardar
                            </Button>
                            <Button
                                size="md"
                                color="neutral"
                                variant="outlined"
                                endDecorator={<Cancel />}
                                sx={{ borderRadius: 24, width: 1/8 }}
                                // disabled={!chkRequerido}
                                onClick={onClickCancelar}
                                >Cancelar
                            </Button>
                    </div>
                </div>
                
                </div>  }   
              </>
            </AccordionDetails>
        </Accordion>
      </AccordionGroup>


   
    </div>
  );
};

export default DatosDirectorio;