import { Table } from "@mui/joy";

export const HistorialRevision = (props: any) => {

return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-4 textLabelHeadboard">
          <span className="fw-bold">HISTORIAL DE RESULTADO</span>
        </div>
        <div className="col-lg-4">
          <span className="fw-bold"> </span>
        </div>
        <div className="col-lg-4 textAlignRigth"></div>
      </div>
  
      <div className="row g-2 mb-2 align-items-center">
         <div className="col-lg-12 tableContainer-resultado align-top">
          <Table borderAxis="none" className="style-table" stripe={'odd'}
            sx={{
              "--TableCell-headBackground": "transparent",
              "--TableCell-selectedBackground": (theme) =>
                theme.vars.palette.primary.softBg,
              "& thead th:nth-child(1)": { width: "18%" },
              "& thead th:nth-child(2)": { width: "10%" },
              "& thead th:nth-child(3)": { width: "13%" },
              "& thead th:nth-child(4)": { width: "13%" },
              "& thead th:nth-child(5)": { width: "13%" },
              "& thead th:nth-child(6)": { width: "20%" },
              "& tr > *:nth-child(n+3)": {},
            }}>
           <thead>
            <tr>
             <th style={{ backgroundColor: "#E1E1E1"}}>FECHA / HORA</th>
             <th style={{ backgroundColor: "#E1E1E1"}}>USUARIO</th>
             {/* <th style={{ backgroundColor: "#E1E1E1", }}>ETAPA</th> */}
             <th style={{ backgroundColor: "#E1E1E1"}}>RESULTADO</th>
             <th style={{ backgroundColor: "#E1E1E1"}}>TIPO RESULTADO</th>
             <th style={{ backgroundColor: "#E1E1E1"}}>COMENTARIO</th>
            </tr>
           </thead>
           <tbody>
           {
           props.tableData.map((row: any) => {
            return (
             <tr key={row.idenT_RESULTADO_REVISION}>
              <td>{row.fecH_CREACION}</td>
              <td>{row.usuario}</td>
              {/* <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}></td> */}
              <td>{row.resultado}</td>
              <td>{row.tipO_RESULTADO}</td>
              <td>{row.comentario}</td>
            </tr>
            )
           })
          }
           </tbody>
          </Table>
         </div>
        </div>
    </div>
  );
  };