import { useEffect, useState } from "react";
import './styles.scss';
import MasterManager from "../../../../../services/origination/wapimaster.service";
import { TMnSolicitudTrackingRequest } from "../../../../../models/MasterManager/solicitud-tracking-response.model";
import { toast } from "sonner";
import { Paper } from "@mui/material";

const ListItem = (props: any) => {
    return (
        <div className="tranking-list-content">
            <div className="tranking-list-circulos">
                <div>
                    <div className="tranking-list-container-circulo">
                        <div className="tranking-list-container-circulo-forma" style={{ backgroundColor: props.item.color }}>
                            {props.indice + 1}
                        </div>
                        {props.indice !== props.max && (
                            <div className="tranking-list-content-endline-color"></div>
                        )}
                    </div>
                </div>
                <div>
                    <div className="tranking-list-container-content">
                        <div><strong className={props.item.status === 1 ? 'tranking-list-container-content-title-disable' : ''}>{props.item.titulo}</strong></div>
                        <div>{props.item.content}</div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const TrakingList = (props: any) => {
    useEffect(() => {
        GetMnSolicitudTracking();
    }, []);

    const GetMnSolicitudTracking = async () => {
        var response = MasterManager.GetMnSolicitudTracking({ idenT_SOLICITUD: props.IdSolicitud } as TMnSolicitudTrackingRequest).then(resp => {
            if (!resp?.data.isValid) {
                toast.error("Error al leer el tracking de la solicitud", { duration: 4000 });
            }
            var dato = resp?.data.content.map((value, index) => {
                var indexFirst = resp?.data.content.length - 1;
                //var status = indexLast==index?1:0;
                var color = index > 0 ? '#C8C8C8' : '#EC0000';

                return {
                    status: 1,
                    color: color,
                    titulo: value.desC_PERFIL,
                    content: <div>Usuario: <span style={{ color: '#0089B5' }}>{value.desC_USUARIO_CREADOR}</span></div>
                }
            });
            setItems(dato as any);
        }).catch(e => {
            toast.error("Error al leer el tracking de la solicitud", { duration: 4000 });
        });

    }



    // const [items, setItems] = useState([
    //     { status: 0, color: '#EC0000', titulo: 'Ejecutivo', content: <div>Usuario: <span style={{ color: '#0089B5' }}>jvelez</span></div> },
    //     { status: 0, color: '#C8C8C8', titulo: 'PLAFT', content: <div>Observado</div> },
    //     { status: 1, color: '#C8C8C8', titulo: 'Analista', content: <></> },
    //     { status: 1, color: '#C8C8C8', titulo: 'Gestor', content: <></> },
    //     { status: 1, color: '#C8C8C8', titulo: 'BPO', content: <></> },
    //     { status: 1, color: '#C8C8C8', titulo: 'Desembolso', content: <></> },
    // ]);

    const [items, setItems] = useState([]);

    return (
        <Paper style={{ maxHeight: 480, padding: 6, overflow: 'auto' }}>
            {items.map((x, index) => {
                return <ListItem item={x} key={index} indice={index} max={items.length - 1} />;
            })}
        </Paper>
    );

};

export default TrakingList;