import { appDefaultValues } from "../../../../config/Config";
import { BTCrearPersonaJuridicaDatoAdicional } from "./BTCrearPersonaJuridicaDatoAdicional";

export class BTCrearPersonaJuridicaOtrosDatos {
    private SdtJSPSsBTDatoAdicional: BTCrearPersonaJuridicaDatoAdicional[];

    constructor() {
        this.SdtJSPSsBTDatoAdicional = appDefaultValues.ArrayDefault;

    }

    public getSdtJSPSsBTDatoAdicional(): BTCrearPersonaJuridicaDatoAdicional[] {
        return this.SdtJSPSsBTDatoAdicional;
    }

    public setSdtJSPSsBTDatoAdicional(SdtJSPSsBTDatoAdicional: BTCrearPersonaJuridicaDatoAdicional[]): void {
        this.SdtJSPSsBTDatoAdicional = SdtJSPSsBTDatoAdicional;
    }
}