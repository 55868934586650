import React, { useEffect, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { Button, Table } from "@mui/joy";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { IconButton, Tooltip } from "@mui/material";
import GeneralHelper from "../../../helpers/GeneralHelper";
import { NumberInput, SelectInput, SimpleInput, TextInput } from "../15.10.-base/elements/inputs";
import { ObtenerListas } from "../15.10.-base/obtenerServicios";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SimbolAdd from "@mui/icons-material/Add";
import Save from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";
import { appConfigSolicitudEtapa, appDefaultValues, appSecurityConfiguration } from "../../../../config/Config";
import TimeHelper from "../../../helpers/TimeHelper";
import { DatosProduccion, DatosProduccionDefault } from "../15.10.-base/interfaces";
import RequestManager from "../../../services/origination/wapirequest.service";
import { Dropdown } from "react-bootstrap";
import CustomDivToggle from "../15.10.-base/elements/customDivToggle/CustomDivToggle";
import { BsThreeDots, BsSearch } from "react-icons/bs";
import { toast } from "sonner";
import { AxiosError } from "axios";

export const Produccion = (props: any) => {
  const [chkRequerido, setChkRequerido] = React.useState<boolean>(false);
  const [listTipoEmpresa, setListTipoEmpresa] = useState<any[]>([]);
  const [dataProduccion, setDataProduccion] = useState<DatosProduccion>(DatosProduccionDefault);
  const [dataListProduccion, setDataListProduccion] = useState<DatosProduccion[]>([]);  
  const [flgShow, setFlgShow] = useState<boolean>(false);
  
  const onClickNuevo = () => {
    setFlgShow(true);
    setDataProduccion(DatosProduccionDefault);
  }

  const handleClickViewDataProduccion = async (value: any) =>  {
    setFlgShow(true);
    const response = await RequestManager.GetMnInformeComercialProduccionRegistroById({ "identInformeComercialProduccion": value });
    if (response.status === 200) {
        setDataProduccion(response.data.content);
    }
}

const handleClickDeleteProduccion = async (value: any) =>  {
    setFlgShow(false);
    setDataProduccion(DatosProduccionDefault);
    const response = await RequestManager.MnInformeComercialProduccionRegistroDelete({ "identInformeComercialProduccion": value });
    if (response.status === 200) {
        console.log(response);
        setDataProduccion(response.data.content);
        CargaInicial();
    }
}

const CargaInicial = async () => {
    const response = await RequestManager.GetInformeComercialProduccionRegistro({ "identInformeComercial": props.dataInformeComercial.identInformeComercial });
    if (response.status === 200) {
        setDataListProduccion(response.data.content);
    }
}

const onClickCancelar = () => {
    setFlgShow(false);
    setDataProduccion(DatosProduccionDefault);
}

const GuardarProduccion = async() => {
    let resultado;
    let bodyInsert = {
        "identInformeComercial": dataProduccion.identInformeComercial == 0 ? props.dataInformeComercial.identInformeComercial : dataProduccion.identInformeComercial,
        "productoLineas": dataProduccion.productoLineas,
        "porcentajeActividad": dataProduccion.porcentajeActividad,
        "porcentajeMercado": dataProduccion.porcentajeMercado,
        "empresaCompetidora": dataProduccion.empresaCompetidora,
        "idTipoEmpresa": dataProduccion.idTipoEmpresa,
        "descTipoEmpresa": dataProduccion.descTipoEmpresa,
        "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
    }
    let bodyUpdate = {
        "identInformeComercialProduccion": dataProduccion.identInformeComercialProduccion,
        "identInformeComercial": dataProduccion.identInformeComercial,
        "productoLineas": dataProduccion.productoLineas,
        "porcentajeActividad": dataProduccion.porcentajeActividad,
        "porcentajeMercado": dataProduccion.porcentajeMercado,
        "empresaCompetidora": dataProduccion.empresaCompetidora,
        "idTipoEmpresa": dataProduccion.idTipoEmpresa,
        "descTipoEmpresa": dataProduccion.descTipoEmpresa,
        "identUsuarioModificador": String(appSecurityConfiguration.SessionUserId)
    }
    try {

      const response = dataProduccion.identInformeComercialProduccion == 0 ? 
      await RequestManager.MnInformeComercialProduccionRegistroInsert(bodyInsert) : 
      await RequestManager.MnInformeComercialProduccionRegistroUpdate(bodyUpdate);

      if (response.status === 200) {
      resultado = response.data.isValid;
      if (resultado === true) {
        if(dataProduccion.identInformeComercialProduccion == 0)
            {toast.success("Registro guardado correctamente", { duration: 5000 });}
        else{toast.success("Registro actualizado correctamente.", { duration: 5000 });}
      }
      else{
        toast.error("Ocurrió un error", { duration: 5000 });
      }
      };
      CargaInicial();

      setFlgShow(false);
      setDataProduccion(DatosProduccionDefault);  
    } catch (error: AxiosError | any) {            
      if (error.response && error.response.data.status === 400 && error.response.data.errors) {
          const validationErrors = error.response.data.errors;
          if (validationErrors) {
              for (const [field, messages] of Object.entries(validationErrors)) {                            
                  let message = messages as string[]; // Type assertion
                  toast.error(`${field}: ${message}`, { duration: 5000 });
              }
          } else {
              toast.error("Ocurrió un error", { duration: 5000 });
          }
      }
  }           
}

const changeValueTipoEmpresa = (e: any) => {
    let id = e.target.value as number;
    let text = '';
    if (id > 0) {
      for (let i = 0; i < listTipoEmpresa.length; i++) {
        if (listTipoEmpresa[i].id === id) {
            text = listTipoEmpresa[i].description;
            break;
        }
      }
    }
    setDataProduccion({ ...dataProduccion, idTipoEmpresa: id, descTipoEmpresa: text });
}

const InitListas = async () => {
    await ObtenerListas.IdentidadesMagnitud().then(response => {
        setListTipoEmpresa(response);
    });
};

useEffect(() => {
    CargaInicial();
    InitListas();
}, [props]);
    
    return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>VII. PRODUCCION</AccordionSummary>
          <AccordionDetails>
            <>
              <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets align-top">
                        <Table borderAxis="none" className="style-table align-top">
                            <thead>
                                <tr>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>PRODUCTOS Y/O LÍNEAS DE PRODUCTOS</th>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>% DE LA ACTIVIDAD</th>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>% DE PART. MERCADO</th>
                                <th style={{ fontSize: "12px", width: "13%", backgroundColor: "#E1E1E1", }}>EMPRESAS COMPETIDORAS</th>
                                <th style={{ fontSize: "12px", width: "13%", backgroundColor: "#E1E1E1", }}>ESTADO</th>
                                <th style={{ fontSize: "12px", width: "13%", backgroundColor: "#E1E1E1", }}>ACCIONES</th>
                                </tr>
                            </thead>
                        <tbody>
                        {
                            dataListProduccion.length > 0 && dataListProduccion.map((row: any, index: number) => {
                            return (
                            <tr key={row.identInformeComercialProduccion}>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.productoLineas}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.porcentajeActividad}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.porcentajeMercado}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.empresaCompetidora}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.estado}</td>
                            <td>
                              <Dropdown key={row.identInformeComercialProduccion}>
                                <Dropdown.Toggle
                                  as={CustomDivToggle}
                                  style={{ cursor: "pointer" }}
                                >
                                  <BsThreeDots />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickViewDataProduccion(
                                            row.identInformeComercialProduccion
                                          )
                                        }
                                      >
                                        Editar
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickDeleteProduccion(
                                            row.identInformeComercialProduccion
                                          )
                                        }
                                        disabled={props.isDisabled}
                                        >
                                        Eliminar
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            </tr>
                            )
                            })
                        }
                        </tbody>
                        </Table>
                    </div>
                    <div className="row g-2 mb-2 align-items-center">
                        <div className="col-lg-12 cardObjets bottom-right">
                            <Button
                                size="md"
                                color="neutral"
                                variant="outlined"
                                endDecorator={<SimbolAdd />}
                                sx={{ borderRadius: 24, width: 1/8 }}
                                // disabled={props.disabled}
                                disabled={props.isDisabled}
                                onClick={onClickNuevo}>Nuevo
                            </Button>
                        </div>
                    </div>
              </div>
              {flgShow && <div>        
              <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-4 cardObjets">
                      <SimpleInput
                          title={"Productos y/o líneas de prod."}
                          placeholder="Ingresar"
                          value={dataProduccion.productoLineas}
                          // disabled={readonlyForm}
                          disabled={props.isDisabled}
                          onChange={(event: any) => setDataProduccion({ ...dataProduccion, productoLineas: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-2 cardObjets">
                      <NumberInput
                          title={"% de la actividad"}
                          placeholder="Ingresar"
                          value={dataProduccion.porcentajeActividad}
                          // disabled={readonlyForm}
                          disabled={props.isDisabled}
                          onChange={(event: any) => setDataProduccion({ ...dataProduccion, porcentajeActividad: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-2 cardObjets">
                      <NumberInput
                          title={"% part. mercado"}
                          placeholder="Ingresar"
                          value={dataProduccion.porcentajeMercado}
                          // disabled={readonlyForm}
                          disabled={props.isDisabled}
                          onChange={(event: any) => setDataProduccion({ ...dataProduccion, porcentajeMercado: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-4 cardObjets">
                      <SimpleInput
                          title={"Empresas competidoras"}
                          placeholder="Ingresar"
                          value={dataProduccion.empresaCompetidora}
                          // disabled={readonlyForm}
                          disabled={props.isDisabled}
                          onChange={(event: any) => setDataProduccion({ ...dataProduccion, empresaCompetidora: event.target.value })}
                      />
                  </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-4 cardObjets">
                      <SelectInput
                            title={"Tipo empresa"}
                            options={listTipoEmpresa}
                            value={dataProduccion.idTipoEmpresa}
                            // disabled={readonlyForm}
                            disabled={props.isDisabled}
                            onChange={(event: any) => {
                                changeValueTipoEmpresa(event);
                            }}
                      />
                  </div>
              </div>              
              <div className="row g-2 mb-2 align-items-center ">
                    <div className="col-lg-12 cardObjets bottom-right">
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<Save />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            onClick={GuardarProduccion}
                              // onClick={props.onClick}>{props.title}
                              disabled={props.isDisabled}
                              >Guardar
                        </Button>
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<Cancel />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            onClick={onClickCancelar}
                              // onClick={props.onClick}>{props.title}
                            >Cancelar
                        </Button>
                    </div>
                </div>
              </div>    
            }          
            </>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
    </div>
  );
};

export default Produccion;