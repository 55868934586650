import { appConfigKey } from "../../config/Config";

const ParameterHelper = {

    GetDocumentTypeName: (documentId: number): string => {
        let documentTypeName = ""
        switch (documentId) {
            case appConfigKey.keyIdDNI: {
                documentTypeName = "DNI"
                break;
            }
            case appConfigKey.keyIdRUC: {
                documentTypeName = "RUC"
                break;
            }
        }
        return documentTypeName;
    },

    GetSolicitudeIdentifier: (solicitudeId: any): string => {
        return "S" + `${solicitudeId}`.padStart(9, '0');
    },

    GetQuotationIdentifier: (quotationId: any): string => {
        return "C" + `${quotationId}`.padStart(9, '0');
    }

}

export default ParameterHelper;