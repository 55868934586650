import { appDefaultValues } from "../../../../../config/Config"

export interface DatosFinancierosInterface {
    EstadoVehiculoId: number,
    VehiculoYear: number,
    ConcesionarioId: number,
    Concesionario: string,
    SucursalId: number,
    Sucursal: string,
    VendedorId: number,
    Vendedor: string,
    VendedorEmail: string,
    MonedaId: number,
    TipoCambioBase: string,
    TipoCambio: string,
    MontoVehiculoBaseSoles: string,
    MontoVehiculoBaseDolares: string,
    MontoVehiculoSoles: string,
    MontoVehiculoDolares: string,
    InicialBase: string,
    Inicial: number,
    InicialValue: string,
    MontoInicialBaseDolares: string,
    MontoInicialBaseSoles: string,
    MontoInicialDolares: string,
    MontoInicialSoles: string,
    UsoVehiculoId: number,
    DestinoCreditoId: number,
    MarcaId: number,
    Marca: string,
    ModeloId: number,
    Modelo: string,
    VersionId: number,
    Version: string,
    MontoFinanciarBase: string,
    MontoFinanciarBaseDolares: string,
    MontoFinanciar: string,
    MontoFinanciarDolares: string,
    UsosVehiculo: string,
    DestinoCredito: string,
    AplicaBono: boolean,
    Segmento: string,
}

export const DatosFinancierosDefault: DatosFinancierosInterface = {
    EstadoVehiculoId: appDefaultValues.NumberDefault,
    VehiculoYear: appDefaultValues.NumberDefault,
    ConcesionarioId: appDefaultValues.NumberDefault,
    Concesionario: appDefaultValues.StringEmpty,
    SucursalId: appDefaultValues.NumberDefault,
    Sucursal: appDefaultValues.StringEmpty,
    VendedorId: appDefaultValues.NumberDefault,
    Vendedor: appDefaultValues.StringEmpty,
    VendedorEmail: appDefaultValues.StringEmpty,
    MonedaId: appDefaultValues.NumberDefault,
    TipoCambioBase: appDefaultValues.StringEmpty,
    TipoCambio: appDefaultValues.StringEmpty,
    MontoVehiculoBaseDolares: appDefaultValues.StringEmpty,
    MontoVehiculoBaseSoles: appDefaultValues.StringEmpty,
    MontoVehiculoDolares: appDefaultValues.StringEmpty,
    MontoVehiculoSoles: appDefaultValues.StringEmpty,
    InicialBase: appDefaultValues.StringEmpty,
    Inicial: appDefaultValues.NumberDefault,
    InicialValue: appDefaultValues.StringEmpty,
    MontoInicialBaseDolares: appDefaultValues.StringEmpty,
    MontoInicialBaseSoles: appDefaultValues.StringEmpty,
    MontoInicialDolares: appDefaultValues.StringEmpty,
    MontoInicialSoles: appDefaultValues.StringEmpty,
    UsoVehiculoId: appDefaultValues.NumberDefault,
    DestinoCreditoId: appDefaultValues.NumberDefault,
    MarcaId: appDefaultValues.NumberDefault,
    Marca: appDefaultValues.StringEmpty,
    ModeloId: appDefaultValues.NumberDefault,
    Modelo: appDefaultValues.StringEmpty,
    VersionId: appDefaultValues.NumberDefault,
    Version: appDefaultValues.StringEmpty,
    MontoFinanciarBase: appDefaultValues.StringEmpty,
    MontoFinanciarBaseDolares: appDefaultValues.StringEmpty,
    MontoFinanciar: appDefaultValues.StringEmpty,
    MontoFinanciarDolares: appDefaultValues.StringEmpty,
    UsosVehiculo: appDefaultValues.StringEmpty,
    DestinoCredito: appDefaultValues.StringEmpty,
    AplicaBono: true,
    Segmento: appDefaultValues.StringEmpty,
};