import { toast } from "sonner";
import { appConfigKey } from "../../../../../config/Config";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import { validarCorreo, validarFecha, validarFechaPosterior, validarSelect, validarText } from "../../../../helpers/ValidarHelper";

const ValidacionResultadosEvaluacionHelper = {


    NewReqResultadoEvaluacion: (reqResultadoEvaluacion: any) => {
        const {
            idenT_RESULTADO,
            idenT_DESTINO_CREDITO,
            comentario,
            idenT_TIPO_RESULTADO,
            idenT_CREDITO_TIPO,
            idenT_SUBTIPO,
            descc_DETALLE,
            numerO_VISOR
        } = reqResultadoEvaluacion;

        if (!validarSelect(idenT_RESULTADO, 'Resultado')) {
            return false;
        }

        if (!validarSelect(idenT_TIPO_RESULTADO, 'Tipo resultado')) {
            return false;
        }

        if (!validarSelect(idenT_DESTINO_CREDITO, "Destino crédito")) {
            return false;
        }

        if (!validarText(numerO_VISOR, "Número visor")) {
            return false;
        } else {
            if (Number(numerO_VISOR) <= 0) {
                toast.error(`El campo Número visor es requerido.`, { duration: appConfigKey.keyDurationToast });
                return false;
            }
        }

        if (!validarText(comentario, "Comentario")) {
            return false;
        }

        if (idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado) {

            if (!validarSelect(idenT_CREDITO_TIPO, "Tipo de crédito")) {
                return false;
            }

            if (!validarSelect(idenT_SUBTIPO, "Subtipo de crédito")) {
                return false;
            }

            if (!validarText(descc_DETALLE, "Detalle")) {
                return false;
            }
        }

        return true;
    },

    NewReevaluarTitular: (ingresoTitular: any) => {
        const {
            fechaIngresoLaboral,
            ingresoNeto,
            idcategoriaLaboral
        } = ingresoTitular;

        if (!validarText(ingresoNeto, "Ingreso neto del titular")) {
            return false;
        }

        if (idcategoriaLaboral !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado) {
            if (!validarFecha(fechaIngresoLaboral, `fecha ingreso laboral del titular`)) {
                return false;
            } else {
    
                let fechaIngLabDesglozado = fechaIngresoLaboral.split('/');
                let fechaIngLab = `${fechaIngLabDesglozado[2]}-${fechaIngLabDesglozado[1]}-${fechaIngLabDesglozado[0]}`;
                if (!validarFechaPosterior(fechaIngLab, `fecha ingreso laboral del titular`)) {
                    return false;
                }
            }
        }

        return true;
    },

    NewReevaluarConyuge: (ingresoConyuge: any) => {
        const {
            fechaIngresoLaboral,
            ingresoNeto
        } = ingresoConyuge;

        if (!validarText(ingresoNeto, "Ingreso neto del cónyuge")) {
            return false;
        }

        if (!validarFecha(fechaIngresoLaboral, `fecha ingreso laboral del cónyuge`)) {
            return false;
        } else {

            let fechaIngLabDesglozado = fechaIngresoLaboral.split('/');
            let fechaIngLab = `${fechaIngLabDesglozado[2]}-${fechaIngLabDesglozado[1]}-${fechaIngLabDesglozado[0]}`;
            if (!validarFechaPosterior(fechaIngLab, `fecha ingreso laboral del cónyuge`)) {
                return false;
            }
        }

        return true;
    },

    NewDatosCondicionFinanciamiento: (datosCondicionFinanciamiento: any, datosCondicionFinanciamientoOriginal: any) => {
        const {
            comentario,
            tea,
            teaString,
            cuotaInicial,
            porcentajeInicial
        } = datosCondicionFinanciamiento;

        if (!validarText(cuotaInicial, "Cuota inical")) {
            return false;
        }
        if (!validarText(porcentajeInicial, "Porcetaje Inicial")) {
            return false;
        }
        if (!validarText(teaString, "TEA")) {
            return false;
        }

        if (datosCondicionFinanciamientoOriginal.tea !== tea) {
            if (!validarText(comentario, "Comentario")) {
                return false;
            }
        }

        return true;
    },

    EsIgualDeudaOriginalVSModificada: (clasificacion: any,
        resultado: any) => {

        const {
            linea_disponible,
            linea_disponible_real
        } = clasificacion;

        const {
            tabla2,
        } = resultado;


        if (linea_disponible_real !== linea_disponible) {
            return false;
        }

        if (tabla2) {
            for (let index = 0; index < tabla2.length; index++) {

                /*if (tabla2[index].id) {
                    return false;
                }*/

                if (Number(tabla2[index].monto_deuda).toFixed(2) !== Number(tabla2[index].monto_deuda_real).toFixed(2)) {
                    return false;
                }

                if (Number(tabla2[index].cuota_fc).toFixed(2) !== Number(tabla2[index].cuota_fc_real).toFixed(2)) {
                    return false;
                }


            }
        }

        return true;
    },

    NewReevaluarOtrosIngresos: (otrosIngresos: any, tipoIntegrante: string) => {

        if (otrosIngresos) {
            for (let item of otrosIngresos) {
                if (!validarText(item.ingresoNeto, "ingreso neto en otros ingresos del " + tipoIntegrante)) {
                    return false;
                }

                if (!validarFecha(item.fechaIngresoLaboral, `fecha ingreso laboral en otros ingresos del ` + tipoIntegrante)) {
                    return false;
                } else {
                    let fechaIngLabDesglozado = item.fechaIngresoLaboral.split('/');
                    let fechaIngLab = `${fechaIngLabDesglozado[2]}-${fechaIngLabDesglozado[1]}-${fechaIngLabDesglozado[0]}`;
                    if (!validarFechaPosterior(fechaIngLab, `fecha ingreso laboral en otros ingresos del ` + tipoIntegrante)) {
                        return false;
                    }

                }
            }
        }

        return true;
    },

    DatosFinanciamientoIguales: (financiamiento: any, otroFinanciamiento: any, tipoCambio: number) => {

        let inicialDolares = financiamiento.idmoneda === appConfigKey.keyIdentificadorDolares ?
            financiamiento.cuotaInicial : (financiamiento.cuotaInicial / tipoCambio).toFixed(2);

        let otroInicialDolares = otroFinanciamiento.idmoneda === appConfigKey.keyIdentificadorDolares ?
            otroFinanciamiento.cuotaInicial : (otroFinanciamiento.cuotaInicial / tipoCambio).toFixed(2);

        if (inicialDolares != otroInicialDolares) return false;
        if (financiamiento.tea != otroFinanciamiento.tea) return false;
        if (financiamiento.cuota != otroFinanciamiento.cuota) return false;
        if (financiamiento.indicadorCuotaDoble != otroFinanciamiento.indicadorCuotaDoble) return false;

        return true;
    },

    NewReevaluarTitularIguales: (ingresoTitularAfter: any, ingresoTitularBeforce: any) => {

        if (ingresoTitularAfter.ingresoNeto !== ingresoTitularBeforce.ingresoNeto) return false;
        if (ingresoTitularAfter.fechaIngresoLaboral !== ingresoTitularBeforce.fechaIngresoLaboral) return false;

        return true;
    },

    NewReevaluarConyugeIguales: (ingresoConyugeAfter: any, ingresoConyugeBeforce: any) => {

        if (ingresoConyugeAfter.ingresoNeto !== ingresoConyugeBeforce.ingresoNeto) return false;
        if (ingresoConyugeAfter.fechaIngresoLaboral !== ingresoConyugeBeforce.fechaIngresoLaboral) return false;

        return true;
    },

    NewReevaluarOtrosIngresosIguales: (otrosIngresosAfter: any, otrosIngresosBeforce: any) => {

        let noTieneCambio: boolean = true;
        otrosIngresosAfter?.forEach((item: any, index: number) => {
            if (item.ingresoNeto !== otrosIngresosBeforce[index].ingresoNeto) {
                noTieneCambio = false;
            }
            if (item.fechaIngresoLaboral !== otrosIngresosBeforce[index].fechaIngresoLaboral) {
                noTieneCambio = false;
            }
        })

        return noTieneCambio;
    },

    NewDatosCondicionFinanciamientoIguales: (datosCondicionFinanciamientoAfter: any, datosCondicionFinanciamientoBeforce: any) => {
        if (datosCondicionFinanciamientoAfter.cuotaInicial !== datosCondicionFinanciamientoBeforce.cuotaInicial) {
            return false;
        }
        return true;
    },

    NewReevaluarClasificacion: (clasificacion: any, tipoIntegrante: string) => {
        const {
            linea_disponible,
            linea_disponible_real,
            comentario,
        } = clasificacion;

        if (linea_disponible !== linea_disponible_real) {
            if (!validarText(comentario, "comentario de nueva linea disponible del " + tipoIntegrante)) {
                return false;
            }
        }

        return true;
    },
};

export default ValidacionResultadosEvaluacionHelper;