import { Component } from "react";
import GesintelListDetail from "./GesintelListDetail";
import EmptyData from "../1.5.3.-empty-data/EmptyData";

interface IGesintelListProps {
  objGesintelListProps: {
    gesintelListData: any[];
    gesintelListDeltaData: any[];
    member: any;
  };
}

class GesintelList extends Component<IGesintelListProps, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const data = this.props.objGesintelListProps;
    return (
      <>
        {!data.gesintelListData || data.gesintelListData.length == 0 && (
          <EmptyData />
        )}
        {data.gesintelListData.length > 0 &&
          <div className="d-flex align-items-center">
            {
              data.gesintelListData.map(
                (item: any, i: number) => {
                  const deltaExists = data.gesintelListDeltaData.some(itemToCheck =>
                    itemToCheck.description === item.description
                  );
                  const showNew = !data.member?.liberadO_PLAFT || !data.member?.liberadO_RIESGOS;
                  return (
                    <GesintelListDetail
                      key={i}
                      objGesintelListDetailProps={{
                        index: i,
                        description: item.description,
                        showNew: deltaExists && showNew,
                      }}
                    ></GesintelListDetail>
                  );
                }
              )
            }
          </div>

        }
      </>
    );
  }
}
export default GesintelList;
