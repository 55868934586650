const TitleCard = (props: any) => {

  const mostrarObligatorio = () => {
    if (props.obligatorio == false) {
      return (<></>);
    }

    return (
      <div className="col-lg-4 textAlignRigth">
        <span className="">Obligatorio</span>
        <span style={{ color: "red" }}> * </span>
      </div>
    );
  };

  return (
    <div className="row g-2 mb-2 align-items-center">
      <div className="col-lg-4 textLabelHeadboard">
        <span className="fw-bold">{props.title}</span>
      </div>
      <div className="col-lg-4">
        <span className="fw-bold"> </span>
      </div>
      { mostrarObligatorio() }
    </div>
  );
};

export default TitleCard;
