
import { appDefaultValues } from "../../../../../../config/Config"

export interface DatosDetailsStepsTwoCard {
    precioVehiculo: string,
    cuotaInicial: string,
    tieneBono: boolean,
    MontoFinanciar: string,
    Concesionario: string,
    Marca: string,
    Segmento: string,
    InicialValor: string,
    Sucursal: string,
    Modelo: string,
    UltimoAnalista: string
};


export const DatosDetailsStepsTwoCardDefault: DatosDetailsStepsTwoCard = {

    precioVehiculo: appDefaultValues.StringEmpty,
    cuotaInicial: appDefaultValues.StringEmpty,
    tieneBono: appDefaultValues.BooleanDefault,
    MontoFinanciar: appDefaultValues.StringEmpty,
    Concesionario: appDefaultValues.StringEmpty,
    Marca: appDefaultValues.StringEmpty,
    Segmento: appDefaultValues.StringEmpty,
    InicialValor: appDefaultValues.StringEmpty,
    Sucursal: appDefaultValues.StringEmpty,
    Modelo: appDefaultValues.StringEmpty,
    UltimoAnalista: appDefaultValues.StringEmpty
};
