import { appDefaultValues } from '../../../config/Config';

export interface ResultadoEvaluacionResumen {
 periodoRCC: string,
 decision: string,
 detalleDecision: string,
 puntaje: number,
 segmento: number,
 segmentoDescripcion: string,
 codigoCMA: string,
 descripcionCMA: string,
 intervinienteCMA: string,
 interviniente: string,
 cuotaExterna: number,
 cuotaInterna: number, 
 descripcion: string,
 nivel_Riesgo: string,
 motivoDerivacion: string,

}

export const ResultadoEvaluacionResumenDefault: ResultadoEvaluacionResumen = {
 periodoRCC: appDefaultValues.StringEmpty,
 decision: appDefaultValues.StringEmpty,
 detalleDecision: appDefaultValues.StringEmpty,
 puntaje: appDefaultValues.NumberDefault,
 segmento: appDefaultValues.NumberDefault,
 segmentoDescripcion: appDefaultValues.StringEmpty,
 codigoCMA: appDefaultValues.StringEmpty,
 descripcionCMA: appDefaultValues.StringEmpty,
 intervinienteCMA: appDefaultValues.StringEmpty,
 interviniente: appDefaultValues.StringEmpty,
 cuotaExterna: appDefaultValues.NumberDefault,
 cuotaInterna: appDefaultValues.NumberDefault,
 descripcion: appDefaultValues.StringEmpty,
 nivel_Riesgo: appDefaultValues.StringEmpty,
 motivoDerivacion: appDefaultValues.StringEmpty

}