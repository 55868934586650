import MasterManager from "../../../services/origination/wapimaster.service";
import RequestManager from "../../../services/origination/wapirequest.service";
import ServicesProvider from "../../../services/support/wapiservicesprovider";

export const ObtenerListas = {

    ObtenerTipoDocumento: async () => {
        const lista: any = [];
        try {

            const response = await ServicesProvider.ObtenerTipoDocumento(null);
            if (response?.status !== 200) {
                return lista;
            }
            const { content } = response.data;

            if (content) {
                content.sdtTipoDocumentos.sdtsBTTipoDocumento.forEach((element: any) => {
                    lista.push({ id: element.identificador, description: element.descripcion });
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LISTA DE TIPO DOCUMENTO.');
        }
        return lista;
    },

    ObtenerIdPerfil: async (username: any) => {
        const lista: any = [];
        try {
            let request = {
                "email_INTERNO": username
            }
            const response: any = await MasterManager.GetMnUsuarios(request);
            if (response?.status !== 200) {
                return lista;
            }

            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }

        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTEBER PERFIL.');
            return false;
        }
        return lista;
    },

    ObtenerEstados: async () => {
        const lista: any = [];
        try {
            let data = {
                v_LIST_IDENT_TABLA: "1",
            };
            const response = await MasterManager.GetMnTablaGenericaDefinicion(data);
            if (response?.status !== 200) {
                return lista;
            }
            const { content } = response.data;

            if (content) {
                content.forEach((element: any) => {
                    lista.push({ id: element.genericA_IDENT_CAMPO, description: element.genericA_DESCM_CAMPO });
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LISTA DE ESTADOS.');
        }
        return lista;
    },

    ConsultaUsuarioGestionSolicitudes: async () => {
        const lista: any = [];
        try {
            let data = {
                v_LIST_IDENT_TABLA: "1",
            };
            const response = await RequestManager.GetConsultaUsuarioGestionSolicitudes();
            if (response?.status !== 200) {
                return lista;
            }
            const { content } = response.data;

            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA GESTION DE SOLICITUDES.');
        }
        return lista;
    },
    ObtenerEtapa: async () => {
        const lista: any = [];
        try {
            let data = {
                v_LIST_IDENT_TABLA: "37",
            };
            const response = await MasterManager.GetMnTablaGenericaDefinicion(data);
            if (response?.status !== 200) {
                return lista;
            }
            const { content } = response.data;

            if (content) {
                content.forEach((element: any) => {
                    lista.push({ id: element.genericA_VALO_CAMPO, description: element.genericA_DESCM_CAMPO });
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LISTA DE ETAPA.');
        }
        return lista;
    },

    GetConsultaUsuariosReasignarGestionSolicitud: async (etapa: number) => {
        const lista: any = [];
        try {
            let data = {
                etapa: etapa,
            };
            const response = await RequestManager.GetConsultaUsuariosReasignarGestionSolicitud(data);
            if (response?.status !== 200) {
                return lista;
            }
            const { content } = response.data;

            if (content) {
                content.forEach((element: any) => {
                    lista.push({ id: element.identUsuario, description: element.usuario });
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA CONSULTAR USUARIOS REASIGNAR GESTION DE SOLICITUDES.');
        }
        return lista;
    },

    GetConsultaBandejaGestionSolicitudes: async (
        identSolicitud: number,
        idTipoDocumento: number,
        codlNumeroDocumento: string,
        desclNombreCompleto: string,
        idEstadoSolicitud: number,
        idEtapa: number,
        identUsuario: number
    ) => {
        const lista: any = [];
        try {
            let data = {
                "identSolicitud": identSolicitud,
                "idTipoDocumento": idTipoDocumento,
                "codlNumeroDocumento": codlNumeroDocumento,
                "desclNombreCompleto": desclNombreCompleto,
                "idEstadoSolicitud": idEstadoSolicitud,
                "idEtapa": idEtapa,
                "identUsuario": identUsuario
            };
            const response = await RequestManager.GetConsultaBandejaGestionSolicitudes(data);
            if (response?.status !== 200) {
                return lista;
            }
            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA CONSULTA BANDEJA GESTION SOLICITUDES.');
        }
        return lista;
    },

}


export const General = {
    GetUsuarioReasignarGestionSolicitud: async (data: any) => {
        try {
            const response: any = await RequestManager.GetUsuarioReasignarGestionSolicitud(data);
            const { isValid } = response.data;
            if (response) {
                return isValid;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO USUARIO REASINGAR EN GESTION DE SOLICITUDES.');
            return false;
        }
    }
}