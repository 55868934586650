import { Component } from "react";
import ObservedMemberListDetail from "./ObservedMemberListDetail";
import EmptyData from "../1.5.3.-empty-data/EmptyData";
import ParameterHelper from "../../../../helpers/ParameterHelper";
import { appConfigPerfiles, appSecurityConfiguration } from "../../../../../config/Config";

interface IObservedMemberListProps {
  objObservedMemberListProps: {
    observedMemberListData: any[];
  };
  focusIndex: number;
  handleClick: any;
}

class ObservedMemberList extends Component<IObservedMemberListProps, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <>
        {this.props.objObservedMemberListProps.observedMemberListData.length ==
          0 && <EmptyData />}
        {this.props.objObservedMemberListProps.observedMemberListData.length >
          0 &&
          this.props.objObservedMemberListProps.observedMemberListData.map(
            (item: any, i: number) => {

              //Mostrar icono nuevo cuando el miembro no haya sido liberado
              const showNew = !item?.liberadO_PLAFT || !item?.liberadO_RIESGOS;

              return (
                <ObservedMemberListDetail
                  key={i}
                  objObservedMemberListDetailProps={{
                    name: item.descL_NOMBRE_COMPLETO,
                    rol: item.descL_TIPO_RELACION,
                    documentTypeName: ParameterHelper.GetDocumentTypeName(item.iD_TIPO_DOCUMENTO),
                    documentNumber: item.codL_NUMERO_DOCUMENTO?.trim(),
                    statusPlaft: item.obS_PLAFT,
                    statusRisk: item.obS_RIESGOS,
                    showNew: showNew,
                    focus: this.props.focusIndex == i,
                  }}
                  handleClick={() => {
                    this.props.handleClick(item, i);
                  }}
                ></ObservedMemberListDetail>
              );
            }
          )}
      </>
    );
  }
}
export default ObservedMemberList;
