import { toast } from "sonner";
import { validarSelect, validarText } from "../../../../helpers/ValidarHelper";
import { appConfigKey } from "../../../../../config/Config";

const ValidacionGestionSolicitudesHelper = {

    NewDataReasignar: (newDataReasingar: any, listRequest: any) => {
        const {
            idEtapa,
            identUsuarioReasignar
        } = newDataReasingar;

        if (!validarSelect(idEtapa, '"Etapa"')) {
            return false;
        }

        if (!validarSelect(identUsuarioReasignar, '"Reasignar a"')) {
            return false;
        }

        if (listRequest?.length > 0) {
            const newListRequest: any = listRequest.filter((x: any) => x?.seleccionado === 1)
            if (newListRequest.length > 0) {
                const newListRequestEtapaDistinta: any = newListRequest.filter((x: any) => x?.idEtapa !== newDataReasingar.idEtapa)
                if (newListRequestEtapaDistinta.length > 0) {
                    toast.error(appConfigKey.keyMsjReasignoValidacionDos, { duration: appConfigKey.keyDurationToast });
                    return false;
                }

                const newListRequestExisteUsuario: any = newListRequest.filter((x: any) => x?.idUsuario == newDataReasingar.identUsuarioReasignar)
                if (newListRequestExisteUsuario.length > 0) {
                    toast.error(appConfigKey.keyMsjReasignoValidacionTres, { duration: appConfigKey.keyDurationToast });
                    return false;
                }

            } else {
                toast.error(appConfigKey.keyMsjReasignoValidacionUno, { duration: appConfigKey.keyDurationToast });
                return false;
            }
        }
        else {
            toast.error(appConfigKey.keyMsjReasignoValidacionUno, { duration: appConfigKey.keyDurationToast });
            return false;
        }

        return true;

    }

}
export default ValidacionGestionSolicitudesHelper;