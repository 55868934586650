import { Box, Link } from "@mui/joy";
import { DataSourceInterface } from "../../interfaces/dataSourceInterface";
import { EnhancedTableInterface } from "../../interfaces/enhancedTableInterfaces";
import { HeadCellsDataSource } from "../../interfaces/headCellsDataSource";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { visuallyHidden } from "@mui/utils";


const camposTable: string[] = [
    //"id",
    "usuario",
    "perfil",
    "rol",
    "etapaI",
    "etapaII",
    "activar",
    "opciones"
];

const headCellsDataSource: readonly HeadCellsDataSource[] = [
    {
        id: "id",
        numeric: false,
        disablePadding: true,
        label: "Id",
        orderActive: true,
        width: "50px"
    },
    {
        id: "usuario",
        numeric: false,
        disablePadding: true,
        label: "Usuario",
        orderActive: true,
        width: "100px"
    },
    {
        id: "rol",
        numeric: false,
        disablePadding: true,
        label: "Rol",
        orderActive: true,
        width: "100px"
    },
    {
        id: "perfil",
        numeric: false,
        disablePadding: true,
        label: "Perfil",
        orderActive: true,
        width: "100px"
    },
    {
        id: "etapaI",
        numeric: false,
        disablePadding: false,
        label: "Cat. SUNAT Etapa 1",
        orderActive: true,
        width: "100px"
    },
    {
        id: "etapaII",
        numeric: false,
        disablePadding: false,
        label: "Cat. SUNAT Etapa 2",
        orderActive: true,
        width: "100px"
    },
    {
        id: "opciones",
        numeric: false,
        disablePadding: false,
        label: "Acción",
        orderActive: false,
        width: "30px"
    }
];


function EnhancedTableHead(props: EnhancedTableInterface) {
    const {
        onSelectAllClick,
        order,
        orderBy,
        numSelected,
        rowCount,
        onRequestSort
    } = props;
    let perfil: number;
    const createSortHandler =
        (property: keyof DataSourceInterface) => (event: React.MouseEvent<unknown>) => {
            onRequestSort(event, property);
        };
    const campos: string[] = [...camposTable];
    const sxHeadObjectGet = (headCell: any) => {
        var result: any = {};
        if (headCell.width)
            result.width = headCell.width;
        return result;

    };
    return (
        <thead className="Tablethead">
            <tr className="headTable">
                {headCellsDataSource.map((headCell: any) => {
                    const active = orderBy === headCell.id;
                    if (!campos.includes(headCell.id)) {
                        return null;
                    }
                    return (
                        <th
                            key={headCell.id}
                            aria-sort={
                                active
                                    ? ({ asc: "ascending", desc: "descending" } as const)[order]
                                    : undefined
                            }
                            style={sxHeadObjectGet(headCell)}
                        >
                            <Link
                                className="headTableColor"
                                underline="none"
                                textColor={active ? "primary.plainColor" : undefined}
                                component="button"
                                onClick={
                                    headCell.orderActive
                                        ? createSortHandler(headCell.id)
                                        : undefined
                                }
                                fontWeight="lg"
                                endDecorator={
                                    headCell.orderActive ? (
                                        <ArrowDownwardIcon sx={{ opacity: active ? 1 : 0 }} />
                                    ) : null
                                }
                                sx={{
                                    "& svg": {
                                        transition: "0.2s",
                                        transform:
                                            active && order === "desc"
                                                ? "rotate(0deg)"
                                                : "rotate(180deg)",
                                    },
                                    "&:hover": { "& svg": { opacity: 1 } },
                                }}
                            >
                                {headCell.label}
                                {active ? (
                                    <Box component="span" sx={visuallyHidden}>
                                        {order === "desc"
                                            ? "sorted descending"
                                            : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </Link>
                        </th>
                    );
                })}
            </tr>
        </thead>
    );
}

export default EnhancedTableHead;