import * as React from "react";
import MainLayout from "../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout";
import { Tab, Tabs, containerClasses } from "@mui/material";
import CardTitleStart from "../../1.-shared/1.2.-layout/1.2.5-cardTitleStart/cardTitleStart";//"../7.20.-cardTitleStart/cardTitleStart";
import CardDebst from "../7.10.-base/components/CardDedts";
import CardMembers from "../7.10.-base/components/CardMembers";
import CardResult from "../7.10.-base/components/CardResult";
import ScoringTitular from "../7.30.-scoring/scoringTitular";
import ScoringConyuge from "../7.30.-scoring/scoringConyuge";
import ScoringCliente from "../7.30.-scoring/scoringCliente";
import FinanciamientoDatosCondiciones from "../7.40.-financiamiento/financiamientoDatosCondiciones";
import ResultadosDocumentos from "../7.50.-Resultados/resultadosDocumentos";
import ResultadosEvaluacion from "../7.50.-Resultados/resultadosEvaluacion";
import ResultadosHistorial from "../7.50.-Resultados/resultadosHistorial";
import { Button } from "@mui/joy";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { useParams } from "react-router-dom";
import { Toaster, toast } from "sonner";
import LoadingProgress from "../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import ScoringClasificacionSBS from "../7.30.-scoring/scoringClasificacionSBS";

import ScoringDatosReevaluacion from "../7.30.-scoring/scoringDatosReevaluacion";

import ScoringInformacionEntidadesSBS from "../7.30.-scoring/scoringInformacionEntidadesSBS";
import CardTitleSummary from "../7.20.-cardTitleStart/cardTitleSummary";
import CardTitleScore from "../7.20.-cardTitleStart/CardTitleScore";
import RequestManager from '../../../services/origination/wapirequest.service';
import ServicesProvider from "../../../services/support/wapiservicesprovider";
import MasterManager from "../../../services/origination/wapimaster.service";
import { Clasificacion, ClasificacionDto, reqClasificacionDto } from "../../../models/ManagerProfile/ClasificacionDto";
import { ResultadoEvaluacionResumen } from "../../../models/ManagerProfile/ResultadoEvaluacionResumen";
import { ConvertidorHelper } from "../7.30.-scoring/helpers/ConvertidorHelper";
import { Solicitud } from "../../../models/ManagerProfile/Solicitud";
import { InformacionEntidad, InformacioEntidadDefault } from "../../../models/ManagerProfile/InformacionEntidad";
import { Debts, DebtsDefault } from "../../../models/ManagerProfile/Debts";
import { Members, MembersDefault, Users } from "../../../models/ManagerProfile/Members";
import { OtrosIngresos, Titular, TitularDefault } from "../../../models/ManagerProfile/Titular";
import { Conyuge, ConyugeDefault } from "../../../models/ManagerProfile/Conyuge";
import { Integrante, Reevaluar, reqReevaluarDto, clsIntegranteDefault, Ingreso, clsIngresoDefault, CargaFinanciera, clsCargaFinancieraDefault } from "../../../models/ManagerProfile/Reevaluar";
import { DatosReevaluacion, DatosReevaluacionDefault, SaveDatosReevaluacion, SaveDatosReevaluacionDefault } from "../../../models/ManagerProfile/DatosReevaluacion";
import './managerProfile.scss';
import { DatosCondicionFinanciamiento, DatosCondicionFinanciamientoDefault } from "../../../models/ManagerProfile/Financiamiento_DatosCondicion";
import { appConfigDecisionMotor, appConfigEstadosProcesoEtapaAnalisisCredito, appConfigEtapaAnalisisCredito, appConfigKey, appConfigProcesoEtapaAnalisisCredito, appDefaultValues, appSecurityConfiguration } from "../../../../config/Config";
import { ResultadoFinanciamiento, ResultadoFinanciamientoDefault } from "../../../models/ManagerProfile/ResultadoFinanciamiento";
import { Dialog } from "@mui/material";
import GeneralHelper from "../../../helpers/GeneralHelper";
import { useNavigate } from "react-router-dom";


import { ResultadoEvaluacionDto, ResultadoEvalucionDtoDefault } from "../../../models/ManagerProfile/ResultadoEvaluacion";
import ValidacionResultadosEvaluacionHelper from "../7.10.-base/helpers/ValidacionResultadosEvaluacionHelper";
import ReportManager from "../../../services/support/wapireport";
import CardResultReevaluar from "./components/CardResultReevaluar";
import CardAlerts from "./components/CardAlerts";
import { General } from "./obtenerServicios";
import { useEffect, useState } from "react";

const headerTitles = ["SCORING", "FINANCIAMIENTO", "RESULTADOS"];
const START_POSITION = 0;

const AnalysProfile = () => {
  const { solicitudId } = useParams();

  const [title, setTitle] = React.useState("Validación documental - Scoring");
  const [tabsPosition, setTabsPosition] = React.useState(START_POSITION);
  const handleTabsPosition = (e: any, value: any) => {
    setTabsPosition(value);
    let title = "Validación documental  - ";
    setTitle(
      value === 0
        ? title + "Scoring"
        : value === 1
          ? title + "Financiamiento"
          : title + "Resultados"
    );
  };
  const [debts, setDebts] = React.useState<Debts>(DebtsDefault);
  const [members, setMembers] = React.useState<Members>(MembersDefault);
  const [solicitud, setSolicitud] = React.useState<Solicitud | number>(0);
  const [tipoCambio, setTipoCambio] = React.useState(0);
  const [resultadoEvaluacionResumen, setResultadoEvaluacionResumen] = React.useState<ResultadoEvaluacionResumen | number>(0);

  const [estadoCivilTitular, setEstadoCivilTitular] = React.useState(0);
  const [categoriaLaboralConyuge, setCategoriaLaboralConyuge] = React.useState(0);
  const [defaultValueTab, setDefaultValueTab] = React.useState(0);
  const [esVisibleSDR, setEsVisibleSDR] = React.useState(true);
  const [esSeparadoBienes, setEsSeparadoBienes] = React.useState(false);

  const [showConyugue, setShowConyugue] = React.useState(0);

  const [titular, setTitular] = React.useState<Titular>(TitularDefault);
  const [titularBeforce, setTitularBeforce] = React.useState<Titular>(TitularDefault);
  const [conyuge, setConyuge] = React.useState<Conyuge>(ConyugeDefault);
  const [conyugeBeforce, setConyugeBeforce] = React.useState<Conyuge>(ConyugeDefault);
  const [posicionCliente, setPosicionCliente] = React.useState({ posicionCliente: [] });
  const [clasificacion, setClasificacion] = React.useState<Clasificacion | number>(0);
  const [tipoIntegranteClasif, setTipoIntegranteClasif] = React.useState<string | null>('TITULAR');
  const [datosReevaluacionTitular, setDatosReevaluacionTitular] = React.useState<DatosReevaluacion>(DatosReevaluacionDefault);
  const [datosReevaluacionConyuge, setDatosReevaluacionConyuge] = React.useState<DatosReevaluacion>(DatosReevaluacionDefault);
  const [datosReevaluacionOrigenTitular, setDatosReevaluacionOrigenTitular] = React.useState<any | null>(null);
  const [datosReevaluacionOrigenConyuge, setDatosReevaluacionOrigenConyuge] = React.useState<any | null>(null);
  const [informacionEntidad, setInformacionEntidad] = React.useState<InformacionEntidad>(InformacioEntidadDefault);
  const [datosCondicionFinanciamientoInicial, setDatosCondicionFinanciamientoInicial] = React.useState<DatosCondicionFinanciamiento>(DatosCondicionFinanciamientoDefault);
  const [datosCondicionFinanciamiento, setDatosCondicionFinanciamiento] = React.useState<DatosCondicionFinanciamiento>(DatosCondicionFinanciamientoDefault);
  const [datosCondicionFinanciamientoOriginal, setDatosCondicionFinanciamientoOriginal] = React.useState<DatosCondicionFinanciamiento>(DatosCondicionFinanciamientoDefault);

  const [resultadosDocumento, setResultadosDocumento] = React.useState([]);
  const [resultadosEvaluacion, setResultadosEvaluacion] = React.useState({
    resultado: "",
    destinoCredito: "",
    comentario: "",
  });
  const [resultadoFinanciamiento, setResultadoFinanciamiento] = React.useState<ResultadoFinanciamiento>(ResultadoFinanciamientoDefault);

  const [esModoLectura, setEsModoLectura] = React.useState(false);
  const [flgResultadosHistorial, setFlgResultadosHistorial] = React.useState(false);
  const handleNewResultadoHistorico = () => {
    setFlgResultadosHistorial(!flgResultadosHistorial)
  };
  const [listaTipoDeuda, setListaTipoDeuda] = React.useState([]);
  const [clasificacionTitular, setClasificacionTitular] = React.useState<Clasificacion | number>(0);
  const [clasificacionConyuge, setClasificacionConyuge] = React.useState<Clasificacion | number>(0);
  const [clasificacionTitularOriginal, setClasificacionTitularOriginal] = React.useState<Clasificacion | number>(0);
  const [clasificacionConyugeOriginal, setClasificacionConyugeOriginal] = React.useState<Clasificacion | number>(0);
  const [listaSegmento, setListaSegmento] = React.useState([]);

  const [cuotas, setCuotas] = React.useState([]);

  //Llamada numero 1
  const [requestMotorBeforceStepOne, setRequestMotorBeforceStepOne] = React.useState(null);
  const [responseMotorBeforceStepOne, setResponseMotorBeforceStepOne] = React.useState(null);
  const [requestAMotorfterStepOne, setRequestMotorAfterStepOne] = React.useState(null);
  const [responseMotorAfterStepOne, setResponseMotorAfterStepOne] = React.useState(null);

  //Llamada numero 2
  const [requestMotorBeforceStepTwo, setRequestMotorBeforceStepTwo] = React.useState(null);
  const [responseMotorBeforceStepTwo, setResponseMotorBeforceStepTwo] = React.useState(null);
  const [requestAMotorfterStepTwo, setRequestMotorAfterStepTwo] = React.useState(null);
  const [responseMotorAfterStepTwo, setResponseMotorAfterStepTwo] = React.useState(null);
  const [responseMotorMotivo, setResponseMotorMotivo] =
    useState(null);

  //Llamada numero 3
  const [requestMotorBeforceStepThree, setRequestMotorBeforceStepThree] = React.useState(null);
  const [responseMotorBeforceStepThree, setResponseMotorBeforceStepThree] = React.useState(null);
  const [requestAMotorfterStepThree, setRequestMotorAfterStepThree] = React.useState(null);
  const [responseMotorAfterStepThree, setResponseMotorAfterStepThree] = React.useState(null);


  const [esVisibleResultReevaluar, setEsVisibleResultReevaluar] = React.useState(false);
  const [nvlRiesgo, setnvlRiesgo] = useState(null);


  const [isVisibleVerSustentoExcepcion, setIsVisibleVerSustentoExcepcion] = useState(false);
  const [show, setShow] = React.useState(false);
  const [resultEvaluacion, setResultEvaluacion] = React.useState(false);
  let navigate = useNavigate();

  const getObtenerSegmento = async () => {
    const lista: any = [];

    try {
      const response: any = await MasterManager.GetMnTablaGenericaDefinicion({
        "v_LIST_IDENT_TABLA": "25"
      });

      if (response.status !== 200) {
        setListaSegmento(lista);
        return;
      }

      const { content } = response.data;

      if (content) {
        content.forEach((element: any) => {
          lista.push({ id: element.genericA_VALO_CAMPO, description: element.genericA_DESCM_CAMPO });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER TIPO DEUDA.');
    }

    setListaSegmento(lista);
  };

  const getObtenerTipoDeuda = async () => {
    const lista: any = [];

    try {
      const response: any = await MasterManager.GetMnTablaGenericaDefinicion({
        "v_LIST_IDENT_TABLA": "28"
      });

      if (response.status !== 200) {
        setListaTipoDeuda(lista);
        return;
      }

      const { content } = response.data;

      if (content) {
        content.forEach((element: any) => {
          lista.push({ id: element.genericA_CODL_CAMPO, description: element.genericA_DESCM_CAMPO });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER TIPO DEUDA.');
    }

    setListaTipoDeuda(lista);
  };

  const getObtenerCuotas = async (productoUId: number) => {
    const lista: any = [];

    try {
      let response: any = await ServicesProvider.ObtenerCantidadCuotas(productoUId)

      if (response.status !== 200) {
        setCuotas(response);
        return;
      }
      const { content } = response.data;
      if (content) {
        content.listaValores.sdtsBTValor.forEach((element: any) => {
          lista.push({ id: element.valor, description: element.valor });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS PLAZOS DE MESES.');
    }

    setCuotas(lista);
  }

  const GetMnEvaluacionSolicitudInsert = async (identSolicitud: number, step: number, modelicaRequest: string, modelicaResponse: string) => {
    try {
      await MasterManager.GetMnEvaluacionSolicitudInsert(identSolicitud, step, modelicaRequest, modelicaResponse);
      return true;
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA REGISTRAR LA EVALUACION DE LA SOLICITUD.');
      return false;
    }
  }




  const getWorkflowClasificacion = async (request: reqClasificacionDto) => {
    await RequestManager.GetMnSolicitudRCC(request)
      .then((response: any) => {
        const mdlInformacionEntidad = ConvertidorHelper.ObtenerInformacionEntidad(response.data.content.resultado)
        setInformacionEntidad(mdlInformacionEntidad);

        const mdlClasificacion = ConvertidorHelper.ObtenerClasificacion(response.data.content.resultado)
        setClasificacion(mdlClasificacion);

      })
  };

  const getWorkflowClasificacionInicial = async (requestTitular: any, requestConyuge: any) => {
    if (requestTitular) {
      await RequestManager.GetMnSolicitudRCC(requestTitular)
        .then((response: any) => {
          const mdlInformacionEntidad = ConvertidorHelper.ObtenerInformacionEntidad(response.data.content.resultado)
          setInformacionEntidad(mdlInformacionEntidad);
          const mdlClasificacion = ConvertidorHelper.ObtenerClasificacion(response.data.content.resultado)
          setClasificacion(GeneralHelper.ClonarObject(mdlClasificacion));
          setClasificacionTitular(GeneralHelper.ClonarObject(mdlClasificacion));
          setClasificacionTitularOriginal(GeneralHelper.ClonarObject(mdlClasificacion));

        })
    }

    if (requestConyuge) {
      await RequestManager.GetMnSolicitudRCC(requestConyuge)
        .then((response: any) => {
          const mdlClasificacion = ConvertidorHelper.ObtenerClasificacion(response.data.content.resultado)
          setClasificacionConyuge(GeneralHelper.ClonarObject(mdlClasificacion));
          setClasificacionConyugeOriginal(GeneralHelper.ClonarObject(mdlClasificacion));
        })
    }
  };

  const getMnEvaluacionScoringGestor = async () => {
    await RequestManager.GetMnEvaluacionScoringGestor({ idenT_SOLICITUD: solicitudId })
      .then(async (response: any) => {
        setDebts({ ...response.data.content.debts });
        setNewMEN(response?.data?.content?.debts?.menS ? response?.data?.content?.debts?.menS : 0);
        const mdlUsers = ConvertidorHelper.ObtenerUser(response.data.content.members.users);
        setMembers(mdlUsers);
        const mdlResultadoFinanciamiento = ConvertidorHelper.ObtenerResultadoFinanciamiento(response.data.content.resultadoFinanciamiento);

        setResultadoFinanciamiento(mdlResultadoFinanciamiento);
        setNewCuota(mdlResultadoFinanciamiento?.montoCuota);



        const mdlSolicitud = ConvertidorHelper.ObtenerSolicitud(response.data.content.solicitud);

        const historialResultados = await General.GetMnResultadoEvaluacion(Number(solicitudId));
        if (historialResultados) {
          const { data: { content } } = historialResultados;
          let listaResultados: any = content.filter((x: any) => x.etapA_RESULTADO === appConfigKey.KeyEtapaCrediticiaMotor || x.etapA_RESULTADO === appConfigKey.KeyValidacionDocumental)
          if (listaResultados && listaResultados?.length > 0) {
            mdlSolicitud.nombreAnalista = listaResultados[0].usuario;
          }
        }

        setSolicitud(mdlSolicitud);

        setReqResultadoEvaluacion({
          ...reqResultadoEvaluacion,
          idenT_SOLICITUD: Number(solicitudId),
          idenT_RESULTADO_EVALUACION: 0,
          idenT_DESTINO_CREDITO: mdlSolicitud.idDestinoCredito,
          idenT_USUARIO_CREADOR: Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
          idenT_TIPO_RESULTADO: 0,
          descc_DETALLE: "",
          idenT_CREDITO_TIPO: 0,
          idenT_SUBTIPO: 0,
          numerO_VISOR: "",
        })

        setEsModoLectura((mdlSolicitud.idEstadoSolicitud !== appConfigKey.keyIdEstadoSolicitudPorValidar &&
          mdlSolicitud.idEstadoSolicitud !== appConfigKey.keyIdEstadoSolicitudEnValidacion &&
          mdlSolicitud.idEstadoSolicitud !== appConfigKey.KeyIdEstadoSolicitudRegistrado))

        setTipoCambio(mdlSolicitud.tipoCambio);
        getObtenerCuotas(mdlSolicitud.idProducto);

        response.data.content.resultEvaluacion.nivel_Riesgo = nvlRiesgo;

        const mdlResultadoEvaluacionResumen = ConvertidorHelper.ObtenerResultadoEvaluacion(response.data.content.resultEvaluacion);
        setResultadoEvaluacionResumen(mdlResultadoEvaluacionResumen);

        setTitular({ ...response.data.content.titularScoringGestor });
        setTitularBeforce({ ...response.data.content.titularScoringGestor });
        setConyuge({ ...response.data.content.conyugueScoringGestor });
        setConyugeBeforce({ ...response.data.content.conyugueScoringGestor });

        if (response.data.content.titularScoringGestor) {
          if (response.data.content.titularScoringGestor?.ingresos) {
            if (response.data.content.titularScoringGestor.ingresos?.separacionBienes) {
              setEsSeparadoBienes(response.data.content.titularScoringGestor.ingresos?.separacionBienes === appConfigKey.keySeparacionBienesSI);
            }
          }
        }

        if (response.data.content.conyugueScoringGestor) {
          if (response.data.content.conyugueScoringGestor?.ingresos) {
            if (response.data.content.conyugueScoringGestor.ingresos?.idcategoriaLaboral) {
              setCategoriaLaboralConyuge(response.data.content.conyugueScoringGestor.ingresos.idcategoriaLaboral);
            }
            if (response.data.content.conyugueScoringGestor.ingresos?.idCategoriaLaboralAnterior) {
              setShowConyugue(response.data.content.conyugueScoringGestor.ingresos.idCategoriaLaboralAnterior);
            }
          }
        }
        setPosicionCliente({ ...response.data.content.posicionCliente });

        const rccTitular = response.data.content.rccTitular;
        const rccConyuge = response.data.content.rccConyuge;

        setDatosReevaluacionTitular(GeneralHelper.ClonarObject(rccTitular));
        setDatosReevaluacionConyuge(GeneralHelper.ClonarObject(rccConyuge));
        const titularResultado = ConvertidorHelper.ObtenerResultado(response?.data?.content?.rccTitular);
        const conyugeResultado = ConvertidorHelper.ObtenerResultado(response?.data?.content?.rccConyuge);
        setDatosReevaluacionOrigenTitular(GeneralHelper.ClonarObject(titularResultado));
        setDatosReevaluacionOrigenConyuge(GeneralHelper.ClonarObject(conyugeResultado));

        const objClasificacion = mdlUsers.users.find(item => item.nroDocumento === mdlSolicitud.numeroDocumento.toString().trim())
        setEstadoCivilTitular(objClasificacion?.idEstadoCivil || 0);

        const dtoClasificacionTitular: ClasificacionDto = {
          solicitud: String(solicitudId),
          tipo_integrante: objClasificacion?.tipoIntegrante || "titular",
          documento: mdlSolicitud.numeroDocumento,
          tipo_documento: objClasificacion?.tipoDocumento || "DNI"
        };
        const reqClasificacionTitular: reqClasificacionDto = {
          request: dtoClasificacionTitular
        }

        await getWorkflowClasificacionInicial(reqClasificacionTitular, null);

        if (objClasificacion?.idEstadoCivil === appConfigKey.keyIdEstadoCivilCasado ||
          objClasificacion?.idEstadoCivil === appConfigKey.keyIdEstadoCivilConviviente) {

          let Conyuge: any = mdlUsers.users.find(item => item.tipoIntegrante.trim() === appConfigKey.keyDescripTipoIntegranteConyuge)

          const dtoClasificacionCoyuge: ClasificacionDto = {
            solicitud: String(solicitudId),
            tipo_integrante: appConfigKey.keyDescripTipoIntegranteConyuge,
            documento: Conyuge?.nroDocumento,
            tipo_documento: Conyuge?.tipoDocumento
          };
          const reqClasificacionConyuge: reqClasificacionDto = {
            request: dtoClasificacionCoyuge
          }
          await getWorkflowClasificacionInicial(null, reqClasificacionConyuge);
        }

        const mdlDatosCondicionFinanciamiento = ConvertidorHelper.ObtenerDatosCondicionFinanciamiento(response.data.content.datosCondicionFinanciamiento, response?.data?.content?.titularScoringGestor);
        setDatosCondicionFinanciamientoInicial(mdlDatosCondicionFinanciamiento);
        setDatosCondicionFinanciamiento(mdlDatosCondicionFinanciamiento);
        setDatosCondicionFinanciamientoOriginal(GeneralHelper.ClonarObject(mdlDatosCondicionFinanciamiento));
        setResultadosDocumento({ ...response.data.content.resultados });
        setResultadosEvaluacion({ ...response.data.content.resultados.resultadoEvaluacion });
        setShow(true);
      })
      .catch(error => {
        setShow(true);
        //myowasp(error.message);
      })
  };

  const [newMEN, setNewMEN] = React.useState(0);
  const [newCuota, setNewCuota] = React.useState(0);
  const [newCFC, setNewCFC] = React.useState(0);
  const [listaIntegrantes, setListaIntegrates] = React.useState<Integrante[]>([]);
  const [listaIngresos, setListaIngresos] = React.useState<Ingreso[]>([]);
  const [listaCargaFinanciera, setListaCargaFinanciera] = React.useState<CargaFinanciera[]>([]);

  const esReevaluarCalculoMENyCFC = async (): Promise<any> => {
    try {
      const validarNewReevaluarTitular = !ValidacionResultadosEvaluacionHelper.NewReevaluarTitular(titular.ingresos);
      if (validarNewReevaluarTitular) return;

      if (titular?.otrosIngresos?.length > 0) {
        const validarNewReevaluarOtrosIngresos = !ValidacionResultadosEvaluacionHelper.NewReevaluarOtrosIngresos(titular?.otrosIngresos, appConfigKey.keyDescripTipoIntegranteTitular);
        if (validarNewReevaluarOtrosIngresos) return;
      }

      const validarNewReevaluarClasificacion = !ValidacionResultadosEvaluacionHelper.NewReevaluarClasificacion(clasificacionTitular, 'Titular');
      if (validarNewReevaluarClasificacion) return;

      if (((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) ||
        (estadoCivilTitular === appConfigKey.keyIdEstadoCivilConviviente && !esSeparadoBienes)) &&
        categoriaLaboralConyuge !== appConfigKey.keyIdSinCategoria) {
        const validarNewReevaluarConyuge = !ValidacionResultadosEvaluacionHelper.NewReevaluarConyuge(conyuge.ingresos);
        if (validarNewReevaluarConyuge) return;

        if (conyuge?.otrosIngresos?.length > 0) {
          const validarNewReevaluarOtrosIngresos = !ValidacionResultadosEvaluacionHelper.NewReevaluarOtrosIngresos(conyuge?.otrosIngresos, 'Cónyuge');
          if (validarNewReevaluarOtrosIngresos) return;
        }

        const validarNewReevaluarClasificacion = !ValidacionResultadosEvaluacionHelper.NewReevaluarClasificacion(clasificacionConyuge, 'Cónyuge');
        if (validarNewReevaluarClasificacion) return;
      }



      const validarNewDatosCondicionFinanciamiento = !ValidacionResultadosEvaluacionHelper.NewDatosCondicionFinanciamiento(datosCondicionFinanciamiento, datosCondicionFinanciamientoOriginal);
      if (validarNewDatosCondicionFinanciamiento) return;

      let esIgualDeudaOriginalVSModificadaTitular: boolean = ValidacionResultadosEvaluacionHelper.EsIgualDeudaOriginalVSModificada(clasificacionTitular, datosReevaluacionTitular?.resultado);
      let esIgualDeudaOriginalVSModificadaConyuge: boolean = true;

      if (((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) ||
        (estadoCivilTitular === appConfigKey.keyIdEstadoCivilConviviente && !esSeparadoBienes)) &&
        categoriaLaboralConyuge !== appConfigKey.keyIdSinCategoria) {
        esIgualDeudaOriginalVSModificadaConyuge = ValidacionResultadosEvaluacionHelper.EsIgualDeudaOriginalVSModificada(clasificacionConyuge, datosReevaluacionConyuge?.resultado);
      }

      let modificacion_Deudas_Externas: any = {};
      modificacion_Deudas_Externas.flag_Modificacion_Deudas_Externas = 1;
      if (esIgualDeudaOriginalVSModificadaTitular && esIgualDeudaOriginalVSModificadaConyuge) {
        modificacion_Deudas_Externas.flag_Modificacion_Deudas_Externas = 0;
      }

      if (typeof clasificacionTitular !== "number") {
        modificacion_Deudas_Externas.deudas_Titular = {}
        modificacion_Deudas_Externas.deudas_Titular.lineas = {}
        modificacion_Deudas_Externas.deudas_Titular.lineas.linea_original = {}
        modificacion_Deudas_Externas.deudas_Titular.lineas.linea_original.monto = clasificacionTitular?.linea_disponible;
        modificacion_Deudas_Externas.deudas_Titular.lineas.linea_modificada = {}
        modificacion_Deudas_Externas.deudas_Titular.lineas.linea_modificada.monto = clasificacionTitular?.linea_disponible_real;
      }

      modificacion_Deudas_Externas.deudas_Titular.deudas = {}
      modificacion_Deudas_Externas.deudas_Titular.deudas.deuda_original = [];
      modificacion_Deudas_Externas.deudas_Titular.deudas.deuda_modificada = [];

      datosReevaluacionTitular?.resultado?.tabla2.map((row: any) => {
        let deuda_modificada: any = {};
        deuda_modificada.tipo_deuda = row.tipo_deuda;
        deuda_modificada.entidad = row.entidad;
        deuda_modificada.deuda = Number(GeneralHelper.QuitarComasAMiles(!row.monto_deuda_real ? 0 : row.monto_deuda_real));
        deuda_modificada.cuota = Number(GeneralHelper.QuitarComasAMiles(!row.cuota_fc_real ? 0 : row.cuota_fc_real));
        modificacion_Deudas_Externas.deudas_Titular.deudas.deuda_modificada.push(deuda_modificada);

        let deuda_original: any = {};
        deuda_original.tipo_deuda = row.tipo_deuda;
        deuda_original.entidad = row.entidad;
        deuda_original.deuda = Number(GeneralHelper.QuitarComasAMiles(!row.monto_deuda ? 0 : row.monto_deuda));
        deuda_original.cuota = Number(GeneralHelper.QuitarComasAMiles(!row.cuota_fc ? 0 : row.cuota_fc));
        modificacion_Deudas_Externas.deudas_Titular.deudas.deuda_original.push(deuda_original);
      })

      if (((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) ||
        (estadoCivilTitular === appConfigKey.keyIdEstadoCivilConviviente && !esSeparadoBienes)) &&
        categoriaLaboralConyuge !== appConfigKey.keyIdSinCategoria) {

        if (typeof clasificacionConyuge !== "number") {

          modificacion_Deudas_Externas.deudas_Conyugue = {}
          modificacion_Deudas_Externas.deudas_Conyugue.lineas = {}
          modificacion_Deudas_Externas.deudas_Conyugue.lineas.linea_original = {}
          modificacion_Deudas_Externas.deudas_Conyugue.lineas.linea_original.monto = clasificacionConyuge?.linea_disponible;
          modificacion_Deudas_Externas.deudas_Conyugue.lineas.linea_modificada = {}
          modificacion_Deudas_Externas.deudas_Conyugue.lineas.linea_modificada.monto = clasificacionConyuge?.linea_disponible_real;
        }

        modificacion_Deudas_Externas.deudas_Conyugue.deudas = {}
        modificacion_Deudas_Externas.deudas_Conyugue.deudas.deuda_original = [];
        modificacion_Deudas_Externas.deudas_Conyugue.deudas.deuda_modificada = [];
        datosReevaluacionConyuge?.resultado?.tabla2.map((row: any) => {
          let deuda_modificada: any = {};
          deuda_modificada.tipo_deuda = row.tipo_deuda;
          deuda_modificada.entidad = row.entidad;
          deuda_modificada.deuda = Number(GeneralHelper.QuitarComasAMiles(!row.monto_deuda_real ? 0 : row.monto_deuda_real));
          deuda_modificada.cuota = Number(GeneralHelper.QuitarComasAMiles(!row.cuota_fc_real ? 0 : row.cuota_fc_real));
          modificacion_Deudas_Externas.deudas_Conyugue.deudas.deuda_modificada.push(deuda_modificada);

          let deuda_original: any = {};
          deuda_original.tipo_deuda = row.tipo_deuda;
          deuda_original.entidad = row.entidad;
          deuda_original.deuda = Number(GeneralHelper.QuitarComasAMiles(!row.monto_deuda ? 0 : row.monto_deuda));
          deuda_original.cuota = Number(GeneralHelper.QuitarComasAMiles(!row.cuota_fc ? 0 : row.cuota_fc));
          modificacion_Deudas_Externas.deudas_Conyugue.deudas.deuda_original.push(deuda_original);


        })
      }


      let requestMotorAfterStepOneTemp: any = requestMotorBeforceStepOne;
      let requestMotorOne = requestMotorAfterStepOneTemp.request;

      let requestMotorAfterStepTwoTemp: any = requestMotorBeforceStepTwo;
      let requestMotorTwo = requestMotorAfterStepTwoTemp.request;

      let requestMotorAfterStepThreeTemp: any = requestMotorBeforceStepThree;
      let requestMotorThree: any = null;
      if (requestMotorAfterStepThreeTemp) {
        requestMotorThree = requestMotorAfterStepThreeTemp.request;
      }

      let esCategoriaJubilado = titular.ingresos.idcategoriaLaboral === appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado;
      let fechaIngresoDesglozadoTitular = esCategoriaJubilado ? null : titular.ingresos.fechaIngresoLaboral.split('/');
      let fechaInicioActividadTitular = esCategoriaJubilado ? appDefaultValues.StringEmpty : `${fechaIngresoDesglozadoTitular![2]}-${fechaIngresoDesglozadoTitular![1]}-${fechaIngresoDesglozadoTitular![0]}`;

      requestMotorOne.datos_Evaluacion.modificacion_Deudas_Externas = modificacion_Deudas_Externas;
      requestMotorOne.datos_Evaluacion.ingreso_declarado.titular.fecha_Inicio_Actividad = fechaInicioActividadTitular;
      requestMotorOne.datos_Evaluacion.ingreso_declarado.titular.ingreso_Mensual = Math.trunc(Number(titular.ingresos.ingresoNeto));
      requestMotorOne.datos_Evaluacion.ingreso_declarado.titular.ingreso_Anualizado = Math.trunc(titular.ingresos.ingresoAnualizado ? Number(titular.ingresos.totalAnualizado) : Number(titular.ingresos.ingresoNeto));

      requestMotorTwo.datos_Evaluacion.modificacion_Deudas_Externas = modificacion_Deudas_Externas;
      requestMotorTwo.datos_Evaluacion.ingreso_declarado.titular.fecha_Inicio_Actividad = fechaInicioActividadTitular;
      requestMotorTwo.datos_Evaluacion.ingreso_declarado.titular.ingreso_Mensual = Math.trunc(Number(titular.ingresos.ingresoNeto));
      requestMotorTwo.datos_Evaluacion.ingreso_declarado.titular.ingreso_Anualizado = Math.trunc(titular.ingresos.ingresoAnualizado ? Number(titular.ingresos.totalAnualizado) : Number(titular.ingresos.ingresoNeto));

      if (requestMotorAfterStepThreeTemp) {

        requestMotorThree.datos_Evaluacion.modificacion_Deudas_Externas = modificacion_Deudas_Externas;
        requestMotorThree.datos_Evaluacion.ingreso_declarado.titular.fecha_Inicio_Actividad = fechaInicioActividadTitular;
        requestMotorThree.datos_Evaluacion.ingreso_declarado.titular.ingreso_Mensual = Math.trunc(Number(titular.ingresos.ingresoNeto));
        requestMotorThree.datos_Evaluacion.ingreso_declarado.titular.ingreso_Anualizado = Math.trunc(titular.ingresos.ingresoAnualizado ? Number(titular.ingresos.totalAnualizado) : Number(titular.ingresos.ingresoNeto));
      }


      titular?.otrosIngresos?.forEach((item: OtrosIngresos, index: number) => {

        let esCategoriaJubilado = item.idcategoriaLaboral === appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado;
        let fechaIngresoDesglozadoTitular = esCategoriaJubilado ? null : item.fechaIngresoLaboral.split('/');
        let fechaInicioActividadTitular = esCategoriaJubilado ? appDefaultValues.StringEmpty : `${fechaIngresoDesglozadoTitular![2]}-${fechaIngresoDesglozadoTitular![1]}-${fechaIngresoDesglozadoTitular![0]}`;

        if (requestMotorOne?.datos_Evaluacion?.ingreso_declarado?.titular?.otros_Ingresos?.length > 0) {
          requestMotorOne.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[index].ingreso_Mensual = Math.trunc(Number(item.ingresoNeto));
          requestMotorOne.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[index].ingreso_Anualizado = Math.trunc(item.ingresoAnualizado ? Number(item.totalAnualizado) : Number(item.ingresoNeto));
          requestMotorOne.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[index].fecha_Inicio_Actividad = fechaInicioActividadTitular;
        }


        if (requestMotorTwo?.datos_Evaluacion?.ingreso_declarado?.titular?.otros_Ingresos?.length > 0) {
          requestMotorTwo.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[index].ingreso_Mensual = Math.trunc(Number(item.ingresoNeto));
          requestMotorTwo.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[index].ingreso_Anualizado = Math.trunc(item.ingresoAnualizado ? Number(item.totalAnualizado) : Number(item.ingresoNeto));
          requestMotorTwo.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[index].fecha_Inicio_Actividad = fechaInicioActividadTitular;
        }

        if (requestMotorAfterStepThreeTemp) {

          if (requestMotorThree?.datos_Evaluacion?.ingreso_declarado?.titular?.otros_Ingresos?.length > 0) {
            requestMotorThree.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[index].ingreso_Mensual = Math.trunc(Number(item.ingresoNeto));
            requestMotorThree.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[index].ingreso_Anualizado = Math.trunc(item.ingresoAnualizado ? Number(item.totalAnualizado) : Number(item.ingresoNeto));
            requestMotorThree.datos_Evaluacion.ingreso_declarado.titular.otros_Ingresos[index].fecha_Inicio_Actividad = fechaInicioActividadTitular;
          }
        }
      })

      if (((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) ||
        (estadoCivilTitular === appConfigKey.keyIdEstadoCivilConviviente && !esSeparadoBienes)) &&
        categoriaLaboralConyuge !== appConfigKey.keyIdSinCategoria) {

        let esCategoriaJubilado = conyuge.ingresos.idcategoriaLaboral === appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado;
        let fechaIngresoDesglozadoConyuge = esCategoriaJubilado ? null : conyuge.ingresos.fechaIngresoLaboral.split('/');
        let fechaInicioActividadConyuge = esCategoriaJubilado ? appDefaultValues.StringEmpty : `${fechaIngresoDesglozadoConyuge![2]}-${fechaIngresoDesglozadoConyuge![1]}-${fechaIngresoDesglozadoConyuge![0]}`;

        //if (requestMotorOne?.datos_Evaluacion?.ingreso_declarado?.conyugue?.otros_Ingresos?.length > 0) {
        requestMotorOne.datos_Evaluacion.ingreso_declarado.conyugue.fecha_Inicio_Actividad = fechaInicioActividadConyuge;
        requestMotorOne.datos_Evaluacion.ingreso_declarado.conyugue.ingreso_Mensual = Math.trunc(Number(conyuge.ingresos.ingresoNeto));
        requestMotorOne.datos_Evaluacion.ingreso_declarado.conyugue.ingreso_Anualizado = Math.trunc(conyuge.ingresos.ingresoAnualizado ? Number(conyuge.ingresos.totalAnualizado) : Number(conyuge.ingresos.ingresoNeto));
        //}

        //if (requestMotorTwo?.datos_Evaluacion?.ingreso_declarado?.conyugue?.otros_Ingresos?.length > 0) {
        requestMotorTwo.datos_Evaluacion.ingreso_declarado.conyugue.fecha_Inicio_Actividad = fechaInicioActividadConyuge;
        requestMotorTwo.datos_Evaluacion.ingreso_declarado.conyugue.ingreso_Mensual = Math.trunc(Number(conyuge.ingresos.ingresoNeto));
        requestMotorTwo.datos_Evaluacion.ingreso_declarado.conyugue.ingreso_Anualizado = Math.trunc(conyuge.ingresos.ingresoAnualizado ? Number(conyuge.ingresos.totalAnualizado) : Number(conyuge.ingresos.ingresoNeto));
        //}

        if (requestMotorAfterStepThreeTemp) {
          //if (requestMotorThree?.datos_Evaluacion?.ingreso_declarado?.conyugue?.otros_Ingresos?.length > 0) {
          requestMotorThree.datos_Evaluacion.ingreso_declarado.conyugue.fecha_Inicio_Actividad = fechaInicioActividadConyuge;
          requestMotorThree.datos_Evaluacion.ingreso_declarado.conyugue.ingreso_Mensual = Math.trunc(Number(conyuge.ingresos.ingresoNeto));
          requestMotorThree.datos_Evaluacion.ingreso_declarado.conyugue.ingreso_Anualizado = Math.trunc(conyuge.ingresos.ingresoAnualizado ? Number(conyuge.ingresos.totalAnualizado) : Number(conyuge.ingresos.ingresoNeto));
          //}
        }

        conyuge?.otrosIngresos?.forEach((item: OtrosIngresos, index: number) => {

          let esCategoriaJubilado = item.idcategoriaLaboral === appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado;
          let fechaIngresoDesglozadoConyugue = esCategoriaJubilado ? null : item.fechaIngresoLaboral.split('/');
          let fechaInicioActividadConyugue = esCategoriaJubilado ? appDefaultValues.StringEmpty : `${fechaIngresoDesglozadoConyugue![2]}-${fechaIngresoDesglozadoConyugue![1]}-${fechaIngresoDesglozadoConyugue![0]}`;

          if (requestMotorOne?.datos_Evaluacion?.ingreso_declarado?.conyugue?.otros_Ingresos?.length > 0) {
            requestMotorOne.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[index].ingreso_Mensual = Math.trunc(Number(item.ingresoNeto));
            requestMotorOne.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[index].ingreso_Anualizado = Math.trunc(item.ingresoAnualizado ? Number(item.totalAnualizado) : Number(item.ingresoNeto));
            requestMotorOne.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[index].fecha_Inicio_Actividad = fechaInicioActividadConyugue;
          }

          if (requestMotorTwo?.datos_Evaluacion?.ingreso_declarado?.conyugue?.otros_Ingresos?.length > 0) {
            requestMotorTwo.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[index].ingreso_Mensual = Math.trunc(Number(item.ingresoNeto));
            requestMotorTwo.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[index].ingreso_Anualizado = Math.trunc(item.ingresoAnualizado ? Number(item.totalAnualizado) : Number(item.ingresoNeto));
            requestMotorTwo.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[index].fecha_Inicio_Actividad = fechaInicioActividadConyugue;
          }

          if (requestMotorAfterStepThreeTemp) {
            if (requestMotorThree?.datos_Evaluacion?.ingreso_declarado?.conyugue?.otros_Ingresos?.length > 0) {
              requestMotorThree.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[index].ingreso_Mensual = Math.trunc(Number(item.ingresoNeto));
              requestMotorThree.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[index].ingreso_Anualizado = Math.trunc(item.ingresoAnualizado ? Number(item.totalAnualizado) : Number(item.ingresoNeto));
              requestMotorThree.datos_Evaluacion.ingreso_declarado.conyugue.otros_Ingresos[index].fecha_Inicio_Actividad = fechaInicioActividadConyugue;
            }
          }
        })
      }

      if (datosCondicionFinanciamiento) {
        requestMotorTwo.datos_Evaluacion.datos_financiamiento.cuota_inicial.monto = Number(datosCondicionFinanciamiento.cuotaInicial);
        requestMotorTwo.datos_Evaluacion.datos_financiamiento.cuota_inicial.porcentaje = Number(datosCondicionFinanciamiento.porcentajeInicial);
        requestMotorTwo.datos_Evaluacion.datos_financiamiento.monto_a_financiar = Number(datosCondicionFinanciamiento.montoVehiculo) - Number(datosCondicionFinanciamiento.cuotaInicial);

        if (requestMotorAfterStepThreeTemp) {
          requestMotorThree.datos_Evaluacion.datos_financiamiento.cuota_inicial.monto = Number(datosCondicionFinanciamiento.cuotaInicial);
          requestMotorThree.datos_Evaluacion.datos_financiamiento.cuota_inicial.porcentaje = Number(datosCondicionFinanciamiento.porcentajeInicial);
          requestMotorThree.datos_Evaluacion.datos_financiamiento.monto_a_financiar = Number(datosCondicionFinanciamiento.montoVehiculo) - Number(datosCondicionFinanciamiento.cuotaInicial);
        }
      }

      let motorOneSuccessful = true;
      let motorTwoSuccessful = true;
      let motorThreeSuccessful = true;
      let errorMessages: string[] = [];
      requestMotorAfterStepOneTemp.request = requestMotorOne;
      setRequestMotorAfterStepOne(requestMotorAfterStepOneTemp);
      let responseMotorAfterStepOne: any = null
      const responseModellicaMotorOne = await ModellicaMotorEvaluacion(requestMotorAfterStepOneTemp).then((response: any) => {
        return response;
      }).catch((error: any) => {
        return error;
      });

      const validacionOneNewModelicaData = NewModelicaData(responseModellicaMotorOne, appConfigKey.KeyLlamadaMotorUno);

      if (validacionOneNewModelicaData) {
        errorMessages.push(validacionOneNewModelicaData);
        motorOneSuccessful = false;
      }

      requestMotorAfterStepTwoTemp.request = requestMotorTwo;
      setRequestMotorAfterStepTwo(requestMotorAfterStepTwoTemp);
      setEsVisibleResultReevaluar(false);
      const cfmAux = debts?.cfm ? Number(debts.cfm.replace('%', '')) * 0.01 : 0;
      let responseMotorAfterStepTwo: any = null
      const responseModellicaMotorTwo = await ModellicaMotorEvaluacion(requestMotorAfterStepTwoTemp).then((response: any) => {
        return response;
      }).catch((error: any) => {
        return error;
      });

      const validacionTwoNewModelicaData = NewModelicaData(responseModellicaMotorTwo, appConfigKey.KeyLlamadaMotorDos);

      if (validacionTwoNewModelicaData) {
        errorMessages.push(validacionTwoNewModelicaData);
        motorTwoSuccessful = false;
      }

      let responseMotorAfterStepThree: any = null
      let responseModellicaMotorThree: any = null;
      if (requestMotorAfterStepThreeTemp) {
        requestMotorAfterStepThreeTemp.request = requestMotorThree;
        setRequestMotorAfterStepThree(requestMotorAfterStepThreeTemp);
        responseModellicaMotorThree = await ModellicaMotorEvaluacion(requestMotorAfterStepThreeTemp).then((response: any) => {
          return response;
        }).catch((error: any) => {
          return error;
        });

        const validacionThreeNewModelicaData = NewModelicaData(responseModellicaMotorThree, appConfigKey.KeyLlamadaMotorTres);

        if (validacionThreeNewModelicaData) {
          errorMessages.push(validacionThreeNewModelicaData);
          motorThreeSuccessful = false;
        }
      }

      if (responseModellicaMotorOne) {
        let content = responseModellicaMotorOne;
        if (motorOneSuccessful) {
          content = JSON.parse(responseModellicaMotorOne.data.content);
          setResponseMotorAfterStepOne(content);
          responseMotorAfterStepOne = content;
        }

        General.GetMnEvaluacionSolicitudInsertLog(
          Number(solicitudId),
          appConfigKey.KeyLlamadaMotorUno,
          JSON.stringify(requestMotorOne),
          JSON.stringify(content),
          Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
          Number(localStorage.getItem('UserProfileMn') ? localStorage.getItem('UserProfileMn') : 0),
          appConfigKey.KeyFlujoValidacionDocumentalScoring);
      }

      if (responseModellicaMotorTwo) {
        let content = responseModellicaMotorTwo;
        if (motorTwoSuccessful) {
          content = JSON.parse(responseModellicaMotorTwo.data.content);
          setResponseMotorAfterStepTwo(content);
          responseMotorAfterStepTwo = content;
        }

        General.GetMnEvaluacionSolicitudInsertLog(
          Number(solicitudId),
          appConfigKey.KeyLlamadaMotorDos,
          JSON.stringify(requestMotorTwo),
          JSON.stringify(content),
          Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
          Number(localStorage.getItem('UserProfileMn') ? localStorage.getItem('UserProfileMn') : 0),
          appConfigKey.KeyFlujoValidacionDocumentalScoring);
      }

      if (responseModellicaMotorThree) {
        let content = responseModellicaMotorThree;
        if (motorThreeSuccessful) {
          content = JSON.parse(responseModellicaMotorThree.data.content);
          setResponseMotorAfterStepThree(content);
          responseMotorAfterStepThree = content;
        }

        General.GetMnEvaluacionSolicitudInsertLog(
          Number(solicitudId),
          appConfigKey.KeyLlamadaMotorTres,
          JSON.stringify(requestMotorThree),
          JSON.stringify(content),
          Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
          Number(localStorage.getItem('UserProfileMn') ? localStorage.getItem('UserProfileMn') : 0),
          appConfigKey.KeyFlujoValidacionDocumentalScoring);
      }

      if (!motorOneSuccessful || !motorTwoSuccessful || !motorThreeSuccessful) {
        const msgHtml = errorMessages.map((msg: any, index: number) => (
          <div key={index}>
            {msg}
          </div>
        ));

        if (msgHtml) {
          toast.error(msgHtml, { duration: appConfigKey.keyDurationToast });
        }
        return;
      }

      if (responseModellicaMotorTwo && motorTwoSuccessful) {
        const content = JSON.parse(responseModellicaMotorTwo.data.content);

        const responseMotor = content?.response;
        const decision = responseMotor?.Politica_Creditos?.decision_Credito?.decision;
        const puntajeAfter = responseMotor?.Politica_Creditos?.decision_Credito?.puntaje;
        const segmentoAfter = responseMotor?.Politica_Creditos?.decision_Credito?.segmento;
        const nivelRiesgo = responseMotor?.Politica_Creditos?.decision_Credito?.nivel_Riesgo;

        if (decision && typeof solicitud !== 'number') {
          const maximoEndeudamiento = responseMotor?.Politica_Creditos?.capacidad_Endeudamiento.maximo_Endeudamiento.find((x: any) => x.CFM == cfmAux);
          const cfc = responseMotor?.Politica_Creditos?.capacidad_Endeudamiento?.CFC;
          const valorCFC = (cfc ? Number(cfc) * 100 : 0).toFixed(2) + '%';
          setNewMEN(maximoEndeudamiento.MEM);
          setNewCFC(cfc);
          setDebts({ ...debts, cfc: valorCFC });
          let resultadoEvaluacionResumenTemp: any = resultadoEvaluacionResumen;
          resultadoEvaluacionResumenTemp.nivel_Riesgo = !nivelRiesgo ? 'N/D' : nivelRiesgo;
          setResultadoEvaluacionResumen(resultadoEvaluacionResumenTemp)

          if (!responseMotor?.productos_comerciales) {
            setEsVisibleResultReevaluar(true);
            return {
              esReevaluarValido: false,
              esAprobadoSolicitud: decision === appConfigKey.CodigoEvaluacionAprobado,
              esRechazadoSolicitud: decision === appConfigKey.CodigoEvaluacionRechazado,
              esZonaGrisSolicitud: decision === appConfigKey.CodigoEvaluacionZonaGris,
              newMen: maximoEndeudamiento.MEM,
              newCfc: cfc,
              requestMotorAfterStepOne: requestMotorAfterStepOneTemp,
              responseMotorAfterStepOne: responseMotorAfterStepOne,
              requestMotorAfterStepTwo: requestMotorAfterStepTwoTemp,
              responseMotorAfterStepTwo: responseMotorAfterStepTwo,
              requestMotorAfterStepThree: requestMotorAfterStepThreeTemp,
              responseMotorAfterStepThree: responseMotorAfterStepThree,
              newPuntaje: puntajeAfter,
              newIdSegmento: segmentoAfter,
              noTieneProductosComerciales: true,
              noCoincidenProducto: false
            };
          }

          let producto: any = responseMotor?.productos_comerciales.find((x: any) => Number(x.codigo) == solicitud?.idProducto);
          if (!producto) {
            setEsVisibleResultReevaluar(true);
            return {
              esReevaluarValido: false,
              esAprobadoSolicitud: decision === appConfigKey.CodigoEvaluacionAprobado,
              esRechazadoSolicitud: decision === appConfigKey.CodigoEvaluacionRechazado,
              esZonaGrisSolicitud: decision === appConfigKey.CodigoEvaluacionZonaGris,
              newMen: maximoEndeudamiento.MEM,
              newCfc: cfc,
              requestMotorAfterStepOne: requestMotorAfterStepOneTemp,
              responseMotorAfterStepOne: responseMotorAfterStepOne,
              requestMotorAfterStepTwo: requestMotorAfterStepTwoTemp,
              responseMotorAfterStepTwo: responseMotorAfterStepTwo,
              requestMotorAfterStepThree: requestMotorAfterStepThreeTemp,
              responseMotorAfterStepThree: responseMotorAfterStepThree,
              newPuntaje: puntajeAfter,
              newIdSegmento: segmentoAfter,
              noTieneProductosComerciales: false,
              noCoincidenProducto: true
            };
          }

          let responseMotorBeforce: any = responseMotorBeforceStepTwo;
          let puntajeBeforce = responseMotorBeforce?.response?.Politica_Creditos?.decision_Credito?.puntaje;
          let segmentoBeforce = responseMotorBeforce?.response?.Politica_Creditos?.decision_Credito?.segmento;

          if (puntajeBeforce !== puntajeAfter || segmentoBeforce !== segmentoAfter) {
            setEsVisibleResultReevaluar(true);
            return {
              esReevaluarValido: false,
              esAprobadoSolicitud: decision === appConfigKey.CodigoEvaluacionAprobado,
              esRechazadoSolicitud: decision === appConfigKey.CodigoEvaluacionRechazado,
              esZonaGrisSolicitud: decision === appConfigKey.CodigoEvaluacionZonaGris,
              newMen: maximoEndeudamiento.MEM,
              newCfc: cfc,
              requestMotorAfterStepOne: requestMotorAfterStepOneTemp,
              responseMotorAfterStepOne: responseMotorAfterStepOne,
              requestMotorAfterStepTwo: requestMotorAfterStepTwoTemp,
              responseMotorAfterStepTwo: responseMotorAfterStepTwo,
              requestMotorAfterStepThree: requestMotorAfterStepThreeTemp,
              responseMotorAfterStepThree: responseMotorAfterStepThree,
              newPuntaje: puntajeAfter,
              newIdSegmento: segmentoAfter,
              noTieneProductosComerciales: false,
              noCoincidenProducto: false
            };
          }

          return {
            esReevaluarValido: true,
            esAprobadoSolicitud: decision === appConfigKey.CodigoEvaluacionAprobado,
            esRechazadoSolicitud: decision === appConfigKey.CodigoEvaluacionRechazado,
            esZonaGrisSolicitud: decision === appConfigKey.CodigoEvaluacionZonaGris,
            newMen: maximoEndeudamiento.MEM,
            newCfc: cfc,
            requestMotorAfterStepOne: requestMotorAfterStepOneTemp,
            responseMotorAfterStepOne: responseMotorAfterStepOne,
            requestMotorAfterStepTwo: requestMotorAfterStepTwoTemp,
            responseMotorAfterStepTwo: responseMotorAfterStepTwo,
            requestMotorAfterStepThree: requestMotorAfterStepThreeTemp,
            responseMotorAfterStepThree: responseMotorAfterStepThree,
            newPuntaje: puntajeAfter,
            newIdSegmento: segmentoAfter,
            noTieneProductosComerciales: false,
            noCoincidenProducto: false
          };
        }
      }

      return;
    }
    catch (error) {
      return;
    }
  };


  const esReevaluarResultados = async (): Promise<any> => {
    let nuevoResultadoFinanciamiento: any = null;
    try {
      let nuevoDatosCondicionFinanciamiento = datosCondicionFinanciamiento;
      let inicialDolares = nuevoDatosCondicionFinanciamiento.idmoneda === appConfigKey.keyIdentificadorDolares ?
        nuevoDatosCondicionFinanciamiento.cuotaInicial : (nuevoDatosCondicionFinanciamiento.cuotaInicial / tipoCambio).toFixed(2);
      let requestSimularOferta = {
        "identSolicitud": Number(solicitudId),
        "tea": nuevoDatosCondicionFinanciamiento.tea || 0,
        "inicial": Number(inicialDolares),
        "plazo": nuevoDatosCondicionFinanciamiento.cuota || 0,
        "cuotasDobles": nuevoDatosCondicionFinanciamiento.indicadorCuotaDoble,
        "comentario": nuevoDatosCondicionFinanciamiento.comentario || "",
        "identActualizarBD": false,
        "idenT_USUARIO_CREADOR": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0)
      };
      setShow(false);

      const responseSimularOferta = await RequestManager.GetMnSimularOfertasVehicularBySolicitud(requestSimularOferta);
      if (responseSimularOferta) {
        setDatosCondicionFinanciamientoInicial(nuevoDatosCondicionFinanciamiento);
        setNewCuota(responseSimularOferta.data.content.valorCuota);
        nuevoResultadoFinanciamiento = responseSimularOferta.data.content;
        setResultadoFinanciamiento({
          ...resultadoFinanciamiento,
          montoFinanciamiento: responseSimularOferta.data.content.montoFinanciamiento,
          montoCuota: responseSimularOferta.data.content.valorCuota,
          totalGastos: responseSimularOferta.data.content.totalGastos,
          tcea: `${responseSimularOferta.data.content.cft} %`,
          totalFinanciamiento: responseSimularOferta.data.content.totalFinanciamiento,
          cuotaDoble: responseSimularOferta.data.content.valorCuotaDoble
        });
        toast.success(appConfigKey.keyMsjRecalcularFinanciamiento, { duration: appConfigKey.keyDurationToast });
      }
    } catch (error: any) {
      let mensajeError: string = appConfigKey.keyMsjErrorEnServicioGenerico.replace('{data}', 'simular oferta vehícular');
      setResultadoFinanciamiento({
        ...resultadoFinanciamiento,
        montoFinanciamiento: 0,
        montoCuota: 0,
        totalGastos: 0,
        tcea: 'N/D',
        totalFinanciamiento: 0,
        cuotaDoble: 0
      })
      toast.error(mensajeError, { duration: appConfigKey.keyDurationToast });
    } finally {
      setShow(true);
      return nuevoResultadoFinanciamiento;
    }
  }


  const volverReevaluar = async (): Promise<boolean> => {

    if (!ValidacionResultadosEvaluacionHelper.NewReevaluarTitularIguales(titular.ingresos, titularBeforce.ingresos)) {
      return true;
    }


    if (titular?.otrosIngresos?.length > 0) {
      if (!ValidacionResultadosEvaluacionHelper.NewReevaluarOtrosIngresosIguales(titular?.otrosIngresos, titularBeforce?.otrosIngresos))
        return true;
    }

    if (((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) ||
      (estadoCivilTitular === appConfigKey.keyIdEstadoCivilConviviente && !esSeparadoBienes)) &&
      categoriaLaboralConyuge !== appConfigKey.keyIdSinCategoria) {
      if (!ValidacionResultadosEvaluacionHelper.NewReevaluarConyugeIguales(conyuge.ingresos, conyugeBeforce.ingresos)) {
        return true;
      }

      if (conyuge?.otrosIngresos?.length > 0) {
        if (!ValidacionResultadosEvaluacionHelper.NewReevaluarOtrosIngresosIguales(conyuge?.otrosIngresos, conyugeBeforce?.otrosIngresos))
          return true;
      }
    }

    if (!ValidacionResultadosEvaluacionHelper.NewDatosCondicionFinanciamientoIguales(datosCondicionFinanciamiento, datosCondicionFinanciamientoInicial))
      return true;


    return false;
  }

  const handleReevaluar = async () => {

    setShow(false);
    let esReevaluarValido = await esReevaluarCalculoMENyCFC();
    if (!esReevaluarValido) {
      toast.error(appConfigKey.keyMsjErrorEnServicioGenerico.replace('{data}', 'Modellica'),
        { duration: appConfigKey.keyDurationToast });
      setShow(true);
      return;
    }
    if (!esReevaluarValido?.esAprobadoSolicitud &&
      !esReevaluarValido?.esRechazadoSolicitud &&
      !esReevaluarValido?.esZonaGrisSolicitud) {
      toast.error(appConfigKey.keyMsjErrorEnServicioGenerico.replace('{data}', 'Modellica'),
        { duration: appConfigKey.keyDurationToast });
      setShow(true);
      return;
    }

    if (!ValidacionResultadosEvaluacionHelper.DatosFinanciamientoIguales(datosCondicionFinanciamientoInicial, datosCondicionFinanciamiento, tipoCambio)) {
      let nuevoResultadoFinanciamiento = await esReevaluarResultados();
      if (nuevoResultadoFinanciamiento) {
        if (esReevaluarValido.newMen < nuevoResultadoFinanciamiento.valorCuota) {
          setEsVisibleResultReevaluar(true);
        }
      }
    } else {
      if (esReevaluarValido.newMen < resultadoFinanciamiento?.montoCuota) {
        setEsVisibleResultReevaluar(true);
      }
    }

    toast.success(appConfigKey.keyMsjRecalcularScoring, { duration: appConfigKey.keyDurationToast });
    setShow(true);

  }

  // Validar Resultado Evaluacion y Guardar Gestor
  //const SaveGestor = async () => { await SaveResultadoEvaluacion(); }

  //******* Save Gestor          ****************************************************************************************************************/
  const [saveDialogOpen, setSaveDialogOpen] = React.useState<boolean>(false);
  const handleSaveDialogOpen = () => { setSaveDialogOpen(true) };
  const handleSaveDialogClose = () => { setSaveDialogOpen(false) };

  const SaveDialog = ({ open, handleClose }: any) => {
    return (
      <Dialog open={open} maxWidth={'xs'} >
        <div className='save-dialong-content'>
          <div className='save-dialog-title'>GUARDAR</div>
          <div className='save-dialog-list-content'>
            <div className="row g-2 mb-2 align-items-center text-center">
              <div className="col cardObjets align-top">
                ¿Esta seguro de finalizar su validación?
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-center px-1">
            <div className='save-dialog-btn-Save cursor-pointer mx-2' onClick={SaveResultadoEvaluacion}>Guardar</div>
            <div className='save-dialog-btn-close cursor-pointer mx-2' onClick={handleClose}>Cerrar</div>
          </div>
        </div>
      </Dialog>
    );
  };

  const postMnSaveGestor = async (newMEN: any, newCFC: any) => {

    let ConyugeIngresosRequest: any = null;
    let titularIngresosRequest: any = null;
    let titularSolicitudRccRequest: any = null;
    let conyugeSolicitudRccRequest: any = null;

    let ingresosPrincipal: any = {
      "identIngresosPersonaHistorico": 0,
      "identSolicitud": Number(solicitudId),
      "identPersona": titular.ingresos.idPersona,
      "identPersonaIngresos": titular.ingresos.idIngresoPersona,
      "tipoParticipacion": "titular",
      "montoIngresoNetoNuevo": Number(titular.ingresos.ingresoNeto),
      "indAnualizadoNuevo": titular.ingresos.ingresoAnualizado,
      "identUsuarioCreador": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
      "comentario": titular.ingresos.comentario,
      "fechaLaboral": titular.ingresos.fechaIngresoLaboral
    }
    let ingresosSecundarios: any[] = [];
    if (titular?.otrosIngresos?.length > 0) {
      titular?.otrosIngresos?.map((item: any) => {
        ingresosSecundarios.push({
          "identIngresosPersonaHistorico": 0,
          "identSolicitud": Number(solicitudId),
          "identPersona": titular.ingresos.idPersona,
          "identPersonaIngresos": item.idIngresoPersona,
          "tipoParticipacion": "titular",
          "montoIngresoNetoNuevo": Number(item.ingresoNeto),
          "indAnualizadoNuevo": item.ingresoAnualizado,
          "identUsuarioCreador": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
          "fechaLaboral": item.fechaIngresoLaboral,
          "comentario": item.comentario
        });
      })
    }

    titularIngresosRequest = {
      "ingresosPrincipal": ingresosPrincipal,
      "ingresosSecundarios": ingresosSecundarios
    }

    let datosTitular: any = null;
    if (typeof solicitud !== 'number') {
      datosTitular = members.users.find(item => item.nroDocumento === solicitud.numeroDocumento.toString().trim())
    }

    let jsonModificadoTitular: any = {
      "resultado": datosReevaluacionTitular.resultado
    }

    titularSolicitudRccRequest = {
      "tipoIntegrante": datosTitular?.tipoIntegrante,
      "tipoDocumento": datosTitular?.tipoDocumento,
      "numeroDocumento": datosTitular?.nroDocumento,
      "jsonModificado": JSON.stringify(jsonModificadoTitular),
      "identSolicitudRcc": datosReevaluacionTitular.ident_solicitud_rcc,
      "identSolicitud": Number(solicitudId),
    }

    if (((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) ||
      (estadoCivilTitular === appConfigKey.keyIdEstadoCivilConviviente && !esSeparadoBienes)) &&
      categoriaLaboralConyuge !== appConfigKey.keyIdSinCategoria) {
      ingresosPrincipal = {
        "identIngresosPersonaHistorico": 0,
        "identSolicitud": Number(solicitudId),
        "identPersona": conyuge.ingresos.idPersona,
        "identPersonaIngresos": conyuge.ingresos.idIngresoPersona,
        "tipoParticipacion": "conyuge",
        "montoIngresoNetoNuevo": Number(conyuge.ingresos.ingresoNeto),
        "indAnualizadoNuevo": conyuge.ingresos.ingresoAnualizado,
        "identUsuarioCreador": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
        "comentario": conyuge.ingresos.comentario,
        "fechaLaboral": conyuge.ingresos.fechaIngresoLaboral
      }

      ingresosSecundarios = [];
      if (conyuge?.otrosIngresos?.length > 0) {
        conyuge?.otrosIngresos?.map((item: any) => {
          ingresosSecundarios.push({
            "identIngresosPersonaHistorico": 0,
            "identSolicitud": Number(solicitudId),
            "identPersona": conyuge.ingresos.idPersona,
            "identPersonaIngresos": item.idIngresoPersona,
            "tipoParticipacion": "conyuge",
            "montoIngresoNetoNuevo": Number(item.ingresoNeto),
            "indAnualizadoNuevo": item.ingresoAnualizado,
            "identUsuarioCreador": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
            "comentario": item.comentario,
            "fechaLaboral": item.fechaIngresoLaboral
          })
        })
      }
      ConyugeIngresosRequest = {
        "ingresosPrincipal": ingresosPrincipal,
        "ingresosSecundarios": ingresosSecundarios
      }

      let datosConyuge: any = null;
      datosConyuge = members.users.find(item => item.tipoIntegrante.trim() === appConfigKey.keyDescripTipoIntegranteConyuge)

      let jsonModificadoConyuge: any = {
        "resultado": datosReevaluacionConyuge.resultado
      }

      conyugeSolicitudRccRequest = {
        "tipoIntegrante": datosConyuge?.tipoIntegrante,
        "tipoDocumento": datosConyuge?.tipoDocumento,
        "numeroDocumento": datosConyuge?.nroDocumento,
        "jsonModificado": JSON.stringify(jsonModificadoConyuge),
        "identSolicitudRcc": datosReevaluacionConyuge.ident_solicitud_rcc,
        "identSolicitud": Number(solicitudId),
      }

    }

    let inicialDolares = datosCondicionFinanciamiento.idmoneda === appConfigKey.keyIdentificadorDolares ?
      datosCondicionFinanciamiento.cuotaInicial : (datosCondicionFinanciamiento.cuotaInicial / tipoCambio).toFixed(2);

    const request = {
      "titularIngresosRequest": titularIngresosRequest,
      "ConyugeIngresosRequest": ConyugeIngresosRequest,
      "reCalcularRequest": {
        "nuevoMEM": newMEN,
        "nuevoCFC": newCFC
      },
      "cronogramaRequest": {
        "identSolicitud": Number(solicitudId),
        "tea": datosCondicionFinanciamiento.tea,
        "inicial": Number(inicialDolares),
        "plazo": datosCondicionFinanciamiento.cuota,
        "cuotasDobles": datosCondicionFinanciamiento.indicadorCuotaDoble,
        "comentario": datosCondicionFinanciamiento.comentario,
        "identActualizarBD": true,
        "idenT_USUARIO_CREADOR": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0)
      },
      "titularSolicitudRccRequest": titularSolicitudRccRequest,
      "conyugeSolicitudRccRequest": conyugeSolicitudRccRequest,
    }

    await RequestManager.SaveGestor(request);
  };
  //********************************************************************************************************************************************/


  //******* Resultado Evaluacion ***************************************************************************************************************//
  const [reqResultadoEvaluacion, setReqResultadoEvaluacion] = React.useState<ResultadoEvaluacionDto>(ResultadoEvalucionDtoDefault)

  const SaveResultadoEvaluacion = async () => {

    setShow(false);
    handleSaveDialogClose();

    const validarNewReqResultadoEvaluacion = !ValidacionResultadosEvaluacionHelper.NewReqResultadoEvaluacion(reqResultadoEvaluacion);
    if (validarNewReqResultadoEvaluacion) {
      setShow(true);
      return;
    }

    const { idPersonaCompraPara, idEstadoCivilTitular, idSolicitud, esPendienteEvaluar }: any = solicitud;


    if (!esPendienteEvaluar) {
      setShow(true);
      return;
    }


    let esReevaluarValidoAlGuardar = await esReevaluarCalculoMENyCFC();
    if (!esReevaluarValidoAlGuardar) {
      toast.error(appConfigKey.keyMsjErrorEnServicioGenerico.replace('{data}', 'Modellica'), { duration: appConfigKey.keyDurationToast });
      setShow(true);
      return;
    }
    if (!esReevaluarValidoAlGuardar?.esAprobadoSolicitud &&
      !esReevaluarValidoAlGuardar?.esRechazadoSolicitud &&
      !esReevaluarValidoAlGuardar.esZonaGrisSolicitud) {
      toast.error(appConfigKey.keyMsjErrorEnServicioGenerico.replace('{data}', 'Modellica'), { duration: appConfigKey.keyDurationToast });
      setShow(true);
      return;
    }


    if (!ValidacionResultadosEvaluacionHelper.DatosFinanciamientoIguales(datosCondicionFinanciamientoInicial, datosCondicionFinanciamiento, tipoCambio)) {
      let nuevoResultadoFinanciamiento = await esReevaluarResultados();
      if (nuevoResultadoFinanciamiento) {
        if (esReevaluarValidoAlGuardar.newMen < nuevoResultadoFinanciamiento.valorCuota) {
          setEsVisibleResultReevaluar(true);
        }
      }
    }
    else {
      if (esReevaluarValidoAlGuardar.newMen < resultadoFinanciamiento?.montoCuota) {
        setEsVisibleResultReevaluar(true);
      }
    }

    if (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado) {
      if (!isVisibleVerSustentoExcepcion && esReevaluarValidoAlGuardar?.esRechazadoSolicitud) {
        toast.error('No se puede guardar, revisar la reevaluación', { duration: appConfigKey.keyDurationToast });
        setShow(true);
        return;
      }
    }

    if (esReevaluarValidoAlGuardar?.noTieneProductosComerciales) {
      toast.error('No se puede guardar, revisar la reevaluación', { duration: appConfigKey.keyDurationToast });
      setShow(true);
      return;
    }

    if (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado) {
      if (esReevaluarValidoAlGuardar?.noCoincidenProducto) {
        toast.error('No se puede guardar, revisar la reevaluación', { duration: appConfigKey.keyDurationToast });
        setShow(true);
        return;
      }
    }

    if (reqResultadoEvaluacion.idenT_RESULTADO_EVALUACION !== appConfigKey.KeyIdResultadoAprobado) {
      await setReqResultadoEvaluacion({
        ...reqResultadoEvaluacion,
        idenT_CREDITO_TIPO: 0,
        descc_DETALLE: '',
        idenT_SUBTIPO: 0,
        numerO_VISOR: "",
      })
    }

    const listaSolicitudEtapas = await General.GetSolicitudEtapas(null, Number(solicitudId));
    let listaSolicitudEtapaRechazado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Rechazado)
    if (listaSolicitudEtapaRechazado.length > 0) {
      toast.error(appConfigKey.keyMsjContinuarSolicitudRechazada);
      setShow(true);
      return
    }

    let listaSolicitudEtapaAprobado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado ||
      x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion)
    if (listaSolicitudEtapaAprobado.length === listaSolicitudEtapas.length) {
      toast.error(appConfigKey.keyMsjContinuarSolicitudAprobada);
      setShow(true);
      return
    }


    let solicitudAnteriorAprobadoConDocumentos: any = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion && x.ordenProceso === 1);
    let solicitudConAutonomia: any = listaSolicitudEtapas.filter((x: any) => x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarCreditoAutonomia);

    if (solicitudAnteriorAprobadoConDocumentos.length > 0 && solicitudConAutonomia.length === 0) {
      toast.error(appConfigKey.keyMsjContinuarSolicitudAprobadaConDocumentos);
      setShow(true);
      return
    }

    let listaSolicitudValidacionDocumentalEnviada = listaSolicitudEtapas.filter((x: any) => x.identEtapa === appConfigEtapaAnalisisCredito.ValidacionDocumental &&
      x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Enviado)
    if (listaSolicitudValidacionDocumentalEnviada.length < 0) {
      toast.error(appConfigKey.keyMsjContinuarSolicitud);
      setShow(true);
      return
    }

    await MasterManager.GetMnResultadoEvaluacionInsert(reqResultadoEvaluacion)
      .then(async (result: any) => {

        if (!result.data?.isValid) {
          toast.error(appConfigKey.keyMsjErrorEnServicio, { duration: appConfigKey.keyDurationToast });
          setShow(true);
          return;
        }

        let solicitudEtapaValidacionDocumental = listaSolicitudEtapas.find((x: any) => x.identEtapa === appConfigEtapaAnalisisCredito.ValidacionDocumental);
        if (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado ||
          reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadorRechazado
        ) {

          await General.GetSolicitudEtapasUpdate(
            {
              "identSolicitudEtapas": solicitudEtapaValidacionDocumental.identSolicitudEtapas,
              "identSolicitud": solicitudEtapaValidacionDocumental.identSolicitud,
              "identDecisionMotor": solicitudEtapaValidacionDocumental.identDecisionMotor,
              "identEtapa": solicitudEtapaValidacionDocumental.identEtapa,
              "identProceso": solicitudEtapaValidacionDocumental.identProceso,
              "identEstadoProceso": reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado ? appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado :
                (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoObservado ? appConfigEstadosProcesoEtapaAnalisisCredito.Observado :
                  appConfigEstadosProcesoEtapaAnalisisCredito.Rechazado
                ),
              "indEstado": solicitudEtapaValidacionDocumental.indEstado,
              "identUsuarioModif": appSecurityConfiguration.SessionUserId,
              "ordenProceso": solicitudEtapaValidacionDocumental.ordenProceso
            }
          );


        } else {


          await General.GetSolicitudEtapasUpdate(
            {
              "identSolicitudEtapas": solicitudEtapaValidacionDocumental.identSolicitudEtapas,
              "identSolicitud": solicitudEtapaValidacionDocumental.identSolicitud,
              "identDecisionMotor": solicitudEtapaValidacionDocumental.identDecisionMotor,
              "identEtapa": solicitudEtapaValidacionDocumental.identEtapa,
              "identProceso": solicitudEtapaValidacionDocumental.identProceso,
              "identEstadoProceso": reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoAprobado ? appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado :
                (reqResultadoEvaluacion.idenT_RESULTADO === appConfigKey.KeyIdResultadoObservado ? appConfigEstadosProcesoEtapaAnalisisCredito.Observado :
                  appConfigEstadosProcesoEtapaAnalisisCredito.Rechazado
                ),
              "indEstado": solicitudEtapaValidacionDocumental.indEstado,
              "identUsuarioModif": appSecurityConfiguration.SessionUserId,
              "ordenProceso": solicitudEtapaValidacionDocumental.ordenProceso
            }
          );

          let esAprobadoAutomatico: boolean = listaSolicitudEtapas.filter((x: any) => x.identDecisionMotor === appConfigDecisionMotor.AprobadoAutomatico).length > 0;
          let esZonaGris: boolean = listaSolicitudEtapas.filter((x: any) => x.identDecisionMotor === appConfigDecisionMotor.ZonaGris).length > 0;
          let esRechazado: boolean = listaSolicitudEtapas.filter((x: any) => x.identDecisionMotor === appConfigDecisionMotor.Rechazado).length > 0;

          let solicitudEtapasRequest: any = [];

          let SolicitudEtapasInsertRequest: any = {
            identSolicitud: Number(solicitudId),
            identUsuarioCreador: appSecurityConfiguration.SessionUserId
          }
          if (esAprobadoAutomatico) {

            solicitudEtapasRequest = [];
            solicitudEtapasRequest.push({
              identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
              identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
              identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
              ordenProceso: 1
            });

            SolicitudEtapasInsertRequest.identDecisionMotor = appConfigDecisionMotor.AprobadoAutomatico
            SolicitudEtapasInsertRequest.solicitudEtapasRequest = solicitudEtapasRequest;
          }
          if (esZonaGris) {

            solicitudEtapasRequest = [];

            solicitudEtapasRequest.push({
              identEtapa: appConfigEtapaAnalisisCredito.EvaluacionCrediticia,
              identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion,
              identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
              ordenProceso: 1
            });

            solicitudEtapasRequest.push({
              identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
              identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
              identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
              ordenProceso: 2
            });

            SolicitudEtapasInsertRequest.identDecisionMotor = appConfigDecisionMotor.ZonaGris
            SolicitudEtapasInsertRequest.solicitudEtapasRequest = solicitudEtapasRequest;
          }
          if (esRechazado) {

            solicitudEtapasRequest = [];

            solicitudEtapasRequest.push({
              identEtapa: appConfigEtapaAnalisisCredito.EvaluacionCrediticia,
              identProceso: appConfigProcesoEtapaAnalisisCredito.SolicitarExcepcion,
              identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
              ordenProceso: 1
            });

            solicitudEtapasRequest.push({
              identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
              identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
              identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
              ordenProceso: 2
            });

            SolicitudEtapasInsertRequest.identDecisionMotor = appConfigDecisionMotor.Rechazado
            SolicitudEtapasInsertRequest.solicitudEtapasRequest = solicitudEtapasRequest;

          }

          General.GetSolicitudEtapasInsert(SolicitudEtapasInsertRequest)

        }
        await postMnSaveGestor(esReevaluarValidoAlGuardar.newMen, esReevaluarValidoAlGuardar.newCfc);

        setReqResultadoEvaluacion({
          ...reqResultadoEvaluacion, idenT_RESULTADO: 0, idenT_DESTINO_CREDITO: 0, comentario: "",
          idenT_TIPO_RESULTADO: 0,
          descc_DETALLE: "",
          idenT_CREDITO_TIPO: 0,
          idenT_SUBTIPO: 0,
          numerO_VISOR: "",
        });
        handleNewResultadoHistorico();

        if (reqResultadoEvaluacion.idenT_RESULTADO === 78) {
          if (idPersonaCompraPara != null && idPersonaCompraPara !== 0) {
            //Reporte contrato Persona Compra Para : 77        
            await generarDescargaContrato(idSolicitud, Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0), 77, false, "");
          }
          //Soltero
          else if (idEstadoCivilTitular === 1) {
            //Reporte contrato Soltero : 74
            await generarDescargaContrato(idSolicitud, Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0), 74, false, "");
          }
          else if (idEstadoCivilTitular === 2 || idEstadoCivilTitular === 3 && !esSeparadoBienes) {
            //Reporte contrato Casado : 75
            await generarDescargaContrato(idSolicitud, Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0), 75, false, "");
          }
          else if (idEstadoCivilTitular === 4) {
            //Reporte contrato Soltero : 74 (Viudo)
            await generarDescargaContrato(idSolicitud, Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0), 74, false, "");
          }
          else if (idEstadoCivilTitular === 5) {
            //Reporte contrato Divorciado : 78
            await generarDescargaContrato(idSolicitud, Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0), 78, false, "");
          }
        }

      })
      .then(async (result: any) => {
        if (esReevaluarValidoAlGuardar.responseMotorAfterStepThree) {
          await Promise.all([
            GetMnEvaluacionSolicitudInsert(
              Number(solicitudId),
              appConfigKey.EvaluacionModelicaStepOne,
              JSON.stringify(esReevaluarValidoAlGuardar.requestMotorAfterStepOne),
              JSON.stringify(esReevaluarValidoAlGuardar.responseMotorAfterStepOne)
            ),
            GetMnEvaluacionSolicitudInsert(
              Number(solicitudId),
              appConfigKey.EvaluacionModelicaStepTwo,
              JSON.stringify(esReevaluarValidoAlGuardar.requestMotorAfterStepTwo),
              JSON.stringify(esReevaluarValidoAlGuardar.responseMotorAfterStepTwo)
            ),
            GetMnEvaluacionSolicitudInsert(
              Number(solicitudId),
              appConfigKey.EvaluacionModelicaStepThree,
              JSON.stringify(esReevaluarValidoAlGuardar.requestMotorAfterStepThree),
              JSON.stringify(esReevaluarValidoAlGuardar.responseMotorAfterStepThree)
            )
          ])
        }
        else {
          await Promise.all([
            GetMnEvaluacionSolicitudInsert(
              Number(solicitudId),
              appConfigKey.EvaluacionModelicaStepOne,
              JSON.stringify(esReevaluarValidoAlGuardar.requestMotorAfterStepOne),
              JSON.stringify(esReevaluarValidoAlGuardar.responseMotorAfterStepOne)
            ),
            GetMnEvaluacionSolicitudInsert(
              Number(solicitudId),
              appConfigKey.EvaluacionModelicaStepTwo,
              JSON.stringify(esReevaluarValidoAlGuardar.requestMotorAfterStepTwo),
              JSON.stringify(esReevaluarValidoAlGuardar.responseMotorAfterStepTwo)
            )
          ])
        }

        setShow(true);
        await toast.success(appConfigKey.keyMsjSaveGestorsuccess, { duration: appConfigKey.keyDurationToast });
        handleSaveDialogClose();
        navigate(`/BandejaSolicitudes/${appConfigKey.keyTipoBusquedaPorDocumentalSeguimiento}`);

      })
      .catch(async (error) => {
        // setLoadingResulEva(false);
        setShow(true);
        //handleChangeResultadoEvaluacion(false);

        if (error.response?.data) {
          let listExceptions: any = error.response.data.exceptions;
          if (listExceptions?.length > 0) {
            toast.error(appConfigKey.keyMsjErrorEnResultado, { duration: appConfigKey.keyDurationToast });
          } else {
            toast.error(appConfigKey.keyMsjErrorEnServicio, { duration: appConfigKey.keyDurationToast });
          }
        } else {
          toast.error(appConfigKey.keyMsjErrorEnServicio, { duration: appConfigKey.keyDurationToast });
        }
      })
      .finally(() => {
        setShow(true);
      });

  }

  const asociarDocumentoDigital = async (documentoId: any, tipoDocumentoId: number, nombre: any, archivoCodificado: any, fechaEmision: any, fechaVencimiento: any, estadoAdjunto: number, fulldata: any, operacionId: number) => {
    var responseAsociarDocumentoDigital = await ServicesProvider.AsociarDocumentoDigital({ tipoDocumentoId, nombre, archivoCodificado, fechaEmision, fechaVencimiento }, operacionId);
    const body = {
      "idenT_DOCUMENTACION_DOCUMENTO": 0,
      "idenT_SOLICITUD": solicitudId,
      "idenT_TIPO_LISTADO": 2,
      "descC_TIPO_LISTADO": "CHECKLIST2",
      "idenT_TIPO_DOCUMENTO": 65,
      "descC_TIPO_DOCUMENTO": "Carta Confirmación",
      "idenT_ESTADO_ADJUNTO": appConfigKey.keyIdEstadoDocumentoAdjuntado,
      "descC_ESTADO_ADJUNTO": appConfigKey.keyDescripEstadoDocumentoAdjuntado,
      "descC_NOMBRE_ARCHIVO": nombre.split(".")[0],
      "descC_EXTENSION_ARCHIVO": `.${nombre.split(".")[1]}`,
      "idenT_DOCUMENTO_BT": responseAsociarDocumentoDigital?.data?.content?.documentoId,
      "idenT_USUARIO": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0)
    };
    await MasterManager.GetMnDocumentacionDocumentoInsert(body);
  }

  function fileToBase64(file: any) {
    return new Promise<string>((resolve, reject) => {
      const reader: any = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        const base64String: string = reader.result.split(',')[1];
        resolve(base64String);
      };
      reader.onerror = (error: any) => reject(error);
    });
  }

  function obtenerFechaActual(addYear: number) {
    const fecha = new Date();
    const anio = fecha.getFullYear();
    const mes = String(fecha.getMonth() + 1).padStart(2, '0'); // El mes es zero-based, por lo que se suma 1
    const dia = String(fecha.getDate()).padStart(2, '0');
    const fechaActual = `${anio + addYear}-${mes}-${dia}`;
    return fechaActual;
  }

  const generarDescargaContrato = async (solicitudId: number, usuarioId: number, reporteId: number, bpo: boolean, data: any) => {
    const response: any = await descargarReporte(solicitudId, usuarioId, reporteId, bpo);
    if (!response) {
      toast.error('Error al descargar el documento.', { duration: appConfigKey.keyDurationToast });
      return;
    }
    var fileName = null;
    var header = response?.headers['content-disposition'];
    if (header) {
      var filenameRegex = /filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(header);
      if (matches != null && matches[1]) {
        //fileName = matches[1].replace(/['"UTF\-8]/g, '');
        fileName = matches[1].replace(/UTF-8['"]*/g, '');
        fileName = decodeURI(fileName);

        const tipoDocumentoId = 65;
        const fileNames = fileName;
        const archivo = await fileToBase64(response?.data);
        const fechaActual = obtenerFechaActual(0);
        const fechaVencimiento = "";//obtenerFechaActual(1);
        const estadoAdjunto = 98;
        const documentoId = 65;

        var idOperacion = 0;
        const tempSolicitud = await MasterManager.GetMnSolicitud({ idenT_SOLICITUD: solicitudId });
        idOperacion = tempSolicitud.data.content[0].idenT_OPERACION;

        await asociarDocumentoDigital(documentoId, tipoDocumentoId, fileNames, archivo, fechaActual, fechaVencimiento, estadoAdjunto, data, idOperacion);
      }
    }

    if (!fileName) {
      fileName = `${data.genericA_DESCM_CAMPO}_${solicitudId}.docx`;
    }

    const url = window.URL.createObjectURL(response?.data);

    const a = document.createElement("a");
    a.href = url;
    a.download = fileName!;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  const descargarReporte = async (solicitudId: number, usuarioId: number, reporteId: number, bpo: boolean) => {
    try {
      const responseObtenerReporte = await ReportManager.ObtenerReporte({
        "idSolicitud": solicitudId,
        "idUsuario": usuarioId,
        "idReporte": reporteId,
        "indBPO": bpo
      });

      if (responseObtenerReporte.status === 200) {
        return responseObtenerReporte;
      }
    } catch (error) {
      //myowasp('Ocurrió un error al asociar el documento');
      return null;
    }
  };
  //********************************************************************************************************************************************/

  const handleVerClasifPorIntegrante = async (tipoIntegrante: string, numeroDocumento: string, tipoDocumento: string) => {

    if (tipoIntegrante === appConfigKey.keyDescripTipoIntegranteConyuge && esSeparadoBienes) {
      return
    }

    //setShow(false);
    setInformacionEntidad(InformacioEntidadDefault);
    setClasificacion(0)
    setTipoIntegranteClasif(tipoIntegrante?.toUpperCase());
    const dtoClasificacion: ClasificacionDto = {
      solicitud: String(solicitudId),
      tipo_integrante: tipoIntegrante,
      documento: numeroDocumento,
      tipo_documento: tipoDocumento
    };
    const reqClasificacion: reqClasificacionDto = {
      request: dtoClasificacion
    }

    setEsVisibleSDR(true)
    if (tipoIntegrante === appConfigKey.keyDescripTipoIntegranteTitular) {
      setDefaultValueTab(0)
    } else if (tipoIntegrante === appConfigKey.keyDescripTipoIntegranteConyuge) {
      setDefaultValueTab(1)
      if (conyuge.ingresos.idcategoriaLaboral === appConfigKey.keyIdSinCategoria) {
        setEsVisibleSDR(false)
      }
    } else {
      setEsVisibleSDR(false)
    }

    await getWorkflowClasificacion(reqClasificacion).then((response: any) => {
    }).finally(() => {
      //setShow(true);
    });
  }

  const NewModelicaData = (response: any, step: number) => {
    let mensajeModelica = "";

    try {
      if (!response) {
        mensajeModelica = `Llamada ${step}: ${appConfigKey.keyMsjErrorEnConsultaMotor}`;
        return mensajeModelica;
      }

      const { data } = response;

      if (!data?.isValid) {
        mensajeModelica = `Llamada ${step}: ${appConfigKey.keyMsjErrorEnConsultaMotor}`;
        return mensajeModelica;
      }

      const responseModelica = JSON.parse(data.content);
      if (!responseModelica.response?.Politica_Creditos) {
        mensajeModelica = `Llamada ${step}: ${responseModelica.response?.error?.message}`;
        return mensajeModelica;
      }
    } catch (error) {
      mensajeModelica = `Llamada ${step}: ${appConfigKey.keyMsjErrorEnConsultaMotor}`;
      return mensajeModelica;
    }
  };

  const ModellicaMotorEvaluacion = async (body: any) => {
    try {
      const response = await ServicesProvider.ModellicaMotorEvaluacion(body);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE EVALUACION DE MODELICA.');
      return null;
    }
  }

  const GetMnEvaluacionSolicitud = async (identSolicitud: any) => {
    let evaluaciones = [];
    try {
      let response = await MasterManager.GetMnEvaluacionSolicitud(identSolicitud);
      if (response && response.status === 200) {
        evaluaciones = response.data.content;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LA EVALUACION DE LA SOLICITUD.');
    }
    return evaluaciones;
  }

  const getRequestYResponseModellicaMotor = async () => {
    const tempEvaluacionSolicitud = await GetMnEvaluacionSolicitud(solicitudId);
    if (tempEvaluacionSolicitud.length > 0) {
      for (let evaluacion of tempEvaluacionSolicitud) {
        let request = evaluacion?.jsoN_REQUEST ? JSON.parse(evaluacion.jsoN_REQUEST) : null;
        let response = evaluacion?.jsoN_RESPONSE ? JSON.parse(evaluacion.jsoN_RESPONSE) : null;

        if (nvlRiesgo === null && response?.response?.Politica_Creditos?.decision_Credito?.nivel_Riesgo) {
          console.log('Setting nvlRiesgo:', response.response.Politica_Creditos.decision_Credito.nivel_Riesgo);
          setnvlRiesgo(response.response.Politica_Creditos.decision_Credito.nivel_Riesgo);
        }

        if (evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepOne) {
          setRequestMotorBeforceStepOne(request);
          setResponseMotorBeforceStepOne(response);
        }

        if (evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepTwo) {
          setRequestMotorBeforceStepTwo(request);
          setResponseMotorBeforceStepTwo(response);
          setResponseMotorMotivo(null)

          let tempDecision_Credito: any = response?.response?.Politica_Creditos?.decision_Credito;
          if (tempDecision_Credito?.decision === appConfigKey.CodigoEvaluacionZonaGris) {
            let motivos: any = tempDecision_Credito?.mesa_Creditos?.motivo_Derivacion;
            let listaMotivos: any = [];
            if (motivos) {
              for (let motivo of motivos) {
                listaMotivos.push(motivo.detalle)
              }
            }
            setResponseMotorMotivo(listaMotivos)
          }

          if (tempDecision_Credito?.decision === appConfigKey.CodigoEvaluacionRechazado) {

            let tempMotivo_Rechazo: any = response?.response?.Politica_Creditos?.motivo_Rechazo;
            let motivos: any = tempMotivo_Rechazo?.Detalle;
            let listaMotivos: any = [];
            if (motivos) {
              for (let motivo of motivos) {
                listaMotivos.push(motivo.interviniente + ': ' + motivo.descripcion)
              }
            }
            setResponseMotorMotivo(listaMotivos)
          }
        }
        if (evaluacion.tipO_LLAMADA === appConfigKey.EvaluacionModelicaStepThree) {
          setRequestMotorBeforceStepThree(request);
          setResponseMotorBeforceStepThree(response);
        }
      }
    }
  }

  const obtenerConfigAccionesPorSolicitud = async () => {
    setIsVisibleVerSustentoExcepcion(false);
    const response = await General.GetSolicitudEtapas(null, Number(solicitudId));
    if (response.length > 0) {
      let solicitudConExcepcion: any = response.filter((x: any) => x.identProceso === appConfigProcesoEtapaAnalisisCredito.SolicitarExcepcion);
      if (solicitudConExcepcion.length > 0) {
        setIsVisibleVerSustentoExcepcion(true);
      }
    }
  }
  const InitDefaultValidacionDocumental = async () => {
    await Promise.all([
      obtenerConfigAccionesPorSolicitud(),
      getObtenerTipoDeuda(),
      getObtenerSegmento(),
      getRequestYResponseModellicaMotor(),
      getMnEvaluacionScoringGestor()
    ])
  }


  React.useEffect(() => {
    InitDefaultValidacionDocumental();
  }, [nvlRiesgo])

  useEffect(() => {
    console.log('nvlRiesgo updated:', nvlRiesgo);
  }, [nvlRiesgo]);

  return (
    <div>
      <Toaster position="top-center" toastOptions={{ style: { width: '500px', margin: '0 auto' } }} richColors closeButton />
      <MainLayout />
      <CardTitleStart Ventana={title} identSolicitud={solicitudId} />
      <CardTitleSummary solicitud={solicitud} />
      <CardAlerts resultadoFinancimaiento={resultadoFinanciamiento} solicitud={solicitud}></CardAlerts>
      {!show && <LoadingProgress />}

      {
        <div className="row g-2 mb-2 cardAnalystProfile">
          <div className="col-lg-9">
            <CardTitleScore
              scoring={nvlRiesgo}
              setScoring={setnvlRiesgo}
              resultadoEvaluacionResumen={resultadoEvaluacionResumen}
              setResultadoEvaluacionResumen={setResultadoEvaluacionResumen}
              motorMotivos={responseMotorMotivo} />

            {esVisibleResultReevaluar && <CardResultReevaluar responseMotorAfterStepTwo={responseMotorAfterStepTwo} responseMotorBeforceStepTwo={responseMotorBeforceStepTwo} listaSegmento={listaSegmento} newMEN={newMEN} newCuota={newCuota} solicitud={solicitud} />}

            <div className="row mb-2">
              <div className="col-lg-6"></div>
              <div className="col-lg-6 bottom-right">
                <Button
                  size="md"
                  color="danger"
                  disabled={esModoLectura}
                  style={{ marginRight: "35px" }}
                  sx={{ borderRadius: 24, width: 3 / 10 }}
                  onClick={handleReevaluar}
                >
                  Reevaluar
                </Button>
              </div>
            </div>
            <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
              <Tabs value={tabsPosition} onChange={handleTabsPosition}>
                {headerTitles.map((header, index) => (
                  <Tab
                    key={index}
                    label={header}
                    className="header-tab-documentation"
                  />
                ))}
              </Tabs>
            </div>
            {tabsPosition === 0 && (
              <>
                <ScoringTitular titular={titular} titularBeforce={titularBeforce} setTitular={setTitular} handleReevaluar={handleReevaluar} esModoLectura={esModoLectura} datosCondicionFinanciamiento={datosCondicionFinanciamiento} setDatosCondicionFinanciamiento={setDatosCondicionFinanciamiento} />
                {(((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) ||
                  (estadoCivilTitular === appConfigKey.keyIdEstadoCivilConviviente && !esSeparadoBienes)) && categoriaLaboralConyuge !== appConfigKey.keyIdSinCategoria) && <ScoringConyuge conyuge={conyuge} conyugeBeforce={conyugeBeforce} setConyuge={setConyuge} handleReevaluar={handleReevaluar} showConyugue={showConyugue} esModoLectura={esModoLectura} />}
                <ScoringCliente posicionCliente={posicionCliente} setPosicionCliente={setPosicionCliente} show={show} />
                <ScoringClasificacionSBS clasificacion={clasificacion} tipoIntegranteClasif={tipoIntegranteClasif} />
                {esVisibleSDR && <ScoringDatosReevaluacion datosReevaluacionTitular={datosReevaluacionTitular} setDatosReevaluacionTitular={setDatosReevaluacionTitular} datosReevaluacionConyuge={datosReevaluacionConyuge} setDatosReevaluacionConyuge={setDatosReevaluacionConyuge} estadoCivilTitular={estadoCivilTitular} handleReevaluar={handleReevaluar} listaTipoDeuda={listaTipoDeuda} datosReevaluacionOrigenTitular={datosReevaluacionOrigenTitular} datosReevaluacionOrigenConyuge={datosReevaluacionOrigenConyuge} defaultValueTab={defaultValueTab}
                  esModoLectura={esModoLectura} clasificacionTitular={clasificacionTitular} setClasificacionTitular={setClasificacionTitular} clasificacionConyuge={clasificacionConyuge} setClasificacionConyuge={setClasificacionConyuge} categoriaLaboralConyuge={categoriaLaboralConyuge} handleVerClasifPorIntegrante={handleVerClasifPorIntegrante} members={members}
                  clasificacionTitularOriginal={clasificacionTitularOriginal} clasificacionConyugeOriginal={clasificacionConyugeOriginal} setShow={setShow} esSeparadoBienes={esSeparadoBienes} />}
                <ScoringInformacionEntidadesSBS clasificacion={clasificacion} informacionEntidad={informacionEntidad} tipoIntegranteClasif={tipoIntegranteClasif} />
              </>
            )}
            {tabsPosition === 1 && (
              <>
                <FinanciamientoDatosCondiciones
                  datosCondicionFinanciamiento={datosCondicionFinanciamiento}
                  datosCondicionFinanciamientoOriginal={datosCondicionFinanciamientoOriginal}
                  resultadoFinanciamiento={resultadoFinanciamiento}
                  setDatosCondicionFinanciamiento={setDatosCondicionFinanciamiento}
                  setShow={setShow}
                  setResult={setResultadoFinanciamiento}
                  esModoLectura={esModoLectura}
                  cuotas={cuotas}
                  solicitud={solicitud}
                  titular={titular}
                  conyugue={conyuge}
                />
              </>
            )}
            {tabsPosition === 2 && (
              <>
                <ResultadosDocumentos solicitud={solicitud} esModoLectura={esModoLectura} />
                <ResultadosEvaluacion handleNewResultadoHistorico={handleNewResultadoHistorico} solicitud={solicitud} esModoLectura={esModoLectura} reqResultadoEvaluacion={reqResultadoEvaluacion} setReqResultadoEvaluacion={setReqResultadoEvaluacion} />

                <div className="row mb-2 align-items-center ">
                  <div className="col-lg-6 cardObjets"></div>
                  <div className="col-lg-6 cardObjets bottom-right">
                    <Button
                      size="md"
                      color="danger"
                      endDecorator={<KeyboardArrowRight />}
                      style={{ marginRight: "35px" }}
                      sx={{ borderRadius: 24, width: 3 / 10 }}
                      onClick={handleSaveDialogOpen}
                      // onClick={SaveGestor}
                      disabled={esModoLectura}
                    >
                      Guardar
                    </Button>
                  </div>
                </div>

                <ResultadosHistorial flgResultadosHistorial={flgResultadosHistorial} />
              </>
            )}
            {/* <ScoringPosicionCliente 
            posicionCliente={posicionCliente}
          /> */}

            <div className="row g-2 mb-2 align-items-center ">
              <div className="col-lg-6 cardObjets"></div>
              <div className="col-lg-6 cardObjets bottom-right">
                {/* <Button
                  size="md"
                  color="danger"
                  endDecorator={<KeyboardArrowRight />}
                  style={{ marginRight: "35px" }}
                  sx={{ borderRadius: 24, width: 3 / 10 }}
                  onClick={(e) => handleNextClick()}
                >
                  Continuar
                </Button> */}

                {/* {tabsPosition === 2 && (
                  <Button
                    size="md"
                    color="danger"
                    endDecorator={<KeyboardArrowRight />}
                    style={{ marginRight: "35px" }}
                    sx={{ borderRadius: 24, width: 3 / 10 }}
                    onClick={handleSaveDialogOpen}
                    disabled={esModoLectura}
                  >
                    Guardar
                  </Button>
                )} */}
              </div>
            </div>
          </div>
          <div className="col-lg-3">
            <div style={{ position: "sticky", top: "0" }}>
              <CardDebst debts={debts} men={newMEN} tipoCambio={tipoCambio} montoCuota={resultadoFinanciamiento?.montoCuota || 0} />
              {tabsPosition === 0 && <CardMembers members={members} handleVerClasifPorIntegrante={handleVerClasifPorIntegrante} />}
              {[1, 2].indexOf(tabsPosition) > -1 && (
                <CardResult result={resultadoFinanciamiento} />
              )}
            </div>
          </div>
        </div>
      }

      <SaveDialog
        open={saveDialogOpen}
        handleClose={handleSaveDialogClose}
      />

    </div>
  );
};

export default AnalysProfile;
