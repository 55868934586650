import "./matrizEvaluacion.scss"
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { appConfigKey, appConfigMatrizTerceraTablas, appSecurityConfiguration } from "../../../../config/Config";
import { toast, Toaster } from "sonner";
import { ConvertidorTablaHelper } from "../../1.-shared/1.6.-table/1.6.1.-regimen/helpers/ConvertidorTablaHelper";
import MatrizDatosCredito from "../../13.-creditEvaluation/13.60.-matriz/matrizDatosCredito";
import MatrizBalanceGeneral from "../../13.-creditEvaluation/13.60.-matriz/matrizBalanceGeneral";
import MatrizFlujoCaja from "../../13.-creditEvaluation/13.60.-matriz/matrizFlujoCaja";
import LoadingProgress from "../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import MainLayout from "../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout";
import MatrizEvaluacionCardTitle from "./components/matrizEvaluacionCardTitle";
import { MatrizEvaluacionProvider } from "./providers/matrizEvaluacionProvider";
import { MnInformeTerceraRequest } from "../../../models/RequestManager/solicitud-informel-tercera-request.model";
import { InformacionTercera } from "../../../models/ManagerProfile/InformacionTercera";
import { General } from "./obtenerServicios";
import { MnFormatoAuditorByIdRequest, MnFormatoAuditorRequest } from "../../../models/RequestManager/solicitud-formato-auditor-request.model";
import { ConvertidorHelper } from "./helpers/ConvertidorHelper";
import { Button } from "@mui/joy";
import ArrowLeft from '../../../../assets/media/icons/ico_arrowleft.svg';

export default function MatrizEvaluacionAuditor({ isModal, solicitudId }: any) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [matrizRegimen, setMatrizRegimen] = React.useState<any | null>(null);
  const [capacidadPagoTabla, setCapacidadPagoTabla] = React.useState<any[] | null>(null);
  const [perdidaGananciaTabla, setPerdidaGananciaTabla] = React.useState<any[] | null>(null);
  const [balanceGeneralTabla, setBalanceGeneralTabla] = React.useState<any[] | null>(null);
  const [flujoCajaTabla, setFlujoCajaTabla] = React.useState<any[] | null>(null);
  const [esModoLectura, setEsModoLectura] = React.useState(false);
  const [informacionTercera, setInformacionTercera] = React.useState<InformacionTercera | number>(0);

  let navigate = useNavigate();

  let urlParams = new URLSearchParams(window.location.search);
  const IdSolicitud = solicitudId ?? urlParams.get('Solicitud');
  const { solicitudRow } = useLocation()?.state ?? {};

  const getMatrizRegimen = async (identInformeComercial: any) => {
    if (!identInformeComercial) {
      toast.error(appConfigKey.keyMsjErrorRequestInformeComercialFormatoRegimen, { duration: appConfigKey.keyDurationToast });
      setMatrizRegimen(null);
      return false;
    }

    let formatoRequest = new MnFormatoAuditorByIdRequest();
    formatoRequest.identInformeComercial = identInformeComercial;
    const response = await General.GetMnFormatoAuditorById(formatoRequest);
    if (!response) {
      toast.error(appConfigKey.keyMsjErrorObtenerFormatoRegimen, { duration: appConfigKey.keyDurationToast });
      setMatrizRegimen(null);
      return false;
    }

    const regimenResponse = ConvertidorHelper.ObtenerRegimenDto(response);
    const newMatrizRegimen = ConvertidorTablaHelper.ObtenerValoresPorPerfil(regimenResponse);
    const newCapacidadPagoTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizGeneral, appConfigMatrizTerceraTablas.CapacidadPago);
    const newPerdidaGananciaTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizGeneral, appConfigMatrizTerceraTablas.EstadoPerdidasGanancias);
    const newBalanceGeneralTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizGeneral, appConfigMatrizTerceraTablas.BalanceGeneral);
    const newFlujoCajaTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizGeneral, appConfigMatrizTerceraTablas.FlujoCaja);

    setMatrizRegimen(newMatrizRegimen);
    setCapacidadPagoTabla(newCapacidadPagoTabla);
    setPerdidaGananciaTabla(newPerdidaGananciaTabla);
    setBalanceGeneralTabla(newBalanceGeneralTabla);
    setFlujoCajaTabla(newFlujoCajaTabla);

    return true;
  }

  const buildMatrizRegimen = () => {
    if (!matrizRegimen) {
      setCapacidadPagoTabla(null);
      setPerdidaGananciaTabla(null);
      setBalanceGeneralTabla(null);
      setFlujoCajaTabla(null);
      return;
    }

    const newMatrizRegimen = ConvertidorTablaHelper.ObtenerValoresPorMatriz(matrizRegimen);
    const newCapacidadPagoTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizGeneral, appConfigMatrizTerceraTablas.CapacidadPago);
    const newPerdidaGananciaTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizGeneral, appConfigMatrizTerceraTablas.EstadoPerdidasGanancias);
    const newBalanceGeneralTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizGeneral, appConfigMatrizTerceraTablas.BalanceGeneral);
    const newFlujoCajaTabla = ConvertidorTablaHelper.ObtenerMatrizTablaPorNombre(newMatrizRegimen.matrizGeneral, appConfigMatrizTerceraTablas.FlujoCaja);

    setMatrizRegimen(newMatrizRegimen);
    setCapacidadPagoTabla(newCapacidadPagoTabla);
    setPerdidaGananciaTabla(newPerdidaGananciaTabla);
    setBalanceGeneralTabla(newBalanceGeneralTabla);
    setFlujoCajaTabla(newFlujoCajaTabla);

  }

  const GetCardMatrizEvaluacionClassName = (isModal: boolean | null | undefined) => {
    let className = 'row g-3 mb-2 cardMatrizEvaluacion';
    if (isModal) className += 'Modal';
    return className;
  };

  const getMnEvaluacionScoringTercera = async () => {
    setIsLoading(true);
    const solicitudResponse = await General.GetMnSolicitud(IdSolicitud)
    if (!solicitudResponse) {
      setIsLoading(false);
      toast.error('Falló la obtención de solicitud.', { duration: appConfigKey.keyDurationToast });
      return;
    }

    const evaluacionTercera = await General.GetMnEvaluacionScoringTercera({
      identSolicitud: IdSolicitud,
    } as MnInformeTerceraRequest);

    if (!evaluacionTercera) {
      setIsLoading(false);
      toast.error('Falló la obtención de evaluación.', { duration: appConfigKey.keyDurationToast });
      return;
    }

    setInformacionTercera({ ...evaluacionTercera });
    await getMatrizRegimen(solicitudRow?.idenT_INFORME_COMERCIAL || solicitudResponse.idenT_INFORME_COMERCIAL);

    setIsLoading(false);
  }

  const handleBackButton = (event: any) => {
    event.preventDefault();
    navigate(`/BandejaSolicitudes/AdmRiesgosPorGestionar`);
  }

  const handleSaveButton = async (event: any) => {
    event.preventDefault();
    setIsLoading(true);

    const updateResponse = await General.UpdateMnFormatoAuditor({
      identFormatoAuditor: matrizRegimen.identFormatoRegistro,
      identUsuario: Number(appSecurityConfiguration.SessionUserId ?? localStorage.getItem('UserMn') ?? 0),
      jsonTable: JSON.stringify(matrizRegimen.matrizGeneral)
    } as MnFormatoAuditorRequest);

    setIsLoading(false);
    if (!updateResponse) {
      toast.error(appConfigKey.keyMsjErrorActualizarFormatoRegimen, { duration: appConfigKey.keyDurationToast });
    } else {
      toast.success(appConfigKey.keyMsjActualizarFormatoRegimen, { duration: appConfigKey.keyDurationToast });
    }
  }

  useEffect(() => {
    getMnEvaluacionScoringTercera();
  }, [])

  return (
    <div>
      {isLoading && <LoadingProgress />}
      <Toaster position="top-center" toastOptions={{ style: { width: '500px', margin: '0 auto' } }} richColors closeButton />

      {!isModal &&
        <>
          <MainLayout />
          <MatrizEvaluacionCardTitle identification={IdSolicitud} />
        </>
      }

      <MatrizEvaluacionProvider solicitudId={IdSolicitud}>
        <div className={GetCardMatrizEvaluacionClassName(isModal)}>
          <MatrizDatosCredito
            capacidadPagoTabla={capacidadPagoTabla ?? []}
            onCapacidadPagoTablaChange={buildMatrizRegimen}
            perdidaGananciaTabla={perdidaGananciaTabla ?? []}
            onPerdidaGananciaTablaChange={buildMatrizRegimen}
            informacionTercera={informacionTercera}
            setInformacionTercera={setInformacionTercera}
            esModoLectura={esModoLectura}
          />
          <MatrizBalanceGeneral
            balanceGeneralTabla={balanceGeneralTabla ?? []}
            onBalanceGeneralTablaChange={buildMatrizRegimen}
            esModoLectura={esModoLectura}
          />
          <MatrizFlujoCaja
            flujoCajaTabla={flujoCajaTabla ?? []}
            onFlujoCajaTablaChange={buildMatrizRegimen}
            esModoLectura={esModoLectura}
          />
          <div className='col pb-4'>
            <div className="row">
              <div className="col d-flex justify-content-start g-1">
                <Button onClick={handleBackButton} size="lg" color="neutral" sx={{ borderRadius: 24, paddingLeft: '50px', paddingRight: '50px' }}>
                  <img src={ArrowLeft} alt='arrow-left' className="me-4" /> Regresar
                </Button>
              </div>
              <div className="col d-flex justify-content-end g-1">
                <Button onClick={handleSaveButton} size="lg" color="danger" sx={{ borderRadius: 24, paddingLeft: '50px', paddingRight: '50px' }}>
                  Guardar
                </Button>
              </div>
            </div>
          </div>
        </div>
      </MatrizEvaluacionProvider>

    </div>
  );
};