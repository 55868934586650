import { FormControl, FormLabel, Input } from "@mui/joy";

export const TextInput = (props: any) => {
    return (
        <FormControl>
            {props.title !== undefined && <FormLabel>{props.title}</FormLabel>}
            <Input
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                sx={{
                    '--Input-radius': '16px',
                }}
                onChange={(event: any) => {
                    if (props.validation) {
                        const newValue = event.target.value;
                        const validation = /^[a-zA-ZáéíóúÁÉÍÓÚ\s.]*$/.test(newValue);

                        if (!validation) {
                            event.preventDefault();
                            return;
                        }
                    }

                    props.onChange(event);
                }}
                disabled={props.disabled}
                startDecorator={props.startDecorator}
            />
        </FormControl>
    );
};