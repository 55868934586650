import { appConfigKey, appDefaultValues } from "../../../../config/Config";
import GeneralHelper from "../../../helpers/GeneralHelper";

export const ObtenerModeloSolicitudAsociado = (
    data: any,
    nombres: string,
    uid: number | null,
    identTipoRelacion: number,
    identUsuario: number | null,
) => {

    let nombreCompleto: string = appDefaultValues.StringEmpty;
    let nombre: string | null = null;
    let apellidoPaterno: string | null = null;
    let apellidoMaterno: string | null = null;

    if (identTipoRelacion === appConfigKey.KeyIdTipoRelacionConcesionario) {
        nombreCompleto = nombres;
    } else if (identTipoRelacion === appConfigKey.KeyIdTipoRelacionVendedor) {
        const result = GeneralHelper.SepararNombreCompleto(nombres);
        if (result) {
            nombreCompleto = [result.nombres?.trim(), result.apellidoPaterno?.trim(), result.apellidoMaterno?.trim()].filter(Boolean).join(' ');
            nombre = result.nombres?.trim();
            apellidoPaterno = result.apellidoPaterno?.trim();
            apellidoMaterno = result.apellidoMaterno?.trim();
        }
    }

    let model: any = {
        "idenT_SOLICITUD_ASOCIADO": appDefaultValues.NumberDefault,
        "idenT_SOLICITUD": appDefaultValues.NumberDefault,
        "uid": uid ?? appDefaultValues.NumberDefault,
        "iD_TIPO_DOCUMENTO": data.tipoDocumento ?? appDefaultValues.NumberDefault,
        "codL_NUMERO_DOCUMENTO": data.numeroDocumento ?? appDefaultValues.StringEmpty,
        "idenT_TIPO_RELACION": identTipoRelacion ?? appDefaultValues.NumberDefault,
        "descL_NOMBRE_COMPLETO": nombreCompleto ?? appDefaultValues.StringEmpty,
        "descL_NOMBRES": nombre,
        "descL_APELLIDO_PATERNO": apellidoPaterno,
        "descL_APELLIDO_MATERNO": apellidoMaterno,
        "inD_ESTADO": appDefaultValues.BooleanDefaultTrue,
        "idenT_USUARIO": identUsuario ?? appDefaultValues.NumberDefault,
    };

    return model;
};