import { ReactComponent as CloseIcon } from '../../../../../../assets/media/icons/ico-close-modal.svg'
import { Button, FormControl, FormLabel, Input, Textarea } from '@mui/joy';
import './styles.scss';
import { Dialog, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import ArrowRight from '../../../../../../assets/media/icons/ico_arrowright.svg';
import MasterManager from '../../../../../services/origination/wapimaster.service';
import { useEffect, useState } from 'react';
import { appConfigKey, appSecurityConfiguration } from '../../../../../../config/Config';  
import { Toaster, toast } from "sonner";

const ChangeRateDialog = (props: any) => {

    const [dataChangeRate, setDataChangeRate] = useState<any[]>([]);
    const [requestBody, setRequestBody] = useState<any>();    
    const [getSolicitud, setSolicitud] = useState<any>();

    useEffect(() => {
        if(props.data !== null && props.open) {
            GetMnSolicitud(props.data.idenT_SOLICITUD);
            GetMnSolicitudHistoricoCambiarTasa(props.data.idenT_SOLICITUD);        
        }
    }, [props.open]);


    const handleChange = (e: any) => {
        let { name, value, type } = e.target;
        if (type === 'number' && value === "") {
            value = 0;
        };
        if (type === 'select') {
            value = parseInt(value);
        };
        if (type === 'date' && value === "Invalid Date") {
            value = null
        }
        
        setRequestBody({
            ...requestBody, [name]: value
        });
    };

    const GetMnSolicitud = async (dat: string) => {
        var body = {
            "idenT_SOLICITUD": dat
        };
        
        await MasterManager.GetMnSolicitud(body)
        .then((result: any) => {
            setSolicitud(result.data.content[0])
        }).catch((error) => {
            //myowasp(error);
        });
    }

    const GetMnSolicitudHistoricoCambiarTasa = async (dat: string) => {
        var body = {
            "idenT_SOLICITUD": dat
        };
        
        await MasterManager.GetMnSolicitudHistoricoCambiarTasa(body)
        .then((result: any) => {
            setDataChangeRate(result.data.content)            
        }).catch((error) => {
            //myowasp(error);
        });
    }    

    const SaveModal = async () => {      
        if(requestBody === undefined || requestBody.teA_NUEVA == null || requestBody.teA_NUEVA === "") {
            toast.error(appConfigKey.keyTasaNuevaEmpty , { duration: appConfigKey.keyDurationToast });
            return;
        }

        if(!Number(requestBody.teA_NUEVA)) {
            toast.error(appConfigKey.keyTasaNuevaIsNumber , { duration: appConfigKey.keyDurationToast });
            return;
        }
        
        if(requestBody.comentario == null || requestBody.comentario === "") {
            toast.error(appConfigKey.keyComentarioCambiarTasaEmpty , { duration: appConfigKey.keyDurationToast });
            return;
        }

        var body = {
            "idenT_SOLICITUD": getSolicitud?.idenT_SOLICITUD,
            "teA_ACTUAL": getSolicitud?.monT_TEA,
            "teA_NUEVA": requestBody.teA_NUEVA,
            "comentario": requestBody.comentario,
            "idenT_USUARIO_CREADOR": appSecurityConfiguration.SessionUserId
        };
    
        if(props.open) {
            await MasterManager.GetMnSolicitudHistoricoCambiarTasaInsert(body)
            .then((result: any) => {
                var requestSolicitud = {
                    ...getSolicitud,
                };
                requestSolicitud.monT_TEA = requestBody.teA_NUEVA;
                requestSolicitud.idenT_USUARIO = appSecurityConfiguration.SessionUserId;

                delete requestSolicitud.fecH_CREACION;
                delete requestSolicitud.fecH_MODIF;
                delete requestSolicitud.idenT_USUARIO_CREADOR;
                delete requestSolicitud.idenT_USUARIO_MODIF;

                MasterManager.GetMnSolicitudUpdate(requestSolicitud)
                .then((result: any) => {
                    toast.success("Registro guardado correctamente." , { duration: appConfigKey.keyDurationToast });
                    GetMnSolicitudHistoricoCambiarTasa(props.data.idenT_SOLICITUD);
                    setRequestBody({
                        teA_NUEVA: "",
                        comentario: "",
                    })
                    GetMnSolicitud(props.data.idenT_SOLICITUD);
                }).catch((error) => {
                    //myowasp(error);
                });
            }).catch((error) => {
                //myowasp(error);
            });
        }
      };

    
    return (
        <Dialog open={props.open} sx={{
            '& .MuiDialog-paper': {
                borderRadius: '15px',
            }
        }}>
            <Toaster position="top-center" richColors closeButton />
                <div className="change-rate-dialog-content">
                    <button className="btn btn-sm change-rate-close-btn" onClick={props.handleClose}>
                    <CloseIcon />
                    </button>
                    <div className='change-rate-dialog-title'>CAMBIAR TASA</div>
                    <div className="change-rate-info-container">
                        <div className="change-rate-info-row">
                            <span className="change-rate-label-info">Nro Solicitud:</span>
                            <span className="change-rate-label-info"><strong>{getSolicitud?.idenT_SOLICITUD}</strong></span>
                        </div>
                        <div className="change-rate-info-row">
                            <span className="change-rate-label-info">Producto:</span>
                            <span className="change-rate-label-info"><strong>{getSolicitud?.descC_PRODUCTO}</strong></span>
                        </div>
                        <div className="change-rate-info-row">
                            <span className="change-rate-label-info">Segmento:</span>
                            <span className="change-rate-label-info"><strong>Gold</strong></span>
                        </div>
                    </div>
                    <div className="textAlignRigth">
                        <FormControl>
                            <FormLabel>
                            <span className='change-rate-label'>TEA</span>&nbsp;
                            <span style={{ color: "red" }}>* </span>
                            </FormLabel>
                            <Input type='change-rate-number' placeholder="Ingresar número" name='teA_NUEVA' value={requestBody?.teA_NUEVA} onChange={handleChange} />
                        </FormControl>
                    </div>
                    <div className="textAlignRigth cardObjets">
                            <FormLabel>
                            <span className='change-rate-label'>Comentario</span>&nbsp;
                            <span style={{ color: "red" }}>* </span>
                            </FormLabel>
                            <div className="row g-2 mb-2 align-items-center">
                                <div className="col-lg-12">
                                    <FormControl>
                                    <Textarea
                                        placeholder="Ingresar texto"
                                        minRows={2}
                                        sx={{ mb: 1 }}
                                        name='comentario'
                                        onChange={handleChange}
                                        value={requestBody?.comentario}
                                    />
                                    </FormControl>
                                </div>
                            </div>
                    </div>
                    <div className="change-rate-info-container">
                        <div className="change-rate-info-row">
                            <span className="change-rate-label-info">Cuota actual</span>
                            <span className="change-rate-label-info">S/ 706.00</span>
                        </div>
                        <div className="change-rate-info-row">
                            <span className="change-rate-label-info"><strong>Cuota nueva</strong></span>
                            <span className="change-rate-label-info"><strong>S/ 645.00</strong></span>
                        </div>
                    </div>
                    <div className='change-rate-dialog-btn-save'>
                        <Button
                            color="danger" sx={{
                            borderRadius: 24,
                            width: '100%'                            
                            }}
                            onClick={() => SaveModal()}
                            >Grabar
                            <span className='arrow-right'>
                                <img src={ArrowRight} alt='arrow-right' />
                            </span>   
                        </Button>                 
                    </div>
                    <div className="textAlignRigth cardObjets">
                        <div>
                            <FormControl>
                                <FormLabel>
                                <span className='change-rate-label'>Historial</span>
                                </FormLabel>
                            </FormControl> 
                        </div>
                    </div>
                        <div className='container-table'>
                            <TableContainer component={Paper} 
                            sx={{
                                '& .MuiTable-root': {
                                    backgroundColor: '#E1E1E1',
                                }
                            }}>
                                <Table sx={
                                    {
                                        '& .MuiTableHead-root': {
                                            backgroundColor: '#E1E1E1',                                     
                                        },
                                        '& .MuiTableCell-root': {
                                            fontSize: '11px',                                            
                                            padding: '7px',                                                
                                        }
                                    }
                                }>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell style={{minWidth:'70px'}}><strong>FECHA</strong></TableCell>
                                            <TableCell style={{minWidth:'65px'}}><strong>TEA A</strong></TableCell>
                                            <TableCell style={{minWidth:'65px'}}><strong>TEA N</strong></TableCell>
                                            <TableCell style={{minWidth:'70px'}}><strong>USUARIO</strong></TableCell>
                                            <TableCell style={{minWidth:'200px'}}><strong>COMENTARIO</strong></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                    {dataChangeRate.map((row, index) => (
                                        <TableRow
                                            key={index} className={
                                                index % 2 === 0 ? 'change-rate-table-impar' : 'change-rate-table-par'
                                            }>
                                        <TableCell style={{fontSize: '12px'}}>{row.fecha}</TableCell>
                                        <TableCell style={{fontSize: '12px'}}>{row.teA_ACTUAL}</TableCell>
                                        <TableCell style={{fontSize: '12px'}}>{row.teA_NUEVA}</TableCell>
                                        <TableCell style={{fontSize: '12px'}}>{row.username}</TableCell>
                                        <TableCell style={{fontSize: '12px'}}>{row.comentario}</TableCell>
                                        </TableRow>
                                    ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>                        
                        </div>
                </div>
        </Dialog>
    );
}

export default ChangeRateDialog;