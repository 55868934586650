import { Component } from "react";

interface ICardSolicitudeDetailProps {
  objCardSolicitudeDetailProps: {
    solicitudeId: number;
    clientName: string;
    executiveName: string;
    statusName: string;
    observedTypeName: string;
    stage: string;
  };
}

class CardSolicitudeDetail extends Component<ICardSolicitudeDetailProps, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className="border rounded-10 p-3 p-md-2 mb-4 mb-md-4 card body-details">
        <div className="row g-2 m-1 align-items-start item-body-details">

          <div className="col-lg-3">
            <div className="row g-2 mb-2">
              <div className="col-lg-6">
                <span>Nro. Solicitud</span>
              </div>
              <div className="col-lg-6">
                <span className="text-negrita">
                  {this.props.objCardSolicitudeDetailProps.solicitudeId}
                </span>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6">
                <span>Etapa</span>
              </div>
              <div className="col-lg-6">
                <span className="text-negrita">
                  {this.props.objCardSolicitudeDetailProps.stage}
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-6">
            <div className="row g-2 mb-2">
              <div className="col-lg-3">
                <span>Ejecutivo</span>
              </div>
              <div className="col-lg-9">
                <span className="text-negrita">
                  {this.props.objCardSolicitudeDetailProps.executiveName}
                </span>
              </div>
            </div>

            <div className="row g-2 mb-2">
              <div className="col-lg-3">
                <span>Cliente</span>
              </div>
              <div className="col-lg-9">
                <span className="text-negrita">
                  {this.props.objCardSolicitudeDetailProps.clientName}
                </span>
              </div>
            </div>
          </div>

          <div className="col-lg-3">
            <div className="row g-2 mb-2">
              <div className="col-lg-6">
                <span>Estado</span>
              </div>
              <div className="col-lg-6">
                <span className="text-negrita">
                  {this.props.objCardSolicitudeDetailProps.statusName}
                </span>
              </div>
            </div>

            <div className="row g-2 mb-2">
              <div className="col-lg-6">
                <span>Tipo observado</span>
              </div>
              <div className="col-lg-6">
                <span className="text-negrita">
                  {this.props.objCardSolicitudeDetailProps.observedTypeName}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default CardSolicitudeDetail;
