import { appConfigKey } from "../../../../../config/Config";
import { validarFecha, validarSelect, validarText } from "../../../../helpers/ValidarHelper";

const ValidacionStepThreeHelper = {

    MotorModellica: (newCondicionesFinanciamiento: any) => {

        const {
            identProducto,
            identSeguroVehicular,
            costoSeguroVehicular,
            identTipoCoberturaDesg,
            esGPS,
            identProveedorGPS,
            esSeguroVehicularEndosado,
        } = newCondicionesFinanciamiento;

        if (!validarSelect(identProducto, 'producto')) {
            return false;
        }

        if (!esSeguroVehicularEndosado) {
            if (!validarSelect(identSeguroVehicular, 'seguro vehicular')) {
                return false;
            }
        }

        if (!validarText(costoSeguroVehicular, "tasa seguro vehicular")) {
            return false;
        }

        if (!validarSelect(identTipoCoberturaDesg, 'tipo cobertura desg')) {
            return false;
        }

        if(esGPS){
            if (!validarSelect(identProveedorGPS, 'Proveedor GPS')) {
                return false;
            }
        }

        return true;

    },

    NewCondicionesFinanciamiento: (newCondicionesFinanciamiento: any, isTitularJuridico: boolean | null = null) => {

        const {
            identProducto,
            identSeguroVehicular,
            costoSeguroVehicular,
            tea,
            identPlazosMeses,
            identDiaPago,
            primerVencimiento,
            identTipoCoberturaDesg,
            identCIPrecio,
            tipoDescuento,
            esGPS,
            identProveedorGPS,
            identTipoInclusionPortes,
            esGastosDeliveryFirmas,
            identGastosDeliveryFirmas,
            esSeguroVehicularEndosado,
            identCampania
        } = newCondicionesFinanciamiento;

        if (!validarSelect(identProducto, 'producto')) {
            return false;
        }

        if (!esSeguroVehicularEndosado) {
            if (!validarSelect(identSeguroVehicular, 'seguro vehicular')) {
                return false;
            }
        }

        if (!validarText(costoSeguroVehicular, "tasa seguro vehicular")) {
            return false;
        }

        if (!validarText(tea, "TEA %")) {
            return false;
        }

        if (!validarSelect(identPlazosMeses, 'plazos en meses')) {
            return false;
        }

        if (!validarSelect(identDiaPago, 'día de pago')) {
            return false;
        }

        // if (!validarFecha(primerVencimiento, '1er vencimiento')) {
        //     return false;
        // }

        if (!isTitularJuridico && !validarSelect(identTipoCoberturaDesg, 'tipo cobertura desg')) {
            return false;
        }

        if (identCampania !== 0) {
            if (!validarSelect(Number(identCIPrecio), 'CI/Precio')) {
                return false;
            }
        }

        if(esGPS){
            if (!validarSelect(identProveedorGPS, 'Proveedor GPS')) {
                return false;
            }
        }

        return true;
    }

}
export default ValidacionStepThreeHelper;