import "../../../3.10.-base/solicitude.scss";
import 'bootstrap/dist/css/bootstrap.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.material-purple.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.material-purple.css';
import { memo, useContext, useEffect, useState } from 'react';
import { Table } from "@mui/joy";
import { StepFourContext } from "../../../3.10.-base/providers/StepFourProvider";
import { StepsContext } from "../../../3.10.-base/providers";
import { Button, IconButton, Dialog, DialogTitle } from "@mui/material";
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { appConfigKey, appConfigReportes, appDefaultValues, appSecurityConfiguration } from "../../../../../../config/Config";
import { SimpleInput } from "../../../3.40.-SolicitudeStepOne/components/elements/inputs";
import { toast } from "sonner";
import ServicesProvider from "../../../../../services/support/wapiservicesprovider";
import ReportManager from "../../../../../services/support/wapireport";
import ModalGastos from "../ModalGastos";
import { ListaGastos } from "../../../../../models/ManagerProfile/HistoricoTea";
import RequestManager from "../../../../../services/origination/wapirequest.service";
import { getRandomInt } from "../../../../../helpers/RandomHelper";

const CronogramaCard = () => {

  const {
    setNewCronograma,
    listaCronograma,
    listaCuotasPorPlazos,
    listaComisionesCronoSel,
    listaComisionesCuotaCronoSel,
    operacionUId,
    totalFinanciamiento
  } = useContext(StepFourContext);

  const {
    clickNext,
    viewModelStepTwoFinal,
    setLoadingSteps,
    clienteUId,
    solicitudData,
    readonlyForm
  } = useContext(StepsContext);

  const [openModalContratacion, setOpenModalContratacion] = useState(false);
  const [listaGastos, setListaGastos] = useState<ListaGastos[]>([])


  const cargarListaGastos = async () => {
    let resultadoHistorial: any = []
    const response = await RequestManager.GetMnOfertaConsumoPrestamo({ "idenT_SOLICITUD": solicitudData?.idenT_SOLICITUD, "id_tipO_TRANSACCION": 1 });
    if (response.status === 200) {
      resultadoHistorial = response.data.content;
    };
    //myowasp(resultadoHistorial)
    setListaGastos(resultadoHistorial);
    setOpenModalGastos(true);
  }

  const [openModalGastos, setOpenModalGastos] = useState(false);
  const handleCloseModalGastos = () => {
    setOpenModalGastos(false);
  };

  const handleClickOpenModalGastos = () => {
    cargarListaGastos();
  };

  const [viewModelContratacion, setViewModelContratacion] = useState({
    NumeroVin: appDefaultValues.StringEmpty,
    Motor: appDefaultValues.StringEmpty,
    Placa: appDefaultValues.StringEmpty,
    Ejecutivo: appDefaultValues.StringEmpty
  });

  const handleDescargarCronograma = async (event: any) => {
    setLoadingSteps(true);
    try {
      let solicitudId = solicitudData?.idenT_SOLICITUD;
      const response = await ReportManager.ObtenerReporte({
        "idSolicitud": solicitudId,
        "idUsuario": appSecurityConfiguration.SessionUserId,
        "idReporte": appConfigReportes.SolicitudCronograma,
        "indBPO": false
      });

      if (response.status === 200) {
        var fileName = null;
        var header = response?.headers['content-disposition'];
        if (header) {
          var filenameRegex = /filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
          var matches = filenameRegex.exec(header);
          if (matches != null && matches[1]) {
            fileName = matches[1].replace(/UTF-8['"]*/g, '');
            fileName = decodeURI(fileName);
          }
        }

        if (!fileName) {
          fileName = `${"Solicitud cronograma"}_${solicitudId}.docx`;
        }

        const url = window.URL.createObjectURL(response?.data);

        const a = document.createElement("a");
        a.href = url;
        a.download = fileName!;
        a.click();
        window.URL.revokeObjectURL(url);
      } else {
        toast.error('Falló la descarga de cronograma.', { duration: appConfigKey.keyDurationToast });
      }
    } catch (error) {
      toast.error('Falló la obtención de cronograma.', { duration: appConfigKey.keyDurationToast });
    }
    setLoadingSteps(false);
  };

  const handleCloseModalContratacion = () => {
    setOpenModalContratacion(false);
  };

  const handleClickOpenModalContratacion = () => {

    setViewModelContratacion({
      ...viewModelContratacion,
      NumeroVin: generarValorAleatorioNumerico(17),
      Motor: appDefaultValues.StringEmpty,
      Placa: appDefaultValues.StringEmpty,
      Ejecutivo: appDefaultValues.StringEmpty
    });

    setOpenModalContratacion(true);
  };

  const handleRegistrarContratacion = async () => {

    if (viewModelContratacion.Ejecutivo === appDefaultValues.StringEmpty) {
      toast.error('El campo ejecutivo es requerido.', { duration: appConfigKey.keyDurationToast });
      return;
    }

    if (viewModelContratacion.Motor === appDefaultValues.StringEmpty) {
      toast.error('El campo motor es requerido.', { duration: appConfigKey.keyDurationToast });
      return;
    }

    if (viewModelContratacion.NumeroVin === appDefaultValues.StringEmpty) {
      toast.error('El campo número vin es requerido.', { duration: appConfigKey.keyDurationToast });
      return;
    }

    if (viewModelContratacion.Placa === appDefaultValues.StringEmpty) {
      toast.error('El campo placa es requerido.', { duration: appConfigKey.keyDurationToast });
      return;
    }

    const json = crearRequestJsonContratacion(
      viewModelStepTwoFinal.VendedorId,
      viewModelStepTwoFinal.SucursalId,
      viewModelStepTwoFinal.ConcesionarioId,
      viewModelContratacion.Ejecutivo,
      viewModelContratacion.Motor,
      viewModelContratacion.NumeroVin,
      viewModelContratacion.Placa
    );

    setLoadingSteps(true);

    await ServicesProvider
      .BTContratarVehicular(json, operacionUId, clienteUId)
      .then((response: any) => {

        const errores = response.data.content.data.erroresnegocio.btErrorNegocio;

        if (errores.length > 0) {
          const mensajeError = errores[0].descripcion;
          alert(`POR FAVOR CORREGIR EL ERROR: ${mensajeError}`);
          return;
        }

        const { movimientoUId } = response.data.content;
        setOpenModalContratacion(false);
        alert(`EL CODIGO DE MOVIMIENTO ES: ${movimientoUId}`);
      })
      .catch((error: any) => {
        //myowasp(error);
        //myowasp('OCURRIO UN ERROR EL SERVICIO.');
      })
      .finally(() => {
        setLoadingSteps(false);
      });

  };

  function generarValorAleatorioNumerico(longitud: number): string {
    let resultado = '';
    for (let i = 0; i < longitud; i++) {
      const digitoAleatorio = getRandomInt(0, 9); // Generar un dígito aleatorio del 0 al 9
      resultado += digitoAleatorio.toString(); // Convertir el número en una cadena y agregarlo al resultado
    }
    return resultado;
  }

  const crearRequestJsonContratacion = (
    vendedorId: number,
    sucursalId: number,
    concesionarioId: number,
    ejecutivo: string,
    numeroMotor: string,
    numeroVin: string,
    placa: string
  ) => {
    //numeroVin = generarValorAleatorioNumerico(17);
    return {
      "sdtParner": {
        "puntoVentaUId": sucursalId,
        "vendedorUId": vendedorId,
        "partnerUId": concesionarioId
      },
      "sdtDatosVehiculo": {
        "destinoEconomico": 1,
        "color": 1,
        "fiadores": {
          "long": [
            0
          ]
        },
        "datosAdicionales": {
          "sdtsBTConcepto": [
            {
              "texto": "string",
              "valor": 0,
              "concepto": "string"
            }
          ]
        },
        "ejecutivo": ejecutivo,
        "numeroMotor": numeroMotor,
        "monedaDesembolso": 1,
        "patente": placa,
        "numeroVIN": numeroVin,
        "compraPara": 36,
        "anioConstruccion": 2023
      }
    };
  };

  useEffect(() => {
    if (clickNext !== 0) {
      setNewCronograma({
        listaCuotasPorPlazos: listaCuotasPorPlazos,
        listaCronograma: listaCronograma,
        listaComisionesCronoSel: listaComisionesCronoSel,
        listaComisionesCuotaCronoSel: listaComisionesCuotaCronoSel,
        operacionUId: operacionUId,
        totalFinanciamiento: totalFinanciamiento
      });
    }
  }, [clickNext]);

  return (
    <div>
      {/* Conograma */}
      <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-4 textLabelHeadboard">
            <span className="fw-bold">CRONOGRAMA</span>
          </div>
          <div className="col-lg-4">
            <span className="fw-bold"> </span>
          </div>
          <div className="col-lg-4 text-end">
            <div style={{
              display: "flex",
              justifyContent: "end",
              gap: "20px"
            }}>
              <div style={{ textAlign: 'end' }}>
                <Button variant="outlined" onClick={handleClickOpenModalGastos}>
                  GASTOS
                </Button>
              </div>
              {<IconButton onClick={handleDescargarCronograma}>
                <FileDownloadOutlinedIcon className="icon-solicitude-link" />
              </IconButton>}
            </div>
            {/* <div style={{ textAlign: 'end' }}>
              <Button disabled={readonlyForm} variant="outlined" onClick={handleClickOpenModalContratacion}>
                REGISTRAR CONTRATACIÓN
              </Button>
            </div> */}
          </div>

        </div>

        <div className="row g-2 mb-2">
          <div className="col-lg-12">
            <div className="horizontal-generico-scrollable ">
              <div className="horizontal-generico-scrollable__wrapper">
                <Table borderAxis="none" className="style-table">
                  <thead className="table-thead">
                    <tr>
                      <th style={{
                        backgroundColor: '#E1E1E1',
                        borderTopLeftRadius: '10px',
                        borderBottomLeftRadius: '10px',
                        width: '80px'
                      }}>Cuota</th>
                      <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>F. Vence</th>
                      <th style={{ backgroundColor: '#E1E1E1', width: '80px' }}>Días</th>
                      <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>Saldo capital</th>
                      <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>Amort.</th>
                      <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>M. Seguro</th>
                      <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>Amort. Total</th>
                      <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>Interés</th>
                      <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>Seguro Desg.</th>
                      <th style={{ backgroundColor: '#E1E1E1', width: '80px' }}>Cuota</th>
                      <th style={{ backgroundColor: '#E1E1E1', width: '100px' }}>Portes</th>
                      <th style={{
                        backgroundColor: '#E1E1E1', width: '100px',
                        borderTopRightRadius: '10px',
                        borderBottomRightRadius: '10px'
                      }}>Pago Total</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      listaCronograma.map((row: any) => (
                        <tr key={row.id}>
                          <td style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px' }}>{row.cuota}</td>
                          <td>{row.fVence}</td>
                          <td>{row.dias}</td>
                          <td>{row.saldoCapital}</td>
                          <td>{row.amort}</td>
                          <td>{row.mSeguro}</td>
                          <td>{row.amortTotal}</td>
                          <td>{row.interes}</td>
                          <td>{row.seguroDesg}</td>
                          <td>{row.cuotaMonto}</td>
                          <td>{row.portes}</td>
                          <td style={{
                            borderTopRightRadius: '10px',
                            borderBottomRightRadius: '10px'
                          }}>{row.pagoTotal}</td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>

        </div>

        <Dialog
          onClose={handleCloseModalContratacion}
          open={openModalContratacion}
          fullWidth={true}
          maxWidth={'sm'}
        >
          <DialogTitle>REGISTRO DE CONTRATACIÓN</DialogTitle>

          <div style={{ padding: '20px' }}>

            <div style={{ display: 'grid', gap: '20px', gridTemplateColumns: 'repeat(2, 1fr)', marginBottom: '20px' }}>

              <SimpleInput
                title={"Número VIN"}
                value={viewModelContratacion.NumeroVin}
                onChange={(event: any) => {
                  setViewModelContratacion({ ...viewModelContratacion, NumeroVin: event.target.value });
                }}
              />

              <SimpleInput
                title={"Motor"}
                value={viewModelContratacion.Motor}
                onChange={(event: any) => {
                  setViewModelContratacion({ ...viewModelContratacion, Motor: event.target.value });
                }}
              />

              <SimpleInput
                title={"Placa"}
                value={viewModelContratacion.Placa}
                onChange={(event: any) => {
                  setViewModelContratacion({ ...viewModelContratacion, Placa: event.target.value });
                }}
              />

              <SimpleInput
                title={"Ejecutivo"}
                value={viewModelContratacion.Ejecutivo}
                onChange={(event: any) => {
                  setViewModelContratacion({ ...viewModelContratacion, Ejecutivo: event.target.value });
                }}
              />

            </div>

            <div style={{ display: 'flex', gap: '20px', alignItems: 'center', justifyContent: 'center' }}>
              <Button variant="contained" color={'inherit'} onClick={() => {
                setViewModelContratacion({
                  Ejecutivo: appDefaultValues.StringEmpty,
                  Motor: appDefaultValues.StringEmpty,
                  NumeroVin: appDefaultValues.StringEmpty,
                  Placa: appDefaultValues.StringEmpty
                })
                setOpenModalContratacion(false);
              }}>
                Regresar
              </Button>
              <Button variant="contained" onClick={handleRegistrarContratacion}>
                Registrar
              </Button>
            </div>
          </div>

        </Dialog>

        <ModalGastos
          handleCloseModalGastos={handleCloseModalGastos}
          openModalGastos={openModalGastos}
          listaGastos={listaGastos}
          tipoMonedaFinanciamiento={viewModelStepTwoFinal.MonedaId}
        />
      </div>
    </div>
  );
};

export default memo(CronogramaCard)