import { ListaNegraItem } from "../1.5.10.-interfaces/ListaNegraInterface";

class ListaNegraResponseHelper {

    static TransformarJsonToList(jsonString?: string): Array<ListaNegraItem> {
        const listaNegra: Array<ListaNegraItem> = [];
        if (!jsonString) return listaNegra;

        const jsonArrayObject = JSON.parse(jsonString);
        jsonArrayObject.forEach(function (b: any) {
            listaNegra.push({ typeName: b.tipo, description: b.descripcion } as ListaNegraItem)
        });
        return listaNegra;
    }

}

export default ListaNegraResponseHelper;