import { Component } from 'react';
import { IGaugeProps } from 'jqwidgets-scripts/jqwidgets-react-tsx/jqxgauge';
import "../3.10.-base/solicitude.scss";
import 'bootstrap/dist/css/bootstrap.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.base.css';
import 'jqwidgets-scripts/jqwidgets/styles/jqx.material-purple.css';
import Ellipse1 from '../../../../assets/media/icons/Ellipse1.svg';
import StepCheck from '../../../../assets/media/icons/step-check.svg';
import StepWhite from '../../../../assets/media/icons/step-white.svg';
import Line1 from '../../../../assets/media/icons/Line1.svg';
import Line2 from '../../../../assets/media/icons/Line2.svg';

interface ICardStepsProps {
  objCardStepsProps : {
      numStep: number, 
  }
}

class StepsSolicitude extends Component<ICardStepsProps, IGaugeProps, any> {
  constructor(props: any) {
    super(props);
    // this.state = {
    //   numStep: Number,
    // }
  }

  render() {
    return (
      <div>
        <div className='content-solicitude'>
          <div className="row g-2 mb-2 align-items-center ">
            <div className="col-lg-12" style={{ textAlign: "center" }} >
              <div className="conteint-img">
                <img src={this.props.objCardStepsProps.numStep === 1 ? Ellipse1: StepCheck}/>
                <div className="conteint-img-center">{this.props.objCardStepsProps.numStep === 1 ? '1' : '' }</div>
              </div>
              <img src={this.props.objCardStepsProps.numStep < 2 ? Line1 : Line2}/>
              <div className="conteint-img">
                <img src={this.props.objCardStepsProps.numStep === 2 ? Ellipse1 : this.props.objCardStepsProps.numStep < 2 ? StepWhite : StepCheck}/>
                <div className="conteint-img-center">{this.props.objCardStepsProps.numStep === 2  ? '2' : '' }</div>
              </div>
              <img src={this.props.objCardStepsProps.numStep < 3 ? Line1 : Line2}/>
              <div className="conteint-img">
                <img src={this.props.objCardStepsProps.numStep === 3 ? Ellipse1 : this.props.objCardStepsProps.numStep < 3 ? StepWhite : StepCheck}/>
                <div className="conteint-img-center">{this.props.objCardStepsProps.numStep === 3  ? '3' : '' }</div>
              </div>
              <img src={this.props.objCardStepsProps.numStep < 4 ? Line1 : Line2}/>
              <div className="conteint-img">
                <img src={this.props.objCardStepsProps.numStep === 4 ? Ellipse1 : this.props.objCardStepsProps.numStep < 4 ? StepWhite : StepCheck}/>
                <div className="conteint-img-center">{this.props.objCardStepsProps.numStep === 4  ? '4' : '' }</div>
              </div>
              <img src={this.props.objCardStepsProps.numStep < 5 ? Line1 : Line2}/>
              <div className="conteint-img">
                <img src={this.props.objCardStepsProps.numStep === 5 ? Ellipse1 : this.props.objCardStepsProps.numStep < 5 ? StepWhite : StepCheck}/>
                <div className="conteint-img-center">{this.props.objCardStepsProps.numStep === 5  ? '5' : '' }</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default StepsSolicitude;