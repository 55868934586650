import { Component } from "react";

class EmptyData extends Component<any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className="col-lg-6 col-md-12 pb-2">
        <div className="row">
          <label>No hay registros.</label>
        </div>
      </div>
    );
  }
}
export default EmptyData;
