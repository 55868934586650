import ArrowLeft from '../../../../../assets/media/icons/ico_arrowleft.svg';

const DocumentationBtnBack = (props: any) => {
    return (
        <div className='documentation-btn-back' onClick={props.handlerOnClick}>
            <img src={ArrowLeft} alt='arrow-left' /> Regresar
        </div>
    );
};

export default DocumentationBtnBack;