import { appDefaultValues } from "../../../../config/Config";

export class BTCrearPersonaJuridicaIntegranteJuridico {
    private nroDocumento: string;
    private vinculoId: number;
    private pais: string;
    private tipoDocumentoId: number;
    private participacion: number;
    private tipoDocumento: string;
    private personaUId: number;
    private paisId: number;
    private nombre: string;
    private vinculo: string;

    constructor() {
        this.nroDocumento = appDefaultValues.StringEmpty;
        this.vinculoId = appDefaultValues.NumberDefault;
        this.pais = appDefaultValues.StringEmpty;
        this.tipoDocumentoId = appDefaultValues.NumberDefault;
        this.participacion = appDefaultValues.NumberDefault;
        this.tipoDocumento = appDefaultValues.StringEmpty;
        this.personaUId = appDefaultValues.NumberDefault;
        this.paisId = appDefaultValues.NumberDefault;
        this.nombre = appDefaultValues.StringEmpty;
        this.vinculo = appDefaultValues.StringEmpty;
    }

    public getNroDocumento(): string {
        return this.nroDocumento;
    }

    public setNroDocumento(nroDocumento: string): void {
        this.nroDocumento = nroDocumento;
    }

    public getVinculoId(): number {
        return this.vinculoId;
    }

    public setVinculoId(vinculoId: number): void {
        this.vinculoId = vinculoId;
    }

    public getPais(): string {
        return this.pais;
    }

    public setPais(pais: string): void {
        this.pais = pais;
    }

    public getTipoDocumentoId(): number {
        return this.tipoDocumentoId;
    }

    public setTipoDocumentoId(tipoDocumentoId: number): void {
        this.tipoDocumentoId = tipoDocumentoId;
    }

    public getParticipacion(): number {
        return this.participacion;
    }

    public setParticipacion(participacion: number): void {
        this.participacion = participacion;
    }

    public getTipoDocumento(): string {
        return this.tipoDocumento;
    }

    public setTipoDocumento(tipoDocumento: string): void {
        this.tipoDocumento = tipoDocumento;
    }

    public getPersonaUId(): number {
        return this.personaUId;
    }

    public setPersonaUId(personaUId: number): void {
        this.personaUId = personaUId;
    }

    public getPaisId(): number {
        return this.paisId;
    }

    public setPaisId(paisId: number): void {
        this.paisId = paisId;
    }

    public getNombre(): string {
        return this.nombre;
    }

    public setNombre(nombre: string): void {
        this.nombre = nombre;
    }

    public getVinculo(): string {
        return this.vinculo;
    }

    public setVinculo(vinculo: string): void {
        this.vinculo = vinculo;
    }

}