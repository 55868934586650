import { IGaugeProps } from "jqwidgets-scripts/jqwidgets-react-tsx/jqxgauge";
import { Component } from "react";
import './card-details-steps1.scss'
import { appConfigKey, appDefaultValues } from "../../../../config/Config";
import CardDetailStepOneHelper from "./CardDetailStepOneHelper";
import GeneralHelper from "../../../helpers/GeneralHelper";

interface ICardDetailsStep1Props {
  objCardDetailsStep1Props: {
    vehicleStatus: string,
    idTypeDocument: number,
    typeDocument: string,
    documentNumber: string,
    married: string,
    netIncome: string,
    CMA: string,
    financialBurden: string,
    memATitle: string,
    memBTitle: string,
    memAValue: string,
    memBValue: string,
    model: any,
    step: number,
    idCategoria: number,
  }
}

class CardDetailsStep1 extends Component<ICardDetailsStep1Props, any> {

  constructor(props: any) {

    super(props);

    let showIngresosTitular = appDefaultValues.BooleanDefault;
    let ingresoTitularTotal: any = appDefaultValues.StringEmpty;

    if (props.objCardDetailsStep1Props.model.isVisibleStep3 === true || props.objCardDetailsStep1Props.model.isVisibleStep3 === true) {

      showIngresosTitular = true;

      const helper = new CardDetailStepOneHelper();

      ingresoTitularTotal = helper.obtenerIngresos(props.objCardDetailsStep1Props.model.listOtrosIngresosTitular, Number(props.objCardDetailsStep1Props.model.tipoCambioFinanciamiento));
      const ingresoTitularConyugeTotal = helper.obtenerIngresos(props.objCardDetailsStep1Props.model.listOtrosIngresosConyuge, Number(props.objCardDetailsStep1Props.model.tipoCambioFinanciamiento));
      ingresoTitularTotal = ingresoTitularTotal + ingresoTitularConyugeTotal;
      ingresoTitularTotal = GeneralHelper.AgregarComasAMiles(ingresoTitularTotal.toFixed(2));
      ingresoTitularTotal = helper.agregarDivisa(ingresoTitularTotal, appConfigKey.keyIdentificadorSoles);

    }

    this.state = {
      ingresoTitular: ingresoTitularTotal,
      showIngresosTitular: showIngresosTitular,
    }

  }

  render() {
    const isTitularJuridico = this.props.objCardDetailsStep1Props.idTypeDocument == appConfigKey.keyIdRUC ||
      this.props.objCardDetailsStep1Props.idCategoria == appConfigKey.keyCodigoCategoriaLaboralTres;
    return (

      <div className="border rounded-10 p-3 p-md-2 mb-4 mb-md-4 card body-details">
        <div className="row g-2 mb-2 align-items-center item-body-details">


          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Estado vehículo</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.props.objCardDetailsStep1Props.vehicleStatus}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>{this.props.objCardDetailsStep1Props.typeDocument} Titular</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.props.objCardDetailsStep1Props.documentNumber}</span>
                </div>
              </div>
            </div>
          </div>


          {this.props.objCardDetailsStep1Props.idTypeDocument !== appConfigKey.keyIdRUC && (
            <div className="col-lg-3 col-md-12">
              <div className="row g-2 mb-2">
                <div className="col-lg-6 col-md-6">
                  <span>Estado civil</span>
                </div>
                <div className="col-lg-6 col-md-6">
                  <div className="text-long">
                    <span className="text-negrita">{this.props.objCardDetailsStep1Props.married}</span>
                  </div>
                </div>
              </div>
              {this.state.showIngresosTitular ? (
                <div className="row g-2 mb-2">
                  <div className="col-lg-6 col-md-6">
                    <span>Ingresos netos</span>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="text-long">
                      <span className="text-negrita">{this.state.ingresoTitular}</span>
                    </div>
                  </div>
                </div>
              ) :
                <div className="row g-2 mb-2">
                  <div className="col-lg-6 col-md-6">
                    <span></span>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="text-long">
                      <span className="text-negrita">&nbsp;</span>
                    </div>
                  </div>
                </div>
              }
            </div>
          )}

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>CMA</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{!isTitularJuridico ? this.props.objCardDetailsStep1Props.CMA : '-'}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span></span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">&nbsp;</span>
                </div>
              </div>
            </div>
          </div>

          {this.props.objCardDetailsStep1Props.step >= 3 &&
            (
              <div className="col-lg-3 col-md-12">
                <div className="row g-2 mb-2">
                  <div className="col-lg-6 col-md-6">
                    <span>CFM {!isTitularJuridico ? `${this.props.objCardDetailsStep1Props.memATitle} %` : ''}</span>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="text-long">
                      <span className="text-negrita">{!isTitularJuridico ? `MEM ${appConfigKey.keySimboloSoles} ${this.props.objCardDetailsStep1Props.memAValue}` : '-'}</span>
                    </div>
                  </div>
                </div>
                <div className="row g-2 mb-2">
                  <div className="col-lg-6 col-md-6">
                    <span></span>
                  </div>
                  <div className="col-lg-6 col-md-6">
                    <div className="text-long">
                      <span className="text-negrita">&nbsp;</span>
                    </div>
                  </div>
                </div>
              </div>
            )
          }

        </div>
      </div>
    )
  }
}
export default CardDetailsStep1;