import { appDefaultValues } from "../../../../config/Config";

export interface CondicionesFinancimaientoInterface {
    identProducto: number,
    Producto: string,
    identSeguroVehicular: number,
    costoSeguroVehicular: string,
    esInfDerechoEndosoProc: boolean,
    tea: string,
    identPlazosMeses: number,
    plazosMeses: string,
    identDiaPago: number,
    diaPago: string,
    esCuotasDobles: boolean,
    primerVencimiento: any,
    identTipoCoberturaDesg: number,
    esActivacionViaEmailOrSms: boolean,
    esIncluirPortes: boolean,
    identCampania: number,
    identCIPrecio: string,
    tipoDescuento: number,
    esGPS: boolean,
    identProveedorGPS: number,
    esGastoRegistrales: boolean,
    esGastoNotariales: boolean,
    identTipoInclusionPortes: number,
    identGastosDeliveryFirmas: number,
    esGastoInclusionGPS: boolean,
    esKitMantenimiento: boolean,
    esSeguroVehicularEndosado: boolean,
    esGastosDeliveryFirmas: boolean,
    listaCuotas: any,
    listaComisionPrestamoCouta: any,
    listaComisionPrestamo: any,
    SeguroVehicular: string,
    CuotaBallon: string,
    clienteUId: number,
    titularPersonaUId: number,
    conyugePersonaUId: number,
    score: string,
    cambiarTeaDialogOpen: boolean,
    bodyCambiarTea: any,
    teaSinBono: string,
}

export const CondicionesFinancimaientoInterfaceDefault: CondicionesFinancimaientoInterface = {
    identProducto: appDefaultValues.NumberDefault,
    Producto: appDefaultValues.StringEmpty,
    identSeguroVehicular: appDefaultValues.NumberDefault,
    costoSeguroVehicular: appDefaultValues.StringEmpty,
    esInfDerechoEndosoProc: appDefaultValues.BooleanDefault,
    tea: appDefaultValues.StringEmpty,
    identPlazosMeses: appDefaultValues.NumberDefault,
    plazosMeses: appDefaultValues.StringEmpty,
    identDiaPago: appDefaultValues.NumberDefault,
    diaPago: appDefaultValues.StringEmpty,
    esCuotasDobles: appDefaultValues.BooleanDefault,
    primerVencimiento: appDefaultValues.FechaDefault,
    identTipoCoberturaDesg: appDefaultValues.NumberDefault,
    esActivacionViaEmailOrSms: appDefaultValues.BooleanDefault,
    esIncluirPortes: appDefaultValues.BooleanDefault,
    identCampania: appDefaultValues.NumberDefault,
    identCIPrecio: appDefaultValues.StringEmpty,
    tipoDescuento: appDefaultValues.NumberDefault,
    esGPS: appDefaultValues.BooleanDefault,
    identProveedorGPS: appDefaultValues.NumberDefault,
    esGastoRegistrales: appDefaultValues.BooleanDefault,
    esGastoNotariales: appDefaultValues.BooleanDefault,
    identTipoInclusionPortes: appDefaultValues.NumberDefault,
    identGastosDeliveryFirmas: appDefaultValues.NumberDefault,
    esGastoInclusionGPS: appDefaultValues.BooleanDefault,
    esKitMantenimiento: appDefaultValues.BooleanDefault,
    esSeguroVehicularEndosado: appDefaultValues.BooleanDefault,
    esGastosDeliveryFirmas: appDefaultValues.BooleanDefault,
    listaCuotas: appDefaultValues.ArrayDefault,
    listaComisionPrestamoCouta: appDefaultValues.ArrayDefault,
    listaComisionPrestamo: appDefaultValues.ArrayDefault,
    SeguroVehicular: appDefaultValues.StringEmpty,
    CuotaBallon: appDefaultValues.StringEmpty,
    clienteUId: appDefaultValues.NumberDefault,
    titularPersonaUId: appDefaultValues.NumberDefault,
    conyugePersonaUId: appDefaultValues.NumberDefault,
    score: appDefaultValues.StringEmpty,
    cambiarTeaDialogOpen: appDefaultValues.BooleanDefault,
    bodyCambiarTea: appDefaultValues.ArrayDefault,
    teaSinBono: appDefaultValues.StringEmpty
};