import dayjs from "dayjs";

const TimeHelper = {

    // FORMATO A RECIBIR "2023-05-31T22:34:54.79"
    // FORMATO QUE DEVUELVE "DD/MM/YYYY"
    ObtenerFormatoFechaPeru(fecha: string | Date) {
        const fechaObjeto = (typeof fecha === 'string') ? new Date(fecha) : fecha;
        let dia: any = fechaObjeto.getDate();
        let mes: any = fechaObjeto.getMonth() + 1;
        let año = fechaObjeto.getFullYear();

        // Asegurarse de que el día y el mes tengan dos dígitos
        dia = dia < 10 ? "0" + dia : dia;
        mes = mes < 10 ? "0" + mes : mes;

        let fechaFormateada = dia + "/" + mes + "/" + año;

        return fechaFormateada;

    },

    ObtenerFormatoFechaAmericana(fecha: string | null) {
        if (!fecha) return fecha;
        let fechaObjeto = new Date(fecha);
        let año = fechaObjeto.getFullYear();
        let mes: any = fechaObjeto.getMonth() + 1; // Los meses en JavaScript son indexados desde 0, por lo que sumamos 1
        let dia: any = fechaObjeto.getDate();

        // Asegurarse de que el mes y el día tengan dos dígitos
        mes = mes < 10 ? "0" + mes : mes;
        dia = dia < 10 ? "0" + dia : dia;

        let fechaFormateada = `${año}-${mes}-${dia}`;

        return fechaFormateada;
    },

    ObtenerFormatoFechaAmericanaDayJs(fecha: dayjs.Dayjs) {
        return fecha.format("YYYY-MM-DD");
    },

    // FORMATO A RECIBIR "2023-05-31T22:34:54.79"
    // FORMATO QUE DEVUELVE "DD/MM/YYYY HH:mm tt"
    ObtenerFormatoFechaHoraAMPM(fecha: string | Date) {
        const fechaObjeto = (typeof fecha === 'string') ? new Date(fecha) : fecha;

        let dia: any = fechaObjeto.getDate();
        let mes: any = fechaObjeto.getMonth() + 1; // Los meses en JavaScript son indexados desde 0, por lo que sumamos 1
        let anio = fechaObjeto.getFullYear();
        let horas: any = fechaObjeto.getHours();
        let minutos: any = fechaObjeto.getMinutes();
        let ampm = "am";

        if (horas > 12) {
            ampm = "pm";
            horas = horas - 12;
        }
        else if (horas === 0) {
            horas = 12;
        }

        // Asegurarse de que el día y el mes tengan dos dígitos
        dia = dia < 10 ? "0" + dia : dia;
        mes = mes < 10 ? "0" + mes : mes;
        minutos = minutos < 10 ? "0" + minutos : minutos;

        let fechaFormateada = `${dia}/${mes}/${anio} ${horas}:${minutos} ${ampm}`;

        return fechaFormateada;
    },

    // FORMATO A RECIBIR "2023-05-31T22:34:54.79"
    // FORMATO QUE DEVUELVE "DD/MM/YYYY HH:mm tt" en UTC -5
    ObtenerFormatoFechaHoraAMPMPeru(fecha: string | Date) {
        let fechaObjeto = (typeof fecha === 'string') ? new Date(fecha) : fecha;
        fechaObjeto.setHours(fechaObjeto.getHours() - 5); // UTC -5 Lima/Perú
        return TimeHelper.ObtenerFormatoFechaHoraAMPM(fechaObjeto);
    },

}

export default TimeHelper;