export class TMnSolicitudPersonaPorValidarRequest {
  idenT_SOLICITUD: number = 0;
  idenT_USUARIO: number = 0;
  tbL_SOLICITUD_PERSONA_POR_VALIDAR: SolicitudPersonaPorValidarType[] = [];
}

export class SolicitudPersonaPorValidarType {
  idenT_PERSONA: number = 0;
  idenT_TIPO_RELACION: number = 0;
  iD_TIPO_DOCUMENTO: number = 0;
  codL_NUMERO_DOCUMENTO: string = '';
}