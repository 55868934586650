import * as Accordion from '@radix-ui/react-accordion';
import { Table } from "@mui/joy";

export const ScoringPosicionCliente = (props: any) => {
 const { posicionCliente } = props;

 return (
  <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
   <Accordion.Root className="AccordionRoot" type="single" defaultValue="item-1" collapsible>
    <Accordion.Item className="AccordionItem" value="item-1">
     <Accordion.Header>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-4 textLabelHeadboard">
        <span className="fw-bold">POSICIÓN CLIENTE (TITULAR)</span>
       </div>
       <div className="col-lg-4">
        <span className="fw-bold"> </span>
       </div>
       <div className="col-lg-4 textAlignRigth">
        <Accordion.Trigger>
         ^
        </Accordion.Trigger>
       </div>
      </div>
     </Accordion.Header>
     <Accordion.Content>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-6 cardObjets align-top">
        <Table borderAxis="none" className="style-table align-top">
         <thead>
          <tr>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>N. OP</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>PRODUCTO</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>MONEDA</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>SALDO ACTUAL</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>CUOTA</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>CUOTA PAGADAS</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>PROM. DIAS ATRASADOS</th>
          </tr>
         </thead>
         <tbody>
          {
           posicionCliente.productos.map((row: any) => {
            return (
             <tr>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.nop}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.producto}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.moneda}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.saldoActual}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.cuota}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.cuotasPagadas}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.proDiasAtrasada}</td>
            </tr>
            )
           })
          }
          <tr><td></td></tr>
          <tr><td></td></tr>
         </tbody>
        </Table>
       </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-6 cardObjets align-top">
        <Table borderAxis="none" className="style-table align-top">
         <thead>
          <tr>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>N. OP</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>PRODUCTO</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>MONEDA</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>SALDO ACTUAL</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>CUOTA</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>CUOTA PAGADAS</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>PROM. DIAS ATRASADOS</th>
          </tr>
         </thead>
         <tbody>
          {
           posicionCliente.productos.map((row: any) => {
            return (
             <tr>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.nop}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.producto}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.moneda}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.saldoActual}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.cuota}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.cuotasPagadas}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.proDiasAtrasada}</td>
            </tr>
            )
           })
          }
          <tr><td></td></tr>
          <tr><td></td></tr>
         </tbody>
        </Table>
       </div>
      </div>
     </Accordion.Content>
    </Accordion.Item>
   </Accordion.Root>
  </div>
 );
};

export default ScoringPosicionCliente;