import { appDefaultValues } from '../../../config/Config';

export interface Ingresos {
 idPersona: number,
 idIngresoPersona: number,
 idmoneda: number,
 moneda: string,
 ingresoNeto: number,
 comentario: string,
 idcategoriaLaboral: number,
 categoriaLaboral: string,
 ruc: string,
 razonSocial: string,
 fechaIngresoLaboral: string,
 ingresoAnualizado: boolean,
 idIngresoPersonaTrabajoAnterior: number,
 idCategoriaLaboralAnterior: number,
 categoriaLaboralAnterior: string,
 categoriaAnterior: string,
 rucAnterior: string,
 razonSocialAnterior: string,
 fechaIngresoLaboralAnterior: string,
 fechaHasta: string,
 totalAnualizado: number,
 separacionBienes: string,

 montoMinimoAnualizado: number
}

export interface otrosIngresos {
 idIngresoPersona: number,
 idmoneda: number,
 moneda: string,
 ingresoNeto: number,
 comentario: string,
 idcategoriaLaboral: number,
 categoriaLaboral: string,
 ruc: string,
 razonSocial: string,
 fechaIngresoLaboral: string,
 ingresoAnualizado: boolean,
 totalAnualizado: number

 // idPersona: number,
 // moneda: string,
 // ingresoNeto: number,
 // comentario: string,
 // categoriaLaboral: string,
 // ruc: string,
 // razonSocial: string,
 // fechaIngresoLaboral: string,
 // ingresoAnualizado: boolean,
}

export interface Conyuge {
 ingresos: Ingresos
 otrosIngresos: otrosIngresos[]
}

export const ConyugeDefault: Conyuge = {
 ingresos:{
  idPersona: appDefaultValues.NumberDefault,
  idIngresoPersona: appDefaultValues.NumberDefault,
  idmoneda: appDefaultValues.NumberDefault,
  moneda: appDefaultValues.StringEmpty,
  ingresoNeto: appDefaultValues.NumberDefault,
  comentario: appDefaultValues.StringEmpty,
  idcategoriaLaboral: appDefaultValues.NumberDefault,
  categoriaLaboral: appDefaultValues.StringEmpty,
  categoriaLaboralAnterior: appDefaultValues.StringEmpty,
  ruc: appDefaultValues.StringEmpty,
  razonSocial: appDefaultValues.StringEmpty,
  fechaIngresoLaboral: appDefaultValues.StringEmpty,
  ingresoAnualizado: appDefaultValues.BooleanDefault,
  idIngresoPersonaTrabajoAnterior: appDefaultValues.NumberDefault,
  idCategoriaLaboralAnterior: appDefaultValues.NumberDefault,
  categoriaAnterior: appDefaultValues.StringEmpty,
  rucAnterior: appDefaultValues.StringEmpty,
  razonSocialAnterior: appDefaultValues.StringEmpty,
  fechaIngresoLaboralAnterior: appDefaultValues.StringEmpty,
  fechaHasta: appDefaultValues.StringEmpty,
  totalAnualizado: appDefaultValues.NumberDefault,
  separacionBienes: appDefaultValues.StringEmpty,
  montoMinimoAnualizado: appDefaultValues.NumberDefault,
 },  
 otrosIngresos: [] 
 // {
 //  idPersona: appDefaultValues.NumberDefault,
 //  moneda: appDefaultValues.StringEmpty,
 //  ingresoNeto: appDefaultValues.NumberDefault,
 //  comentario: appDefaultValues.StringEmpty,
 //  categoriaLaboral: appDefaultValues.StringEmpty,
 //  ruc: appDefaultValues.StringEmpty,
 //  razonSocial: appDefaultValues.StringEmpty,
 //  fechaIngresoLaboral: appDefaultValues.StringEmpty,
 //  ingresoAnualizado: appDefaultValues.NumberDefault,
 // }
}