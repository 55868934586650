import { useEffect, useRef, useState } from "react";
import './../UserCarousel/style.scss'
import KeyboardDoubleArrowLeftIcon from '@mui/icons-material/KeyboardDoubleArrowLeft';
import KeyboardDoubleArrowRightIcon from '@mui/icons-material/KeyboardDoubleArrowRight';
import { Button } from "@mui/joy";

const UserCarousel = (props: any) => {

    const [currentSlide, setCurrentSlide] = useState(0);
    const carouselRef = useRef<HTMLDivElement>(null);
    const [dataSource, setDataSource] = useState<any>([]);

    const goToPreviousSlide = () => {
        setCurrentSlide((prevSlide) => (prevSlide === 0 ? dataSource.length - 1 : prevSlide - 1));
        scrollToSlide(currentSlide - 1);
        
        props.setViewModelBusqueda({
            ...props.viewModelBusqueda,
            identUsuario: dataSource[currentSlide - 1].identUsuario
        });        
        props.CargarBandejaGestionSolicitudesPorUsuario(dataSource[currentSlide - 1].identUsuario);
    };

    const goToNextSlide = () => {
        const nextSlideIndex = (currentSlide + 1) % dataSource.length;
        setCurrentSlide(nextSlideIndex);
        scrollToSlide(nextSlideIndex);
        
        props.setViewModelBusqueda({
            ...props.viewModelBusqueda,
            identUsuario: dataSource[nextSlideIndex].identUsuario
        });        
        props.CargarBandejaGestionSolicitudesPorUsuario(dataSource[nextSlideIndex].identUsuario);

    };
    const scrollToSlide = (index: number) => {
        if (carouselRef.current) {
            const slideWidth = carouselRef.current.children[0].clientWidth;
            carouselRef.current.scrollTo({
                left: slideWidth * index,
                behavior: 'smooth'
            });
        }

    };

    const goToSlide = (index: number, item: any) => {
        setCurrentSlide(index);
        props.setViewModelBusqueda({
            ...props.viewModelBusqueda,
            identUsuario: item.identUsuario
        });        
        props.CargarBandejaGestionSolicitudesPorUsuario(item.identUsuario);

    };

    const InitDefaultUserCarousel = async () => {
        let newItem: any = {
            identUsuario: 0, usuario: "TODOS"
        }
        const dataSourceTemp = [newItem, ...props.data];
        setDataSource(dataSourceTemp);
    }

    useEffect(() => {
        if (props.data) {
            InitDefaultUserCarousel();
        }
    }, [props.data]);

    return (
        <div className="carousel-container">
            <Button
                size="md"
                color="danger"
                endDecorator={<KeyboardDoubleArrowLeftIcon />}
                className="prev-button"
                onClick={goToPreviousSlide}>
            </Button>

            <div className="carousel" ref={carouselRef}>
                {dataSource.map((item: any, index: any) => (
                    <div key={item.identUsuario} className={`slide ${index === currentSlide ? 'active' : ''}`}
                        onClick={() => goToSlide(index, item)}>
                        <p>
                            <strong>{item.usuario}</strong>
                        </p>
                        {item?.solicitudesAsignadas >= 0 &&
                            <p>
                                <strong>Cantidad: {item?.solicitudesAsignadas}</strong>
                            </p>
                        }
                    </div>
                ))}
            </div>
            <Button
                size="md"
                color="danger"
                endDecorator={<KeyboardDoubleArrowRightIcon />}
                className="next-button"
                onClick={goToNextSlide}>
            </Button>
        </div>
    );

}
export default UserCarousel;