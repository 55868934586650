import { Button, FormControl, FormLabel } from "@mui/joy";
import SimbolAdd from "@mui/icons-material/Add";
import { BsSearch } from "react-icons/bs";

const obligatorioStyle = {
    color: "red",
    marginLeft: '5px'
};

export const ButtonSearch = (props: any) => {
    return (
        <FormControl>          
            <Button
                size="md"
                color="danger"
                endDecorator={<BsSearch />}
                sx={{ borderRadius: 24, width: 1 / 4,
                marginTop: '25px' }}
                disabled={props.disabled}
                onClick={props.onClick}>{props.title}
            </Button>
        </FormControl>
    )
};

export const ButtonGeneric = (props: any) => {
    return (
        <FormControl>          
            <Button
                size="md"
                color="danger"
                sx={{ borderRadius: 24, width: 2 / 4,
                marginTop: '25px' }}
                disabled={props.disabled}
                onClick={props.onClick}>
                    {props.label}  
            </Button>
        </FormControl>
    )
};