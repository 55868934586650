import { appDefaultValues } from '../../../config/Config';

export interface Table1 {
 periodo_rcc: string,
 por_deuda_normal: number,
 por_deuda_cpp: number,
 por_deuda_def: number,
 por_deuda_dud: number,
 por_deuda_per: number,
 linea_disponible: number,
 nro_entidades_rep: number,
 nro_entidades_rep_d_dir: number,
 nro_entidades_rep_d_indir: number,
 nro_entidades_rep_c_deuda: number,
 nro_entidades_rep_s_deuda: number
}

export interface Table2 {
 tipo_deuda: string,
 entidad: string,
 monto_deuda: number,
 cuota_fc: number,
 cuota_fc_real: number,
 solo_lectura: boolean
}

export interface Table3 {
 periodo: number,
 nro_reportantes: number,
 nro_reportantes_c_deuda: number,
 porc_deuda_normal: number,
 porc_deuda_cpp: number,
 porc_deuda_def: number,
 porc_deuda_dud: number,
 porc_deuda_per: number
}

export interface Table4 {
 periodo: number,
 nombre: string,
 deuda: number
}

export interface DatosReevaluacion {
 ident_solicitud_rcc: number,
 resultado: {
  tabla1: Table1 []
  tabla2: Table2 []
  tabla3: Table3 []
  tabla4: Table4 []
 }
}

export const DatosReevaluacionDefault = {
 ident_solicitud_rcc: appDefaultValues.NumberDefault,
 resultado: {
  tabla1: [],
  tabla2: [],
  tabla3: [],
  tabla4: []
 }
}

export interface SaveDatosReevaluacion {
 resultado: {
  tabla1: Table1 []
  tabla2: Table2 []
  tabla3: Table3 []
  tabla4: Table4 []
 }
}

export const SaveDatosReevaluacionDefault = {
 resultado: {
  tabla1: [],
  tabla2: [],
  tabla3: [],
  tabla4: []
 }
}