import SantanderLogoRojo from '../../../../../assets/media/icons/SantanderLogoRojo.svg';

export const ErrorComponent = ({error}:any) => {

    return( 
        <div className="loginBackground">
            <div className="contendorLogin">
              <div className="wrapper">
                <div className="formContenedorLogin">
                  <div className="divTextTitulo">
                    <label className="labelLoginTitulo">
                      <div className="row g-2 mb-2 align-items-center">
                        <div className="col-lg-3 ">
                          <img src={SantanderLogoRojo} alt="" style={{ width: '50%' }} />
                        </div>
                        <div className="col-lg-6 ">
                          <h1 className="textLabelLoginTitulo">
                            Santander
                          </h1>
                        </div>
                      </div>
                      <h3 className="textLabelLoginTitulo">
                        Consumer
                      </h3>
                      <h5 className="textLabelLoginSubTitulo">
                        Crédito Vehicular Santander Consumer
                      </h5>
                    </label>
                    <div className="rounded-10 p-3 p-md-4 mb-4 mb-md-4 ">
                      <div className="row g-2 mb-2 align-items-center textLabelLoginSubTitulo">
                        <div className="col-lg-12 ">
                          <h6 className="textLabelLoginSubTitulo">Ocurrió un error: {error.errorCode}</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          )
}