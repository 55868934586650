import { appDefaultValues } from "../../../../config/Config";
import { BTCrearPersonaJuridicaGrupoEconomico } from "./BTCrearPersonaJuridicaGrupoEconomico";

export class BTCrearPersonaJuridicaGruposEconomicos {
    private SdtJSPSsBTGrupoEconomico: BTCrearPersonaJuridicaGrupoEconomico[];

    constructor() {
        this.SdtJSPSsBTGrupoEconomico = appDefaultValues.ArrayDefault;
    }

    public getSdtJSPSsBTGrupoEconomico(): BTCrearPersonaJuridicaGrupoEconomico[] {
        return this.SdtJSPSsBTGrupoEconomico;
    }

    public setSdtJSPSsBTGrupoEconomico(SdtJSPSsBTGrupoEconomico: BTCrearPersonaJuridicaGrupoEconomico[]): void {
        this.SdtJSPSsBTGrupoEconomico = SdtJSPSsBTGrupoEconomico;
    }

}