import { appDefaultValues } from "../../../../../../config/Config"

export interface DatosPersonalesDelConyuge {
    TipoDocumentoId: number,
    NumeroDocumento: string,
    Nombres: string,
    ApellidoPaterno: string,
    ApellidoMaterno: string,
    FechaNacimiento: string | null,
    GeneroId: number,
    MagnitudId: number,
    ActividadEconomicaId: number,
    NacionalidadId: number,
    NivelEstudioId: number,
    Correo: string,
    ProfesionId: number,
    CargoLaboralId: number,
    CargoLaboral: string,
   
};

export const DatosPersonalesDelConyugeDefault: DatosPersonalesDelConyuge = {
    TipoDocumentoId: appDefaultValues.NumberDefault,
    NumeroDocumento: appDefaultValues.StringEmpty,
    Nombres: appDefaultValues.StringEmpty,
    ApellidoPaterno: appDefaultValues.StringEmpty,
    ApellidoMaterno: appDefaultValues.StringEmpty,
    FechaNacimiento: appDefaultValues.FechaDefault,
    GeneroId: appDefaultValues.NumberDefault,
    MagnitudId: appDefaultValues.NumberDefault,
    ActividadEconomicaId: appDefaultValues.NumberDefault,
    NacionalidadId: appDefaultValues.NumberDefault,
    NivelEstudioId: appDefaultValues.NumberDefault,
    Correo: appDefaultValues.StringEmpty,
    ProfesionId: appDefaultValues.NumberDefault,
    CargoLaboralId: appDefaultValues.NumberDefault,
    CargoLaboral: appDefaultValues.StringEmpty,
   
}