import ErrorIcon from '@mui/icons-material/Error';

const DocumentationRechazado = (props: any) => {
    return (
        <div className='documentation-mensaje-rechazo shadow'>
            <ErrorIcon sx={{ color: '#EC0000' }} />
            <div>
                {props.children}
            </div>
        </div>
    );
};

export default DocumentationRechazado;