import { ReactComponent as CloseIcon } from '../../../../../../../assets/media/icons/ico-close-modal.svg'
import { Button, FormControl, FormLabel, Input, Textarea } from '@mui/joy';
import './styles.scss';
import { Dialog, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow} from "@mui/material";
import ArrowRight from '../../../../../../../assets/media/icons/ico_arrowright.svg';
import MasterManager from '../../../../../../services/origination/wapimaster.service';
import { useEffect, useState } from 'react';
import { appConfigKey, appSecurityConfiguration } from '../../../../../../../config/Config';  
import { Toaster, toast } from "sonner";

const CambiarTeaOpen = (props: any) => {

    const [requestBody, setRequestBody] = useState<any>();    
    const [getSolicitud, setSolicitud] = useState<any>();
    
    useEffect(() => {
        if(props.data !== null && props.open) {
            alert(props.data);     
        }
    }, [props.open]);


    const handleChange = (e: any) => {
        let { name, value, type } = e.target;
        if (type === 'number' && value === "") {
            value = 0;
        };
        if (type === 'select') {
            value = parseInt(value);
        };
        if (type === 'date' && value === "Invalid Date") {
            value = null
        }
        
        setRequestBody({
            ...requestBody, [name]: value
        });
    };

    const SaveModal = () => {                
        if(requestBody === undefined || requestBody.teA_NUEVA == null || requestBody.teA_NUEVA === "") {
            toast.error(appConfigKey.keyTasaNuevaEmpty , { duration: appConfigKey.keyDurationToast });
            return;
        }

        if(!Number(requestBody.teA_NUEVA)) {
            toast.error(appConfigKey.keyTasaNuevaIsNumber , { duration: appConfigKey.keyDurationToast });
            return;
        }
        
        if(requestBody.comentario == null || requestBody.comentario === "") {
            toast.error(appConfigKey.keyComentarioCambiarTasaEmpty , { duration: appConfigKey.keyDurationToast });
            return;
        }
        props.handleClose(); 
    };      
    
    
    return (        
        <Dialog open={props.open} sx={{
            '& .MuiDialog-paper': {
                borderRadius: '15px',
            }
        }}>
            <Toaster position="top-center" richColors closeButton />
                <div className="change-rate-dialog-content">
                    <button className="btn btn-sm change-rate-close-btn" onClick={props.handleClose}>
                     <CloseIcon />                      
                    </button>                    
                    <div className='change-rate-dialog-title'>CAMBIAR TEA</div>                    
                     <div className="change-rate-info-container">
                        <div className="change-rate-info-row">
                            <span className="change-rate-label-info">TEA Actual:</span>
                            <span className="change-rate-label-info"><strong>{props.data}</strong></span>
                        </div>
                    </div> 
                    <div className="textAlignRigth">
                        <FormControl>
                            <FormLabel>
                            <span className='change-rate-label'>TEA</span>&nbsp;
                            <span style={{ color: "red" }}>* </span>
                            </FormLabel>
                            <Input type='change-rate-number' placeholder="Ingresar número" name='teA_NUEVA' value={requestBody?.teA_NUEVA} onChange={handleChange} />
                        </FormControl>
                    </div>
                    <div className="textAlignRigth cardObjets">
                            <FormLabel>
                            <span className='change-rate-label'>Comentario</span>&nbsp;
                            <span style={{ color: "red" }}>* </span>
                            </FormLabel>
                            <div className="row g-2 mb-2 align-items-center">
                                <div className="col-lg-12">
                                    <FormControl>
                                    <Textarea
                                        placeholder="Ingresar texto"
                                        minRows={2}
                                        sx={{ mb: 1 }}
                                        name='comentario'
                                        onChange={handleChange}
                                        value={requestBody?.comentario}
                                    />
                                    </FormControl>
                                </div>
                            </div>
                    </div>
                    <div className='change-rate-dialog-btn-save'>
                        <Button
                            color="danger" sx={{
                            borderRadius: 24,
                            width: '100%'                            
                            }}
                            onClick={() => {
                                SaveModal();
                                props.saveModal(requestBody);
                            }}
                            >Grabar
                            <span className='arrow-right'>
                                 <img src={ArrowRight} alt='arrow-right' /> 
                            </span>   
                        </Button>                 
                    </div>                   
                </div>
        </Dialog>
    );
}

export default CambiarTeaOpen;