import React, { useEffect, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { Button } from "@mui/joy";
//import * as Accordion from '@radix-ui/react-accordion';
import HistoricoIngresosResquestDialog from './scoringHistoricoIngreso';
import RequestManager from "../../../services/origination/wapirequest.service";
import { HistorialIngresos } from "../../../models/ManagerProfile/HistorialIngresos";
import { OtrosIngresos } from "../../../models/ManagerProfile/Titular";
import CardOtrosIngresos from './components/cardOtrosIngresos';

import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import GeneralHelper from "../../../helpers/GeneralHelper";
import { appConfigKey } from "../../../../config/Config";
import { IconButton, Tooltip } from "@mui/material";
import RestorePageIcon from '@mui/icons-material/RestorePage';

export const ScoringTitular = ({ titular, titularBeforce, setTitular, handleReevaluar, esModoLectura, datosCondicionFinanciamiento, setDatosCondicionFinanciamiento }: any) => {

  const [historicoIngresos, setHistoricoIngresos] = useState<HistorialIngresos[]>([]);
  const [esModoLecturaScoringTitular, setEsModoLecturaScoringTitular] = useState(false);

  const getListHistoricoIngresos = async () => {
    var body = {
      idenT_PERSONA: titular.ingresos.idPersona,
    };
    await RequestManager.GetMnIngresosPersonaHistorico(body)
      .then((response: any) => {
        setHistoricoIngresos(response.data.content);
        setHistoricoIngresoRequestDialogOpen(true);
      });
  }

  const [historicoIngresoRequestDialogOpen, setHistoricoIngresoRequestDialogOpen] = React.useState<boolean>(false);
  const handleHistoricoIngresoRequestDialogClose = () => {
    setHistoricoIngresoRequestDialogOpen(false);
  };

  const handleHistoricoIngresoRequestDialogOpen = () => {
    getListHistoricoIngresos();
  };

  const handleAnualizadoIngreso = async (event: any) => {
    const ingresoNeto = GeneralHelper.QuitarComasAMiles(event.target.value)
    if (/^\d*\.?\d{0,2}$/.test(ingresoNeto)) {
      let ingresos = titular.ingresos;
      const newTotalIngreso = (ingresos.ingresoAnualizado && (ingresos.montoMinimoAnualizado <= Number(ingresoNeto))) ? Number(((Number(ingresoNeto) * 14) / 12).toFixed(2)) : 0;
      ingresos = {
        ...ingresos,
        ingresoNeto: ingresoNeto,
        totalAnualizado: newTotalIngreso
      };
      setTitular({ ...titular, ingresos: ingresos });
    }
  }

  const handleAnualizadoQuinta = async (event: any) => {
    let ingresos = titular.ingresos;
    const newTotalIngreso = (event.target.checked && (ingresos.montoMinimoAnualizado <= Number(ingresos.ingresoNeto))) ? Number(((titular?.ingresos?.ingresoNeto * 14) / 12).toFixed(2)) : 0;

    ingresos = {
      ...ingresos,
      ingresoAnualizado: event.target.checked,
      totalAnualizado: newTotalIngreso
    };
    setTitular({ ...titular, ingresos: ingresos });
    setDatosCondicionFinanciamiento({ ...datosCondicionFinanciamiento, indicadorCuotaDoble: false });
  }
  const [otrosIngresos, setOtrosIngresos] = useState<OtrosIngresos[]>([]);
  const [otrosIngresosBeforce, setOtrosIngresosBeforce] = useState<any>([]);

  const restoreIngresosTitular = () => {

    let ingresosBeforce: any = null;
    let otrosIngresosBeforceTemp: any = [];
    otrosIngresosBeforce?.map((item: any, index: number) => {
      ingresosBeforce = {};
      ingresosBeforce.idIngresoPersona = item.idIngresoPersonaTemp;
      ingresosBeforce.idmoneda = item.idmonedaTemp;
      ingresosBeforce.moneda = item.monedaTemp;
      ingresosBeforce.ingresoNeto = item.ingresoNetoTemp;
      ingresosBeforce.comentario = item.comentarioTemp;
      ingresosBeforce.idcategoriaLaboral = item.idcategoriaLaboralTemp;
      ingresosBeforce.categoriaLaboral = item.categoriaLaboralTemp;
      ingresosBeforce.ruc = item.rucTemp;
      ingresosBeforce.razonSocial = item.razonSocialTemp;
      ingresosBeforce.fechaIngresoLaboral = item.fechaIngresoLaboralTemp;
      ingresosBeforce.ingresoAnualizado = item.ingresoAnualizadoTemp;
      ingresosBeforce.totalAnualizado = item.totalAnualizadoTemp;
      ingresosBeforce.montoMinimoAnualizado = item.montoMinimoAnualizadoTemp;
      ingresosBeforce.montoMinimoAnualizadoDolares = item.montoMinimoAnualizadoDolaresTemp;
      otrosIngresosBeforceTemp.push(ingresosBeforce);
    });
    setTitular({
      ...titular,
      ingresos: titularBeforce.ingresos,
      otrosIngresos: otrosIngresosBeforceTemp
    });
  };


  useEffect(() => {
    setOtrosIngresos(titular.otrosIngresos);
    setEsModoLecturaScoringTitular(!esModoLectura ? false : esModoLectura);
  }, [titular])

  useEffect(() => {

      let ingresosBeforceTemp: any = null;
      let otrosIngresosBeforceTemp: any = [];
      titularBeforce?.otrosIngresos?.map((item: any, index: number) => {
        ingresosBeforceTemp = {};
        ingresosBeforceTemp.idIngresoPersonaTemp = item.idIngresoPersona;
        ingresosBeforceTemp.idmonedaTemp = item.idmoneda;
        ingresosBeforceTemp.monedaTemp = item.moneda;
        ingresosBeforceTemp.ingresoNetoTemp = item.ingresoNeto;
        ingresosBeforceTemp.comentarioTemp = item.comentario;
        ingresosBeforceTemp.idcategoriaLaboralTemp = item.idcategoriaLaboral;
        ingresosBeforceTemp.categoriaLaboralTemp = item.categoriaLaboral;
        ingresosBeforceTemp.rucTemp = item.ruc;
        ingresosBeforceTemp.razonSocialTemp = item.razonSocial;
        ingresosBeforceTemp.fechaIngresoLaboralTemp = item.fechaIngresoLaboral;
        ingresosBeforceTemp.ingresoAnualizadoTemp = item.ingresoAnualizado;
        ingresosBeforceTemp.totalAnualizadoTemp = item.totalAnualizado;
        ingresosBeforceTemp.montoMinimoAnualizadoTemp = item.montoMinimoAnualizado;
        ingresosBeforceTemp.montoMinimoAnualizadoDolaresTemp = item.montoMinimoAnualizadoDolares;
        otrosIngresosBeforceTemp.push(ingresosBeforceTemp);
      });

      setOtrosIngresosBeforce(otrosIngresosBeforceTemp);
    

  }, [titularBeforce])


  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>INGRESOS TITULAR</AccordionSummary>
          <AccordionDetails>
            <>
              <div className="row">
                <div className="col-lg-12">
                  <div style={{ display: "flex", flexDirection: "column", position: "relative" }}>
                    <Tooltip title="Restaurar valores">
                      <IconButton
                        aria-label="restorepage"
                        size="large" color="error"
                        disabled={esModoLecturaScoringTitular}
                        style={{ position: "absolute", right: "0", top: "0" }}
                        onClick={restoreIngresosTitular}>
                        <RestorePageIcon fontSize="inherit" />
                      </IconButton>
                    </Tooltip>
                  </div>
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-3 cardObjets">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormLabel>Moneda</FormLabel>
                    <Input
                      name="moneda"
                      placeholder="Soles"
                      value={titular.ingresos.moneda}
                      disabled={true}
                    />
                  </LocalizationProvider>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>Ingreso neto S/<span style={{ color: "red" }}> * </span></FormLabel>
                    <Input
                      name="ingresoNeto"
                      placeholder="Ej: 00000000"
                      value={GeneralHelper.NumeroConCommas(titular.ingresos.ingresoNeto)}
                      disabled={esModoLecturaScoringTitular}
                      sx={{
                        '--Input-radius': '16px',
                      }}
                      onChange={handleAnualizadoIngreso}
                    />
                  </FormControl>
                </div>
                <div className="col-lg-5 cardObjets">
                  <FormControl>
                    <FormLabel>Comentario {/*<span style={{ color: "red" }}> * </span>*/}</FormLabel>
                    <Input
                      name="comentario"
                      placeholder=""
                      value={titular.ingresos.comentario}
                      disabled={esModoLecturaScoringTitular}
                      onChange={(event: any) => {
                        let ingresos = titular.ingresos;
                        ingresos = { ...ingresos, comentario: event.target.value };
                        setTitular({ ...titular, ingresos: ingresos });
                      }} />
                  </FormControl>
                </div>
              </div>

              <div className="row g-2 mb-2 align-items-center">
                {titular.ingresos.idcategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCinco && <div className="col-lg-3 cardObjets">
                  <FormLabel>Anualizado solo 5ta</FormLabel>
                  <Switch
                    color={titular.ingresos.ingresoAnualizado ? "success" : "neutral"}
                    checked={titular.ingresos.ingresoAnualizado}
                    onChange={handleAnualizadoQuinta}
                    disabled={esModoLecturaScoringTitular}
                    slotProps={{
                      track: {
                        children: (
                          <React.Fragment>
                            <Typography
                              component="span"
                              level="inherit"
                              sx={{ ml: "10px" }}
                            >
                              Si
                            </Typography>
                            <Typography
                              component="span"
                              level="inherit"
                              sx={{ mr: "8px" }}
                            >
                              No
                            </Typography>
                          </React.Fragment>
                        ),
                      },
                    }}
                    sx={{
                      "--Switch-thumbSize": "27px",
                      "--Switch-trackWidth": "64px",
                      "--Switch-trackHeight": "31px",
                    }}
                  />
                </div>}
                {/* <div className="col-lg-3 cardObjets">
                  <Button
                    size="md"
                    color="danger"
                    variant="outlined"
                    disabled={esModoLecturaScoringTitular}
                    sx={{
                      borderRadius: 24,
                      borderColor: "red",
                      fontWeight: 650,
                      width: 1 / 2,
                    }}
                    onClick={handleReevaluar}
                  >
                    Reevaluar
                  </Button>
                </div>*/}
                <div className="col-lg-4 cardObjets">
                  <Button
                    size="md"
                    color="danger"
                    variant="outlined"
                    sx={{
                      borderRadius: 24,
                      borderColor: "red",
                      fontWeight: 650,
                      width: 1 / 2,
                    }}
                    onClick={handleHistoricoIngresoRequestDialogOpen}
                  >
                    Ver historial ingresos
                  </Button>
                </div>

              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-3 cardObjets">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormLabel>Categoría laboral</FormLabel>
                    <span>{titular.ingresos.categoriaLaboral}</span>
                  </LocalizationProvider>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>RUC</FormLabel>
                    <span>{titular.ingresos.ruc}</span>
                  </FormControl>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>Razón Social</FormLabel>
                    <span>{titular.ingresos.razonSocial}</span>
                  </FormControl>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <FormLabel>Fecha ingreso laboral</FormLabel>
                      <DatePicker
                        format="DD/MM/YYYY"
                        disabled={esModoLecturaScoringTitular}
                        value={dayjs(GeneralHelper.ParseDateString('DD/MM/YYYY', titular.ingresos.fechaIngresoLaboral))}
                        onChange={(date: any | null) => {
                          let ingresos = titular.ingresos;
                          ingresos = { ...ingresos, fechaIngresoLaboral: !date ? '' : date.format("DD/MM/YYYY") };
                          setTitular({ ...titular, ingresos: ingresos });
                        }}
                      />
                    </LocalizationProvider>
                  </FormControl>
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                {titular.ingresos.idcategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCinco && <div className="col-lg-3 cardObjets">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormLabel>Ingreso anualizado</FormLabel>
                    <span>{GeneralHelper.NumeroConCommas(titular.ingresos.totalAnualizado)}</span>
                  </LocalizationProvider>
                </div>}
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>Separación de bienes</FormLabel>
                    <span>{titular.ingresos.separacionBienes}</span>
                  </FormControl>
                </div>
              </div>

              {titular.ingresos.idIngresoPersonaTrabajoAnterior !== 0 &&
                <>
                  <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-3 cardObjets">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormLabel>Categoría anterior</FormLabel>
                        <span>{titular.ingresos.CategoríaLaboralAnterior}</span>
                      </LocalizationProvider>
                    </div>
                    <div className="col-lg-3 cardObjets">
                      <FormControl>
                        <FormLabel>RUC</FormLabel>
                        <span>{titular.ingresos.rucAnterior}</span>
                      </FormControl>
                    </div>
                    <div className="col-lg-3 cardObjets">
                      <FormControl>
                        <FormLabel>Razón Social</FormLabel>
                        <span>{titular.ingresos.razonSocialAnterior}</span>
                      </FormControl>
                    </div>
                    <div className="col-lg-3 cardObjets">
                      <FormControl>
                        <FormLabel>Fecha ingreso laboral</FormLabel>
                        <span>{titular.ingresos.fechaIngresoLaboralAnterior}</span>
                      </FormControl>
                    </div>
                  </div>
                  <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-3 cardObjets">
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <FormLabel>Fecha hasta</FormLabel>
                        <FormLabel>{titular.ingresos.fechaHasta}</FormLabel>
                      </LocalizationProvider>
                    </div>
                  </div>
                </>
              }
              <CardOtrosIngresos otrosIngresos={otrosIngresos} setOtrosIngresos={setOtrosIngresos} handleReevaluar={handleReevaluar} esModoLectura={esModoLecturaScoringTitular} />
            </>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>


      <HistoricoIngresosResquestDialog
        open={historicoIngresoRequestDialogOpen}
        handleClose={handleHistoricoIngresoRequestDialogClose}
        historicoIngresos={historicoIngresos}
      />
    </div>
  );
};

export default ScoringTitular;