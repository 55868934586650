import { createContext, useContext, useEffect, useState } from "react";
import JsonHelper from "../../../../helpers/JsonHelper";
import { General } from "../obtenerServicios";
import { StepsContext } from "./StepsProvider";
import ValidacionStepFourHelper from "../helpers/ValidacionStepFourHelper";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import { appConfigKey, appConfigTipoTransaccionLog, appDefaultValues, appSecurityConfiguration } from "../../../../../config/Config";
import { BanTotalComisiones, BanTotalListaComisiones } from "../../../../constant/BanTotalComisiones";
import { BantotalAplicaBonoA, BantotalTipoDescuento } from "../../../../constant/BantotalTipoDescuento";
import { TMnSolicitudCronogramaRequest } from "../../../../models/MasterManager/solicitud-cronograma-request.model";
import { toast } from "sonner";
import { MnIntegracionSolicitudRequest } from "../../../../models/RequestManager/integracion-solicitud-request.model";
import { CalcularInicialConBonoRequest, calcularValorInicialDolares, calcularValorInicialDolaresOriginal, calcularValorVehiculoBallon, calcularValorVehiculoDolares, calcularValorVehiculoDolaresOriginal } from "../../../../helpers/CalculosConBonoHelper";

export const StepFourContext = createContext<any>({});

export const StepFourProvider = ({ children }: any) => {

  const {
    step,
    setStep,
    setBtnContinuarLoading,
    setClickNext,
    viewModelStepTwoFinal,
    viewModelStepThreeFinal,
    setViewModelStepThreeFinal,
    setViewModelStepFourFinal,
    setLoadingSteps,
    clienteUId,
    responseStepOne,
    responseStepTwo,
    listTipoDocumento, listProfesion, listEstadoCivil, listPais,
    listIdentidadesGenero,
    listMagnitud,
    listActividadEconomica,
    listCategoriaLaboral,
    listMoneda,
    listRegimen,
    listaEstadosVehiculos,
    listProveedorGPS,
    solicitudData,
    viewModelStepOneFinal,
    readonlyForm,
    TCEA, setTCEA,
    setStepsTotalFinanciamiento,
    memValor,
    validacionModelicaDecisionRCH,
    isTitularJuridico,
  } = useContext(StepsContext);

  const [newCronograma, setNewCronograma] = useState<any>(null);
  const [listaCronograma, setListaCronograma] = useState([]);
  const [listaCuotasPorPlazos, setListaCuotasPorPlazos] = useState([]);
  const [listaComisionesCronoSel, setListaComisionesCronoSel] = useState([]);
  const [listaComisionesCuotaCronoSel, setListaComisionesCuotaCronoSel] = useState([]);
  const [operacionUId, setOperacionUId] = useState(0);
  const [totalFinanciamiento, setTotalFinanciamiento] = useState(0);
  const [temptotalFinanciamiento, setTempTotalFinanciamiento] = useState(0);

  const GenerateActualizacionSolicitudV2 = async (body: any) => {
    return await General.GenerateActualizacionSolicitudV2(body);
  };

  const SimularConograma = async () => {

    let valorCuotaBallon = 0;

    const requestCalculoInicial = new CalcularInicialConBonoRequest();

    requestCalculoInicial.set_divisa(Number(viewModelStepTwoFinal.MonedaId));
    requestCalculoInicial.set_tipoCambio(Number(viewModelStepTwoFinal.TipoCambio));
    requestCalculoInicial.set_porcentajeInicial(Number(viewModelStepTwoFinal.Inicial));
    requestCalculoInicial.set_montoVehiculoSoles(Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoSoles)));
    requestCalculoInicial.set_montoVehiculoDolares(Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoDolares)));
    requestCalculoInicial.set_tipoDescuento(Number(viewModelStepThreeFinal.formulario.identCIPrecio));


    const campaniaEncontrada: any = viewModelStepThreeFinal.listas.tempListaCampania.find(
      (x: any) => x.id === viewModelStepThreeFinal.formulario.identCampania
    );

    if (campaniaEncontrada !== undefined) {
      requestCalculoInicial.set_montoBono(Number(campaniaEncontrada.montoBono));
    }

    if (viewModelStepThreeFinal.activarCuotaBallon) {
      const porcentaje = Number(viewModelStepThreeFinal.formulario.CuotaBallon) / 100;

      var montoVehiculo = calcularValorVehiculoBallon(requestCalculoInicial);

      valorCuotaBallon = porcentaje * montoVehiculo;
      valorCuotaBallon = Number(valorCuotaBallon.toFixed(2));
    }

    let montoOferta = Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoFinanciarDolares));

    const tasa = viewModelStepThreeFinal.formulario.tea;

    const tempListCuotas = [];

    tempListCuotas.push(viewModelStepThreeFinal.formulario.identPlazosMeses);

    let tempGastos: any = [];

    viewModelStepThreeFinal.listas.tempListaComisionesSeleccionas.map((codigo: number) => {

      let tempComision = BanTotalListaComisiones.find((item) => item.Codigo === codigo);
      let tempComisionProducto = viewModelStepThreeFinal.listas.tempListaComisiones.find((item: any) => item.codigo === codigo);

      const tempComisionAdd = {
        "codigo": appDefaultValues.NumberDefault,
        "descripcion": appDefaultValues.StringEmpty,
        "importe": appDefaultValues.NumberDefault,
        "porcentaje": appDefaultValues.NumberDefault
      };

      if (tempComision !== undefined) {
        tempComisionAdd.codigo = tempComision.Codigo;
        tempComisionAdd.descripcion = tempComision.Comision;
      }

      if (tempComisionProducto !== undefined) {
        tempComisionAdd.importe = tempComisionProducto.importe;
        tempComisionAdd.porcentaje = tempComisionProducto.porcentaje;
      }

      tempGastos.push(tempComisionAdd);

    });

    const tempSeguroVehicular = viewModelStepThreeFinal.listas.tempListaSeguroVehicular.find((x: any) => x.id === viewModelStepThreeFinal.formulario.identSeguroVehicular);
    let tempSeguroVehicularDescripcion = appDefaultValues.StringEmpty;

    if (tempSeguroVehicular !== undefined) {
      tempSeguroVehicularDescripcion = tempSeguroVehicular.description;
    }

    let tempTipoCobertura: any | undefined = undefined;
    let tempSeguroCoberturaDescripcion = appDefaultValues.StringEmpty;
    let tempSeguroCoberturaPorcentaje = appDefaultValues.NumberDefault;

    if (isTitularJuridico) {
      tempTipoCobertura = viewModelStepThreeFinal.listas.tempListaTipoCoberturaDesg.find((x: any) => x.id === viewModelStepThreeFinal.formulario.identTipoCoberturaDesg);

      if (tempTipoCobertura !== undefined) {
        tempSeguroCoberturaDescripcion = tempTipoCobertura.description;
        tempSeguroCoberturaPorcentaje = tempTipoCobertura.porcentaje;
      }
    }

    const comisionPorte = [];

    const tempComisionPorte = tempGastos.find((x: any) => x.codigo === BanTotalComisiones.PORTES);

    if (tempComisionPorte !== undefined) {
      comisionPorte.push(tempComisionPorte);
    }

    tempGastos = tempGastos.filter((x: any) => x.codigo !== BanTotalComisiones.PORTES);

    let tempSeguros = [
      {
        "porcentaje": Number(viewModelStepThreeFinal.formulario.costoSeguroVehicular),
        "descripcion": tempSeguroVehicularDescripcion,
        "importe": 0,
        "tipo": "Automotor",
        "codigo": Number(viewModelStepThreeFinal.formulario.identSeguroVehicular),
      }
    ];

    if (!isTitularJuridico) {
      tempSeguros.push(
        {
          "porcentaje": Number(tempSeguroCoberturaPorcentaje),
          "descripcion": tempSeguroCoberturaDescripcion,
          "importe": 0,
          "tipo": "Vida",
          "codigo": Number(viewModelStepThreeFinal.formulario.identTipoCoberturaDesg)
        }
      );
    }

    let aplicaBonoA: string = appDefaultValues.StringEmpty;

    if (viewModelStepThreeFinal.formulario.tipoDescuento === BantotalTipoDescuento.OPCION_DOBLE) {
      if (viewModelStepThreeFinal.formulario.identCIPrecio === BantotalTipoDescuento.PRECIO_VEHICULO) {
        aplicaBonoA = BantotalAplicaBonoA.PRECIO_VEHICULO;
      }
      if (viewModelStepThreeFinal.formulario.identCIPrecio === BantotalTipoDescuento.CUOTA_INICIAL) {
        aplicaBonoA = BantotalAplicaBonoA.MONTO_INICIAL;
      }
    }

    const tempUsoVehiculoId = viewModelStepTwoFinal.UsoVehiculoId;

    const jsonRequestSimularOfertasVehicular = JsonHelper.crearJsonRequestSimularOfertasVehicular(
      viewModelStepTwoFinal.SucursalId,
      viewModelStepTwoFinal.VendedorId,
      viewModelStepTwoFinal.ConcesionarioId,
      viewModelStepThreeFinal.formulario.identCampania,
      // calcularValorVehiculoDolares(requestCalculoInicial),
      calcularValorVehiculoDolaresOriginal(requestCalculoInicial),
      viewModelStepThreeFinal.formulario.identProveedorGPS,
      appConfigKey.keyIdentificadorDolares,
      tempUsoVehiculoId,
      viewModelStepTwoFinal.VersionId,
      Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.TipoCambio)),
      aplicaBonoA,
      viewModelStepTwoFinal.EstadoVehiculoId,
      // calcularValorInicialDolares(requestCalculoInicial),
      calcularValorInicialDolaresOriginal(requestCalculoInicial),
      viewModelStepThreeFinal.formulario.identProducto,
      Number(viewModelStepThreeFinal.formulario.diaPago),
      comisionPorte,
      tempGastos,
      tempListCuotas,
      viewModelStepThreeFinal.formulario.esCuotasDobles,
      valorCuotaBallon,
      // calcularValorVehiculoDolares(requestCalculoInicial) - calcularValorInicialDolares(requestCalculoInicial),
      calcularValorVehiculoDolaresOriginal(requestCalculoInicial) - calcularValorInicialDolaresOriginal(requestCalculoInicial),
      tasa,
      tempSeguros
    );

    const tempEsCuotasDobles = viewModelStepThreeFinal.formulario.esCuotasDobles;
    const tempPlazosMeses = Number(viewModelStepThreeFinal.formulario.plazosMeses);
    const tempJsonRequest = jsonRequestSimularOfertasVehicular;
    const tieneCuotaBallon = viewModelStepThreeFinal.activarCuotaBallon;

    General.Conograma(clienteUId, tempPlazosMeses, tempUsoVehiculoId, tempEsCuotasDobles, tempJsonRequest, tieneCuotaBallon).then(async (cronograma: any) => {

      if (cronograma === null) {
        setStep(3);
        return;
      }

      if (cronograma !== null) {

        insertarIntegracionSolicitud({ clienteUId: clienteUId, simulacionUId: cronograma.simulacionUId }, cronograma?.detalleOfertaRequest, cronograma?.detalleOfertaResponse?.data?.content);

        setListaCronograma(cronograma.listaConograma);
        setListaCuotasPorPlazos(cronograma.listaCuotasPorPlazos);
        setListaComisionesCronoSel(cronograma.listaComisionesCronoSel);
        setListaComisionesCuotaCronoSel(cronograma.listaComisionesCuotaCronoSel);
        setOperacionUId(cronograma.operacionUId);
        setTotalFinanciamiento(cronograma.totalFinanciamiento);
        setViewModelStepThreeFinal({
          ...viewModelStepThreeFinal,
          cronograma: {
            totalFinanciamiento: cronograma.totalFinanciamiento,
            listaConograma: cronograma.listaConograma
          }
        })
        setTCEA(cronograma.tcea);
        setStepsTotalFinanciamiento(cronograma.calculoMontoFinanciacion);
        setTempTotalFinanciamiento(cronograma.calculoMontoFinanciacion);

        let listaCampania = viewModelStepThreeFinal.listas.tempListaCampania;
        let listaPlazosMeses = viewModelStepThreeFinal.listas.tempListaPlazosMeses;
        let listaTipoCoberturaDesg = viewModelStepThreeFinal.listas.tempListaTipoCoberturaDesg;

        const listasTemp = {
          listTipoDocumento,
          listEstadoCivil,
          listIdentidadesGenero,
          listMagnitud,
          listActividadEconomica,
          listCategoriaLaboral,
          listMoneda,
          listPais,
          listProfesion,
          listRegimen,
          listaTipoCoberturaDesg,
          listaEstadosVehiculos,
          listProveedorGPS,
          listaCampania,
          listaPlazosMeses
        };

        let newCronogramaTemp: any = {
          listaCuotasPorPlazos: cronograma?.listaCuotasPorPlazos,
          listaCronograma: cronograma?.listaConograma,
          listaComisionesCronoSel: cronograma?.listaComisionesCronoSel,
          listaComisionesCuotaCronoSel: cronograma?.listaComisionesCuotaCronoSel,
          operacionUId: cronograma?.operacionUId,
          totalFinanciamiento: cronograma?.totalFinanciamiento
        }

        const RequestGenerateActualizacionSolicitudV2 = JsonHelper.crearJsonRequestGenerateActualizacionSolicitudV2(
          step,
          solicitudData,
          viewModelStepOneFinal.newTitularData,
          viewModelStepOneFinal.newConyugeData,
          viewModelStepOneFinal.newIngresosTitularData,
          viewModelStepOneFinal.newIngresosConyugeData,
          viewModelStepOneFinal.newPersonaCompraParaData,
          viewModelStepOneFinal.newPersonaCompraParaConyugeData,
          viewModelStepOneFinal.newFiadorData,
          viewModelStepOneFinal.newObservacionData,
          viewModelStepTwoFinal,
          viewModelStepThreeFinal.formulario,
          newCronogramaTemp,
          null,
          null,
          null,
          null,
          null,
          listasTemp,
          appSecurityConfiguration.SessionUserId,
          TCEA,
          isTitularJuridico,
          null,
          null,
        );

        RequestGenerateActualizacionSolicitudV2.mnSolicitudInsertUpdateRequest.monT_CALC_FINANCIAMIENTO = cronograma?.calculoMontoFinanciacion;

        const solicitudSaved = await GenerateActualizacionSolicitudV2(RequestGenerateActualizacionSolicitudV2);

        if (!solicitudSaved) {
          toast.error("Falló el guardado de cronograma, vuelva a intentarlo nuevamente.", { duration: appConfigKey.keyDurationToast });
          return;
        }

      }
    }).finally(() => {
      setLoadingSteps(false);
    });



  };

  const insertarIntegracionSolicitud = async (params: any, request: any, response: any) => {
    let body = {
      idenT_SOLICITUD: solicitudData?.idenT_SOLICITUD,
      params: JSON.stringify(params),
      request: JSON.stringify(request),
      response: JSON.stringify(response),
      iD_TIPO_TRANSACCION: appConfigTipoTransaccionLog.ObtenerDetalleSolicitudEjecutivo,
      idenT_USUARIO_CREADOR: appSecurityConfiguration.SessionUserId
    } as MnIntegracionSolicitudRequest;

    await General.GetMnIntegracionSolicitudInsert(body);
  }

  const GetConograma = async () => {
    let response = await General.GetMnSolicitudCronograma({ idenT_SOLICITUD: solicitudData?.idenT_SOLICITUD } as TMnSolicitudCronogramaRequest);
    setListaCronograma(response.listaConograma);
    const tempCronograma = viewModelStepThreeFinal.cronograma;
    tempCronograma.listaConograma = response.listaConograma;
    setViewModelStepThreeFinal({
      ...viewModelStepThreeFinal,
      cronograma: tempCronograma
    })
  }

  const InitConograma = async () => {

    if (validacionModelicaDecisionRCH) {
      toast.error(validacionModelicaDecisionRCH, { duration: appConfigKey.keyDurationToast });
    }

    if (readonlyForm) {
      GetConograma();
    } else {
      SimularConograma();
    }
  }

  const resetSendClick = () => {
    setClickNext(0);
    setBtnContinuarLoading(false);
    setNewCronograma(null);
    setLoadingSteps(false);
  };

  useEffect(() => {
    if (viewModelStepThreeFinal !== null && viewModelStepTwoFinal !== null) {
      InitConograma();
    }
  }, []);

  const continuarSiguientePaso = () => {

    if (newCronograma !== null) {

      if (readonlyForm) {
        setViewModelStepFourFinal(newCronograma);
        setStep(step + 1);
        resetSendClick();
        return;
      }

      const validarNewCronograma = !ValidacionStepFourHelper.NewCronograma(newCronograma);

      if (validarNewCronograma) {
        resetSendClick();
        return;
      }

      setViewModelStepFourFinal(newCronograma);

      // let listaCampania = viewModelStepThreeFinal.listas.tempListaCampania;
      // let listaPlazosMeses = viewModelStepThreeFinal.listas.tempListaPlazosMeses;
      // let listaTipoCoberturaDesg = viewModelStepThreeFinal.listas.tempListaTipoCoberturaDesg;

      // const listasTemp = {
      //   listTipoDocumento,
      //   listEstadoCivil,
      //   listIdentidadesGenero,
      //   listMagnitud,
      //   listActividadEconomica,
      //   listCategoriaLaboral,
      //   listMoneda,
      //   listPais,
      //   listProfesion,
      //   listRegimen,
      //   listaTipoCoberturaDesg,
      //   listaEstadosVehiculos,
      //   listProveedorGPS,
      //   listaCampania,
      //   listaPlazosMeses
      // };

      // const RequestGenerateActualizacionSolicitudV2 = JsonHelper.crearJsonRequestGenerateActualizacionSolicitudV2(
      //   step,
      //   solicitudData,
      //   viewModelStepOneFinal.newTitularData,
      //   viewModelStepOneFinal.newConyugeData,
      //   viewModelStepOneFinal.newIngresosTitularData,
      //   viewModelStepOneFinal.newIngresosConyugeData,
      //   viewModelStepOneFinal.newPersonaCompraParaData,
      //   viewModelStepOneFinal.newPersonaCompraParaConyugeData,
      //   viewModelStepOneFinal.newFiadorData,
      //   viewModelStepOneFinal.newObservacionData,
      //   viewModelStepTwoFinal,
      //   viewModelStepThreeFinal.formulario,
      //   newCronograma,
      //   null,
      //   null,
      //   null,
      //   null,
      //   null,
      //   listasTemp,
      //   appSecurityConfiguration.SessionUserId,
      //   TCEA
      // );

      // RequestGenerateActualizacionSolicitudV2.mnSolicitudInsertUpdateRequest.monT_CALC_FINANCIAMIENTO = temptotalFinanciamiento;

      // GenerateActualizacionSolicitudV2(RequestGenerateActualizacionSolicitudV2);
      const jsonResponseModelicaPasoDos = responseStepTwo;
      let tieneDerivacionActivo = jsonResponseModelicaPasoDos?.response?.Politica_Creditos?.decision_Credito?.mesa_Creditos?.derivacion == appConfigKey.keyCodigoDerivacionActivo;

      let validacionModelicaMEM = ValidacionStepFourHelper.ModelicaMEM(memValor, newCronograma, tieneDerivacionActivo);

      if (validacionModelicaMEM) {
        if (!tieneDerivacionActivo) {
          toast.error(validacionModelicaMEM, { duration: appConfigKey.keyDurationToast });
          resetSendClick();
          return;
        } else {
          toast.warning(validacionModelicaMEM, { duration: appConfigKey.keyDurationToast });         
        }
      }

      resetSendClick();
      setStep(step + 1);
    }

  }

  useEffect(() => continuarSiguientePaso(), [newCronograma]);

  return (
    <StepFourContext.Provider value={{
      newCronograma,
      setNewCronograma,
      listaCronograma,
      listaCuotasPorPlazos,
      listaComisionesCronoSel,
      listaComisionesCuotaCronoSel,
      operacionUId,
      totalFinanciamiento
    }}>
      {children}
    </StepFourContext.Provider>
  );
};

