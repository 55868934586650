import { useState, useEffect } from 'react';
import ServicesProvider from '../../../../../services/support/wapiservicesprovider';

const minutos30EnMilisegundos = 1800000;

const UpdateOtherService = () => {

  const [serviciosActivos, setServiciosActivos] = useState(true);

  let contadorTimeout: any;

  const resetTimer = () => {
    clearTimeout(contadorTimeout);
    contadorTimeout = setTimeout(() => setServiciosActivos(false), minutos30EnMilisegundos);
  };

  useEffect(() => {

    resetTimer();

    return () => {
      clearTimeout(contadorTimeout);
    };

  }, []);

  useEffect(() => {
    if (!serviciosActivos) {
        ServicesProvider.AutenticacionBantotal().then(() => setServiciosActivos(true));
        resetTimer();
    }
  }, [serviciosActivos]);

  return (<></>);
};

export default UpdateOtherService;