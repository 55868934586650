import ArrowRight from '../../../../../assets/media/icons/ico_arrowright.svg';

const SolicitudeBtnSend = (props: any) => {

    const { bloquearInputs  } = props;

    return (
        <div className={ bloquearInputs ? 'solicitude-button-red solicitude-button-red-disable' : 'solicitude-button-red' } onClick={() => {
            
            if (bloquearInputs) {
                return;
            }

            props.onClick();

        }}>
            <img src={ArrowRight} /> {props.text}
        </div>
    );
};

export default SolicitudeBtnSend;