import { Box, FormControl, FormLabel, Input, Switch, Tooltip, Typography } from "@mui/joy";
import { Select, MenuItem, Autocomplete, TextField, AutocompleteCloseReason, AutocompleteInputChangeReason, AutocompleteChangeReason, makeStyles } from '@mui/material';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import React from "react";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import './styles.scss';
import icoQuestion from '../../../../../../assets/media/icons/ico_question.svg';
import { useState } from "react";
import GeneralHelper from "../../../../../helpers/GeneralHelper";

const obligatorioStyle = {
    color: "red",
    marginLeft: '5px'
};

export const SelectAutocompleteInput = (props: any) => {
    var [showToolTip, setShowToolTip] = useState(false);
    const handlerButtonClick = (e: any) => {
        setShowToolTip(!showToolTip);

        //e.target.open=true;
    }

    const isOptionEqualToValue = (option: any, value: number | null) => {
        return option.id === value;
    };

    const defaultOption = { id: 0, description: 'Seleccione' };
    const optionsWithDefault = [defaultOption, ...props?.options ?? []];
    const selectedOption = optionsWithDefault.find((option: any) => option.id === props.value) ?? null;

    let lastValue = selectedOption;

    return (
        <FormControl>
            {props.title !== undefined && (
                <FormLabel>{props.title} {(props.required === undefined || props.required === true) && <span style={obligatorioStyle}>*</span>}
                    {props.tooltip !== undefined && (<Tooltip sx={{ borderColor: '#0089B5' }} open={showToolTip} disableInteractive={true} title={
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                maxWidth: 250,
                                justifyContent: 'center',
                                p: 1
                            }}
                        >
                            {props.tooltip}
                        </Box>}
                        variant="outlined"
                        placement="top-start"
                        size="sm">
                        <button className="circle-btn-tooltip" onClick={handlerButtonClick} >
                            <img src={icoQuestion} />
                        </button>
                    </Tooltip>)}
                </FormLabel>
            )}

            <Autocomplete
                disablePortal
                options={optionsWithDefault}
                value={selectedOption}
                clearText=""
                openText=""
                onChange={(event: any, newValue: any, reason: AutocompleteChangeReason, details: any) => {
                    if (reason === 'clear') return;
                    lastValue = newValue?.id ?? null
                    props.onChange(event, lastValue);
                }}
                onInputChange={(event: any, newValue: any, reason: AutocompleteInputChangeReason) => {
                    if (reason === 'clear') {
                        lastValue = defaultOption.id
                        props.onChange(event, lastValue);
                        return
                    } else if (reason === 'input') {
                        if (newValue === '') {
                            lastValue = null
                        }
                    }
                }}
                onClose={(event: any, reason: AutocompleteCloseReason) => {
                    if (!lastValue) {
                        props.onChange(event, defaultOption.id);
                    }
                }}
                disabled={props.disabled}
                getOptionLabel={(option: any) => {
                    return option.description
                }}
                // isOptionEqualToValue={isOptionEqualToValue}
                renderInput={(params) => <TextField {...params} label="" />
                }
                renderOption={(props, option) => {
                    return (
                        <li {...props} key={option.id}>
                            {option?.description}
                        </li>
                    );
                }}
                popupIcon={(<KeyboardArrowDown />)}
                noOptionsText="No hay opciones disponibles"
            />
        </FormControl>
    );
};

export const SelectInput = (props: any) => {
    var [showToolTip, setShowToolTip] = useState(false);
    const handlerButtonClick = (e: any) => {
        setShowToolTip(!showToolTip);

        //e.target.open=true;
    }
    return (
        <FormControl>
            {props.title !== undefined && (
                <FormLabel>{props.title} {(props.required === undefined || props.required === true) && <span style={obligatorioStyle}>*</span>}
                    {props.tooltip !== undefined && (<Tooltip sx={{ borderColor: '#0089B5' }} open={showToolTip} disableInteractive={true} title={
                        <Box
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                                maxWidth: 250,
                                justifyContent: 'center',
                                p: 1
                            }}
                        >
                            {props.tooltip}
                        </Box>}
                        variant="outlined"
                        placement="top-start"
                        size="sm">
                        <button className="circle-btn-tooltip" onClick={handlerButtonClick} >
                            <img src={icoQuestion} />
                        </button>
                    </Tooltip>)}
                </FormLabel>
            )}

            <Select
                name={props.name}
                className={"select-input-card"}
                disabled={props.disabled}
                onChange={(event: any, newValue: any) => {
                    props.onChange(event, event.target.value);
                }}
                value={props.value}
                IconComponent={props => (<KeyboardArrowDown {...props} className={`material-icons ${props.className}`} />)}

            >
                <MenuItem value={0}>Seleccione</MenuItem>
                {props.options.map((data: any, index: any) => (
                    <MenuItem value={data.id} key={index}>{data.description}</MenuItem>
                ))}
            </Select>
        </FormControl>
    );
};

export const SimpleInput = (props: any) => {
    return (
        <FormControl>
            {props.title !== undefined && (
                <FormLabel>{props.title} {(props.required === undefined || props.required === true) && <span style={obligatorioStyle}>*</span>} </FormLabel>
            )}
            <Input
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={props.onChange}
                disabled={props.disabled}
                startDecorator={props.startDecorator}
            />
        </FormControl>
    );
};

export const TextInput = (props: any) => {
    return (
        <FormControl>
            {props.title !== undefined && <FormLabel>{props.title}<span style={obligatorioStyle}>*</span></FormLabel>}
            <Input
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                sx={{
                    '--Input-radius': '16px',
                }}
                onChange={(event: any) => {
                    const newValue = event.target.value;
                    const validation = /^[a-zA-ZáéíóúÁÉÍÓñÑ\s]*$/.test(newValue);

                    if (!validation) {
                        event.preventDefault();
                        return;
                    }

                    props.onChange(event);
                }}
                disabled={props.disabled}
                startDecorator={props.startDecorator}
            />
        </FormControl>
    );
};

export const NumberInput = (props: any) => {
    return (
        <FormControl>
            {props.title !== undefined && (
                <FormLabel>{props.title} {(props.required === undefined || props.required === true) && <span style={obligatorioStyle}>*</span>} </FormLabel>
            )}
            <Input
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(event: any) => {

                    const newValue = event.target.value;
                    const validation = /^\d*$/.test(newValue);

                    if (props.longNumber !== undefined) {
                        if (props.longNumber < newValue.length) {
                            event.preventDefault();
                            return;
                        }
                    }

                    if (!validation) {
                        event.preventDefault();
                        return;
                    }

                    props.onChange(event);

                }}
                disabled={props.disabled}
                startDecorator={props.startDecorator}
            />
        </FormControl>
    );
};

export const NumberDecimalInput = (props: any) => {
    return (
        <FormControl>
            {props.title !== undefined && <FormLabel>{props.title}<span style={obligatorioStyle}>*</span></FormLabel>}
            <Input
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(event: any) => {
                    const value = event.target.value;
                    let numberOfDecimalsDefault = 2;
                    if (props.numberOfDecimals) {
                        numberOfDecimalsDefault = props.numberOfDecimals
                    }
                    const regex = new RegExp(`^\\d*\\.?\\d{0,${numberOfDecimalsDefault}}$`);
                    const validation = regex.test(value);

                    if (props.longNumber !== undefined) {
                        if (props.longNumber < value.length) {
                            event.preventDefault();
                            return;
                        }
                    }

                    if (!validation) {
                        event.preventDefault();
                        return;
                    }

                    props.onChange(event);
                }}
                disabled={props.disabled}
                startDecorator={props.startDecorator}
            />
        </FormControl>
    );
};

export const MoneyInput = (props: any) => {

    return (
        <FormControl>
            {props.title !== undefined && (
                <FormLabel>{props.title} {(props.required === undefined || props.required === true) && <span style={obligatorioStyle}>*</span>} </FormLabel>
            )}
            <Input
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(event: any) => {
                    const newValue = event.target.value.replace(/,/g, '');

                    const validation = /^\d*\.?\d{0,2}$/.test(newValue);

                    if (!validation) {
                        event.preventDefault();
                        return;
                    }

                    event.target.value = GeneralHelper.AgregarComasAMiles(newValue);
                    props.onChange(event);
                }}
                disabled={props.disabled}
                startDecorator={props.startDecorator}
                sx={{
                    backgroundColor: props.disabled ? 'transparent' : 'white',
                }}
            />
        </FormControl>
    );
};

export const PercentageInput = (props: any) => {

    return (
        <FormControl>
            {props.title !== undefined && (
                <FormLabel>{props.title} {(props.required === undefined || props.required === true) && <span style={obligatorioStyle}>*</span>} </FormLabel>
            )}
            <Input
                name={props.name}
                placeholder={props.placeholder}
                value={props.value}
                onChange={(event: any) => {
                    const newValue = event.target.value.replace(/,/g, '');

                    const validation = /^\d*\.?\d{0,2}$/.test(newValue);

                    if (!validation) {
                        event.preventDefault();
                        return;
                    }

                    event.target.value = GeneralHelper.AgregarComasAMiles(newValue);
                    props.onChange(event);
                }}
                disabled={props.disabled}
                startDecorator={props.startDecorator}
                endDecorator={props.endDecorator}
                className="percentage-input"
                sx={{
                    backgroundColor: props.disabled ? 'transparent' : 'white',
                }}
            />
        </FormControl>
    );
};

export const SwitchInput = (props: any) => {
    return (
        <>
            <FormLabel>{props.title}</FormLabel>
            <Switch
                color={props.checked ? "success" : "neutral"}
                checked={props.checked}
                onChange={props.onChange}
                disabled={props.disabled === undefined || props.disabled === null ? false : props.disabled}
                slotProps={{
                    track: {
                        children: (
                            <React.Fragment>
                                <Typography component="span" level="inherit" sx={{ ml: "10px" }}>Si</Typography>
                                <Typography component="span" level="inherit" sx={{ mr: "8px" }}>No</Typography>
                            </React.Fragment>
                        ),
                    }
                }}
                sx={{ "--Switch-thumbSize": "27px", "--Switch-trackWidth": "64px", "--Switch-trackHeight": "31px" }} />
        </>
    );
};

export const DateInput = (props: any) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            {props.title !== undefined && (
                <FormLabel>
                    {props.title} {props.required !== false && <span style={obligatorioStyle}>*</span>}
                </FormLabel>
            )}
            <DatePicker
                format="DD/MM/YYYY"
                value={dayjs(props.value)}
                onChange={props.onChange}
                disabled={props.disabled}
                slotProps={{
                    textField: {
                        fullWidth: true,
                        sx: {
                            '& .MuiInputBase-root': {
                                backgroundColor: props.disabled ? 'transparent' : 'white',
                            }
                        }
                    }
                }}
                className="custom-date-picker"
            />
        </LocalizationProvider>
    );
}