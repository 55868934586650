import { appDefaultValues } from '../../../config/Config';

export interface ResultadoFinanciamiento {
 montoFinanciamiento: number,
 montoCuota: number,
 totalGastos: number,
 tcea: string,
 totalFinanciamiento: number,
 cuotaDoble: number
}

export const ResultadoFinanciamientoDefault: ResultadoFinanciamiento = {
 montoFinanciamiento: appDefaultValues.NumberDefault,
 montoCuota: appDefaultValues.NumberDefault,
 totalGastos: appDefaultValues.NumberDefault,
 tcea: appDefaultValues.StringEmpty,
 totalFinanciamiento: appDefaultValues.NumberDefault,
 cuotaDoble: appDefaultValues.NumberDefault
}