import IconNotes from '../../../../../assets/media/icons/ico_notes.svg';

const DocumentationNote = (props: any) => {
    return (
        <div className='documentation-note shadow'>
            <img src={IconNotes} alt='notes' />
            <div>
                {props.children}
            </div>
        </div>
    );
};

export default DocumentationNote;