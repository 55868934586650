import { FiadorInterface } from "../interfaces/fiadorInterface";

class FiadoresHelper {

    static TransformarModeloFiadores(tempFiadores: []): Array<FiadorInterface> {

        const fiadores: Array<FiadorInterface> = [];

        tempFiadores.map((fiador: any) => {
            fiadores.push({
                SolicitudPersonaFiadorId: Number(fiador.idenT_SOLICITUD_PERSONA_FIADORES),
                PersonaId: Number(fiador.idenT_PERSONA),
                ConyugeId: Number(fiador.idenT_PERSONA_CONYUGUE),
                ConyugeTiene: Boolean(fiador.inD_CONYUGUE),
                SolicitudId: Number(fiador.idenT_SOLICITUD)
            })
        });

        return fiadores;
    }

    static handlerBtnOtrosIngresosTitular(viewOne: any, setViewOne: any) {
        const tempListaOtrosTrabajos = viewOne.titularDatosLaboral.listaOtrosTrabajos;
        tempListaOtrosTrabajos.push({});
        let titularDatosLaboral = viewOne.titularDatosLaboral;
        titularDatosLaboral = { ...titularDatosLaboral, listaOtrosTrabajos: tempListaOtrosTrabajos };
        setViewOne({ ...viewOne, titularDatosLaboral: titularDatosLaboral });
    }

    static handlerBtnOtrosConyugeIngresosTitular(viewOne: any, setViewOne: any) {
        const tempListaOtrosTrabajos = viewOne.titularConyugueDatosLaboral.listaOtrosTrabajos;
        tempListaOtrosTrabajos.push({});
        let tempTitularConyugueDatosLaboral = viewOne.titularConyugueDatosLaboral;
        tempTitularConyugueDatosLaboral = { ...tempTitularConyugueDatosLaboral, listaOtrosTrabajos: tempListaOtrosTrabajos };
        setViewOne({ ...viewOne, titularConyugueDatosLaboral: tempTitularConyugueDatosLaboral });
    }

    static handlerBtnOtrosFiadores(fiadores: any, setFiadores: any) {
        const tempListaFiadores = fiadores.ListaFiadores;
        tempListaFiadores.push({});
        setFiadores({ ...fiadores, ListaFiadores: tempListaFiadores });
    }

}

export default FiadoresHelper;