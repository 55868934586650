import React from "react";
import { useEffect } from "react";
import { appConfigKey } from "../../../../../config/Config";
import GeneralHelper from "../../../../helpers/GeneralHelper";

export const CardAlerts = (props: any) => {
    const { resultadoFinancimaiento, solicitud } = props;
    return (
        <>
            {resultadoFinancimaiento.totalFinanciamiento > solicitud.montoTotalFinanciamiento && <div className="border rounded-10 p-3 p-md-2 mb-4 mb-md-4 card body-alert-warning">
                <div className="row g-2 mb-2 item-body-details">
                    <div className="col-lg-12 col-md-12 text-center">
                        <span className="text-negrita">{appConfigKey.KeyMensajeMontoMaximoTotalFinanciamiento.replace('{data}', GeneralHelper.NumeroConCommas(solicitud.montoTotalFinanciamiento))}</span>
                    </div>

                </div>
            </div>}
        </>
    );
};

export default CardAlerts;