import { Switch, Table, Typography, Button } from "@mui/joy";
import { Dialog } from "@mui/material";
import React, { useState } from "react";
import ArrowLeft from '../../../../../assets/media/icons/ico_arrowleft.svg';
import ArrowRight from '../../../../../assets/media/icons/ico_arrowright.svg';
import { TextInput } from "./TextInput";

const DocumentationAddDescription = (props: any) => {
    const [searchText, setSearchText] = React.useState<string | null>(null);
    
    const onCloseClick = () => {
        setSearchText(null);
        props.handleClose();
    }
    
    const cambiarTexto = (event: any) => {
        let text = event.target.value;
        setSearchText(text);
    }

    const onAgregarClick = () => {
        props.handleAgregar(searchText);
        setSearchText(null);
    }

    return (
        <Dialog open={props.open} maxWidth={'sm'}>
            <div className="content-documentation-add-dialog">
                <div className="content-documentation-add-dialog-title">
                    <h2>DOCUMENTOS ADICIONALES</h2>
                </div>
                <div className="content-documentation-add-description-body">
                    <Table borderAxis="none" className="style-table" stripe={'odd'}>
                        <tbody>
                        <label>
                            Nombre del Documento
                        </label>
                        <TextInput
                            placeholder="Ingresar nombre"
                            onChange={cambiarTexto}
                        ></TextInput>
                        </tbody>
                    </Table>
                </div>
                <div className="content-documentation-add-dialog-footer">
                    <div className="content-documentation-add-dialog-footer-close cursor-pointer" onClick={onCloseClick}> 
                        <img src={ArrowLeft} alt='arrow-left' /> <div>Cancelar</div>
                    </div>
                    <div className="content-documentation-add-dialog-footer-add cursor-pointer" onClick={onAgregarClick}>
                        <div>Agregar</div> <img src={ArrowRight} alt='arrow-right' />
                    </div>
                </div>
            </div>
        </Dialog>
    );

};

export default DocumentationAddDescription;