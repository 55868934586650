import { useContext, useEffect, useState } from "react";
import { DateInput, NumberInput, SelectAutocompleteInput, SelectInput, SimpleInput, TextInput } from "../elements/inputs";
import TitleCard from "../elements/titleCard";
import { appConfigKey } from "../../../../../../config/Config";
import { StepOneContext, StepsContext } from "../../../3.10.-base/providers";

const DatosPersonalesCompraParaConyugeCard = () => {

    const {
        listTipoDocumento,
        listIdentidadesGenero,
        listMagnitud,
        listActividadEconomica,
        clickNext,
        step,
        messageCustomerIsBlackListGesintel,
        readonlyForm
    } = useContext(StepsContext);

    const { personaCompraParaConyugeData, setNewPersonaCompraParaConyugeData } = useContext(StepOneContext);

    const [viewModel, setViewModel] = useState({
        TipoDocumentoId: 0,
        NumeroDocumento: "",
        Nombres: "",
        ApellidoPaterno: "",
        ApellidoMaterno: "",
        FechaNacimiento: "",
        GeneroId: 0,
        MagnitudId: 0,
        ActividadEconomicaId: 0
    });

    useEffect(() => {
        if (personaCompraParaConyugeData !== 0) {
            setViewModel(personaCompraParaConyugeData);
        }
    }, [personaCompraParaConyugeData]);

    useEffect(() => {
        if (clickNext !== 0 && step === 1) {
            setNewPersonaCompraParaConyugeData(viewModel);
        }
    }, [clickNext]);

    const obtenerListaTipoDocumentos = () => {
        const nuevaListaTipoDocumento = listTipoDocumento.filter((x: any) => x.id !== appConfigKey.keyIdRUC);
        return nuevaListaTipoDocumento;
    }

    return (
        <>
            <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
                <TitleCard title={"DATOS PERSONALES COMPRA PARA CÓNYUGUE"} />

                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-2 cardObjets">
                        <SelectInput
                            title={"Tipo de doc. cónyugue"}
                            value={viewModel.TipoDocumentoId}
                            options={obtenerListaTipoDocumentos()}
                            disabled={readonlyForm}
                            onChange={(event: any, newValue: any) => {
                                //myowasp(newValue);
                                setViewModel({ ...viewModel, TipoDocumentoId: newValue })
                            }}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        {viewModel.TipoDocumentoId === appConfigKey.keyCodigoDNI ? (
                            <NumberInput
                                title={"Número de documento"}
                                placeholder="Ej: 00000000"
                                longNumber={8}
                                value={viewModel.NumeroDocumento.trim()}
                                disabled={readonlyForm}
                                onChange={(event: any) => setViewModel({ ...viewModel, NumeroDocumento: event.target.value })}
                            />
                        ) : (
                            <NumberInput
                                title={"Número de documento"}
                                placeholder="Ej: 00000000"
                                value={viewModel.NumeroDocumento}
                                disabled={readonlyForm}
                                onChange={(event: any) => setViewModel({ ...viewModel, NumeroDocumento: event.target.value })}
                            />
                        )}
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <TextInput
                            title={"Nombres"}
                            placeholder="Indique los nombres"
                            value={viewModel.Nombres}
                            disabled={readonlyForm}
                            onChange={(event: any) => setViewModel({ ...viewModel, Nombres: event.target.value })}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <TextInput
                            title={"Apellido Paterno"}
                            placeholder={"Indique el Apellido Paterno"}
                            value={viewModel.ApellidoPaterno}
                            disabled={readonlyForm}
                            onChange={(event: any) => setViewModel({ ...viewModel, ApellidoPaterno: event.target.value })}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <TextInput
                            title={"Apellido Materno"}
                            placeholder="Indique el Apellido Materno"
                            value={viewModel.ApellidoMaterno}
                            disabled={readonlyForm}
                            onChange={(event: any) => setViewModel({ ...viewModel, ApellidoMaterno: event.target.value })}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <DateInput
                            title={"Fecha de Nacimiento"}
                            value={viewModel.FechaNacimiento}
                            disabled={readonlyForm}
                            onChange={(date: any | null) => setViewModel({ ...viewModel, FechaNacimiento: date === null ? '' : date.format("YYYY-MM-DD") })}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <SelectInput
                            title={"Género"}
                            value={viewModel.GeneroId}
                            options={listIdentidadesGenero}
                            disabled={readonlyForm}
                            onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, GeneroId: newValue })}

                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SelectInput
                            title={"Magnitud"}
                            value={viewModel.MagnitudId}
                            options={listMagnitud}
                            disabled={readonlyForm}
                            onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, MagnitudId: newValue })}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SelectAutocompleteInput
                            title={"Actividad económica"}
                            value={viewModel.ActividadEconomicaId}
                            options={listActividadEconomica}
                            disabled={readonlyForm}
                            onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, ActividadEconomicaId: newValue })}
                        />
                    </div>
                </div>
            </div>
        </>
    );
};

export default DatosPersonalesCompraParaConyugeCard;