
export const listaSegundoNivelTipoResultadoAprobado = [
  {
    key: 1,
    label: "Sin Observaciones",
    value: 71,
  },
  {
    key: 2,
    label: "Con VB de SubGerencia",
    value: 72,
  },
  {
    key: 3,
    label: "Con VB de Gerencia",
    value: 73,
  },
];

export const keyCreditosConsumoNoRevolvente = 3037;
export const keyDeudorMinorista = 3042;
