import { Button } from '@mui/joy';
import IconUploadBlack from '../../../../../assets/media/icons/ico_upload_black.svg';

const DocumentationAddFile = (props: any) => {

    return (
        <Button variant="outlined" color="neutral" onClick={props.handlerOnClick}
            disabled={!props.disabled ? false : props.disabled} >
            Agregar Nuevo <img style={{ paddingLeft: '5px' }} src={IconUploadBlack} />
        </Button>
    );

}

export default DocumentationAddFile;