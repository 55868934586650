import { useContext } from "react";
import { CompraParaContext } from "../contexts";
import { appConfigKey } from "../../../../../config/Config";
import DatosPersonalesCompraParaConyugeCard from "../components/DatosPersonalesCompraParaConyugeCard";
import DatosPersonalesCompraParaCard from "../components/DatosPersonalesCompraParaCard";

export const CompraParaSection = () => {

    const { compraParaEstadoCivilId, tipoDocumentoId } = useContext(CompraParaContext);

    return (
        <>
            <DatosPersonalesCompraParaCard />
            {
                ((compraParaEstadoCivilId == appConfigKey.keyCodigoEstadoCivilCasado ||
                  compraParaEstadoCivilId == appConfigKey.keyIdEstadoCivilConviviente
                ) &&
                    tipoDocumentoId !== appConfigKey.keyIdRUC
                ) && (
                    <DatosPersonalesCompraParaConyugeCard />
                )}
        </>
    );
};