import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormLabel from "@mui/joy/FormLabel";
import GeneralHelper from "../../../../helpers/GeneralHelper";

const CardResult = (props: any) => {
  const { result } = props;

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <div className="row g-2 align-items-center">
        <div className="col-lg-10 textLabelHeadboard">
          <span className="fw-bold">RESULTADOS</span>
        </div>
        <div className="row g-2 align-items-center">
          <div className="row g-2 mb-2 align-items-center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>Monto a financiar</FormLabel>
              <span>{result.montoFinanciamiento ? GeneralHelper.NumeroConCommas(result.montoFinanciamiento.toFixed(2)) : 'N/D'}</span>
            </LocalizationProvider>
          </div>
          <div className="row g-2 mb-2 align-items-center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>Monto cuota</FormLabel>
              <span>{result.montoCuota ? GeneralHelper.NumeroConCommas(result.montoCuota.toFixed(2)) : 'N/D'}</span>
            </LocalizationProvider>
          </div>
          <div className="row g-2 mb-2 align-items-center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>Total gastos</FormLabel>
              <span>{result.totalGastos ? GeneralHelper.NumeroConCommas(result.totalGastos.toFixed(2)) : 'N/D'}</span>
            </LocalizationProvider>
          </div>
          <div className="row g-2 mb-2 align-items-center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>TCEA</FormLabel>
              <span>{result.tcea}</span>

            </LocalizationProvider>
          </div>
          <div className="row g-2 mb-2 align-items-center">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>Total financiamiento</FormLabel>
              <span>{result.totalFinanciamiento ? GeneralHelper.NumeroConCommas(result.totalFinanciamiento.toFixed(2)) : 'N/D'}</span>
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardResult;
