import { appDefaultValues } from "../../../../../../config/Config";

export interface DatosAdicionalesTitularEmpresaInterface {
    codSBS: string,
    capitalSocialActual: number,
    patrimonio: number,
    patrimonioPersonal: number,
    sectorEconomico: string,
    tipoCadenaProductiva: string,
    actividadComplementaria: string | null,
}

export const DatosAdicionalesTitularEmpresaInterfaceDefault: DatosAdicionalesTitularEmpresaInterface = {

    codSBS: appDefaultValues.StringEmpty,
    capitalSocialActual: appDefaultValues.NumberDefault,
    patrimonio: appDefaultValues.NumberDefault,
    patrimonioPersonal: appDefaultValues.NumberDefault,
    sectorEconomico: appDefaultValues.StringEmpty,
    tipoCadenaProductiva: appDefaultValues.StringEmpty,
    actividadComplementaria: appDefaultValues.StringEmpty,
};