import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import { Table, Sheet } from "@mui/joy";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React from 'react';

export const ScoringInformacionEntidadesSBS = ({ clasificacion, informacionEntidad, tipoIntegranteClasif }: any) => {

  const [newInformacionEntidades, setNewInformacionEntidades] = React.useState(
    {
      periodo: ['CLASIFICACIÓN'],
      nro_reportantes: ['N. Acreedores'],
      nro_reportantes_c_deuda: ['N. Acreedores con deuda'],
      porc_deuda_normal: ['Normal'],
      porc_deuda_cpp: ['CPP'],
      porc_deuda_def: ['Deficiente'],
      porc_deuda_dud: ['Dudoso'],
      porc_deuda_per: ['Pérdida'],
    }
  );

  const [newInformacionEntidadesResumen, setNewInformacionEntidadesResumen] = React.useState(
    {
      resumen: [],
    }
  );

  const getPivotInformacionFinanciera = async () => {
    const newArray: any = { ...newInformacionEntidades };
    if (informacionEntidad?.reporte !== undefined && informacionEntidad?.reporte?.length > 0) {
    //if (reporte !== undefined && reporte?.length > 0) {
      informacionEntidad.reporte.map((item: any, index: number) => {
        newArray.periodo[index + 1] = item.periodo;
        newArray.nro_reportantes[index + 1] = item.nro_reportantes;
        newArray.nro_reportantes_c_deuda[index + 1] = item.nro_reportantes_c_deuda;
        newArray.porc_deuda_normal[index + 1] = item.porc_deuda_normal;
        newArray.porc_deuda_cpp[index + 1] = item.porc_deuda_cpp;
        newArray.porc_deuda_def[index + 1] = item.porc_deuda_def;
        newArray.porc_deuda_dud[index + 1] = item.porc_deuda_dud;
        newArray.porc_deuda_per[index + 1] = item.porc_deuda_per;
      })
    }
    setNewInformacionEntidades(newArray);
  }

  const getPivotTotales = async () => {
    const newArrayResumen: any = { ...newInformacionEntidadesResumen };
    if (informacionEntidad !== undefined && informacionEntidad?.reporteResumen !== undefined && informacionEntidad?.reporteResumen?.length > 0) {
    //if (reporteResumen !== undefined && reporteResumen?.length > 0) {
      const meses: string[] = ['ENE', 'FEB', 'MAR', 'ABR', 'MAY', 'JUN', 'JUL', 'AGO', 'SET', 'OCT', 'NOV', 'DIC']
      let newObje: any = { entidad: 'ENTIDAD' }
      let newNombre = '';
      
      informacionEntidad.reporteResumen.map((item: any, index: number) => {
      //reporteResumen.map((item: any, index: number) => {
        if (index === 0 || item.nombre.toString().toUpperCase() === newNombre) {
          newNombre = item.nombre.toString().toUpperCase();
          if (index === 0) newObje.entidad = item.nombre;
          newObje[item.periodo] = item.deuda;
        } else {
          newArrayResumen.resumen.push(newObje);
          newObje = { entidad: item.nombre.toString().toUpperCase() };
          newObje[item.periodo] = item.deuda;
          newNombre = item.nombre.toString().toUpperCase();
        }

        if (index === informacionEntidad.reporteResumen.length - 1) {
        //if (index === reporteResumen.length - 1) {
          newArrayResumen.resumen.push(newObje);

          newObje = { entidad: 'ENTIDAD' };
          for (const propiedad in newArrayResumen.resumen[0]) {
            if (propiedad.toString().toUpperCase() !== 'ENTIDAD') newObje[propiedad] = meses[parseInt(propiedad.toString().substr(4, 2)) - 1] + ' ' + propiedad.toString().substr(0, 4);
          }
          newArrayResumen.resumen.unshift(newObje)
        }

      });
    }
    setNewInformacionEntidadesResumen(newArrayResumen);
  }

  const construyentoPivot = async () => {
    await getPivotInformacionFinanciera();
    await getPivotTotales();
  }

  React.useEffect(() => {
    construyentoPivot();
  }, [informacionEntidad?.reporte])

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>INFORMACIÓN ENTIDADES SBS ({tipoIntegranteClasif})</AccordionSummary>
          <AccordionDetails>
            <>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-3 cardObjets">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormLabel>Entidades deuda directa</FormLabel>
                    <span>{clasificacion.nro_entidades_rep_d_dir}</span>
                  </LocalizationProvider>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>Entidades deuda indirecta</FormLabel>
                    <span>{clasificacion.nro_entidades_rep_d_indir}</span>
                  </FormControl>
                </div>
                <div className="col-lg-3 cardObjets">
                  <FormControl>
                    <FormLabel>Entidades con deuda</FormLabel>
                    <span>{clasificacion.nro_entidades_rep_c_deuda}</span>
                  </FormControl>
                </div>
                <div className="col-lg-3 cardObjets">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormLabel>Entidades sin deuda</FormLabel>
                    <span>{clasificacion.nro_entidades_rep_s_deuda}</span>
                  </LocalizationProvider>
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-3 cardObjets">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <FormLabel>N. entidades reportan</FormLabel>
                    <span>{clasificacion.nro_entidades_rep}</span>
                  </LocalizationProvider>
                </div>
              </div>

              <div className="row g-2 mb-2 align-items-center borderOtherIncome">
                <div className="col-lg-4 textLabelHeadboard">
                  <span className="fw-bold">INFORMACIÓN FINANCIERA</span>
                </div>
                <div className="col-lg-4">
                  <span className="fw-bold"> </span>
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-12 cardObjets align-top">
                  {newInformacionEntidades.periodo.length > 0 &&
                  <Sheet sx={{ overflow: 'auto' }}>
                    <Table borderAxis="none" className="style-table align-top" size={'md'} sx={{ '& thead tr:first-child': { borderTopLeftRadius: '10px', borderBottomLeftRadius: "10px" } }} >
                      <thead>
                        <tr key={0}>
                          {
                            newInformacionEntidades.periodo.map((col: any, index: number) => {
                              return (
                                <th key={index} style={{ width: "170px", backgroundColor: "#E1E1E1" }}>{col}</th>
                              )
                            })
                          }
                        </tr>
                      </thead>
                      <tbody>
                        <tr key={1}>{newInformacionEntidades.nro_reportantes.map((row: any, index: number) => { return (<td key={index}> {row}</td>) })}</tr>
                        <tr key={2}>{newInformacionEntidades.nro_reportantes_c_deuda.map((row: any, index: number) => { return (<td key={index} style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row}</td>) })}</tr>
                        <tr key={3}>{newInformacionEntidades.porc_deuda_normal.map((row: any, index: number) => { return (<td key={index} style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row} {'%'}</td>) })}</tr>
                        <tr key={4}>{newInformacionEntidades.porc_deuda_cpp.map((row: any, index: number) => { return (<td key={index} style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row} {'%'}</td>) })}</tr>
                        <tr key={5}>{newInformacionEntidades.porc_deuda_def.map((row: any, index: number) => { return (<td key={index} style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row} {'%'}</td>) })}</tr>
                        <tr key={6}>{newInformacionEntidades.porc_deuda_dud.map((row: any, index: number) => { return (<td key={index} style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row} {'%'}</td>) })}</tr>
                        <tr key={7}>{newInformacionEntidades.porc_deuda_per.map((row: any, index: number) => { return (<td key={index} style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row} {'%'}</td>) })}</tr>
                      </tbody>
                    </Table>
                  </Sheet>
                  }
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-12 cardObjets align-top">
                  {newInformacionEntidadesResumen?.resumen?.length > 0 &&
                    <Sheet sx={{ overflow: 'auto' }}>
                      <Table borderAxis="none" className="style-table align-top" size={'md'} sx={{ '& thead tr:first-child': { borderTopLeftRadius: '10px', borderBottomLeftRadius: "10px" } }} >
                        <thead>
                          <tr>
                            {
                              Object.keys(newInformacionEntidadesResumen.resumen[0]).reverse().map((col: any, index: number) => {
                                return (
                                  <th style={{ width: "170px", backgroundColor: "#E1E1E1" }}>{newInformacionEntidadesResumen.resumen[0][col]}</th>
                                )
                              })
                            }
                          </tr>
                        </thead>
                        <tbody>
                          {
                            newInformacionEntidadesResumen.resumen.map((row: any, index: number) => {
                              //if (index > 0)
                                return ( index > 0 &&
                                    <tr key={index}>
                                    {
                                      Object.keys(row).reverse().map((item: any, index: number) => {
                                        return (
                                          <th style={{ width: "170px", backgroundColor: "#E1E1E1" }}>{row[item]}</th>
                                        )
                                      })
                                    }
                                  </tr>
                                )
                            })
                          }
                        </tbody>
                      </Table>
                    </Sheet>
                  }
                </div>
              </div>
            </>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
    </div>
  )
}

export default ScoringInformacionEntidadesSBS;