import { toast } from "sonner";
import { appConfigKey, appDefaultValues } from "../../../../config/Config";
import GeneralHelper from "../../../helpers/GeneralHelper";
import JsonHelper from "../../../helpers/JsonHelper";
import TimeHelper from "../../../helpers/TimeHelper";
import { TMnSolicitudCronogramaRequest } from "../../../models/MasterManager/solicitud-cronograma-request.model";
import { MnIntegracionSolicitudRequest } from "../../../models/RequestManager/integracion-solicitud-request.model";
import { BTObtenerPersonaNaturalRequest } from "../../../models/ServicesProviderManager/obtener-persona-natural-request.model";
import { BTAltaClienteReducidaRequest, BTCrearClienteRequest, BTCrearPersonaFisicaRequest } from "../../../models/bantotal";
import { BlackListItem } from "../../../models/observed-member.model";
import MasterManager from "../../../services/origination/wapimaster.service";
import RequestManager from "../../../services/origination/wapirequest.service";
import ServicesProvider from "../../../services/support/wapiservicesprovider";
import GesintelHelper from "../../1.-shared/1.5.-observed/1.5.9.-helpers/GesintelHelper";
import ListaNegraHelper from "../../1.-shared/1.5.-observed/1.5.9.-helpers/ListaNegraHelper";
import { TMnSolicitudPersonaPorValidarRequest } from "../../../models/MasterManager/solicitud-persona-observada-request.model";
import { BTObtenerDatosPartnerVendedorRequest } from "../../../models/bantotal/BTObtenerDatosPartnerVendedor/BTObtenerDatosPartnerVendedorRequest";
import { TMnSolicitudAsociadoInsertUpdateRequest, TMnSolicitudAsociadoRequest } from "../../../models/MasterManager/solicitud-asociado-request.model";

function tieneContent(content: any) {
  return content !== undefined && content !== null;
}

function numeroConCommas(numero: string): string {
  return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function parseDateString(dateString: string): String {
  let dateParts = dateString.split("-");
  let year = dateParts[0];
  let month = dateParts[1];
  let day = dateParts[2];
  let formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

export const ObtenerListas = {

  TipoDocumento: async () => {
    const lista: any = [];

    try {

      const response = await ServicesProvider.ObtenerTipoDocumento(null);

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtTipoDocumentos.sdtsBTTipoDocumento.forEach((element: any) => {
          lista.push({ id: element.identificador, description: element.descripcion });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER TIPOS DE DOCUMENTOS.');
    }

    return lista;
  },

  EstadoCiviles: async () => {
    const lista: any = [];

    try {

      const response = await ServicesProvider.ObtenerEstadoCiviles(null);

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtEstadoCiviles.sBTEstadoCivil.forEach((element: any) => {
          lista.push({ id: Number(element.identificador), description: element.descripcion });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER ESTADOS CIVILES.');
    }

    return lista;
  },

  IdentidadesGenero: async () => {
    const lista: any = [];

    try {

      const response = await ServicesProvider.ObtenerIdentidadesGenero(null);

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtIdentidadesDeGenero.sBTIdentidadDeGenero.forEach((element: any) => {
          lista.push({ id: element.codigo, description: element.descripcion });
        });
      }

    } catch (error) {
      console.warn("OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER GENEROS.");
    }

    return lista;
  },

  IdentidadesMagnitud: async () => {
    const lista: any = [];

    try {

      const response = await ServicesProvider.ObtenerIdentidadesMagnitud({ codigo: appConfigKey.keyCodeObtenerGuiaEspecialDeProceso });

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtGuiaEspecialDeProceso.sdtsBTCorrelativoGuiaEspecial.forEach((element: any) => {
          lista.push({ id: element.valorEspecifico1, description: element.descripcion });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER MAGNITUDES.');
    }

    return lista;
  },

  ActividadEconomica: async () => {
    const lista: any = [];

    try {

      const response = await ServicesProvider.ObtenerIdentidadesActividadEconomica(null);

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtActividades.sBTActividad.forEach((element: any) => {
          lista.push({ id: element.identificador, description: element.descripcion });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR PARA OBTENER ACTIVIDADES ECONOMICAS.');
    }

    return lista;
  },

  CategoriaLaboral: async () => {
    const lista: any = [];

    try {

      const response = await ServicesProvider.ObtenerCategoriaLaboral(null);

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtOcupaciones.sBTOcupacion.forEach((element: any) => {
          lista.push({ id: element.identificador, description: element.descripcion });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR EN EL SERVICIO AL OBTENER CATEGORIAS LABORALES');
    }

    return lista;
  },

  Moneda: async () => {
    const lista: any = [];

    try {

      const response = await ServicesProvider.ObtenerMonedas(null);

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtMonedas.sBTMoneda.forEach((element: any) => {
          lista.push({ id: element.identificador, description: element.descripcion });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR EN EL SERVICIO PARA OBTENER TIPOS DE DIVISAS.');
    }

    return lista;
  },

  Profesion: async () => {
    const lista: any = [];

    try {

      const response = await ServicesProvider.ObtenerProfesiones(null);

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtProfesiones.sBTProfesion.forEach((element: any) => {
          lista.push({ id: element.identificador, description: element.descripcion });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER PROFESIONES.');
    }

    return lista;
  },

  Pais: async () => {
    const lista: any = [];

    try {

      const response = await ServicesProvider.ObtenerPaises(null);

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtPaises.sBTPais.forEach((element: any) => {
          lista.push({ id: element.identificador, description: element.descripcion });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER PAISES.');
    }

    return lista;
  },

  MagnitudPN: async (data: any) => {
    const lista: any = [];

    try {

      const response = await MasterManager.GetMnTablaGenericaDefinicion({ "v_LIST_IDENT_TABLA": `${data}` });

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.forEach((element: any) => {
          lista.push({ id: element.genericA_VALO_CAMPO, description: element.genericA_DESCM_CAMPO });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER REGISTROS GENERICOS.');
    }

    return lista;
  },

  Generica: async (data: any) => {
    const lista: any = [];

    try {

      const response = await MasterManager.GetMnTablaGenericaDefinicion({ "v_LIST_IDENT_TABLA": `${data}` });

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.forEach((element: any) => {
          lista.push({ id: element.genericA_IDENT_CAMPO, description: element.genericA_DESCM_CAMPO });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER REGISTROS GENERICOS.');
    }

    return lista;
  },

  NivelesEducativos: async () => {
    const lista: any = [];

    try {
      const response = await ServicesProvider.ObtenerNivelesEducativos(null);

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtNivelesEducativos.sBTNivelEducativo.forEach((element: any) => {
          lista.push({ id: element.codigo, description: element.descripcion });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER NIVELES EDUCATIVOS.');
    }

    return lista;
  },

  EstadosVehiculo: async () => {
    const lista: any = [];

    try {

      const response = await ServicesProvider.ObtenerEstadosVehiculo({});

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtEstados.sBTParametroVehiculo.forEach((element: any) => {
          lista.push({ id: element.codigo, description: element.descripcion });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER ESTADOS DEL VEHICULO');
    }

    return lista;

  },

  AniosVehiculo: () => {

    let anioActual: number = new Date().getFullYear();
    let rangoAnios = appConfigKey.keyRangoAnios;
    let aniosAgregados = appConfigKey.keyAniosAgregados;
    let listAnioVehiculo: any = [{ id: (anioActual + aniosAgregados), label: (aniosAgregados + anioActual).toString() }];

    for (let i = 1; i <= rangoAnios + aniosAgregados; i++) {
      listAnioVehiculo.push({ id: ((anioActual + aniosAgregados) - i), description: ((anioActual + aniosAgregados) - i).toString() });
    }

    return listAnioVehiculo;

  },

  Concesionarios: async () => {

    const lista: any = [];

    try {

      const response = await ServicesProvider.ObtenerConcesionarios({});

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtPartners.sBTPartner.forEach((element: any) => {
          lista.push({ id: element.partnerUId, description: element.nombre });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER CONCESIONARIOS.');
    }

    return lista;

  },

  Sucursales: async (partnerUId: number) => {

    const lista: any = [];

    try {

      const response = await ServicesProvider.ObtenerSucursal({
        "sdtPartner": {
          "puntoVentaUId": 0,
          "vendedorUId": 0,
          "partnerUId": partnerUId
        }
      });

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtPuntosVenta.sBTPuntoVenta.forEach((element: any) => {
          lista.push({ id: element.puntoVentaUId, description: element.nombre });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER SUCURSALES.');
    }

    return lista;

  },

  Vendedores: async (partnerUId: number, puntoVentaUId: number) => {

    const lista: any = [];

    try {

      const response = await ServicesProvider.ObtenerVendedores({
        "sdtPartner": {
          "puntoVentaUId": puntoVentaUId,
          "vendedorUId": 0,
          "partnerUId": partnerUId
        }
      });

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtVendedores.sBTVendedor.forEach((element: any) => {
          lista.push({ id: element.vendedorUId, description: element.nombre });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER VENDEDORES.');
    }

    return lista;

  },

  UsosVehiculo: async () => {

    const lista: any = [];

    try {
      const response = await ServicesProvider.ObtenerUsos({});

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtUsos.sBTParametroVehiculo.forEach((element: any) => {
          lista.push({ id: element.codigo, description: element.descripcion });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS TIPOS DE USOS DE LOS VEHICULOS.');
    }

    return lista;

  },

  Marcas: async (puntoVentaUId: number, vendedorUId: number, partnerUId: number) => {

    const lista: any = [];

    try {
      const response = await ServicesProvider.ObtenerMarcas({
        "sdtPartner": {
          "puntoVentaUId": puntoVentaUId,
          "vendedorUId": vendedorUId,
          "partnerUId": partnerUId
        }
      });

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtMarcas.sBTMarca.forEach((element: any) => {
          lista.push({ id: element.marcaUId, description: element.nombreMarca });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LAS MARCAS DE VEHICULOS.');
    }

    return lista;

  },

  Modelos: async (marcaUId: number, puntoVentaUId: number, vendedorUId: number, partnerUId: number) => {

    const lista: any = [];

    try {
      const response = await ServicesProvider.ObtenerModelos(marcaUId, {
        "sdtPartner": {
          "puntoVentaUId": puntoVentaUId,
          "vendedorUId": vendedorUId,
          "partnerUId": partnerUId
        }
      });

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtModelos.sBTModelo.forEach((element: any) => {
          lista.push({ id: element.modeloUId, description: element.nombreModelo });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR EN EL SERVICIO PARA OBTENER LOS MODELOS.');
    }

    return lista;

  },

  Version: async (modeloUId: number, puntoVentaUId: number, vendedorUId: number, partnerUId: number) => {

    const lista: any = [];

    try {
      const response = await ServicesProvider.ObtenerVersion(modeloUId, {
        "sdtPartner": {
          "puntoVentaUId": puntoVentaUId,
          "vendedorUId": vendedorUId,
          "partnerUId": partnerUId
        }
      });

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtVersiones.sBTVersion.forEach((element: any) => {
          lista.push({ id: element.versionUId, description: element.nombreVersion });
        });
      }

    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER VERSIONES.');
    }

    return lista;

  },

  DestinoCredito: async () => {
    const lista: any = [];

    try {
      const response: any = await MasterManager.GetMnTablaGenericaDefinicion({
        "v_LIST_IDENT_TABLA": "16"
      });

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.forEach((element: any) => {
          lista.push({ id: element.genericA_VALO_CAMPO, description: element.genericA_DESCM_CAMPO });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS DESTINOS DE CREDITO.');
    }

    return lista;
  },

  TiposDeVivienda: async () => {
    const lista: any = [];

    try {
      const response = await ServicesProvider.ObtenerTiposDeVivienda(null);
      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtViviendas.sBTTipoDeVivienda.forEach((element: any) => {
          lista.push({ id: Number(element.codigo), description: element.descripcion });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS TIPOS DE VIVIENDA.');
    }

    return lista;
  },

  Productos: async (idMoneda: number, body: any, setResponseConsultaProductos: any) => {
    const lista: any = [];

    try {
      const response = await ServicesProvider.ObtenerProductos(body);

      if (response.status !== 200) {
        return response;
      }
      const { content } = response.data;

      setResponseConsultaProductos(content);

      if (tieneContent(content)) {
        content.sdtProductos.sBTProducto.forEach((element: any) => {
          lista.push({ id: element.productoUId, description: element.nombre });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS PRODUCTOS.');
    }

    return lista;
  },

  SegurosVehicular: async (productoUId: number) => {
    const lista: any = [];

    try {
      let response: any = await ServicesProvider.ObtenerCoberturaDesgravament(productoUId)

      if (response.status !== 200) {
        return response;
      }
      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtSeguros.sdtsBTSeguroPrestamo.forEach((element: any) => {
          if (element.tipo === appConfigKey.keySeguroPrestamoAutoMotor) {
            lista.push({ id: element.codigo, description: element.descripcion });
          }

        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS TIPOS DE SEGUROS VEHICULARES.');
    }

    return lista;
  },

  PlazosMeses: async (productoUId: number) => {
    const lista: any = [];

    try {
      let response: any = await ServicesProvider.ObtenerCantidadCuotas(productoUId)

      if (response.status !== 200) {
        return response;
      }
      const { content } = response.data;
      if (tieneContent(content)) {
        content.listaValores.sdtsBTValor.forEach((element: any) => {
          lista.push({ id: element.valor, description: element.valor });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS PLAZOS DE MESES.');
    }

    return lista;
  },

  TiposCoberturaDesg: async (productoUId: number) => {
    const lista: any = [];

    try {
      let response: any = await ServicesProvider.ObtenerCoberturaDesgravament(productoUId)

      if (response.status !== 200) {
        return response;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtSeguros.sdtsBTSeguroPrestamo.forEach((element: any) => {
          if (element.tipo === appConfigKey.keySeguroPrestamoVida) {
            lista.push({ id: element.codigo, description: element.descripcion, porcentaje: element.porcentaje });
          }
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS TIPOS DE COBERTURA DE DESGRAVAMEN.');
    }

    return lista;
  },

  Campanias: async (productoUId: number, versionUId: number, data: any) => {
    const lista: any = [];

    try {
      let response: any = await ServicesProvider.ObtenerCampanias(productoUId, versionUId, data);

      if (response.status !== 200) {
        return response;
      }
      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtCampanias.jspSsBTCampania.forEach((element: any) => {
          lista.push({
            id: Number(element.codCampania),
            description: element.descripcion,
            tipoDescuento: element.tipoDescuento,
            tasa: element.tasa,
            montoBonoAdic: element.montoBonoAdic,
            moduloDesc: element.moduloDesc,
            montoBono: element.montoBono,
          });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LAS CAMPANIAS.');
    }

    return lista;
  },

  ProveedorGPS: async () => {
    const lista: any = [];

    try {
      let response: any = await ServicesProvider.ObtenerTiposGPS();

      if (response.status !== 200) {
        return response;
      }
      const { content } = response.data;

      if (tieneContent(content)) {
        content.sbtTiposGPS.sbtTiposGPS.forEach((element: any) => {
          lista.push({ id: element.codigo, description: element.descripcion });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR EN EL SERVICIO PARA OBTENER LOS PROVEEDORES DE GPS.');
    }

    return lista;
  },

  Comisiones: async (productoUId: number) => {
    const lista: any = [];

    try {
      let response: any = await ServicesProvider.ObtenerComisiones(productoUId)

      if (response.status !== 200) {
        return response;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtComisiones.sbtComisionPrestamo.forEach((element: any) => {
          lista.push(element);
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR EN EL SERVICIO PARA OBTENER COMISIONES.');
    }

    return lista;
  },

  ComisionesCoutas: async (productoUId: number) => {
    const lista: any = [];

    try {
      let response: any = await ServicesProvider.ObtenerComisionesCuota(productoUId)

      if (response.status !== 200) {
        return response;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtComisionesCuota.sBTComisionPrestamo.forEach((element: any) => {
          lista.push(element);
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR EN EL SERVICIO PARA OBNTER LAS COMISIONES CUOTAS.');
    }

    return lista;
  },

  EstadosProvinciasDepartamentos: async (paisId: number) => {
    const lista: any = [];

    try {
      let response: any = await ServicesProvider.ObtenerEstadosProvinciasDepartamentos({
        "pais": paisId
      })

      if (response.status !== 200) {
        return response;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtEstados.sBTEstado.forEach((element: any) => {
          lista.push({ id: element.identificador, description: element.descripcion });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS ESTADOS | PROVINCIAS | DEPARTAMENTOS');
    }

    return lista;
  },

  CiudadesLocalidades: async (paisId: number, departamentoId: number) => {
    const lista: any = [];

    try {
      let response: any = await ServicesProvider.ObtenerCiudadesLocalidades({
        "pais": paisId,
        "estado": departamentoId
      })

      if (response.status !== 200) {
        return response;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.sdtCiudades.sBTCiudad.forEach((element: any) => {
          lista.push({ id: element.identificador, description: element.descripcion });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER CIDUDADES | LCOALES.');
    }

    return lista;
  },

  BarriosColonias: async (paisId: number, departamentoId: number, provinciaId: number) => {
    const lista: any = [];

    try {
      let response: any = await ServicesProvider.ObtenerBarriosColonias({
        "pais": paisId,
        "estado": departamentoId,
        "ciudad": provinciaId
      })

      if (response.status !== 200) {
        return response;
      }
      const { content } = response.data;
      if (tieneContent(content)) {
        content.sdtBarrios.sBTBarrio.forEach((element: any) => {
          lista.push({ id: element.identificador, description: element.descripcion });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS BARRIOS | COLONIAS.');
    }

    return lista;
  },

  Domicilios: async () => {
    const lista: any = [];

    try {
      const response: any = await MasterManager.GetMnTablaGenericaDefinicion({
        "v_LIST_IDENT_TABLA": "29"
      });

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.forEach((element: any) => {
          lista.push({ id: element.genericA_CODL_CAMPO, description: element.genericA_DESCM_CAMPO });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS DESTINOS DE CREDITO.');
    }

    return lista;
  },

  TiempoResidencia: async () => {
    const lista: any = [];

    try {
      const response: any = await MasterManager.GetMnTablaGenericaDefinicion({
        "v_LIST_IDENT_TABLA": "15"
      });

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (tieneContent(content)) {
        content.forEach((element: any) => {
          lista.push({ id: element.genericA_IDENT_CAMPO, description: element.genericA_DESCM_CAMPO });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS DESTINOS DE CREDITO.');
    }

    return lista;
  },

};

export const General = {
  Solicitud: async (solicitudId: any) => {
    let solicitud = 0;

    try {
      const response = await MasterManager.GetMnSolicitud({ "idenT_SOLICITUD": solicitudId });

      if (response.status === 200) {
        solicitud = response.data.content[0];
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LA SOLICITUD.');
    }

    return solicitud;
  },

  Persona: async (personaId: any) => {
    let persona = 0;

    try {
      const response = await MasterManager.GetMnPersonaByID({ "idenT_PERSONA": personaId });

      if (response.status === 200) {
        persona = response.data.content[0];
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LAS INFORMACION DE LAS PERSONAS.');
    }

    return persona;
  },


  PersonaByTipoDocAndNroDoc: async (TipoDocumentoId: number, NumeroDocumento: string) => {
    let persona = 0;

    try {
      const response = await MasterManager.GetMnPersonaByTipoDocAndNroDoc({ "iD_TIPO_DOCUMENTO": TipoDocumentoId, "codL_NUMERO_DOCUMENTO": NumeroDocumento });

      if (response.status === 200) {
        persona = response.data.content[0];
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LAS INFORMACION DE LAS PERSONAS.');
    }

    return persona;
  },

  Ingresos: async (personaId: any) => {
    let ingresos = [];

    try {
      const response = await MasterManager.GetMnPersonaIngresosByIDCliente({ "idenT_PERSONA_INGRESOS": 0, "idenT_PERSONA": personaId });

      if (response.status === 200) {
        ingresos = response.data.content;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS INGRESOS DE UNA PERSONA.');
    }

    return ingresos;
  },

  Empresa: async (empresaId: any) => {
    let empresa = {};

    try {
      const response = await MasterManager.GetMnEmpresaEmpleadorPersona({
        "iD_TIPO_DOC": 0,
        "descL_NRO_DOC": "",
        "idenT_EMPRESA": empresaId,
        "descL_RAZON_SOCIAL_EMPRESA": ""
      });

      if (response.status === 200) {
        empresa = response.data.content[0];
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LAS EMPRESAS.');
    }

    return empresa;
  },

  Fiadores: async (solicitudId: any) => {
    let fiadores = [];

    try {
      const response = await MasterManager.GetMnSolicitudPersonaFiador({ "idenT_SOLICITUD_PERSONA_FIADORES": 0, "idenT_PERSONA": 0, "idenT_SOLICITUD": solicitudId });

      if (response.status === 200) {
        fiadores = response.data.content;
      };
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LOS FIADORES.');
    }

    return fiadores;
  },

  ActualizarHistoricoTea: async (body: any) =>{
    try {
      let response = await MasterManager.GetMnSolicitudHistoricoCambiarTasaInsert(body);
      return true;
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA REGISTRAR EL HISTORICO DE CAMBIO DE TEA.');
      return false;
    }
  },

  //PASO 4
  Conograma: async (
    clienteUId: number,
    cantidadCuotas: number,
    destinoCredito: number,
    esCuotaDoble: boolean,
    data: any,
    tieneCuotaBallon: boolean
  ) => {

    let simularOfertaResponse: any = null
    let cronograma: any = null
    let listaComisionesCuotaCronoSel: any = null;
    let listaComisionesCronoSel: any = null;
    let listaConograma: any = [];
    let listaCuotasPorPlazos: any = [];
    let simulacionUId: number = 0;
    let operacionUId: number = 0;
    let totalFinanciamiento: number = 0;

    try {
      simularOfertaResponse = await ServicesProvider.SimularOfertasVehicular(clienteUId, data).then((responseSimplesOrDoble: any) => {

        const { btErrorNegocio } = responseSimplesOrDoble.data.content.erroresnegocio;

        if (btErrorNegocio.length > 0) {
          const errorItemFirst = btErrorNegocio[0];
          toast.error(errorItemFirst.descripcion, { duration: appConfigKey.keyDurationToast });
          return null;
        }

        simulacionUId = responseSimplesOrDoble.data.content.simulacionUId;

        responseSimplesOrDoble.data.content.sdtOfertas.jspSsBTOfertaOutput.forEach((element: any) => {

          listaCuotasPorPlazos.push({
            id: element.cantidadCuotas,
            plazo: element.cantidadCuotas.toString(),
            simple: esCuotaDoble ? '' : GeneralHelper.NumeroConCommas(element.valorCuota.toString()),
            doble: esCuotaDoble ? GeneralHelper.NumeroConCommas(element.valorCuota.toString()) : '',
            rangomonto: esCuotaDoble ? GeneralHelper.NumeroConCommas(element.valorCuotaDoble.toString()) : '',
            monto: GeneralHelper.NumeroConCommas(element.totalAPagar.toString()),
            montoDoble: esCuotaDoble ? GeneralHelper.NumeroConCommas(element.totalAPagar.toString()) : '',
            simulacionSimpleUId: esCuotaDoble ? 0 : simulacionUId,
            simulacionDobleUId: esCuotaDoble ? simulacionUId : 0,
            comisNoFinanciadasSimple: esCuotaDoble ? {} : element.comisNoFinanciadas,
            comisNoFinanciadasDoble: esCuotaDoble ? element.comisNoFinanciadas : {},
            totalFinanciamiento: element.capital
          });

        });

        return responseSimplesOrDoble;

      });
    } catch (error) {
      return null;
    }

    if (simulacionUId === 0) {
      return null;
    }

    if (simulacionUId > 0 && listaCuotasPorPlazos.length > 0) {

      if (!tieneCuotaBallon) {
        totalFinanciamiento = listaCuotasPorPlazos.find((x: any) => x.plazo === cantidadCuotas.toString())?.totalFinanciamiento ?? 0;
      } else {
        const cantidadCuotasFinal = cantidadCuotas + 1;
        totalFinanciamiento = listaCuotasPorPlazos.find((x: any) => x.plazo === cantidadCuotasFinal.toString())?.totalFinanciamiento ?? 0;
      }

      const jsonRequestObtenerDetalleOferta = JsonHelper.crearJsonRequestObtenerDetalleOferta(data.sdtPartner.puntoVentaUId,
        data.sdtPartner.vendedorUId,
        data.sdtPartner.partnerUId,
        cantidadCuotas,
        destinoCredito);

      let detalleOfertaResponse = await ServicesProvider.ObtenerDetalleOferta(simulacionUId, clienteUId, jsonRequestObtenerDetalleOferta);

      if (detalleOfertaResponse.status !== 200) {
        return cronograma;
      };

      const { content } = detalleOfertaResponse.data;

      if (tieneContent(content)) {

        listaComisionesCuotaCronoSel = content.sdtSimulacion.comisionesCuota.sdtsBTComisionPrestamo;
        listaComisionesCronoSel = content.sdtSimulacion.comisiones.sdtsBTComisionPrestamo;

        operacionUId = GeneralHelper.ParseNumber(content.sdtSimulacion.operacionUId);

        let saldoCapital = content.sdtSimulacion.capital;
        let amortizacionAntigua = appDefaultValues.NumberDefault;

        content.sdtSimulacion.cronograma.sdtsBTCuotaSimulacion.forEach((element: any, index: number) => {

          let porte = element.detalleComisiones.sdtsBTComisionPrestamo.find((x: any) => x.codigo === appConfigKey.keyCodigoComisionesCuotaPortes);
          let porteImporte = appDefaultValues.NumberDefault;

          if (porte !== undefined) {
            porteImporte = porte.importe;
          }

          let seguroMonto = appDefaultValues.NumberDefault;
          const listaSegurosDesgravamen = appConfigKey.CodigosSegurosDesgravamen;

          element.detalleSeguros.sdtsBTSeguroPrestamo.map((x: any) => {

            const seguroEncontrado = listaSegurosDesgravamen.find((item: any) => x.codigo === item);

            if (seguroEncontrado !== undefined) {
              seguroMonto = x.importe;
            }

          });

          let seguroVehicularMonto = appDefaultValues.NumberDefault;
          const listaSegurosVehiculo = appConfigKey.CodigosSegurosVehiculares;

          element.detalleSeguros.sdtsBTSeguroPrestamo.map((x: any) => {

            const seguroEncontrado = listaSegurosVehiculo.find((item: any) => x.codigo === item);

            if (seguroEncontrado !== undefined) {
              seguroVehicularMonto = x.importe;
            }

          });

          let cuotaMonto = element.cuota - porteImporte;
          let amortTotal = cuotaMonto - seguroMonto - element.intereses;

          if (index !== appDefaultValues.NumberDefault) {
            saldoCapital = saldoCapital - amortizacionAntigua;
          }

          let amort = amortTotal - seguroVehicularMonto;
          amortizacionAntigua = amort;

          listaConograma.push({
            id: index + 1,
            cuota: element.nroCuota.toString(),
            fVence: parseDateString(element.fechaPago),
            dias: element.plazo,
            saldoCapital: numeroConCommas(saldoCapital.toFixed(2)),
            amort: numeroConCommas(amort.toFixed(2)),
            mSeguro: seguroVehicularMonto.toFixed(2),
            amortTotal: numeroConCommas(amortTotal.toFixed(2)),
            interes: numeroConCommas(element.intereses.toFixed(2)),
            seguroDesg: seguroMonto.toFixed(2),
            cuotaMonto: numeroConCommas(cuotaMonto.toFixed(2)),
            portes: numeroConCommas(porteImporte.toFixed(2)),
            pagoTotal: numeroConCommas(element.cuota.toFixed(2)),
          });

        });

        const calculoMontoFinanciacion = content.sdtSimulacion.capital;

        cronograma = {
          listaConograma,
          listaCuotasPorPlazos,
          listaComisionesCronoSel,
          listaComisionesCuotaCronoSel,
          operacionUId,
          totalFinanciamiento,
          tcea: content.sdtSimulacion.cft,
          simulacionUId: simulacionUId,
          simularOfertaRequest: data,
          simularOfertaResponse: simularOfertaResponse,
          detalleOfertaRequest: jsonRequestObtenerDetalleOferta,
          detalleOfertaResponse: detalleOfertaResponse,
          calculoMontoFinanciacion,
        }
      }

    }

    return cronograma;
  },

  GetMnSolicitudCronograma: async (request: TMnSolicitudCronogramaRequest) => {
    let listaConograma: any = [];

    try {
      let response = await MasterManager.GetMnSolicitudCronograma(request);

      if (response && response.status == 200) {
        listaConograma = response.data.content.map((e, index) => {
          return {
            id: index + 1,
            cuota: e.canT_NUMERO_CUOTA.toString(),
            fVence: TimeHelper.ObtenerFormatoFechaPeru(e.fecH_VENCIMIENTO),
            dias: e.idenT_CANT_DIAS,
            saldoCapital: numeroConCommas(e.monT_SALDO_CAPITAL_INICIAL.toFixed(2)),
            amort: numeroConCommas(e.monT_AMORTIZACION.toFixed(2)),
            mSeguro: e.monT_SEGURO.toFixed(2),
            amortTotal: numeroConCommas(e.monT_TOTAL_AMORTIZACION.toFixed(2)),
            interes: numeroConCommas(e.monT_INTERES.toFixed(2)),
            seguroDesg: e.monT_SEGURO_DESGRAVAMEN.toFixed(2),
            cuotaMonto: numeroConCommas(e.monT_SALDO_TOTAL_INICIAL.toFixed(2)),
            portes: numeroConCommas(e.monT_PORTES.toFixed(2)),
            pagoTotal: numeroConCommas(e.monT_TOTAL_CUOTA.toFixed(2)),
          }
        });
      }

    } catch (error) {
    }

    let cronograma = {
      listaConograma: listaConograma,
      listaCuotasPorPlazos: [],
      listaComisionesCronoSel: [],
      listaComisionesCuotaCronoSel: [],
      operacionUId: 0,
      totalFinanciamiento: 0
    }

    return cronograma;
  },

  ValidarListaNegraYGesintel: async (data: any) => {
    let bantotalPlaftObservadoValue: boolean = false;
    let bantotalRiesgosObservadoValue: boolean = false;
    let gesintelPlaftObservadoValue: boolean = false;
    let bantotalResponse: string | null = null;
    let gesintelResponse: string | null = null;
    let clientePepValue: boolean = false;
    let validacion = null
    let errorBantotal: boolean = false;
    let errorGesintel: boolean = false;
    try {
      const RequestValidarListasInhabilitados = JsonHelper.crearJsonRequestValidarListasInhabilitados();
      RequestValidarListasInhabilitados.pais = appConfigKey.KeyIdPaisPeru;
      RequestValidarListasInhabilitados.tipoDocumento = data?.tipoDocumento;
      RequestValidarListasInhabilitados.numeroDocumento = data?.numeroDocumento;
      RequestValidarListasInhabilitados.razonSocial = data?.razonSocial;
      const response = await ServicesProvider.ValidarListasInhabilitados(RequestValidarListasInhabilitados);

      if (response.status === 200) {
        if (response.data.content?.listaInhabilitados?.sBtListaInhabilitados != null) {
          let resultList = ListaNegraHelper.TransformarContentToList(response.data.content);
          resultList?.forEach((element) => {
            if (element?.tipo === appConfigKey.keyDescripPlaft) bantotalPlaftObservadoValue = true;
            if (element?.tipo === appConfigKey.keyDescripRiesgosMin) bantotalRiesgosObservadoValue = true;
            if (element?.descripcion === appConfigKey.keyDescripPlaftPEP) clientePepValue = true;
          });
          if (bantotalPlaftObservadoValue || bantotalRiesgosObservadoValue) {
            bantotalResponse = JSON.stringify(resultList);
          }
        } else if (!response.data.isValid) {
          errorBantotal = true;
        }
      } else {
        errorBantotal = true;
      }
    }
    catch (error) {
      errorBantotal = true;
    }

    try {
      const RequestGesintelObtenerAMLResult = JsonHelper.crearJsonRequestGesintelObtenerAMLResult();
      RequestGesintelObtenerAMLResult.rut = data?.numeroDocumento;
      RequestGesintelObtenerAMLResult.typeSearch = appConfigKey.keyTypeSearchGesintel;
      const response = await ServicesProvider.ValidarGesintelAML(RequestGesintelObtenerAMLResult);

      if (response.status === 200) {
        if (response.data.content?.results != null) {
          let resultList = GesintelHelper.TransformarContentToList(response.data.content);
          if (resultList && resultList.length > 0) {
            gesintelPlaftObservadoValue = true;

            if (resultList.filter(e => [appConfigKey.keyDescripPEPVigente, appConfigKey.keyDescripPEPHistorico, appConfigKey.keyDescripPEPCandidato].includes(e.descripcion))) {
              clientePepValue = true;
            }

            gesintelResponse = JSON.stringify(resultList);
          }
        } else if (!response.data.isValid) {
          errorGesintel = true;
        }
      } else {
        errorGesintel = true;
      }
    }
    catch (error) {
      errorGesintel = true;
    }

    validacion = {
      bantotalPlaftObservado: bantotalPlaftObservadoValue,
      bantotalRiesgosObservado: bantotalRiesgosObservadoValue,
      gesintelPlaftObservado: gesintelPlaftObservadoValue,
      bantotalJson: bantotalResponse,
      gesintelJson: gesintelResponse,
      clientePep: clientePepValue,
      errorBantotal: errorBantotal,
      errorGesintel: errorGesintel,
    }

    return validacion;

  },

  ValidarListaNegra: async (body: any) => {
    try {
      const response = await ServicesProvider.ValidarListasInhabilitados(body);

      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE LISTA INHABILITADOS.');
      return null;
    }
  },

  ValidarGesintelAML: async (data: any) => {
    try {
      const response = await ServicesProvider.ValidarGesintelAML(data);

      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE GESINTEL AML.');
      return null;
    }
  },

  EstadosFinancieros: async (personaId: any) => {
    let estadosFinancieros = [];

    try {
      const response = await MasterManager.GetMnEstadoFinancieroPersonaJuridica({ "idenT_ESTADO_FINANCIERO": 0, "idenT_PERSONA": personaId });

      if (response.status === 200) {
        estadosFinancieros = response.data.content;
      };
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER ESTADOS FINANCIEROS.');
    }

    return estadosFinancieros;
  },

  Modelica: async (body: any) => {

    try {
      const response = await ServicesProvider.ModellicaMotorEvaluacion(body);

      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE EVALUACION DE MODELICA.');
      return null;
    }
  },

  GenerateActualizacionSolicitud: async (body: any) => {
    try {
      const response = await RequestManager.GetMnGenerateActualizacionSolicitud(body);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE ACTUALIZACION DE SOLICITUD.');
      return null;
    }
  },

  GetMnSolicitudGastos: async (idenT_SOLICITUD: number) => {
    let gastos = [];
    try {
      const response: any = await MasterManager.GetMnSolicitudGastos({
        "idenT_SOLICITUD": idenT_SOLICITUD
      });
      if (response.status === 200) {
        gastos = response.data.content;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER SOLICITUD DE GASTOS.');
    }
    return gastos;
  },

  GetMnEvaluacionSolicitud: async (identSolicitud: any) => {
    let evaluaciones = [];
    try {
      let response = await MasterManager.GetMnEvaluacionSolicitud(identSolicitud);
      if (response && response.status === 200) {
        evaluaciones = response.data.content;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LA EVALUACION DE LA SOLICITUD.');
    }
    return evaluaciones;
  },

  GetMnEvaluacionSolicitudInsert: async (identSolicitud: number, step: number, modelicaRequest: string, modelicaResponse: string) => {
    try {
      await MasterManager.GetMnEvaluacionSolicitudInsert(identSolicitud, step, modelicaRequest, modelicaResponse);
      return true;
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA REGISTRAR LA EVALUACION DE LA SOLICITUD.');
      return false;
    }
  },

  GetMnResultadoEvaluacionInsert: async (data: any) => {
    try {
      await MasterManager.GetMnResultadoEvaluacionInsert(data);
      return true;
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA REGISTRAR LA EVALUACION DE LA SOLICITUD.');
      return false;
    }
  },

  ConsultarPersonaFisica: async (tipoDocumentoId: number, numeroDocumento: string) => {
    try {
      const response = await ServicesProvider.BTObtenerIdentificadorUnico(tipoDocumentoId, numeroDocumento);
      return response?.data.content;
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA CONSULTAR PERSONA FISICA.');
      return null;
    }
  },

  BTCrearPersonaFisica: async (persona: BTCrearPersonaFisicaRequest) => {
    try {
      const response = await ServicesProvider.BTCrearPersonaFisica(persona);
      return response?.data.content;
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA CREAR PERSONA FISICA.');
      return null;
    }
  },

  BTCrearCliente: async (request: BTCrearClienteRequest) => {
    try {
      const response = await ServicesProvider.BTCrearCliente(request);
      return response?.data.content;
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA CREAR CLIENTE.');
      return null;
    }
  },

  BTActualizaPersonaFisica: async (persona: BTCrearPersonaFisicaRequest, personaId: number) => {
    try {
      const response = await ServicesProvider.BTActualizaPersonaFisica(persona, personaId);
      return response?.data.content;
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA ACTUALIZAR PERSONA FISICA.');
      return null;
    }
  },

  GenerateObservacionSolicitud: async (body: any) => {
    try {
      const response = await RequestManager.GetMnGenerateObservacionSolicitud(body);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE ACTUALIZACION DE SOLICITUD.');
      return null;
    }
  },

  GenerateActualizacionSolicitudV2: async (body: any) => {
    try {
      const response = await RequestManager.GetMnGenerateActualizacionSolicitudV2(body);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE ACTUALIZACION DE SOLICITUD.');
      return null;
    }
  },

  GetMnIntegracionSolicitudInsert: async (body: MnIntegracionSolicitudRequest) => {
    try {
      const response = await RequestManager.GetMnIntegracionSolicitudInsert(body);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE INSERTAR INTEGRACION SOLICITUD.');
      return null;
    }
  },

  ObtenerPersonaNatural: async (body: BTObtenerPersonaNaturalRequest) => {
    try {
      const response = await ServicesProvider.BTObtenerPersonaNatural(body);
      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE OBTENER PERSONA NATURAL.');
      return null;
    }
  },

  GetMnSolicitudPersonaPorValidar: async (body: TMnSolicitudPersonaPorValidarRequest) => {
    try {
      const response = await MasterManager.GetMnSolicitudPersonaPorValidar(body);
      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER MIEMBROS DE LA SOLICITUD POR VALIDAR.');
    }
    return null;
  },

  GetSolicitudPersonaObservada: async (solicitudId: number) => {
    try {
      let datos: any[] = [];
      const response = await MasterManager.GetMnSolicitudPersonaObservada(solicitudId);
      if (response?.status === 200) {
        datos = response.data.content;
      }
      return datos;
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER MIEMBROS OBSERVADOS DE LA SOLICITUD.');
    }
    return null;
  },  

  ValidacionSolicitud: async (idenT_SOLICITUD: number, idenT_USUARIO: number, idenT_FLUJO: number) => {
    try {
      const response = await RequestManager.GetValidacionSolicitud({
        "idenT_SOLICITUD": idenT_SOLICITUD,
        "idenT_USUARIO": idenT_USUARIO,
        "idenT_FLUJO": idenT_FLUJO
      });
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE VALIDACIÓN SOLICITUD.');
      return null;
    }
  },

  GetUsuario: async (userId: number) => {
    try {
      const response = await MasterManager.GetMnUsuarios({
        idenT_USUARIO: userId,
      });
      if (response.status === 200) {
        return response.data.content[0];
      }
    } catch (error) {
      //myowasp('OCURRIO UN ERROR CON EL SERVICIO DE OBTENER USUARIO.');
      return null;
    }
  },

  ObtenerDatosPartnerVendedor: async (body: BTObtenerDatosPartnerVendedorRequest) => {
    try {
      const response = await ServicesProvider.ObtenerDatosPartnerVendedor(body);
      if (response?.status === 200) {
        return response.data.content;
      }
    } catch (error) {
      //myowasp('OCURRIO UN ERROR CON EL SERVICIO DE OBTENER PARTNER VENDEDOR.');
      return null;
    }
  },

  GetMnSolicitudAsociado: async (body: TMnSolicitudAsociadoRequest) => {
    try {
      const response = await MasterManager.GetMnSolicitudAsociado(body);
      if (response?.status === 200) {
        return response.data.content;
      }
    } catch (error) {
      //myowasp('OCURRIO UN ERROR CON EL SERVICIO DE OBTENER SOLICITUD ASOCIADO.');
      return null;
    }
  },

  GetMnSolicitudAsociadoInsert: async (body: TMnSolicitudAsociadoInsertUpdateRequest) => {
    try {
      const response = await MasterManager.GetMnSolicitudAsociadoInsert(body);
      if (response?.status === 200) {
        return response.data.content;
      }
    } catch (error) {
      //myowasp('OCURRIO UN ERROR CON EL SERVICIO DE INSERTAR SOLICITUD ASOCIADO.');
      return null;
    }
  },

  BTAltaReducidaClientes: async (body: BTAltaClienteReducidaRequest) => {
    try {
      const response = await ServicesProvider.BTAltaReducidaClientes(body);
      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE CREAR REDUCIDO.');
      return null;
    }
  },

};