import { appDefaultValues } from '../../../config/Config';

export interface Ingresos {
 idPersona: number,
 idIngresoPersona: number,
 moneda: string,
 ingresoNeto: number,
 comentario: string,
 categoriaLaboral: string,
 ruc: string,
 razonSocial: string,
 fechaIngresoLaboral: string,
 ingresoAnualizado: boolean,
 separacionDeBienes: string,

 categoriaLaboralAnterior: string,
 rucAnterior: string,
 razonSocialAnterior: string,
 fechaIngresoLaboralAnterior: string,
 fechaHasta: string,
 totalAnualizado: string,
 separacionBienes: string,

 montoMinimoAnualizado: number,
 idcategoriaLaboral: number
}

export interface OtrosIngresos {
 idIngresoPersona: number,
 idmoneda: number,
 moneda: string,
 ingresoNeto: number,
 comentario: string,
 idcategoriaLaboral: number,
 categoriaLaboral: string,
 ruc: string,
 razonSocial: string,
 fechaIngresoLaboral: string,
 ingresoAnualizado: boolean,
 totalAnualizado: number
}


export interface Titular {
 ingresos: Ingresos
 otrosIngresos: OtrosIngresos[]
}

export const TitularDefault: Titular = {
 ingresos:{
  idPersona: appDefaultValues.NumberDefault,
  idIngresoPersona: appDefaultValues.NumberDefault,
  moneda: appDefaultValues.StringEmpty,
  ingresoNeto: appDefaultValues.NumberDefault,
  comentario: appDefaultValues.StringEmpty,
  categoriaLaboral: appDefaultValues.StringEmpty,
  ruc: appDefaultValues.StringEmpty,
  razonSocial: appDefaultValues.StringEmpty,
  fechaIngresoLaboral: appDefaultValues.StringEmpty,
  ingresoAnualizado: appDefaultValues.BooleanDefault,
  separacionDeBienes: appDefaultValues.StringEmpty,
  categoriaLaboralAnterior: appDefaultValues.StringEmpty,
  rucAnterior: appDefaultValues.StringEmpty,
  razonSocialAnterior: appDefaultValues.StringEmpty,
  fechaIngresoLaboralAnterior: appDefaultValues.StringEmpty,
  fechaHasta: appDefaultValues.StringEmpty,
  totalAnualizado: appDefaultValues.StringEmpty,
  separacionBienes: appDefaultValues.StringEmpty,
  montoMinimoAnualizado: appDefaultValues.NumberDefault,
  idcategoriaLaboral: appDefaultValues.NumberDefault,
 },  
 otrosIngresos: []
 // {
 //  moneda: appDefaultValues.StringEmpty,
 //  ingresoNeto: appDefaultValues.NumberDefault,
 //  comentario: appDefaultValues.StringEmpty,
 //  categoriaLaboral: appDefaultValues.StringEmpty,
 //  ruc: appDefaultValues.StringEmpty,
 //  razonSocial: appDefaultValues.StringEmpty,
 //  fechaIngresoLaboral: appDefaultValues.StringEmpty,
 //  ingresoAnualizado: appDefaultValues.NumberDefault,
 // }
}