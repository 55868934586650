import { Button } from "@mui/joy";
import SimbolAdd from "@mui/icons-material/Add";

export const ButtonAdd = (props: any) => {
    return (
        <Button
            size="md"
            color="neutral"
            variant="outlined"
            endDecorator={<SimbolAdd />}
            sx={{ borderRadius: 24, width: 1/8 }}
            disabled={props.disabled}
            onClick={props.onClick}>{props.title}</Button>
    )
};