import { Table } from "@mui/joy";
import { useParams } from "react-router-dom";
import MasterManager from "../../../services/origination/wapimaster.service";
import { useEffect, useState } from "react";

const ResultadosHistorial = ({ flgResultadosHistorial, selSolicitud }: any) => {
  const { solicitudId } = useParams();
  const [resultadosHistorial, setResultadosHistorial] = useState([]);

  const obtenerListHistorialResultadoAnalisis = async () => {
    await MasterManager.GetMnResultadoEvaluacion(selSolicitud || Number(solicitudId))
      .then((response: any) => {
        setResultadosHistorial(response.data.content);
      })
      .catch(error => {
        console.log(error.message);
      })
  };

  useEffect(() => {
    obtenerListHistorialResultadoAnalisis();
  }, [flgResultadosHistorial])


  return (
    <div className="rounded-9 p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-4 textLabelHeadboard">
          <span className="fw-bold">HISTORIAL RESULTADO ANÁLISIS</span>
        </div>
        <div className="col-lg-4">
          <span className="fw-bold"> </span>
        </div>
        <div className="col-lg-4 textAlignRigth"></div>
      </div>

      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-12 tableContainer-resultado align-top table-credit-evaluation-hist">
          <Table borderAxis="none" className="style-table align-top">
            <thead>
              <tr>
                <th style={{ width: "150px", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>FECHA/HORA</th>
                <th style={{ width: "100px", backgroundColor: "#E1E1E1", }}>USUARIO</th>
                <th style={{ width: "280px", backgroundColor: "#E1E1E1", }}>ETAPA</th>
                <th style={{ width: "180px", backgroundColor: "#E1E1E1", }}>DECISIÓN</th>
                <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>TIPO DECISIÓN</th>
                <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>COMENTARIO</th>
              </tr>
            </thead>
            <tbody>
              {
                resultadosHistorial && resultadosHistorial.map((row: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.fechahora}</td>
                      <td > {row.usuario}</td>
                      <td > {row.etapA_RESULTADO}</td>
                      <td > {row.resultado}</td>
                      <td > {
                        row.etapA_RESULTADO !== "EVALUACION CREDITICIA - MOTOR" && row.tipO_RESULTADO
                      }</td>
                      <td > {row.comentario}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </div>
      </div>
    </div>

  );
};

export default ResultadosHistorial;