import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormLabel from "@mui/joy/FormLabel";
import { useEffect } from "react";
import React from "react";
import GeneralHelper from "../../../../helpers/GeneralHelper";

const CardDebts = ({ debts, men, tipoCambio, montoCuota }: any) => {
  const [menS, setMenS] = React.useState(0);
  const [menD, setMenD] = React.useState(0);

  const [cuotaS, setCuotaS] = React.useState(0);
  const [cuotaD, setCuotaD] = React.useState(0);

  useEffect(() => {
    const newMenS = men || debts.menS;
    setMenS(newMenS);
    const newMenD = (men / tipoCambio) || debts.menD;
    setMenD(newMenD);

    //** Cuota */
    const newCuotaS = montoCuota || debts.cuotasS;
    setCuotaS(newCuotaS);
    const newCuotaD = (montoCuota / tipoCambio) || debts.cuotasD;
    setCuotaD(newCuotaD);
  })

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <div className="row g-2 align-items-center">
        <div className="col-lg-12 mx-3 textLabelHeadboard">
          <span className="fw-bold">CAPACIDAD DE ENDEUDAMIENTO</span>
        </div>
      </div>
      <div className="row mx-3 align-items-center">
        <div className="col-lg-6 cardObjets">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormLabel>CFM</FormLabel>
            <span>{debts.cfm}</span>
          </LocalizationProvider>
        </div>
        <div className="col-lg-6 cardObjets">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormLabel>CFC</FormLabel>
            <span>{debts.cfc}</span>
          </LocalizationProvider>
        </div>
      </div>
      <div className="row mx-3 align-items-center">
        <div className="col-lg-6 cardObjets">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormLabel>CUOTA S/</FormLabel>
            <span>{cuotaS ? GeneralHelper.AgregarComasAMiles(cuotaS.toFixed(2)) : cuotaS}</span>
          </LocalizationProvider>
        </div>
        <div className="col-lg-6 cardObjets">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormLabel>CUOTA $</FormLabel>
            <span>{cuotaD ? GeneralHelper.NumeroConCommas(cuotaD.toFixed(2)) : cuotaD}</span>
          </LocalizationProvider>
        </div>
      </div>
      <div className="row mx-3 align-items-center">
        <div className="col-lg-6 cardObjets">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormLabel>MEM S/</FormLabel>
            <span>{menS ? GeneralHelper.NumeroConCommas(menS.toFixed(2)): menS}</span>
          </LocalizationProvider>
        </div>
        <div className="col-lg-6 cardObjets">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormLabel>MEM $</FormLabel>
            <span>{menD ? GeneralHelper.NumeroConCommas(menD.toFixed(2))  : menD}</span>
          </LocalizationProvider>
        </div>
      </div>
    </div>
  );
};

export default CardDebts;