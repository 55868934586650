
import './../error404/error404.scss';
import logoError404 from './../../../../../assets/media/icons/ilustracion-404.png';
import { Button } from '@mui/joy';
import { useNavigate } from 'react-router-dom';

const Error404 = () => {

    const navigate = useNavigate();

    return (
        <div className="container">
            <div className="content">
                <div className="row g-2 mb-2">
                    <div className="col-lg-12" style={{ fontSize: 'xx-large' }}>
                        <strong>Vaya!</strong>
                    </div>
                    <div className="col-lg-12" style={{ fontSize: 'x-large' }}>
                        No podemos encontrar esa página
                    </div>
                    <div className="col-lg-12" >
                        <img src={logoError404} alt="Logo" style={{ width: '400px' }} />
                    </div>
                    <div>
                        <Button
                            disabled={false}
                            size="lg"
                            variant="solid"
                            style={{
                                borderRadius: 100,
                                backgroundColor: '#EC0000',
                                width: '338px',
                                height: '40px',
                                caretColor: 'transparent'
                            }}
                            onClick={() => {
                                navigate(`/`);
                              }}
                        >Retornar a SANTI

                        </Button>
                    </div>
                </div>
            </div>
        </div>

    );
}
export default Error404;