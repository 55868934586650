import { appDefaultValues } from '../../../config/Config';

export interface ResultadoEvaluacionDto {
 idenT_RESULTADO_EVALUACION: number;
 idenT_SOLICITUD: number;
 idenT_RESULTADO: number;
 idenT_DESTINO_CREDITO: number;
 comentario: string;
 idenT_USUARIO_CREADOR: number;
 idenT_TIPO_RESULTADO: number;
 descc_DETALLE: string,
 idenT_CREDITO_TIPO: number;
 idenT_SUBTIPO: number;
 numerO_VISOR: string;
}

export interface reqResultadoEvaluacionDto {
 request: ResultadoEvaluacionDto
}

export const ResultadoEvalucionDtoDefault: ResultadoEvaluacionDto = {
 idenT_RESULTADO_EVALUACION: appDefaultValues.NumberDefault,
 idenT_SOLICITUD: appDefaultValues.NumberDefault,
 idenT_RESULTADO: appDefaultValues.NumberDefault,
 idenT_DESTINO_CREDITO: appDefaultValues.NumberDefault,
 comentario: appDefaultValues.StringEmpty,
 idenT_USUARIO_CREADOR: appDefaultValues.NumberDefault,
 idenT_TIPO_RESULTADO: appDefaultValues.NumberDefault, //Enviar siempre este valor -> Christopher
 descc_DETALLE: appDefaultValues.StringEmpty,
 idenT_CREDITO_TIPO: appDefaultValues.NumberDefault,
 idenT_SUBTIPO: appDefaultValues.NumberDefault,
 numerO_VISOR: appDefaultValues.StringEmpty,
}



export interface Listado {
 id: number,
 label: string,
 detail: string
}

export const ListadoDefault: Listado = {
 id: appDefaultValues.NumberDefault,
 label: appDefaultValues.StringEmpty,
 detail: appDefaultValues.StringEmpty,
}



export interface ResultadoEvaluacion {
 resultado: string,
 destinoCredito: string,
 comentario: string,
}

export const ResultadoEvaluacionDefault: ResultadoEvaluacion = {
 resultado: appDefaultValues.StringEmpty,
 destinoCredito: appDefaultValues.StringEmpty,
 comentario: appDefaultValues.StringEmpty,
}