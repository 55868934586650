import { appDefaultValues } from "../../../../config/Config";

export const ObtenerModeloSolicitudCampania = (
    listaCampanias: any,
    campaniaSeleccionada: any,
    montoVehiculoBono: any,
    montoInicialBono: any,
    montoFinanciarBono: any,
) => {

    let campaniaModel: any = {
        "idenT_SOLICITUD": appDefaultValues.NumberDefault,
        "tasA_CAMPANIA": appDefaultValues.NumberDefault,
        "monT_BONOADIC": appDefaultValues.NumberDefault,
        "descripcion": appDefaultValues.StringEmpty,
        "modulO_DESC": appDefaultValues.StringEmpty,
        "monT_BONO": appDefaultValues.NumberDefault,
        "tipO_DESCUENTO": appDefaultValues.NumberDefault,
        "codigO_CAMPANIA": appDefaultValues.NumberDefault,
        "monT_PRECIO_VEHICULO_BONO": appDefaultValues.NumberDefault,
        "monT_CUOTA_INICIAL_BONO": appDefaultValues.NumberDefault,
        "monT_A_FINANCIAR_BONO": appDefaultValues.NumberDefault,
        "estado": appDefaultValues.BooleanDefault,
    };

    const campaniaEncontrada = listaCampanias.find(
        (x: any) => x.id === campaniaSeleccionada
    );

    if (campaniaEncontrada !== undefined) {
        campaniaModel.tasA_CAMPANIA = campaniaEncontrada.tasa;
        campaniaModel.monT_BONOADIC = campaniaEncontrada.montoBonoAdic;
        campaniaModel.descripcion = campaniaEncontrada.label;
        campaniaModel.modulO_DESC = campaniaEncontrada.moduloDesc;
        campaniaModel.monT_BONO = campaniaEncontrada.montoBono;
        campaniaModel.tipO_DESCUENTO = campaniaEncontrada.tipoDescuento;
        campaniaModel.codigO_CAMPANIA = campaniaEncontrada.id;
        campaniaModel.monT_PRECIO_VEHICULO_BONO = montoVehiculoBono;
        campaniaModel.monT_CUOTA_INICIAL_BONO = montoInicialBono;
        campaniaModel.monT_A_FINANCIAR_BONO = montoFinanciarBono;
        campaniaModel.estado = appDefaultValues.BooleanDefaultTrue;
    } else {
        campaniaModel = null;
    }

    return campaniaModel;
};