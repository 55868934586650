import { Dialog, Paper, Select, MenuItem, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import { useEffect, useState } from "react";
import { ReactComponent as CloseIcon } from './../../../../../assets/media/icons/ico-close-modal.svg';
import LoadingProgress from "../../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import { Button, FormControl, FormLabel, Textarea } from "@mui/joy";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { General, Lista } from "../obtenerServicios";
import ValidacionRequestLiberarExcepcionHelper from "../helpers/ValidacionRequestLiberarExcepcionHelper";
import { toast } from "sonner";
import { appConfigDecisionMotor, appConfigEstadosProcesoEtapaAnalisisCredito, appConfigEtapaAnalisisCredito, appConfigKey, appConfigProcesoEtapaAnalisisCredito, appConfigResultadoLiberacion, appSecurityConfiguration } from "../../../../../config/Config";

const ReleaseExceptionDialog = (props: any) => {

    const { data, setEsModoLectura, solicitud, setSolicitud } = props;
    const [dataExceptionResult, setDataExceptionResult] = useState([]);
    const [requestReleaseException, setRequestReleaseException] = useState<any>({
        identSolicitudExcepcion: 0,
        comentario: "",
        idEstadoResultadoExcepcion: 0,
        identUsuarioCreador: 0
    });
    const [dataSustentoException, setDataSustentoException] = useState<any>([]);
    const [acciones, setAcciones] = useState<any>([]);
    const [showLoading, setShowLoading] = useState(false);
    const [esModoLecturaForm, setEsModoLecturaForm] = useState(false);

    const GetSolicitudExcepcionLiberacionByIdSolicitud = async (identSolicitud: number) => {
        const response: any = await Lista.GetSolicitudExcepcionLiberacionByIdSolicitud(identSolicitud);
        if (response.length > 0) {
            setDataExceptionResult(response);
        }
    };
    const getObtenerAcciones = async () => {
        const response: any = await Lista.ObtenerAcciones();
        setAcciones(response);
    };

    const GuardarLiberarExcepcionModal = async () => {
        const validacionRequestException =
            !ValidacionRequestLiberarExcepcionHelper.NewReqSolicitudExcepcionLiberacionInsert(requestReleaseException);

        if (validacionRequestException) return;

        setShowLoading(true);

        const listaSolicitudEtapas = await Lista.GetSolicitudEtapas(null, Number(solicitud.idSolicitud));
        let listaSolicitudEtapaRechazado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Rechazado)
        if (listaSolicitudEtapaRechazado.length > 0) {
            toast.error(appConfigKey.keyMsjContinuarSolicitudRechazada);
            setShowLoading(false);
            return
        }

        let listaSolicitudEtapaAprobado = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Aprobado ||
            x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion)
        if (listaSolicitudEtapaAprobado.length === listaSolicitudEtapas.length) {
            toast.error(appConfigKey.keyMsjContinuarSolicitudAprobada);
            setShowLoading(false);
            return
        }

        let solicitudAnteriorAprobadoConDocumentos: any = listaSolicitudEtapas.filter((x: any) => x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.AprobadoConDocumentacion && x.ordenProceso === 1);
        let solicitudConAutonomia: any = listaSolicitudEtapas.filter((x: any) => x.identProceso === appConfigProcesoEtapaAnalisisCredito.EnviarCreditoAutonomia);

        if (solicitudAnteriorAprobadoConDocumentos.length > 0 && solicitudConAutonomia.length === 0) {
            toast.error(appConfigKey.keyMsjContinuarSolicitudAprobadaConDocumentos);
            setShowLoading(false);
            return
        }

        let listaSolicitudEvaluacionCrediticiaEnviada = listaSolicitudEtapas.filter((x: any) => x.identEtapa === appConfigEtapaAnalisisCredito.EvaluacionCrediticia &&
            x.identEstadoProceso === appConfigEstadosProcesoEtapaAnalisisCredito.Enviado)
        if (listaSolicitudEvaluacionCrediticiaEnviada.length < 0) {
            toast.error(appConfigKey.keyMsjContinuarSolicitud);
            setShowLoading(false);
            return
        }


        const response: any = await General.GetSolicitudExcepcionLiberacionInsert(requestReleaseException);
        if (!response) {
            toast.error("Ocurrió un error con el servicio para derivar a excepción la solicitud.");
        } else {

            if (requestReleaseException.idEstadoResultadoExcepcion === appConfigResultadoLiberacion.Observado) {

                let esAprobadoAutomatico: boolean = listaSolicitudEtapas.filter((x: any) => x.identDecisionMotor === appConfigDecisionMotor.AprobadoAutomatico).length > 0;
                let esZonaGris: boolean = listaSolicitudEtapas.filter((x: any) => x.identDecisionMotor === appConfigDecisionMotor.ZonaGris).length > 0;
                let esRechazado: boolean = listaSolicitudEtapas.filter((x: any) => x.identDecisionMotor === appConfigDecisionMotor.Rechazado).length > 0;

                let solicitudEtapasRequest: any = [];

                let SolicitudEtapasInsertRequest: any = {
                    identSolicitud: Number(solicitud.idSolicitud),
                    identUsuarioCreador: appSecurityConfiguration.SessionUserId
                }
                if (esAprobadoAutomatico) {

                    solicitudEtapasRequest = [];
                    solicitudEtapasRequest.push({
                        identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
                        identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
                        identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
                        ordenProceso: 1
                    });

                    SolicitudEtapasInsertRequest.identDecisionMotor = appConfigDecisionMotor.AprobadoAutomatico
                    SolicitudEtapasInsertRequest.solicitudEtapasRequest = solicitudEtapasRequest;
                }
                if (esZonaGris) {

                    solicitudEtapasRequest = [];

                    solicitudEtapasRequest.push({
                        identEtapa: appConfigEtapaAnalisisCredito.EvaluacionCrediticia,
                        identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarEvaluacion,
                        identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
                        ordenProceso: 1
                    });

                    solicitudEtapasRequest.push({
                        identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
                        identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
                        identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
                        ordenProceso: 2
                    });

                    SolicitudEtapasInsertRequest.identDecisionMotor = appConfigDecisionMotor.ZonaGris
                    SolicitudEtapasInsertRequest.solicitudEtapasRequest = solicitudEtapasRequest;
                }
                if (esRechazado) {

                    solicitudEtapasRequest = [];

                    solicitudEtapasRequest.push({
                        identEtapa: appConfigEtapaAnalisisCredito.EvaluacionCrediticia,
                        identProceso: appConfigProcesoEtapaAnalisisCredito.SolicitarExcepcion,
                        identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
                        ordenProceso: 1
                    });

                    solicitudEtapasRequest.push({
                        identEtapa: appConfigEtapaAnalisisCredito.ValidacionDocumental,
                        identProceso: appConfigProcesoEtapaAnalisisCredito.EnviarValidacion,
                        identEstadoProceso: appConfigEstadosProcesoEtapaAnalisisCredito.Pendiente,
                        ordenProceso: 2
                    });

                    SolicitudEtapasInsertRequest.identDecisionMotor = appConfigDecisionMotor.Rechazado
                    SolicitudEtapasInsertRequest.solicitudEtapasRequest = solicitudEtapasRequest;

                }

                await General.GetSolicitudEtapasInsert(SolicitudEtapasInsertRequest)

            }


            let mensaje = "Se [DATA] liberación solicitud con excepción.";

            toast.success(mensaje.replace('[DATA]', acciones.find((x: any) => x.id === requestReleaseException.idEstadoResultadoExcepcion).description));
            setRequestReleaseException({
                identSolicitudExcepcion: 0,
                comentario: "",
                idEstadoResultadoExcepcion: 0,
                identUsuarioCreador: 0
            });
            props.handleTabsPositionReset();
            props.handleClose();
        }
        setShowLoading(false);
    }

    const InitDefaultRequestException = async () => {
        setShowLoading(true);
        await getObtenerAcciones();
        await GetSolicitudExcepcionLiberacionByIdSolicitud(solicitud.idSolicitud);
        setEsModoLecturaForm(solicitud.idEstadoSolicitud !== appConfigKey.keyIdEstadoSolicitudPorLiberarExcepcion)
        setRequestReleaseException({
            ...requestReleaseException,
            identSolicitudExcepcion: solicitud.identSolicitudExcepcion,
            comentario: "",
            idEstadoResultadoExcepcion: 0,
            identUsuarioCreador: Number(
                localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0
            )
        });
        let tempDataSustentoException: any = [{
            fecha: solicitud.fechaExcepcion,
            usuario: solicitud.usuarioExcepcion,
            comentario: solicitud.sustento,
        }];
        setDataSustentoException(tempDataSustentoException);
        setShowLoading(false);
    }
    useEffect(() => {
        if (props?.open) {
            InitDefaultRequestException();
        }
    }, [props?.open]);

    return (
        <>
            {showLoading && <LoadingProgress />}
            <Dialog style={{ zIndex: 1000 }} open={props.open} sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '15px',
                    alignContent: 'center',
                    width: '950px'
                }
            }}>
                {showLoading && <LoadingProgress />}
                <div className="release-exception-dialog-content">
                    <button className="btn btn-sm change-rate-close-btn" onClick={props.handleClose}>
                        <CloseIcon />
                    </button>
                    <div className='release-exception-dialog-title'>Liberar Excepción</div>

                    <div className="cardObjets" style={{ fontWeight: 'bold' }}>
                        Comercial - Sustento Excepción
                    </div>
                    <div className='container-table'>
                        <TableContainer component={Paper}
                            sx={{
                                '& .MuiTable-root': {
                                    backgroundColor: '#E1E1E1',
                                }
                            }}>
                            <Table sx={
                                {
                                    '& .MuiTableHead-root': {
                                        backgroundColor: '#E1E1E1',
                                    },
                                    '& .MuiTableCell-root': {
                                        fontSize: '11px',
                                        padding: '7px',
                                    }
                                }
                            }>
                                <TableHead>
                                    <TableRow className='change-rate-table-container'>
                                        <TableCell style={{ minWidth: '70px' }}><strong>FECHA</strong></TableCell>
                                        <TableCell style={{ minWidth: '150px' }}><strong>USUARIO</strong></TableCell>
                                        <TableCell style={{ minWidth: '250px' }}><strong>COMENTARIO</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataSustentoException?.map((row: any, index: any) => (
                                        <TableRow
                                            key={index} className={
                                                index % 2 === 0 ? 'change-rate-table-impar' : 'change-rate-table-par'
                                            }>
                                            <TableCell style={{ fontSize: '12px' }}>{row?.fecha}</TableCell>
                                            <TableCell style={{ fontSize: '12px' }}>{row?.usuario}</TableCell>
                                            <TableCell style={{ fontSize: '12px' }}>{row?.comentario}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                    <div className="cardObjets" style={{ fontWeight: 'bold' }}>
                        Resultado Excepción
                    </div>

                    <div className="cardTabs">
                        <div className="row g-2 mb-2 align-items-center">
                            <div className="col-lg-12 cardObjets">
                                <div className="row">
                                    <div className="col-lg-6">
                                        <FormControl>
                                            <FormLabel>
                                                Acción  <span style={{ color: "red" }}> * </span>
                                            </FormLabel>
                                            <Select
                                                name="resultado"
                                                className={"select-input-card"}
                                                disabled={esModoLecturaForm}
                                                onChange={(event: any, newValue: any) => {
                                                    const { value } = event.target;
                                                    setRequestReleaseException({
                                                        ...requestReleaseException,
                                                        idEstadoResultadoExcepcion: value,
                                                    });

                                                }}
                                                value={requestReleaseException.idEstadoResultadoExcepcion}
                                                IconComponent={(props) => (
                                                    <KeyboardArrowDown
                                                        {...props}
                                                        className={`material-icons ${props.className}`}
                                                    />
                                                )}
                                            >
                                                <MenuItem value={0}>Seleccione</MenuItem>
                                                {acciones.map((data: any, index: any) => (
                                                    <MenuItem value={data.id} key={index}>
                                                        {data.description}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>

                                    </div>
                                    <div className="col-lg-6">
                                        <br></br>
                                        <Button
                                            size="md"
                                            color="danger"
                                            disabled={requestReleaseException.idEstadoResultadoExcepcion <= 0 || esModoLecturaForm}
                                            style={{ marginRight: "35px" }}
                                            sx={{ borderRadius: 24, width: 1 }}
                                            onClick={() => GuardarLiberarExcepcionModal()}
                                        >
                                            {requestReleaseException.idEstadoResultadoExcepcion <= 0 ? 'Aceptar' :
                                                acciones.find((x: any) => x.id === requestReleaseException.idEstadoResultadoExcepcion).description} Evaluación
                                        </Button>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-lg-12">
                                        <FormControl>
                                            <FormLabel>
                                                Comentario <span style={{ color: "red" }}> * </span>
                                            </FormLabel>
                                            <Textarea
                                                name="comentario"
                                                placeholder="Ingresar texto"
                                                disabled={esModoLecturaForm}
                                                value={requestReleaseException.comentario}
                                                minRows={3}
                                                onChange={(event: any) => {
                                                    setRequestReleaseException({
                                                        ...requestReleaseException,
                                                        comentario: event.target.value,
                                                    });
                                                }}
                                            />
                                        </FormControl>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='container-table' style={{ maxHeight: "150px" }}>
                        <TableContainer component={Paper}
                            sx={{
                                '& .MuiTable-root': {
                                    backgroundColor: '#E1E1E1',
                                }
                            }}>
                            <Table sx={
                                {
                                    '& .MuiTableHead-root': {
                                        backgroundColor: '#E1E1E1',
                                    },
                                    '& .MuiTableCell-root': {
                                        fontSize: '11px',
                                        padding: '7px',
                                    }
                                }
                            }>
                                <TableHead>
                                    <TableRow className='change-rate-table-container'>
                                        <TableCell style={{ minWidth: '70px' }}><strong>FECHA</strong></TableCell>
                                        <TableCell style={{ minWidth: '100px' }}><strong>USUARIO</strong></TableCell>
                                        <TableCell style={{ minWidth: '100px' }}><strong>RESULTADO</strong></TableCell>
                                        <TableCell style={{ minWidth: '200px' }}><strong>COMENTARIO</strong></TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {dataExceptionResult?.map((row: any, index) => (
                                        <TableRow
                                            key={index} className={
                                                index % 2 === 0 ? 'change-rate-table-impar' : 'change-rate-table-par'
                                            }>
                                            <TableCell style={{ fontSize: '12px' }}>{row?.fecha}</TableCell>
                                            <TableCell style={{ fontSize: '12px' }}>{row?.usuario}</TableCell>
                                            <TableCell style={{ fontSize: '12px' }}>{row?.resultado}</TableCell>
                                            <TableCell style={{ fontSize: '12px' }}>{row?.comentario}</TableCell>
                                        </TableRow>
                                    ))}

                                </TableBody>
                            </Table>
                        </TableContainer>
                    </div>
                </div>
            </Dialog >
        </>);

}
export default ReleaseExceptionDialog;