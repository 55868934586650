import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormLabel from "@mui/joy/FormLabel";

const CardDebts = (props: any) => {
  const { debts } = props;

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-10 textLabelHeadboard">
          <span className="fw-bold">CAPACIDAD DE ENDEUDAMIENTO</span>
        </div>
        <div className="col-lg-4">
          <span className="fw-bold"> </span>
        </div>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-4 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>CFM</FormLabel>
              <FormLabel>{debts.cfm}</FormLabel>
            </LocalizationProvider>
          </div>
          <div className="col-lg-4 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>CFC</FormLabel>
              <FormLabel>{debts.cfc}</FormLabel>
            </LocalizationProvider>
          </div>
        </div>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-4 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>CUOTA S/</FormLabel>
              <FormLabel>{debts.cuotasS}</FormLabel>
            </LocalizationProvider>
          </div>
          <div className="col-lg-4 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>CUOTA $</FormLabel>
              <FormLabel>{debts.cuotasD}</FormLabel>
            </LocalizationProvider>
          </div>
        </div>        
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-4 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>MEN S/</FormLabel>
              <FormLabel>{debts.menS}</FormLabel>
            </LocalizationProvider>
          </div>
          <div className="col-lg-4 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>MEN $</FormLabel>
              <FormLabel>{debts.menD}</FormLabel>
            </LocalizationProvider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardDebts;