import React, { useEffect, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { Button, Table } from "@mui/joy";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { IconButton, Tooltip } from "@mui/material";
import GeneralHelper from "../../../helpers/GeneralHelper";
import { DateInput, NumberInput, SelectInput, SimpleInput, TextInput } from "../15.10.-base/elements/inputs";
import { ObtenerListas } from "../15.10.-base/obtenerServicios";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SimbolAdd from "@mui/icons-material/Add";
import Save from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";
import { appConfigTipoPersonaTercera, appDefaultValues, appSecurityConfiguration } from "../../../../config/Config";
import TimeHelper from "../../../helpers/TimeHelper";
import RequestManager from "../../../services/origination/wapirequest.service";
import { Dropdown } from "react-bootstrap";
import CustomDivToggle from "../15.10.-base/elements/customDivToggle/CustomDivToggle";
import { BsThreeDots, BsSearch } from "react-icons/bs";
import { toast } from "sonner";
import { DatosGrupoEconomico, DatosGrupoEconomicoDefault } from "../15.10.-base/interfaces";
import MasterManager from "../../../services/origination/wapimaster.service";
import { AxiosError } from "axios";

export const DatosGrupo = (props: any) => {
    const [listMagnitud, setListMagnitud] = React.useState<any>([])
    const [listIndResidencia, setListIndResidencia] = React.useState<any>([])
    const [listIndVinculacion, setListIndVinculacion] = React.useState<any>([])
    const [listTipoDocumento, setListTipoDocumento] = useState<any[]>([]);
    const [listPaises, setListPaises] = useState<any[]>([]);
    const [listEstadoCivil, setListEstadoCivil] = useState([]);
    const [listProfesion, setListProfesion] = useState<any[]>([]);
    const [listGenero, setListGenero] = useState([]);
    const [dataGrupoEconomico, setDataGrupoEconomico] = useState<DatosGrupoEconomico>(DatosGrupoEconomicoDefault);
    const [dataListGrupoEconomico, setDataListGrupoEconomico] = useState<DatosGrupoEconomico[]>([]);
    const [chkRequerido, setChkRequerido] = React.useState<boolean>(false);
    const [chkGrupoEconomico, setChkGrupoEconomico] = React.useState<boolean>(false);
    const [chkHabilitado, setChkHabilitado] = React.useState<boolean>(false);
    const [chkUnidadDecision, setChkUnidadDecision] = React.useState<boolean>(false);
    const [flgShow, setFlgShow] = useState<boolean>(false);  
    const [listActividadEconomica, setListActividadEconomica] = useState([]);
    const [longNumber, setLongNumber] = useState<any>(8);

    const InitListas = async () => {
      await ObtenerListas.ActividadEconomica().then(response => {
        setListActividadEconomica(response);
      });
      // await ObtenerListas.IdentidadesMagnitud().then(response => {
      //     setListMagnitud(response);
      // });
      await ObtenerListas.TipoDocumento().then(response => {
          setListTipoDocumento(response);
      });
      await ObtenerListas.Pais().then(response =>{
          setListPaises(response)
      });
      await ObtenerListas.EstadoCiviles().then(response =>{
          setListEstadoCivil(response)
      });
      await ObtenerListas.Profesion().then(response =>{
          setListProfesion(response)
      });
      await ObtenerListas.IdentidadesGenero().then(response =>{
        setListGenero(response);
      });
    };
    const ObtenerMagnitudEmpresarial = async () => {
      var body = {
        "v_LIST_IDENT_TABLA": "1098"
      };
      let listMagnitudEmpresarial: any = [];
      var response: any = await MasterManager.GetMnTablaGenericaDefinicion(body)
        .then((response: any) => {
          response.data.content.forEach((element: any) => {
            listMagnitudEmpresarial.push({ id: element.genericA_IDENT_CAMPO, description: element.genericA_DESCM_CAMPO });
          })
          setListMagnitud(listMagnitudEmpresarial);
        })
    }
    const ObtenerIndicadorResidencia = async () => {
      var body = {
        "v_LIST_IDENT_TABLA": "1099"
      };
      let listIndResidencia: any = [];
      var response: any = await MasterManager.GetMnTablaGenericaDefinicion(body)
        .then((response: any) => {
          response.data.content.forEach((element: any) => {
            listIndResidencia.push({ id: element.genericA_IDENT_CAMPO, description: element.genericA_DESCM_CAMPO });
          })
          setListIndResidencia(listIndResidencia);
        })
    }
    const ObtenerIndicadorVinculacion = async () => {
      var body = {
        "v_LIST_IDENT_TABLA": "1100"
      };
      let listIndVinculacion: any = [];
      var response: any = await MasterManager.GetMnTablaGenericaDefinicion(body)
        .then((response: any) => {
          response.data.content.forEach((element: any) => {
            listIndVinculacion.push({ id: element.genericA_IDENT_CAMPO, description: element.genericA_DESCM_CAMPO });
          })
          setListIndVinculacion(listIndVinculacion);
        })
    }
    const onClickNuevo = () => {
      setFlgShow(true);
      setDataGrupoEconomico(DatosGrupoEconomicoDefault);
    }
    const onClickCancelar = () => {
      setFlgShow(false);
      setDataGrupoEconomico(DatosGrupoEconomicoDefault);
    }
    const GuardarGrupo = async () => {
      // const idGenero = dataGrupoEconomico.idGenero.trim();
      let resultado;
      let bodyInsert = {
        "identInformeComercial": dataGrupoEconomico.identInformeComercial == 0 ? props.dataInformeComercial.identInformeComercial : dataGrupoEconomico.identInformeComercial,
        "idGrupoEconomico": dataGrupoEconomico.idGrupoEconomico,
        "descGrupoEconomico": dataGrupoEconomico.descGrupoEconomico,
        "idTipoDocumento": dataGrupoEconomico.idTipoDocumento,
        "descTipoDocumento": dataGrupoEconomico.descTipoDocumento,
        "nroDocumento": dataGrupoEconomico.nroDocumento,
        "razonSocial": dataGrupoEconomico.razonSocial,
        "nombres": dataGrupoEconomico.nombres,
        "apellidoPaterno": dataGrupoEconomico.apellidoPaterno,
        "apellidoMaterno": dataGrupoEconomico.apellidoMaterno,
        "apellidoCasada": dataGrupoEconomico.apellidoCasada,
        "idMagnitudEmpresarial": dataGrupoEconomico.idMagnitudEmpresarial,
        "magnitudEmpresarial": dataGrupoEconomico.magnitudEmpresarial,
        "idSectorEconomico": dataGrupoEconomico.idSectorEconomico,
        "sectorEconomico": dataGrupoEconomico.sectorEconomico,
        "idIndicadorResidencia": dataGrupoEconomico.idIndicadorResidencia,
        "indicadorResidencia": dataGrupoEconomico.indicadorResidencia,
        "idNacionalidadFuncionamiento": dataGrupoEconomico.idNacionalidadFuncionamiento,
        "nacionalidadFuncionamiento": dataGrupoEconomico.nacionalidadFuncionamiento,
        "idIndicadorVinculacion": dataGrupoEconomico.idIndicadorVinculacion,
        "indicadorVinculacion": dataGrupoEconomico.indicadorVinculacion,
        "direccion": dataGrupoEconomico.direccion,
        "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId),
        "habilitado": dataGrupoEconomico.habilitado,
        "unidadDecision": dataGrupoEconomico.unidadDecision
      }
      let bodyUpdate = {
        "identInformeComercialGrupoEconomico": dataGrupoEconomico.identInformeComercialGrupoEconomico,
        "identInformeComercial": dataGrupoEconomico.identInformeComercial,
        "idGrupoEconomico": dataGrupoEconomico.idGrupoEconomico,
        "descGrupoEconomico": dataGrupoEconomico.descGrupoEconomico,
        "idTipoDocumento": dataGrupoEconomico.idTipoDocumento,
        "descTipoDocumento": dataGrupoEconomico.descTipoDocumento,
        "nroDocumento": dataGrupoEconomico.nroDocumento,
        "razonSocial": dataGrupoEconomico.razonSocial,
        "nombres": dataGrupoEconomico.nombres,
        "apellidoPaterno": dataGrupoEconomico.apellidoPaterno,
        "apellidoMaterno": dataGrupoEconomico.apellidoMaterno,
        "apellidoCasada": dataGrupoEconomico.apellidoCasada,
        "idMagnitudEmpresarial": dataGrupoEconomico.idMagnitudEmpresarial,
        "magnitudEmpresarial": dataGrupoEconomico.magnitudEmpresarial,
        "idSectorEconomico": dataGrupoEconomico.idSectorEconomico,
        "sectorEconomico": dataGrupoEconomico.sectorEconomico,
        "idIndicadorResidencia": dataGrupoEconomico.idIndicadorResidencia,
        "indicadorResidencia": dataGrupoEconomico.indicadorResidencia,
        "idNacionalidadFuncionamiento": dataGrupoEconomico.idNacionalidadFuncionamiento,
        "nacionalidadFuncionamiento": dataGrupoEconomico.nacionalidadFuncionamiento,
        "idIndicadorVinculacion": dataGrupoEconomico.idIndicadorVinculacion,
        "indicadorVinculacion": dataGrupoEconomico.indicadorVinculacion,
        "direccion": dataGrupoEconomico.direccion,
        "identUsuarioModificador": String(appSecurityConfiguration.SessionUserId),
        "habilitado": dataGrupoEconomico.habilitado,
        "unidadDecision": dataGrupoEconomico.unidadDecision
      }

      try {
        const response = dataGrupoEconomico.identInformeComercialGrupoEconomico == 0 ? 
        await RequestManager.MnInformeComercialGrupoEconomicoRegistroInsert(bodyInsert) : 
        await RequestManager.MnInformeComercialGrupoEconomicoRegistroUpdate(bodyUpdate);

        if (response.status === 200) {
          resultado = response.data.isValid;
          if (resultado === true) {
            if(dataGrupoEconomico.identInformeComercialGrupoEconomico == 0)
                {toast.success("Registro guardado correctamente", { duration: 5000 });}
            else{toast.success("Registro actualizado correctamente.", { duration: 5000 });}
          }
          else{
            toast.error("Ocurrió un error", { duration: 5000 });
          }
        };
        CargaInicial();

        setFlgShow(false);
        setDataGrupoEconomico(DatosGrupoEconomicoDefault);        
      } catch (error: AxiosError | any) {            
        if (error.response && error.response.data.status === 400 && error.response.data.errors) {
            const validationErrors = error.response.data.errors;
            if (validationErrors) {
                for (const [field, messages] of Object.entries(validationErrors)) {                            
                    let message = messages as string[]; // Type assertion
                    toast.error(`${field}: ${message}`, { duration: 5000 });
                }
            } else {
                toast.error("Ocurrió un error", { duration: 5000 });
            }
        }
    }           
}
  
  const CargaInicial = async () => {
      const response = await RequestManager.GetInformeComercialGrupoEconomicoRegistro({ "identInformeComercial": props.dataInformeComercial.identInformeComercial });
      if (response.status === 200) {
        setDataListGrupoEconomico(response.data.content);
      }
      console.log(dataListGrupoEconomico);
  }
  
  const handleClickViewDataGrupo = async (value: any) =>  {
      setFlgShow(true);
      const response = await RequestManager.GetMnInformeComercialGrupoEconomicoRegistroById({ "IdentInformeComercialGrupoEconomico": value });
      if (response.status === 200) {
          setDataGrupoEconomico(response.data.content);
      }
  }
  
  const handleClickDeleteGrupo = async (value: any) =>  {
      setFlgShow(false);
      setDataGrupoEconomico(DatosGrupoEconomicoDefault);
      const response = await RequestManager.MnInformeComercialGrupoEconomicoRegistroDelete({ "identInformeComercialPersona": value });
      if (response.status === 200) {
          console.log(response);
          setDataListGrupoEconomico(response.data.content);
          CargaInicial();
      }
  }
  
  const changeValueTipoDocumento = (e: any) => {
      let id = e.target.value as number;
      let text = '';
      if (id > 0) {
        for (let i = 0; i < listTipoDocumento.length; i++) {
          if (listTipoDocumento[i].id === id) {
              text = listTipoDocumento[i].description;
              break;
          }
        }
      }
      setDataGrupoEconomico({ ...dataGrupoEconomico, idTipoDocumento: id, descTipoDocumento: text,nroDocumento: appDefaultValues.StringEmpty });
      if(id === 172 || id === 173){
          setLongNumber(12);
      }
      else if(id === 174){
          setLongNumber(20);
      }
      else{
          setLongNumber(8)
      }
  }
  
  const changeValueMagnitud = (e: any) => {
    let id = e.target.value as number;
    let text = '';
    if (id > 0) {
      for (let i = 0; i < listMagnitud.length; i++) {
        if (listMagnitud[i].id === id) {
            text = listMagnitud[i].description;
            break;
        }
      }
    }
    setDataGrupoEconomico({ ...dataGrupoEconomico, idMagnitudEmpresarial: id, magnitudEmpresarial: text });
  }

  const changeValuePaisFuncionamiento = (e: any) => {
      let id = e.target.value as number;
      let text = '';
      if (id > 0) {
        for (let i = 0; i < listPaises.length; i++) {
          if (listPaises[i].id === id) {
              text = listPaises[i].description;
              break;
          }
        }
      }
      setDataGrupoEconomico({ ...dataGrupoEconomico, idNacionalidadFuncionamiento: id, nacionalidadFuncionamiento: text});
  }
  
  const changeValuePaisResidencia = (e: any) => {
      let id = e.target.value as number;
      let text = '';
      if (id > 0) {
        for (let i = 0; i < listPaises.length; i++) {
          if (listPaises[i].id === id) {
              text = listPaises[i].description;
              break;
          }
        }
      }
      // setDataGrupoEconomico({ ...dataGrupoEconomico, idPaisResidencia: id, descPaisResidencia: text});
  }
  
  const changeValueCargo = (e: any) => {
    let id = e.target.value as number;
    let text = '';
    if (id > 0) {
      for (let i = 0; i < listProfesion.length; i++) {
        if (listProfesion[i].id === id) {
            text = listProfesion[i].description;
            break;
        }
      }
    }
    // setDataGrupoEconomico({ ...dataGrupoEconomico, idCargo: id, descCargo: text});
  }
    
    useEffect(() => {
      CargaInicial();
      ObtenerIndicadorResidencia();
      ObtenerIndicadorVinculacion();
      ObtenerMagnitudEmpresarial();
      InitListas();
  }, [props]);

    return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>XII. DATOS GRUPO ECONÓMICO</AccordionSummary>
            <AccordionDetails>
              <>
              <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets align-top">
                        <Table borderAxis="none" className="style-table align-top">
                            <thead>
                                <tr>
                                <th style={{ fontSize: "12px", width: "10%", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>UND DE DECISIÓN</th>
                                <th style={{ fontSize: "12px", width: "10%", backgroundColor: "#E1E1E1", }}>GRUPO ECONÓMICO</th>
                                <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>TDI</th>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>N° DE DOC.</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>APELLIDO PATERNO</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>APELLIDO MATERNO</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>PRIMER NOMBRE O RAZÓN SOCIAL</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>PAÍS DE NACIONALIDAD</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>UNIDAD DE DECISIÓN</th>
                                <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>ESTADO</th>
                                <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>ACCIONES</th>
                                {/* <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>APORTE %</th> */}
                                </tr>
                            </thead>
                        <tbody>
                        {
                            dataListGrupoEconomico.length > 0 && dataListGrupoEconomico.map((row: any, index: number) => {
                            return (
                            <tr key={row.identInformeComercialPersona}>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.undDecision}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descGrupoEconomico}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descTipoDocumento}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.nroDocumento}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.apellidoPaterno}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.apellidoMaterno}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {(row.idTipoDocumento === 174 ? row.razonSocial : row.nombres)}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.nacionalidadFuncionamiento}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.unidadDecision}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.estado}</td>
                            {/* <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.AportePorcentaje}</td> */}
                            <td>
                                <Dropdown key={row.identInformeComercialGrupoEconomico}>
                                  <Dropdown.Toggle
                                    as={CustomDivToggle}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <BsThreeDots />
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                          onClick={(e: any) =>
                                            handleClickViewDataGrupo(
                                              row.identInformeComercialGrupoEconomico
                                            )
                                          }
                                        >
                                          Editar
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                          onClick={(e: any) =>
                                            handleClickDeleteGrupo(
                                              row.identInformeComercialGrupoEconomico
                                            )
                                          }
                                          disabled={props.isDisabled}
                                        >
                                          Eliminar
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                            </td>
                            </tr>
                                )
                            })
                        }
                        </tbody>
                        </Table>
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets bottom-right">
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<SimbolAdd />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            // disabled={props.disabled}
                            disabled={props.isDisabled}
                            onClick={onClickNuevo}>Nuevo
                        </Button>
                    </div>
                </div>  
              { flgShow && <div>     
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-4 cardObjets">
                        <FormLabel>Pertenece a algún grupo económico</FormLabel>
                        <Switch
                          color={chkGrupoEconomico ? "success" : "neutral"}
                          checked={chkGrupoEconomico}
                          disabled={props.isDisabled}
                          onChange={(event: any) => {
                            setChkGrupoEconomico(event.target.checked);
                            setDataGrupoEconomico({ ...dataGrupoEconomico, descGrupoEconomico: "" })
                          }}
                          slotProps={{
                            track: {
                              children: (
                                <React.Fragment>
                                  <Typography
                                    component="span"
                                    level="inherit"
                                    sx={{ ml: "10px" }}
                                  >
                                    Si
                                  </Typography>
                                  <Typography
                                    component="span"
                                    level="inherit"
                                    sx={{ mr: "8px" }}
                                  >
                                    No
                                  </Typography>
                                </React.Fragment>
                              ),
                            },
                          }}
                          sx={{
                            "--Switch-thumbSize": "27px",
                            "--Switch-trackWidth": "64px",
                            "--Switch-trackHeight": "31px",
                          }}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <TextInput
                            title={"Grupo económico"}
                            placeholder="Ingresar"
                            value={dataGrupoEconomico.descGrupoEconomico}
                            disabled={!chkGrupoEconomico || props.isDisabled}
                            onChange={(event: any) => setDataGrupoEconomico({ ...dataGrupoEconomico, descGrupoEconomico: event.target.value })}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <FormLabel>Unidad decisión</FormLabel>
                        <Switch
                          color={chkUnidadDecision ? "success" : "neutral"}
                          checked={chkUnidadDecision}
                          disabled={props.isDisabled}
                          onChange={(event: any) => {
                          setChkUnidadDecision(event.target.checked);
                          setDataGrupoEconomico({ ...dataGrupoEconomico, unidadDecision: event.target.value })
                      }}
                      // disabled={esModoLecturaScoringTitular}
                      slotProps={{
                        track: {
                          children: (
                            <React.Fragment>
                              <Typography
                                component="span"
                                level="inherit"
                                sx={{ ml: "10px" }}
                              >
                                Si
                              </Typography>
                              <Typography
                                component="span"
                                level="inherit"
                                sx={{ mr: "8px" }}
                              >
                                No
                              </Typography>
                            </React.Fragment>
                              ),
                            },
                          }}
                          sx={{
                            "--Switch-thumbSize": "27px",
                            "--Switch-trackWidth": "64px",
                            "--Switch-trackHeight": "31px",
                          }}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <FormLabel>Habilitado</FormLabel>
                        <Switch
                          color={chkHabilitado ? "success" : "neutral"}
                          checked={chkHabilitado}
                          disabled={props.isDisabled}
                          onChange={(event: any) => {
                          setChkHabilitado(event.target.checked);
                          setDataGrupoEconomico({ ...dataGrupoEconomico, habilitado: event.target.value });
                      }}
                      // disabled={esModoLecturaScoringTitular}
                      slotProps={{
                        track: {
                          children: (
                            <React.Fragment>
                              <Typography
                                component="span"
                                level="inherit"
                                sx={{ ml: "10px" }}
                              >
                                Si
                              </Typography>
                              <Typography
                                component="span"
                                level="inherit"
                                sx={{ mr: "8px" }}
                              >
                                No
                              </Typography>
                            </React.Fragment>
                              ),
                            },
                          }}
                          sx={{
                            "--Switch-thumbSize": "27px",
                            "--Switch-trackWidth": "64px",
                            "--Switch-trackHeight": "31px",
                          }}
                        />
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-2 cardObjets">
                        <SelectInput
                            title={"Tipo de documento"}
                            options={listTipoDocumento}
                            value={dataGrupoEconomico.idTipoDocumento}
                            disabled={props.isDisabled}
                            onChange={(event: any) => {
                              changeValueTipoDocumento(event);
                            }}
                        />
                    </div>
                    {(dataGrupoEconomico.idTipoDocumento === 172 || dataGrupoEconomico.idTipoDocumento === 173) ?
                        <div className="col-lg-2 cardObjets">
                            <SimpleInput
                                title={"Nro. documento"}
                                placeholder=""
                                longNumber={longNumber}
                                required={true}
                                value={dataGrupoEconomico.nroDocumento.trim()}
                                disabled={props.isDisabled}
                                onChange={(event: any) => {
                                    setDataGrupoEconomico({ ...dataGrupoEconomico, nroDocumento: event.target.value});
                                }}
                        />
                        </div>
                        :
                        <div className="col-lg-2 cardObjets">
                        <NumberInput
                            title={"Nro. documento"}
                            placeholder=""
                            longNumber={longNumber}
                            required={true}
                            value={dataGrupoEconomico.nroDocumento.trim()}
                            disabled={props.isDisabled}
                            onChange={(event: any) => {
                                setDataGrupoEconomico({ ...dataGrupoEconomico, nroDocumento: event.target.value});
                            }}
                        />
                        </div>
                    }
                    <div className="col-lg-4 cardObjets">
                        <TextInput
                            title={"Nombre / Razón Social"}
                            placeholder="Ingresar nombre"
                            value={dataGrupoEconomico.idTipoDocumento === 174 ? dataGrupoEconomico.razonSocial : dataGrupoEconomico.nombres}
                            // disabled={readonlyForm}
                            disabled={props.isDisabled}
                            onChange={(event: any) => {
                              if(dataGrupoEconomico.idTipoDocumento === 174){
                                setDataGrupoEconomico({ ...dataGrupoEconomico, razonSocial: event.target.value, nombres: "-"});
                              }
                              else{
                                setDataGrupoEconomico({ ...dataGrupoEconomico, nombres: event.target.value, razonSocial: "-"});
                              }
                          }}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <TextInput
                            title={"Apellido Paterno"}
                            placeholder="Ingresar apellido"
                            value={dataGrupoEconomico.apellidoPaterno}
                            // disabled={readonlyForm}
                            disabled={props.isDisabled}
                            onChange={(event: any) => setDataGrupoEconomico({ ...dataGrupoEconomico, apellidoPaterno: event.target.value })}
                        />
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-4 cardObjets">
                        <TextInput
                            title={"Apellido Materno"}
                            placeholder="Ingresar apellido"
                            value={dataGrupoEconomico.apellidoMaterno}
                            // disabled={readonlyForm}
                            disabled={props.isDisabled}
                            onChange={(event: any) => setDataGrupoEconomico({ ...dataGrupoEconomico, apellidoMaterno: event.target.value })}
                        />
                    </div>     
                    <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            title={"Apellido de casada"}
                            placeholder="Ingresar"
                            required={false}
                            // value={dataGrupoEconomico.apell}
                            // disabled={readonlyForm}
                            // onChange={(event: any) => setDataGrupoEconomico({ ...dataGrupoEconomico, Nombres: event.target.value })}
                            disabled={props.isDisabled}
                            onChange={(event: any) => setDataGrupoEconomico({ ...dataGrupoEconomico, apellidoCasada: event.target.value })}
                       
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SelectInput
                            title={"Magnitud empresarial"}
                            options={listMagnitud}
                            value={dataGrupoEconomico.idMagnitudEmpresarial}
                            disabled={props.isDisabled}
                            onChange={(event: any, newValue: any) => {
                              changeValueMagnitud(event);
                            }}
                      />
                    </div>    
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-4 cardObjets">
                        <SelectInput
                            title={"Sector económico"}
                            options={listActividadEconomica}
                            value={dataGrupoEconomico.idSectorEconomico}
                          // disabled={readonlyForm}
                          disabled={props.isDisabled}
                          onChange={(event: any) => {
                              setDataGrupoEconomico({ ...dataGrupoEconomico, idSectorEconomico: event.target.value});
                            }}
                      />
                    </div>  
                    <div className="col-lg-4 cardObjets">
                        <SelectInput
                            title={"Indicador residencia"}
                            options={listIndResidencia}
                            value={dataGrupoEconomico.idIndicadorResidencia}
                          // disabled={readonlyForm}
                          disabled={props.isDisabled}
                          onChange={(event: any, newValue: any) => {
                            setDataGrupoEconomico({ ...dataGrupoEconomico, idIndicadorResidencia: newValue });
                          }}
                        />
                    </div>  
                    <div className="col-lg-4 cardObjets">
                        <SelectInput
                            title={"Nacionalidad o funcionamiento"}
                            options={listPaises}
                            value={dataGrupoEconomico.idNacionalidadFuncionamiento}
                          // disabled={readonlyForm}
                          disabled={props.isDisabled}
                          onChange={(event: any) => {
                            changeValuePaisFuncionamiento(event);
                        }}
                        />
                    </div>       
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-4 cardObjets">
                        <SelectInput
                            title={"Indicador de vinculación"}
                            options={listIndVinculacion}
                            value={dataGrupoEconomico.idIndicadorVinculacion}
                          // disabled={readonlyForm}
                          disabled={props.isDisabled}
                          onChange={(event: any, newValue: any) => {
                            setDataGrupoEconomico({ ...dataGrupoEconomico, idIndicadorVinculacion: newValue });
                          }}
                        />
                    </div>    
                    <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            title={"Dirección"}
                            placeholder="Ejm. Av. La Marina"
                            value={dataGrupoEconomico.direccion}
                            // disabled={readonlyForm}
                            disabled={props.isDisabled}
                            onChange={(event: any) => setDataGrupoEconomico({ ...dataGrupoEconomico, direccion: event.target.value })}
                        />
                  </div>  
                </div>
                <div className="row g-2 mb-2 align-items-center ">
                  <div className="col-lg-12 cardObjets bottom-right">
                          <Button
                              size="md"
                              color="neutral"
                              variant="outlined"
                              endDecorator={<Save />}
                              sx={{ borderRadius: 24, width: 1/8 }}
                              disabled={props.isDisabled}
                              onClick={GuardarGrupo}
                              >Guardar
                          </Button>
                          <Button
                              size="md"
                              color="neutral"
                              variant="outlined"
                              endDecorator={<Cancel />}
                              sx={{ borderRadius: 24, width: 1/8 }}
                              // disabled={!chkRequerido}
                              onClick={onClickCancelar}
                              >Cancelar
                          </Button>
                  </div>
                </div>
                </div>}
              </>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>


   
    </div>
  );
};

export default DatosGrupo;