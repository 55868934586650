export const BanTotalComisiones = {
    PORTES: 251,
    GASTOS_NOTARIALES: 252,
    GASTOS_REGISTRALES: 253,
    DELIVERY_FIRMAS: 254
};

export const BanTotalListaComisiones = [
    { Codigo: 251, Comision: 'Portes' },
    { Codigo: 252, Comision: 'Gastos Notariales' },
    { Codigo: 253, Comision: 'Gastos Registrales' },
    { Codigo: 254, Comision: 'Delivery de firmas' },
];