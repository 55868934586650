import { useContext, useEffect, useState } from "react";
import { StepsContext } from "../../../3.10.-base/providers/StepsProvider";
import TitleCard from "../../../3.40.-SolicitudeStepOne/components/elements/titleCard";
import { MoneyInput, NumberDecimalInput, NumberInput, SelectInput, SimpleInput, SwitchInput } from "../../../3.40.-SolicitudeStepOne/components/elements/inputs";
import { StepFiveContext } from "../../../3.10.-base/providers";
import { appConfigKey, appDefaultValues } from "../../../../../../config/Config";
import { toast } from "sonner";
import { DatosAdicionalesTitularEmpresaInterface, DatosAdicionalesTitularEmpresaInterfaceDefault } from "./interfaces";

const DatosAdicionalesTitularEmpresaCard = () => {

    const {
        clickNext,
        step,
        readonlyForm,
        viewModelStepFiveFinal,
    } = useContext(StepsContext);

    const {
        setNewDatosAdicionalesTitularEmpresaData,
        datosAdicionalesTitularEmpresaData,
    } = useContext(StepFiveContext);

    const [viewModel, setViewModel] = useState<DatosAdicionalesTitularEmpresaInterface>(DatosAdicionalesTitularEmpresaInterfaceDefault);

    useEffect(() => {
        if (viewModelStepFiveFinal !== null) {
            const { newDatosAdicionalesTitularEmpresaData } = viewModelStepFiveFinal;
            setViewModel(newDatosAdicionalesTitularEmpresaData);
        }
    }, [viewModelStepFiveFinal]);

    useEffect(() => {
        if (datosAdicionalesTitularEmpresaData) {
            setViewModel(datosAdicionalesTitularEmpresaData);
        }
    }, [datosAdicionalesTitularEmpresaData])

    useEffect(() => {
        if (clickNext !== 0 && step === 5) {
            setNewDatosAdicionalesTitularEmpresaData(viewModel);
        }
    }, [clickNext]);

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
            <TitleCard title={"DATOS ADICIONALES EMPRESA"} obligatorio={false} />
            <div className="row g-2 mb-2 align-items-center">
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-2 cardObjets">
                        <NumberInput
                            name={"codSBS"}
                            title={"Código SBS"}
                            placeholder="0"
                            value={viewModel.codSBS}
                            disabled={readonlyForm}
                            longNumber={15}
                            onChange={(event: any) => setViewModel({ ...viewModel, codSBS: event.target.value })}
                            required={false}
                        />
                    </div>
                    <div className="col-lg-2 cardObjets">
                        <MoneyInput
                            name={"capitalSocialActual"}
                            startDecorator={"S/"}
                            title={"Capital social actual"}
                            value={viewModel.capitalSocialActual}
                            disabled={readonlyForm}
                            required={true}
                            onChange={(event: any) => setViewModel({ ...viewModel, capitalSocialActual: event.target.value })}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <MoneyInput
                            name={"patrimonio"}
                            startDecorator={"S/"}
                            title={"Patrimonio"}
                            value={viewModel.patrimonio}
                            disabled={readonlyForm}
                            required={true}
                            onChange={(event: any) => setViewModel({ ...viewModel, patrimonio: event.target.value })}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <MoneyInput
                            name={"patrimonioPersonal"}
                            startDecorator={"S/"}
                            title={"Patrimonio personal a la fecha"}
                            value={viewModel.patrimonioPersonal}
                            disabled={readonlyForm}
                            required={true}
                            onChange={(event: any) => setViewModel({ ...viewModel, patrimonioPersonal: event.target.value })}
                        />
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            name={"sectorEconomico"}
                            title={"Sector económico"}
                            placeholder=""
                            value={viewModel.sectorEconomico}
                            disabled={readonlyForm}
                            required={false}
                            onChange={(event: any) => setViewModel({ ...viewModel, sectorEconomico: event.target.value })}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            name={"tipoCadenaProductiva"}
                            title={"Tipo cadena productiva"}
                            placeholder=""
                            value={viewModel.tipoCadenaProductiva}
                            disabled={readonlyForm}
                            required={true}
                            onChange={(event: any) => setViewModel({ ...viewModel, tipoCadenaProductiva: event.target.value })}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            name={"actividadComplementaria"}
                            title={"Actividades complementarias"}
                            placeholder=""
                            value={viewModel.actividadComplementaria}
                            disabled={readonlyForm}
                            required={false}
                            onChange={(event: any) => setViewModel({ ...viewModel, actividadComplementaria: event.target.value })}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DatosAdicionalesTitularEmpresaCard;