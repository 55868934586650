import { useEffect, useState } from "react";
import { NumberInput, SelectAutocompleteInput, SelectInput, SimpleInput } from "../../../../3.40.-SolicitudeStepOne/components/elements/inputs";
import TitleCard from "../../../../3.40.-SolicitudeStepOne/components/elements/titleCard";
import { ObtenerListas } from '../../../obtenerServicios';
import { General } from "../../../../3.10.-base/obtenerServicios";
import GeneralHelper from "../../../../../../helpers/GeneralHelper";

const TitularDatosLaboralesCard = (props: any) => {

    const {
        listActividadEconomica,
        listPais,
        listProfesion,
        data,
        empresaTitular,
        setTitularLaboralesFinal,
        clickSubmit
    } = props;

    const [listaProvinciasDepartamentos, setlistaProvinciasDepartamentos] = useState([]);
    const [listaCiudadesLocalidades, setlistaCiudadesLocalidades] = useState([]);
    const [listaBarriosColonias, setListaBarriosColonias] = useState([]);

    const getObtenerDepartamentos = (paisId: any) => {
        ObtenerListas.EstadosProvinciasDepartamentos(paisId).then(response => {
            setlistaProvinciasDepartamentos(response);
        });
    };

    const getObtenerCiudadesLocalidades = (paisId: any, departamentoId: any) => {
        ObtenerListas.CiudadesLocalidades(paisId, departamentoId).then(response => {
            setlistaCiudadesLocalidades(response);
        });
    };

    const getObtenerBarriosColonias = (paisId: any, departamentoId: any, provinciaId: any) => {
        ObtenerListas.BarriosColonias(paisId, departamentoId, provinciaId).then(response => {
            setListaBarriosColonias(response);
        });
    };

    const initListas = async () => {
        let departamentoId = 0;
        let provinciaId = 0;

        if (empresaTitular.idenT_UBIGEO !== 1) {
            departamentoId = GeneralHelper.ObtenerDepartamentoId(empresaTitular.idenT_UBIGEO);
            provinciaId = GeneralHelper.ObtenerProvinciaId(empresaTitular.idenT_UBIGEO);
        } else {
            departamentoId = 1;
            provinciaId = 1;
        }

        Promise.all([
            ObtenerListas.EstadosProvinciasDepartamentos(empresaTitular.idenT_PAIS),
            ObtenerListas.CiudadesLocalidades(empresaTitular.idenT_PAIS, departamentoId),
            ObtenerListas.BarriosColonias(empresaTitular.idenT_PAIS, departamentoId, provinciaId)
        ]).then(response => {
            
            const departamentos = response[0];
            const provincias = response[1];
            const distritos = response[2];

            setlistaProvinciasDepartamentos(departamentos);
            setlistaCiudadesLocalidades(provincias);
            setListaBarriosColonias(distritos);

        });

        setViewModel({ 
            ...viewModel, 
            identActividadEconomica: empresaTitular.iD_ACTIVIDAD_ECONOMICA,
            identPais: empresaTitular.idenT_PAIS,
            identDepartamentos: departamentoId,
            identProvincias: provinciaId,
            identDistritos: empresaTitular.idenT_UBIGEO,
            identCargoLaboral: data.idenT_CARGO_LABORAL,
            nombreVia: empresaTitular.descM_NOMBRE_VIA,
            numeroVia: empresaTitular.descC_NUMERO,
            intDptoPiso: empresaTitular.descC_INTERIOR_DEPARTAMENTO_PISO,
            lote: empresaTitular.descC_LOTE,
            nombreAgrupacion: empresaTitular.descM_NOMBRE_AGRUPACION,
            referencia: empresaTitular.descL_REFERENCIA,
            telefono: empresaTitular.descC_TELEFONO,
            anexo: empresaTitular.descC_ANEXOS,
            correo: empresaTitular.descC_CORREO,
            descripcionCargo: data.descL_DESCRIPCION_CARGO
        });
    }

    useEffect(() => {
        if (empresaTitular !== null && empresaTitular !== undefined) {
            initListas();
        }
        
    }, [empresaTitular]);

    useEffect(() => {
        if (clickSubmit !== 0) {

            const findPais: any = listPais.find((pais: any) => pais.id === viewModel.identPais);
            const descripcionPais = findPais?.description;

            const findCategoriaLaboral: any = listProfesion.find((profesion: any) => profesion.id === viewModel.identCargoLaboral);
            const descripcionCategoriaLaboral = findCategoriaLaboral?.description;

            const findActividadEconomica = listActividadEconomica.find((actividadEconomica: any) => actividadEconomica.id === viewModel.identActividadEconomica);
            const descripcionActividadEconomica = findActividadEconomica?.description;
            
            const findDepartamento: any = listaProvinciasDepartamentos.find((departamento: any) => departamento.id === viewModel.identDepartamentos);
            const descripcionDepartamento = findDepartamento?.description;

            const findProvincia: any = listaCiudadesLocalidades.find((provincia: any) => provincia.id === viewModel.identProvincias);
            const descripcionProvincia = findProvincia?.description;

            const findDistrito: any = listaBarriosColonias.find((distrito: any) => distrito.id === viewModel.identDistritos);
            const descripcionDistrito = findDistrito?.description;

            setTitularLaboralesFinal({
                ...viewModel,
                descripcionPais,
                descripcionCategoriaLaboral,
                descripcionActividadEconomica,
                empresaUbigeoDescripcion: `${descripcionDistrito} / ${descripcionProvincia} / ${descripcionDepartamento}`,
                empresaUbigeo: Number(viewModel.identDistritos),
            });
        }
    }, [clickSubmit]);

    const [viewModel, setViewModel] = useState({
        identActividadEconomica: 0,
        identPais: 0,
        identDepartamentos: 0,
        identProvincias: 0,
        identDistritos: 0,
        nombreVia: "",
        numeroVia: "",
        intDptoPiso: "",
        manzana: "",
        lote: "",
        nombreAgrupacion: "",
        referencia: "",
        telefono: "",
        anexo: "",
        correo: "",
        identCargoLaboral: 0,
        descripcionCargo: "",
    });

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
            <TitleCard title={"DATOS LABORALES DEL TITULAR"} obligatorio={true} />
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectAutocompleteInput
                        value={viewModel.identActividadEconomica}
                        name={"identActividadEconomica"}
                        title={"Actividad económica"}
                        options={listActividadEconomica}
                        required={true}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel,
                                identActividadEconomica: newValue
                            });
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectAutocompleteInput
                        value={viewModel.identPais}
                        name={"identPais"}
                        title={"País"}
                        options={listPais}
                        required={true}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel,
                                identPais: newValue,
                                identDepartamentos: 0,
                                identProvincias: 0,
                                identDistritos: 0
                            });
                            getObtenerDepartamentos(newValue);
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        value={viewModel.identDepartamentos}
                        name={"identDepartamentos"}
                        title={"Departamento"}
                        options={listaProvinciasDepartamentos}
                        required={true}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel,
                                identDepartamentos: newValue,
                                identProvincias: 0,
                                identDistritos: 0
                            });
                            getObtenerCiudadesLocalidades(
                                viewModel.identPais,
                                newValue
                            );
                        }}
                    />
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        value={viewModel.identProvincias}
                        name={"identProvincias"}
                        title={"Provincia"}
                        options={listaCiudadesLocalidades}
                        required={true}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel,
                                identProvincias: newValue,
                                identDistritos: 0
                            });
                            getObtenerBarriosColonias(
                                viewModel.identPais,
                                viewModel.identDepartamentos,
                                newValue
                            );
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        value={viewModel.identDistritos}
                        required={true}
                        name={"identDistritos"}
                        title={"Distrito"}
                        options={listaBarriosColonias}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel,
                                identDistritos: newValue
                            });
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        value={viewModel.nombreVia}
                        name={"nombreVia"}
                        title={"Nombre de vía"}
                        required={true}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                nombreVia: event.target.value
                            });
                        }}
                    />
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"numeroVia"}
                        title={"Número"}
                        value={viewModel.numeroVia}
                        required={true}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                numeroVia: event.target.value
                            });
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"intDptoPiso"}
                        title={"Int/Dpto/Piso"}
                        value={viewModel.intDptoPiso}
                        required={false}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                intDptoPiso: event.target.value
                            });
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"manzana"}
                        title={"Manzana"}
                        value={viewModel.manzana}
                        required={false}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                manzana: event.target.value
                            });
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"lote"}
                        title={"Lote"}
                        value={viewModel.lote}
                        required={false}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                lote: event.target.value
                            });
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"nombreAgrupacion"}
                        title={"Nombre agrupación"}
                        required={false}
                        value={viewModel.nombreAgrupacion}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                nombreAgrupacion: event.target.value
                            });
                        }}
                    />
                </div>
            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"referencia"}
                        title={"Referencia"}
                        value={viewModel.referencia}
                        required={false}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                referencia: event.target.value
                            });
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        name={"telefono"}
                        title={"Teléfono"}
                        value={viewModel.telefono}
                        longNumber={9}
                        required={true}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                telefono: event.target.value
                            });
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        name={"anexo"}
                        title={"Anexo"}
                        value={viewModel.anexo}
                        longNumber={9}
                        required={false}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                anexo: event.target.value
                            });
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"correo"}
                        title={"Correo"}
                        value={viewModel.correo}
                        required={true}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                correo: event.target.value
                            });
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        value={viewModel.identCargoLaboral}
                        name={"identCargoLaboral"}
                        title={"Cargo laboral"}
                        options={listProfesion}
                        required={true}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel,
                                identCargoLaboral: newValue
                            });
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        value={viewModel.descripcionCargo}
                        name={"descripcionCargo"}
                        title={"Descripción cargo"}
                        required={false}
                        onChange={(event: any) => {
                            setViewModel({
                                ...viewModel,
                                descripcionCargo: event.target.value
                            });
                        }}
                    />
                </div>
            </div>
        </div>
    );

};

export default TitularDatosLaboralesCard;