import { appDefaultValues } from "../../../../../../config/Config"

export interface DatosPersonalesDelTitular {
    FechaSolicitud: string | null,
    TipoPersonaId: number,
    TipoDocumentoId: number,
    NumeroDocumento: string,
    Nombres: string,
    ApellidoPaterno: string,
    ApellidoMaterno: string,
    EstadoCivilId: number,
    GeneroId: number,
    Celular: string,
    FechaNacimiento: string | null,
    Correo: string,
    Domicilio: string,
    MagnitudId: number,
    DocumentoComplementarioId: number,
    NumeroDocumentoComplementario: string,
    ActividadEconomicaId: number,
    ClientePep: boolean,
    SeparacionBienes: boolean,
    NacionalidadId: number,
    NivelEstudiosId: number,
    ProfesionId: number,
    NroHijos: number,
    NroDependientes: number,
    NombreCompletoContacto1: string,
    NombreCompletoContacto2: string,
    NumeroContacto1: string,
    NumeroContacto2: string,
    NombreVia: string,
    NumeroDomicilio: string,
    IntDptoPiso: string,
    Manzana: string,
    Lote: string,
    NombreAgrupacion: string,
    Referencia: string,
    TipoViviendaId: number,
    TiempoResidenciaId: number,
    CargoLaboralId: number,
    CargoLaboral: string,
    RangoVentaAnualId: number | null,
    RangoVentaAnual: string,
    FechaInicioActividad: string | null,
    GiroNegocioId: number | null,
    GiroNegocio: string,
}

export const DatosPersonalesDelTitularDefault: DatosPersonalesDelTitular = {
    FechaSolicitud: appDefaultValues.FechaDefault,
    TipoPersonaId: appDefaultValues.NumberDefault,
    TipoDocumentoId: appDefaultValues.NumberDefault,
    NumeroDocumento: appDefaultValues.StringEmpty,
    Nombres: appDefaultValues.StringEmpty,
    ApellidoPaterno: appDefaultValues.StringEmpty,
    ApellidoMaterno: appDefaultValues.StringEmpty,
    EstadoCivilId: appDefaultValues.NumberDefault,
    GeneroId: appDefaultValues.NumberDefault,
    Celular: appDefaultValues.StringEmpty,
    FechaNacimiento: appDefaultValues.FechaDefault,
    Correo: appDefaultValues.StringEmpty,
    Domicilio: appDefaultValues.StringEmpty,
    MagnitudId: appDefaultValues.NumberDefault,
    DocumentoComplementarioId: appDefaultValues.NumberDefault,
    NumeroDocumentoComplementario: appDefaultValues.StringEmpty,
    ActividadEconomicaId: appDefaultValues.NumberDefault,
    ClientePep: appDefaultValues.BooleanDefault,
    SeparacionBienes: appDefaultValues.BooleanDefault,
    NacionalidadId: appDefaultValues.NumberDefault,
    NivelEstudiosId: appDefaultValues.NumberDefault,
    ProfesionId: appDefaultValues.NumberDefault,
    NroHijos: appDefaultValues.NumberDefault,
    NroDependientes: appDefaultValues.NumberDefault,
    NombreCompletoContacto1: appDefaultValues.StringEmpty,
    NombreCompletoContacto2: appDefaultValues.StringEmpty,
    NumeroContacto1: appDefaultValues.StringEmpty,
    NumeroContacto2: appDefaultValues.StringEmpty,
    NombreVia: appDefaultValues.StringEmpty,
    NumeroDomicilio: appDefaultValues.StringEmpty,
    IntDptoPiso: appDefaultValues.StringEmpty,
    Manzana: appDefaultValues.StringEmpty,
    Lote: appDefaultValues.StringEmpty,
    NombreAgrupacion: appDefaultValues.StringEmpty,
    Referencia: appDefaultValues.StringEmpty,
    TipoViviendaId: appDefaultValues.NumberDefault,
    TiempoResidenciaId: appDefaultValues.NumberDefault,
    CargoLaboralId: appDefaultValues.NumberDefault,
    CargoLaboral: appDefaultValues.StringEmpty,
    RangoVentaAnualId: appDefaultValues.NumberDefault,
    RangoVentaAnual: appDefaultValues.StringEmpty,
    FechaInicioActividad: appDefaultValues.FechaDefault,
    GiroNegocioId: appDefaultValues.NumberDefault,
    GiroNegocio: appDefaultValues.StringEmpty,
}

