import { appConfigKey } from "../../../../../config/Config";
import { ValidacionSolicitudObservada } from "../../../3.-solicitude/3.10.-base/interfaces/solicitudObservadaInterface";

const ValidacionBPOHelper = {

    NewBlackListGesintel: (content: any): ValidacionSolicitudObservada | null => {
        let entityBlackListGesintel: ValidacionSolicitudObservada | null = null;
        let detalle: string[] = [];

        if (content?.miembrosObservados && content.miembrosObservados.length > 0) {

            //Obtener detalle de observación por miembro
            content.miembrosObservados.forEach((member: any) => {
                let tipoRelacionDescription = "";
                let observacionMensaje = "";

                member.tipoRelacionId = member.tipoRelacion;
                member.bantotalObservado = member.riesgosDeltaJson;
                member.gesintelObservado = member.plaftDeltaJson;

                if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionTitular) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionTitularDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionConyuge) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionConyugeDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionEmpresa) {
                    tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionEmpresaDescripcion2} ${member.numeroDocumento?.trim()}`;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionFiador) {
                    tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionFiadorDescripcion} ${member.numeroDocumento?.trim()}`;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionFiadorConyuge) {
                    tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionFiadorConyugeDescripcion} ${member.numeroDocumento?.trim()}`;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionCompraPara) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionCompraParaDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionCompraParaConyuge) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionCompraParaConyugeDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionEjecutivo) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionEjecutivoDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionConcesionario) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionConcesionarioDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionVendedor) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionVendedorDescripcion;
                }

                if (member.bantotalObservado && member.gesintelObservado) {
                    observacionMensaje = `${appConfigKey.keyMsjBaseNegativa} / ${appConfigKey.keyMsjBaseGesintel}`
                } else if (member.bantotalObservado) {
                    observacionMensaje = `${appConfigKey.keyMsjBaseNegativa}`
                } else if (member.gesintelObservado) {
                    observacionMensaje = `${appConfigKey.keyMsjBaseGesintel}`
                }

                detalle.push(`${tipoRelacionDescription} ${observacionMensaje}`)
            });

            if (content?.mensaje) {
                entityBlackListGesintel = {
                    mensaje: content.mensaje,
                    detalle: detalle,
                    observadoPlaft: content.plaftObservado ?? false,
                    observadoRiesgo: content.riesgosObservado ?? false
                }
            }
        }

        return entityBlackListGesintel;
    }
};

export default ValidacionBPOHelper;