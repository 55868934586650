import { useContext, useEffect, useState } from "react";
import { StepsContext } from "../../../3.10.-base/providers/StepsProvider";
import TitleCard from "../../../3.40.-SolicitudeStepOne/components/elements/titleCard";
import { NumberInput, SelectAutocompleteInput, SelectInput, SimpleInput, SwitchInput } from "../../../3.40.-SolicitudeStepOne/components/elements/inputs";
import { DatosAdicionalesTitularInterface, DatosAdicionalesTitularInterfaceDefault } from "./interfaces";
import { StepFiveContext } from "../../../3.10.-base/providers";
import { appConfigKey, appDefaultValues } from "../../../../../../config/Config";
import { toast } from "sonner";

const DatosAdicionalesTitularCard = () => {

    const {
        listPais,
        listNivelEducacion,
        listProfesion,
        clickNext,
        step,
        viewModelStepOneFinal,
        readonlyForm,
        viewModelStepFiveFinal,
    } = useContext(StepsContext);

    const {
        setNewDatosAdicionalesTitularData
    } = useContext(StepFiveContext);

    const [viewModel, setViewModel] = useState<DatosAdicionalesTitularInterface>(DatosAdicionalesTitularInterfaceDefault);

    useEffect(() => {
        if (viewModelStepOneFinal !== null && viewModelStepFiveFinal === null) {

            setViewModel({
                ...viewModel,
                identNacionalidad: viewModelStepOneFinal.newTitularData.NacionalidadId,
                identNivelEducacion: viewModelStepOneFinal.newTitularData.NivelEstudiosId,
                identProfesion: viewModelStepOneFinal.newTitularData.ProfesionId,
                nroHijos: viewModelStepOneFinal.newTitularData.NroHijos,
                nroDependientes: viewModelStepOneFinal.newTitularData.NroDependientes,
                separacionBienes: appDefaultValues.BooleanDefault,
                nomYApeContacto1: viewModelStepOneFinal.newTitularData.NombreCompletoContacto1,
                celular1: viewModelStepOneFinal.newTitularData.NumeroContacto1,
                nomYApeContacto2: viewModelStepOneFinal.newTitularData.NombreCompletoContacto2,
                celular2: viewModelStepOneFinal.newTitularData.NumeroContacto2,
                correo: viewModelStepOneFinal.newTitularData.Correo,
            });
            
        }
    }, [viewModelStepOneFinal]);

    useEffect(() => {
        if (viewModelStepFiveFinal !== null) {
            const { newDatosAdicionalesTitularData } = viewModelStepFiveFinal;
            setViewModel(newDatosAdicionalesTitularData);
        }
    }, [viewModelStepFiveFinal]);

    useEffect(() => {
        if (clickNext !== 0 && step === 5) {
            setNewDatosAdicionalesTitularData(viewModel);
        }
    }, [clickNext]);

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
            <TitleCard title={"DATOS DEL TITULAR"} obligatorio={false}/>
            <div className="row g-2 mb-2 align-items-center">
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-4 cardObjets">
                        <SelectAutocompleteInput
                            name={"identNacionalidad"}
                            title={"Nacionalidad"}
                            options={listPais}
                            value={viewModel.identNacionalidad}
                            disabled={readonlyForm}
                            onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identNacionalidad: newValue })}
                            required={false}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SelectInput
                            name={"identNivelEducacion"}
                            title={"Nivel de educación"}
                            options={listNivelEducacion}
                            value={viewModel.identNivelEducacion}
                            disabled={readonlyForm}
                            onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identNivelEducacion: newValue })}
                            required={false}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SelectInput
                            name={"identProfesion"}
                            title={"Profesión"}
                            options={listProfesion}
                            value={viewModel.identProfesion}
                            disabled={readonlyForm}
                            onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identProfesion: newValue })}
                            required={false}
                        />
                    </div>

                </div>
                <div className="row g-2 mb-2 align-items-center">

                    <div className="col-lg-2 cardObjets">
                        <NumberInput
                            name={"nroHijos"}
                            title={"Nro. Hijos"}
                            placeholder=""
                            value={viewModel.nroHijos}
                            disabled={readonlyForm}
                            longNumber={3}
                            onChange={(event: any) => setViewModel({ ...viewModel, nroHijos: event.target.value })}
                            required={false}
                        />
                    </div>

                    <div className="col-lg-2 cardObjets">
                        <NumberInput
                            name={"nroDependientes"}
                            title={"Nro. Dependientes"}
                            placeholder=""
                            value={viewModel.nroDependientes}
                            disabled={readonlyForm}
                            longNumber={3}
                            onChange={(event: any) => setViewModel({ ...viewModel, nroDependientes: event.target.value })}
                            required={false}
                        />
                    </div>

                    <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            name={"nomYApeContacto1"}
                            title={"Nombre y Apellido contacto 1"}
                            placeholder=""
                            value={viewModel.nomYApeContacto1}
                            disabled={readonlyForm}
                            onChange={(event: any) => setViewModel({ ...viewModel, nomYApeContacto1: event.target.value })}
                            required={false}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <NumberInput
                            name={"celular1"}
                            title={"Celular 1"}
                            placeholder=""
                            value={viewModel.celular1}
                            disabled={readonlyForm}
                            longNumber={9}
                            onChange={(event: any) => setViewModel({ ...viewModel, celular1: event.target.value })}
                            required={false}
                        />
                    </div>
                </div>

                <div className="row g-2 mb-2 align-items-center">

                    <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            name={"nomYApeContacto2"}
                            title={"Nombre y Apellido contacto 2"}
                            placeholder=""
                            value={viewModel.nomYApeContacto2}
                            disabled={readonlyForm}
                            onChange={(event: any) => setViewModel({ ...viewModel, nomYApeContacto2: event.target.value })}
                            required={false}
                        />

                    </div>
                    <div className="col-lg-4 cardObjets">
                        <NumberInput
                            name={"celular2"}
                            title={"Celular 2"}
                            placeholder=""
                            value={viewModel.celular2}
                            disabled={readonlyForm}
                            longNumber={9}
                            onChange={(event: any) => setViewModel({ ...viewModel, celular2: event.target.value })}
                            required={false}
                        />
                    </div>
                    <div className="col-lg-4 cardObjets">
                        <SimpleInput
                            name={"Correo"}
                            title={"Correo"}
                            placeholder=""
                            value={viewModel.correo}
                            disabled={readonlyForm}
                            onChange={(event: any) => setViewModel({ ...viewModel, correo: event.target.value })}
                            required={false}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DatosAdicionalesTitularCard;