import { appConfigKey } from "../../../../../config/Config";

class SolicitudPersonaObservadaHelper {

    static GetDetalleObservaciones(miembroList?: any[], mostrarMiembroLiberado: boolean = false): string[] {
        let detalleObservaciones: string[] = [];
        if (!miembroList || miembroList.length <= 0) return detalleObservaciones;

        if (!mostrarMiembroLiberado) {
            miembroList = miembroList.filter((member: any) => !member.liberadO_PLAFT || !member.liberadO_RIESGOS)
        }

        miembroList.forEach((member: any) => {
            let tipoRelacionDescription = "";
            let observacionMensaje = "";

            if (member.idenT_TIPO_RELACION == appConfigKey.KeyIdTipoRelacionTitular) {
                tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionTitularDescripcion;
            } else if (member.idenT_TIPO_RELACION == appConfigKey.KeyIdTipoRelacionConyuge) {
                tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionConyugeDescripcion;
            } else if (member.idenT_TIPO_RELACION == appConfigKey.KeyIdTipoRelacionEmpresa) {
                tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionEmpresaDescripcion2} ${member.codL_NUMERO_DOCUMENTO?.trim()}`;
            } else if (member.idenT_TIPO_RELACION == appConfigKey.KeyIdTipoRelacionFiador) {
                tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionFiadorDescripcion} ${member.codL_NUMERO_DOCUMENTO?.trim()}`;
            } else if (member.idenT_TIPO_RELACION == appConfigKey.KeyIdTipoRelacionFiadorConyuge) {
                tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionFiadorConyugeDescripcion} ${member.codL_NUMERO_DOCUMENTO?.trim()}`;
            } else if (member.idenT_TIPO_RELACION == appConfigKey.KeyIdTipoRelacionCompraPara) {
                tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionCompraParaDescripcion;
            } else if (member.idenT_TIPO_RELACION == appConfigKey.KeyIdTipoRelacionCompraParaConyuge) {
                tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionCompraParaConyugeDescripcion;
            } else if (member.idenT_TIPO_RELACION == appConfigKey.KeyIdTipoRelacionEjecutivo) {
                tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionEjecutivoDescripcion;
            } else if (member.idenT_TIPO_RELACION == appConfigKey.KeyIdTipoRelacionConcesionario) {
                tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionConcesionarioDescripcion;
            } else if (member.idenT_TIPO_RELACION == appConfigKey.KeyIdTipoRelacionVendedor) {
                tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionVendedorDescripcion;
            }

            if (member.listA_NEGRA_DELTA && member.gesinteL_DELTA) {
                observacionMensaje = `${appConfigKey.keyMsjBaseNegativa} / ${appConfigKey.keyMsjBaseGesintel}`
            } else if (member.listA_NEGRA_DELTA) {
                observacionMensaje = `${appConfigKey.keyMsjBaseNegativa}`
            } else if (member.gesinteL_DELTA) {
                observacionMensaje = `${appConfigKey.keyMsjBaseGesintel}`
            }

            detalleObservaciones.push(`${tipoRelacionDescription} ${observacionMensaje}`)
        });

        return detalleObservaciones
    }

}

export default SolicitudPersonaObservadaHelper;