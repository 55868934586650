import { appDefaultValues } from '../../../config/Config';

export interface HistoricoTea {
 fecha: string,
 teA_ACTUAL: string,
 teA_NUEVA: string,
 username: string,
 comentario: string,
}

export class clsHistoricoTeaDefault {
 fecha : string = appDefaultValues.StringEmpty;
 teA_ACTUAL : string = appDefaultValues.StringEmpty;
 teA_NUEVA : string = appDefaultValues.StringEmpty;
 username : string = appDefaultValues.StringEmpty;
 comentario : string = appDefaultValues.StringEmpty;
}

export interface ListaGastos {
descripcion: string,
importe: string,
}
   
export class clsListaGastosDefault {
descripcion : string = appDefaultValues.StringEmpty;
importe : string = appDefaultValues.StringEmpty;
}