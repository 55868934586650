import { useContext, useEffect, useState } from "react";
import FiadoresCard from "./components/FiadoresCard";
import ObservacionesCard from "./components/ObservacionesCard";
import TitularCompraParaProvider from "./providers/TitularCompraParaProvider";
import TitularDatosProvider from "./providers/TitularDatosProvider";
import { CompraParaSection, TitularSection } from "./sections";
import WindowHelper from "../../../helpers/WindowHelper";

const SolicitudeStepOne = () => {
  useEffect(() => {
    WindowHelper.ScrollToTop();
}, []);
  return (
    <div style={{ paddingBottom: '20px' }}>
      <TitularDatosProvider>
        <TitularSection />
      </TitularDatosProvider>
      <FiadoresCard />
      <TitularCompraParaProvider>
        <CompraParaSection />
      </TitularCompraParaProvider>
      <ObservacionesCard />
    </div>
  );
};

export default SolicitudeStepOne;

