import { Component } from "react";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

interface IObservedMemberListDetailProps {
  objObservedMemberListDetailProps: {
    name: string;
    rol: string;
    documentTypeName: string;
    documentNumber: string;
    statusPlaft: boolean;
    statusRisk: boolean;
    showNew: boolean;
    focus: boolean;
  };
  handleClick: any;
}

class ObservedMemberListDetail extends Component<
  IObservedMemberListDetailProps,
  any
> {
  constructor(props: any) {
    super(props);
  }

  render() {
    let item = this.props.objObservedMemberListDetailProps;
    return (
      <div
        className={`row m-1 p-3 mb-3 card-inner clickable ${item.focus ? "focus" : ""
          }`}
        onClick={() => {
          this.props.handleClick(item);
        }}
      >
        {item.showNew &&
          (<div className="col pb-2 text-error">
            <ReportProblemOutlinedIcon style={{ marginRight: 2, paddingBottom: 2 }} fontSize="medium" />
            <label className="align-middle">Nuevo</label>
          </div>
          )
        }
        <label className={item.focus ? "text-negrita" : ""}>
          {item.name} ({item.rol})
        </label>
        <label>
          {item.documentTypeName} {item.documentNumber}
        </label>
        <label className="text-negrita">
          {item.statusPlaft && item.statusRisk
            ? "Riesgos / PLAFT"
            : item.statusPlaft
              ? "PLAFT"
              : "Riesgos"}
        </label>
      </div>
    );
  }
}
export default ObservedMemberListDetail;
