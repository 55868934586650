import { appConfig } from "../../config/Config";

export const GetConfigApiMaster = async () => {

    let config: {
        headers: {
            Authorization: string;
        };
        data?: any;
    };

    const account = appConfig.msalInstance.getActiveAccount();

    if (!account) {
        alert('¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount.');
        return null;
    }

    try {

        const response = await appConfig.msalInstance.acquireTokenSilent({
            scopes: [`api://${appConfig.clientIDwapimaster}/.default`],
            account: account
        });

        config = {
            headers: {
                Authorization: 'Bearer ' + response.accessToken
            },
        };

    } catch (error) {
        //myowasp('ERROR EN LA AUTENTIFICACION CON EL SERVICIO MASTER.');
        return null;
    }

    return config;

};

export const GetConfigApiServiceProvider = async () => {

    let config = null;

    const account = appConfig.msalInstance.getActiveAccount();

    if (!account) {
        alert("¡Ninguna cuenta activa! Verifique que un usuario haya iniciado sesión y que se haya llamado a setActiveAccount.");
        return null;
    }

    try {

        const response = await appConfig.msalInstance.acquireTokenSilent({
            scopes: [`api://${appConfig.clientIDwapiservicesprovider}/.default`],
            account: account
        });
        
        config = {
            headers: {
                'Authorization': 'Bearer ' + response.accessToken
            },
        };

    } catch (error) {
        //myowasp('ERROR EN LA AUTENTIFICACION CON EL SERVICIO SERVICE PROVIDER.');
    }

    return config;

};