import { appConfigKey } from "../../../../../config/Config";
import GeneralHelper from "../../../../helpers/GeneralHelper";

const ValidacionStepFourHelper = {
    NewCronograma: (newCronograma: any) => {
        const {
            listaCronograma
        } = newCronograma;

        if (newCronograma.listaCronograma === null ||
            newCronograma.listaCronograma === undefined ||
            newCronograma.listaCronograma.length <= 0)
            return false;

        return true;
    },

    ModelicaMEM: (memValor: any, newCronograma: any, tieneDerivacionActivo: boolean) => {
        let mensajeModelica;
        let mensajes = [];

        let cuotaValor = 0;
        if (newCronograma?.listaCronograma && newCronograma?.listaCronograma?.length > 0) {
            let cuota = newCronograma.listaCronograma.find((c: any) => {
                const mes = Number(c?.fVence.split('/')[1]);
                return mes !== 1 && mes !== 8;
            });

            cuotaValor = cuota?.pagoTotal ? GeneralHelper.ParseNumber(cuota.pagoTotal) : 0;
        }

        if (memValor && memValor < cuotaValor) {
            let memAValorFormat = `${appConfigKey.keySimboloSoles} ${GeneralHelper.AgregarComasAMiles(memValor.toFixed(2))}`;
            let cuotaValorFormat = `${appConfigKey.keySimboloSoles} ${GeneralHelper.AgregarComasAMiles(cuotaValor.toFixed(2))}`;
            mensajes.push(`MEM ${memAValorFormat} es menor que la cuota ${cuotaValorFormat}`);
        }


        if (mensajes.length > 0) {
            if (tieneDerivacionActivo) {
                mensajes.unshift(appConfigKey.keyMsjModelicaObservado.replace("[CAMPO]", appConfigKey.keyMsjModelicaObservadoMEM));
            } else {
                mensajes.unshift(appConfigKey.keyMsjModelicaObservadoCuota.replace("[CAMPO]", appConfigKey.keyMsjModelicaObservadoMEM));
            }

            mensajeModelica = mensajes?.map((msg: any, index: number) => (
                <div key={index} style={{ marginBottom: index === 0 ? '10px' : '0' }}>
                    {(index > 0 ? '- Motivo: ' : '') + msg}
                </div>
            ));
        }

        return mensajeModelica;
    },
}
export default ValidacionStepFourHelper;