import { Fragment } from 'react';
import icoAlertWarning from './../../../../../assets/media/icons/ico_alert_warning.svg';
import CircularProgress from '@mui/joy/CircularProgress';

const AlertSolicitudObservadaCard = (props: any) => {

    return (
        <div className="border rounded-10 p-3 p-md-2 mb-4 mb-md-4 card body-alert-warning">
            <div className="row g-2 mb-2 item-alert-warning">
                <div className="col-lg-1 col-md-12 d-flex">
                    <img src={icoAlertWarning} alt="Alert Icon" />
                </div>
                <div className="col-lg-11 col-md-12 align-items-center">
                    <span>
                        <b>{props.mensaje}</b>
                        {props.detalle && props.detalle.map((item: string, index: number) => (
                            <Fragment key={index}>
                                <br />
                                {item}
                            </Fragment>
                        ))}
                        {!props.detalle &&
                            <div className="col text-center">
                                <br />
                                <CircularProgress size="sm" variant='plain' color="danger" />
                            </div>
                        }
                    </span>
                </div>
            </div>
        </div>
    );
};

export default AlertSolicitudObservadaCard;