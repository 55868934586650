import { Order } from "../interfaces/enhancedTableInterfaces";


const tableUtilHelper = {

    getComparator<Key extends keyof any>(
        order: Order,
        orderBy: Key
    ): (
        a: { [key in Key]: number | string },
        b: { [key in Key]: number | string }
    ) => number {
        return order === "desc"
            ? (a, b) => tableUtilHelper.descendingComparator(a, b, orderBy)
            : (a, b) => -tableUtilHelper.descendingComparator(a, b, orderBy);
    },

    descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    },

    labelDisplayedRows({
        from,
        to,
        count,
    }: {
        from: number;
        to: number;
        count: number;
    }) {
        return `${from}–${to} of ${count !== -1 ? count : `more than ${to}`}`;
    },

    stableSort<T>(
        array: readonly T[],
        comparator: (a: T, b: T) => number
    ) {
        const stabilizedThis = array.map((el, index) => [el, index] as [T, number]);
        stabilizedThis.sort((a, b) => {
            const order = comparator(a[0], b[0]);
            if (order !== 0) {
                return order;
            }
            return a[1] - b[1];
        });
        return stabilizedThis.map((el) => el[0]);
    },

    
}
export default tableUtilHelper;