import { Component } from "react";
import ReportProblemOutlinedIcon from '@mui/icons-material/ReportProblemOutlined';

interface IBlackListDetailProps {
  objBlackListDetailProps: {
    typeName: string;
    description: string;
    showNew: boolean;
  };
}

class BlackListDetail extends Component<IBlackListDetailProps, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const data = this.props.objBlackListDetailProps;
    return (
      <div className="col">
        <div className="col-lg-6 col-md-12 pb-2">
          <div className="row">
            <label className="text-negrita">
              Tipo {data.typeName} - Motivo
            </label>
            <div className="col">
              {data.showNew && <ReportProblemOutlinedIcon className="text-error" style={{ marginRight: 2 }} fontSize="small" />}
              <label className="align-middle">{data.description}</label>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
export default BlackListDetail;
