import { appConfigKey } from "../../../../config/Config";
import { ValidationQuatitionMemberStepOneDataInteface } from "../interfaces/validationQuatitionMember";

const ValidacionStepOneHelper = {

    NewBlackListGesintel: (blackListGesintel: any) => {
        let entityBlackListGesintel: any = {};

        if ((blackListGesintel.filter((x: any) => x.esObservadoPlaftYRiesgo).length > 0) ||
            (blackListGesintel.filter((x: any) => x.esObservadoRiesgos).length > 0 &&
                blackListGesintel.filter((x: any) => x.esObservadoPlaft).length > 0)) {
            entityBlackListGesintel = {
                mensaje: appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'PLAFT y RIESGOS'),
                esObservadoPlaftYRiesgo: true,
                esObservadoRiesgos: false,
                esObservadoPlaft: false
            }
            return entityBlackListGesintel;

        } else if (blackListGesintel.filter((x: any) => x.esObservadoRiesgos).length > 0) {
            entityBlackListGesintel = {
                mensaje: appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'RIESGOS'),
                esObservadoPlaftYRiesgo: false,
                esObservadoRiesgos: true,
                esObservadoPlaft: false
            }
            return entityBlackListGesintel;

        } else {
            entityBlackListGesintel = {
                mensaje: appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'PLAFT'),
                esObservadoPlaftYRiesgo: false,
                esObservadoRiesgos: false,
                esObservadoPlaft: true
            }
            return entityBlackListGesintel;
        }
    },

    NewModelicaData: (response: any) => {
        let mensajeModelica = "";

        if (!response) {
            mensajeModelica = 'Error en la consulta del Motor';
            return mensajeModelica;
        }

        const { data } = response;

        if (!data?.isValid || !data?.content) {
            mensajeModelica = 'Error en la consulta del Motor';
            return mensajeModelica;
        }

        const responseModelica = JSON.parse(data.content);
        if (!responseModelica.response?.Politica_Creditos) {
            mensajeModelica = responseModelica.response?.error?.message ?? 'Error en la consulta del Motor';
            return mensajeModelica;
        }

        return mensajeModelica;
    },

    ModelicaCMA: (response: any) => {
        let mensajeModelica;

        let jsonResponseModelica = JSON.parse(response?.data?.content);

        let detalleRechazo = jsonResponseModelica?.response?.Politica_Creditos?.motivo_Rechazo?.Detalle;
        if (detalleRechazo && detalleRechazo?.length > 0) {
            //CMA_0010: Base Negativa de Riesgos
            //RCH_0001: UPLA - PEP
            detalleRechazo = detalleRechazo.filter((r: any) => (r.codigo_CMA != "CMA_0010") && (r.codigo_CMA != "RCH_0001")
            );
        }
        if (detalleRechazo && detalleRechazo?.length > 0) {

            detalleRechazo.unshift({
                codigo_CMA: '',
                descripcion: appConfigKey.keyMsjModelicaObservado.replace("[CAMPO]", appConfigKey.keyMsjModelicaObservadoCMA),
                interviniente: ''
            });

            mensajeModelica = detalleRechazo?.map((det: any, index: number) => (
                <div key={index} style={{ marginBottom: index === 0 ? '10px' : '0' }}>
                    {(index > 0 ? '- Revise ' : '') + det?.descripcion + (det?.interviniente ? ' del ' + det?.interviniente : '')}
                </div>
            ));
        }

        return mensajeModelica;
    },

    BlackListGesintelAndModelicaCMA: (blackListGesintel: any, response: any): JSX.Element[] | undefined => {
        let mensaje;
        let entityBlackListGesintel = null;
        let entityModelica = null;
        let validacionMensajes: string[] = [];

        if (blackListGesintel && blackListGesintel.length > 0) {
            if ((blackListGesintel.filter((x: any) => x.esObservadoPlaftYRiesgo).length > 0) ||
                (blackListGesintel.filter((x: any) => x.esObservadoRiesgos).length > 0 &&
                    blackListGesintel.filter((x: any) => x.esObservadoPlaft).length > 0)) {
                entityBlackListGesintel = {
                    mensaje: appConfigKey.keyMsjClienteListaNegraYGesintelCotizacion.replace('[CAMPO]', 'PLAFT y RIESGOS'),
                    esObservadoPlaftYRiesgo: true,
                    esObservadoRiesgos: false,
                    esObservadoPlaft: false
                }
            } else if (blackListGesintel.filter((x: any) => x.esObservadoRiesgos).length > 0) {
                entityBlackListGesintel = {
                    mensaje: appConfigKey.keyMsjClienteListaNegraYGesintelCotizacion.replace('[CAMPO]', 'RIESGOS'),
                    esObservadoPlaftYRiesgo: false,
                    esObservadoRiesgos: true,
                    esObservadoPlaft: false
                }
            } else {
                entityBlackListGesintel = {
                    mensaje: appConfigKey.keyMsjClienteListaNegraYGesintelCotizacion.replace('[CAMPO]', 'PLAFT'),
                    esObservadoPlaftYRiesgo: false,
                    esObservadoRiesgos: false,
                    esObservadoPlaft: true
                }
            }

            //Obtener detalle de observación por miembro
            blackListGesintel.forEach((member: any) => {
                let tipoRelacionDescription = "";
                let observacionMensaje = "";

                if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionTitular) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionTitularDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionConyuge) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionConyugeDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionEmpresa) {
                    tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionEmpresaDescripcion2} ${member.numeroDocumento?.trim()}`;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionFiador) {
                    tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionFiadorDescripcion} ${member.numeroDocumento?.trim()}`;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionFiadorConyuge) {
                    tipoRelacionDescription = `${appConfigKey.KeyIdTipoRelacionFiadorConyugeDescripcion} ${member.numeroDocumento?.trim()}`;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionCompraPara) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionCompraParaDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionCompraParaConyuge) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionCompraParaConyugeDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionEjecutivo) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionEjecutivoDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionConcesionario) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionConcesionarioDescripcion;
                } else if (member.tipoRelacionId == appConfigKey.KeyIdTipoRelacionVendedor) {
                    tipoRelacionDescription = appConfigKey.KeyIdTipoRelacionVendedorDescripcion;
                }

                if (member.bantotalObservado && member.gesintelObservado) {
                    observacionMensaje = `${appConfigKey.keyMsjBaseNegativa} / ${appConfigKey.keyMsjBaseGesintel}`
                } else if (member.bantotalObservado) {
                    observacionMensaje = `${appConfigKey.keyMsjBaseNegativa}`
                } else if (member.gesintelObservado) {
                    observacionMensaje = `${appConfigKey.keyMsjBaseGesintel}`
                }

                validacionMensajes.push(`- ${tipoRelacionDescription} ${observacionMensaje}`)
            });
        }

        if (response?.data?.content) {
            let jsonResponseModelica = JSON.parse(response?.data?.content);

            let detalleRechazo = jsonResponseModelica?.response?.Politica_Creditos?.motivo_Rechazo?.Detalle;
            if (detalleRechazo && detalleRechazo?.length > 0) {
                //CMA_0010: Base Negativa de Riesgos
                //RCH_0001: UPLA - PEP
                detalleRechazo = detalleRechazo.filter((r: any) => (r.codigo_CMA != "CMA_0010") && (r.codigo_CMA != "RCH_0001")
                );
            }
            if (detalleRechazo && detalleRechazo?.length > 0) {
                detalleRechazo.forEach((det: any) => {
                    validacionMensajes.push(`- Revise ${det?.descripcion} ${det?.interviniente ? ' del ' + det?.interviniente : ''}`)
                });

                entityModelica = detalleRechazo
            }
        }

        if (entityBlackListGesintel && entityModelica) {
            //Observado por ambos
            validacionMensajes.unshift(`${entityBlackListGesintel.mensaje} y ${appConfigKey.keyMsjModelicaMensajeCMA}.`);
        } else if (entityBlackListGesintel) {
            //Observado por lista negra o gesintel
            validacionMensajes.unshift(`${entityBlackListGesintel.mensaje}.`);
        } else if (entityModelica) {
            //Observado por motor
            validacionMensajes.unshift(`${appConfigKey.keyMsjModelicaMensajeCMA}.`);
        }

        if (validacionMensajes.length > 0) {
            mensaje = validacionMensajes?.map((msg: string, index: number) => (
                <div key={index} style={{ marginBottom: index === 0 ? '10px' : '0' }}>
                    {msg}
                </div>
            ));
        }

        return mensaje;

    },

    GetMembersStepOneData: (
        state: any
    ) => {
        let validateMembers: ValidationQuatitionMemberStepOneDataInteface[] = []
        let idCotizacion = state?.quotationIdentificador;

        if (state?.nroDocumentoTitular) {
            let member = {
                IdCotizacion: idCotizacion,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionTitular,
                TipoDocumento: state.selectedTipoDocTitular,
                NumeroDocumento: state.nroDocumentoTitular
            } as ValidationQuatitionMemberStepOneDataInteface
            validateMembers.push(member);
        }

        if (state?.nroDocumentoConyuge && state?.selectedEstadoCivil == appConfigKey.keyIdEstadoCivilCasado) {
            let member = {
                IdCotizacion: idCotizacion,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionConyuge,
                TipoDocumento: state.selectedTipoDocTitularConyuge,
                NumeroDocumento: state.nroDocumentoConyuge
            } as ValidationQuatitionMemberStepOneDataInteface
            validateMembers.push(member);
        }

        if (state?.listOtrosIngresosTitular && state?.listOtrosIngresosTitular.length >= 1) {
            let member = {
                IdCotizacion: idCotizacion,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
                TipoDocumento: appConfigKey.keyIdRUC,
                NumeroDocumento: state.listOtrosIngresosTitular[0].nroRuc
            } as ValidationQuatitionMemberStepOneDataInteface
            validateMembers.push(member);

            if (state.listOtrosIngresosTitular.length >= 2) {
                const listaOtrosTrabajos = state.listOtrosIngresosTitular.slice(1);

                for (let rowOtroTrabajo of listaOtrosTrabajos) {
                    let member = {
                        IdCotizacion: idCotizacion,
                        TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
                        TipoDocumento: appConfigKey.keyIdRUC,
                        NumeroDocumento: rowOtroTrabajo.nroRuc
                    } as ValidationQuatitionMemberStepOneDataInteface
                    validateMembers.push(member);
                }
            }
        }

        if (state?.selectedEstadoCivil == appConfigKey.keyIdEstadoCivilCasado &&
            state?.listOtrosIngresosConyuge &&
            state?.listOtrosIngresosConyuge.length >= 1 &&
            state?.listOtrosIngresosConyuge[0].idCategoria != appConfigKey.keyIdSinCategoria) {
            let member = {
                IdCotizacion: idCotizacion,
                TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
                TipoDocumento: appConfigKey.keyIdRUC,
                NumeroDocumento: state.listOtrosIngresosConyuge[0].nroRuc
            } as ValidationQuatitionMemberStepOneDataInteface
            validateMembers.push(member);

            if (state.listOtrosIngresosConyuge.length >= 2) {
                const listaOtrosTrabajos = state.listOtrosIngresosConyuge.slice(1);
                for (let rowOtroTrabajo of listaOtrosTrabajos) {
                    let member = {
                        IdCotizacion: idCotizacion,
                        TipoRelacionId: appConfigKey.KeyIdTipoRelacionEmpresa,
                        TipoDocumento: appConfigKey.keyIdRUC,
                        NumeroDocumento: rowOtroTrabajo.nroRuc
                    } as ValidationQuatitionMemberStepOneDataInteface
                    validateMembers.push(member);
                }
            }
        }

        //Remover miembros repetidos
        // const claves = new Set<string>();
        // validateMembers = validateMembers.filter(m => {
        //     const clave = `${m.IdPersona}-${m.TipoRelacionId}-${m.TipoDocumento}-${m.NumeroDocumento}`;
        //     if (!claves.has(clave)) {
        //         claves.add(clave);
        //         return true;
        //     }
        //     return false;
        // });

        return validateMembers;
    }


};

export default ValidacionStepOneHelper;