import { appDefaultValues } from "../../../../config/Config"
// appDefaultValues.NumberDefault
// appDefaultValues.StringEmpty
// appDefaultValues.FechaDefault
export interface Combo {
    value: number,
    name: string
}
export const ComboDefault: Combo = {
    value: appDefaultValues.NumberDefault,
    name: appDefaultValues.StringEmpty
}
export interface DatosInformeComercial {
    identInformeComercial: number,
    identSolicitud: number,
    fechaRegistro: string | null,
    nroRuc: string,
    razonSocial: string,
    direccionComercialFabrica: string,
    direccionComercialOficina: string,
    direccionComercialLocal_1: string,
    direccionComercialLocal_2: string,
    telefono_1: string,
    telefono_2: string,
    fechaConstitucion: string,
    codigoCIIU: string,
    capitalSocialActual: string,
    codigoSBS: string,
    idGrupoEconomico: number,
    descGrupoEcnonomico: string,
    idSectorEconomico: number,
    descSectorEconomico: string,
    idActividadGiroPrincipal: number,
    actividadGiroPrincipal: string,
    idAuditorExterno: number,
    auditorExterno: string,
    idOtrasActividadesComplementarias: number,
    descOtrasActividadesComplementarias: string,
    idPatrimonio: number,
    descPatrimonio: string
}

export const DatosInformeComercialDefault: DatosInformeComercial = {
    identInformeComercial: appDefaultValues.NumberDefault,
    identSolicitud: appDefaultValues.NumberDefault,
    fechaRegistro: appDefaultValues.FechaDefault,
    nroRuc: appDefaultValues.StringEmpty,
    razonSocial: appDefaultValues.StringEmpty,
    direccionComercialFabrica: appDefaultValues.StringEmpty,
    direccionComercialOficina: appDefaultValues.StringEmpty,
    direccionComercialLocal_1: appDefaultValues.StringEmpty,
    direccionComercialLocal_2: appDefaultValues.StringEmpty,
    telefono_1: appDefaultValues.StringEmpty,
    telefono_2: appDefaultValues.StringEmpty,
    fechaConstitucion: appDefaultValues.StringEmpty,
    codigoCIIU: appDefaultValues.StringEmpty,
    capitalSocialActual: appDefaultValues.StringEmpty,
    codigoSBS: appDefaultValues.StringEmpty,
    idGrupoEconomico: appDefaultValues.NumberDefault,
    descGrupoEcnonomico: appDefaultValues.StringEmpty,
    idSectorEconomico: appDefaultValues.NumberDefault,
    descSectorEconomico: appDefaultValues.StringEmpty,
    idActividadGiroPrincipal: appDefaultValues.NumberDefault,
    actividadGiroPrincipal: appDefaultValues.StringEmpty,
    idAuditorExterno: appDefaultValues.NumberDefault,
    auditorExterno: appDefaultValues.StringEmpty,
    idOtrasActividadesComplementarias: appDefaultValues.NumberDefault,
    descOtrasActividadesComplementarias: appDefaultValues.StringEmpty,
    idPatrimonio: appDefaultValues.NumberDefault,
    descPatrimonio: appDefaultValues.StringEmpty
}

export interface DatosAccionistas {
    identInformeComercialPersona: number,
    identInformeComercial: number,
    identPersona: number,
    idTipoDocumento: number,
    descTipoDocumento: string,
    nroDocumento: string,
    rucPnn: string,
    razonSocial: string,
    nombres: string,
    apellidoPaterno: string,
    apellidoMaterno: string,
    idEstadoCivil: number,
    descEstadoCivil: string,
    fechaNacimiento: string | null,
    idGenero: string,
    descGenero: string,
    idPaisNacimiento: number,
    descPaisNacimiento: string,
    idPaisResidencia: number,
    descPaisResidencia: string,
    idDistrito: number,
    descDistrito: string,
    direccion: string,
    telefono1: string,
    telefono2: string,
    correo: string,
    idProfesion: number,
    descProfesion: string,
    idCargo: number,
    descCargo: string,
    idTipoPersonaInforme: number,
    descTipoPersonaInforme: string,
    estado: string,
    aporteSoles: number,
    aportePorcentaje: number
}

export const DatosAccionistasDefault: DatosAccionistas = {
    identInformeComercialPersona: appDefaultValues.NumberDefault,
    identInformeComercial: appDefaultValues.NumberDefault,
    identPersona: appDefaultValues.NumberDefault,
    idTipoDocumento: appDefaultValues.NumberDefault,
    descTipoDocumento: appDefaultValues.StringEmpty,
    nroDocumento: appDefaultValues.StringEmpty,
    rucPnn: appDefaultValues.StringEmpty,
    razonSocial: appDefaultValues.StringEmpty,
    nombres: appDefaultValues.StringEmpty,
    apellidoPaterno: appDefaultValues.StringEmpty,
    apellidoMaterno: appDefaultValues.StringEmpty,
    idEstadoCivil: appDefaultValues.NumberDefault,
    descEstadoCivil: appDefaultValues.StringEmpty,
    fechaNacimiento: appDefaultValues.FechaDefault,
    idGenero: '0',
    descGenero: appDefaultValues.StringEmpty,
    idPaisNacimiento: appDefaultValues.NumberDefault,
    descPaisNacimiento: appDefaultValues.StringEmpty,
    idPaisResidencia: appDefaultValues.NumberDefault,
    descPaisResidencia: appDefaultValues.StringEmpty,
    idDistrito: appDefaultValues.NumberDefault,
    descDistrito: appDefaultValues.StringEmpty,
    direccion: appDefaultValues.StringEmpty,
    telefono1: appDefaultValues.StringEmpty,
    telefono2: appDefaultValues.StringEmpty,
    correo: appDefaultValues.StringEmpty,
    idProfesion: appDefaultValues.NumberDefault,
    descProfesion: appDefaultValues.StringEmpty,
    idCargo: appDefaultValues.NumberDefault,
    descCargo: appDefaultValues.StringEmpty,
    idTipoPersonaInforme: appDefaultValues.NumberDefault,
    descTipoPersonaInforme: appDefaultValues.StringEmpty,
    estado: appDefaultValues.StringEmpty,
    aporteSoles: appDefaultValues.NumberDefault,
    aportePorcentaje: appDefaultValues.NumberDefault
}

export interface DatosDirectorio {
    IdDatosDirectorio: number,
    TipoDocumento: number,
    NumeroDocumento: string,
    RUC: number,
    Nombre: string,
    ApellidoPaterno: string,
    ApellidoMaterno: string,
    EstadoCivilId: number,
    FechaNacimiento: string | null,
    GeneroId: number,
    NacionalidadNacimientoId: number,
    NacionalidadNacimiento: string,
    NacionalidadResidenciaId: number,
    NacionalidadResidencia: string,
    Direccion: string,
    Telefono1: number,
    Telefono2: number,
    Correo: string,
    ProfesionId: number,
    Profesion: string,
    CargoId: number,
    Cargo: string
}

export const DatosDirectorioDefault: DatosDirectorio = {
    IdDatosDirectorio: appDefaultValues.NumberDefault,
    TipoDocumento: appDefaultValues.NumberDefault,
    NumeroDocumento: appDefaultValues.StringEmpty,
    RUC: appDefaultValues.NumberDefault,
    Nombre: appDefaultValues.StringEmpty,
    ApellidoPaterno: appDefaultValues.StringEmpty,
    ApellidoMaterno: appDefaultValues.StringEmpty,
    EstadoCivilId: appDefaultValues.NumberDefault,
    FechaNacimiento: appDefaultValues.FechaDefault,
    GeneroId: appDefaultValues.NumberDefault,
    NacionalidadNacimientoId: appDefaultValues.NumberDefault,
    NacionalidadNacimiento: appDefaultValues.StringEmpty,
    NacionalidadResidenciaId: appDefaultValues.NumberDefault,
    NacionalidadResidencia: appDefaultValues.StringEmpty,
    Direccion: appDefaultValues.StringEmpty,
    Telefono1: appDefaultValues.NumberDefault,
    Telefono2: appDefaultValues.NumberDefault,
    Correo: appDefaultValues.StringEmpty,
    ProfesionId: appDefaultValues.NumberDefault,
    Profesion: appDefaultValues.StringEmpty,
    CargoId: appDefaultValues.NumberDefault,
    Cargo: appDefaultValues.StringEmpty
}

export interface DatosGerenciaEjecutivos {
    IdDatosGerencia: number,
    TipoDocumento: number,
    NumeroDocumento: string,
    RUC: number,
    Nombre: string,
    ApellidoPaterno: string,
    ApellidoMaterno: string,
    EstadoCivilId: number,
    FechaNacimiento: string | null,
    GeneroId: number,
    NacionalidadNacimientoId: number,
    NacionalidadNacimiento: string,
    NacionalidadResidenciaId: number,
    NacionalidadResidencia: string,
    Direccion: string,
    Telefono1: number,
    Telefono2: number,
    Correo: string,
    ProfesionId: number,
    Profesion: string,
    CargoId: number,
    Cargo: string
}

export const DatosGerenciaEjecutivosDefault: DatosGerenciaEjecutivos = {
    IdDatosGerencia: appDefaultValues.NumberDefault,
    TipoDocumento: appDefaultValues.NumberDefault,
    NumeroDocumento: appDefaultValues.StringEmpty,
    RUC: appDefaultValues.NumberDefault,
    Nombre: appDefaultValues.StringEmpty,
    ApellidoPaterno: appDefaultValues.StringEmpty,
    ApellidoMaterno: appDefaultValues.StringEmpty,
    EstadoCivilId: appDefaultValues.NumberDefault,
    FechaNacimiento: appDefaultValues.FechaDefault,
    GeneroId: appDefaultValues.NumberDefault,
    NacionalidadNacimientoId: appDefaultValues.NumberDefault,
    NacionalidadNacimiento: appDefaultValues.StringEmpty,
    NacionalidadResidenciaId: appDefaultValues.NumberDefault,
    NacionalidadResidencia: appDefaultValues.StringEmpty,
    Direccion: appDefaultValues.StringEmpty,
    Telefono1: appDefaultValues.NumberDefault,
    Telefono2: appDefaultValues.NumberDefault,
    Correo: appDefaultValues.StringEmpty,
    ProfesionId: appDefaultValues.NumberDefault,
    Profesion: appDefaultValues.StringEmpty,
    CargoId: appDefaultValues.NumberDefault,
    Cargo: appDefaultValues.StringEmpty
}

export interface DatosParticipacion {
    identInformeComercial: number,
    identInformeComercialParticipacionPatrimonial: number,
    razonSocial: string,
    razonSocialOtrasEntidades: string,
    rucOtrasEntidades: number,
    aporteSoles: number,
    aportePorcentaje: number,
    codigoSbs: number,
    estado: string,
    ultimoModificador: string
}

export const DatosParticipacionDefault: DatosParticipacion = {
    identInformeComercial: appDefaultValues.NumberDefault,
    identInformeComercialParticipacionPatrimonial: appDefaultValues.NumberDefault,
    razonSocial: appDefaultValues.StringEmpty,
    razonSocialOtrasEntidades: appDefaultValues.StringEmpty,
    rucOtrasEntidades: appDefaultValues.NumberDefault,
    aporteSoles: appDefaultValues.NumberDefault,
    aportePorcentaje: appDefaultValues.NumberDefault,
    codigoSbs: appDefaultValues.NumberDefault,
    estado: appDefaultValues.StringEmpty,
    ultimoModificador: appDefaultValues.StringEmpty
}

export interface DatosCargos {
    identInformeComercialCargosOtrasEntidades: number,
    identInformeComercial: number
    razonSocial: string,
    razonSocialOtrasEntidades: string,
    rucOtrasEntidades: number,
    cargoFuncion: string,
    codigoSbs: number,
    estado: string
}

export const DatosCargosDefault: DatosCargos = {
    identInformeComercialCargosOtrasEntidades: appDefaultValues.NumberDefault,
    identInformeComercial: appDefaultValues.NumberDefault,
    razonSocial: appDefaultValues.StringEmpty,
    razonSocialOtrasEntidades: appDefaultValues.StringEmpty,
    rucOtrasEntidades: appDefaultValues.NumberDefault,
    cargoFuncion: appDefaultValues.StringEmpty,
    codigoSbs: appDefaultValues.NumberDefault,
    estado: appDefaultValues.StringEmpty
}

export interface DatosProduccion {
    identInformeComercialProduccion: number,
    identInformeComercial: number,
    productoLineas: string,
    porcentajeActividad: number,
    porcentajeMercado: number,
    empresaCompetidora: string,
    idTipoEmpresa: number,
    descTipoEmpresa: string,
    estado: string,
    ultimoModificador: string
}

export const DatosProduccionDefault: DatosProduccion = {
    identInformeComercialProduccion: appDefaultValues.NumberDefault,
    identInformeComercial: appDefaultValues.NumberDefault,
    productoLineas: appDefaultValues.StringEmpty,
    porcentajeActividad: appDefaultValues.NumberDefault,
    porcentajeMercado: appDefaultValues.NumberDefault,
    empresaCompetidora: appDefaultValues.StringEmpty,
    idTipoEmpresa: appDefaultValues.NumberDefault,
    descTipoEmpresa: appDefaultValues.StringEmpty,
    estado: appDefaultValues.StringEmpty,
    ultimoModificador: appDefaultValues.StringEmpty
}



export interface InformeComercialPersona {
    identInformeComercialPersona: number,
    identInformeComercial: number,
    identPersona: number,
    idTipoDocumento: number,
    descTipoDocumento: string,
    nroDocumento: string,
    rucPnn: string,
    razonSocial: string,
    nombres: string,
    apellidoPaterno: string,
    apellidoMaterno: string,
    idEstadoCivil: number,
    descEstadoCivil: string,
    fechaNacimiento: string | null,
    idGenero: string,
    descGenero: string,
    idPaisNacimiento: number,
    descPaisNacimiento: string,
    idPaisResidencia: number,
    descPaisResidencia: string,
    idDistrito: number,
    descDistrito: string,
    direccion: string,
    telefono1: string,
    telefono2: string,
    correo: string,
    idProfesion: number,
    descProfesion: string,
    idCargo: number,
    descCargo: string,
    idTipoPersonaInforme: number,
    descTipoPersonaInforme: string,
    indEstado: number,
    aporteSoles: number,
    aportePorcentaje: number
}

export const InformeComercialPersonaDefault: InformeComercialPersona = {
    identInformeComercialPersona: appDefaultValues.NumberDefault,
    identInformeComercial: appDefaultValues.NumberDefault,
    identPersona: appDefaultValues.NumberDefault,
    idTipoDocumento: appDefaultValues.NumberDefault,
    descTipoDocumento: appDefaultValues.StringEmpty,
    nroDocumento: appDefaultValues.StringEmpty,
    rucPnn: appDefaultValues.StringEmpty,
    razonSocial: appDefaultValues.StringEmpty,
    nombres: appDefaultValues.StringEmpty,
    apellidoPaterno: appDefaultValues.StringEmpty,
    apellidoMaterno: appDefaultValues.StringEmpty,
    idEstadoCivil: appDefaultValues.NumberDefault,
    descEstadoCivil: appDefaultValues.StringEmpty,
    fechaNacimiento: appDefaultValues.FechaDefault,
    idGenero: '0',
    descGenero: appDefaultValues.StringEmpty,
    idPaisNacimiento: appDefaultValues.NumberDefault,
    descPaisNacimiento: appDefaultValues.StringEmpty,
    idPaisResidencia: appDefaultValues.NumberDefault,
    descPaisResidencia: appDefaultValues.StringEmpty,
    idDistrito: appDefaultValues.NumberDefault,
    descDistrito: appDefaultValues.StringEmpty,
    direccion: appDefaultValues.StringEmpty,
    telefono1: appDefaultValues.StringEmpty,
    telefono2: appDefaultValues.StringEmpty,
    correo: appDefaultValues.StringEmpty,
    idProfesion: appDefaultValues.NumberDefault,
    descProfesion: appDefaultValues.StringEmpty,
    idCargo: appDefaultValues.NumberDefault,
    descCargo: appDefaultValues.StringEmpty,
    idTipoPersonaInforme: appDefaultValues.NumberDefault,
    descTipoPersonaInforme: appDefaultValues.StringEmpty,
    indEstado: appDefaultValues.NumberDefault,
    aporteSoles: appDefaultValues.NumberDefault,
    aportePorcentaje: appDefaultValues.NumberDefault
}

export interface DatosPropiedades {
    "identInformeComercialMaquinarias": number,
    "identInformeComercial": number,
    "descripcionUbicacion": string,
    "propioAlquiladoLeasing": string,
    "valorComercial": number,
    "gravamenMontoDolares": number,
    "gravamenEntidad": string,
    "aniosAntiguedad": number,
    "aniosVidaUtil": number,
    "estado": string,
    "ultimoModificador": string
}

export const DatosPropiedadesDefault: DatosPropiedades = {
    identInformeComercialMaquinarias: appDefaultValues.NumberDefault,
    identInformeComercial: appDefaultValues.NumberDefault,
    descripcionUbicacion: appDefaultValues.StringEmpty,
    propioAlquiladoLeasing: appDefaultValues.StringEmpty,
    valorComercial: appDefaultValues.NumberDefault,
    gravamenMontoDolares: appDefaultValues.NumberDefault,
    gravamenEntidad: appDefaultValues.StringEmpty,
    aniosAntiguedad: appDefaultValues.NumberDefault,
    aniosVidaUtil: appDefaultValues.NumberDefault,
    estado: appDefaultValues.StringEmpty,
    ultimoModificador: appDefaultValues.StringEmpty
}

export interface DatosProveedores {
    "identInformeComercialProveedores": number,
    "identInformeComercial": number,
    "materiaPrimaInsumos": string,
    "porcentajeSobreCompras": number,
    "mayoresProveedores": string,
    "porcentajeSobreProveedores": number,
    "porcentajeMe": number,
    "pagoModalidad": number,
    "importacionesGlobales": number,
    "pagoPlazoDias": number,
    "estado": string,
    "ultimoModificador": string
}

export const DatosProveedoresDefault: DatosProveedores = {
    identInformeComercialProveedores: appDefaultValues.NumberDefault,
    identInformeComercial: appDefaultValues.NumberDefault,
    materiaPrimaInsumos: appDefaultValues.StringEmpty,
    porcentajeSobreCompras: appDefaultValues.NumberDefault,
    mayoresProveedores: appDefaultValues.StringEmpty,
    porcentajeSobreProveedores: appDefaultValues.NumberDefault,
    porcentajeMe: appDefaultValues.NumberDefault,
    pagoModalidad: appDefaultValues.NumberDefault,
    importacionesGlobales: appDefaultValues.NumberDefault,
    pagoPlazoDias: appDefaultValues.NumberDefault,
    estado: appDefaultValues.StringEmpty,
    ultimoModificador: appDefaultValues.StringEmpty
}

export interface DatosVentas {
    "identInformeComercialClientes": number,
    "identInformeComercial": number,
    "razonSocial": string,
    "ruc": string,
    "porcentajeFacturacion": number,
    "porcentajeMe": number,
    "porcentajeContado": number,
    "porcentajeCredito": number,
    "creditoModalidad": string,
    "creditoPlazo": number,
    "estado": string,
    "ultimoModificador": string,
    "porcentajeSobreCompra": number
}

export const DatosVentasDefault: DatosVentas = {
    identInformeComercialClientes: appDefaultValues.NumberDefault,
    identInformeComercial: appDefaultValues.NumberDefault,
    razonSocial: appDefaultValues.StringEmpty,
    ruc: appDefaultValues.StringEmpty,
    porcentajeFacturacion: appDefaultValues.NumberDefault,
    porcentajeMe: appDefaultValues.NumberDefault,
    porcentajeContado: appDefaultValues.NumberDefault,
    porcentajeCredito: appDefaultValues.NumberDefault,
    creditoModalidad: appDefaultValues.StringEmpty,
    creditoPlazo: appDefaultValues.NumberDefault,
    estado: appDefaultValues.StringEmpty,
    ultimoModificador: appDefaultValues.StringEmpty,
    porcentajeSobreCompra: appDefaultValues.NumberDefault
}

export interface DatosClientes {
    "identInformeComercialClientes": number,
    "identInformeComercial": number,
    "razonSocial": string,
    "ruc": string,
    "porcentajeSobreCompra": number,
    "porcentajeFacturacion": number,
    "porcentajeMe": number,
    "porcentajeContado": number,
    "porcentajeCredito": number,
    "creditoModalidad": string,
    "creditoPlazo": number,
    "estado": string,
    "ultimoModificador": string
}

export const DatosClientesDefault: DatosClientes = {
    identInformeComercialClientes: appDefaultValues.NumberDefault,
    identInformeComercial: appDefaultValues.NumberDefault,
    razonSocial: appDefaultValues.StringEmpty,
    ruc: appDefaultValues.StringEmpty,
    porcentajeSobreCompra: appDefaultValues.NumberDefault,
    porcentajeFacturacion: appDefaultValues.NumberDefault,
    porcentajeMe: appDefaultValues.NumberDefault,
    porcentajeContado: appDefaultValues.NumberDefault,
    porcentajeCredito: appDefaultValues.NumberDefault,
    creditoModalidad: appDefaultValues.StringEmpty,
    creditoPlazo: appDefaultValues.NumberDefault,
    estado: appDefaultValues.StringEmpty,
    ultimoModificador: appDefaultValues.StringEmpty
}

export interface DatosGrupoEconomico {
    "identInformeComercialGrupoEconomico": number,
    "identInformeComercial": number,
    "idGrupoEconomico": number,
    "descGrupoEconomico": string,
    "idTipoDocumento": number,
    "descTipoDocumento": string,
    "nroDocumento": string,
    "razonSocial": string,
    "nombres": string,
    "apellidoPaterno": string,
    "apellidoMaterno": string,
    "idMagnitudEmpresarial": number,
    "magnitudEmpresarial": string,
    "idSectorEconomico": number,
    "sectorEconomico": string,
    "idIndicadorResidencia": number,
    "indicadorResidencia": string,
    "idNacionalidadFuncionamiento": number,
    "nacionalidadFuncionamiento": string,
    "idIndicadorVinculacion": number,
    "indicadorVinculacion": string,
    "direccion": string,
    "identUsuarioModificador": string,
    "apellidoCasada": string,
    "unidadDecision": number,
    "habilitado": number,
}

export const DatosGrupoEconomicoDefault: DatosGrupoEconomico = {
    identInformeComercialGrupoEconomico: appDefaultValues.NumberDefault,
    identInformeComercial: appDefaultValues.NumberDefault,
    idGrupoEconomico: appDefaultValues.NumberDefault,
    descGrupoEconomico: appDefaultValues.StringEmpty,
    idTipoDocumento: appDefaultValues.NumberDefault,
    descTipoDocumento: appDefaultValues.StringEmpty,
    nroDocumento: appDefaultValues.StringEmpty,
    razonSocial: appDefaultValues.StringEmpty,
    nombres: appDefaultValues.StringEmpty,
    apellidoPaterno: appDefaultValues.StringEmpty,
    apellidoMaterno: appDefaultValues.StringEmpty,
    idMagnitudEmpresarial: appDefaultValues.NumberDefault,
    magnitudEmpresarial: appDefaultValues.StringEmpty,
    idSectorEconomico: appDefaultValues.NumberDefault,
    sectorEconomico: appDefaultValues.StringEmpty,
    idIndicadorResidencia: appDefaultValues.NumberDefault,
    indicadorResidencia: appDefaultValues.StringEmpty,
    idNacionalidadFuncionamiento: appDefaultValues.NumberDefault,
    nacionalidadFuncionamiento: appDefaultValues.StringEmpty,
    idIndicadorVinculacion: appDefaultValues.NumberDefault,
    indicadorVinculacion: appDefaultValues.StringEmpty,
    direccion: appDefaultValues.StringEmpty,
    identUsuarioModificador: appDefaultValues.StringEmpty,
    apellidoCasada: appDefaultValues.StringEmpty,
    unidadDecision: appDefaultValues.NumberDefault,
    habilitado: appDefaultValues.NumberDefault,
}