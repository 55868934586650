import { appConfigKey } from "../../../../config/Config";
import { GenerarMnFormatoRegimenRequest, MnFormatoRegimenUpdateRequest } from "../../../models/RequestManager/solicitud-formato-regimen-request.model";
import MasterManager from "../../../services/origination/wapimaster.service";
import RequestManager from "../../../services/origination/wapirequest.service";

export const General = {

  ValidacionSolicitud: async (idenT_SOLICITUD: number, idenT_USUARIO: number, idenT_FLUJO: number) => {
    try {
      const response = await RequestManager.GetValidacionSolicitud({
        "idenT_SOLICITUD": idenT_SOLICITUD,
        "idenT_USUARIO": idenT_USUARIO,
        "idenT_FLUJO": idenT_FLUJO
      });
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE VALIDACIÓN SOLICITUD.');
      return null;
    }
  },

  GetMnEvaluacionSolicitudInsertLog: async (
    identSolicitud: number,
    tipoLlamada: number,
    jsonRequest: string,
    jsonResponse: string,
    identUsuarioCreador: number,
    identPerfil: number,
    tipoFlujo: number) => {
    try {
      const response = await MasterManager.GetMnEvaluacionSolicitudInsertLog({
        "identSolicitud": identSolicitud,
        "tipoLlamada": tipoLlamada,
        "jsonRequest": jsonRequest,
        "jsonResponse": jsonResponse,
        "identUsuarioCreador": identUsuarioCreador,
        "identPerfil": identPerfil,
        "tipoFlujo": tipoFlujo
      });
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE EVALUACIÓN SOLICITUD INSERTAR LOG.');
      return null;
    }
  },

  GetMnResultadoEvaluacion: async (idenT_SOLICITUD: number) => {
    try {
      const response: any = await MasterManager.GetMnResultadoEvaluacion(idenT_SOLICITUD)
      if (response?.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE RESULTADOS EVALUACIÓN SOLICITUD.');
      return null;
    }
  },
  
  ObtenerResultados: async () => {
    const lista: any = [];
    try {
      const response: any = await MasterManager.GetMnTablaGenericaDefinicion({
        "v_LIST_IDENT_TABLA": appConfigKey.keyIdResultado
      });

      if (response.status !== 200) {
        return lista;
      }
      const { content } = response.data;
      if (content) {
        content.forEach((element: any) => {
          lista.push({ id: element.genericA_IDENT_CAMPO, label: element.genericA_DESCM_CAMPO });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER RESULTADOS.');
    }

    return lista
  },

  ObtenerDestinoCredito: async () => {
    const lista: any = [];
    try {
      const response: any = await MasterManager.GetMnTablaGenericaDefinicion({
        "v_LIST_IDENT_TABLA": appConfigKey.keyIdResumenDestinoCredito
      });

      if (response.status !== 200) {
        return lista;
      }
      const { content } = response.data;
      if (content) {
        content.forEach((element: any) => {
          lista.push({ id: element.genericA_VALO_CAMPO, label: element.genericA_DESCM_CAMPO });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER RESULTADOS.');
    }

    return lista
  }

  ,

  ObtenerTipoResultados: async (tablaId: string) => {
    const lista: any = [];
    try {
      const response: any = await MasterManager.GetMnTablaGenericaDefinicion({
        "v_LIST_IDENT_TABLA": tablaId
      });

      if (response.status !== 200) {
        return lista;
      }
      const { content } = response.data;
      if (content) {
        content.forEach((element: any) => {
          lista.push({ id: element.genericA_IDENT_CAMPO, label: element.genericA_DESCM_CAMPO });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER RESULTADOS.');
    }

    return lista
  },

  ObtenerTipoCreditos: async () => {
    const lista: any = [];
    try {
      const response: any = await MasterManager.GetMnTablaGenericaDefinicion({
        "v_LIST_IDENT_TABLA": appConfigKey.keyIdTipoCredito
      });

      if (response.status !== 200) {
        return lista;
      }
      const { content } = response.data;
      if (content) {
        content.forEach((element: any) => {
          lista.push({ id: element.genericA_IDENT_CAMPO, label: element.genericA_DESCM_CAMPO, detail: element.detallE_CAMPO });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER RESULTADOS.');
    }

    return lista
  }
  ,

  ObtenerSubtipoCredito: async () => {
    const lista: any = [];
    try {
      const response: any = await MasterManager.GetMnTablaGenericaDefinicion({
        "v_LIST_IDENT_TABLA": appConfigKey.keyIdSubtipoCredito
      });

      if (response.status !== 200) {
        return lista;
      }
      const { content } = response.data;
      if (content) {
        content.forEach((element: any) => {
          lista.push({ id: element.genericA_IDENT_CAMPO, label: element.genericA_DESCM_CAMPO });
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER RESULTADOS.');
    }

    return lista
  },

  ObtenerResultadoEvaluacion: async (solicitudId: number) => {
    const lista: any = [];
    try {
      const response: any = await MasterManager.GetMnResultadoEvaluacion(solicitudId);

      if (response.status !== 200) {
        return lista;
      }
      const { content } = response.data;
      if (content) {
        content.forEach((element: any) => {
          lista.push(element);
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER RESULTADOS.');
    }
    return lista
  },


  ObtenerExtExperianConfigFC: async (tipoDeuda: any, deudaReal: any) => {
    const lista: any = [];
    try {
      const response: any = await MasterManager.GetExtExperianConfigFC(tipoDeuda, deudaReal);

      if (response.status !== 200) {
        return lista;
      }
      const { content } = response.data;
      if (content) {
        content.forEach((element: any) => {
          lista.push(element);
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER CONFIGURACIONES FC DE EXPERIAN.');
    }
    return lista
  },

  GetSolicitudEtapas: async (identSolicitudEtapas: any, identSolicitud: any) => {
    const lista: any = [];

    try {
      let response: any = await RequestManager.GetSolicitudEtapas({
        "identSolicitudEtapas": identSolicitudEtapas,
        "identSolicitud": identSolicitud
      })

      if (response.status !== 200) {
        return lista;
      }

      const { content } = response.data;

      if (content) {
        content.forEach((element: any) => {
          lista.push(element);
        });
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER ETAPAS POR SOLICITUD');
    }

    return lista;
  },
  GetSolicitudEtapasInsert: async (data: any) => {
    try {
      const response = await RequestManager.GetSolicitudEtapasInsert(data);
      if (response?.status === 200) {
        return response.data.content;
      }
    } catch (error) {
      return null;
    }
  },
  GetSolicitudEtapasUpdate: async (data: any) => {
    try {
      const response = await RequestManager.GetSolicitudEtapasUpdate(data);


      if (response?.status === 200) {
        return response.data.content;
      }
    } catch (error) {
      return null;
    }
  },
}