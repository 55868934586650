import { Button } from "@mui/joy";
import { Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import React from "react";
import InformeComercial from "../../../../15.-InformeComercial/15.10.-base/informeComercial";

const InformeComercialModal = ({ open, solicitudId, handleClose }: any) => {

    const [loading, setLoading] = React.useState<boolean>(false);

    React.useEffect(() => {
    }, [open]);

    return (
        <Dialog open={open} maxWidth={'xl'}>
            <DialogTitle id="alert-dialog-title"><strong>INFORME COMERCIAL</strong></DialogTitle>
            <DialogContent dividers>
                < InformeComercial isModal={true} solicitudId={solicitudId} />
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} size="md" color="neutral" sx={{ borderRadius: 24, paddingLeft: '50px', paddingRight: '50px' }}>
                    Cerrar
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default InformeComercialModal;