import { appDefaultValues } from '../../../config/Config';

// export class ClasificacionDto {
//  solicitud: string;
//  tipo_integrante: string;
//  documento: string;
//  tipo_documento: string;
  
//  constructor(solicitud?: string, tipo_integrante?: string, documento?: string, tipo_documento?: string ) {
//   this.solicitud = solicitud?solicitud:appDefaultValues.StringEmpty;
//   this.tipo_integrante = tipo_integrante?tipo_integrante:appDefaultValues.StringEmpty;
//   this.documento = documento?documento:appDefaultValues.StringEmpty;
//   this.tipo_documento = tipo_documento?tipo_documento:appDefaultValues.StringEmpty;
//  }

//  public getSolicitud(): string {
//   return this.solicitud;
//  }

//  public setSolicitud (valor: string): void {
//   this.solicitud = valor;
//  }

//  public getTipo_integrante(): string {
//   return this.tipo_integrante;
//  }

//  public setTipo_integrante(valor: string): void {
//   this.tipo_integrante = valor;
//  }

//  public getDocumento():string {
//   return this.documento;
//  }

//  public setDocumento(valor: string): void {
//   this.documento = valor;
//  }

//  public getTipo_documento(): string {
//   return this.tipo_documento;
//  }

//  public setTipo_documento(valor: string): void {
//   this.tipo_documento = valor;
//  }

// }


export interface ClasificacionDto {
 solicitud: string;
 tipo_integrante: string;
 documento: string;
 tipo_documento: string; 
}

export interface reqClasificacionDto {
 request: ClasificacionDto
}

export interface reporte {
 tipo_deuda: string,
 entidad: string,
 monto_deuda: number,
 cuota_fc: number,
 cuota_fc_real: number,
 solo_lectura: boolean
}

export interface Clasificacion {
 periodo_rcc: string,
 por_deuda_normal: number,
 por_deuda_cpp: number,
 por_deuda_def: number,
 por_deuda_dud: number,
 por_deuda_per: number,
 linea_disponible: number,
 linea_disponible_real: number,

 nro_entidades_rep: number,
 nro_entidades_rep_d_dir: number,
 nro_entidades_rep_d_indir: number,
 nro_entidades_rep_c_deuda: number,
 nro_entidades_rep_s_deuda: number

 comentario: string,
 reporte: reporte []
}

export const ClasificacionDefault: Clasificacion = {
 periodo_rcc: appDefaultValues.StringEmpty,
 por_deuda_normal: appDefaultValues.NumberDefault,
 por_deuda_cpp: appDefaultValues.NumberDefault,
 por_deuda_def: appDefaultValues.NumberDefault,
 por_deuda_dud: appDefaultValues.NumberDefault,
 por_deuda_per: appDefaultValues.NumberDefault,
 linea_disponible: appDefaultValues.NumberDefault,
 linea_disponible_real: appDefaultValues.NumberDefault,

 nro_entidades_rep: appDefaultValues.NumberDefault,
 nro_entidades_rep_d_dir: appDefaultValues.NumberDefault,
 nro_entidades_rep_d_indir: appDefaultValues.NumberDefault,
 nro_entidades_rep_c_deuda: appDefaultValues.NumberDefault,
 nro_entidades_rep_s_deuda: appDefaultValues.NumberDefault,

 comentario: appDefaultValues.StringEmpty,
 reporte: []
}