
import { useContext, useEffect, useState } from "react";
import { DatosDetailsStepsTwoCard, DatosDetailsStepsTwoCardDefault } from "./interfaces";
import { StepsContext } from "../../providers";
import { CalcularInicialConBonoRequest, calcularInicialOriginalYSimbolo, calcularMontoFinanciarPrecioBonoYSimbolo, calcularMontoVehiculoConBonoYSimbolo, calcularPorcentajeConBonoYSimbolo } from "../../../../../helpers/CalculosConBonoHelper";
import GeneralHelper from "../../../../../helpers/GeneralHelper";

const DetailsStepsTwoCard = () => {

  const {
    solicitudData,
    viewModelStepTwoFinal,
    stepsListaCampanias,
    stepsCampaniaId,
    stepsMontoBono,
    stepsCIPrecio,
    readonlyForm,
    stepTwoReadOnlyViewModel,
    isTitularJuridico,
  } = useContext(StepsContext);

  const [viewModel, setViewModel] = useState<DatosDetailsStepsTwoCard>(DatosDetailsStepsTwoCardDefault);

  useEffect(() => {

    if (viewModelStepTwoFinal !== null) {
      const requestCalculoInicial = new CalcularInicialConBonoRequest();

      requestCalculoInicial.set_divisa(Number(viewModelStepTwoFinal.MonedaId));
      requestCalculoInicial.set_tipoCambio(Number(viewModelStepTwoFinal.TipoCambio));
      requestCalculoInicial.set_porcentajeInicial(Number(viewModelStepTwoFinal.Inicial));
      requestCalculoInicial.set_montoVehiculoSoles(Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoSoles)));
      requestCalculoInicial.set_montoVehiculoDolares(Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoDolares)));
      requestCalculoInicial.set_tipoDescuento(Number(stepsCIPrecio));

      const campaniaEncontrada = stepsListaCampanias.find(
        (x: any) => x.id === stepsCampaniaId
      );

      if (campaniaEncontrada !== undefined) {
        requestCalculoInicial.set_montoBono(Number(campaniaEncontrada.montoBono));
      }

      setViewModel({
        ...viewModel,
        precioVehiculo: calcularMontoVehiculoConBonoYSimbolo(requestCalculoInicial),
        cuotaInicial: calcularInicialOriginalYSimbolo(requestCalculoInicial),
        tieneBono: (stepsCIPrecio == 1),
        InicialValor: calcularPorcentajeConBonoYSimbolo(requestCalculoInicial),
        MontoFinanciar: calcularMontoFinanciarPrecioBonoYSimbolo(requestCalculoInicial),
      });

    }

  }, [stepsCIPrecio]);

  useEffect(() => {

    if (viewModelStepTwoFinal !== null) {

      const requestCalculoInicial = new CalcularInicialConBonoRequest();

      requestCalculoInicial.set_divisa(Number(viewModelStepTwoFinal.MonedaId));
      requestCalculoInicial.set_tipoCambio(Number(viewModelStepTwoFinal.TipoCambio));
      requestCalculoInicial.set_porcentajeInicial(Number(viewModelStepTwoFinal.Inicial));
      requestCalculoInicial.set_montoVehiculoSoles(Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoSoles)));
      requestCalculoInicial.set_montoVehiculoDolares(Number(GeneralHelper.QuitarComasAMiles(viewModelStepTwoFinal.MontoVehiculoDolares)));
      requestCalculoInicial.set_tipoDescuento(Number(stepsCIPrecio));
      requestCalculoInicial.set_montoBono(Number(stepsMontoBono));

      setViewModel({
        ...viewModel,
        Concesionario: viewModelStepTwoFinal.Concesionario,
        Sucursal: viewModelStepTwoFinal.Sucursal,
        Marca: viewModelStepTwoFinal.Marca,
        Modelo: viewModelStepTwoFinal.Modelo,
        precioVehiculo: calcularMontoVehiculoConBonoYSimbolo(requestCalculoInicial),
        cuotaInicial: calcularInicialOriginalYSimbolo(requestCalculoInicial),
        tieneBono: (stepsCIPrecio == 1),
        InicialValor: calcularPorcentajeConBonoYSimbolo(requestCalculoInicial),
        MontoFinanciar: calcularMontoFinanciarPrecioBonoYSimbolo(requestCalculoInicial),
        Segmento: viewModelStepTwoFinal.Segmento,
      });

    }

  }, [viewModelStepTwoFinal]);

  useEffect(() => {

    if (readonlyForm) {

      const tieneBono = (solicitudData.idenT_CAMPANIA_CI_PRECIO_TEA === 1);

      let precioVehiculo = '';
      let porcentaje = '';
      let montoFinanciar = '';

      if (tieneBono) {
        const montoVehiculoBono = Number(solicitudData.monT_PRECIO_VEHICULO_BONO);
        const montoVehiculoBonoDecimales = GeneralHelper.AgregarComasAMiles(Number(montoVehiculoBono).toFixed(2));
        precioVehiculo = stepTwoReadOnlyViewModel.MonedaId === 1 ? `S/ ${montoVehiculoBonoDecimales}` : `$ ${montoVehiculoBonoDecimales}`;
        let cuotaInicial = stepTwoReadOnlyViewModel.MonedaId === 1 ? stepTwoReadOnlyViewModel.MontoInicialSoles : stepTwoReadOnlyViewModel.MontoInicialDolares;
        cuotaInicial = Number(GeneralHelper.QuitarComasAMiles(cuotaInicial));
        porcentaje = `${((cuotaInicial / montoVehiculoBono) * 100).toFixed(0)}%`;
        montoFinanciar = stepTwoReadOnlyViewModel.MonedaId === 1 ? `S/ ${GeneralHelper.AgregarComasAMiles((montoVehiculoBono - cuotaInicial).toFixed(2))}` : `$ ${GeneralHelper.AgregarComasAMiles((montoVehiculoBono - cuotaInicial).toFixed(2))}`
      } else {
        precioVehiculo = stepTwoReadOnlyViewModel.MonedaId === 1 ? `S/ ${stepTwoReadOnlyViewModel.MontoVehiculoBaseSoles}` : `$ ${stepTwoReadOnlyViewModel.MontoVehiculoBaseDolares}`
        porcentaje = `${stepTwoReadOnlyViewModel.Inicial}%`;
        montoFinanciar = stepTwoReadOnlyViewModel.MonedaId === 1 ? `S/ ${stepTwoReadOnlyViewModel.MontoFinanciar}` : `$ ${stepTwoReadOnlyViewModel.MontoFinanciar}`;
      }

      setViewModel({
        ...viewModel,
        Concesionario: stepTwoReadOnlyViewModel.Concesionario,
        Sucursal: stepTwoReadOnlyViewModel.Sucursal,
        Marca: stepTwoReadOnlyViewModel.Marca,
        Modelo: stepTwoReadOnlyViewModel.Modelo,
        precioVehiculo: precioVehiculo,
        cuotaInicial: stepTwoReadOnlyViewModel.MonedaId === 1 ? `S/ ${stepTwoReadOnlyViewModel.MontoInicialSoles}` : `$ ${stepTwoReadOnlyViewModel.MontoInicialDolares}`,
        tieneBono: tieneBono,
        InicialValor: porcentaje,
        MontoFinanciar: montoFinanciar,
        Segmento: solicitudData.segmento,
      });

    }

  }, []);

  return (
    <>
      <div className="border rounded-10 p-3 p-md-2 mb-4 mb-md-4 card body-details">
        <div className="row g-2 mb-2 align-items-center item-body-details">

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Precio Vehículo{viewModel.tieneBono ? " - Campaña" : ""}</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.precioVehiculo}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Cuota inicial</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <span className="text-negrita">{viewModel.cuotaInicial}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Monto a financiar</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.MontoFinanciar}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Inicial </span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.InicialValor}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Concesionario</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.Concesionario}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Sucursal</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.Sucursal}</span>
                </div>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Marca</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{viewModel.Marca}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Segmento</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{!isTitularJuridico ? viewModel.Segmento : '-'}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>

    </>
  );
};

export default DetailsStepsTwoCard;
