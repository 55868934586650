import { appDefaultValues } from "../../../../config/Config";

export class BTCrearClientePartner {
    private puntoVentaUId: number;
    private vendedorUId: number;
    private partnerUId: number;

    constructor() {
        this.puntoVentaUId = appDefaultValues.NumberDefault;
        this.vendedorUId = appDefaultValues.NumberDefault;
        this.partnerUId = appDefaultValues.NumberDefault;
    }

    public getPuntoVentaUId(): number {
        return this.puntoVentaUId;
    }

    public setPuntoVentaUId(puntoVentaUId: number): void {
        this.puntoVentaUId = puntoVentaUId;
    }

    public getVendedorUId(): number {
        return this.vendedorUId;
    }

    public setVendedorUId(vendedorUId: number): void {
        this.vendedorUId = vendedorUId;
    }

    public getPartnerUId(): number {
        return this.partnerUId;
    }

    public setPartnerUId(partnerUId: number): void {
        this.partnerUId = partnerUId;
    }

}