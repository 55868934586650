import React from "react";
import IconUpload from '../../../../../assets/media/icons/ico_upload.svg';
import CircularProgress from '@mui/material/CircularProgress';

const DocumentationFileUploader = (props: any) => {
    const hiddenFileInput = React.useRef<HTMLInputElement>(document.createElement("input"));
    const [loading, setLoading] = React.useState<boolean>(false);


    const handleClick = (event: any) => {
        
        if (!props.disabled) {
            hiddenFileInput.current.click();
        }
    };

    const handleChange = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        const fileUploaded = event.target.files[0];
        await props.handleFile(fileUploaded, props.data);

        if (hiddenFileInput.current) {
            hiddenFileInput.current.value = '';
        }

        setLoading(false);
    };

    return (
        <>
            {!loading ? (
                <>
                    <img className={props.disabled ? 'grayscale' : 'cursor-pointer '} onClick={handleClick} src={IconUpload} />
                    <input type="file" ref={hiddenFileInput} onChange={(event: any) => handleChange(event)} style={{ display: 'none' }} />
                </>
            ) : (
                <CircularProgress size={12} />
            )}
        </>
    );
};

export default DocumentationFileUploader;