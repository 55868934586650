import { appDefaultValues } from "../../../../../../config/Config";

export interface DomicilioTitularInterface {   
    nombreVia: string,
    numDomicilio: string,
    intDptoPiso: string,
    manzana: string,
    lote: string,
    nombreAgrupacion: string,
    referencia: string,
    identTipoVivienda: number,
    identTiempoResidencia: number
}

export const DomicilioTitularInterfaceDefault: DomicilioTitularInterface = {
        
    nombreVia: appDefaultValues.StringEmpty,
    numDomicilio: appDefaultValues.StringEmpty,
    intDptoPiso: appDefaultValues.StringEmpty,
    manzana: appDefaultValues.StringEmpty,
    lote: appDefaultValues.StringEmpty,
    nombreAgrupacion: appDefaultValues.StringEmpty,
    referencia: appDefaultValues.StringEmpty,
    identTipoVivienda: appDefaultValues.NumberDefault,
    identTiempoResidencia: appDefaultValues.NumberDefault
};