import { Component } from "react";
import './card-details-steps2.scss'
import './../2.10.-base/quatition.scss'
import { appConfigKey, appConfigSegmentos } from "../../../../config/Config";

interface ICardDetailsStep2Props {
  objCardDetailsStep2Props: {
    AmountVehicle: string,
    startingAmount: string,
    concessionaire: string,
    branch: string,
    seller: string,
    segment: string,
    brand: string,
    model: string,
    idTypeDocument: number,
    idCategoria: number,
  }
}

class CardDetailsStep2 extends Component<ICardDetailsStep2Props, any> {

  constructor(props: any) {
    super(props);

  }

  render() {
    const isTitularJuridico = this.props.objCardDetailsStep2Props.idTypeDocument == appConfigKey.keyIdRUC ||
      this.props.objCardDetailsStep2Props.idCategoria == appConfigKey.keyCodigoCategoriaLaboralTres;
    return (

      <div className="border rounded-10 p-3 p-md-2 mb-4 mb-md-4 card body-details">
        <div className="row g-2 mb-2 align-items-center item-body-details" >

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Monto Vehículo</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.props.objCardDetailsStep2Props.AmountVehicle}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Inicial</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.props.objCardDetailsStep2Props.startingAmount}</span>
                </div>
              </div>
            </div>
          </div>



          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Concesionario</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.props.objCardDetailsStep2Props.concessionaire}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Sucursal</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.props.objCardDetailsStep2Props.branch}</span>
                </div>
              </div>
            </div>
          </div>


          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Vendedor</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.props.objCardDetailsStep2Props.seller}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Segmento</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  {/* <span className="text-negrita">{this.props.objCardDetailsStep2Props.segment}</span> */}
                  <span className="text-negrita">{!isTitularJuridico ? appConfigSegmentos[this.props.objCardDetailsStep2Props.segment] : '-'}</span>
                </div>
              </div>
            </div>
          </div>



          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Marca</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.props.objCardDetailsStep2Props.brand}</span>
                </div>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-6 col-md-6">
                <span>Modelo</span>
              </div>
              <div className="col-lg-6 col-md-6">
                <div className="text-long">
                  <span className="text-negrita">{this.props.objCardDetailsStep2Props.model}</span>
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    )
  }
}
export default CardDetailsStep2;