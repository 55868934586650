import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import TitularDatosCard from './TitularDatosCard';
import TitularDomicilioCard from './TitularDomicilioCard';
import TitularDatosLaboralesCard from './TitularDatosLaboralesCard';
import ConyugeDatosCard from './ConyugeDatosCard';
import { useEffect, useState } from 'react';
import ConyugeDatosLaboralesCard from './ConyugeDatosLaboralesCard';
import { General, ObtenerListas } from '../../obtenerServicios';
import { appConfigKey } from '../../../../../../config/Config';
import ValidacionStepFiveHelper from '../../helpers/ValidacionStepFiveHelper';
import RequestManager from '../../../../../services/origination/wapirequest.service';
import { Toaster, toast } from 'sonner';

const DatosAdicionalesModal = (props: any) => {

    const { open, handleClose, setBtnModal, data, solicitudData,cargarDataDatosAdicionales } = props;

    const [viewModelTitular, setViewModelTitular] = useState({
        EstadoCivilId: 0,
    });

    const [viewModelConyuge, setViewModelConyuge] = useState({
        CategoriaLaboralId: 0,
    });
    const [tempDataConyugue] = useState({
        identNacionalidad: 0,
        identNivelEducacion: 0,
        correo: "",
        identProfesion: ""
    })
    const [tempDataLaboralConyugue] = useState({
        identActividadEconomica: 0,
        identPais: 0,
        identDepartamentos: 0,
        identProvincias: 0,
        identDistritos: 0,
        nombreVia: "",
        numeroVia: "",
        telefono : "",
        correo: "",
        identCargoLaboral: 0,
    })
    const [listProfesion, setListProfesion] = useState([]);
    const [listPais, setListPais] = useState([]);
    const [listNivelEdu, setlistNivelEdu] = useState([]);
    const [listTipoVivienda, setlistTipoVivienda] = useState([]);
    const [listTiempoResidencia, setlistTiempoResidencia] = useState([]);
    const [listActividadEconomica, setlistActividadEconomica] = useState([]);

    const [empresaTitular, setEmpresaTitular] = useState<any>(null);
    const [empresaConyuge, setEmpresaConyuge] = useState<any>(null);

    const [titularDatosFinal, setTitularDatosFinal] = useState<any>(null);
    const [titularDomicilioFinal, setTitularDomicilioFinal] = useState<any>(null);
    const [titularLaboralesFinal, setTitularLaboralesFinal] = useState<any>(null);
    const [conyugeDatosFinal, setConyugeDatosFinal] = useState<any>(null);
    const [conyugeLaboralesFinal, setConyugeLaboralesFinal] = useState<any>(null);

    const [clickSubmit, setClickSubmit] = useState(0);

    useEffect(() => {

        Promise.all([
            ObtenerListas.Profesion(),
            ObtenerListas.Pais(),
            ObtenerListas.NivelesEducativos(),
            ObtenerListas.TiposDeVivienda(),
            ObtenerListas.TiempoResidencia(),
            ObtenerListas.ActividadEconomica()
        ]).then(response => {

            const profesiones = response[0];
            const paises = response[1];
            const nivelesEducativos = response[2];
            const tiposDeVivienda = response[3];
            const tiemposEnResidencias = response[4];
            const actividadesEconomicas = response[5];

            setListProfesion(profesiones);
            setListPais(paises);
            setlistNivelEdu(nivelesEducativos);
            setlistTipoVivienda(tiposDeVivienda);
            setlistTiempoResidencia(tiemposEnResidencias);
            setlistActividadEconomica(actividadesEconomicas);

        });

    }, []);

    useEffect(() => {
        if (data !== undefined && data !== null) {
            const { oldDataTitular, oldDataConyuge } = data;
            
            setViewModelTitular({ EstadoCivilId: oldDataTitular.iD_ESTADO_CIVIL });
            
            obtenerEmpresaPorPersona(oldDataTitular.idenT_PERSONA).then(response => {
                setEmpresaTitular(response.dataEmpresa);
            });
            if (oldDataConyuge !== null && oldDataConyuge !== undefined) {
                if (
                    oldDataTitular.iD_ESTADO_CIVIL === appConfigKey.keyIdEstadoCivilCasado ||
                    oldDataTitular.iD_ESTADO_CIVIL === appConfigKey.keyIdEstadoCivilConviviente
                ) {
                    obtenerEmpresaPorPersona(oldDataConyuge.idenT_PERSONA).then(response => {
                        let idCategoria : any = 0;
                        idCategoria = response.ingresoPrincipal?.iD_CATEGORIA_LABORAL === null ? 0 : 
                        response.ingresoPrincipal?.iD_CATEGORIA_LABORAL === undefined ? 0 : response.ingresoPrincipal?.iD_CATEGORIA_LABORAL; 
                        setViewModelConyuge({
                            ...viewModelConyuge,
                            CategoriaLaboralId: idCategoria
                        });
                        if(idCategoria === 0 || idCategoria === appConfigKey.keyIdSinCategoria
                            || idCategoria === null || idCategoria === undefined){
                            setEmpresaConyuge(null);
                        }
                        else{
                            setEmpresaConyuge(response.dataEmpresa);
                        }
                    });
                }
            }
        }
    }, [data]);

    const obtenerEmpresaPorPersona = async (personaId: any) => {
        const dataIngresos = await General.Ingresos(personaId);
        const ingresoPrincipal = dataIngresos.find((x: any) => x.inD_INGRESO_PRINCIPAL === true);
        const dataEmpresa: any = await General.Empresa(ingresoPrincipal.idenT_EMPRESA);
        return {dataEmpresa, ingresoPrincipal};
    };

    const actualizarDatosAdicionales = () => {
        const tempClickSubmit = 1 + clickSubmit;
        setClickSubmit(tempClickSubmit);
    }

    const resetSendClick = () => {
        setClickSubmit(0);
    }

    useEffect(() => {
        if (
            titularDatosFinal !== null &&
            titularDomicilioFinal !== null &&
            titularLaboralesFinal !== null
        ) {
            const validarNewDatosAdicionalesTitularData = !ValidacionStepFiveHelper.NewDatosAdicionalesTitularData(titularDatosFinal);

            if (validarNewDatosAdicionalesTitularData) {
                setTitularDatosFinal(null);
                resetSendClick();
                return;
            }

            const validarNewDatosDomicilioTitularData = !ValidacionStepFiveHelper.NewDatosDomicilioTitulardata(titularDomicilioFinal);
            if (validarNewDatosDomicilioTitularData) {
                setTitularDomicilioFinal(null);
                resetSendClick();
                return;
            }

            const validarNewDatosLaboralesAdicionalesTitularData = !ValidacionStepFiveHelper.NewDatosLaboralesAdicionalesTitularData(titularLaboralesFinal);
            if (validarNewDatosLaboralesAdicionalesTitularData) {
                setTitularLaboralesFinal(null);
                resetSendClick();
                return;
            }

            if (viewModelTitular.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado || viewModelTitular.EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente) {
                if ((viewModelConyuge.CategoriaLaboralId !== appConfigKey.keyIdSinCategoria && viewModelConyuge.CategoriaLaboralId !== 0)
                    && (conyugeDatosFinal === null || conyugeLaboralesFinal === null)) {
                    return;
                }
                const validarNewDatosAdicionalesConyugeData = !ValidacionStepFiveHelper.NewDatosAdicionalesConyugeData(conyugeDatosFinal == null ? tempDataConyugue : conyugeDatosFinal);
                if (validarNewDatosAdicionalesConyugeData) {
                    resetSendClick();
                    return;
                }

                if (viewModelConyuge.CategoriaLaboralId !== appConfigKey.keyIdSinCategoria && viewModelConyuge.CategoriaLaboralId !== 0) {
                    const validarNewDatosLaboralesAdicionalesConyugeData = !ValidacionStepFiveHelper.NewDatosLaboralesAdicionalesConyugeData2(conyugeLaboralesFinal == null ? tempDataLaboralConyugue : conyugeLaboralesFinal);
                    if (validarNewDatosLaboralesAdicionalesConyugeData) {
                        resetSendClick();
                        return;
                    }
                }
            }

            //ENVIAR LA INFORMACIÓN DE LOS MODELOS FINALES AL ENDPOINT PARA ACTUALIZAR DATOS ADICIONALES
            const { oldDataTitular, oldDataConyuge } = data;

            const bodyConyuge = (viewModelTitular.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado || viewModelTitular.EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente) ? {
                idenT_PERSONA: oldDataConyuge.idenT_PERSONA,
                iD_TIPO_PERSONA: oldDataConyuge.iD_TIPO_PERSONA,
                descC_TIPO_PERSONA: oldDataConyuge.descC_TIPO_PERSONA,
                iD_TIPO_DOCUMENTO: oldDataConyuge.iD_TIPO_DOCUMENTO,
                descC_TIPO_DOCUMENTO: oldDataConyuge.descC_TIPO_DOCUMENTO,
                codL_NUMERO_DOCUMENTO: oldDataConyuge.codL_NUMERO_DOCUMENTO,
                descL_NOMBRE_COMPLETO: oldDataConyuge.descL_NOMBRE_COMPLETO,
                descC_CELULAR: oldDataConyuge.descC_CELULAR,
                descL_EMAIL: conyugeDatosFinal.correo,
                fecH_NACIMIENTO_CONSTITUCION: oldDataConyuge.fecH_NACIMIENTO_CONSTITUCION,
                iD_RANGO_VENTA_ANUAL: oldDataConyuge.iD_RANGO_VENTA_ANUAL,
                descC_RANGO_VENTA_ANUAL: oldDataConyuge.descC_RANGO_VENTA_ANUAL,
                iD_RANGO_VENTA_ANUAL_ANTERIOR: oldDataConyuge.iD_RANGO_VENTA_ANUAL_ANTERIOR,
                descC_RANGO_VENTA_ANUAL_ANTERIOR: oldDataConyuge.descC_RANGO_VENTA_ANUAL_ANTERIOR,
                descL_APELLIDO_PATERNO: oldDataConyuge.descL_APELLIDO_PATERNO,
                descL_APELLIDO_MATERNO: oldDataConyuge.descL_APELLIDO_MATERNO,
                descL_NOMBRES: oldDataConyuge.descL_NOMBRES,
                iD_GENERO: oldDataConyuge.iD_GENERO,
                descC_GENERO: oldDataConyuge.descC_GENERO,
                iD_MAGNITUD: oldDataConyuge.iD_MAGNITUD,
                descC_MAGNITUD: oldDataConyuge.descC_MAGNITUD,
                iD_TIPO_DOCUMENTO_COMPLEMENTARIO: oldDataConyuge.iD_TIPO_DOCUMENTO_COMPLEMENTARIO,
                descC_TIPO_DOCUMENTO_COMPLEMENTARIO: oldDataConyuge.descC_TIPO_DOCUMENTO_COMPLEMENTARIO,
                codL_NUMERO_DOCUMENTO_COMPLEMENTARIO: oldDataConyuge.codL_NUMERO_DOCUMENTO_COMPLEMENTARIO,
                iD_ACTIVIDAD_ECONOMICA: oldDataConyuge.iD_ACTIVIDAD_ECONOMICA,
                descC_ACTIVIDAD_ECONOMICA: oldDataConyuge.descC_ACTIVIDAD_ECONOMICA,
                flaG_PEP: oldDataConyuge.flaG_PEP,
                iD_CARGO_PEP: oldDataConyuge.iD_CARGO_PEP,
                descC_CARGO_PEP: oldDataConyuge.descC_CARGO_PEP,
                cargO_PEP_OTROS: oldDataConyuge.cargO_PEP_OTROS,
                iD_NACIONALIDAD: conyugeDatosFinal.identNacionalidad,
                descC_NACIONALIDAD: conyugeDatosFinal.descripcionNacionalidad,
                iD_NIVEL_ESTUDIOS: conyugeDatosFinal.identNivelEducacion,
                descC_NIVEL_ESTUDIOS: conyugeDatosFinal.descripcionNivelEducacion,
                monT_NUMERO_HIJOS: oldDataConyuge.monT_NUMERO_HIJOS,
                monT_NUMERO_DEPENDIENTES: oldDataConyuge.monT_NUMERO_DEPENDIENTES,
                inD_SEPARACION_BIENES: oldDataConyuge.inD_SEPARACION_BIENES,
                iD_PROFESION: conyugeDatosFinal.identProfesion,
                descC_PROFESION: conyugeDatosFinal.descripcionProfesion,
                descL_NOMBRE_COMPLETO_CONTACTO1: oldDataConyuge.descL_NOMBRE_COMPLETO_CONTACTO1,
                descL_NOMBRE_COMPLETO_CONTACTO2: oldDataConyuge.descL_NOMBRE_COMPLETO_CONTACTO2,
                descC_NUMERO_CONTACTO1: oldDataConyuge.descC_NUMERO_CONTACTO1,
                descC_NUMERO_CONTACTO2: oldDataConyuge.descC_NUMERO_CONTACTO2,
                descM_NOMBRE_VIA: conyugeDatosFinal.descM_NOMBRE_VIA,
                descC_NUMERO: conyugeDatosFinal.descC_NUMERO,
                descC_INTERIOR_DEPARTAMENTO_PISO: conyugeDatosFinal.descC_INTERIOR_DEPARTAMENTO_PISO,
                descC_MANZANA: conyugeDatosFinal.descC_MANZANA,
                descC_LOTE: conyugeDatosFinal.descC_LOTE,
                descM_NOMBRE_AGRUPACION: conyugeDatosFinal.descM_NOMBRE_AGRUPACION,
                descL_REFERENCIA: conyugeDatosFinal.descL_REFERENCIA,
                iD_TIPO_VIVIENDA: oldDataConyuge.iD_TIPO_VIVIENDA,
                descC_TIPO_VIVIENDA: oldDataConyuge.descC_TIPO_VIVIENDA,
                iD_TIEMPO_RESIDENCIA: oldDataConyuge.iD_TIEMPO_RESIDENCIA,
                descC_TIEMPO_RESIDENCIA: oldDataConyuge.descC_TIEMPO_RESIDENCIA,
                idenT_CARGO_LABORAL: conyugeLaboralesFinal?.identCargoLaboral == null ? 0 : conyugeLaboralesFinal.identCargoLaboral,
                descL_DESCRIPCION_CARGO: conyugeLaboralesFinal?.descripcionCargo == null ? "": conyugeLaboralesFinal.descripcionCargo,
                inD_ESTADO: oldDataConyuge.inD_ESTADO,
                idenT_USUARIO: oldDataConyuge.idenT_USUARIO,
                iD_ESTADO_CIVIL: oldDataConyuge.iD_ESTADO_CIVIL,
                descC_ESTADO_CIVIL: oldDataConyuge.descC_ESTADO_CIVIL,
                descC_DOMICILIO: oldDataConyuge.descC_DOMICILIO,
                iD_PAIS_RESIDENCIA: oldDataConyuge.iD_PAIS_RESIDENCIA,
                descL_PAIS_RESIDENCIA: oldDataConyuge.descL_PAIS_RESIDENCIA,
                clientuid: oldDataConyuge.clientuid,
                personauid: oldDataConyuge.personauid,
            } : null;
            const boyEmpresaConyuge = ((viewModelTitular.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado || viewModelTitular.EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente) &&
                                        (viewModelConyuge.CategoriaLaboralId !== appConfigKey.keyIdSinCategoria && viewModelConyuge.CategoriaLaboralId !== 0)) ? {
                idenT_EMPRESA: empresaConyuge.idenT_EMPRESA,
                descL_RAZON_SOCIAL_EMPRESA: empresaConyuge.descL_RAZON_SOCIAL_EMPRESA,
                iD_TIPO_DOC: empresaConyuge.iD_TIPO_DOC,
                descC_TIPO_DOC: empresaConyuge.descC_TIPO_DOC,
                descL_NRO_DOC: empresaConyuge.descL_NRO_DOC,
                iD_ACTIVIDAD_ECONOMICA: conyugeLaboralesFinal.identActividadEconomica,
                descC_ACTIVIDAD_ECONOMICA: conyugeLaboralesFinal.descripcionActividadEconomica,
                descM_NOMBRE_VIA: conyugeLaboralesFinal.nombreVia,
                descC_NUMERO: conyugeLaboralesFinal.numeroVia,
                descC_INTERIOR_DEPARTAMENTO_PISO: conyugeLaboralesFinal.intDptoPiso,
                descC_MANZANA: conyugeLaboralesFinal.manzana,
                descC_LOTE: conyugeLaboralesFinal.lote,
                descM_NOMBRE_AGRUPACION: conyugeLaboralesFinal.nombreAgrupacion,
                idenT_PAIS: conyugeLaboralesFinal.identPais,
                idenT_UBIGEO: Number(conyugeLaboralesFinal.empresaUbigeo),
                descL_REFERENCIA: conyugeLaboralesFinal.referencia,
                inD_ESTADO: true,
                idenT_USUARIO: Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
                descC_TELEFONO: conyugeLaboralesFinal.telefono,
                descC_ANEXOS: conyugeLaboralesFinal.anexo,
                descC_CORREO: conyugeLaboralesFinal.correo,
            } : null;

            const body = {
                identSolicitud: solicitudData.idenT_SOLICITUD,
                identOperacionOld: solicitudData.idenT_OPERACION,
                mnSolicitudInsertUpdateRequest: {
                    idenT_SOLICITUD: solicitudData.idenT_SOLICITUD,
                    idenT_PERSONA_TITULAR: solicitudData.idenT_PERSONA_TITULAR,
                    idenT_PERSONA_CONYUGUE: solicitudData.idenT_PERSONA_CONYUGUE,
                    descL_OBSERVACION: solicitudData.descL_OBSERVACION,
                    iD_ESTADO_VEHICULO_FINANCIAR: solicitudData.iD_ESTADO_VEHICULO_FINANCIAR,
                    descC_ESTADO_VEHICULO_FINANCIAR: solicitudData.descC_ESTADO_VEHICULO_FINANCIAR,
                    idenT_CONCESIONARIO: solicitudData.idenT_CONCESIONARIO,
                    descC_CONCESIONARIO: solicitudData.descC_CONCESIONARIO,
                    idenT_SUCURSAL: solicitudData.idenT_SUCURSAL,
                    descC_SUCURSAL: solicitudData.descC_SUCURSAL,
                    idenT_VENDEDOR: solicitudData.idenT_VENDEDOR,
                    descC_VENDEDOR: solicitudData.descC_VENDEDOR,
                    descL_EMAIL_VENDEDOR: solicitudData.descL_EMAIL_VENDEDOR,
                    descL_EMAIL_ANALISTA: solicitudData.descL_EMAIL_ANALISTA,
                    monT_TIPO_CAMBIO: solicitudData.monT_TIPO_CAMBIO,
                    iD_TIPO_MONEDA_FINANCIAMIENTO: solicitudData.iD_TIPO_MONEDA_FINANCIAMIENTO,
                    descC_TIPO_MONEDA_FINANCIAMIENTO: solicitudData.descC_TIPO_MONEDA_FINANCIAMIENTO,
                    iD_TIPO_MONEDA_PRECIO_VEHICULO: solicitudData.iD_TIPO_MONEDA_PRECIO_VEHICULO,
                    descC_TIPO_MONEDA_PRECIO_VEHICULO: solicitudData.descC_TIPO_MONEDA_PRECIO_VEHICULO,
                    monT_PRECIO_VEHICULO: solicitudData.monT_PRECIO_VEHICULO,
                    iD_TIPO_MONEDA_CUOTA_INICIAL: solicitudData.iD_TIPO_MONEDA_CUOTA_INICIAL,
                    descC_TIPO_MONEDA_CUOTA_INICIAL: solicitudData.descC_TIPO_MONEDA_CUOTA_INICIAL,
                    monT_CUOTA_INICIAL: solicitudData.monT_CUOTA_INICIAL,
                    iD_USO_VEHICULO: solicitudData.iD_USO_VEHICULO,
                    descC_USO_VEHICULO: solicitudData.descC_USO_VEHICULO,
                    idenT_MARCA: solicitudData.idenT_MARCA,
                    descC_MARCA: solicitudData.descC_MARCA,
                    idenT_MODELO: solicitudData.idenT_MODELO,
                    descC_MODELO: solicitudData.descC_MODELO,
                    idenT_VERSION: solicitudData.idenT_VERSION,
                    descC_VERSION: solicitudData.descC_VERSION,
                    idenT_PROVEEDOR_GPS: solicitudData.idenT_PROVEEDOR_GPS,
                    descC_PROVEEDOR_GPS: solicitudData.descC_PROVEEDOR_GPS,
                    idenT_CAMPANA: solicitudData.idenT_CAMPANA,
                    descC_CAMPANA: solicitudData.descC_CAMPANA,
                    monT_CALC_GASTOS: solicitudData.monT_CALC_GASTOS,
                    monT_CALC_FINANCIAMIENTO: solicitudData.monT_CALC_FINANCIAMIENTO,
                    iD_ESTADO_CAMPANA: solicitudData.iD_ESTADO_CAMPANA,
                    descC_ESTADO_CAMPANA: solicitudData.descC_ESTADO_CAMPANA,
                    idenT_PRODUCTO: solicitudData.idenT_PRODUCTO,
                    descC_PRODUCTO: solicitudData.descC_PRODUCTO,
                    idenT_SEGURO_VEHICULAR: solicitudData.idenT_SEGURO_VEHICULAR,
                    descC_SEGURO_VEHICULAR: solicitudData.descC_SEGURO_VEHICULAR,
                    monT_PORCENTAJE_SEGURO_VEHICULAR: solicitudData.monT_PORCENTAJE_SEGURO_VEHICULAR,
                    inD_PROCESO_DERECHO_ENDOSO: solicitudData.inD_PROCESO_DERECHO_ENDOSO,
                    monT_TEA: solicitudData.monT_TEA,
                    indeT_PLAZO: solicitudData.indeT_PLAZO,
                    descC_DIA_PAGO: solicitudData.descC_DIA_PAGO,
                    inD_CUOTAS_DOBLES: solicitudData.inD_CUOTAS_DOBLES,
                    fecH_PRIMER_VENCIMIENTO: solicitudData.fecH_PRIMER_VENCIMIENTO,
                    iD_COBERTURA_SEGURO_DESGRAVAMENT: solicitudData.iD_COBERTURA_SEGURO_DESGRAVAMENT,
                    descC_COBERTURA_SEGURO_DESGRAVAMENT: solicitudData.descC_COBERTURA_SEGURO_DESGRAVAMENT,
                    inD_ESTADO: true,
                    idenT_USUARIO: solicitudData.idenT_USUARIO,
                    iD_ESTADO_SOLICITUD: solicitudData.iD_ESTADO_SOLICITUD,
                    descC_ID_ESTADO_SOLICITUD: solicitudData.descC_ID_ESTADO_SOLICITUD,
                    idenT_OPERACION: solicitudData.idenT_OPERACION,
                    idenT_PERSONA_COMPRA_PARA: solicitudData.idenT_PERSONA_COMPRA_PARA,
                    idenT_PERSONA_COMPRA_PARA_CONYUGUE: solicitudData.idenT_PERSONA_COMPRA_PARA_CONYUGUE,
                    idenT_CAMPANIA_CI_PRECIO_TEA: solicitudData.idenT_CAMPANIA_CI_PRECIO_TEA,
                    idenT_ANIO_VEHICULO: solicitudData.idenT_ANIO_VEHICULO,
                    idenT_DESTINO_CREDITO: solicitudData.idenT_DESTINO_CREDITO,
                    descC_DESTINO_CREDITO: solicitudData.descC_DESTINO_CREDITO,
                    tcea: solicitudData.tcea,
                    cuotA_BALLON: solicitudData.cuotA_BALLON,
                    derechO_ENDOSO: solicitudData.derechO_ENDOSO,
                    inD_BONO: solicitudData.inD_BONO,
                    iD_ESTADO_RECONFIRMACION: solicitudData.iD_ESTADO_RECONFIRMACION,
                    descL_COMENTARIO_RECONFIRMACION: solicitudData.descL_COMENTARIO_RECONFIRMACION,
                },
                mnPersonaRequest: {
                    mnPersonaRequest: {
                        idenT_PERSONA: oldDataTitular.idenT_PERSONA,
                        iD_TIPO_PERSONA: oldDataTitular.iD_TIPO_PERSONA,
                        descC_TIPO_PERSONA: oldDataTitular.descC_TIPO_PERSONA,
                        iD_TIPO_DOCUMENTO: oldDataTitular.iD_TIPO_DOCUMENTO,
                        descC_TIPO_DOCUMENTO: oldDataTitular.descC_TIPO_DOCUMENTO,
                        codL_NUMERO_DOCUMENTO: oldDataTitular.codL_NUMERO_DOCUMENTO,
                        descL_NOMBRE_COMPLETO: oldDataTitular.descL_NOMBRE_COMPLETO,
                        descC_CELULAR: oldDataTitular.descC_CELULAR,
                        descL_EMAIL: titularDatosFinal.correo,
                        fecH_NACIMIENTO_CONSTITUCION: oldDataTitular.fecH_NACIMIENTO_CONSTITUCION,
                        iD_RANGO_VENTA_ANUAL: oldDataTitular.iD_RANGO_VENTA_ANUAL,
                        descC_RANGO_VENTA_ANUAL: oldDataTitular.descC_RANGO_VENTA_ANUAL,
                        iD_RANGO_VENTA_ANUAL_ANTERIOR: oldDataTitular.iD_RANGO_VENTA_ANUAL_ANTERIOR,
                        descC_RANGO_VENTA_ANUAL_ANTERIOR: oldDataTitular.descC_RANGO_VENTA_ANUAL_ANTERIOR,
                        descL_APELLIDO_PATERNO: oldDataTitular.descL_APELLIDO_PATERNO,
                        descL_APELLIDO_MATERNO: oldDataTitular.descL_APELLIDO_MATERNO,
                        descL_NOMBRES: oldDataTitular.descL_NOMBRES,
                        iD_GENERO: oldDataTitular.iD_GENERO,
                        descC_GENERO: oldDataTitular.descC_GENERO,
                        iD_MAGNITUD: oldDataTitular.iD_MAGNITUD,
                        descC_MAGNITUD: oldDataTitular.descC_MAGNITUD,
                        iD_TIPO_DOCUMENTO_COMPLEMENTARIO: oldDataTitular.iD_TIPO_DOCUMENTO_COMPLEMENTARIO,
                        descC_TIPO_DOCUMENTO_COMPLEMENTARIO: oldDataTitular.descC_TIPO_DOCUMENTO_COMPLEMENTARIO,
                        codL_NUMERO_DOCUMENTO_COMPLEMENTARIO: oldDataTitular.codL_NUMERO_DOCUMENTO_COMPLEMENTARIO,
                        iD_ACTIVIDAD_ECONOMICA: oldDataTitular.iD_ACTIVIDAD_ECONOMICA,
                        descC_ACTIVIDAD_ECONOMICA: oldDataTitular.descC_ACTIVIDAD_ECONOMICA,
                        flaG_PEP: oldDataTitular.flaG_PEP,
                        iD_CARGO_PEP: oldDataTitular.iD_CARGO_PEP,
                        descC_CARGO_PEP: oldDataTitular.descC_CARGO_PEP,
                        cargO_PEP_OTROS: oldDataTitular.cargO_PEP_OTROS,
                        iD_NACIONALIDAD: titularDatosFinal.identNacionalidad,
                        descC_NACIONALIDAD: titularDatosFinal.descripcionNacionalidad,
                        iD_NIVEL_ESTUDIOS: titularDatosFinal.identNivelEducacion,
                        descC_NIVEL_ESTUDIOS: titularDatosFinal.descripcionNivelEducacion,
                        monT_NUMERO_HIJOS: titularDatosFinal.nroHijos,
                        monT_NUMERO_DEPENDIENTES: titularDatosFinal.nroDependientes,
                        inD_SEPARACION_BIENES: oldDataTitular.inD_SEPARACION_BIENES,
                        iD_PROFESION: titularDatosFinal.identProfesion,
                        descC_PROFESION: titularDatosFinal.descripcionProfesion,
                        descL_NOMBRE_COMPLETO_CONTACTO1: titularDatosFinal.nomYApeContacto1,
                        descL_NOMBRE_COMPLETO_CONTACTO2: titularDatosFinal.nomYApeContacto2,
                        descC_NUMERO_CONTACTO1: titularDatosFinal.celular1,
                        descC_NUMERO_CONTACTO2: titularDatosFinal.celular2,
                        descM_NOMBRE_VIA: titularDomicilioFinal.nombreVia,
                        descC_NUMERO: titularDomicilioFinal.numDomicilio,
                        descC_INTERIOR_DEPARTAMENTO_PISO: titularDomicilioFinal.intDptoPiso,
                        descC_MANZANA: titularDomicilioFinal.manzana,
                        descC_LOTE: titularDomicilioFinal.lote,
                        descM_NOMBRE_AGRUPACION: titularDomicilioFinal.nombreAgrupacion,
                        descL_REFERENCIA: titularDomicilioFinal.referencia,
                        iD_TIPO_VIVIENDA: titularDomicilioFinal.identTipoVivienda,
                        descC_TIPO_VIVIENDA: titularDomicilioFinal.descripcionTipoVivienda,
                        iD_TIEMPO_RESIDENCIA: titularDomicilioFinal.identTiempoResidencia,
                        descC_TIEMPO_RESIDENCIA: titularDomicilioFinal.descripcionTiempoResidencia,
                        idenT_CARGO_LABORAL: titularLaboralesFinal.identCargoLaboral,
                        descL_DESCRIPCION_CARGO: titularLaboralesFinal.descripcionCargo,
                        inD_ESTADO: true,
                        idenT_USUARIO: Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
                        iD_ESTADO_CIVIL: oldDataTitular.iD_ESTADO_CIVIL,
                        descC_ESTADO_CIVIL: oldDataTitular.descC_ESTADO_CIVIL,
                        descC_DOMICILIO: oldDataTitular.descC_DOMICILIO,
                        iD_PAIS_RESIDENCIA: oldDataTitular.iD_PAIS_RESIDENCIA,
                        descL_PAIS_RESIDENCIA: oldDataTitular.descL_PAIS_RESIDENCIA,
                        clientuid: oldDataTitular.clientuid,
                        personauid: oldDataTitular.personauid,
                    },
                    mnPersonaConyugueRequest: bodyConyuge
                },
                mnEmpresaEmpleadorInsertUpdateRequest: {
                    idenT_EMPRESA: empresaTitular.idenT_EMPRESA,
                    descL_RAZON_SOCIAL_EMPRESA: empresaTitular.descL_RAZON_SOCIAL_EMPRESA,
                    iD_TIPO_DOC: empresaTitular.iD_TIPO_DOC,
                    descC_TIPO_DOC: empresaTitular.descC_TIPO_DOC,
                    descL_NRO_DOC: empresaTitular.descL_NRO_DOC,
                    iD_ACTIVIDAD_ECONOMICA: titularLaboralesFinal.identActividadEconomica,
                    descC_ACTIVIDAD_ECONOMICA: titularLaboralesFinal.descripcionActividadEconomica,
                    descM_NOMBRE_VIA: titularLaboralesFinal.nombreVia,
                    descC_NUMERO: titularLaboralesFinal.numeroVia,
                    descC_INTERIOR_DEPARTAMENTO_PISO: titularLaboralesFinal.intDptoPiso,
                    descC_MANZANA: titularLaboralesFinal.manzana,
                    descC_LOTE: titularLaboralesFinal.lote,
                    descM_NOMBRE_AGRUPACION: titularLaboralesFinal.nombreAgrupacion,
                    idenT_PAIS: titularLaboralesFinal.identPais,
                    idenT_UBIGEO: Number(titularLaboralesFinal.empresaUbigeo),
                    descL_REFERENCIA: titularLaboralesFinal.referencia,
                    inD_ESTADO: true,
                    idenT_USUARIO: Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
                    descC_TELEFONO: titularLaboralesFinal.telefono,
                    descC_ANEXOS: titularLaboralesFinal.anexo,
                    descC_CORREO: titularLaboralesFinal.correo,
                },
                mnEmpresaEmpleadorConyugeInsertUpdateRequest: boyEmpresaConyuge
            };

            Promise.all([
                RequestManager.GetMnActualizarDatosAdicionales(body)
            ]).then(response => {
                if(response[0].data.content.resultado === 200){
                    toast.success("Actualizado correctamente",{ duration: appConfigKey.keyDurationToast });
                    setBtnModal();
                    cargarDataDatosAdicionales();
                }
                else{
                    toast.error(response[0].data.content.mensaje,{ duration: appConfigKey.keyDurationToast });
                }
            });

            //ACCIONES POR DEFECTO AL TERMINAR DE ENVIAR LOS DATOS DEL FORMULARIO
            setTitularDatosFinal(null);
            setTitularDomicilioFinal(null);
            setTitularLaboralesFinal(null);
            setConyugeDatosFinal(null);
            setConyugeLaboralesFinal(null);
            resetSendClick();
            handleClose();
        }

    }, [titularDatosFinal, titularDomicilioFinal, titularLaboralesFinal, conyugeDatosFinal, conyugeLaboralesFinal]);

    return (
        <Dialog open={open} onClose={handleClose} maxWidth="lg">
            <DialogTitle id="alert-dialog-title"><strong>DATOS ADICIONALES SOLICITUD</strong></DialogTitle>
            <DialogContent>
                <TitularDatosCard
                    data={data?.oldDataTitular}
                    listPais={listPais}
                    listProfesion={listProfesion}
                    listNivelEducacion={listNivelEdu}
                    setTitularDatosFinal={setTitularDatosFinal}
                    clickSubmit={clickSubmit}
                />
                <TitularDomicilioCard
                    data={data?.oldDataTitular}
                    listTipoVivienda={listTipoVivienda}
                    listTiempoResidencia={listTiempoResidencia}
                    setTitularDomicilioFinal={setTitularDomicilioFinal}
                    clickSubmit={clickSubmit}
                />
                <TitularDatosLaboralesCard
                    data={data?.oldDataTitular}
                    empresaTitular={empresaTitular}
                    listActividadEconomica={listActividadEconomica}
                    listPais={listPais}
                    listProfesion={listProfesion}
                    setTitularLaboralesFinal={setTitularLaboralesFinal}
                    clickSubmit={clickSubmit}
                />
                {(
                    viewModelTitular.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ||
                    viewModelTitular.EstadoCivilId === appConfigKey.keyIdEstadoCivilConviviente
                ) && (data.oldDataConyuge !== undefined && data.oldDataConyuge !== null) && (
                        <>
                            <ConyugeDatosCard
                                titularData={viewModelTitular}
                                conyugeData={data.oldDataConyuge}
                                listPais={listPais}
                                listProfesion={listProfesion}
                                listNivelEducacion={listNivelEdu}
                                setConyugeDatosFinal={setConyugeDatosFinal}
                                clickSubmit={clickSubmit}
                            />
                            {(
                                viewModelConyuge.CategoriaLaboralId !== appConfigKey.keyIdSinCategoria && 
                                viewModelConyuge.CategoriaLaboralId !== 0
                            )&&(
                            <>
                            <ConyugeDatosLaboralesCard
                                titularData={viewModelTitular}
                                conyugeData={data.oldDataConyuge}
                                listActividadEconomica={listActividadEconomica}
                                listPais={listPais}
                                listProfesion={listProfesion}
                                setConyugeLaboralesFinal={setConyugeLaboralesFinal}
                                clickSubmit={clickSubmit}
                                
                            />
                            </>
                            )}
                        </>
                    )}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cerrar</Button>
                <Button onClick={actualizarDatosAdicionales} autoFocus>Actualizar</Button>
            </DialogActions>
        </Dialog>
    );
};

export default DatosAdicionalesModal;