import MasterManager from "../../../services/origination/wapimaster.service";
import RequestManager from "../../../services/origination/wapirequest.service";

export const General = {
    AddSolicitudExcepcion: async (data: any) => {
        try {
            const response: any = await RequestManager.AddSolicitudExcepcion(data);
            const { isValid } = response.data;
            if (response) {
                return isValid;
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO DERIVAR A EXCEPCIÓN.');
            return false;
        }
    },

    GetSolicitudExceptionByIdSolicitud: async (identSolicitud: number) => {
        let item: any = null;
        try {
            const response: any = await RequestManager.GetSolicitudExceptionByIdSolicitud({
                "identSolicitud": identSolicitud
            });

            if (response.status !== 200) {
                return item;
            }
            const { content } = response.data;
            if (content) {
                item = content;
            }

        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO DERIVAR A EXCEPCIÓN.');
            return false;
        }
        return item;
    },
    GetMnResultadoEvaluacionInsert: async (data: any) => {
        try {
            await MasterManager.GetMnResultadoEvaluacionInsert(data);
            return true;
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA REGISTRAR LA EVALUACION DE LA SOLICITUD.');
            return false;
        }
    },
    GetSolicitudExcepcionValidarUsuarioByIdSolicitud: async (identSolicitud: number) => {
        let item: any = null;
        try {
            const response: any = await RequestManager.GetSolicitudExcepcionValidarUsuarioByIdSolicitud({
                "identSolicitud": identSolicitud
            });

            if (response.status !== 200) {
                return item;
            }
            const { content } = response.data;
            if (content) {
                item = content;
            }

        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO VALIDAR SOLICITUD EXCEPCIÓN CON USUARIO.');
            return false;
        }
        return item;
    },

    GetSolicitudEtapasInsert: async (data: any) => {
        try {
            const response = await RequestManager.GetSolicitudEtapasInsert(data);
            if (response?.status === 200) {
                return response.data.content;
            }
        } catch (error) {
            return null;
        }
    },
    GetSolicitudEtapasUpdate: async (data: any) => {
        try {
            const response = await RequestManager.GetSolicitudEtapasUpdate(data);
            if (response?.status === 200) {
                return response.data.content;
            }
        } catch (error) {
            return null;
        }
    },
}

export const ObtenerListas = {
    GetSolicitudExcepcionLiberacionById: async (identSolicitudExcepcion: number) => {
        const lista: any = [];
        try {
            let response: any = await RequestManager.GetSolicitudExcepcionLiberacionById({
                "identSolicitudExcepcion": identSolicitudExcepcion
            })
            if (response.status !== 200) {
                return lista;
            }

            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LA EVALUACION DE LA SOLICITUD.');
        }
        return lista;

    },

    GetSolicitudExcepcionLiberacionByIdSolicitud: async (identSolicitud: number) => {
        const lista: any = [];
        try {
            let response: any = await RequestManager.GetSolicitudExcepcionLiberacionByIdSolicitud({
                "identSolicitud": identSolicitud
            })
            if (response.status !== 200) {
                return lista;
            }

            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LA EVALUACION DE LA SOLICITUD.');
        }
        return lista;

    },
    GetMnEvaluacionSolicitud: async (identSolicitud: number) => {
        const lista: any = [];
        try {
            let response: any = await MasterManager.GetMnEvaluacionSolicitud(identSolicitud);

            if (response.status !== 200) {
                return lista;
            }

            const { content } = response.data;
            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LA EVALUACION DE LA SOLICITUD.');
        }
        return lista;

    },

    GetSolicitudEtapas: async (identSolicitudEtapas: any, identSolicitud: any) => {
        const lista: any = [];

        try {
            let response: any = await RequestManager.GetSolicitudEtapas({
                "identSolicitudEtapas": identSolicitudEtapas,
                "identSolicitud": identSolicitud
            })

            if (response.status !== 200) {
                return lista;
            }

            const { content } = response.data;

            if (content) {
                content.forEach((element: any) => {
                    lista.push(element);
                });
            }
        } catch (error) {
            console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER ETAPAS POR SOLICITUD');
        }

        return lista;
    }
}