import { Box, Dialog, Tab, Tabs } from "@mui/material";
import { Toaster, toast } from "sonner";
import { ReactComponent as CloseIcon } from '../../../../../../assets/media/icons/ico-close-modal.svg'
import React, { useEffect, useState } from "react";
import { Button, IconButton, Switch, Typography } from "@mui/joy";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import './../../teamManagement.scss';
import { General, ObtenerListas } from "../../obtenerServicios";
import { appConfigKey } from "../../../../../../config/Config";
import LoadingProgress from "../../../../1.-shared/1.4.-loading/loading-progress/loading-progress";
import ValidacionAsignCatDialogHelper from "../../helpers/validacionAsignCatDialogHelper";


const AssignCategoriesDialog = (props: any) => {

    const [showLoading, setShowLoading] = useState(false);
    const [value, setValue] = React.useState('1');
    const [viewModelEtapaActivaUno, setviewModelEtapaActivaUno] = useState<boolean>(false);
    const [viewModelEtapaActivaDos, setviewModelEtapaActivaDos] = useState<boolean>(false);
    const [viewModelEtapaUno, setviewModelEtapaUno] = useState<any | null>(null);
    const [viewModelEtapaDos, setviewModelEtapaDos] = useState<any | null>(null);
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue);
    };
    const AsignacionesPorUsuario = async (identUsuario: number) => {
        const response = await ObtenerListas.UsuarioAsignacionById(identUsuario);

        setviewModelEtapaActivaUno(false);
        setviewModelEtapaActivaDos(false);
        setviewModelEtapaUno(ValidacionAsignCatDialogHelper.NewDataListaAsignacion());
        setviewModelEtapaDos(ValidacionAsignCatDialogHelper.NewDataListaAsignacion());

        if (response.length > 0) {
            let respEtapaUno: any = response?.find((x: any) => x.idEtapaAnalisisCredito === appConfigKey.KeyIdEtapaAnalisisCreditoUno)

            if (respEtapaUno?.etapaActiva) {
                setviewModelEtapaActivaUno(respEtapaUno?.etapaActiva);
            }

            if (respEtapaUno?.listUsuarioAsignacion) {
                setviewModelEtapaUno({
                    "ListaAsignacionPN": respEtapaUno?.listUsuarioAsignacion ? respEtapaUno?.listUsuarioAsignacion?.filter((x: any) => x.idCategoriaLaboral !== appConfigKey.keyId3raCategoríaPerRUC) : [],
                    "ListaAsignacionPJ": respEtapaUno?.listUsuarioAsignacion ? respEtapaUno?.listUsuarioAsignacion?.filter((x: any) => x.idCategoriaLaboral === appConfigKey.keyId3raCategoríaPerRUC) : []
                });
            }

            let respEtapaDos: any = response?.find((x: any) => x.idEtapaAnalisisCredito === appConfigKey.KeyIdEtapaAnalisisCreditoDos)
            if (respEtapaDos?.etapaActiva) {
                setviewModelEtapaActivaDos(respEtapaDos?.etapaActiva);
            }
            if (respEtapaDos?.listUsuarioAsignacion) {
                setviewModelEtapaDos({
                    "ListaAsignacionPN": respEtapaDos?.listUsuarioAsignacion ? respEtapaDos?.listUsuarioAsignacion?.filter((x: any) => x.idCategoriaLaboral !== appConfigKey.keyId3raCategoríaPerRUC) : [],
                    "ListaAsignacionPJ": respEtapaDos?.listUsuarioAsignacion ? respEtapaDos?.listUsuarioAsignacion?.filter((x: any) => x.idCategoriaLaboral === appConfigKey.keyId3raCategoríaPerRUC) : []
                });
            }
        }

    }

    const AsignacionInsertPorUsuario = async (request: any) => {
        const response = await General.UsuarioAsignacionInsert(request);
        if (response) {
            toast.success(appConfigKey.keyMsjGuardadoExitoso, { duration: appConfigKey.keyDurationToast });
            props.listarUsuarioAsignacion();
            props.handleClose();
        } else {
            toast.error(appConfigKey.keyMsjErrorEnServicio, { duration: appConfigKey.keyDurationToast });
        }
    }

    const handleAsingar = async () => {
        setShowLoading(true);
        let mergedListaAsignacionesUno: any[] = [...viewModelEtapaUno.ListaAsignacionPN, ...viewModelEtapaUno.ListaAsignacionPJ];
        let mergedListaAsignacionesDos: any[] = [...viewModelEtapaDos.ListaAsignacionPN, ...viewModelEtapaDos.ListaAsignacionPJ];

        let etapaRequestTemp: any = [];
        let etapaRequestItemTemp: any = {};

        etapaRequestItemTemp = {
            "idEtapaAnalisisCredito": appConfigKey.KeyIdEtapaAnalisisCreditoUno,
            "etapaActiva": viewModelEtapaActivaUno,
            "usuarioAsignacionRequest": mergedListaAsignacionesUno
        }
        etapaRequestTemp.push(etapaRequestItemTemp);

        etapaRequestItemTemp = {
            "idEtapaAnalisisCredito": appConfigKey.KeyIdEtapaAnalisisCreditoDos,
            "etapaActiva": viewModelEtapaActivaDos,
            "usuarioAsignacionRequest": mergedListaAsignacionesDos
        }
        etapaRequestTemp.push(etapaRequestItemTemp);


        const requestTemp = {
            "identUsuarioSeleccionado": props.data.identUsuario,
            "identUsuarioSesion": Number(localStorage.getItem('UserMn') ? localStorage.getItem('UserMn') : 0),
            "etapaRequest": etapaRequestTemp
        }

        await AsignacionInsertPorUsuario(requestTemp);
        setShowLoading(false);
    };

    const InitDefaultAsingacionCategoria = async () => {
        setShowLoading(true);
        setValue('1');
        await AsignacionesPorUsuario(props.data.identUsuario);
        setShowLoading(false);
    }

    useEffect(() => {
        if (props.open) {
            InitDefaultAsingacionCategoria();
        }
    }, [props.open]);

    return (
        <>
            {showLoading && <LoadingProgress />}
            <Dialog style={{ zIndex: 1000 }} open={props.open} sx={{
                '& .MuiDialog-paper': {
                    borderRadius: '15px',
                    width: '500px',
                    alignContent: 'center'
                }
            }}>
                <div className="reassign-request-dialog-content">
                    <button className="btn btn-sm change-rate-close-btn" onClick={props.handleClose}>
                        <CloseIcon />
                    </button>
                </div>

                <div className='reassign-request-dialog-title'>ASIGNAR CATEGORIAS</div>
                <div className='reassign-request-dialog-title'>{'USUARIO : ' + props.data?.usuario + ' - ROL :' + props.data?.rol}</div>
                <div className="cardTeamManagementDialog">
                    <Box sx={{ width: '90%' }}>
                        <Tabs
                            value={value}
                            onChange={handleChange}
                        >
                            <Tab value="1" label="Etapa 1" />
                            <Tab value="2" label="Etapa 2" />
                        </Tabs>
                        {value === '1' && (

                            <div className="marginTopDialog">
                                <div className="row g-2 mb-2 align-items-center">
                                    <div className="col-lg-6">
                                        Habilitar
                                    </div>
                                    <div className="col-lg-3">
                                        <Switch
                                            color={viewModelEtapaActivaUno ? "success" : "neutral"}
                                            checked={viewModelEtapaActivaUno}
                                            onChange={(event: any) => {
                                                setviewModelEtapaActivaUno(event.target.checked)
                                            }}
                                            slotProps={{
                                                track: {
                                                    children: (
                                                        <React.Fragment>
                                                            <Typography
                                                                component="span"
                                                                level="inherit"
                                                                sx={{ ml: "10px" }}
                                                            >
                                                                Si
                                                            </Typography>
                                                            <Typography
                                                                component="span"
                                                                level="inherit"
                                                                sx={{ mr: "8px" }}
                                                            >
                                                                No
                                                            </Typography>
                                                        </React.Fragment>
                                                    ),
                                                },
                                            }}
                                            sx={{
                                                "--Switch-thumbSize": "27px",
                                                "--Switch-trackWidth": "64px",
                                                "--Switch-trackHeight": "31px",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row g-2 mb-2 align-items-center">
                                    <div className="col-lg-12 text-center">
                                        PN
                                    </div>
                                </div>
                                {(viewModelEtapaUno?.ListaAsignacionPN?.length > 0 &&
                                    <div className="row g-2 mb-2 align-items-center">
                                        <div className="col-lg-6">
                                        </div>
                                        <div className="col-lg-3">
                                        </div>
                                        <div className="col-lg-3">
                                            <span className="fw-bold">Excepción</span>
                                        </div>
                                    </div>
                                )}
                                {viewModelEtapaUno?.ListaAsignacionPN.map((data: any, index: any) => (
                                    <div className="row g-2 mb-2 align-items-center">
                                        <div className="col-lg-6">
                                            {data.descripcionCategoriaLaboral}
                                        </div>
                                        <div className="col-lg-3">
                                            <Switch
                                                color={data.selectCategory ? "success" : "neutral"}
                                                checked={data.selectCategory}
                                                disabled={!viewModelEtapaActivaUno}
                                                onChange={(event: any) => {
                                                    const ListaAsignacionPNTemp = viewModelEtapaUno.ListaAsignacionPN;
                                                    ListaAsignacionPNTemp[index].selectCategory = event.target.checked;
                                                    setviewModelEtapaUno({ ...viewModelEtapaUno, ListaAsignacionPN: ListaAsignacionPNTemp })
                                                }}
                                                slotProps={{
                                                    track: {
                                                        children: (
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    level="inherit"
                                                                    sx={{ ml: "10px" }}
                                                                >
                                                                    Si
                                                                </Typography>
                                                                <Typography
                                                                    component="span"
                                                                    level="inherit"
                                                                    sx={{ mr: "8px" }}
                                                                >
                                                                    No
                                                                </Typography>
                                                            </React.Fragment>
                                                        ),
                                                    },
                                                }}
                                                sx={{
                                                    "--Switch-thumbSize": "27px",
                                                    "--Switch-trackWidth": "64px",
                                                    "--Switch-trackHeight": "31px",
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <Switch
                                                color={data.selectExcepcion ? "success" : "neutral"}
                                                checked={data.selectExcepcion}
                                                disabled={!viewModelEtapaActivaUno}
                                                onChange={(event: any) => {
                                                    const ListaAsignacionPNTemp = viewModelEtapaUno.ListaAsignacionPN;
                                                    ListaAsignacionPNTemp[index].selectExcepcion = event.target.checked;
                                                    setviewModelEtapaUno({ ...viewModelEtapaUno, ListaAsignacionPN: ListaAsignacionPNTemp })
                                                }}
                                                slotProps={{
                                                    track: {
                                                        children: (
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    level="inherit"
                                                                    sx={{ ml: "10px" }}
                                                                >
                                                                    Si
                                                                </Typography>
                                                                <Typography
                                                                    component="span"
                                                                    level="inherit"
                                                                    sx={{ mr: "8px" }}
                                                                >
                                                                    No
                                                                </Typography>
                                                            </React.Fragment>
                                                        ),
                                                    },
                                                }}
                                                sx={{
                                                    "--Switch-thumbSize": "27px",
                                                    "--Switch-trackWidth": "64px",
                                                    "--Switch-trackHeight": "31px",
                                                }}
                                            />
                                        </div>
                                    </div>))}

                                <div className="row g-2 mb-2 align-items-center">
                                    <div className="col-lg-12 text-center">
                                        PJ/PNN
                                    </div>
                                </div>
                                {(viewModelEtapaUno?.ListaAsignacionPJ?.length > 0 &&
                                    <div className="row g-2 mb-2 align-items-center">
                                        <div className="col-lg-6">
                                        </div>
                                        <div className="col-lg-3">
                                        </div>
                                        <div className="col-lg-3">
                                            <span className="fw-bold">Excepción</span>
                                        </div>
                                    </div>)}

                                {viewModelEtapaUno?.ListaAsignacionPJ.map((data: any, index: any) => (
                                    <div className="row g-2 mb-2 align-items-center">
                                        <div className="col-lg-6">
                                            {data.descripcionCategoriaLaboral}
                                        </div>
                                        <div className="col-lg-3">
                                            <Switch
                                                color={data.selectCategory ? "success" : "neutral"}
                                                checked={data.selectCategory}
                                                disabled={!viewModelEtapaActivaUno}
                                                onChange={(event: any) => {
                                                    const ListaAsignacionPJTemp = viewModelEtapaUno.ListaAsignacionPJ;
                                                    ListaAsignacionPJTemp[index].selectCategory = event.target.checked;
                                                    setviewModelEtapaUno({ ...viewModelEtapaUno, ListaAsignacionPJ: ListaAsignacionPJTemp })
                                                }}
                                                slotProps={{
                                                    track: {
                                                        children: (
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    level="inherit"
                                                                    sx={{ ml: "10px" }}
                                                                >
                                                                    Si
                                                                </Typography>
                                                                <Typography
                                                                    component="span"
                                                                    level="inherit"
                                                                    sx={{ mr: "8px" }}
                                                                >
                                                                    No
                                                                </Typography>
                                                            </React.Fragment>
                                                        ),
                                                    },
                                                }}
                                                sx={{
                                                    "--Switch-thumbSize": "27px",
                                                    "--Switch-trackWidth": "64px",
                                                    "--Switch-trackHeight": "31px",
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                            <Switch
                                                color={data.selectExcepcion ? "success" : "neutral"}
                                                checked={data.selectExcepcion}
                                                disabled={!viewModelEtapaActivaUno}
                                                onChange={(event: any) => {
                                                    const ListaAsignacionPJTemp = viewModelEtapaUno.ListaAsignacionPJ;
                                                    ListaAsignacionPJTemp[index].selectExcepcion = event.target.checked;
                                                    setviewModelEtapaUno({ ...viewModelEtapaUno, ListaAsignacionPJ: ListaAsignacionPJTemp })
                                                }}
                                                slotProps={{
                                                    track: {
                                                        children: (
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    level="inherit"
                                                                    sx={{ ml: "10px" }}
                                                                >
                                                                    Si
                                                                </Typography>
                                                                <Typography
                                                                    component="span"
                                                                    level="inherit"
                                                                    sx={{ mr: "8px" }}
                                                                >
                                                                    No
                                                                </Typography>
                                                            </React.Fragment>
                                                        ),
                                                    },
                                                }}
                                                sx={{
                                                    "--Switch-thumbSize": "27px",
                                                    "--Switch-trackWidth": "64px",
                                                    "--Switch-trackHeight": "31px",
                                                }}
                                            />
                                        </div>
                                    </div>))}

                            </div>
                        )}

                        {value === '2' && (
                            <div className="marginTopDialog">
                                <div className="row g-2 mb-2 align-items-center">
                                    <div className="col-lg-6">
                                        Habilitar
                                    </div>
                                    <div className="col-lg-3">
                                        <Switch
                                            color={viewModelEtapaActivaDos ? "success" : "neutral"}
                                            checked={viewModelEtapaActivaDos}
                                            onChange={(event: any) => {
                                                setviewModelEtapaActivaDos(event.target.checked)
                                            }}
                                            slotProps={{
                                                track: {
                                                    children: (
                                                        <React.Fragment>
                                                            <Typography
                                                                component="span"
                                                                level="inherit"
                                                                sx={{ ml: "10px" }}
                                                            >
                                                                Si
                                                            </Typography>
                                                            <Typography
                                                                component="span"
                                                                level="inherit"
                                                                sx={{ mr: "8px" }}
                                                            >
                                                                No
                                                            </Typography>
                                                        </React.Fragment>
                                                    ),
                                                },
                                            }}
                                            sx={{
                                                "--Switch-thumbSize": "27px",
                                                "--Switch-trackWidth": "64px",
                                                "--Switch-trackHeight": "31px",
                                            }}
                                        />
                                    </div>
                                </div>
                                <div className="row g-2 mb-2 align-items-center">
                                    <div className="col-lg-12 text-center">
                                        PN
                                    </div>
                                </div>

                                {viewModelEtapaDos?.ListaAsignacionPN.map((data: any, index: any) => (
                                    <div className="row g-2 mb-2 align-items-center">
                                        <div className="col-lg-6">
                                            {data.descripcionCategoriaLaboral}
                                        </div>
                                        <div className="col-lg-3">
                                            <Switch
                                                color={data.selectCategory ? "success" : "neutral"}
                                                checked={data.selectCategory}
                                                disabled={!viewModelEtapaActivaDos}
                                                onChange={(event: any) => {
                                                    const ListaAsignacionPNTemp = viewModelEtapaDos.ListaAsignacionPN;
                                                    ListaAsignacionPNTemp[index].selectCategory = event.target.checked;
                                                    setviewModelEtapaDos({ ...viewModelEtapaDos, ListaAsignacionPN: ListaAsignacionPNTemp })
                                                }}
                                                slotProps={{
                                                    track: {
                                                        children: (
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    level="inherit"
                                                                    sx={{ ml: "10px" }}
                                                                >
                                                                    Si
                                                                </Typography>
                                                                <Typography
                                                                    component="span"
                                                                    level="inherit"
                                                                    sx={{ mr: "8px" }}
                                                                >
                                                                    No
                                                                </Typography>
                                                            </React.Fragment>
                                                        ),
                                                    },
                                                }}
                                                sx={{
                                                    "--Switch-thumbSize": "27px",
                                                    "--Switch-trackWidth": "64px",
                                                    "--Switch-trackHeight": "31px",
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-3">

                                        </div>
                                    </div>))}

                                <div className="row g-2 mb-2 align-items-center">
                                    <div className="col-lg-12 text-center">
                                        PJ/PNN
                                    </div>
                                </div>
                                {viewModelEtapaDos?.ListaAsignacionPJ.map((data: any, index: any) => (
                                    <div className="row g-2 mb-2 align-items-center">
                                        <div className="col-lg-6">
                                            {data.descripcionCategoriaLaboral}
                                        </div>
                                        <div className="col-lg-3">
                                            <Switch
                                                color={data.selectCategory ? "success" : "neutral"}
                                                checked={data.selectCategory}
                                                disabled={!viewModelEtapaActivaDos}
                                                onChange={(event: any) => {
                                                    const ListaAsignacionPJTemp = viewModelEtapaDos.ListaAsignacionPJ;
                                                    ListaAsignacionPJTemp[index].selectCategory = event.target.checked;
                                                    setviewModelEtapaDos({ ...viewModelEtapaDos, ListaAsignacionPJ: ListaAsignacionPJTemp })
                                                }}
                                                slotProps={{
                                                    track: {
                                                        children: (
                                                            <React.Fragment>
                                                                <Typography
                                                                    component="span"
                                                                    level="inherit"
                                                                    sx={{ ml: "10px" }}
                                                                >
                                                                    Si
                                                                </Typography>
                                                                <Typography
                                                                    component="span"
                                                                    level="inherit"
                                                                    sx={{ mr: "8px" }}
                                                                >
                                                                    No
                                                                </Typography>
                                                            </React.Fragment>
                                                        ),
                                                    },
                                                }}
                                                sx={{
                                                    "--Switch-thumbSize": "27px",
                                                    "--Switch-trackWidth": "64px",
                                                    "--Switch-trackHeight": "31px",
                                                }}
                                            />
                                        </div>
                                        <div className="col-lg-3">
                                        </div>
                                    </div>))}

                            </div>
                        )}



                    </Box>
                </div>

                <div className="textAlignCenter">
                    <Button
                        size="md"
                        color="danger"
                        style={{ marginRight: "35px", margin: "10px" }}
                        sx={{ borderRadius: 24, width: 200 }}
                        onClick={handleAsingar}
                    >
                        Asignar
                        <IconButton style={{
                            backgroundColor: '#C41C1C',
                            color: 'white',
                            position: 'relative',
                            left: '29.33%',
                            right: '33.33%',
                            top: '2.33%',
                            bottom: '20%'
                        }}>
                            <KeyboardArrowRight />
                        </IconButton>
                    </Button>
                </div>

            </Dialog>
        </>
    )
}
export default AssignCategoriesDialog;
