import ArrowRight from '../../../../../assets/media/icons/ico_arrowright.svg';

const DocumentationSendValidation = (props: any) => {
    return (
        <div className={props.disabled ? 'documentation-btn-send btn-red-disabled' : 'documentation-btn-send' } onClick={props.handlerOnClick}>
            {props.text} <img src={ArrowRight} alt='arrow-right' />
        </div>
    );
};

export default DocumentationSendValidation;

