import ObservedMembersScreen from "../../1.-shared/1.5.-observed/1.5.12.-screen/ObservedMembersScreen";


const RiskProfile = () => {
  return (
    <ObservedMembersScreen title="Base Negativa Riesgos - Integrantes OBS" />
  );
};

export default RiskProfile;
