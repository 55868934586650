import { useContext, useEffect, useState } from "react";
import { MoneyInput, NumberDecimalInput, NumberInput, SelectInput, SimpleInput, SwitchInput } from "../../3.40.-SolicitudeStepOne/components/elements/inputs";
import TitleCard from "../../3.40.-SolicitudeStepOne/components/elements/titleCard";
import { DatosFinancierosDefault, DatosFinancierosInterface } from "./interfaces";
import { StepTwoContext, StepsContext } from "../../3.10.-base/providers";
import GeneralHelper from "../../../../helpers/GeneralHelper";
import { appConfigKey } from "../../../../../config/Config";
import OnlyView from "./onlyView";

const SolicitudeStepTwo = () => {

    const {

        clickNext,

        listMoneda,
        listaEstadosVehiculos,
        listaVehiculoYears,
        listaConcesionarios,
        listaSucursales,
        listaVendedores,
        listaUsosVehiculo,
        listaMarcas,
        listaModelos,
        listaVersiones,
        listaDestinoCredito,

        getObtenerSucursales,
        getObtenerVendores,
        getObtenerMarcas,
        getObtenerModelos,
        getVersiones,

        readonlyForm,
        stepTwoReadOnlyViewModel,

    } = useContext(StepsContext);

    const {
        datosFinancierosViewModel,
        setNewDatosFinanciamiento,
        modelOnlyView,
    } = useContext(StepTwoContext);

    const [viewModel, setViewModel] = useState<DatosFinancierosInterface>(DatosFinancierosDefault);

    const eventoContinuar = () => {
        if (clickNext !== 0) {
            setNewDatosFinanciamiento(viewModel);
        }
    };

    useEffect(() => setViewModel(datosFinancierosViewModel), [datosFinancierosViewModel]);
    useEffect(() => eventoContinuar(), [clickNext]);

    if (readonlyForm) {
        return (<OnlyView viewModel={stepTwoReadOnlyViewModel} />);
    }

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
            <TitleCard title={"DATOS DEL FINANCIAMIENTO"} />
            <div className="row g-2 mb-4 align-items-center">
                <div className="col-lg-2 cardObjets">
                    <SelectInput
                        title={"Estado del vehículo"}
                        options={listaEstadosVehiculos}
                        value={viewModel.EstadoVehiculoId}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, EstadoVehiculoId: newValue })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SelectInput
                        title={"Año del vehículo"}
                        options={listaVehiculoYears}
                        value={viewModel?.VehiculoYear}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, VehiculoYear: newValue })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        title={"Concesionario"}
                        options={listaConcesionarios}
                        value={viewModel.ConcesionarioId}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({ ...viewModel, ConcesionarioId: newValue });
                            getObtenerSucursales(newValue);
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        title={"Sucursal"}
                        options={listaSucursales}
                        value={viewModel.SucursalId}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({ ...viewModel, SucursalId: newValue });
                            getObtenerVendores(viewModel.ConcesionarioId, newValue);
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        title={"Vendedor"}
                        options={listaVendedores}
                        value={viewModel.VendedorId}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({ ...viewModel, VendedorId: newValue });
                            getObtenerMarcas(viewModel.SucursalId, newValue, viewModel.ConcesionarioId);
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        title={"Email vendedor"}
                        value={viewModel.VendedorEmail}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, VendedorEmail: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SelectInput
                        title={"Moneda"}
                        options={listMoneda}
                        value={viewModel.MonedaId}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, MonedaId: newValue })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <NumberDecimalInput
                        title={"Tipo de cambio"}
                        value={viewModel.TipoCambioBase}
                        disabled={readonlyForm}
                        longNumber={appConfigKey.keyLongTipoCambio}
                        numberOfDecimals={3}
                        onChange={(event: any) => {

                            const newTipoCambio = event.target.value;

                            if (Number(newTipoCambio) === 0) {
                                setViewModel({ ...viewModel, TipoCambio: newTipoCambio, TipoCambioBase: newTipoCambio });
                                return;
                            }

                            if (viewModel.MontoVehiculoBaseDolares === "" &&
                                viewModel.MontoVehiculoBaseSoles === "" &&
                                viewModel.MontoInicialBaseDolares === "" &&
                                viewModel.MontoInicialBaseSoles === "") {
                                setViewModel({
                                    ...viewModel,
                                    TipoCambio: newTipoCambio,
                                    TipoCambioBase: newTipoCambio,
                                    MontoVehiculoBaseDolares: "0.00",
                                    MontoVehiculoBaseSoles: "0.00",
                                    MontoInicialBaseDolares: "0.00",
                                    MontoInicialBaseSoles: "0.00",
                                    MontoVehiculoDolares: "0.00",
                                    MontoVehiculoSoles: "0.00",
                                    MontoInicialDolares: "0.00",
                                    MontoInicialSoles: "0.00"
                                });
                                return;
                            }

                            let tempMontoVehiculoDolares: number = Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoVehiculoDolares));
                            let tempMontoVehiculoSoles: number = Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoVehiculoSoles));
                            let tempMontoInicialDolares: number = Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoInicialDolares));
                            let tempMontoInicialSoles: number = Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoInicialSoles));

                            if (newTipoCambio === "") {
                                setViewModel({
                                    ...viewModel,
                                    TipoCambio: newTipoCambio,
                                    TipoCambioBase: newTipoCambio,
                                    MontoVehiculoBaseSoles: "0.00",
                                    MontoInicialBaseSoles: "0.00",
                                    MontoVehiculoSoles: "0.00",
                                    MontoInicialSoles: "0.00"
                                });
                                return;
                            }

                            if (viewModel.MontoVehiculoDolares !== "" && viewModel.MontoVehiculoDolares !== "0.00") {
                                tempMontoVehiculoSoles = tempMontoVehiculoDolares * Number(event.target.value);
                                tempMontoVehiculoSoles = Number(tempMontoVehiculoSoles.toFixed(2));
                            }

                            if (viewModel.MontoInicialDolares !== "" && viewModel.MontoInicialDolares !== "0.00") {
                                tempMontoInicialSoles = tempMontoInicialDolares * Number(event.target.value);
                                tempMontoInicialSoles = Number(tempMontoInicialSoles.toFixed(2));
                            }

                            let finalMontoVehiculoSoles = "0.00";
                            let finalMontoInicialSoles = "0.00";

                            if (tempMontoVehiculoSoles !== 0) {
                                finalMontoVehiculoSoles = GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoSoles));
                            }

                            if (tempMontoInicialSoles !== 0) {
                                finalMontoInicialSoles = GeneralHelper.AgregarComasAMiles(String(tempMontoInicialSoles));
                            }

                            setViewModel({
                                ...viewModel,
                                TipoCambio: event.target.value,
                                TipoCambioBase: event.target.value,
                                MontoVehiculoBaseSoles: finalMontoVehiculoSoles,
                                MontoInicialBaseSoles: finalMontoInicialSoles,
                                MontoVehiculoSoles: finalMontoVehiculoSoles,
                                MontoInicialSoles: finalMontoInicialSoles
                            });

                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <MoneyInput
                        startDecorator={"$"}
                        title={"Monto del vehículo"}
                        value={viewModel.MontoVehiculoBaseDolares}
                        disabled={readonlyForm}
                        onChange={(event: any) => {

                            const newValue = event.target.value;

                            if (viewModel.TipoCambio === "" && viewModel.InicialValue === "") {
                                setViewModel({ ...viewModel, MontoVehiculoBaseDolares: newValue, MontoVehiculoDolares: newValue });
                                return;
                            }

                            const newMontoVehiculoDolares = Number(GeneralHelper.QuitarComasAMiles(newValue));
                            const tempMontoVehiculoSoles = newMontoVehiculoDolares * Number(viewModel.TipoCambio);

                            if (viewModel.TipoCambio !== "" && viewModel.InicialValue === "") {
                                setViewModel({
                                    ...viewModel,
                                    MontoVehiculoBaseDolares: newValue,
                                    MontoVehiculoBaseSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoSoles)),
                                    MontoVehiculoDolares: newValue,
                                    MontoVehiculoSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoSoles)),
                                });
                                return;
                            }

                            const inicial = Number(viewModel.Inicial);
                            const tempMontoInicialDolaresNumber = newMontoVehiculoDolares * (inicial / 100);

                            if (viewModel.TipoCambio === "" && viewModel.InicialValue !== "") {
                                setViewModel({
                                    ...viewModel,
                                    InicialBase: inicial?.toFixed(2),
                                    InicialValue: inicial?.toFixed(2),
                                    MontoVehiculoBaseDolares: newValue,
                                    MontoInicialBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialDolaresNumber.toFixed(2))),
                                    MontoVehiculoDolares: newValue,
                                    MontoInicialDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialDolaresNumber.toFixed(2))),
                                });
                                return;
                            }

                            const tempMontoInicialSolesNumber = tempMontoInicialDolaresNumber * Number(viewModel.TipoCambio);
                            const tempMontoFinanciar = tempMontoVehiculoSoles - tempMontoInicialSolesNumber;
                            const tempMontoFinanciarDolares = newMontoVehiculoDolares - tempMontoInicialDolaresNumber;

                            setViewModel({
                                ...viewModel,
                                InicialBase: inicial?.toFixed(2),
                                InicialValue: inicial?.toFixed(2),
                                MontoVehiculoBaseDolares: event.target.value,
                                MontoVehiculoBaseSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoSoles)),
                                MontoInicialBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialDolaresNumber.toFixed(2))),
                                MontoInicialBaseSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialSolesNumber.toFixed(2))),
                                MontoFinanciarBase: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciar.toFixed(2))),
                                MontoFinanciarBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciarDolares.toFixed(2))),
                                MontoVehiculoDolares: event.target.value,
                                MontoVehiculoSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoSoles)),
                                MontoInicialDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialDolaresNumber.toFixed(2))),
                                MontoInicialSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialSolesNumber.toFixed(2))),
                                MontoFinanciar: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciar.toFixed(2))),
                                MontoFinanciarDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciarDolares.toFixed(2)))
                            });

                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <MoneyInput
                        startDecorator={"S/"}
                        title={"Monto del vehículo"}
                        value={viewModel.MontoVehiculoBaseSoles}
                        disabled={readonlyForm}
                        onChange={(event: any) => {

                            const newValue = event.target.value;

                            if (viewModel.TipoCambio === "" && viewModel.InicialValue === "") {
                                setViewModel({ ...viewModel, MontoVehiculoBaseSoles: newValue, MontoVehiculoSoles: newValue });
                                return;
                            }

                            const newMontoVehiculoSoles = Number(GeneralHelper.QuitarComasAMiles(newValue));
                            const tempMontoVehiculoDolares = newMontoVehiculoSoles / Number(viewModel.TipoCambio);

                            if (viewModel.TipoCambio !== "" && viewModel.InicialValue === "") {
                                setViewModel({
                                    ...viewModel,
                                    MontoVehiculoBaseSoles: newValue,
                                    MontoVehiculoBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoDolares.toFixed(2))),
                                    MontoVehiculoSoles: newValue,
                                    MontoVehiculoDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoDolares.toFixed(2))),
                                });
                                return;
                            }

                            const tempMontoInicialSolesNumber = newMontoVehiculoSoles * (Number(viewModel.Inicial) / 100);
                            const tempMontoFinanciar = Number(GeneralHelper.QuitarComasAMiles(newValue)) - tempMontoInicialSolesNumber;
                            const tempMontoFinanciarDolares = tempMontoFinanciar / Number(viewModel.TipoCambio);

                            if (viewModel.TipoCambio === "" && viewModel.InicialValue !== "") {
                                setViewModel({
                                    ...viewModel,
                                    MontoVehiculoBaseSoles: newValue,
                                    MontoInicialBaseSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialSolesNumber.toFixed(2))),
                                    MontoFinanciarBase: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciar.toFixed(2))),
                                    MontoFinanciarBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciarDolares.toFixed(2))),
                                    MontoVehiculoSoles: newValue,
                                    MontoInicialSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialSolesNumber.toFixed(2))),
                                    MontoFinanciar: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciar.toFixed(2))),
                                    MontoFinanciarDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciarDolares.toFixed(2)))
                                });
                                return;
                            }

                            const tempMontoInicialDolaresNumber = tempMontoInicialSolesNumber / Number(viewModel.TipoCambio);

                            setViewModel({
                                ...viewModel,
                                MontoVehiculoBaseSoles: newValue,
                                MontoVehiculoBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoDolares.toFixed(2))),
                                MontoInicialBaseSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialSolesNumber.toFixed(2))),
                                MontoInicialBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialDolaresNumber.toFixed(2))),
                                MontoFinanciarBase: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciar.toFixed(2))),
                                MontoFinanciarBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciarDolares.toFixed(2))),
                                MontoVehiculoSoles: newValue,
                                MontoVehiculoDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoDolares.toFixed(2))),
                                MontoInicialSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialSolesNumber.toFixed(2))),
                                MontoInicialDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialDolaresNumber.toFixed(2))),
                                MontoFinanciar: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciar.toFixed(2))),
                                MontoFinanciarDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciarDolares.toFixed(2)))
                            });

                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <MoneyInput
                        startDecorator={"%"}
                        title={"Inicial"}
                        value={viewModel.InicialValue}
                        disabled={readonlyForm}
                        onChange={(event: any) => {

                            const newValue = event.target.value;

                            if (Number(newValue) === 0) {
                                setViewModel({ ...viewModel, InicialBase: newValue, Inicial: newValue, InicialValue: newValue });
                                return;
                            }
                            let tempMontoVehiculoDolares = Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoVehiculoDolares));
                            let tempMontoVehiculoSoles = Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoVehiculoSoles));
                            let tempMontoInicialDolares = Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoInicialDolares));
                            let tempMontoInicialSoles = Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoInicialSoles));

                            if (tempMontoVehiculoDolares === 0 &&
                                tempMontoVehiculoSoles === 0 &&
                                tempMontoInicialDolares === 0 &&
                                tempMontoInicialSoles === 0) {
                                setViewModel({
                                    ...viewModel,
                                    InicialBase: newValue,
                                    MontoVehiculoBaseDolares: "0.00",
                                    MontoVehiculoBaseSoles: "0.00",
                                    MontoInicialBaseDolares: "0.00",
                                    MontoInicialBaseSoles: "0.00",
                                    Inicial: newValue,
                                    InicialValue: newValue,
                                    MontoVehiculoDolares: "0.00",
                                    MontoVehiculoSoles: "0.00",
                                    MontoInicialDolares: "0.00",
                                    MontoInicialSoles: "0.00"
                                });
                                return;
                            }

                            const tempInicial = Number(newValue) / 100;

                            if (tempMontoVehiculoDolares !== 0) {
                                tempMontoInicialDolares = tempMontoVehiculoDolares * tempInicial;
                            }

                            if (tempMontoVehiculoSoles !== 0) {
                                tempMontoInicialSoles = tempMontoVehiculoSoles * tempInicial;
                            }

                            const tempMontoFinanciar = tempMontoVehiculoSoles - tempMontoInicialSoles;
                            const tempMontoFinanciarDolares = tempMontoVehiculoDolares - tempMontoInicialDolares;

                            setViewModel({
                                ...viewModel,
                                InicialBase: newValue,
                                // MontoVehiculoBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoDolares.toFixed(2))),
                                // MontoVehiculoBaseSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoSoles.toFixed(2))),
                                MontoInicialBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialDolares.toFixed(2))),
                                MontoInicialBaseSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialSoles.toFixed(2))),
                                MontoFinanciarBase: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciar.toFixed(2))),
                                MontoFinanciarBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciarDolares.toFixed(2))),
                                Inicial: newValue,
                                InicialValue: newValue,
                                // MontoVehiculoDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoDolares.toFixed(2))),
                                // MontoVehiculoSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoSoles.toFixed(2))),
                                MontoInicialDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialDolares.toFixed(2))),
                                MontoInicialSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialSoles.toFixed(2))),
                                MontoFinanciar: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciar.toFixed(2))),
                                MontoFinanciarDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciarDolares.toFixed(2)))
                            });

                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <MoneyInput
                        title={"Monto inicial"}
                        startDecorator={"$"}
                        value={viewModel.MontoInicialBaseDolares}
                        disabled={readonlyForm}
                        onChange={(event: any) => {

                            let tempMontoVehiculoDolares = Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoVehiculoDolares));
                            let tempMontoVehiculoSoles = Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoVehiculoSoles));
                            // let tempMontoInicialDolares = Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoInicialDolares));
                            // let tempMontoInicialSoles = Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoInicialSoles));
                            let tempInicial = Number(viewModel.Inicial);
                            let tempTipoCambio = Number(viewModel.TipoCambio);

                            let tempMontoInicialDolares = Number(GeneralHelper.QuitarComasAMiles(event.target.value));
                            let tempMontoInicialSoles = tempMontoInicialDolares * tempTipoCambio;

                            let tempInicialPorcentaje = (tempInicial / 100);
                            // tempMontoInicialDolares = tempInicialPorcentaje === 0 ? 0 : tempMontoInicialDolares;
                            // tempMontoInicialSoles =  tempInicialPorcentaje === 0 ? 0 : tempMontoInicialDolares * tempTipoCambio;
                            // const tempMontoVehiculoSoles = tempMontoVehiculoDolares * tempTipoCambio;

                            let tempMontoFinanciar = tempMontoVehiculoSoles - tempMontoInicialSoles;
                            let tempMontoFinanciarDolares = tempMontoVehiculoDolares - tempMontoInicialDolares;
                            let newInicial = Number((tempMontoInicialDolares * 100) / tempMontoVehiculoDolares);

                            setViewModel({
                                ...viewModel,
                                MontoInicialDolares: event.target.value,
                                MontoInicialBaseDolares: event.target.value,
                                MontoVehiculoDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoDolares.toFixed(2))),
                                MontoVehiculoBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoDolares.toFixed(2))),
                                MontoInicialSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialSoles.toFixed(2))),
                                MontoInicialBaseSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialSoles.toFixed(2))),
                                MontoVehiculoSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoSoles.toFixed(2))),
                                MontoVehiculoBaseSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoSoles.toFixed(2))),
                                MontoFinanciar: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciar.toFixed(2))),
                                MontoFinanciarBase: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciar.toFixed(2))),
                                MontoFinanciarDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciarDolares.toFixed(2))),
                                MontoFinanciarBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciarDolares.toFixed(2))),
                                Inicial: newInicial,
                                InicialValue: String(newInicial.toFixed(2))
                            });
                            //myowasp(viewModel);
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <MoneyInput
                        title={"Monto inicial"}
                        startDecorator={"S/"}
                        value={viewModel.MontoInicialBaseSoles}
                        disabled={readonlyForm}
                        onChange={(event: any) => {

                            // const tempInicial = Number(viewModel.InicialBase);
                            // const tempTipoCambio = Number(viewModel.TipoCambio);

                            // const tempMontoInicialSoles = Number(GeneralHelper.QuitarComasAMiles(event.target.value));

                            // const tempMontoInicialDolares = tempTipoCambio === 0 ? 0 : (tempMontoInicialSoles / tempTipoCambio);

                            // const tempInicialPorcentaje = (tempInicial / 100);
                            // const tempMontoVehiculoSoles = tempInicialPorcentaje === 0 ? 0 : (tempMontoInicialSoles / tempInicialPorcentaje);
                            // const tempMontoVehiculoDolares = tempTipoCambio === 0 ? 0 : (tempMontoVehiculoSoles / tempTipoCambio);

                            // const tempMontoFinanciar = Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoVehiculoBaseSoles)) - tempMontoInicialSoles;
                            // const tempMontoFinanciarDolares = Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoVehiculoBaseDolares)) -  tempMontoInicialDolares;
                            // const newInicial = Number((tempMontoInicialSoles * 100) / Number(GeneralHelper.QuitarComasAMiles(viewModel.MontoVehiculoBaseSoles)));
                            // setViewModel({
                            //     ...viewModel,
                            //     MontoInicialSoles: event.target.value,
                            //     // MontoInicialBaseSoles: event.target.value,
                            //     MontoVehiculoSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoSoles.toFixed(2))),
                            //     // MontoVehiculoBaseSoles: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoSoles.toFixed(2))),
                            //     MontoInicialDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialDolares.toFixed(2))),
                            //     MontoInicialBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoInicialDolares.toFixed(2))),
                            //     MontoVehiculoDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoDolares.toFixed(2))),
                            //     // MontoVehiculoBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoVehiculoDolares.toFixed(2))),
                            //     MontoFinanciar: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciar.toFixed(2))),
                            //     MontoFinanciarBase: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciar.toFixed(2))),
                            //     MontoFinanciarDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciarDolares.toFixed(2))),
                            //     MontoFinanciarBaseDolares: GeneralHelper.AgregarComasAMiles(String(tempMontoFinanciarDolares.toFixed(2))),
                            //     InicialBase: String(newInicial.toFixed(2))
                            // });

                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        title={"Uso del vehículo"}
                        options={listaUsosVehiculo}
                        value={viewModel.UsoVehiculoId}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, UsoVehiculoId: newValue })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        title={"Destino del crédito"}
                        options={listaDestinoCredito}
                        value={viewModel.DestinoCreditoId}
                        disabled={readonlyForm}
                        tooltip={"ACTIVIDADES DE PRODUCCIÓN: Si el crédito tiene por finalidad elaborar un producto destinado a la venta\nCOMERCIALIZACIÓN: Si el crédito financiará generación de estrategias de promoción y venta de productos o servicios.\nCONSUMO PERSONAL: Si el crédito tiene la finalidad de atender el pago de bienes, servicios o gastos no relacionados con la actividad empresarial\nPRESTACIÓN DE SERVICIOS: Si el crédito tiene por finalidad un tipo de actividad que consiste en realizar un trabajo. En este caso, no entrega algo material, como un producto\nPRESTACIÓN DE SERVICIOS-TAXI: Si el crédito tiene por finalidad un tipo de actividad que consiste en realizar un trabajo, a diferencia de Prestación de Servicios, este servicio es Taxi.\n"}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, DestinoCreditoId: newValue })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        title={"Marca"}
                        options={listaMarcas}
                        value={viewModel.MarcaId}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({ ...viewModel, MarcaId: newValue });
                            getObtenerModelos(newValue, viewModel.SucursalId, viewModel.VendedorId, viewModel.ConcesionarioId);
                        }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        title={"Modelo"}
                        options={listaModelos}
                        value={viewModel.ModeloId}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({ ...viewModel, ModeloId: newValue });
                            getVersiones(newValue, viewModel.SucursalId, viewModel.VendedorId, viewModel.ConcesionarioId);
                        }}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SelectInput
                        title={"Versión"}
                        options={listaVersiones}
                        value={viewModel.VersionId}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, VersionId: newValue })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <MoneyInput
                        title={"Monto a financiar"}
                        required={false}
                        startDecorator={viewModel.MonedaId === 1 ? "S/" : "$"}
                        disabled={true}
                        value={viewModel.MonedaId === 1 ? viewModel.MontoFinanciar : viewModel.MontoFinanciarBase}
                    />
                </div>
                {false &&
                    <div className="col-lg-2 cardObjets">
                        <SwitchInput
                            disabled={readonlyForm}
                            title={"Aplica bono"}
                            checked={viewModel?.AplicaBono ?? true}
                            onChange={(event: any) => {
                                setViewModel({ ...viewModel, AplicaBono: event.target.checked })
                            }}
                        />
                    </div>
                }
            </div>
        </div>
    );

};

export default SolicitudeStepTwo;