import { useContext, useEffect, useState } from "react";
import { TitularContext } from "../contexts";
import { StepOneContext } from "../../3.10.-base/providers";

const TitularDatosProvider = ({ children }: any) => {
    
    const { titularData, ingresosTitularData } = useContext(StepOneContext);
    
    const [titularEstadoCivilId, setTitularEstadoCivilId] = useState<number>(0);
    const [separacionBienes, setSeparacionBienes] = useState<boolean>(false);

    useEffect(() => {
        if (typeof titularData !== "number") {
            setTitularEstadoCivilId(titularData.EstadoCivilId);
        }
    }, [titularData]);

    useEffect(() => {
        if (ingresosTitularData !== 0) {
            setSeparacionBienes(ingresosTitularData.separacionBienes);
        }
    }, [ingresosTitularData]);

    return (
        <TitularContext.Provider value={{ titularEstadoCivilId, setTitularEstadoCivilId, separacionBienes }}>
            {children}
        </TitularContext.Provider>
    );
};

export default TitularDatosProvider;