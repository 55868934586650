import { appDefaultValues } from "../../../../config/Config";
import { BTCrearPersonaFisicaDomicilio } from "./BTCrearPersonaFisicaDomicilio";

export class BTCrearPersonaFisicaDomicilios {
    private sdtJSPSsBTDomicilio: BTCrearPersonaFisicaDomicilio[];

    constructor() {
        this.sdtJSPSsBTDomicilio = appDefaultValues.ArrayDefault;
    }


    public getSdtJSPSsBTDomicilio(): BTCrearPersonaFisicaDomicilio[] {
        return this.sdtJSPSsBTDomicilio;
    }

    public setSdtJSPSsBTDomicilio(sdtJSPSsBTDomicilio: BTCrearPersonaFisicaDomicilio[]): void {
        this.sdtJSPSsBTDomicilio = sdtJSPSsBTDomicilio;
    }
}