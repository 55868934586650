import React, { ReactElement } from "react";
import CircularProgress from '@mui/material/CircularProgress';
import { Dropdown } from "react-bootstrap";
import { BsThreeDots } from "react-icons/bs";
import { appConfigBTTipoDocumento } from "../../../../../config/Config";

const DocumentationOptions = (props: any) => {
    const hiddenFileInput = React.useRef<HTMLInputElement>(document.createElement("input"));
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleClick = (event: any) => {
        hiddenFileInput.current.click();
    };

    const handleDeleteClick = async (event: any) => {
        setLoading(true);
        await props.handleDelete(props.data);
        setLoading(false);
    }

    const handleChange = async (event: any) => {
        event.preventDefault();
        setLoading(true);

        const fileUploaded = event.target.files[0];
        await props.handleFile(fileUploaded, props.data);

        if (hiddenFileInput.current) {
            hiddenFileInput.current.value = '';
        }

        setLoading(false);
    };

    const getOptions = () => {
        var childs: ReactElement<any, any>[] = [];

        childs.push(
            <>
                <Dropdown.Item disabled={props.data?.disabledFileUploader} onClick={handleClick}>Adjuntar</Dropdown.Item>
                <input type="file" ref={hiddenFileInput} onChange={(event: any) => handleChange(event)} style={{ display: 'none' }} />
            </>
        )

        let obligatoryDocument = [appConfigBTTipoDocumento.BoletaPagoTitular].includes(props?.data?.documentType);

        childs.push(
            <Dropdown.Item disabled={props.data?.disabledFileUploader || obligatoryDocument} onClick={handleDeleteClick}>Borrar</Dropdown.Item>
        )

        return childs;
    }

    var options = getOptions();

    return (
        <>
            {!loading ? (
                <div className={`col text-center ${options.length > 0 ? 'icon-document-link' : ''}`}>
                    {options.length > 0 &&
                        <Dropdown key={props.key}>
                            <Dropdown.Toggle as={props.as} >
                                <BsThreeDots />
                            </Dropdown.Toggle>
                            <Dropdown.Menu>
                                {options.map(child => child)}
                            </Dropdown.Menu>
                        </Dropdown>
                    }
                    {options.length == 0 &&
                        <BsThreeDots />
                    }

                </div>
            ) : (
                <div className="col text-center icon-document-link">
                    <CircularProgress size={12} />
                </div>

            )}
        </>
    );
};

export default DocumentationOptions;