import * as Accordion from '@radix-ui/react-accordion';
import { Table } from "@mui/joy";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormLabel from "@mui/joy/FormLabel";
import FormControl from "@mui/joy/FormControl";
import Input from "@mui/joy/Input";
import { inflateSync } from 'zlib';

export const ScoringInformacionEntidad = (props: any) => {
 const { informacionEntidad } = props;

 return (
  <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
   <Accordion.Root className="AccordionRoot" type="single" defaultValue="item-1" collapsible>
    <Accordion.Item className="AccordionItem" value="item-1">
     <Accordion.Header>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-4 textLabelHeadboard">
        <span className="fw-bold">INFORMACIÓN ENTIDADES SBS</span>
       </div>
       <div className="col-lg-4">
        <span className="fw-bold"> </span>
       </div>
       <div className="col-lg-4 textAlignRigth">
        <Accordion.Trigger>
         ^
        </Accordion.Trigger>
       </div>
      </div>
     </Accordion.Header>
     <Accordion.Content>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Entidades deuda directa</FormLabel>
         <FormLabel>{informacionEntidad.informacion.entidadesDeudaDirecta}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <FormControl>
         <FormLabel>Entidades deuda indirecta</FormLabel>
         <FormLabel>{informacionEntidad.informacion.entidadesDeudaIndirecta}</FormLabel>
        </FormControl>
       </div>
       <div className="col-lg-3 cardObjets">
        <FormControl>
         <FormLabel>Entidades con deuda</FormLabel>
         <FormLabel>{informacionEntidad.informacion.entidadesConDeuda}</FormLabel>
        </FormControl>
       </div>
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Entidades sin deuda</FormLabel>
         <FormLabel>{informacionEntidad.informacion.entidadesSinDeuda}</FormLabel>
        </LocalizationProvider>
       </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>N. entidades reportan</FormLabel>
         <FormLabel>{informacionEntidad.informacion.nEntidadReportan}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Documentos morosos</FormLabel>
         <FormLabel>{informacionEntidad.informacion.documentosMorosos}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Valor S/</FormLabel>
         <FormLabel>{informacionEntidad.informacion.valorS}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Valor $</FormLabel>
         <FormLabel>{informacionEntidad.informacion.valorD}</FormLabel>
        </LocalizationProvider>
       </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Indicador de deudor</FormLabel>
         <FormLabel>{informacionEntidad.informacion.IndicadorDeDeudor}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Peor condición deuda</FormLabel>
         <FormLabel>{informacionEntidad.informacion.peorCondicionDeuda}</FormLabel>
        </LocalizationProvider>
       </div>
      </div>

      <div className="row g-2 mb-2 align-items-center borderOtherIncome">
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>N. Cta Cte Cerrados</FormLabel>
         <FormLabel>{informacionEntidad.informacion.nCtaCteCerrados}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>N. TC anulados</FormLabel>
         <FormLabel>{informacionEntidad.informacion.nTCAnulados}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>N. protestos</FormLabel>
         <FormLabel>{informacionEntidad.informacion.nProtestos}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Fecha último protesto</FormLabel>
         <FormLabel>{informacionEntidad.informacion.fechaUltimoProtesto}</FormLabel>
        </LocalizationProvider>
       </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>N. protestos no aclarados</FormLabel>
         <FormLabel>{informacionEntidad.informacion.nProtestosNoAclarados}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Valor S/</FormLabel>
         <FormLabel>{informacionEntidad.informacion.valorSoles}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Valor $</FormLabel>
         <FormLabel>{informacionEntidad.informacion.valorDolares}</FormLabel>
        </LocalizationProvider>
       </div>
       <div className="col-lg-3 cardObjets">
        <LocalizationProvider dateAdapter={AdapterDayjs}>
         <FormLabel>Fecha última aclaración</FormLabel>
         <FormLabel>{informacionEntidad.informacion.fechaUltimaAclaracion}</FormLabel>
        </LocalizationProvider>
       </div>
      </div>

      <div className="row g-2 mb-2 align-items-center borderOtherIncome">
       <div className="col-lg-4 textLabelHeadboard">
        <span className="fw-bold">INFORMACIÓN FINANCIERA</span>
       </div>
       <div className="col-lg-4">
        <span className="fw-bold"> </span>
       </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-6 cardObjets align-top">
        <Table borderAxis="none" className="style-table align-top">
         <thead>
          <tr>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>TIPO DEUDA</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>ENTIDAD</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>MONTO DEUDA</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>CUOTA FC</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>CUOTA REAL</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>COMENTARIO</th>
          </tr>
         </thead>
         <tbody>
          {
           informacionEntidad.clasificacion.map((row: any) => {
            return (
             <tr>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.tipoDeuda}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.entidad}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.montoDeuda}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.cuotaFC}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.cuotaReal}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.comentario}</td>
             </tr>
            )
           })
          }
          <tr><td></td></tr>
          <tr><td></td></tr>
         </tbody>
        </Table>
       </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
       <div className="col-lg-6 cardObjets align-top">
        <Table borderAxis="none" className="style-table align-top">
         <thead>
          <tr>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>TIPO DEUDA</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>ENTIDAD</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>MONTO DEUDA</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>CUOTA FC</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>CUOTA REAL</th>
           <th style={{ width: "170px", backgroundColor: "#E1E1E1", }}>COMENTARIO</th>
          </tr>
         </thead>
         <tbody>
          {
           informacionEntidad.clasificacion.map((row: any) => {
            return (
             <tr>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.tipoDeuda}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.entidad}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.montoDeuda}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.cuotaFC}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.cuotaReal}</td>
              <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.comentario}</td>
             </tr>
            )
           })
          }
          <tr><td></td></tr>
          <tr><td></td></tr>
         </tbody>
        </Table>
       </div>
      </div>
     </Accordion.Content>
    </Accordion.Item>
   </Accordion.Root>
  </div>
 );
};

export default ScoringInformacionEntidad;