import { ReactComponent as CloseIcon } from '../../../../../../assets/media/icons/ico-close-modal.svg'
import { Button, FormControl, FormLabel, Textarea } from "@mui/joy";
import Select from '@mui/joy/Select';
import Option from '@mui/joy/Option';
import { Dialog, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import ArrowRight from '../../../../../../assets/media/icons/ico_arrowright.svg'
import './styles.scss';
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import { useEffect, useState } from "react";
import MasterManager from "../../../../../services/origination/wapimaster.service";
import { appConfigKey, appConfigPerfiles, appSecurityConfiguration } from '../../../../../../config/Config';
import { Toaster, toast } from "sonner";
import LoadingProgress from '../../../../1.-shared/1.4.-loading/loading-progress/loading-progress';



const ReassignRequestDialog = (props: any) => {

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [optionsEstado, setOptionsEstado] = useState<any[]>([]);
    const [dataReassignRequest, setReassignRequest] = useState<any[]>([]);
    const [requestBody, setRequestBody] = useState<any>({
        idenT_USUARIO_REASIGNADO: 0,
        comentario: "",
    });
    const [getSolicitud, setSolicitud] = useState<any>();

    const [modalDataReassignRequest, setModalDataReassignRequest] = useState<any>(null);


    useEffect(() => {
        setModalDataReassignRequest(props.data);
        if (props.data !== null) {
            GetMnSolicitud(props.data.idenT_SOLICITUD);
            GetMnSolicitudHistoricoReasignarUsuario(props.data.idenT_SOLICITUD);
            GetMnUsuarioPerfil(props.data?.idenT_PERFIL, props.data?.opcioN_BANDEJA);
        }
    }, [props.open]);


    const handleChange = (e: any) => {
        let { name, value, type } = e.target;
        if (type === 'number' && value === "") {
            value = 0;
        };
        if (type === 'select') {
            value = parseInt(value);
        };
        if (type === 'date' && value === "Invalid Date") {
            value = null
        }

        setRequestBody({
            ...requestBody, [name]: value
        });
    };

    const GetMnSolicitud = async (dat: string) => {
        var body = {
            "idenT_SOLICITUD": dat
        };

        await MasterManager.GetMnSolicitud(body)
            .then((result: any) => {
                setSolicitud(result.data.content[0])
            }).catch((error) => {
                //myowasp(error);
            });
    }

    // const GetMnUsuarioPerfilEjecutivo = async (dat: string) => {
    //     var body = {
    //         "idenT_PERFIL": dat
    //     };
    //     if(props.open) {
    //         await MasterManager.GetMnUsuarioPerfilEjecutivo(body)
    //         .then((result: any) => {
    //             setOptionsEstado(result.data.content)
    //         }).catch((error) => {
    //             //myowasp(error);
    //         });
    //     }
    // }
    const GetMnUsuarioPerfil = async (identPerfil: any, opcionBandeja: any) => {

        setRequestBody({
            idenT_USUARIO_REASIGNADO: 0,
            comentario: "",
        })

        if (identPerfil) {
            if (identPerfil !== appConfigPerfiles.CodigoGestor &&
                identPerfil !== appConfigPerfiles.CodigoJefeCredito &&
                identPerfil !== appConfigPerfiles.CodigoSupervisorCredito) {
                opcionBandeja = 0;
            }
        }

        var body = {
            "identPerfil": identPerfil,
            "opcionBandeja": opcionBandeja
        };
        if (props.open) {
            await MasterManager.GetMnUsuarioPerfil(body)
                .then((result: any) => {
                    let dataList: any = [];
                    if (result?.data?.content) {
                        dataList = result?.data?.content;
                        dataList = dataList?.filter((x: any) => x.idenT_USUARIO !== appSecurityConfiguration.SessionUserId)
                    }
                    setOptionsEstado(dataList)
                }).catch((error) => {
                    //myowasp(error);
                });
        }
    }

    const GetMnSolicitudHistoricoReasignarUsuario = async (dat: string) => {
        setReassignRequest([]);
        var body = {
            "idenT_SOLICITUD": dat
        };

        if (props.open) {
            await MasterManager.GetMnSolicitudHistoricoReasignarUsuario(body)
                .then((result: any) => {
                    setReassignRequest(result.data.content)
                }).catch((error) => {
                    //myowasp(error);
                });
        }
    }

    const SaveModal = async () => {

        if (!requestBody.idenT_USUARIO_REASIGNADO) {
            let campo = props.data.idenT_PERFIL === appConfigPerfiles.CodigoGestor ||
                props.data.idenT_PERFIL === appConfigPerfiles.CodigoJefeCredito ||
                props.data.idenT_PERFIL === appConfigPerfiles.CodigoSupervisorCredito ? 'Usuario' : 'Ejecutivo'
            toast.error(appConfigKey.KeyIdNuevoEjecutivoEmpty.replace('[CAMPO]', campo), { duration: appConfigKey.keyDurationToast });
            return;
        }

        if (!requestBody.comentario) {
            toast.error(appConfigKey.keyComentarioReasignarUsuarioEmpty, { duration: appConfigKey.keyDurationToast });
            return;
        }

        var body = {
            "idenT_SOLICITUD": modalDataReassignRequest.idenT_SOLICITUD,
            "idenT_USUARIO_ACTUAL": appSecurityConfiguration.SessionUserId,
            "idenT_USUARIO_REASIGNADO": requestBody.idenT_USUARIO_REASIGNADO,
            "comentario": requestBody.comentario,
            "idenT_USUARIO_CREADOR": appSecurityConfiguration.SessionUserId
        };

        if (props.open) {
            setIsLoading(true);
            await MasterManager.GetMnSolicitudHistoricoReasignarUsuarioInsert(body)
                .then((result: any) => {
                    
                    toast.success("Registro guardado correctamente.", { duration: appConfigKey.keyDurationToast });
                    GetMnSolicitudHistoricoReasignarUsuario(props.data.idenT_SOLICITUD);
                    setRequestBody({
                        idenT_USUARIO_REASIGNADO: 0,
                        comentario: "",
                    })
                    GetMnSolicitud(props.data.idenT_SOLICITUD);
                    setIsLoading(false);
                    props.handleClose();
                    props.GetMnbandejaSolicitud();


                }).catch((error) => {
                    setIsLoading(false);
                    //myowasp(error);
                });
        }
    };

    return (
        <Dialog style={{ zIndex: 1000 }} open={props.open} sx={{
            '& .MuiDialog-paper': {
                borderRadius: '15px',
            }
        }}>
            <Toaster position="top-center" richColors closeButton />
            {isLoading && <LoadingProgress />}
            <div className="reassign-request-dialog-content">
                <button className="btn btn-sm change-rate-close-btn" onClick={props.handleClose}>
                    <CloseIcon />
                </button>
                <div className='reassign-request-dialog-title'>RESIGNAR SOLICITUD</div>
                <div className="reassign-request-info-container">
                    <div className="reassign-request-info-row">
                        <span className="reassign-request-label-info">Nro Solicitud:</span>
                        <span className="reassign-request-label-info"><strong>{getSolicitud?.idenT_SOLICITUD}</strong></span>
                    </div>
                </div>
                <div className="textAlignRigth">
                    <FormControl>
                        <FormLabel>{modalDataReassignRequest?.idenT_PERFIL === appConfigPerfiles.CodigoGestor ||
                            modalDataReassignRequest?.idenT_PERFIL === appConfigPerfiles.CodigoJefeCredito ||
                            modalDataReassignRequest?.idenT_PERFIL === appConfigPerfiles.CodigoSupervisorCredito ? 'Usuario' : modalDataReassignRequest?.idenT_PERFIL === appConfigPerfiles.CodigoJefeBPO ? 'BPO' : 'Ejecutivo'} <span style={{ color: "red" }}> * </span></FormLabel>
                        <Select
                            name='idenT_USUARIO_REASIGNADO'
                            value={requestBody?.idenT_USUARIO_REASIGNADO}
                            onChange={(e, newValue) =>
                                handleChange({ target: { value: newValue, name: 'idenT_USUARIO_REASIGNADO', type: 'select' } })
                            }
                            indicator={<KeyboardArrowDown />}>
                            <Option value={0}>--Seleccione--</Option>
                            {optionsEstado.map((option) => (
                                <Option value={option.idenT_USUARIO}>{modalDataReassignRequest?.idenT_PERFIL === appConfigPerfiles.CodigoGestor ||
                                    modalDataReassignRequest?.idenT_PERFIL === appConfigPerfiles.CodigoJefeCredito ||
                                    modalDataReassignRequest?.idenT_PERFIL === appConfigPerfiles.CodigoSupervisorCredito ? (option.username + ' ' + '(' + (!option?.solicitudeS_ASIGNADAS ? '0' : option?.solicitudeS_ASIGNADAS) + ')') : option.username}</Option>
                            ))}
                        </Select>
                    </FormControl>
                </div>
                <div className="textAlignRigth cardObjets">
                    <FormLabel>
                        <span className='reassign-request-label'>Comentario </span>
                        <span style={{ color: "red" }}> * </span>
                    </FormLabel>
                    <div className="row g-2 mb-2 align-items-center">
                        <div className="col-lg-12">
                            <FormControl>
                                <Textarea
                                    placeholder="Ingresar texto"
                                    minRows={2}
                                    sx={{ mb: 1 }}
                                    name='comentario'
                                    onChange={handleChange}
                                    value={requestBody?.comentario}
                                />
                            </FormControl>
                        </div>
                    </div>
                </div>
                <div className='change-rate-dialog-btn-save'>
                    <Button
                        color="danger" sx={{
                            borderRadius: 24,
                            width: '100%'
                        }}
                        onClick={() => SaveModal()}
                    >Grabar
                        <span className='arrow-right'>
                            <img src={ArrowRight} alt='arrow-right' />
                        </span>
                    </Button>
                </div>
                <div className="textAlignRigth cardObjets">
                    <div>
                        <FormControl>
                            <FormLabel>
                                <span className='change-rate-label'>Historial</span>
                            </FormLabel>
                        </FormControl>
                    </div>
                </div>
                <div className='container-table'>
                    <TableContainer component={Paper}
                        sx={{
                            '& .MuiTable-root': {
                                backgroundColor: '#E1E1E1',
                            }
                        }}>
                        <Table sx={
                            {
                                '& .MuiTableHead-root': {
                                    backgroundColor: '#E1E1E1',
                                },
                                '& .MuiTableCell-root': {
                                    fontSize: '11px',
                                    padding: '7px',
                                }
                            }
                        }>
                            <TableHead>
                                <TableRow className='change-rate-table-container'>
                                    <TableCell style={{ minWidth: '70px' }}><strong>FECHA</strong></TableCell>
                                    <TableCell style={{ minWidth: '100px' }}><strong>ACTUAL</strong></TableCell>
                                    <TableCell style={{ minWidth: '100px' }}><strong>REASIGNADO</strong></TableCell>
                                    <TableCell style={{ minWidth: '200px' }}><strong>COMENTARIO</strong></TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {dataReassignRequest.map((row, index) => (
                                    <TableRow
                                        key={index} className={
                                            index % 2 === 0 ? 'change-rate-table-impar' : 'change-rate-table-par'
                                        }>
                                        <TableCell style={{ fontSize: '12px' }}>{row.fecha}</TableCell>
                                        <TableCell style={{ fontSize: '12px' }}>{row.usuariO_ACTUAL}</TableCell>
                                        <TableCell style={{ fontSize: '12px' }}>{row.usuariO_REASIGNADO}</TableCell>
                                        <TableCell style={{ fontSize: '12px' }}>{row.comentario}</TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </div>
            </div>
        </Dialog>
    );
}

export default ReassignRequestDialog;
