import { appDefaultValues } from "../../../../../../config/Config";

export interface LaboresAdicionalesConyuge {
    ActividadEconomicaId: number,
    PaisId: number,
    DepartamentoId: number,
    ProvinviaId: number,
    DistritoId: number,
    NombreVia: string,
    NumeroVia: string,
    IntDptoPiso: string,
    Manzana: string,
    Lote: string,
    NombreAgrupacion: string,
    Referencia: string,
    Telefono: string,
    Anexo: string,
    Correo: string,
}

export const LaboresAdicionalesConyugeDefault: LaboresAdicionalesConyuge = {

    ActividadEconomicaId: appDefaultValues.NumberDefault,
    PaisId: appDefaultValues.NumberDefault,
    DepartamentoId: appDefaultValues.NumberDefault,
    ProvinviaId: appDefaultValues.NumberDefault,
    DistritoId: appDefaultValues.NumberDefault,
    NombreVia: appDefaultValues.StringEmpty,
    NumeroVia: appDefaultValues.StringEmpty,
    IntDptoPiso: appDefaultValues.StringEmpty,
    Manzana: appDefaultValues.StringEmpty,
    Lote: appDefaultValues.StringEmpty,
    NombreAgrupacion: appDefaultValues.StringEmpty,
    Referencia: appDefaultValues.StringEmpty,
    Telefono: appDefaultValues.StringEmpty,
    Anexo: appDefaultValues.StringEmpty,
    Correo: appDefaultValues.StringEmpty,

}
