
import CronogramaCard from "./components/CronogramaCard";

const SolicitudeStepFour = () => {
  return (
    <div>
      <CronogramaCard />
    </div>
  );
};

export default SolicitudeStepFour;