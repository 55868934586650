import { appDefaultValues } from "../../../../config/Config";
import { BTCrearPersonaFisicaDatosLaborales } from "./BTCrearPersonaFisicaDatosLaborales";

export class BTCrearPersonaFisicaInformacionDatosLaborales {
    private sdtJSPSsBTDatosLaborales: BTCrearPersonaFisicaDatosLaborales[];

    constructor() {
        this.sdtJSPSsBTDatosLaborales = appDefaultValues.ArrayDefault;
    }

    public getSdtJSPSsBTDatosLaborales(): BTCrearPersonaFisicaDatosLaborales[] {
        return this.sdtJSPSsBTDatosLaborales;
    }

    public setSdtJSPSsBTDatosLaborales(sdtJSPSsBTDatosLaborales: BTCrearPersonaFisicaDatosLaborales[]): void {
        this.sdtJSPSsBTDatosLaborales = sdtJSPSsBTDatosLaborales;
    }
}