import { useContext, useEffect, useState } from "react";
import { StepsContext } from "../../../3.10.-base/providers/StepsProvider";
import TitleCard from "../../../3.40.-SolicitudeStepOne/components/elements/titleCard";
import { NumberInput, SelectAutocompleteInput, SelectInput, SimpleInput } from "../../../3.40.-SolicitudeStepOne/components/elements/inputs";
import { DatosLaboralesAdicionalesConyugeInterface, DatosLaboralesAdicionalesConyugeInterfaceDefault } from "./interfaces";
import { StepFiveContext } from "../../../3.10.-base/providers";
import { appConfigKey } from "../../../../../../config/Config";

const DatosLaboralesAdicionalesConyugeCard = () => {

    const {
        listActividadEconomica,
        listPais,
        listProfesion,
        clickNext,
        step,
        viewModelStepOneFinal,
        readonlyForm,
        viewModelStepFiveFinal
    } = useContext(StepsContext);

    const {
        listaProvinciasDepartamentosConyuge,
        listaCiudadesLocalidadesConyuge,
        listaBarriosColoniasConyuge,
        setNewDatosLaboralesAdicionalesConyugeData,
        getObtenerDepartamentosConyuge,
        getObtenerCiudadesLocalidadesConyuge,
        getObtenerBarriosColoniasConyuge,
    } = useContext(StepFiveContext);


    const [viewModel, setViewModel] = useState<DatosLaboralesAdicionalesConyugeInterface>(DatosLaboralesAdicionalesConyugeInterfaceDefault);

    useEffect(() => {
        if (viewModelStepOneFinal !== null && viewModelStepFiveFinal === null) {
            setViewModel({
                ...viewModel,
                identActividadEconomica: viewModelStepOneFinal.newConyugeData.ActividadEconomicaId,
                identPais: viewModelStepOneFinal.newLaboresAdicionalesConyugeData.PaisId,
                nombreVia: viewModelStepOneFinal.newLaboresAdicionalesConyugeData.NombreVia,
                numeroVia: viewModelStepOneFinal.newLaboresAdicionalesConyugeData.NumeroVia,
                intDptoPiso: viewModelStepOneFinal.newLaboresAdicionalesConyugeData.IntDptoPiso,
                manzana: viewModelStepOneFinal.newLaboresAdicionalesConyugeData.Manzana,
                lote: viewModelStepOneFinal.newLaboresAdicionalesConyugeData.Lote,
                nombreAgrupacion: viewModelStepOneFinal.newLaboresAdicionalesConyugeData.NombreAgrupacion,
                referencia: viewModelStepOneFinal.newLaboresAdicionalesConyugeData.Referencia,
                identCargoLaboral: viewModelStepOneFinal.newConyugeData.CargoLaboralId,
                descripcionCargo: viewModelStepOneFinal.newConyugeData.CargoLaboral,
                telefono: viewModelStepOneFinal.newLaboresAdicionalesConyugeData.Telefono,
                anexo: viewModelStepOneFinal.newLaboresAdicionalesConyugeData.Anexo,
                correo: viewModelStepOneFinal.newLaboresAdicionalesConyugeData.Correo,
                identDistritos: viewModelStepOneFinal.newLaboresAdicionalesConyugeData.DistritoId,
                identProvincias: viewModelStepOneFinal.newLaboresAdicionalesConyugeData.ProvinviaId,
                identDepartamentos: viewModelStepOneFinal.newLaboresAdicionalesConyugeData.DepartamentoId
            });

            if (viewModelStepOneFinal.newLaboresAdicionalesConyugeData.PaisId > 0) {
                getObtenerDepartamentosConyuge(viewModelStepOneFinal.newLaboresAdicionalesConyugeData.PaisId)
                if (viewModelStepOneFinal.newLaboresAdicionalesConyugeData.DepartamentoId > 0) {
                    getObtenerCiudadesLocalidadesConyuge(viewModelStepOneFinal.newLaboresAdicionalesConyugeData.PaisId,
                        viewModelStepOneFinal.newLaboresAdicionalesConyugeData.DepartamentoId)
                    if (viewModelStepOneFinal.newLaboresAdicionalesConyugeData.ProvinviaId > 0) {
                        getObtenerBarriosColoniasConyuge(viewModelStepOneFinal.newLaboresAdicionalesConyugeData.PaisId,
                            viewModelStepOneFinal.newLaboresAdicionalesConyugeData.DepartamentoId,
                            viewModelStepOneFinal.newLaboresAdicionalesConyugeData.ProvinviaId)
                    }
                }
            }
        }

    }, [viewModelStepOneFinal]);

    useEffect(() => {

        if (viewModelStepFiveFinal !== null) {

            const { newDatosLaboralesAdicionalesConyugeData } = viewModelStepFiveFinal;

            if (newDatosLaboralesAdicionalesConyugeData.identPais > 0) {
                
                getObtenerDepartamentosConyuge(newDatosLaboralesAdicionalesConyugeData.identPais);

                if (newDatosLaboralesAdicionalesConyugeData.identDepartamentos > 0) {
                    
                    getObtenerCiudadesLocalidadesConyuge(
                        newDatosLaboralesAdicionalesConyugeData.identPais,
                        newDatosLaboralesAdicionalesConyugeData.identDepartamentos
                    );

                    if (newDatosLaboralesAdicionalesConyugeData.identProvincias > 0) {

                        getObtenerBarriosColoniasConyuge(
                            newDatosLaboralesAdicionalesConyugeData.identPais,
                            newDatosLaboralesAdicionalesConyugeData.identDepartamentos,
                            newDatosLaboralesAdicionalesConyugeData.identProvincias
                        );

                    }
                }
            }

            setViewModel(newDatosLaboralesAdicionalesConyugeData);
        }

    }, [viewModelStepFiveFinal]);

    useEffect(() => {
        if (clickNext !== 0 && step === 5) {
            setNewDatosLaboralesAdicionalesConyugeData(viewModel);
        }
    }, [clickNext]);

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
            <TitleCard title={`DATOS LABORALES DEL ${viewModelStepOneFinal.newTitularData.EstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ? "CÓNYUGE" : "CONVIVIENTE"}`} obligatorio={false}/>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectAutocompleteInput
                        name={"identActividadEconomica"}
                        title={"Actividad económica"}
                        options={listActividadEconomica}
                        value={viewModel.identActividadEconomica}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identActividadEconomica: newValue })}
                        required={false}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectAutocompleteInput
                        name={"identPais"}
                        title={"País"}
                        options={listPais}
                        value={viewModel.identPais}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel,
                                identPais: newValue,
                                identDepartamentos: 0,
                                identProvincias: 0,
                                identDistritos: 0
                            })
                            getObtenerDepartamentosConyuge(newValue);
                        }}
                        required={false}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identDepartamentos"}
                        title={"Departamentos"}
                        options={listaProvinciasDepartamentosConyuge}
                        value={viewModel.identDepartamentos}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel, identDepartamentos: newValue,
                                identProvincias: 0,
                                identDistritos: 0
                            })
                            getObtenerCiudadesLocalidadesConyuge(viewModel.identPais, newValue);
                        }}
                        required={false}
                    />
                </div>

            </div>
            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identProvincias"}
                        title={"Provincias"}
                        options={listaCiudadesLocalidadesConyuge}
                        value={viewModel.identProvincias}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({
                                ...viewModel, identProvincias: newValue,
                                identDistritos: 0
                            })
                            getObtenerBarriosColoniasConyuge(viewModel.identPais, viewModel.identDepartamentos, newValue);
                        }}
                        required={false}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identDistritos"}
                        title={"Distritos"}
                        options={listaBarriosColoniasConyuge}
                        value={viewModel.identDistritos}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => {
                            setViewModel({ ...viewModel, identDistritos: newValue })
                        }}
                        required={false}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"nombreVia"}
                        title={"Nombre de vía"}
                        required={false}
                        placeholder=""
                        value={viewModel.nombreVia}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, nombreVia: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"numeroVia"}
                        title={"Número"}
                        required={false}
                        placeholder=""
                        value={viewModel.numeroVia}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, numeroVia: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"intDptoPiso"}
                        title={"Int/Dpto/Piso"}
                        required={false}
                        placeholder=""
                        value={viewModel.intDptoPiso}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, intDptoPiso: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"manzana"}
                        title={"Manzana"}
                        required={false}
                        placeholder=""
                        value={viewModel.manzana}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, manzana: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput
                        name={"lote"}
                        title={"Lote"}
                        required={false}
                        placeholder=""
                        value={viewModel.lote}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, lote: event.target.value })}
                    />
                </div>
            </div>

            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"nombreAgrupacion"}
                        title={"Nombre agrupación"}
                        required={false}
                        placeholder=""
                        value={viewModel.nombreAgrupacion}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, nombreAgrupacion: event.target.value })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"referencia"}
                        title={"Referencia"}
                        required={false}
                        placeholder=""
                        value={viewModel.referencia}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, referencia: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        name={"telefono"}
                        title={"Teléfono"}
                        required={false}
                        placeholder=""
                        value={viewModel.telefono}
                        disabled={readonlyForm}
                        longNumber={9}
                        onChange={(event: any) => setViewModel({ ...viewModel, telefono: event.target.value })}
                    />
                </div>
                <div className="col-lg-2 cardObjets">
                    <NumberInput
                        name={"anexo"}
                        title={"Anexo"}
                        required={false}
                        placeholder=""
                        value={viewModel.anexo}
                        disabled={readonlyForm}
                        longNumber={9}
                        onChange={(event: any) => setViewModel({ ...viewModel, anexo: event.target.value })}
                    />
                </div>
            </div>

            <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"correo"}
                        title={"Correo"}
                        required={false}
                        placeholder=""
                        value={viewModel.correo}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, correo: event.target.value })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        name={"identCargoLaboral"}
                        title={"Cargo laboral"}
                        required={false}
                        options={listProfesion}
                        value={viewModel.identCargoLaboral}
                        disabled={readonlyForm}
                        onChange={(event: any, newValue: any) => setViewModel({ ...viewModel, identCargoLaboral: newValue })}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput
                        name={"descripcionCargo"}
                        title={"Descripción cargo"}
                        required={false}
                        placeholder=""
                        value={viewModel.descripcionCargo}
                        disabled={readonlyForm}
                        onChange={(event: any) => setViewModel({ ...viewModel, descripcionCargo: event.target.value })}
                    />
                </div>
            </div>
        </div>
    );
};

export default DatosLaboralesAdicionalesConyugeCard;