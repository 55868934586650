import React from "react";
import './styles.scss';
import { Dialog } from "@mui/material";

const Mensaje = ({ open, handleClose, titulo, mensaje }: any) => {
  return (
    <Dialog open={open} maxWidth={'lg'} >
      <div className='request-dialong-content'>
        <div className='request-dialog-title'>{titulo}</div>
        <div className='request-dialog-list-content'>
          <div className="row g-2 mb-2 align-items-center">
            <div className="col-lg-8 cardObjets align-top">
              <p>{mensaje}</p>
            </div>
          </div>
        </div>
        <div className='request-dialog-btn-close cursor-pointer' onClick={handleClose}>Cerrar</div>
      </div>
    </Dialog>
  )
}

export const CardTitleSummary = ({ solicitud }: any) => {

  const [DialogOpen, setDialogOpen] = React.useState<boolean>(false);
  const handleDialogClose = () => {
    setDialogOpen(false);
  };
  const [titulo, setTitulo] = React.useState('');
  const [mensaje, setMensaje] = React.useState('');

  const handleObservacion = (e: any) => {
    setTitulo("OBSERVACIÓN")
    setMensaje(solicitud.observacion)
    setDialogOpen(true);
  };

  const handleSustento = (e: any) => {
    setTitulo("SUSTENTO EXCEPCIÓN")
    setMensaje(solicitud.sustento)
    setDialogOpen(true);
  };

  const handleOpenLink = async (event: any) => {
    // //myowasp(window.location.hostname);
    const link1 = 'http://localhost:3000/Solicitude/' + event.target.innerText;
    const link = `${window.location.origin}/Solicitude/${event.target.innerText}`;
    window.open(link, '_blank');
  };

  return (
    <>
      <div className="border rounded-10 p-3 p-md-2 mb-4 mb-md-4 card body-details">
        <div className="row g-2 mb-2 align-items-center item-body-details">
          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-4 col-md-6">
                <span>Nro. Solicitud</span>
              </div>
              <div className="col-lg-8 col-md-6">
                <span className={"text-link"} onClick={handleOpenLink}>{solicitud.idSolicitud}</span>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-4 col-md-6">
                <span>Cliente</span>
              </div>
              <div className="col-lg-8 col-md-6">
                <span className="text-negrita">{solicitud.nombreTitular}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-6 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-2 col-md-6">
                <span>Ejecutivo</span>
              </div>
              <div className="col-lg-10 col-md-6">
                <span className="text-negrita">{solicitud.nombreEjecutivo}</span>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-2 col-md-6">
                <span>Analista</span>
              </div>
              <div className="col-lg-10 col-md-6">
                <span className="text-negrita">{solicitud.nombreAnalista}</span>
              </div>
            </div>
          </div>

          <div className="col-lg-3 col-md-12">
            <div className="row g-2 mb-2">
              <div className="col-lg-4 col-md-6">
                {solicitud?.observacion && <span>Observación</span>}
              </div>
              <div className="col-lg-8 col-md-6">
                <span  className={"text-link"} onClick={handleObservacion}>Ver observación</span>
              </div>
            </div>
            <div className="row g-2 mb-2">
              <div className="col-lg-4 col-md-6">
                {solicitud?.sustento && <span>Sustento</span>}
              </div>
              <div className="col-lg-8 col-md-6">
                {solicitud?.sustento && <span className={"text-negrita"} onClick={handleSustento}>Ver sustento excep.</span>}
              </div>
            </div>
          </div>
        </div>
      </div>

      <Mensaje open={DialogOpen} handleClose={handleDialogClose} titulo={titulo} mensaje={mensaje} />
    </>
  );
};

export default CardTitleSummary;
