import { appDefaultValues } from "../../../../config/Config";
import { BTCrearPersonaJuridicaIntegranteJuridico } from "./BTCrearPersonaJuridicaIntegranteJuridico";

export class BTCrearPersonaJuridicaIntegrantesJuridicos {
    private SdtsBTIntegranteJuridico: BTCrearPersonaJuridicaIntegranteJuridico[];

    constructor() {
        this.SdtsBTIntegranteJuridico = appDefaultValues.ArrayDefault;
    }

    public getSdtsBTIntegranteJuridico(): BTCrearPersonaJuridicaIntegranteJuridico[] {
        return this.SdtsBTIntegranteJuridico;
    }

    public setSdtsBTIntegranteJuridico(SdtsBTIntegranteJuridico: BTCrearPersonaJuridicaIntegranteJuridico[]): void {
        this.SdtsBTIntegranteJuridico = SdtsBTIntegranteJuridico;
    }

}