import React, { useEffect, useState } from "react";
import { Button, Table } from "@mui/joy";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { IconButton, Tooltip } from "@mui/material";
import GeneralHelper from "../../../helpers/GeneralHelper";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SimbolAdd from "@mui/icons-material/Add";
import Save from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";
import { appDefaultValues, appSecurityConfiguration } from "../../../../config/Config";
import TimeHelper from "../../../helpers/TimeHelper";
import { DatosCargos, DatosCargosDefault } from "../15.10.-base/interfaces";
import { DateInput, MoneyInput, NumberDecimalInput, NumberInput, SelectInput, SimpleInput, TextInput } from "../15.10.-base/elements/inputs";
import RequestManager from "../../../services/origination/wapirequest.service";
import { Dropdown } from "react-bootstrap";
import CustomDivToggle from "../15.10.-base/elements/customDivToggle/CustomDivToggle";
import { BsThreeDots, BsSearch } from "react-icons/bs";
import { toast } from "sonner";
import { AxiosError } from "axios";

export const CargosAuditor = (props: any) => {
  const [chkRequerido, setChkRequerido] = React.useState<boolean>(false);
  const [dataCargos, setDataCargos] = useState<DatosCargos>(DatosCargosDefault);
  const [dataListCargos, setDataListCargos] = useState<DatosCargos[]>([]);  
  const [flgShow, setFlgShow] = useState<boolean>(false);
  
  const onClickNuevo = () => {
    setFlgShow(true);
    setDataCargos(DatosCargosDefault);
  }

  const handleClickViewDataCargos = async (value: any) =>  {
    setFlgShow(true);
    const response = await RequestManager.GetMnInformeComercialCargosOtrasEntidadesById({ "identInformeComercialCargosOtrasEntidades": value });
    if (response.status === 200) {
        setDataCargos(response.data.content);
    }
}

const handleClickDeleteCargos = async (value: any) =>  {
    setFlgShow(false);
    setDataCargos(DatosCargosDefault);
    const response = await RequestManager.MnInformeComercialCargosOtrasEntidadesDelete({ "identInformeComercialCargosOtrasEntidades": value });
    if (response.status === 200) {
        console.log(response);
        setDataCargos(response.data.content);
        CargaInicial();
    }
}

const CargaInicial = async () => {
    const response = await RequestManager.GetInformeComercialCargosOtrasEntidades({ "identInformeComercial": props.dataInformeComercial.identInformeComercial });
    if (response.status === 200) {
        setDataListCargos(response.data.content);
    }
}

const onClickCancelar = () => {
    setFlgShow(false);
    setDataCargos(DatosCargosDefault);
}

const GuardarCargos = async() => {
    let resultado;
    let bodyInsert = {
        "identInformeComercial": dataCargos.identInformeComercial == 0 ? props.dataInformeComercial.identInformeComercial : dataCargos.identInformeComercial,
        "razonSocial": dataCargos.razonSocial,
        "razonSocialOtrasEntidades": dataCargos.razonSocialOtrasEntidades,
        "rucOtrasEntidades": dataCargos.rucOtrasEntidades,
        "cargoFuncion": dataCargos.cargoFuncion,
        "codigoSbs": dataCargos.codigoSbs,
        "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
    }
    let bodyUpdate = {
        "identInformeComercialCargosOtrasEntidades": dataCargos.identInformeComercialCargosOtrasEntidades,
        "identInformeComercial": dataCargos.identInformeComercial,
        "razonSocial": dataCargos.razonSocial,
        "razonSocialOtrasEntidades": dataCargos.razonSocialOtrasEntidades,
        "rucOtrasEntidades": dataCargos.rucOtrasEntidades,
        "cargoFuncion": dataCargos.cargoFuncion,
        "codigoSbs": dataCargos.codigoSbs,
        "identUsuarioModificador": String(appSecurityConfiguration.SessionUserId)
    }

    try {
      const response = dataCargos.identInformeComercialCargosOtrasEntidades == 0 ? 
      await RequestManager.MnInformeComercialCargosOtrasEntidadesInsert(bodyInsert) : 
      await RequestManager.MnInformeComercialCargosOtrasEntidadesUpdate(bodyUpdate);

      if (response.status === 200) {
        resultado = response.data.isValid;
        if (resultado === true) {
          if(dataCargos.identInformeComercialCargosOtrasEntidades == 0)
              {toast.success("Registro guardado correctamente", { duration: 5000 });}
          else{toast.success("Registro actualizado correctamente.", { duration: 5000 });}
        }
        else{
          toast.error("Ocurrió un error", { duration: 5000 });
        }
      };
      CargaInicial();

      setFlgShow(false);
      setDataCargos(DatosCargosDefault);      
    } catch (error: AxiosError | any) {            
      if (error.response && error.response.data.status === 400 && error.response.data.errors) {
          const validationErrors = error.response.data.errors;
          if (validationErrors) {
              for (const [field, messages] of Object.entries(validationErrors)) {                            
                  let message = messages as string[]; // Type assertion
                  toast.error(`${field}: ${message}`, { duration: 5000 });
              }
          } else {
              toast.error("Ocurrió un error", { duration: 5000 });
          }
      }
  }           
}

useEffect(() => {
    CargaInicial();
    // InitListas();
}, [props]);

    return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>VI. CARGOS II, III, IV OTRAS ENTIDADES</AccordionSummary>
          <AccordionDetails>
            <>
                <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-12 cardObjets align-top">
                        <Table borderAxis="none" className="style-table align-top">
                            <thead>
                                <tr>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>NOMBRE O RAZÓN SOCIAL</th>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>PART. OTRAS ENT. RAZÓN SOCIAL</th>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>PART. OTRAS ENT. RUC</th>
                                <th style={{ fontSize: "12px", width: "13%", backgroundColor: "#E1E1E1", }}>CARGO Y FUNCIÓN</th>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>CÓDIGO SBS</th>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>ESTADO</th>
                                <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>ACCIONES</th>
                                </tr>
                            </thead>
                        <tbody>
                        {
                            dataListCargos.length > 0 && dataListCargos.map((row: any, index: number) => {
                            return (
                            <tr key={row.identInformeComercialCargosOtrasEntidades}>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.razonSocial}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.razonSocialOtrasEntidades}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.rucOtrasEntidades}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.cargoFuncion}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.codigoSbs}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.estado}</td>
                            <td>
                              <Dropdown key={row.identInformeComercialCargosOtrasEntidades}>
                                <Dropdown.Toggle
                                  as={CustomDivToggle}
                                  style={{ cursor: "pointer" }}
                                >
                                  <BsThreeDots />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickViewDataCargos(
                                            row.identInformeComercialCargosOtrasEntidades
                                          )
                                        }
                                      >
                                        Editar
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickDeleteCargos(
                                            row.identInformeComercialCargosOtrasEntidades
                                          )
                                        }
                                      >
                                        Eliminar
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                            </td>
                            </tr>
                            )
                            })
                        }
                        </tbody>
                        </Table>
                    </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-12 cardObjets bottom-right">
                      <Button
                          size="md"
                          color="neutral"
                          variant="outlined"
                          endDecorator={<SimbolAdd />}
                          sx={{ borderRadius: 24, width: 1/8 }}
                          // disabled={props.disabled}
                          onClick={onClickNuevo}>Nuevo
                      </Button>
                  </div>
                </div>
                { flgShow && <div>        
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-4 cardObjets">
                      <SimpleInput
                          title={"Nombre / Razón Social"}
                          placeholder="Ingresar"
                          value={dataCargos.razonSocial}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataCargos({ ...dataCargos, razonSocial: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-4 cardObjets">
                      <SimpleInput
                          title={"Part. otras ent. razón social"}
                          placeholder="Ingresar"
                          value={dataCargos.razonSocialOtrasEntidades}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataCargos({ ...dataCargos, razonSocialOtrasEntidades: event.target.value })}
                      />
                  </div>
                  <div className="col-lg-4 cardObjets">
                      <SimpleInput
                          title={"Part. otras ent. RUC"}
                          placeholder="Ingresar"
                          value={dataCargos.rucOtrasEntidades}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataCargos({ ...dataCargos, rucOtrasEntidades: event.target.value })}
                      />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-3 cardObjets">
                        <SimpleInput
                            title={"Cargo y función"}
                            placeholder="Ingresar"
                            value={dataCargos.cargoFuncion}
                            // disabled={readonlyForm}
                            onChange={(event: any) => setDataCargos({ ...dataCargos, cargoFuncion: event.target.value })}
                        />
                  </div>
                  <div className="col-lg-3 cardObjets">
                      <SimpleInput
                          title={"Codigo SBS"}
                          placeholder="Ingresar"
                          value={dataCargos.codigoSbs}
                          // disabled={readonlyForm}
                          onChange={(event: any) => setDataCargos({ ...dataCargos, codigoSbs: event.target.value })}
                      />
                  </div>
                </div>
                <div className="row g-2 mb-2 align-items-center ">
                    <div className="col-lg-12 cardObjets bottom-right">
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<Save />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            onClick={GuardarCargos}
                              // onClick={props.onClick}>{props.title}
                            >Guardar
                        </Button>
                        <Button
                            size="md"
                            color="neutral"
                            variant="outlined"
                            endDecorator={<Cancel />}
                            sx={{ borderRadius: 24, width: 1/8 }}
                            onClick={onClickCancelar}
                              // onClick={props.onClick}>{props.title}
                            >Cancelar
                        </Button>
                    </div>
                </div>
                </div>}
            </>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>


   
    </div>
  );
};

export default CargosAuditor;