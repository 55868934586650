import { appDefaultValues } from "../../../../config/Config";

export class BTAltaClienteReducidaRequest {
    sdtPersonaReducida: SdtPersonaReducida = new SdtPersonaReducida();
}

export class SdtPersonaReducida {
    estadoCivilId: string = appDefaultValues.StringEmpty;
    segundoApellido: string = appDefaultValues.StringEmpty;
    nacionalidadId: number = appDefaultValues.NumberDefault;
    nroDocumento: string = appDefaultValues.StringEmpty;
    primerApellido: string = appDefaultValues.StringEmpty;
    profesionId: number = appDefaultValues.NumberDefault;
    sexo: string = appDefaultValues.StringEmpty;
    tipoDocumentoId: number = appDefaultValues.NumberDefault;
    segundoNombre: string = appDefaultValues.StringEmpty;
    primerNombre: string = appDefaultValues.StringEmpty;
    fechaNacimiento: string = appDefaultValues.StringEmpty;
    paisDocumentoId: number = appDefaultValues.NumberDefault;
    fechaVencimiento: string = appDefaultValues.StringEmpty;
    correoElectronico: string = appDefaultValues.StringEmpty;
    conyugeUId: number = appDefaultValues.NumberDefault;
    paisDomicilioId: number = appDefaultValues.NumberDefault;
}
