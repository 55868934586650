import { appConfigEstadoSolicitud, appConfigKey, appConfigPerfiles } from "../../../../../config/Config";

const ValidacionStepsHelper = {
    IsSolicitudReadonly: (solicitud: any, perfilId: any) => {

        let readonly = true;
        let canResetData = false;
        let estadoSolicitud = solicitud ? solicitud?.estadoSolicitud : null;
        let idUsuarioCreador = solicitud ? solicitud?.idUsuarioCreador : null;
        let idUsuarioLogeador = Number(
            localStorage.getItem("UserMn") ? localStorage.getItem("UserMn") : 0
        )
        let plaftOrRiesgos = solicitud ? [solicitud?.idSituacionPlaft, solicitud?.idSituacionRiesgo].includes(appConfigKey.CodigoSituacionPlaftRiesgosObservada) : null;

        if (perfilId == appConfigPerfiles.CodigoEjecutivo || perfilId == appConfigPerfiles.CodigoJefeComercial) {

            if (idUsuarioCreador === idUsuarioLogeador) {
                if (estadoSolicitud == appConfigEstadoSolicitud.RegistroDeSolicitud ||
                    estadoSolicitud == appConfigEstadoSolicitud.Pendiente) {
                    readonly = false;
                    canResetData = true;
                } else if (
                    (estadoSolicitud == appConfigEstadoSolicitud.Aprobado || estadoSolicitud == appConfigEstadoSolicitud.Observado) &&
                    !plaftOrRiesgos
                ) {
                    canResetData = true;
                }
            } 

        } else if (perfilId == appConfigPerfiles.CodigoGestor
        ) {
        } else if (perfilId == appConfigPerfiles.CodigoPlaft
        ) {
        } else if (perfilId == appConfigPerfiles.CodigoRiesgos
        ) {
        } else if (perfilId == appConfigPerfiles.CodigoBPO
        ) {
        } else if (perfilId == appConfigPerfiles.CodigoJefeBPO
        ) {
        }
        return {
            readonly: readonly,
            canResetData: canResetData
        };
    }

}
export default ValidacionStepsHelper;