import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { IconButton, Table, Tooltip } from "@mui/joy";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import React, { useEffect } from 'react';
import { Button } from "@mui/joy";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';

import Tabs from '@mui/joy/Tabs';
import TabList from '@mui/joy/TabList';
import Tab, { tabClasses } from '@mui/joy/Tab';
import TabPanel from '@mui/joy/TabPanel';
import { appConfig, appConfigKey } from "../../../../config/Config";
import { MenuItem, Select, Stack } from "@mui/material";
import KeyboardArrowDown from "@mui/icons-material/KeyboardArrowDown";
import GeneralHelper from "../../../helpers/GeneralHelper";
import CalculateIcon from '@mui/icons-material/Calculate';
import { General } from "../13.10.-base/obtenerServicios";
import { toast } from "sonner";



const DtReevaluacionDet = ({ datosReevaluacion, setDatosReevaluacion, handleReevaluar, esModoLectura, clasificacion, setClasificacion, listaTipoDeuda, datosReevaluacionOrigen, clasificacionOriginal, setShow }: any) => {

  const [lineaDisponible, setLineaDisponible] = React.useState(0);
  const [lineaDisponibleReal, setLineaDisponibleReal] = React.useState("");
  const [listaTipoDeudaTemp, setListaTipoDeudaTemp] = React.useState([]);

  const handleLineaDisponibleReal = (event: any) => {
    let valorTemp = GeneralHelper.QuitarComasAMiles(event.target.value)
    if (/^\d*\.?\d{0,2}$/.test(valorTemp)) {
      setLineaDisponibleReal(valorTemp);
      setClasificacion({ ...clasificacion, linea_disponible_real: Number(valorTemp) })

    }

    const newDatosReevaluacion = datosReevaluacion?.resultado?.tabla1?.map((item: any, index: number) => {
      if (/^\d*\.?\d{0,2}$/.test(valorTemp)) {
        item.linea_disponible_real = valorTemp;
      }
      return item;
    });
    setDatosReevaluacion({ ...datosReevaluacion, tabla1: [...newDatosReevaluacion] })

  }

  const [esModoLecturaDtRee, setEsModoLecturaDtRee] = React.useState(false);

  const handleChangeCuotaReal = (e: any) => {
    const newDatosReevaluacion = datosReevaluacion?.resultado?.tabla2?.map((item: any, index: number) => {
      if (index.toString() === e.target.parentElement.dataset.index) {
        let valorTemp = GeneralHelper.QuitarComasAMiles(e.target.value);
        if (/^\d*\.?\d{0,2}$/.test(valorTemp)) {
          item.cuota_fc_real = valorTemp;
        }
      }
      return item;
    });
    setDatosReevaluacion({ ...datosReevaluacion, tabla2: [...newDatosReevaluacion] })
  }


  const handleChangeMontoDeuda = (e: any) => {
    const newDatosReevaluacion = datosReevaluacion?.resultado?.tabla2?.map((item: any, index: number) => {
      if (index.toString() === e.target.parentElement.dataset.index) {
        let valorTemp = GeneralHelper.QuitarComasAMiles(e.target.value);
        if (/^\d*\.?\d{0,2}$/.test(valorTemp)) {
          item.monto_deuda_real = valorTemp;
        }
      }
      return item;
    });
    setDatosReevaluacion({ ...datosReevaluacion, tabla2: [...newDatosReevaluacion] })
  }

  const handleComentario = (e: any) => {
    setClasificacion({ ...clasificacion, comentario: e.target.value });

    const newDatosReevaluacion = datosReevaluacion?.resultado?.tabla1?.map((item: any, index: number) => {
      item.comentario = e.target.value;
      return item;
    });
    setDatosReevaluacion({ ...datosReevaluacion, tabla1: [...newDatosReevaluacion] })
  }

  const handleChangeComentario = (e: any) => {
    const newDatosReevaluacion = datosReevaluacion?.resultado?.tabla2?.map((item: any, index: number) => {
      if (index.toString() === e.target.parentElement.dataset.index) {
        item.comentario = e.target.value;
      }
      return item;
    });
    setDatosReevaluacion({ ...datosReevaluacion, tabla2: [...newDatosReevaluacion] })
  }


  const handlEliminarDeuda = (row: any) => {
    const newDatosReevaluacion: any = datosReevaluacion;
    newDatosReevaluacion.resultado.tabla2 = datosReevaluacion?.resultado?.tabla2?.filter((x: any) => !x.id || x?.id !== row.id);
    setDatosReevaluacion({ ...datosReevaluacion })
  }

  const handlCalcularCuotaReal = async (row: any, numRow: number) => {

    if (!row?.tipo_deuda) return

    if (!row?.monto_deuda_real) {
      toast.error(appConfigKey.keyMsjCampoObligatorioDeudaReal, {
        duration: appConfigKey.keyDurationToastV1,
      });
      return;
    }

    let montoDeudaReal: any = GeneralHelper.QuitarComasAMiles(row?.monto_deuda_real);
    setShow(false);
    let response: any = await General.ObtenerExtExperianConfigFC(row?.tipo_deuda, montoDeudaReal);
    if (response.length > 0) {
      const ExperianConfigFC: any = response[0];
      let newCuotaReal: any = ExperianConfigFC?.operador === '/' ?
        (Number(montoDeudaReal) / ExperianConfigFC.factor) :
        (Number(montoDeudaReal) * ExperianConfigFC.factor);

      const newDatosReevaluacion = datosReevaluacion?.resultado?.tabla2?.map((item: any, index: number) => {
        if (index === numRow) {
          item.cuota_fc_real = newCuotaReal;
        }
        return item;
      });
      setDatosReevaluacion({ ...datosReevaluacion, tabla2: [...newDatosReevaluacion] });
    }
    setShow(true);
  }


  const handleRestaurarDeuda = () => {
    setClasificacion(clasificacionOriginal);
    setLineaDisponibleReal(!clasificacionOriginal?.linea_disponible_real ? '' : clasificacionOriginal?.linea_disponible_real);

    const newDataReevaluacion = { ...datosReevaluacion };
    if (newDataReevaluacion.resultado && newDataReevaluacion.resultado.tabla1) {
      const newDataReevaluacionTemp1 = datosReevaluacionOrigen.tabla1Original;
      const tabla1Temp: any = [];
      let filaTemp: any = null;

      newDataReevaluacionTemp1.map((item: any, index: number) => {
        filaTemp = {};
        filaTemp.linea_disponible = item.linea_disponible_original;
        filaTemp.linea_disponible_real = item.linea_disponible_real_original;
        filaTemp.comentario = item.comentario_original;
        filaTemp.nro_entidades_rep = item.nro_entidades_rep_original;
        filaTemp.nro_entidades_rep_c_deuda = item.nro_entidades_rep_c_deuda_original;
        filaTemp.nro_entidades_rep_d_dir = item.nro_entidades_rep_d_dir_original;
        filaTemp.nro_entidades_rep_d_indir = item.nro_entidades_rep_d_indir_original;
        filaTemp.nro_entidades_rep_s_deuda = item.nro_entidades_rep_s_deuda_original;
        filaTemp.periodo_rcc = item.periodo_rcc_original;
        filaTemp.por_deuda_cpp = item.por_deuda_cpp_original;
        filaTemp.por_deuda_def = item.por_deuda_def_original;
        filaTemp.por_deuda_dud = item.por_deuda_dud_original;
        filaTemp.por_deuda_normal = item.por_deuda_normal_original;
        filaTemp.por_deuda_per = item.por_deuda_per_original;
        tabla1Temp.push(filaTemp);
      });
      newDataReevaluacion.resultado.tabla1 = tabla1Temp;

    }
    if (newDataReevaluacion.resultado && newDataReevaluacion.resultado.tabla2) {

      const newDataReevaluacionTemp2 = datosReevaluacionOrigen?.tabla2Original?.filter((x: any) => !x?.id);
      const tabla2Temp: any = [];
      let filaTemp: any = null;

      newDataReevaluacionTemp2.map((item: any, index: number) => {
        filaTemp = {};
        filaTemp.comentario = item.comentario_original;
        filaTemp.cuota_fc = item.cuota_fc_original;
        filaTemp.cuota_fc_real = item.cuota_fc_real_original;
        filaTemp.entidad = item.entidad_original;
        filaTemp.monto_deuda = item.monto_deuda_original;
        filaTemp.monto_deuda_real = item.monto_deuda_real_original;
        filaTemp.solo_lectura = item.solo_lectura_original;
        filaTemp.tipo_deuda = item.tipo_deuda_original;
        tabla2Temp.push(filaTemp);
      });

      newDataReevaluacion.resultado.tabla2 = tabla2Temp;
    }
    setDatosReevaluacion(newDataReevaluacion);


  }

  const handleAgregarDeuda = () => {
    let nuevoItem: any = {
      "id": ((new Date()).getTime()),
      "tipo_deuda": appConfigKey.DescripTipoDeudaPorDefecto,
      "entidad": appConfigKey.keyDescripcionNinguna,
      "monto_deuda": 0,
      "monto_deuda_real": 0,
      "cuota_fc": 0,
      "cuota_fc_real": 0,
      "comentario": "",
      "solo_lectura": false
    }
    datosReevaluacion?.resultado?.tabla2?.push(nuevoItem);
    setDatosReevaluacion({ ...datosReevaluacion, datosReevaluacion })
  }

  useEffect(() => {
    setListaTipoDeudaTemp(!listaTipoDeuda ? [] : listaTipoDeuda);
    setEsModoLecturaDtRee(!esModoLectura ? false : esModoLectura);
    setLineaDisponible(clasificacion?.linea_disponible);
    setLineaDisponibleReal(!lineaDisponibleReal ? (!clasificacion?.linea_disponible_real ? '' : clasificacion?.linea_disponible_real) : lineaDisponibleReal);
  }, [esModoLectura, clasificacion])


  return (
    <>
      <div className="row g-2 mb-2 align-items-center">

        <div className="col-lg-2 cardObjets">
          <FormControl>
            <FormLabel>N. entidades reportan</FormLabel>
            <FormLabel>{clasificacion.nro_entidades_rep}</FormLabel>
          </FormControl>
        </div>

        <div className="col-lg-2 cardObjets">
          <FormControl>
            <FormLabel>Línea disponible S/</FormLabel>
            <FormLabel>{GeneralHelper.NumeroConCommas(lineaDisponible)}</FormLabel>
          </FormControl>
        </div>

        <div className="col-lg-2 cardObjets">
          <FormControl>
            <FormLabel>Línea disponible real S/  <span style={{ color: "red" }}> * </span>  </FormLabel>
            <Input
              name="nuevaLineaDisponible"
              placeholder="Ej: 00000000"
              disabled={esModoLecturaDtRee}
              value={GeneralHelper.AgregarComasAMiles(lineaDisponibleReal)}
              onChange={handleLineaDisponibleReal}
            />
          </FormControl>
        </div>
        {/*  <div className="col-lg-2 cardObjets">
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormLabel>N. entidades reportan <span style={{ color: "red" }}> * </span></FormLabel>
            <Input
              name="nroEntidadesReportan"
              placeholder=""
              disabled={esModoLecturaDtRee}
              value={clasificacion.nro_entidades_rep}
            />
          </LocalizationProvider>
        </div>*/}
        <div className="col-lg-6 cardObjets">
          <FormControl>
            <FormLabel>Comentario  {clasificacion?.linea_disponible_real !== clasificacion?.linea_disponible && <span style={{ color: "red" }}> * </span>} </FormLabel>
            <Input
              name="comentario"
              placeholder=""
              disabled={esModoLecturaDtRee}
              value={clasificacion.comentario}
              onChange={handleComentario}
            />
          </FormControl>
        </div>
      </div>
      {/*<div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-3 cardObjets">
          <Button
            size="md"
            color="danger"
            variant="outlined"
            disabled={esModoLecturaDtRee}
            sx={{
              borderRadius: 24,
              borderColor: "red",
              fontWeight: 650,
              width: 1 / 2,
            }}
            onClick={handleReevaluar}
          >
            Reevaluar
          </Button>
        </div>

          </div>*/}
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-12 cardObjets horizontal-generico-scrollable__wrapper align-top">
          <Table borderAxis="none" className="table-layout align-top">
            <thead>
              <tr>
                <th style={{ width: "200px", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>TIPO DEUDA</th>
                <th style={{ width: "22%", backgroundColor: "#E1E1E1", }}>ENTIDAD</th>
                <th style={{ width: "13%", backgroundColor: "#E1E1E1", }}>DEUDA</th>
                <th style={{ width: "10%", backgroundColor: "#E1E1E1", }}>CUOTA FC</th>
                <th style={{ width: "155px", backgroundColor: "#E1E1E1", }}>DEUDA REAL</th>
                <th style={{ width: "120px", backgroundColor: "#E1E1E1", }}>CUOTA REAL</th>
                <th style={{ width: "17%", backgroundColor: "#E1E1E1", }}>COMENTARIO</th>
                <th style={{ width: "12%", backgroundColor: "#E1E1E1", }}>ACCIÓN</th>
              </tr>
            </thead>
            <tbody>
              {
                datosReevaluacion && datosReevaluacion?.resultado?.tabla2?.length > 0 &&
                datosReevaluacion?.resultado?.tabla2.map((row: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>
                        {!row.solo_lectura && row?.id > 0 ? '' : row.tipo_deuda}
                        {(!row.solo_lectura && row?.id > 0) && <Select
                          name="tipoDeuda"
                          className={"select-input-card width-160px"}
                          IconComponent={props => (<KeyboardArrowDown {...props} className={`material-icons ${props.className}`} />)}
                          onChange={(e: any, newValue: any) => {
                            const newDatosReevaluacion = datosReevaluacion?.resultado?.tabla2?.map((item: any, index: number) => {
                              let id = !item?.id ? 0 : item?.id;
                              if (id === row.id) {
                                item.tipo_deuda = e.target.value;
                                return item;
                              }
                            });
                            setDatosReevaluacion({ ...datosReevaluacion, tabla2: [...newDatosReevaluacion] })
                          }}
                          value={row.tipo_deuda ? row.tipo_deuda : appConfigKey.DescripTipoDeudaPorDefecto}
                        >
                          {listaTipoDeudaTemp.map((data: any, index: any) => (
                            <MenuItem value={data.id} key={index}>{data.description}</MenuItem>
                          ))}
                        </Select>}
                      </td>
                      <td style={{ overflow: "hidden", textOverflow: "ellipsis", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.entidad}</td>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.NumeroConCommas(row.monto_deuda ? row.monto_deuda.toFixed(2) : '0')}</td>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.NumeroConCommas(row.cuota_fc ? row.cuota_fc.toFixed(2) : '0')}</td>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> <Input disabled={esModoLecturaDtRee} startDecorator={{ soles: 'S/.' }['soles']} data-tipo_deuda={row.tipo_deuda} onChange={handleChangeMontoDeuda} value={GeneralHelper.AgregarComasAMiles(row?.monto_deuda_real ? (typeof row?.monto_deuda_real === "number" ? row?.monto_deuda_real.toFixed(2) : row?.monto_deuda_real) : '')} data-index={index} /> </td>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> <Input disabled={esModoLecturaDtRee} startDecorator={{ soles: 'S/.' }['soles']} data-tipo_deuda={row.tipo_deuda} onChange={handleChangeCuotaReal} value={GeneralHelper.AgregarComasAMiles(row?.cuota_fc_real ? (typeof row?.cuota_fc_real === "number" ? row?.cuota_fc_real.toFixed(2) : row?.cuota_fc_real) : '')} data-index={index} /> </td>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> <Input disabled={esModoLecturaDtRee} data-tipo_deuda={row.tipo_deuda} onChange={handleChangeComentario} value={row.comentario} data-index={index} /> </td>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>
                        <Stack direction="row">
                          <Tooltip title="Calcular" placement="bottom">
                            <IconButton
                              disabled={esModoLecturaDtRee}
                              aria-label="Calcular"
                              onClick={() => handlCalcularCuotaReal(row, index)}
                            >
                              <CalculateIcon />
                            </IconButton>
                          </Tooltip>
                          {(!row.solo_lectura && row?.id > 0) &&
                            <Tooltip title="Eliminar" placement="bottom">
                              <IconButton aria-label="Eliminar"
                                onClick={() => handlEliminarDeuda(row)}
                              >
                                <DeleteForeverIcon />
                              </IconButton>
                            </Tooltip>
                          }
                        </Stack>
                      </td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>

        </div>
      </div>

      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-12 cardObjets bottom-right" >
          <div style={{ display: "flex" }}>
            <div style={{ width: "85%", boxSizing: "border-box", padding: "5px" }}>
              <Button
                size="md"
                color="danger"
                variant="outlined"
                disabled={esModoLecturaDtRee}
                sx={{
                  borderRadius: 24,
                  borderColor: "red",
                  fontWeight: 650,
                }}
                onClick={handleRestaurarDeuda}
              >
                Restaurar deuda
              </Button>
            </div>
            <div style={{ width: "15%", boxSizing: "border-box", padding: "5px" }}>
              <Button
                size="md"
                color="danger"
                variant="outlined"
                disabled={esModoLecturaDtRee}
                sx={{
                  borderRadius: 24,
                  borderColor: "red",
                  fontWeight: 650,
                }}
                onClick={handleAgregarDeuda}
              >
                Agregar deuda
              </Button>
            </div>
          </div>

        </div>
      </div>
      <div className="row g-2 mb-2 align-items-center borderOtherIncome">
        <div className="col-lg-4 textLabelHeadboard">
          <span className="fw-bold">REPORTE CREDITICIO DEUDA SBS</span>
        </div>
        <div className="col-lg-4">
          <span className="fw-bold"> </span>
        </div>
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-3 cardObjets">
            <FormControl>
              <FormLabel>N. entidades reportan</FormLabel>
              <FormLabel>{clasificacion.nro_entidades_rep}</FormLabel>
            </FormControl>
          </div>
          <div className="col-lg-3 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>Línea disponible</FormLabel>
              <FormLabel>{clasificacion.linea_disponible}</FormLabel>
            </LocalizationProvider>
          </div>
          <div className="col-lg-3 cardObjets">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <FormLabel>Línea disponible real</FormLabel>
              <FormLabel>{clasificacion.linea_disponible_real}</FormLabel>
            </LocalizationProvider>
          </div>
        </div>
      </div>
      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-12 cardObjets horizontal-generico-scrollable__wrapper align-top">
          <Table borderAxis="none" className="style-table align-top">
            <thead>
              <tr>
                <th style={{ width: "20%", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>TIPO DEUDA</th>
                <th style={{ width: "20%", backgroundColor: "#E1E1E1", }}>ENTIDAD</th>
                <th style={{ width: "15%", backgroundColor: "#E1E1E1", }}>DEUDA</th>
                <th style={{ width: "15%", backgroundColor: "#E1E1E1", }}>CUOTA FC</th>
                <th style={{ width: "15%", backgroundColor: "#E1E1E1", }}>DEUDA REAL</th>
                <th style={{ width: "15%", backgroundColor: "#E1E1E1", }}>CUOTA REAL</th>
                <th style={{ width: "15%", backgroundColor: "#E1E1E1", }}>COMENTARIO</th>
              </tr>
            </thead>
            <tbody>
              {
                datosReevaluacion && datosReevaluacion?.resultado?.tabla2?.length > 0 &&
                datosReevaluacion?.resultado?.tabla2?.map((row: any, index: number) => {
                  return (
                    <tr key={index}>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.tipo_deuda}</td>
                      <td style={{ overflow: "hidden", textOverflow: "ellipsis", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.entidad}</td>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.NumeroConCommas(Number(row.monto_deuda).toFixed(2))}</td>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.NumeroConCommas(Number(row.cuota_fc).toFixed(2))}</td>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.NumeroConCommas(Number(row.monto_deuda_real).toFixed(2))}</td>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {GeneralHelper.NumeroConCommas(Number(row.cuota_fc_real).toFixed(2))}</td>
                      <td style={{ borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.comentario}</td>
                    </tr>
                  )
                })
              }
            </tbody>
          </Table>
        </div>
      </div>
    </>
  )
}


export const ScoringDatosReevaluacion = ({ datosReevaluacionTitular, setDatosReevaluacionTitular, datosReevaluacionConyuge, setDatosReevaluacionConyuge, estadoCivilTitular, handleReevaluar, esModoLectura, clasificacionTitular, setClasificacionTitular, clasificacionConyuge, setClasificacionConyuge, listaTipoDeuda, datosReevaluacionOrigenTitular, datosReevaluacionOrigenConyuge, defaultValueTab, categoriaLaboralConyuge, handleVerClasifPorIntegrante, members,
  clasificacionTitularOriginal, clasificacionConyugeOriginal, setShow, esSeparadoBienes }: any) => {

  const [esModoLecturaScoDatosRee, setEsModoLecturaScoDatosRee] = React.useState(false);
  const [defaultValueTabDE, setDefaultValueTabDE] = React.useState(0);

  const handleChangeTab = async (event: any, newValue: any) => {
    let tabIndex = Number(newValue);
    setDefaultValueTabDE(tabIndex);

    let objPersona: any = null;
    if (tabIndex === 0) {
      objPersona = members.users.find((item: any) => item.tipoIntegrante === appConfigKey.keyDescripTipoIntegranteTitular);
    }
    if (tabIndex === 1) {
      objPersona = members.users.find((item: any) => item.tipoIntegrante === appConfigKey.keyDescripTipoIntegranteConyuge);
    }

    if (objPersona) {
      handleVerClasifPorIntegrante(objPersona.tipoIntegrante, objPersona.nroDocumento, objPersona.tipoDocumento);
    }


  }
  useEffect(() => {
    setEsModoLecturaScoDatosRee(!esModoLectura ? false : esModoLectura);
  }, [esModoLectura, defaultValueTab])

  useEffect(() => {
    setDefaultValueTabDE(defaultValueTab);
  }, [defaultValueTab]);

  return (
    <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
      <AccordionGroup>
        <Accordion>
          <AccordionSummary>DATOS DE REEVALUACIÓN</AccordionSummary>
          <AccordionDetails>
            <Tabs aria-label="Basic tabs" value={defaultValueTabDE} onChange={(e, newValue) => handleChangeTab(e, newValue)}>
              <TabList
                sx={{
                  pt: 1,
                  justifyContent: 'start',
                  [`&& .${tabClasses.root}`]: {
                    flex: 'initial',
                    bgcolor: 'transparent',
                    '&:hover': {
                      bgcolor: 'transparent',
                    },
                    [`&.${tabClasses.selected}`]: {
                      //color: 'primary.plainColor',
                      '&::after': {
                        height: 3,
                        borderTopLeftRadius: 3,
                        borderTopRightRadius: 3,
                        bgcolor: 'danger.500',
                      },
                    },
                  },
                }}
              >


                <Tab >Titular</Tab>
                {((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) && categoriaLaboralConyuge !== appConfigKey.keyIdSinCategoria) && <Tab>Cónyuge</Tab>}
              </TabList>
              <TabPanel value={0}>
                {/* {tblTitular && tblTitular.length > 0 && <DtReevaluacionDet tabla={tblTitular} />} */}
                {datosReevaluacionTitular && <DtReevaluacionDet datosReevaluacion={datosReevaluacionTitular} setDatosReevaluacion={setDatosReevaluacionTitular} handleReevaluar={handleReevaluar} esModoLectura={esModoLecturaScoDatosRee} clasificacion={clasificacionTitular} setClasificacion={setClasificacionTitular} listaTipoDeuda={listaTipoDeuda} datosReevaluacionOrigen={datosReevaluacionOrigenTitular} clasificacionOriginal={clasificacionTitularOriginal} setShow={setShow} />}
              </TabPanel>
              {((estadoCivilTitular === appConfigKey.keyIdEstadoCivilCasado && !esSeparadoBienes) && categoriaLaboralConyuge !== appConfigKey.keyIdSinCategoria) && <TabPanel value={1}>
                {/* {tblConyuge && tblConyuge.length > 0 && <DtReevaluacionDet tabla={tblConyuge} />} */}
                {datosReevaluacionConyuge && <DtReevaluacionDet datosReevaluacion={datosReevaluacionConyuge} setDatosReevaluacion={setDatosReevaluacionConyuge} handleReevaluar={handleReevaluar} esModoLectura={esModoLecturaScoDatosRee} clasificacion={clasificacionConyuge} setClasificacion={setClasificacionConyuge} listaTipoDeuda={listaTipoDeuda} datosReevaluacionOrigen={datosReevaluacionOrigenConyuge} clasificacionOriginal={clasificacionConyugeOriginal} setShow={setShow} />}
              </TabPanel>}
            </Tabs>
          </AccordionDetails>
        </Accordion>
      </AccordionGroup>
    </div>
  );

}

export default ScoringDatosReevaluacion;