import TitleCard from "../elements/titleCard";
import { DateInput, SelectInput, SwitchInput, SimpleInput, NumberInput, MoneyInput, SelectAutocompleteInput } from "../elements/inputs";
import GeneralHelper from "../../../../../helpers/GeneralHelper";
import { appConfigKey, appDefaultValues } from "../../../../../../config/Config";
import { ButtonAdd } from "../elements/button";
import { useContext, useEffect, useState } from "react";
import { StepOneContext, StepsContext } from "../../../3.10.-base/providers";
import { Button, IconButton, Tooltip } from "@mui/joy";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import { TitularContext } from "../../contexts";

const DatosLaboralesDelConyuge = () => {

  const { titularEstadoCivilId } = useContext(TitularContext);

  const {
    listCategoriaLaboral,
    listActividadEconomica,
    listMoneda,
    listRegimen,
    clickNext,
    messageCustomerIsBlackListGesintel,
    readonlyForm
  } = useContext(StepsContext);

  const {
    ingresosConyugeData,
    setNewIngresosConyugeData
  } = useContext(StepOneContext);

  const [datosLaboralesConguye, setDatosLaboralesConguye] = useState<any>({
    identCategoriaLaboral: 0,
    ruc: "",
    razonSocial: "",
    identTipoMoneda: 1,
    ingresosNetos: "",
    fechaIngresoLaboral: "",
    anualizado: false,
    calculoAnualizado: "0.00",
    regimenId: 0,
    tieneTrabajoAnterior: false,

    identCategoriaLaboralAnterior: 0,
    rucAnterior: "",
    razonSocialAnterior: "",
    fechaIngresoLaboralAnterior: "",
    fechaHastaAnterior: "",
    regimenIdAnterior: 0,

    listaOtrosTrabajos: [],
  });

  const handlerBtnOtrosConyugeIngresosTitular = () => {
    const data = { ...datosLaboralesConguye };
    data.listaOtrosTrabajos.push({
      idFila: ((new Date()).getTime()),
      idCategoria: 0,
      categoria: '',
      nroRuc: '',
      idTipoMoneda: 0,
      tipoMoneda: '',
      symboloMoneda: '',
      ingresoNeto: '',
      fecLaboral: null,
      fecLaboralText: '',
      esAnualizado5ta: false,
      ingresoAnualizado: ''
    });
    setDatosLaboralesConguye(data);
  };

  const deleteOtrosIngresosConyuge = (index: number) => {
    let data = { ...datosLaboralesConguye}; 
    let idFila = data.listaOtrosTrabajos[index].idFila;
    data.listaOtrosTrabajos = data.listaOtrosTrabajos.filter((x: any) => x.idFila !== idFila);
    setDatosLaboralesConguye(data);
  }

  const onChangeSelectInput = (event: any, newValue: any) => {
    const data: any = { ...datosLaboralesConguye };
    data[event.target.name] = newValue;
    setDatosLaboralesConguye(data);
  };

  const onChangeTextInput = (event: any) => {
    const data: any = { ...datosLaboralesConguye };
    data[event.target.name] = event.target.value;
    setDatosLaboralesConguye(data);
  };

  useEffect(() => {
    if (ingresosConyugeData !== 0) {
      setDatosLaboralesConguye({ ...datosLaboralesConguye, ...ingresosConyugeData });
    }
  }, [ingresosConyugeData]);

  const eventoContinuar = () => {
    if (clickNext !== 0) {
      setNewIngresosConyugeData(datosLaboralesConguye);
    }
  };

  useEffect(() => eventoContinuar(), [clickNext]);

  return (
    <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
      <TitleCard title={`DATOS LABORALES DEL ${titularEstadoCivilId === appConfigKey.keyIdEstadoCivilCasado ? 'CÓNYUGE' : 'CONVIVIENTE' }`} />

      <div className="row g-2 mb-2 align-items-center">
        <div className="col-lg-2 cardObjets">
          <SelectInput
            name={"identCategoriaLaboral"}
            title={"Categoria laboral"}
            value={datosLaboralesConguye.identCategoriaLaboral}
            disabled={readonlyForm}
            onChange={(event: any, newValue: any) => {

              const tempDatosLaboralesConguye = { ...datosLaboralesConguye };

              let tempFechaIngresoLaboral = tempDatosLaboralesConguye.fechaIngresoLaboral;

              if (appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado === newValue) {
                tempFechaIngresoLaboral = '';
              }

              tempDatosLaboralesConguye.fechaIngresoLaboral = tempFechaIngresoLaboral;
              tempDatosLaboralesConguye.identCategoriaLaboral = newValue;
              tempDatosLaboralesConguye.anualizado = false;
              tempDatosLaboralesConguye.tieneTrabajoAnterior = false;
              tempDatosLaboralesConguye.rucAnterior = '';
              tempDatosLaboralesConguye.razonSocialAnterior = '';
              tempDatosLaboralesConguye.fechaIngresoLaboralAnterior = null;
              tempDatosLaboralesConguye.fechaHastaAnterior = null;
              tempDatosLaboralesConguye.regimenIdAnterior = 0;
              tempDatosLaboralesConguye.identCategoriaLaboralAnterior = 0;

              if (appConfigKey.keyIdSinCategoria === newValue) {
                tempDatosLaboralesConguye.ruc = '';
                tempDatosLaboralesConguye.razonSocial = '';
                tempDatosLaboralesConguye.identTipoMoneda = 0;
                tempDatosLaboralesConguye.ingresosNetos = '';
                tempDatosLaboralesConguye.fechaIngresoLaboral = null;
                tempDatosLaboralesConguye.listaOtrosTrabajos = [];
                tempDatosLaboralesConguye.calculoAnualizado = '';
              }

              setDatosLaboralesConguye(tempDatosLaboralesConguye);
            }}
            options={listCategoriaLaboral}
          />
        </div>
        {datosLaboralesConguye.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria && (
          datosLaboralesConguye.identCategoriaLaboral !== appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado && 
            (
              <>
              <div className="col-lg-2 cardObjets">
                <NumberInput
                  name={"ruc"}
                  title={"RUC"}
                  placeholder={"Ej: 00000000000"}
                  longNumber={11}
                  value={datosLaboralesConguye.ruc}
                  disabled={readonlyForm}
                  onChange={onChangeTextInput}
                />
              </div>
              <div className="col-lg-4 cardObjets">
                <SimpleInput
                  name={"razonSocial"}
                  title={"Razón Social"}
                  placeholder={"Indique Razón Social"}
                  value={datosLaboralesConguye.razonSocial}
                  disabled={readonlyForm}
                  onChange={onChangeTextInput}
                />
              </div>
  
            </>
          )
        )}
        {datosLaboralesConguye.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria && (
          <>
            <div className="col-lg-2 cardObjets">
              <SelectInput
                name={"identTipoMoneda"}
                title={"Tipo de moneda"}
                options={listMoneda}
                value={datosLaboralesConguye.identTipoMoneda}
                disabled={readonlyForm}
                onChange={onChangeSelectInput}
              />
            </div>
            <div className="col-lg-2 cardObjets">
              <MoneyInput
                title={"Ingresos netos"}
                placeholder={"Indique Ingresos netos"}
                value={datosLaboralesConguye.ingresosNetos}
                disabled={readonlyForm}
                onChange={(event: any) => setDatosLaboralesConguye({ ...datosLaboralesConguye, ingresosNetos: event.target.value, calculoAnualizado: GeneralHelper.CalcularAnulizado(event.target.value) })}
              />
            </div>
          </>
        )}
        {datosLaboralesConguye.identCategoriaLaboral == appConfigKey.keyCodigoCategoriaLaboralCinco && (
          <>
            <div className="col-lg-2 cardObjets">
              <SwitchInput
                name={"anualizado"}
                title={"Anualizado solo 5ta"}
                checked={datosLaboralesConguye?.anualizado ?? false}    
                // disabled={Number(datosLaboralesConguye.ingresosNetos?datosLaboralesConguye.ingresosNetos.replace(/,/g, ''):0) < 2000 ? true : false}
                disabled={readonlyForm}
                onChange={(event: any) => {
                  setDatosLaboralesConguye({
                    ...datosLaboralesConguye,
                    anualizado: event.target.checked,
                    calculoAnualizado: GeneralHelper.CalcularAnulizado(datosLaboralesConguye.ingresosNetos)
                  });
                }}
              />
            </div>
            {datosLaboralesConguye.anualizado && (
              <div className="col-lg-2 cardObjets">
                <SimpleInput
                  title={"Ingresos anualizado"}
                  placeholder={"Indique ingresos netos"}
                  value={datosLaboralesConguye.calculoAnualizado}
                  disabled={true}
                />
              </div>
            )}
          </>
        )}
        {datosLaboralesConguye.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria && (
          <div className="col-lg-2 cardObjets">
            <DateInput
              title={"Fecha ingreso laboral"}
              value={datosLaboralesConguye.fechaIngresoLaboral}
              disabled={readonlyForm || datosLaboralesConguye.identCategoriaLaboral === appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado}
              onChange={(newValue: any | null) => setDatosLaboralesConguye({ ...datosLaboralesConguye, fechaIngresoLaboral: newValue === null ? '' : newValue?.format("YYYY-MM-DD") })}
            />
          </div>
        )}

        {datosLaboralesConguye.identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralTres && (
          <div className="col-lg-2 cardObjets">
            <SelectInput
              name={'regimenId'}
              title={"Régimen"}
              value={datosLaboralesConguye.regimenId}
              options={listRegimen}
              disabled={readonlyForm}
              onChange={onChangeSelectInput}
            />
          </div>
        )}
        {datosLaboralesConguye.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria && (
          <div className="col-lg-2 cardObjets">
            <SwitchInput
              title={"Ingresar trabajo anterior"}
              checked={datosLaboralesConguye?.tieneTrabajoAnterior ?? false}
              disabled={readonlyForm}
              onChange={(event: any) => {
                setDatosLaboralesConguye({
                  ...datosLaboralesConguye,
                  tieneTrabajoAnterior: event.target.checked
                });
              }}
            />
          </div>
        )}
      </div>

      {(datosLaboralesConguye.tieneTrabajoAnterior && datosLaboralesConguye.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria) && (
        <div className="row g-2 mb-2 align-items-center">
          <div className="col-lg-2 cardObjets">
            <SelectInput
              name={"identCategoriaLaboralAnterior"}
              title={"Categoría anterior"}
              options={listCategoriaLaboral?.filter((x: any) => x.id !== appConfigKey.keyIdSinCategoria)}
              value={datosLaboralesConguye.identCategoriaLaboralAnterior}
              disabled={readonlyForm}
              onChange={(event: any, newValue: any) => {

                let tempFechaIngresoLaboral = datosLaboralesConguye.fechaIngresoLaboralAnterior;
                let tempFechaHastaAnterior = datosLaboralesConguye.fechaHastaAnterior;

                if (datosLaboralesConguye.fechaIngresoLaboralAnterior === appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado) {
                  tempFechaIngresoLaboral = '';
                  tempFechaHastaAnterior = '';
                }

                setDatosLaboralesConguye({
                  ...datosLaboralesConguye,
                  identCategoriaLaboralAnterior: newValue,
                  regimenIdAnterior: 0,
                  fechaIngresoLaboralAnterior: tempFechaIngresoLaboral,
                  fechaHastaAnterior: tempFechaHastaAnterior,
                });

              }}
            />
          </div>
          <div className="col-lg-2 cardObjets">
            <NumberInput
              name={"rucAnterior"}
              title={"RUC"}
              longNumber={11}
              placeholder="Ej: 00000000000"
              value={datosLaboralesConguye.rucAnterior}
              disabled={readonlyForm}
              onChange={(event: any) => {
                const data = { ...datosLaboralesConguye };
                data["rucAnterior"] = event.target.value;
                setDatosLaboralesConguye(data);
              }}
            />
          </div>
          <div className="col-lg-4 cardObjets">
            <SimpleInput
              name={"razonSocialAnterior"}
              title={"Razón Social"}
              placeholder="Indique Razón Social"
              value={datosLaboralesConguye.razonSocialAnterior}
              disabled={readonlyForm}
              onChange={(event: any) => {
                const data = { ...datosLaboralesConguye };
                data["razonSocialAnterior"] = event.target.value;
                setDatosLaboralesConguye(data);
              }}
            />
          </div>
          {datosLaboralesConguye.identCategoriaLaboralAnterior === appConfigKey.keyCodigoCategoriaLaboralTres && (
            <div className="col-lg-2 cardObjets">
              <SelectInput
                name={"regimenIdAnterior"}
                title={"Régimen"}
                options={listRegimen}
                value={datosLaboralesConguye.regimenIdAnterior}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => {
                  const data = { ...datosLaboralesConguye };
                  data["regimenIdAnterior"] = newValue;
                  setDatosLaboralesConguye(data);
                }}
              />
            </div>
          )}
          <div className="col-lg-2 cardObjets">
            <DateInput
              name={"fechaIngresoLaboralAnterior"}
              title={"Fecha ingreso laboral"}
              value={datosLaboralesConguye.fechaIngresoLaboralAnterior}
              disabled={readonlyForm || datosLaboralesConguye.identCategoriaLaboralAnterior === appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado}
              onChange={(date: any | null) => {
                const data = { ...datosLaboralesConguye };
                data["fechaIngresoLaboralAnterior"] = date === null ? '' : date.format("YYYY-MM-DD");
                setDatosLaboralesConguye(data);
              }}
            />
          </div>
          <div className="col-lg-2 cardObjets">
            <DateInput
              name={"fechaHastaAnterior"}
              title={"Hasta"}
              value={datosLaboralesConguye.fechaHastaAnterior}
              disabled={readonlyForm || datosLaboralesConguye.identCategoriaLaboralAnterior === appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado}
              onChange={(date: any | null) => {
                const data = { ...datosLaboralesConguye };
                data["fechaHastaAnterior"] = date === null ? '' : date.format("YYYY-MM-DD");
                setDatosLaboralesConguye(data);
              }}
            />
          </div>

          {/*  <div className="col-lg-2 cardObjets">
            <MoneyInput
              placeholder={"Indique los ingresos netos"}
              name={"ingresosNetosAnterior"}
              title={"Ingreso netos"}
              value={datosLaboralesConguye.ingresosNetosAnterior}
              onChange={(event: any) => {
                const data = { ...datosLaboralesConguye };
                data["ingresosNetosAnterior"] = event.target.value;
                data["calculoAnualizadoAnterior"] = GeneralHelper.CalcularAnulizado(event.target.value);
                setDatosLaboralesConguye(data);
              }}
            />
          </div>*/}

          {/*datosLaboralesConguye.identCategoriaLaboralAnterior == appConfigKey.keyCodigoCategoriaLaboralCinco &&
            datosLaboralesConguye.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria && (
              <>
                <div className="col-lg-2 cardObjets">
                  <SwitchInput
                    name={"anualizadoAnterior"}
                    title={"Anualizado solo 5ta"}
                    checked={datosLaboralesConguye.anualizadoAnterior}
                    onChange={(event: any) => {
                      const data = { ...datosLaboralesConguye };
                      data["anualizadoAnterior"] = event.target.checked;
                      data["calculoAnualizadoAnterior"] = GeneralHelper.CalcularAnulizado(datosLaboralesConguye.ingresosNetosAnterior);
                      setDatosLaboralesConguye(data);
                    }}
                  />
                </div>
                {datosLaboralesConguye.anualizadoAnterior && (
                  <div className="col-lg-2 cardObjets">
                    <SimpleInput
                      title={"Ingresos anualizado"}
                      disabled={true}
                      placeholder="Indique Ingresos netos"
                      value={datosLaboralesConguye.calculoAnualizadoAnterior}
                    />
                  </div>
                )}
              </>
            )*/}
        </div>
      )}

      {datosLaboralesConguye.listaOtrosTrabajos.map((data: any, index: any) => (
          <div className="row g-2 mb-2 align-items-center borderOtherIncome" key={index}>
            <div className="col-lg-2 cardObjets">
              <SelectInput
                name={"identCategoriaLaboral"}
                title={"Categoría laboral"}
                options={listCategoriaLaboral?.filter((x: any) => x.id !== appConfigKey.keyIdSinCategoria)}
                value={data.identCategoriaLaboral ?? 0}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => {

                  const tempDatosLaboralesConguye = { ...datosLaboralesConguye };

                  let tempFechaIngresoLaboral = tempDatosLaboralesConguye.listaOtrosTrabajos[index].fechaIngresoLaboral;

                  if (appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado === newValue) {
                    tempFechaIngresoLaboral = '';
                  }

                  tempDatosLaboralesConguye.listaOtrosTrabajos[index].identCategoriaLaboral = newValue;
                  tempDatosLaboralesConguye.listaOtrosTrabajos[index].regimenId = 0;
                  tempDatosLaboralesConguye.listaOtrosTrabajos[index].anualizado = false;
                  tempDatosLaboralesConguye.listaOtrosTrabajos[index].calculoAnualizado = '';
                  tempDatosLaboralesConguye.listaOtrosTrabajos[index].fechaIngresoLaboral = tempFechaIngresoLaboral;

                  setDatosLaboralesConguye(tempDatosLaboralesConguye);

                }}
              />
            </div>
            <div className="col-lg-2 cardObjets">
              <NumberInput
                name={"ruc"}
                title={"RUC"}
                longNumber={11}
                placeholder="Ej: 00000000000"
                value={data.ruc}
                disabled={readonlyForm}
                onChange={(event: any) => {
                  data[event.target.name] = event.target.value;
                  const tempDatosLaboralesConguye = {
                    ...datosLaboralesConguye,
                  };
                  tempDatosLaboralesConguye.listaOtrosTrabajos[index] = data;
                  setDatosLaboralesConguye(tempDatosLaboralesConguye);
                }}
              />
            </div>
            <div className="col-lg-4 cardObjets">
              <SimpleInput
                name={"razonSocial"}
                title={"Razón Social"}
                placeholder="Indique Razón Social"
                value={data.razonSocial}
                disabled={readonlyForm}
                onChange={(event: any) => {
                  data[event.target.name] = event.target.value;
                  const tempDatosLaboralesConguye = {
                    ...datosLaboralesConguye,
                  };
                  tempDatosLaboralesConguye.listaOtrosTrabajos[index] = data;
                  setDatosLaboralesConguye(tempDatosLaboralesConguye);
                }}
              />
            </div>
            <div className="col-lg-2 cardObjets">
              <SelectInput
                name={"identTipoMoneda"}
                title={"Tipo de moneda"}
                options={listMoneda}
                value={data.identTipoMoneda ?? 0}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => {
                  data["identTipoMoneda"] = newValue;
                  const tempDatosLaboralesConguye = {
                    ...datosLaboralesConguye,
                  };
                  tempDatosLaboralesConguye.listaOtrosTrabajos[index] = data;
                  setDatosLaboralesConguye(tempDatosLaboralesConguye);
                }}
              />
            </div>
            <div className="col-lg-2 cardObjets">
              <MoneyInput
                name={"ingresosNetos"}
                title={"Utilidad neta"}
                placeholder="Indique Utilidad Neta"
                value={data.ingresosNetos}
                disabled={readonlyForm}
                onChange={(event: any) => {
                  data[event.target.name] = event.target.value;
                  data["calculoAnualizado"] = GeneralHelper.CalcularAnulizado(event.target.value);
                  const tempDatosLaboralesConguye = {
                    ...datosLaboralesConguye,
                  };
                  tempDatosLaboralesConguye.listaOtrosTrabajos[index] = data;
                  setDatosLaboralesConguye(tempDatosLaboralesConguye);
                }}
              />
            </div>
            {data.identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralCinco && (
              <>
                <div className="col-lg-2 cardObjets">
                  <SwitchInput
                    name={"anualizado"}
                    title={"Anualizado solo 5ta"}
                    checked={data?.anualizado ?? false}
                    // disabled={Number(data?.ingresosNetos?data?.ingresosNetos.replace(/,/g, ''):0) < 2000 ? true : false}
                    disabled={readonlyForm} 
                    onChange={(event: any) => {
                      data["anualizado"] = event.target.checked;
                      data["calculoAnualizado"] = GeneralHelper.CalcularAnulizado(data["ingresosNetos"]);
                      const tempDatosLaboralesConguye = { ...datosLaboralesConguye };
                      tempDatosLaboralesConguye.listaOtrosTrabajos[index] = data;
                      setDatosLaboralesConguye(tempDatosLaboralesConguye);
                    }}
                  />
                </div>
                {data.anualizado && (
                  <div className="col-lg-2 cardObjets">
                    <SimpleInput
                      title={"Ingresos anualizados"}
                      disabled={true}
                      placeholder={"Indique Ingresos netos"}
                      value={data.calculoAnualizado}
                    />
                  </div>
                )}
              </>
            )}
            <div className="col-lg-2 cardObjets">
              <DateInput
                name={"fechaIngresoLaboral"}
                title={"Fecha ingreso laboral"}
                value={data.fechaIngresoLaboral ?? ""}
                disabled={readonlyForm || data.identCategoriaLaboral === appConfigKey.KeyCodigoCategoriaLaboralCincoJubilado}
                onChange={(date: any | null) => {
                  data["fechaIngresoLaboral"] = date === null ? '' : date.format("YYYY-MM-DD");
                  const tempDatosLaboralesConguye = {
                    ...datosLaboralesConguye,
                  };
                  tempDatosLaboralesConguye.listaOtrosTrabajos[index] = data;
                  setDatosLaboralesConguye(tempDatosLaboralesConguye);
                }}
              />
            </div>
            {data.identCategoriaLaboral === appConfigKey.keyCodigoCategoriaLaboralTres && (
              <div className="col-lg-2 cardObjets">
                <SelectInput
                  name={"regimenId"}
                  title={"Régimen"}
                  options={listRegimen}
                  value={data.regimenId ?? 0}
                  disabled={readonlyForm}
                  onChange={(event: any, newValue: any) => {
                    data["regimenId"] = newValue;
                    const tempDatosLaboralesConguye = {
                      ...datosLaboralesConguye,
                    };
                    tempDatosLaboralesConguye.listaOtrosTrabajos[index] = data;
                    setDatosLaboralesConguye(tempDatosLaboralesConguye);
                  }}
                />
              </div>
            )}
            <div className="col-lg-4 cardObjets">
              <SelectAutocompleteInput
                name={"actividadEconomicaId"}
                title={"Actividad económica"}
                options={listActividadEconomica}
                value={data.actividadEconomicaId ?? 0}
                disabled={readonlyForm}
                onChange={(event: any, newValue: any) => {
                  data["actividadEconomicaId"] = newValue;
                  const tempDatosLaboralesConguye = {
                    ...datosLaboralesConguye,
                  };
                  tempDatosLaboralesConguye.listaOtrosTrabajos[index] = data;
                  setDatosLaboralesConguye(tempDatosLaboralesConguye);
                }}
              />
            </div>
            {index >= 0 && <div className="col-lg-1 cardObjets center-button">

                    <Tooltip title="Eliminar" placement="bottom">
                      <Button
                        disabled={false}
                        onClick={() => deleteOtrosIngresosConyuge(index)}
                        size="sm"
                        variant="solid"
                        style={{
                          borderRadius: 100,
                          backgroundColor: '#444444',
                          width: '30px',
                          height: '30px'
                        }}
                      >
                        <IconButton style={{
                          backgroundColor: '#444444',
                          color: 'white'
                        }}>
                          <DeleteForeverIcon />
                        </IconButton>


                      </Button>
                    </Tooltip>
                  </div>}
          </div>
        ))

      }

      {
        datosLaboralesConguye.identCategoriaLaboral !== appConfigKey.keyIdSinCategoria && (
          <div className="row g-2 mb-2 align-items-center ">
            <div className="col-lg-12 cardObjets bottom-right">
              <ButtonAdd
                onClick={handlerBtnOtrosConyugeIngresosTitular}
                disabled={readonlyForm}
                title={"Otros Ingresos"}
              />
            </div>
          </div>
        )
      }
    </div >
  );
};

export default DatosLaboralesDelConyuge;
