import { Dialog } from "@mui/material";
import ArrowRight from '../../../../../assets/media/icons/ico_arrowright.svg';
import IconNo from '../../../../../assets/media/icons/ico_no.svg';
import ImgDNI from '../../../../../assets/media/icons/img_dni.svg';
import React from "react";

const DocumentationDialogDNI = (props: any) => {

    const hiddenFileInput = React.useRef<HTMLInputElement>(document.createElement("input"));

    const handleClick = (event: any) => {
        hiddenFileInput.current.click();
    };

    const handleChange = (event: any) => {
        const fileUploaded = event.target.files[0];
        props.handleFile(fileUploaded);
        props.handleClose();
    };

    return (
        <Dialog open={props.open} maxWidth={'sm'}>
            <div className="content-documentation-add-dialog-dni">
                <div className="close-dialog-documentation" onClick={props.handleClose}>
                    <img src={IconNo} className="cursor-pointer"/>
                </div>
                <div className="content-documentation-add-dialog-dni-title">
                    <h2>VERIFICAR FECHA CADUCIDAD</h2>
                </div>
                <div className="content-documentation-add-dialog-dni-body">
                    <img src={ImgDNI} alt='DNI'/>
                    <p>Recuerda que al subir un DNI debes revisar que la fecha de caducidad este vigente</p>
                </div>
                <div className="content-documentation-add-dialog-dni-footer">
                    <div className="content-documentation-add-dialog-dni-footer-update-document cursor-pointer" onClick={handleClick}>
                        <div>Subir documento</div> <img src={ArrowRight} alt='arrow-right' />
                        <input type="file" ref={hiddenFileInput} onChange={handleChange} style={{ display: 'none' }} />
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default DocumentationDialogDNI;