import { appDefaultValues } from '../../../config/Config';

export interface Debts {
 cfm: string,
 cfc: string,
 cuotasS: string,
 cuotasD: string,
 menS: number,
 menD: number,
 cemS: string,
 cemD: string,
}

export const DebtsDefault: Debts = {
    cfm: appDefaultValues.StringEmpty,
    cfc: appDefaultValues.StringEmpty,
    cuotasS: appDefaultValues.StringEmpty,
    cuotasD: appDefaultValues.StringEmpty,
    menS: appDefaultValues.NumberDefault,
    menD: appDefaultValues.NumberDefault,
    cemS: appDefaultValues.StringEmpty,
    cemD: appDefaultValues.StringEmpty
}