import { useEffect, useState } from 'react';
import * as React from 'react';
import MainLayout from '../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout';
import { Tab, Tabs } from '@mui/material';
import CardTitleStart from '../../1.-shared/1.2.-layout/1.2.5-cardTitleStart/cardTitleStart';
import FinanciamientoDatosCondiciones from "../9.40.-financiamiento/financiamientoDatosCondiciones";
import ResultadosEvaluacion from '../9.50.-resultado/resultadoEvaluacion';
import RevisionEvaluacion from '../9.60.-revision/revision';
import RequestManager from '../../../services/origination/wapirequest.service';
import { useParams } from "react-router-dom";
import { Toaster, toast } from 'sonner';
import LoadingProgress from '../../1.-shared/1.4.-loading/loading-progress/loading-progress';
import { ResultadoRevision } from '../9.60.-revision/9.60.1.-resultadoRevision/resultadoRevision';
import { HistorialRevision } from '../9.60.-revision/9.60.2.-historialRevision/historialRevision';
import MasterManager from '../../../services/origination/wapimaster.service';
import { useLocation, useNavigate } from "react-router-dom";
import { appConfigKey, appConfigTipoTransaccionLog, appSecurityConfiguration } from '../../../../config/Config';
import ResultadosHistorial from '../../7.-managerProfile/7.50.-Resultados/resultadosHistorial';
import DocumentationContratosTable from '../../5.-documentation/5.10.-base/components/DocumentationContratosTable';
import DocumentationTabPanel from '../../5.-documentation/5.10.-base/components/DocumentationTabPanel';
import DocumentationDownload from '../../5.-documentation/5.10.-base/components/DocumentationDownload';
import ServicesProvider from "../../../services/support/wapiservicesprovider";
import { ExceptionResponse } from '../../../models/Shared/result-response.model';
import { MnIntegracionSolicitudRequest } from '../../../models/RequestManager/integracion-solicitud-request.model';
import ReportManager from '../../../services/support/wapireport';
import AlertSolicitudObservadaCard from './components/AlertSolicitudObservadaCard';
import ValidacionBPOHelper from './helpers/ValidacionBPOHelper';
import SolicitudPersonaObservadaHelper from '../../1.-shared/1.5.-observed/1.5.9.-helpers/SolicitudPersonaObservadaHelper';


const headerTitles = ['FINANCIAMIENTO', 'RESULTADO DE VALIDACIÓN', 'REVISIÓN BPO', 'CONTRATO'];
const START_POSITION = 0;


const DboProfile = () => {

  const { solicitudId } = useParams();
  const [requestSolicitudId, setRequestSolicitudId] = React.useState({
    idenT_SOLICITUD: 0
  })
  const [title, setTitle] = React.useState("Evaluación Documental - Financiamiento");
  const [tabsPosition, setTabsPositon] = React.useState(START_POSITION);
  const handleTabsPosition = (e: any, value: any) => {
    setTabsPositon(value);
    let title = "Evaluación Documental  - ";
    setTitle(
      value === 0
        ? title + "Financiamiento"
        : value === 1
          ? title + "Resultado de validación"
          : title + "Revisión BPO"
    );
  };

  const [datosFinanciamiento, setDatosFinanciamiento] = React.useState<any>([]);
  const [resultadosEvaluacion, SetResultadosEvaluacion] = React.useState<any>([]);
  const [revisionEvaluacion, SetRevisionEvaluacion] = React.useState<any>([]);
  const [datosRegistroBien, SetDatosRegistroBien] = React.useState<any>([]);
  const [datosSolActivacion, SetDatosSolActivacion] = React.useState<any>(null);
  const [contratoList, setContratoList] = React.useState<any[]>([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [listResultadoHistorial, setListResultadoHistorial] = React.useState<any>([]);
  const [solicitudData, setSolicitudData] = useState<any>(0);
  const [solicitudObservada, setSolicitudObservada] = React.useState<any>({ observado: false, mensaje: "", detalle: null });
  const tableContratosHeaders = ['DOCUMENTOS DESCARGABLES', 'ACCIÓN'];
  const contratosWidthColumns = (index: number) => {
    const width = ['80%', '20%'];
    return width[index];
  };

  let navigate = useNavigate();

  const cargarDatosFinanciamiento = async (data: any) => {
    let datosFinanciamiento: any = [];
    let datosResultado: any = [];
    let datosRevision: any = [];
    let datosActivacion: any = {};
    const response = await RequestManager.GetMnDatosFinanciamiento({ "IDENT_SOLICITUD": data.idenT_SOLICITUD });
    if (response.status === 200) {
      datosFinanciamiento = response.data.content.datosFinanciamiento;
      datosResultado = response.data.content.resultadosEvaluacion;
      datosRevision = response.data.content.revisionEvaluacion;
      datosActivacion = response.data.content.datosActivacion;

      setDatosFinanciamiento(datosFinanciamiento);
      SetResultadosEvaluacion(datosResultado);
      SetRevisionEvaluacion(datosRevision);
      SetDatosRegistroBien(datosFinanciamiento);
      SetDatosSolActivacion(datosActivacion);
    }
  }
  const GetMnHistorialResultado = async () => {
    let resultadoHistorial: any = []
    const response = await RequestManager.GetMnResultadoRevision({ "idenT_SOLICITUD": solicitudId });
    if (response.status === 200) {
      resultadoHistorial = response.data.content;
    };
    setListResultadoHistorial(resultadoHistorial);
  }

  const onSolicitudObservada = (content: any) => {
    const validacionNewBlackListGesintel = ValidacionBPOHelper.NewBlackListGesintel(content);
    setSolicitudObservada({ observado: true, mensaje: validacionNewBlackListGesintel?.mensaje, detalle: validacionNewBlackListGesintel?.detalle })
  }

  const handleDownloadAllContratoFile = async (event: any) => {
    event.preventDefault();
    try {
      setIsLoading(true)
      for (let index = 0; index < contratoList.length; index++) {
        const data = contratoList[index];
        await generarDescargaContrato(Number(solicitudId), Number(datosFinanciamiento.idoperacion), appSecurityConfiguration.SessionUserId, data.genericA_IDENT_CAMPO, false, data);
      }
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const BTObtenerResumenPrestamo = async (operacionUId: number) => {
    try {
      let result = await ServicesProvider.BTObtenerResumenPrestamo(operacionUId);
      if (result?.status === 200 && result?.data?.isValid && result?.data?.content) {
        return result.data.content;
      } else if (result?.data?.exceptions && result?.data?.exceptions?.length > 0) {
        return result?.data?.exceptions[0];
      } else {
        return {
          code: "Frontend",
          description: `Response status code does not indicate success: ${result?.status}.`
        } as ExceptionResponse
      }
    } catch (error) {
      return null;
    }
  }

  const GetMnIntegracionSolicitudInsert = async (solicitudId: number, tipoTransaccion: number, params: any, request: any, response: any) => {
    let body = {
      idenT_SOLICITUD: solicitudId,
      params: params ? JSON.stringify(params) : '{}',
      request: request ? JSON.stringify(request) : '{}',
      response: response ? JSON.stringify(response) : '{}',
      iD_TIPO_TRANSACCION: tipoTransaccion,
      idenT_USUARIO_CREADOR: appSecurityConfiguration.SessionUserId
    } as MnIntegracionSolicitudRequest;

    try {
      let result = await RequestManager.GetMnIntegracionSolicitudInsert(body);
      return result?.status === 200 && result?.data?.isValid;
    } catch (error) {
      return false;
    }
  }

  const descargarReporte = async (solicitudId: number, usuarioId: number, reporteId: number, bpo: boolean) => {
    const responseObtenerReporte = await ReportManager.ObtenerReporte({
      "idSolicitud": solicitudId,
      "idUsuario": usuarioId,
      "idReporte": reporteId,
      "indBPO": bpo
    });

    if (responseObtenerReporte.status !== 200) {
      return null;
    }

    return responseObtenerReporte;
  };

  const getExtensionFromContentType = (contentType: string) => {

    const contentTypeMapping: any = {
      'application/pdf': 'pdf',
      'image/jpeg': 'jpg',
      'image/png': 'png',
      'image/gif': 'gif',
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    };

    const extension = contentTypeMapping[contentType];

    if (extension) {
      return extension;
    }

    return null;

  }

  const generarDescargaContrato = async (solicitudId: number, operacionUId: number, usuarioId: number, reporteId: number, bpo: boolean, data: any) => {

    let params = { operacionUId };
    let resumenPrestamoResponse = await BTObtenerResumenPrestamo(params.operacionUId);
    if (resumenPrestamoResponse && 'code' in resumenPrestamoResponse) {
      toast.message(`No se pudo obtener resumen de prestamo para ${data?.genericA_DESCM_CAMPO}`, { duration: appConfigKey.keyDurationToast });
    }

    let integracionSuccess = await GetMnIntegracionSolicitudInsert(solicitudId, appConfigTipoTransaccionLog.ObtenerPrestamoResumenEjecutivo, params, null, resumenPrestamoResponse);

    if (!integracionSuccess) {
      toast.error(`Falló la generación resumen informativa para ${data?.genericA_DESCM_CAMPO}`, { duration: appConfigKey.keyDurationToast });
      return;
    }

    let reporteResponse: any = await descargarReporte(solicitudId, usuarioId, reporteId, bpo);

    if (reporteResponse === null) {
      toast.error(`Falló la descarga de ${data?.genericA_DESCM_CAMPO}`, { duration: appConfigKey.keyDurationToast });
      return;
    }

    var fileName = null;
    var header = reporteResponse?.headers['content-disposition'];

    if (header) {
      var filenameRegex = /filename\*[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      var matches = filenameRegex.exec(header);
      if (matches != null && matches[1]) {
        fileName = matches[1].replace(/UTF-8['"]*/g, '');
        fileName = decodeURI(fileName);
        fileName = fileName.split('.');
        fileName = fileName[0] + '.' + getExtensionFromContentType(reporteResponse?.headers['content-type']);
      }
    }

    if (!fileName) {
      fileName = `${data?.genericA_DESCM_CAMPO}_${solicitudId}.docx`;
    }

    const url = window.URL.createObjectURL(reporteResponse?.data);
    const a = document.createElement("a");

    a.href = url;
    a.download = fileName!;
    a.click();

    window.URL.revokeObjectURL(url);
  }

  const handleDownloadContratoFile = async (event: any, data: any) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      //myowasp("POrueba Contrato");
      //myowasp(datosFinanciamiento);
      await generarDescargaContrato(Number(solicitudId), Number(datosFinanciamiento.idoperacion), appSecurityConfiguration.SessionUserId, data.genericA_IDENT_CAMPO, false, data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
    }
  }

  const obtenerContratos = () => {
    MasterManager.GetMnTablaGenericaDefinicion({
      "v_LIST_IDENT_TABLA": "14"
    }).then(response => {
      const { content } = response.data;
      const contratoList = content.filter((x: any) => x.genericA_CODL_CAMPO === "1" && x.genericA_IDENT_CAMPO !== 51);
      setContratoList(contratoList);
    });
  };

  const checkPlaftRiesgoObservado = async (tempSolicitud: any) => {
    if (tempSolicitud && tempSolicitud !== 0) {
      if (tempSolicitud.iD_ESTADO_SOLICITUD === appConfigKey.KeyIdEstadoSolicitudObservado &&
        [tempSolicitud.idenT_SITUACION_PLAFT, tempSolicitud.idenT_SITUACION_RIESGOS].includes(appConfigKey.CodigoSituacionPlaftRiesgosObservada)
      ) {

        let detalle: string[] = [];
        let solicitudObservada = { observado: true, mensaje: "", detalle: null };

        if (tempSolicitud.idenT_SITUACION_PLAFT === appConfigKey.CodigoSituacionPlaftRiesgosObservada &&
          tempSolicitud.idenT_SITUACION_RIESGOS === appConfigKey.CodigoSituacionPlaftRiesgosObservada) {
          solicitudObservada.mensaje = appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'PLAFT y RIESGOS');
        } else if (tempSolicitud.idenT_SITUACION_PLAFT === appConfigKey.CodigoSituacionPlaftRiesgosObservada) {
          solicitudObservada.mensaje = appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'PLAFT');
        } else {
          solicitudObservada.mensaje = appConfigKey.keyMsjClienteListaNegraYGesintel.replace('[CAMPO]', 'RIESGOS');
        }

        setSolicitudObservada(solicitudObservada);

        try {
          const response = await MasterManager.GetMnSolicitudPersonaObservada(tempSolicitud.idenT_SOLICITUD);
          if (response?.status === 200 && response.data?.content?.length > 0) {
            const miembroList = response.data.content;
            detalle = SolicitudPersonaObservadaHelper.GetDetalleObservaciones(miembroList)
          } else {
            detalle.push(appConfigKey.keyMsjErrorListaNegraYGesintelDetalle);
          }
        } catch (error) {
          detalle.push(appConfigKey.keyMsjErrorListaNegraYGesintelDetalle);
        }

        setSolicitudObservada({ ...solicitudObservada, detalle });

      }
    }
  }

  const initSolicitud = async (idenT_SOLICITUD: any) => {
    try {
      const response = await MasterManager.GetMnSolicitud({ "idenT_SOLICITUD": idenT_SOLICITUD });
      if (response.status === 200 && response.data?.content?.length > 0) {
        setSolicitudData(response.data.content[0]);
      } else {
        toast.message('Hubo un problema al obtener los datos de la solicitud. Por favor, inténtelo de nuevo.', { duration: appConfigKey.keyDurationToast });
      }
    } catch (error) {
      toast.message('Hubo un problema al obtener los datos de la solicitud. Por favor, inténtelo de nuevo.', { duration: appConfigKey.keyDurationToast });
    } finally {
    }
  };

  React.useEffect(() => {
    checkPlaftRiesgoObservado(solicitudData)
  }, [solicitudData]);

  React.useEffect(() => {
    initSolicitud(solicitudId);
  }, [solicitudId]);

  React.useEffect(() => {
    const reqSolicitud: any = { ...requestSolicitudId };
    reqSolicitud.idenT_SOLICITUD = solicitudId;
    setRequestSolicitudId(reqSolicitud);

    cargarDatosFinanciamiento(reqSolicitud);
    GetMnHistorialResultado();
    obtenerContratos();
  }, [requestSolicitudId.idenT_SOLICITUD]);

  return (

    <div>
      <Toaster
        position="top-center"
        toastOptions={{ style: { width: '500px', margin: '0 auto' } }}
        richColors closeButton
      />
      <MainLayout />
      <CardTitleStart Ventana={title} identSolicitud={solicitudId} />
      {isLoading && <LoadingProgress />}

      {solicitudObservada.observado
        && <AlertSolicitudObservadaCard mensaje={solicitudObservada.mensaje} detalle={solicitudObservada.detalle} />}

      <div className="row g-2 mb-2 cardAnalystProfile">
        <div className="col-lg-12">

          <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
            <Tabs value={tabsPosition} onChange={handleTabsPosition}>
              {headerTitles.map((header, index) => (
                <Tab
                  style={{ fontWeight: "bold", fontSize: "50px" }}
                  key={index}
                  label={header}
                  className="header-tab-documentation" />))}
            </Tabs>
          </div>
          {tabsPosition === 0 && (
            <>
              <FinanciamientoDatosCondiciones
                datosFinanciamiento={datosFinanciamiento}
                datosSolActivacion={datosSolActivacion}
              />
            </>
          )}
          {tabsPosition === 1 && (
            <>
              <ResultadosEvaluacion
                resultadosEvaluacion={resultadosEvaluacion}
              />
              <ResultadosHistorial
              />
            </>
          )}
          {tabsPosition === 2 && (
            <>
              <RevisionEvaluacion
                revisionEvaluacion={revisionEvaluacion}
              />
              <ResultadoRevision
                datosRegistroBien={datosRegistroBien}
                GetMnHistorialResultado={GetMnHistorialResultado}
                onSolicitudObservada={onSolicitudObservada}
                listResultadoRevision={listResultadoHistorial}
              />
              <HistorialRevision
                tableData={listResultadoHistorial}
              />
            </>
          )}
          <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
            <div className="row g-2 mb-2 align-items-center">
              <DocumentationTabPanel value={tabsPosition} index={3}>
                <DocumentationContratosTable
                  widthColumns={contratosWidthColumns}
                  tableHeaders={tableContratosHeaders}
                  tableData={contratoList}
                  handleClick={handleDownloadContratoFile}
                />
                <div style={{ display: 'flex', justifyContent: 'end', marginTop: '20px' }}>
                  <DocumentationDownload text='Descargar todo' handlerOnClick={handleDownloadAllContratoFile} />
                </div>
              </DocumentationTabPanel>
            </div>
          </div>

          {/* {tabsPosition === 3 && (
            <>
            <DocumentationContratosTable
                widthColumns={contratosWidthColumns}
                tableHeaders={tableContratosHeaders}
                tableData={contratoList}
                //handleClick={handleDownloadContratoFile}
             />
            </>
          )} */}
        </div>
      </div>
    </div>
  )
}

export default DboProfile;