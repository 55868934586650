import { useContext } from "react";
import { MoneyInput, SimpleInput } from "../../3.40.-SolicitudeStepOne/components/elements/inputs";
import TitleCard from "../../3.40.-SolicitudeStepOne/components/elements/titleCard";
import { StepsContext } from "../../3.10.-base/providers";

const OnlyView = (props: any) => {

    const { viewModel } = props;

    const { solicitudData } = useContext(StepsContext);

    if (viewModel === null) {
        return <></>;
    }

    return (
        <div className="border rounded-10 shadow p-3 p-md-4 mb-4 mb-md-4 card">
            <TitleCard title={"DATOS DEL FINANCIAMIENTO"} />
            <div className="row g-2 mb-4 align-items-center">
                <div className="col-lg-2 cardObjets">
                    <SimpleInput title={"Estado del vehículo"} value={viewModel.EstadoVehiculo} disabled={true} />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput title={"Año del vehículo"} value={viewModel.VehiculoYear} disabled={true} />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput title={"Concesionario"} value={viewModel.Concesionario} disabled={true} />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput title={"Sucursal"} value={viewModel.Sucursal} disabled={true} />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput title={"Vendedor"} value={viewModel.Vendedor} disabled={true} />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput title={"Email vendedor"} value={viewModel.VendedorEmail} disabled={true} />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput title={"Moneda"} value={viewModel.Moneda} disabled={true} />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput title={"Tipo de cambio"} value={viewModel.TipoCambioBase} disabled={true} />
                </div>
                <div className="col-lg-2 cardObjets">
                    <MoneyInput startDecorator={"$"} title={"Monto del vehículo"} value={viewModel.MontoVehiculoBaseDolares} disabled={true} />
                </div>
                <div className="col-lg-2 cardObjets">
                    <MoneyInput startDecorator={"S/"} title={"Monto del vehículo"} value={viewModel.MontoVehiculoBaseSoles} disabled={true} />
                </div>
                <div className="col-lg-4 cardObjets">
                    <MoneyInput startDecorator={"%"} title={"Inicial"} value={viewModel.InicialBase} disabled={true} />
                </div>
                <div className="col-lg-2 cardObjets">
                    <MoneyInput title={"Monto inicial"} startDecorator={"$"} value={viewModel.MontoInicialBaseDolares} disabled={true} />
                </div>
                <div className="col-lg-2 cardObjets">
                    <MoneyInput title={"Monto inicial"} startDecorator={"S/"} value={viewModel.MontoInicialBaseSoles} disabled={true} />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput title={"Uso del vehículo"} value={viewModel.UsoVehiculo} disabled={true} />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput title={"Destino del crédito"} value={viewModel.DestinoCredito} disabled={true} />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput title={"Marca"} value={viewModel.Marca} disabled={true} />
                </div>
                <div className="col-lg-4 cardObjets">
                    <SimpleInput title={"Modelo"} value={solicitudData.descC_MODELO} disabled={true} />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput title={"Versión"} value={viewModel.Version} disabled={true} />
                </div>
                <div className="col-lg-2 cardObjets">
                    <SimpleInput startDecorator={viewModel.MonedaId === 1 ? "S/" : "$"} title={"Monto a financiar"} value={viewModel.MontoFinanciarBase} disabled={true} />
                </div>
            </div>
        </div>
    );

};

export default OnlyView;