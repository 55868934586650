import React from "react";
import CircularProgress from '@mui/material/CircularProgress';

const DocumentationFileDowload = (props: any) => {
    const [loading, setLoading] = React.useState<boolean>(false);

    const handleClick = async (event: any) => {
        event.preventDefault();
        if (props.data?.file === '-') return;

        setLoading(true);

        await props.handleDownloadFile(event, props.data);

        setLoading(false);
    };

    return (
        <>
            {!loading ? (

                <div
                    className={props.className}
                    style={{ cursor: props.data?.file == '-' ? 'default' : 'pointer' }}
                    onClick={handleClick}
                >{props.data.file}</div>
            ) : (
                <>
                    <CircularProgress size={12} />
                    {/* <label className={props.className}>Descargando..</label> */}
                </>
            )}
        </>
    );
};

export default DocumentationFileDowload;