import { appDefaultValues } from "../../../../config/Config";
import { BTCrearClientePartner } from "./BTCrearClientePartner";

export class BTCrearClienteRequest {
    
    private personaUID: number;
    private sectorId: number;
    private clasificacionInternaId: number;
    private ejecutivoId: number;
    private btpartner: BTCrearClientePartner;

    constructor() {
        this.personaUID = appDefaultValues.NumberDefault;
        this.sectorId = appDefaultValues.NumberDefault;
        this.clasificacionInternaId = appDefaultValues.NumberDefault;
        this.ejecutivoId = appDefaultValues.NumberDefault;
        this.btpartner = new BTCrearClientePartner();
    }

    public getPersonaUID(): number {
        return this.personaUID;
    }

    public setPersonaUID(personaUID: number): void {
        this.personaUID = personaUID;
    }

    public getSectorId(): number {
        return this.sectorId;
    }

    public setSectorId(sectorId: number): void {
        this.sectorId = sectorId;
    }

    public getClasificacionInternaId(): number {
        return this.clasificacionInternaId;
    }

    public setClasificacionInternaId(clasificacionInternaId: number): void {
        this.clasificacionInternaId = clasificacionInternaId;
    }

    public getEjecutivoId(): number {
        return this.ejecutivoId;
    }

    public setEjecutivoId(ejecutivoId: number): void {
        this.ejecutivoId = ejecutivoId;
    }

    public getBtpartner(): BTCrearClientePartner {
        return this.btpartner;
    }

    public setBtpartner(btpartner: BTCrearClientePartner): void {
        this.btpartner = btpartner;
    }

}