import './scoringHistoricoIngreso.scss';
import { Dialog } from "@mui/material";
import { Table } from "@mui/joy";

const HistoricoIngresosRequestDialog = ({open, handleClose, historicoIngresos}: any) => {
    return (
        <Dialog open={open} maxWidth={'lg'} >
            <div className='request-dialong-content'>
                <div className='request-dialog-title'>HISTORIAL INGRESOS</div>
                <div className='request-dialog-list-content'>
                    <div className="row g-2 mb-2 align-items-center">
                    <div className="col-lg-8 cardObjets align-top">
                        <Table borderAxis="none" className="style-table align-top">
                        <thead>
                        <tr>
                        <th style={{ fontSize: "12px", width: "80px", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>FECHA</th>
                        <th style={{ fontSize: "12px", width: "70px", backgroundColor: "#E1E1E1", }}>USUARIO</th>
                        <th style={{ fontSize: "12px", width: "80px", backgroundColor: "#E1E1E1", }}>PARTICIP.</th>
                        <th style={{ fontSize: "12px", width: "60px", backgroundColor: "#E1E1E1", }}>NETO</th>
                        <th style={{ fontSize: "12px", width: "95px", backgroundColor: "#E1E1E1", }}>NETO NUEVO</th>
                        <th style={{ fontSize: "12px", width: "95px", backgroundColor: "#E1E1E1", }}>ING ANUAL A</th>
                        <th style={{ fontSize: "12px", width: "95px", backgroundColor: "#E1E1E1", }}>ING ANUAL B</th>
                        <th style={{ fontSize: "12px", width: "120px", backgroundColor: "#E1E1E1", }}>MONTO ANUAL A</th>
                        <th style={{ fontSize: "12px", width: "120px", backgroundColor: "#E1E1E1", }}>MONTO ANUAL B</th>
                        <th style={{ fontSize: "12px", width: "100px", backgroundColor: "#E1E1E1", }}>TIPO INGRESO</th>
                        <th style={{ fontSize: "12px", width: "240px", backgroundColor: "#E1E1E1", }}>COMENTARIO</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                        historicoIngresos.length > 0 && historicoIngresos.map((row: any, index: number) => {
                            return (
                            <tr key={index}>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.fechA_REGISTRO}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.username}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.tipO_PARTICIPACION}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.montO_INGRESO_NETO}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.montO_INGRESO_NETO_NUEVO}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.inD_ANUALIZADO}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.inD_ANUALIZADO_NUEVO}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.montO_INGRESO_NETO_ANUALIZADO}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.montO_INGRESO_NETO_ANUALIZADO_NUEVO}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.tipO_INGRESO}</td>
                            <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.comentario}</td>
                            </tr>
                            )
                        })
                        }
                        </tbody>
                        </Table>
                    </div>
                    </div>
                </div>
                <div className='request-dialog-btn-close cursor-pointer' onClick={handleClose}>Cerrar</div>
            </div>
        </Dialog>
    );
};

export default HistoricoIngresosRequestDialog;