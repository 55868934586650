import { Component } from "react";
import { Button } from "@mui/joy";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import KeyboardArrowRight from "@mui/icons-material/KeyboardArrowRight";
import { ReleaseRejectDialogAction } from "../1.5.6.-dialog/ReleaseRejectDialog";

interface IObservedActionButtonsProps {
  handleClick: any;
}

class ObservedActionButtons extends Component<IObservedActionButtonsProps, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    return (
      <div className="row g-2 mb-2 justify-content-between">
        <div className="col-sm-6 col-md-4 col-lg-2 col-12">

        </div>
        <div className="col-sm-6 col-md-4 col-lg-2 col-12" style={{ textAlign: "end" }}>
          <Button
            disabled={false}
            size="md"
            color="danger"
            sx={{ borderRadius: 24, width: 1 }}
            onClick={() => {
              this.props.handleClick(ReleaseRejectDialogAction.RELEASE);
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                width: "-webkit-fill-available",
              }}
            >
              <div style={{ width: "inherit" }}>Guardar</div>
              <KeyboardArrowRight />
            </div>
          </Button>
        </div>
      </div>
    );
  }
}
export default ObservedActionButtons;
