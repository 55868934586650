import {
  CircularProgress,
  FormControl,
  FormLabel,
  Textarea
} from "@mui/joy";
import { Box, Dialog, DialogTitle, List, MenuItem, Select, Tab, Tabs } from "@mui/material";

import { Button } from '@mui/joy';
import SaveIcon from '@mui/icons-material/Save'
import AddIcon from '@mui/icons-material/Add'
import React, { useState } from "react";
import '../../../2.-quatition/2.10.-base/quatition.scss';
import MasterManager from "../../../../services/origination/wapimaster.service";
import RequestManager from "../../../../services/origination/wapirequest.service";
import { useNavigate, useParams } from "react-router-dom";
import RegistrarBienDialog from "../../9.10.-base/components/RegistrarBienDialog";
import { Toaster, toast } from "sonner";
// import { appConfig, appConfigKey, appConfigSolicitudEtapa } from "../../../../../config/Config";
import { appConfig, appConfigKey } from "../../../../../config/Config";
import { MnValidarListaNegraYGesintelRequest } from "../../../../models/RequestManager/solicitud-validar-request.model";


export const ResultadoRevision = (props: any) => {

  const { solicitudId } = useParams();
  const [requestResultado, setRequestResultado] = React.useState({
    idTipoResultado: 0,
    idResultado: 0,
    comentario: ""
  })
  const [listEstado, setListEstado] = React.useState<any>([]);
  const [listTipoResultado, setListTipoResultado] = React.useState<any>([]);
  const [openBien, setOpenBien] = React.useState(false);
  const [btnBien, setBtnBien] = React.useState(false);
  const [btnGuardar, setBtnGuardar] = React.useState(false);
  const [btnActivar, setBtnActivar] = React.useState(true);
  const [inputState, setInputstate] = React.useState(false);

  const changeValue = (e: any, indicador: any) => {
    let { name, value, type } = e.target;
    if (indicador === 1) {
      ObtenerEstado(value);
    }
    if (type == "number" && value == "") {
      value = 0;
    }
    if (type == "select") {
      value = parseInt(value);
    }
    if ((type = "date" && value == "Invalid Date")) {
      value = null;
    }

    setRequestResultado({
      ...requestResultado,
      [name]: value,
    });
  }
  const ObtenerEstado = async (id: any) => {
    var body = {
      "idenT_CAMPO": id
    };
    let listEstados: any = [];
    var response: any = await RequestManager.GetMnTipoResultado(body)
      .then((response: any) => {

        response.data.content.forEach((element: any) => {
          listEstados.push({ id: element.genericA_IDENT_CAMPO, label: element.genericA_DESCM_CAMPO });
        })
        setListEstado(listEstados);
      })
  }
  const ObtenerTipoResultado = async () => {
    var body = {
      "v_LIST_IDENT_TABLA": "18"
    };
    let listTipoResultado: any = [];
    var response: any = await MasterManager.GetMnTablaGenericaDefinicion(body)
      .then((response: any) => {

        response.data.content.forEach((element: any) => {
          listTipoResultado.push({ id: element.genericA_IDENT_CAMPO, label: element.genericA_DESCM_CAMPO });
        })
        listTipoResultado = listTipoResultado.filter((item: any) =>
          item.id !== 3052
        )
        
        setListTipoResultado(listTipoResultado);
      })
  }

  let navigate = useNavigate();

  const Guardar = async () => {

    let idResultado = Number(requestResultado.idResultado);
    let idTipoResultado = Number(requestResultado.idTipoResultado);
    let comentario = String(requestResultado.comentario);
    if (idResultado <= 0) {
      toast.error(appConfigKey.keyMsjCampoOblResultado, { duration: 4000 });
      return;
    }
    if (idTipoResultado <= 0) {
      toast.error(appConfigKey.keyMsjCampoOblTipoResultado, { duration: 4000 });
      return;
    }
    if (comentario === '') {
      toast.error(appConfigKey.keyMsjCampoOblComentario, { duration: 4000 });
      return;
    }

    let resultado;
    var body = {
      idenT_SOLICITUD: solicitudId,
      idenT_RESULTADO: requestResultado.idResultado,
      idenT_TIPO_RESULTADO: requestResultado.idTipoResultado,
      comentario: requestResultado.comentario,
      idenT_USUARIO_CREADOR: localStorage.getItem('UserMn')
    };
    const response = await RequestManager.GetMnResultadoRevisionInsert(body);
    if (response.status === 200) {
      resultado = response.data.isValid;
      if (resultado === true) {
        toast.success("Registro guardado", { duration: 5000 });
        if (requestResultado.idResultado === 80) {
          const timer = setTimeout(() => {
            navigate(`/BandejaSolicitudes/${appConfigKey.keyTipoBusquedaPorBpoSeguimiento}`);
          }, 1000);
          return () => clearTimeout(timer);
        }
      }
    };
    GetMnEstadoSolicitud();
    props.GetMnHistorialResultado();
  }
  const GetMnEstadoSolicitud = async () => {
    let resultado;
    var body = {
      idenT_SOLICITUD: solicitudId,
    };
    const response = await RequestManager.GetMnSolicitudEstado(body);
    if (response.status === 200) {
      resultado = response.data.content;
      if (resultado.iD_ESTADO_SOLICITUD === 86) {
        setBtnGuardar(true)
        setInputstate(true)
        const Data = props.listResultadoRevision.filter((x: any) => x.idenT_RESULTADO === 78);
        ObtenerEstado(78);
        setRequestResultado({
          idTipoResultado: Data[0].idenT_TIPO_RESULTADO,
          idResultado: Data[0].idenT_RESULTADO,
          comentario: Data[0].comentario
        });
      }
      else if (resultado.iD_ESTADO_SOLICITUD === 84) {
        setBtnBien(false);
        setBtnActivar(false);
        setInputstate(true)
        setBtnGuardar(true);
      }
      else {
        if (resultado.iD_ESTADO_SOLICITUD === 48 || resultado.iD_ESTADO_SOLICITUD === 57) {
          setBtnBien(false);
          setInputstate(true)
          setBtnGuardar(true);
        } else {
          setBtnBien(true);
          setInputstate(false)
          setBtnGuardar(false);
        }

        if (resultado.iD_ESTADO_SOLICITUD === 92) {
          setBtnBien(false);
          setBtnActivar(false);
          setInputstate(true)
          setBtnGuardar(true);
        }
        if (resultado.iD_ESTADO_SOLICITUD === 49 || resultado.iD_ESTADO_SOLICITUD === 88) {
                  setBtnBien(true);
                  setBtnActivar(true);
                  setInputstate(true)
                  setBtnGuardar(true);
          //   const success = await RequestManager.GetValidarListaNegraYGesintel(
          //   {
          //       identSolicitud: Number(solicitudId),
          //       identEtapa: appConfigSolicitudEtapa.Formalizacion,
          //       identUsuario: Number(localStorage.getItem('UserMn')),
          //       actualizarBD: true,
          //       miembros: null,
          //   } as MnValidarListaNegraYGesintelRequest
          //   ).then((response) => {
          //     const content = response.data.content;
          //     //Miembros observados
          //     if (content.resultado == 1 || content.miembrosObservados?.length > 0) {
          //         setBtnBien(false);
          //         setBtnActivar(true);
          //         setInputstate(true)
          //         setBtnGuardar(true);
          //     }
          //     else{
          //         setBtnBien(true);
          //         setBtnActivar(true);
          //         setInputstate(true)
          //         setBtnGuardar(true);
          //     }
          // });
            
          }
        // }else{setBtnActivar(true);setBtnBien(true);}
      }
    };
  }
  const openDialogBien = (event: any) => {
    setOpenBien(true);
  }
  const handleDialogCloseBien = () => {
    setOpenBien(false);
  };
  const cargarHistorial = () => {
    props.GetMnHistorialResultado();
  };
  const onSolicitudObservada = (mensaje: string) => {
    props.onSolicitudObservada(mensaje);
  }
  React.useEffect(() => {
    // ObtenerEstado();
    ObtenerTipoResultado();
    GetMnEstadoSolicitud();
  }, []);

  return (

    <div className="border rounded-9 shadow p-3 p-md-4 mb-md-4 cardTabs">
      <div className="row g-2 align-items-center">
        <div className="col-lg-4 textLabelHeadboard">
          <span className="fw-bold">RESULTADO DE REVISIÓN</span>
        </div>
        <div className="col-lg-4">
          <span className="fw-bold"> </span>
        </div>
        <div className="col-lg-4 textAlignRigth"></div>
      </div>

      <div className="row g-2 mb-2 align-top">
        <div className="col-lg-2">
          <FormControl>
            <FormLabel>Resultado<span style={{ color: "red" }}> * </span></FormLabel>
            <Select
              variant="outlined"
              id="selResultado"
              name="idResultado"
              onChange={((e) => changeValue(e, 1))}
              //defaultValue="0"
              className="custom-select" disabled={inputState}
              value={requestResultado.idResultado}
            >
              <MenuItem value="0">--Seleccione--</MenuItem>
              {listTipoResultado.map((row: any) => (
                <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-4">
        </div>
        <div className="col-lg-2">
          <FormControl>
            <FormLabel>Tipo Resultado</FormLabel>
            <Select
              variant="outlined"
              id="selTipoResultado"
              name="idTipoResultado"
              onChange={((e) => changeValue(e, 0))}
              //defaultValue="0"
              value={requestResultado.idTipoResultado}
              className="custom-select" disabled={inputState}>
              <MenuItem value="0">--Seleccione--</MenuItem>
              {listEstado.map((row: any) => (
                <MenuItem key={row.id} value={row.id}> {row.label}</MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
        <div className="col-lg-12 align-top ">
          <FormLabel>Comentario<span style={{ color: "red" }}> * </span></FormLabel>
          <Textarea name="comentario" id="textComentario" value={requestResultado.comentario} minRows={4} disabled={inputState} className="custom-textarea" onChange={(e) => changeValue(e, 0)}></Textarea>
        </div>
      </div>

      <div className="row g-2 mb-2 align-items-center cardObjets">
        <div className="col-lg-3 cardObjets">
        </div>
        <div className="col-lg-3 cardObjets">
        </div>
        <div className="col-lg-6 cardObjets bottom-right">
          <Button size="md" color="danger" startDecorator={<AddIcon />}
            sx={{ borderRadius: 24, width: 4 / 10 }} onClick={openDialogBien} disabled={btnBien}>
            Registrar bien
          </Button> &nbsp;&nbsp;
          <Button size="md" color="danger" startDecorator={<SaveIcon />}
            sx={{ borderRadius: 24, width: 3 / 10 }} onClick={() => Guardar()} disabled={btnGuardar} >
            Guardar
          </Button>
        </div>

      </div>
      {/* <HistorialRevision
           tableData={listResultadoHistorial}
        /> */}
      <RegistrarBienDialog
        open={openBien}
        handleClose={handleDialogCloseBien}
        datosRegistroBien={props.datosRegistroBien}
        btnActivar={btnActivar}
        cargarHistorial={cargarHistorial}
        onSolicitudObservada={onSolicitudObservada}
        setInputstate={setInputstate}
      />
    </div>
  );
};