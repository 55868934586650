import { appDefaultValues } from "../../../../../../config/Config"

export interface DatosAlertPlaftRiskCard {
    Mensaje: string,
    Detalle: string[] | null,
};

export const DatosAlertPlaftRiskCardDefault: DatosAlertPlaftRiskCard = {
    Mensaje: appDefaultValues.StringEmpty,
    Detalle: appDefaultValues.NullDefault
};
