import { Component } from "react";
import BlackListDetail from "./BlackListDetail";
import EmptyData from "../1.5.3.-empty-data/EmptyData";

interface IBlackListProps {
  objBlackListProps: {
    blackListData: any[];
    blackListDeltaData: any[];
    member: any;
  };
}

class BlackList extends Component<IBlackListProps, any> {
  constructor(props: any) {
    super(props);
  }

  render() {
    const data = this.props.objBlackListProps;
    return (
      <>
        {data.blackListData.length == 0 && (
          <EmptyData />
        )}
        {data.blackListData.length > 0 &&
          data.blackListData.map(
            (item: any, i: number) => {
              const deltaExists = data.blackListDeltaData.some(itemToCheck =>
                itemToCheck.typeName === item.typeName &&
                itemToCheck.description === item.description
              );
              const showNew = !data.member?.liberadO_PLAFT || !data.member?.liberadO_RIESGOS;
              return (
                <div key={i} className="col">
                  <BlackListDetail
                    objBlackListDetailProps={{
                      typeName: item.typeName,
                      description: item.description,
                      showNew: deltaExists && showNew,
                    }}
                  ></BlackListDetail>
                  {i <
                    data.blackListData.length - 1 && (
                      <hr className="hr hr-blurry mb-4" />
                    )}
                </div>
              );
            }
          )}
      </>
    );
  }
}
export default BlackList;
