import React, { useEffect, useState } from "react";
import FormControl from "@mui/joy/FormControl";
import FormLabel from "@mui/joy/FormLabel";
import Input from "@mui/joy/Input";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import Switch from "@mui/joy/Switch";
import Typography from "@mui/joy/Typography";
import { Button, Table } from "@mui/joy";
import Accordion from '@mui/joy/Accordion';
import AccordionDetails from '@mui/joy/AccordionDetails';
import AccordionGroup from '@mui/joy/AccordionGroup';
import AccordionSummary from '@mui/joy/AccordionSummary';
import { IconButton, Tooltip } from "@mui/material";
import GeneralHelper from "../../../helpers/GeneralHelper";
import { DateInput, NumberInput, SelectInput, SimpleInput, TextInput } from "../15.10.-base/elements/inputs";
import { ObtenerListas } from "../15.10.-base/obtenerServicios";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import SimbolAdd from "@mui/icons-material/Add";
import Save from "@mui/icons-material/Save";
import Cancel from "@mui/icons-material/Cancel";
import { appConfigTipoPersonaTercera, appDefaultValues, appSecurityConfiguration } from "../../../../config/Config";
import TimeHelper from "../../../helpers/TimeHelper";
import RequestManager from "../../../services/origination/wapirequest.service";
import { Dropdown } from "react-bootstrap";
import CustomDivToggle from "../15.10.-base/elements/customDivToggle/CustomDivToggle";
import { BsThreeDots, BsSearch } from "react-icons/bs";
import { toast } from "sonner";
import { InformeComercialPersona, InformeComercialPersonaDefault } from "../15.10.-base/interfaces";
import { AxiosError } from "axios";

export const DatosGerenciaAuditor = (DatosGerencia: any) => {
  const [listTipoDocumento, setListTipoDocumento] = useState<any[]>([]);
  const [listPaises, setListPaises] = useState<any[]>([]);
  const [listEstadoCivil, setListEstadoCivil] = useState([]);
  const [listProfesion, setListProfesion] = useState<any[]>([]);
  const [listGenero, setListGenero] = useState([]);
  const [dataGerencia, setDataGerencia] = useState<InformeComercialPersona>(InformeComercialPersonaDefault);
  const [dataListGerencia, setDataListGerencia] = useState<InformeComercialPersona[]>([]);
  const [chkRequerido, setChkRequerido] = React.useState<boolean>(false);
  const [flgShow, setFlgShow] = useState<boolean>(false);
  const [longNumber, setLongNumber] = useState<any>(8);

  const InitListas = async () => {
    await ObtenerListas.TipoDocumento().then(response => {
        setListTipoDocumento(response);
    });
    await ObtenerListas.Pais().then(response =>{
        setListPaises(response)
    });
    await ObtenerListas.EstadoCiviles().then(response =>{
        setListEstadoCivil(response)
    });
    await ObtenerListas.Profesion().then(response =>{
        setListProfesion(response)
    });
    await ObtenerListas.IdentidadesGenero().then(response =>{
      setListGenero(response);
  });
};
const onClickNuevo = () => {
    setFlgShow(true);
    setDataGerencia(InformeComercialPersonaDefault);
}
const onClickCancelar = () => {
    setFlgShow(false);
    setDataGerencia(InformeComercialPersonaDefault);
}
const GuardarDirectorio = async () => {
    const idGenero = dataGerencia.idGenero.trim();
    let resultado;
    let bodyInsert = {
        "identInformeComercial": dataGerencia.identInformeComercial == 0 ? DatosGerencia.dataInformeComercial.identInformeComercial : dataGerencia.identInformeComercial,
        "identPersona": dataGerencia.identPersona == null ? 0 : dataGerencia.identPersona,
        "idTipoDocumento": dataGerencia.idTipoDocumento,
        "descTipoDocumento": dataGerencia.descTipoDocumento,
        "nroDocumento": dataGerencia.nroDocumento,
        "rucPnn": dataGerencia.rucPnn,
        "razonSocial": dataGerencia.razonSocial,
        "nombres": dataGerencia.nombres,
        "apellidoPaterno": dataGerencia.apellidoPaterno,
        "apellidoMaterno": dataGerencia.apellidoMaterno,
        "idEstadoCivil": dataGerencia.idEstadoCivil,
        "descEstadoCivil": dataGerencia.descEstadoCivil,
        "fechaNacimiento": dataGerencia.fechaNacimiento,
        "idGenero": idGenero,
        "descGenero": dataGerencia.descGenero,
        "idPaisNacimiento": dataGerencia.idPaisNacimiento,
        "descPaisNacimiento": dataGerencia.descPaisNacimiento,
        "idPaisResidencia": dataGerencia.idPaisResidencia,
        "descPaisResidencia": dataGerencia.descPaisResidencia,
        "idDistrito": dataGerencia.idDistrito,
        "descDistrito": dataGerencia.descDistrito,
        "direccion": dataGerencia.direccion,
        "telefono1": dataGerencia.telefono1,
        "telefono2": dataGerencia.telefono2,
        "correo": dataGerencia.correo,
        "idProfesion": dataGerencia.idProfesion,
        "descProfesion": dataGerencia.descProfesion,
        "idCargo": dataGerencia.idCargo,
        "descCargo": dataGerencia.descCargo,
        "idTipoPersonaInforme": appConfigTipoPersonaTercera.PersonaGerencia,
        "descTipoPersonaInforme": appConfigTipoPersonaTercera.DescPersonaGerencia,
        // "aporteSoles": dataGerencia.aporteSoles,
        // "aportePorcentaje": dataGerencia.aportePorcentaje,
        "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
    }
    let bodyUpdate = {
        "identInformeComercialPersona": dataGerencia.identInformeComercialPersona,
        "identInformeComercial": dataGerencia.identInformeComercial == 0 ? DatosGerencia.dataInformeComercial.identInformeComercial : dataGerencia.identInformeComercial,
        "identPersona": dataGerencia.identPersona == null ? 0 : dataGerencia.identPersona,
        "idTipoDocumento": dataGerencia.idTipoDocumento,
        "descTipoDocumento": dataGerencia.descTipoDocumento,
        "nroDocumento": dataGerencia.nroDocumento,
        "rucPnn": dataGerencia.rucPnn,
        "razonSocial": dataGerencia.razonSocial,
        "nombres": dataGerencia.nombres,
        "apellidoPaterno": dataGerencia.apellidoPaterno,
        "apellidoMaterno": dataGerencia.apellidoMaterno,
        "idEstadoCivil": dataGerencia.idEstadoCivil,
        "descEstadoCivil": dataGerencia.descEstadoCivil,
        "fechaNacimiento": dataGerencia.fechaNacimiento,
        "idGenero": idGenero,
        "descGenero": dataGerencia.descGenero,
        "idPaisNacimiento": dataGerencia.idPaisNacimiento,
        "descPaisNacimiento": dataGerencia.descPaisNacimiento,
        "idPaisResidencia": dataGerencia.idPaisResidencia,
        "descPaisResidencia": dataGerencia.descPaisResidencia,
        "idDistrito": dataGerencia.idDistrito,
        "descDistrito": dataGerencia.descDistrito,
        "direccion": dataGerencia.direccion,
        "telefono1": dataGerencia.telefono1,
        "telefono2": dataGerencia.telefono2,
        "correo": dataGerencia.correo,
        "idProfesion": dataGerencia.idProfesion,
        "descProfesion": dataGerencia.descProfesion,
        "idCargo": dataGerencia.idCargo,
        "descCargo": dataGerencia.descCargo,
        "idTipoPersonaInforme": appConfigTipoPersonaTercera.PersonaGerencia,
        "descTipoPersonaInforme": appConfigTipoPersonaTercera.DescPersonaGerencia,
        // "aporteSoles": dataGerencia.aporteSoles,
        // "aportePorcentaje": dataGerencia.aportePorcentaje,
        "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId)
    }

    try {
      const response = dataGerencia.identInformeComercialPersona == 0 ? 
      await RequestManager.MnInformeComercialPersonaInsert(bodyInsert) : 
      await RequestManager.MnInformeComercialPersonaUpdate(bodyUpdate);
      
      if (response.status === 200) {
        resultado = response.data.isValid;
        if (resultado === true) {
          if(dataGerencia.identInformeComercialPersona == 0)
              {toast.success("Registro guardado correctamente", { duration: 5000 });}
          else{toast.success("Registro actualizado correctamente.", { duration: 5000 });}
        }
        else{
          toast.error("Ocurrió un error", { duration: 5000 });
        }
      };
      CargaInicial();

      setFlgShow(false);
      setDataGerencia(InformeComercialPersonaDefault);      
    } catch (error: AxiosError | any) {            
      if (error.response && error.response.data.status === 400 && error.response.data.errors) {
          const validationErrors = error.response.data.errors;
          if (validationErrors) {
              for (const [field, messages] of Object.entries(validationErrors)) {                            
                  let message = messages as string[]; // Type assertion
                  toast.error(`${field}: ${message}`, { duration: 5000 });
              }
          } else {
              toast.error("Ocurrió un error", { duration: 5000 });
          }
      }
  }           
}

const CargaInicial = async () => {
    const response = await RequestManager.GetInformeComercialPersona({ "identInformeComercial": DatosGerencia.dataInformeComercial.identInformeComercial, 
                                                                        "idTipoPersonaInforme": appConfigTipoPersonaTercera.PersonaGerencia });
    if (response.status === 200) {
      setDataListGerencia(response.data.content);
    }
}

const handleClickViewDataDirectorio = async (value: any) =>  {
    setFlgShow(true);
    const response = await RequestManager.GetMnInformeComercialPersonaById({ "identInformeComercialPersona": value });
    if (response.status === 200) {
        setDataGerencia(response.data.content);
    }
}

const handleClickDeleteDirectorio = async (value: any) =>  {
    setFlgShow(false);
    setDataGerencia(InformeComercialPersonaDefault);
    const response = await RequestManager.MnInformeComercialPersonaDelete({ "identInformeComercialPersona": value });
    if (response.status === 200) {
        console.log(response);
        setDataListGerencia(response.data.content);
        CargaInicial();
    }
}

const changeValueTipoDocumento = (e: any) => {
    let id = e.target.value as number;
    let text = '';
    if (id > 0) {
      for (let i = 0; i < listTipoDocumento.length; i++) {
        if (listTipoDocumento[i].id === id) {
            text = listTipoDocumento[i].description;
            break;
        }
      }
    }
    setDataGerencia({ ...dataGerencia, idTipoDocumento: id, descTipoDocumento: text,nroDocumento: appDefaultValues.StringEmpty });
    if(id === 172 || id === 173){
      setLongNumber(12);
    }
    else if(id === 174){
      setLongNumber(20);
    }
    else{
      setLongNumber(8);
    }
}

const changeValuePaisNacimiento = (e: any) => {
    let id = e.target.value as number;
    let text = '';
    if (id > 0) {
      for (let i = 0; i < listPaises.length; i++) {
        if (listPaises[i].id === id) {
            text = listPaises[i].description;
            break;
        }
      }
    }
    setDataGerencia({ ...dataGerencia, idPaisNacimiento: id, descPaisNacimiento: text});
}

const changeValuePaisResidencia = (e: any) => {
    let id = e.target.value as number;
    let text = '';
    if (id > 0) {
      for (let i = 0; i < listPaises.length; i++) {
        if (listPaises[i].id === id) {
            text = listPaises[i].description;
            break;
        }
      }
    }
    setDataGerencia({ ...dataGerencia, idPaisResidencia: id, descPaisResidencia: text});
}

const changeValueCargo = (e: any) => {
  let id = e.target.value as number;
  let text = '';
  if (id > 0) {
    for (let i = 0; i < listProfesion.length; i++) {
      if (listProfesion[i].id === id) {
          text = listProfesion[i].description;
          break;
      }
    }
  }
  setDataGerencia({ ...dataGerencia, idCargo: id, descCargo: text});
}
  
  useEffect(() => {
    CargaInicial();
    InitListas();
}, [DatosGerencia]);
    
return (
  <div className="border rounded-9 shadow p-3 p-md-4 mb-4 mb-md-4 cardTabs">
    <AccordionGroup>
      <Accordion>
        <AccordionSummary>IV. DATOS DE LA GERENCIA Y EJECUTIVOS PRINCIPALES</AccordionSummary>
          <AccordionDetails>
            <>
              <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-12 cardObjets align-top">
                      <Table borderAxis="none" className="style-table align-top">
                          <thead>
                              <tr>
                              <th style={{ fontSize: "12px", width: "10%", backgroundColor: "#E1E1E1", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}>TIPO DOCUMENTO</th>
                              <th style={{ fontSize: "12px", width: "10%", backgroundColor: "#E1E1E1", }}>NRO DOCUMENTO</th>
                              <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>RUC</th>
                              <th style={{ fontSize: "12px", width: "15%", backgroundColor: "#E1E1E1", }}>NOMBRE O RAZÓN SOCIAL</th>
                              <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>APELLIDO PATERNO</th>
                              <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>APELLIDO MATERNO</th>
                              <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>PAÍS DE NACIMIENTO</th>
                              <th style={{ fontSize: "12px", width: "11%", backgroundColor: "#E1E1E1", }}>PAÍS DE RESIDENCIA</th>
                              <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>CARGO</th>
                              <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>ESTADO</th>
                              <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>ACCIONES</th>
                              {/* <th style={{ fontSize: "12px", width: "7%", backgroundColor: "#E1E1E1", }}>APORTE %</th> */}
                              </tr>
                          </thead>
                      <tbody>
                      {
                          dataListGerencia.length > 0 && dataListGerencia.map((row: any, index: number) => {
                          return (
                          <tr key={row.identInformeComercialPersona}>
                          <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descTipoDocumento}</td>
                          <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.nroDocumento}</td>
                          <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.rucPnn}</td>
                          <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {(row.idTipoDocumento === 174 ? row.razonSocial : row.nombres)}</td>
                          <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.apellidoPaterno}</td>
                          <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.apellidoMaterno}</td>
                          <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descPaisNacimiento}</td>
                          <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descPaisResidencia}</td>
                          <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.descCargo}</td>
                          <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.estado}</td>
                          {/* <td style={{ fontSize: "12px", borderTopLeftRadius: "10px", borderBottomLeftRadius: "10px", }}> {row.AportePorcentaje}</td> */}
                          <td>
                              <Dropdown key={row.identInformeComercialPersona}>
                                <Dropdown.Toggle
                                  as={CustomDivToggle}
                                  style={{ cursor: "pointer" }}
                                >
                                  <BsThreeDots />
                                </Dropdown.Toggle>
                                <Dropdown.Menu>
                                  <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickViewDataDirectorio(
                                            row.identInformeComercialPersona
                                          )
                                        }
                                      >
                                        Editar
                                  </Dropdown.Item>
                                  <Dropdown.Item
                                        onClick={(e: any) =>
                                          handleClickDeleteDirectorio(
                                            row.identInformeComercialPersona
                                          )
                                        }
                                      >
                                        Eliminar
                                  </Dropdown.Item>
                                </Dropdown.Menu>
                              </Dropdown>
                          </td>
                          </tr>
                              )
                          })
                      }
                      </tbody>
                      </Table>
                  </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                  <div className="col-lg-12 cardObjets bottom-right">
                      <Button
                          size="md"
                          color="neutral"
                          variant="outlined"
                          endDecorator={<SimbolAdd />}
                          sx={{ borderRadius: 24, width: 1/8 }}
                          // disabled={props.disabled}
                          onClick={onClickNuevo}>Nuevo
                      </Button>
                  </div>
              </div>        

              { flgShow && <div>     
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-2 cardObjets">
                  <FormLabel>Requerido</FormLabel>
                  <Switch
                    color={chkRequerido ? "success" : "neutral"}
                    checked={chkRequerido}
                    onChange={(event: any) => {
                      setChkRequerido(event.target.checked);
                    }}
                    slotProps={{
                      track: {
                        children: (
                          <React.Fragment>
                            <Typography
                              component="span"
                              level="inherit"
                              sx={{ ml: "10px" }}
                            >
                              Si
                            </Typography>
                            <Typography
                              component="span"
                              level="inherit"
                              sx={{ mr: "8px" }}
                            >
                              No
                            </Typography>
                          </React.Fragment>
                        ),
                      },
                    }}
                    sx={{
                      "--Switch-thumbSize": "27px",
                      "--Switch-trackWidth": "64px",
                      "--Switch-trackHeight": "31px",
                    }}
                  />
                </div>
                <div className="col-lg-2 cardObjets">
                  <SelectInput
                      title={"Tipo de documento"}
                      options={listTipoDocumento}
                      value={dataGerencia.idTipoDocumento}
                      disabled={!chkRequerido}
                      onChange={(event: any) => {
                        changeValueTipoDocumento(event);
                        // setDataGerencia({ ...dataGerencia, TipoDocumento: event.target.value, NumeroDocumento: appDefaultValues.StringEmpty });
                    }}
                  />
                </div>
                {(dataGerencia.idTipoDocumento === 172 || dataGerencia.idTipoDocumento === 173) ?
                        <div className="col-lg-2 cardObjets">
                            <SimpleInput
                                title={"Nro. documento"}
                                placeholder=""
                                longNumber={longNumber}
                                required={true}
                                value={dataGerencia.nroDocumento.trim()}
                                onChange={(event: any) => {
                                    setDataGerencia({ ...dataGerencia, nroDocumento: event.target.value});
                                }}
                        />
                        </div>
                        :
                        <div className="col-lg-2 cardObjets">
                        <NumberInput
                            title={"Nro. documento"}
                            placeholder=""
                            longNumber={longNumber}
                            required={true}
                            value={dataGerencia.nroDocumento.trim()}
                            onChange={(event: any) => {
                                setDataGerencia({ ...dataGerencia, nroDocumento: event.target.value});
                            }}
                        />
                        </div>
                    }
                  <div className="col-lg-2 cardObjets">
                    <NumberInput
                        title={"RUC PNN"}
                        placeholder=""
                        value={dataGerencia.rucPnn}
                        longNumber={20}
                        disabled={!chkRequerido}
                        onChange={(event: any) => {
                          setDataGerencia({ ...dataGerencia, rucPnn: event.target.value });
                      }}
                    />
                  </div>
                <div className="col-lg-4 cardObjets">
                  <SimpleInput
                      title={"Nombre / Razón Social"}
                      placeholder="Ingresar nombre"
                      value={dataGerencia.idTipoDocumento === 174 ? dataGerencia.razonSocial : dataGerencia.nombres}
                      disabled={!chkRequerido}
                      onChange={(event: any) => {
                        if(dataGerencia.idTipoDocumento === 174){
                          setDataGerencia({ ...dataGerencia, razonSocial: event.target.value, nombres: "-"});
                        }
                        else{
                          setDataGerencia({ ...dataGerencia, nombres: event.target.value, razonSocial: "-"});
                        }
                    }}
                  />
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                  <TextInput
                      title={"Apellido Paterno"}
                      placeholder="Ingresar apellido"
                      value={dataGerencia.apellidoPaterno}
                      disabled={!chkRequerido}
                      onChange={(event: any) => {
                        setDataGerencia({ ...dataGerencia, apellidoPaterno: event.target.value });
                    }}
                  />
                </div>
                <div className="col-lg-4 cardObjets">
                  <TextInput
                      title={"Apellido Materno"}
                      placeholder="Ingresar apellido"
                      value={dataGerencia.apellidoMaterno}
                      disabled={!chkRequerido}
                      onChange={(event: any) => {
                        setDataGerencia({ ...dataGerencia, apellidoMaterno: event.target.value });
                    }}
                  />
                </div>
                <div className="col-lg-2 cardObjets">
                  <SelectInput
                      title={"Estado Civil"}
                      options={listEstadoCivil}
                      value={dataGerencia.idEstadoCivil}
                      disabled={!chkRequerido}
                      onChange={(event: any) => {
                        setDataGerencia({ ...dataGerencia, idEstadoCivil: event.target.value });
                    }}
                  />
                </div>
                <div className="col-lg-2 cardObjets">
                  <DateInput
                      title={"Fecha de nacimiento"}
                      value={dataGerencia.fechaNacimiento}
                      disabled={!chkRequerido}
                      onChange={(date: any | null) => setDataGerencia({ ...dataGerencia, fechaNacimiento: date === null ? '' : TimeHelper.ObtenerFormatoFechaAmericanaDayJs(date) })}
                  />
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                  <SelectInput
                      title={"Género"}
                      options={listGenero}
                      value={dataGerencia.idGenero}
                      disabled={!chkRequerido}
                      onChange={(event: any) => {
                        setDataGerencia({ ...dataGerencia, idGenero: event.target.value});
                    }}
                  />
                </div>
                <div className="col-lg-4 cardObjets">
                  <SelectInput
                      title={"País de nacimiento"}
                      options={listPaises}
                      value={dataGerencia.idPaisNacimiento}
                      disabled={!chkRequerido}
                      onChange={(event: any) => {
                        changeValuePaisNacimiento(event);
                    }}
                  />
                </div>
                <div className="col-lg-4 cardObjets">
                  <SelectInput
                      title={"País de residencia"}
                      options={listPaises}
                      value={dataGerencia.idPaisResidencia}
                      disabled={!chkRequerido}
                      onChange={(event: any) => {
                        changeValuePaisResidencia(event);
                    }}
                  />
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                  <SimpleInput
                      title={"Dirección"}
                      placeholder="Ejm. Av. La Marina"
                      value={dataGerencia.direccion}
                      disabled={!chkRequerido}
                      onChange={(event: any) => {
                        setDataGerencia({ ...dataGerencia, direccion: event.target.value});
                    }}
                  />
                </div>
                <div className="col-lg-2 cardObjets">
                  <NumberInput
                      title={"Teléfono 1"}
                      placeholder=""
                      longNumber={9}
                      required={true}
                      value={dataGerencia.telefono1}
                      disabled={!chkRequerido}
                      onChange={(event: any) => {
                        setDataGerencia({ ...dataGerencia, telefono1: event.target.value});
                    }}
                  />
                </div>
                <div className="col-lg-2 cardObjets">
                  <NumberInput
                      title={"Teléfono 2"}
                      placeholder=""
                      longNumber={9}
                      required={true}
                      value={dataGerencia.telefono2}
                      disabled={!chkRequerido}
                      onChange={(event: any) => {
                        setDataGerencia({ ...dataGerencia, telefono2: event.target.value});
                    }}
                  />
                </div>
                <div className="col-lg-4 cardObjets">
                  <SimpleInput
                      title={"Correo"}
                      placeholder="Ingresar email"
                      value={dataGerencia.correo}
                      disabled={!chkRequerido}
                      onChange={(event: any) => {
                        setDataGerencia({ ...dataGerencia, correo: event.target.value});
                    }}
                  />
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center">
                <div className="col-lg-4 cardObjets">
                    <SelectInput
                        title={"Profesión"}
                        options={listProfesion}
                        value={dataGerencia.idProfesion}
                        disabled={!chkRequerido}
                        onChange={(event: any) => {
                          setDataGerencia({ ...dataGerencia, idProfesion: event.target.value});
                      }}
                    />
                </div>
                <div className="col-lg-4 cardObjets">
                  <SelectInput
                      title={"Cargo"}
                      options={listProfesion}
                      value={dataGerencia.idCargo}
                      disabled={!chkRequerido}
                      onChange={(event: any) => {
                        changeValueCargo(event);
                  }}
                  />
                </div>
              </div>
              <div className="row g-2 mb-2 align-items-center ">
                  <div className="col-lg-12 cardObjets bottom-right">
                          <Button
                              size="md"
                              color="neutral"
                              variant="outlined"
                              endDecorator={<Save />}
                              sx={{ borderRadius: 24, width: 1/8 }}
                              onClick={GuardarDirectorio}
                              disabled={!chkRequerido}
                              >Guardar
                          </Button>
                          <Button
                              size="md"
                              color="neutral"
                              variant="outlined"
                              endDecorator={<Cancel />}
                              sx={{ borderRadius: 24, width: 1/8 }}
                              // disabled={!chkRequerido}
                              onClick={onClickCancelar}
                              >Cancelar
                          </Button>
                  </div>
              </div>
              
              </div>  }   
            </>
          </AccordionDetails>
      </Accordion>
    </AccordionGroup>
  </div>
);
};

export default DatosGerenciaAuditor;