import { useContext, useEffect, useState } from "react";
import { CompraParaContext } from "../contexts";
import { StepOneContext } from "../../3.10.-base/providers";

const TitularCompraParaProvider = ({ children }: any) => {

    const { personaCompraParaData } = useContext(StepOneContext);

    const [compraParaEstadoCivilId, setCompraParaEstadoCivilId] = useState<number>(0);
    const [tipoDocumentoId, setTipoDocumentoId] = useState<number>(0);

    useEffect(() => {
        if (personaCompraParaData !== 0) {
            setCompraParaEstadoCivilId(personaCompraParaData.EstadoCivilId);
            setTipoDocumentoId(personaCompraParaData.TipoDocumentoId);
        }
    }, [personaCompraParaData]);

    return (
        <CompraParaContext.Provider value={{  
            compraParaEstadoCivilId, 
            setCompraParaEstadoCivilId,
            tipoDocumentoId,
            setTipoDocumentoId,
        }}>
            {children}
        </CompraParaContext.Provider>
    );

};

export default TitularCompraParaProvider;