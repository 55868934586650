import MasterManager from "../../../services/origination/wapimaster.service";
import RequestManager from "../../../services/origination/wapirequest.service";
import { MnIntegracionCotizacionRequest } from "../../../models/RequestManager/integracion-cotizacion-request.model";
import JsonHelper from "../../../helpers/JsonHelper";
import { appConfigKey } from "../../../../config/Config";
import ServicesProvider from "../../../services/support/wapiservicesprovider";
import GesintelHelper from "../../1.-shared/1.5.-observed/1.5.9.-helpers/GesintelHelper";
import ListaNegraHelper from "../../1.-shared/1.5.-observed/1.5.9.-helpers/ListaNegraHelper";
import { GetCotizacionSimulacionRequest } from "../../../models/ReportManager/cotizacion-simulacion-request.model";
import ReportManager from "../../../services/support/wapireport";
import { BTObtenerDatosPartnerVendedorRequest } from "../../../models/bantotal/BTObtenerDatosPartnerVendedor/BTObtenerDatosPartnerVendedorRequest";

function tieneContent(content: any) {
  return content !== undefined && content !== null;
}

function numeroConCommas(numero: string): string {
  return numero.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

function parseDateString(dateString: string): String {
  let dateParts = dateString.split("-");
  let year = dateParts[0];
  let month = dateParts[1];
  let day = dateParts[2];
  let formattedDate = `${day}/${month}/${year}`;
  return formattedDate;
}

export const General = {
  GetMnEvaluacionCotizacion: async (identCotizacion: any) => {
    let evaluaciones = [];
    try {
      let response = await MasterManager.GetMnEvaluacionCotizacion(identCotizacion);
      if (response && response.status === 200) {
        evaluaciones = response.data.content;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA OBTENER LA EVALUACION DE LA COTIZACION.');
    }
    return evaluaciones;
  },

  GetMnEvaluacionCotizacionInsert: async (identCotizacion: number, step: number, modelicaRequest: string, modelicaResponse: string, identUsuario: number) => {
    try {
      await MasterManager.GetMnEvaluacionCotizacionInsert(identCotizacion, step, modelicaRequest, modelicaResponse, identUsuario);
      return true;
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO PARA REGISTRAR LA EVALUACION DE LA COTIZACION.');
      return false;
    }
  },

  GetMnIntegracionCotizacionInsert: async (body: MnIntegracionCotizacionRequest) => {
    try {
      const response = await RequestManager.GetMnIntegracionCotizacionInsert(body);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      console.warn('OCURRIO UN ERROR CON EL SERVICIO DE INSERTAR INTEGRACION COTIZACION.');
      return null;
    }
  },

  ValidarListaNegraYGesintel: async (data: any) => {
    let bantotalPlaftObservadoValue: boolean = false;
    let bantotalRiesgosObservadoValue: boolean = false;
    let gesintelPlaftObservadoValue: boolean = false;
    let bantotalResponse: string | null = null;
    let gesintelResponse: string | null = null;
    let clientePepValue: boolean = false;
    let validacion = null
    let errorBantotal: boolean = false;
    let errorGesintel: boolean = false;
    try {
      const RequestValidarListasInhabilitados = JsonHelper.crearJsonRequestValidarListasInhabilitados();
      RequestValidarListasInhabilitados.pais = appConfigKey.KeyIdPaisPeru;
      RequestValidarListasInhabilitados.tipoDocumento = data?.tipoDocumento;
      RequestValidarListasInhabilitados.numeroDocumento = data?.numeroDocumento;
      const response = await ServicesProvider.ValidarListasInhabilitados(RequestValidarListasInhabilitados);

      if (response.status === 200) {
        if (response.data.content?.listaInhabilitados?.sBtListaInhabilitados != null) {
          let resultList = ListaNegraHelper.TransformarContentToList(response.data.content);
          resultList?.forEach((element) => {
            if (element?.tipo === appConfigKey.keyDescripPlaft) bantotalPlaftObservadoValue = true;
            if (element?.tipo === appConfigKey.keyDescripRiesgosMin) bantotalRiesgosObservadoValue = true;
            if (element?.descripcion === appConfigKey.keyDescripPlaftPEP) clientePepValue = true;
          });
          if (bantotalPlaftObservadoValue || bantotalRiesgosObservadoValue) {
            bantotalResponse = JSON.stringify(resultList);
          }
        } else if (!response.data.isValid) {
          errorBantotal = true;
        }
      } else {
        errorBantotal = true;
      }
    }
    catch (error) {
      errorBantotal = true;
    }

    try {
      const RequestGesintelObtenerAMLResult = JsonHelper.crearJsonRequestGesintelObtenerAMLResult();
      RequestGesintelObtenerAMLResult.rut = data?.numeroDocumento;
      RequestGesintelObtenerAMLResult.typeSearch = appConfigKey.keyTypeSearchGesintel;
      const response = await ServicesProvider.ValidarGesintelAML(RequestGesintelObtenerAMLResult);

      if (response.status === 200) {
        if (response.data.content?.results != null) {
          let resultList = GesintelHelper.TransformarContentToList(response.data.content);
          if (resultList && resultList.length > 0) {
            gesintelPlaftObservadoValue = true;

            if (resultList.filter(e => [appConfigKey.keyDescripPEPVigente, appConfigKey.keyDescripPEPHistorico, appConfigKey.keyDescripPEPCandidato].includes(e.descripcion))) {
              clientePepValue = true;
            }

            gesintelResponse = JSON.stringify(resultList);
          }
        } else if (!response.data.isValid) {
          errorGesintel = true;
        }
      } else {
        errorGesintel = true;
      }
    }
    catch (error) {
      errorGesintel = true;
    }

    validacion = {
      bantotalPlaftObservado: bantotalPlaftObservadoValue,
      bantotalRiesgosObservado: bantotalRiesgosObservadoValue,
      gesintelPlaftObservado: gesintelPlaftObservadoValue,
      bantotalJson: bantotalResponse,
      gesintelJson: gesintelResponse,
      clientePep: clientePepValue,
      errorBantotal: errorBantotal,
      errorGesintel: errorGesintel,
    }

    return validacion;

  },

  GetCotizacionSimulacion: async (body: GetCotizacionSimulacionRequest) => {
    try {
      const response = await ReportManager.GetCotizacionSimulacion(body);
      if (response.status === 200) {
        return response;
      }
    } catch (error) {
      //myowasp('OCURRIO UN ERROR CON EL SERVICIO DE SIMULACION COTIZACION.');
      return null;
    }
  },
  
  GetUsuario: async (userId: number) => {
    try {
      const response = await MasterManager.GetMnUsuarios({
        idenT_USUARIO: userId,
      });
      if (response.status === 200) {
        return response.data.content[0];
      }
    } catch (error) {
      //myowasp('OCURRIO UN ERROR CON EL SERVICIO DE OBTENER USUARIO.');
      return null;
    }
  },

  ObtenerDatosPartnerVendedor: async (body: BTObtenerDatosPartnerVendedorRequest) => {
    try {
      const response = await ServicesProvider.ObtenerDatosPartnerVendedor(body);
      if (response?.status === 200) {
        return response.data.content;
      }
    } catch (error) {
      //myowasp('OCURRIO UN ERROR CON EL SERVICIO DE OBTENER PARTNER VENDEDOR.');
      return null;
    }
  },

};