import { Toaster } from 'sonner';
import MainLayout from '../../1.-shared/1.2.-layout/1.2.3.-main-layout/main-layout';
import LoadingProgress from '../../1.-shared/1.4.-loading/loading-progress/loading-progress';
import { useEffect, useState } from 'react';
import './informeComercial.scss';
import DatosGenerales from '../15.20.-datosGenerales/datosGenerales';
import Accionistas from '../15.30.-accionistas/accionistas';
import DatosDirectorio from '../15.40.-datosDirectorio/datosDirectorio';
import DatosGerencia from '../15.50.-datosGerencia/datosGerencia';
import Participacion from '../15.60.-participacion/participacion';
import Cargos from '../15.70.-cargos/cargos';
import Produccion from '../15.80.-produccion/produccion';
import Propiedades from '../15.90.-propiedades/propiedades';
import Proveedores from '../15.100.-proveedores/proveedores';
import Ventas from '../15.110.-ventas/ventas';
import Representantes from '../15.120.-representantes/representantes';
import DatosGrupo from '../15.130.-datosGrupo/datosGrupo';
import RequestManager from '../../../services/origination/wapirequest.service';
import { DatosInformeComercial, DatosInformeComercialDefault } from './interfaces';
import { appConfig, appConfigPerfiles, appSecurityConfiguration } from '../../../../config/Config';
import { InformeComercialProvider } from './providers/informeComercialProvider';
import InformeComercialCardTitle from './components/InformeComercialCardTitle';
import { Button } from '@mui/joy';
import { useLocation, useNavigate } from "react-router-dom";
import MasterManager from '../../../services/origination/wapimaster.service';


export default function InformeComercial({ isModal, solicitudId }: any) {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isOk, setIsOk] = useState(false);
  const [dataInformeComercial, setDataInformeComercial] = useState<DatosInformeComercial>(DatosInformeComercialDefault);
  const [idPerfilSession, setIdPerfilSession] = useState<number>(0);
  const [isDisabled, setIsDisabled] = useState<any>(false);

  let navigate = useNavigate();
  let urlParams = new URLSearchParams(window.location.search);

  const IdSolicitud = solicitudId ?? urlParams.get('Solicitud');

  const GetMnIdPerfil = async () => {
    const account = appConfig.msalInstance.getActiveAccount();
    if (![null, undefined].includes(account)) {
      let request = {
        "email_INTERNO": account.username
      }
      await MasterManager.GetMnUsuarios(request).then((data) => {
        let idPerfil = data.data.content[0].idenT_PERFIL;
        setIdPerfilSession(idPerfil);
        if(idPerfil === appConfigPerfiles.CodigoBPO){
          setIsDisabled(true);
        }
      });
    }
  }
  
  const CargaInicial = async () => {
    const response = await RequestManager.GetMnInformeComercialRegistroByIdSolicitud({ "identSolicitud": IdSolicitud, "identUsuarioCreador": String(appSecurityConfiguration.SessionUserId) });
    if (response.status === 200) {
      setDataInformeComercial(response.data.content);
    }
  }

  const changeValorFlg = (val: any) => {
    setIsOk(val);
  }

  const handleClose = () => {
    navigate(`/BandejaSolicitudes/MisSolicitudes`);
  }
  
  const GetCardInformeComercialClassName = (isModal: boolean | null | undefined) => {
    let className = 'row g-3 mb-2 cardInformeComercial';
    if (isModal) className += 'Modal';
    return className;
  };

  useEffect(() => {
    CargaInicial();
    GetMnIdPerfil();
  }, []);

  return (
    <div>
      {isLoading && <LoadingProgress />}
      <Toaster position="top-center" toastOptions={{ style: { width: '500px', margin: '0 auto' } }} richColors closeButton />

      {!isModal && 
      <>
        <MainLayout />
        <InformeComercialCardTitle identification={IdSolicitud}/>
      </>
      }
      <InformeComercialProvider solicitudId={IdSolicitud}>
        <div className={GetCardInformeComercialClassName(isModal)}>
          <DatosGenerales
            isDisabled = {isDisabled}
          />
          <Accionistas
            dataInformeComercial={dataInformeComercial}
            changeValorFlg= {changeValorFlg}
            isDisabled = {isDisabled}
          />
          <DatosDirectorio
            dataInformeComercial={dataInformeComercial}
            isDisabled = {isDisabled}
          />
          <DatosGerencia
            dataInformeComercial={dataInformeComercial}
            isDisabled = {isDisabled}
          />
          <Participacion
            dataInformeComercial={dataInformeComercial}
            isDisabled = {isDisabled}
          />
          <Cargos
            dataInformeComercial={dataInformeComercial}
            isDisabled = {isDisabled}
          />
          <Produccion
            dataInformeComercial={dataInformeComercial}
            isDisabled = {isDisabled}
          />
          <Propiedades
            dataInformeComercial={dataInformeComercial}
            isDisabled = {isDisabled}
          />
          <Proveedores
            dataInformeComercial={dataInformeComercial}
            isDisabled = {isDisabled}
          />
          <Ventas
            dataInformeComercial={dataInformeComercial}
            isDisabled = {isDisabled}
          />
          <Representantes
            dataInformeComercial={dataInformeComercial}
            isDisabled = {isDisabled}
          />
          <DatosGrupo
            dataInformeComercial={dataInformeComercial}
            isDisabled = {isDisabled}
          />
        </div>
        {!isModal && 
        <>
          <div className="row g-2 mb-2 align-items-center ">
            <div className="col-lg-12 cardObjets bottom-right">
              <Button onClick={handleClose} size="md" color="neutral" sx={{ borderRadius: 24, paddingLeft: '50px', paddingRight: '50px' }}>
                    Cerrar
              </Button>
            </div>
          </div>
        </>
        }
      </InformeComercialProvider>
    </div>
  );
}